import { FC } from 'react';
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ModalEditPermissionsInterface } from './indexModel';
import { Formik } from 'formik';
import Loading from '../../../../../components/loading';

import './styles.scss';

const ModalEditPermissions: FC<ModalEditPermissionsInterface> = (props) => {

  const submit = async () => {
    await props.submit();
  }

  return (
    <Modal className="modalEditUserPermissions" show={props.showModal} onHide={() => props.hide()} aria-labelledby="" centered={true}>
      { props.isLoading && <Loading visibility={props.isLoading} noBootstrap={true} customStyle={{ position: 'absolute' }} /> }
      <Modal.Header style={{ padding: '1rem 1rem 0rem 1rem' }} closeButton>
        <span>Alterar permissões</span>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <span className="userInfo">Conta: {props.currentUser?.email}</span>
          <Formik
            initialValues={{
              master: false,
              contract: false,
              company: false,
              keys: false,
              agents: false,
              read_all: false,
            }}
            onSubmit={submit}
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit}>
                <div className="d-flex flex-column nopadding state-base">
                  { props.formStateBase.map((item, index) => {
                    return (
                      <div className="check-item" key={`modalPermissionsOptions${index}`}>
                        <div className="check-item-icon">{item.icon}</div>
                        <div className="switch-input">
                          <span className="check-item-label">{item.label}</span>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tootip" className="tooltip-arrow-Top">{item.value ? 'Desativar' : 'Ativar'}</Tooltip>
                            }
                          >  
                            <label className={props.isLoading ? "switch disabled" : "switch"}>
                              <input 
                                disabled={props.isLoading}
                                type="checkbox" 
                                checked={item.value} 
                                onChange={() => item.set(!item.value)} 
                                />
                              <span className="slider round"></span>
                            </label>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </div>
                { !props.isOnlyRead && 
                  <div className="d-flex flex-column nopadding state-permissions">
                    { props.formState.map((item, index) => {
                      return (
                        <div className="check-item" key={`modalPermissionsOptions${index}`}>
                          <div className="check-item-icon">{item.icon}</div>
                          <div className="switch-input">
                            <span className="check-item-label">{item.label}</span>
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tootip" className="tooltip-arrow-Top">{item.value ? 'Desativar' : 'Ativar'}</Tooltip>
                              }
                            >  
                              <label className={props.isLoading ? "switch disabled" : "switch"}>
                                <input 
                                  disabled={props.isLoading}
                                  type="checkbox" 
                                  checked={item.value} 
                                  onChange={() => item.set(!item.value)} 
                                  />
                                <span className="slider round"></span>
                              </label>
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
                <Row className="mt-3">
                  <Col md={5} className="">
                    <Button size='lg' className="buttonWhite2" style={{ width: '100%' }} onClick={() => props.hide()} >Cancelar</Button>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={5} className="d-flex justify-content-end align-self-end">
                    <Button type='submit' disabled={props.isLoading} size='lg' className="buttonBlue2WithoutBold" style={{ width: '100%' }}>Salvar</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditPermissions;
