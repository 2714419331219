import { Button, Col, Modal, Row } from "react-bootstrap";
import SelectComponentController from "../selectComponent/indexController";
import DatePicker from "react-datepicker";
import { TFunction, t } from "i18next";
import { IconDownload, IconCalendarEvent } from '@tabler/icons-react';
import './styles.scss'
import { Dispatch, SetStateAction, forwardRef } from "react";
import { addDays, differenceInDays,  } from "date-fns";
import PlaceholderLoading from 'react-placeholder-loading'

interface IOptions {
  title;
  options;
  setOptions;
  selected;
  setSelected;
  overrideStrings;
}

const DateInput = forwardRef<any, any>((props, ref) => (
  <div className="ticket_export-date-input" ref={ref} onClick={props.onClick} aria-labelledby="Data">
    <input type='text' placeholder="dd/mm/yyyy - dd/mm/yyyy" onChange={props.onChange} value={props.value} style={!props.inputValid ? {borderColor: 'hsl(354, 70%, 54%)'} : {}}/> 
    <IconCalendarEvent/>
  </div>
));

const ModalExportTickets = ({
  modalShow,
  Hide,
  selectItems,
  startDate,
  endDate,
  onChangeDate,
  exportTickets,
  showLinks,
  links,
  isReseting,
  setIsReseting,
  reset,
  isLoading,
  t,
  validDate
}: {
  modalShow: boolean;
  Hide: () => void;
  selectItems: IOptions[];
  startDate: any;
  endDate: any;
  onChangeDate: (dates: any) => void;
  exportTickets: (hasAdditionalContent: boolean) => void;
  showLinks: boolean;
  links: string[]
  isReseting: boolean
  setIsReseting: Dispatch<SetStateAction<boolean>>
  reset: () => void
  isLoading: boolean
  t: TFunction
  validDate: boolean
}) => {
  return (
    <Modal className="modal-ticket-export" show={modalShow} onHide={Hide} aria-labelledby="" size="sm" centered fullscreen='sm-down' dialogClassName="modal-ticket-export">
      <Modal.Header
        closeButton
      >
        <Modal.Title>{showLinks ? t("history_tickets.modals.ticket_export.exported_tickets") : t("history_tickets.modals.ticket_export.ticket_export")}</Modal.Title>
      </Modal.Header>
      <Modal.Body
      >
        {
          !showLinks && 
          <>
            <h6>{t("history_tickets.modals.ticket_export.filter_by")}</h6>
            <div className="filter">
          {selectItems.map((item) => {
            return (
              <div className="item-wrapper">
                <label htmlFor={item.title}>{t(`history_tickets.modals.ticket_export.${item.title.toLowerCase()}`)}</label>
                <SelectComponentController
                  options={item.options}
                  setOptions={item.setOptions}
                  selecteds={item.selected}
                  setSelecteds={item.setSelected}
                  singleSelect={false}
                  isFormNoFilter={false}
                  title={item.title}
                  overrideStrings={item.overrideStrings}
                  hasCreateItem={false}
                  hasNullOption={false}
                  hasAllOption={true}
                  isChannel={item.title === "Canal"}
                  isReseting={isReseting}
                  setIsReseting={setIsReseting}
                />
              </div>
            );
          })}
          <div className="item-wrapper date-wrapper">
            <label htmlFor="Data">{t("history_tickets.modals.ticket_export.date")}<span className={validDate ? '' : 'invalid'}>*</span></label>
            <DatePicker 
              dateFormat="dd/MM/yyyy"
              placeholderText={t("filter_option.date")}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(dates) => onChangeDate(dates)}
              maxDate={startDate && !endDate ? addDays(startDate, 31) : new Date()}
              customInput={<DateInput inputValid={validDate} />}
              popperClassName="custom-calendar"
            />
          </div>
          {/* <Button className="nopadding buttonTicketsSec4" onClick={reset}>
            <IconX></IconX>
          </Button> */}
          </div>
          {(!validDate && differenceInDays(endDate, startDate) > 31) && 
            <p className="error-message">{t("history_tickets.modals.ticket_export.date_message_error")}</p>
          }
          <p>{t("history_tickets.modals.ticket_export.date_range_message")}</p>
          </>
        }
        {
          showLinks &&
          <>
            {isLoading && (
            <>
              <PlaceholderLoading shape="rect" width={'100%'} height={15} />
              <PlaceholderLoading shape="rect" width={'100%'} height={15} />
              <PlaceholderLoading shape="rect" width={'100%'} height={15} />
              <PlaceholderLoading shape="rect" width={'100%'} height={15} />
            </>
            )}
            <div className='lista'>
              {
                links.map(link => {
                  let fileName = link.split('/').slice(-1)[0]
                  return (
                    <div className="item">
                      <p>{fileName}</p>
                      <button onClick={() => window.location = link as (string & Location)}>
                        <IconDownload/> 
                        <a href={link}>
                          {t("history_tickets.modals.ticket_export.download")}
                        </a>
                      </button>
                    </div>
                  )
                })
              }
            </div>
              {
                !isLoading  && links.length === 0 && 
                <p>{t("history_tickets.modals.ticket_export.tickets_not_found")}</p>
              }
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {!showLinks ? (
          <div className="button-wrapper">
            <Button
              size="sm"
              className="buttonWhite"
              style={{ width: "40%" }}
              disabled={!validDate}
              onClick={() => exportTickets(false)}
            >
              {t("history_tickets.modals.ticket_export.has_no_content")}
            </Button>
            <Button
              type="submit"
              size="sm"
              className="buttonBlue"
              style={{ width: "40%" }}
              disabled={!validDate}
              onClick={() => exportTickets(true)}
            >
              {t("history_tickets.modals.ticket_export.has_content")}
            </Button>
          </div>
        ): (
          <Button
            type="submit"
            size="lg"
            className="buttonBlue"
            disabled={isLoading}
            style={{ width: "40%", display:'block', marginLeft: 'auto' }}
            onClick={Hide}
          >
            {t("history_tickets.modals.ticket_export.close")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalExportTickets;
