import React, { useState, useEffect, FC } from 'react';
import { useDispatch } from "react-redux";

import { setShowUpdatedBot } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { ModalEditTokenChatbot } from './indexModel';
import EditTokenChatbot from './index'
import UserService from '../../../../services/user-service';

const AppRequesterConst = new AppRequesterController();

const EditTokenChatbotController: FC<ModalEditTokenChatbot> = (props) => {
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [tokenUserGpt, setTokenUserGpt] = useState("" as string || null);
  const [showPassword, setShowPassword] = useState(false);

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.visibility === true) {
      getTokenChatbot().then(() => {
        setModalShow(props.visibility);
      });
    } else {
      setModalShow(props.visibility);
    }
  }, [props.visibility]);

  const Hide = () => {
    dispatch(setShowUpdatedBot(false));
  }

  const clickPassword = () => {
    if ((document.getElementById("token") as HTMLInputElement).type === "text") {
      (document.getElementById("token") as HTMLInputElement).type = "password";
    } else {
      (document.getElementById("token") as HTMLInputElement).type = "text";
    }

    setShowPassword(!showPassword);
  };


  const getTokenChatbot = async () => {

    const headers = UserService.getHeaders()

    await AppRequesterConst.Get(
      '/company', { headers },
      (response: Object) => { },
      (data) => {
        setIsLoading(true)
        if (data.status === 200) {
          setTokenUserGpt(data.data.companies[0].token_chatgpt)
        }
        setIsLoading(false)
      },
      (error: Object) => { }, navigate, dispatch, setIsLoading, {}
    );
  }

  return (
    <EditTokenChatbot
      modalShow={modalShow}
      Hide={Hide}
      setTokenUserGpt={setTokenUserGpt}
      props={props}
      tokenUserGpt={tokenUserGpt}
      dispatch={dispatch}
      navigate={navigate}
      setIsLoading={setIsLoading}
      showPassword={showPassword}
      clickPassword={clickPassword}
    />
  );
}

export default EditTokenChatbotController;