//Imports do react
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

//Imports de estilos 
import './styles.scss';
import Styles from './styles.module.scss';

//Imports do bootstrap 
import { Container, Row, Col, Image, InputGroup, FormControl, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//Imports de componentes externos
import { Formik } from 'formik';

//Imports de componentes internos
import Loading from '../../components/loading/index'

//Imports de constantes
import ConstRouters from '../../routes/constsRouter'

//Imports das requisições
import { CompanyRequests } from '../../services/company/companyControler'

//Imports Controler
import { HomeControler } from './indexControler'
import ModalSelectLanguagueController from '../homeApp/components/modalSelectLanguage/indexController';
import { useTranslation } from 'react-i18next';

const HomeControlerConst = new HomeControler();
const CompanyRequestsConst = new CompanyRequests();


const HomeSubdomain = () => {
  const { t } = useTranslation();
  const [subdomain, setSubDomain] = useState("");
  const [showfeedback, setShowfeedback] = useState(false);
  const [validated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCustomFieldModalEdit, setShowCustomFieldModalEdit] = useState(false)
  const [language, setLanguage] = useState("")
  const handleCloseCustomFieldModalEdit = () => setShowCustomFieldModalEdit(false)
  const handleShowCustomFieldModalEdit = () => setShowCustomFieldModalEdit(true)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmitValidationSubdomain = () => {
    const body = {
      "subdomain": subdomain,
    };

    const headers = {
      "Content-Type": "application/json; charset=utf-8"
    };

    CompanyRequestsConst.CompanySubdomainValidation(
      body,
      { headers },
      (response: Object) => {
        return response
      },
      (data: Object) => {
        return data
      },
      (error: Object) => {
        setShowfeedback(true)
        document.getElementById("feedbackText").innerHTML = "Subdomínio não encontrado";
      }, navigate, dispatch, setIsLoading,
    );
  }

  const [isHovered, setHovered] = useState(false);
  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  return (
    <Container fluid>
      <Loading visibility={isLoading}></Loading>
      <Row style={{ height: '100vh' }} className='d-flex'>
        <Col className={Styles.backgroundImage}>
          <Row style={{ height: '100%' }}>
            <div style={{ position: 'absolute', width: "fit-content", padding: '0' }}>
              <img src="/Logo_Branca.svg" alt="" width={'90%'} />
            </div>
            <Col md={4} xl={4} className="mt-5 pt-5">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.181 367.946">
                <g id="Grupo_11233" data-name="Grupo 11233" transform="translate(0 -178.643)">
                  <path id="Caminho_5464" data-name="Caminho 5464" d="M489.308,400.5H445.143l.023-2.157.069-2.145.114-2.134.159-2.122.2-2.109.247-2.1.29-2.082.333-2.068.375-2.054.417-2.039.459-2.023.5-2.008.54-1.991.58-1.974.62-1.957.659-1.939.7-1.921.736-1.9.774-1.883.811-1.864.848-1.844.884-1.823.92-1.8.955-1.781.99-1.759,1.025-1.736,1.059-1.713,1.093-1.69,1.126-1.666,1.158-1.642,1.19-1.617,1.222-1.592,1.253-1.566,1.284-1.54,1.315-1.514,1.344-1.487,1.374-1.459,1.4-1.431,1.431-1.4,1.459-1.374,1.487-1.344,1.514-1.315,1.54-1.284,1.566-1.253,1.592-1.222,1.617-1.19,1.642-1.158,1.666-1.126,1.69-1.093,1.713-1.059,1.736-1.025,1.759-.99,1.781-.956,1.8-.92,1.823-.884,1.844-.848,1.864-.811,1.883-.774,1.9-.736,1.921-.7,1.939-.659,1.957-.62,1.974-.58,1.991-.54,2.008-.5,2.023-.459,2.039-.417,2.054-.375,2.068-.333,2.082-.29,2.1-.247,2.109-.2,2.122-.159,2.134-.114,2.146-.069,2.157-.023v44.164l-2.16.042-2.139.124-2.117.2-2.092.284-2.067.361-2.039.438-2.011.512-1.98.585-1.949.657-1.915.727-1.88.8-1.844.862-1.806.928-1.767.992-1.726,1.054-1.683,1.115-1.639,1.174-1.594,1.232-1.547,1.288-1.5,1.343-1.448,1.4-1.4,1.448-1.343,1.5-1.288,1.547-1.232,1.594-1.174,1.639L497.873,371l-1.054,1.726-.992,1.767L494.9,376.3l-.862,1.844-.8,1.88-.727,1.915-.657,1.949-.585,1.981-.512,2.011-.438,2.039-.361,2.067-.284,2.092-.2,2.117-.124,2.139-.042,2.16Z" transform="translate(-335.143 -122.674)" fill="#e8e8e8" />
                  <path id="Caminho_5467" data-name="Caminho 5467" d="M713.467,400.548l1.825-.034,1.823-.1,1.818-.168,1.81-.235,1.8-.3,1.788-.367,1.774-.433,1.756-.5,1.737-.562,1.715-.626,1.691-.688,1.664-.75,1.636-.811,1.6-.87,1.572-.929,1.536-.986,1.5-1.042,1.46-1.1,1.418-1.15,1.375-1.2,1.33-1.251,1.283-1.3,1.234-1.345,1.184-1.39,1.132-1.432,1.079-1.473L755.035,378l.967-1.548.91-1.583.851-1.615.791-1.646.73-1.673.667-1.7.6-1.723.541-1.744.476-1.762.411-1.779.345-1.793.279-1.8.212-1.813.146-1.82.078-1.824.015-1.217,0-.609L763,348.525l-.123-1.821-.19-1.816-.257-1.808-.323-1.8-.389-1.784-.454-1.768-.519-1.75-.583-1.73-.647-1.707-.709-1.682-.771-1.655-.831-1.626-.89-1.594-.948-1.56-1-1.524-1.06-1.486-1.114-1.446-1.167-1.4-1.218-1.36-1.267-1.314-1.314-1.267-1.36-1.218-1.4-1.167-1.446-1.114-1.486-1.06-1.524-1-1.56-.948-1.594-.89-1.626-.831-1.655-.77-1.682-.709-1.707-.647-1.73-.583-1.75-.519-1.768-.454-1.784-.389-1.8-.323-1.807-.257-1.816-.19-1.821-.123-1.825-.056-.609,0-.609,0-1.825.056-1.821.123-1.816.19-1.808.257-1.8.323-1.784.389-1.768.454-1.75.519-1.73.583-1.707.647-1.682.709-1.655.77-1.626.831-1.594.89-1.56.948-1.524,1-1.486,1.06-1.446,1.114-1.4,1.167-1.36,1.218-1.314,1.267-1.267,1.314-1.218,1.36-1.167,1.4-1.114,1.446-1.06,1.486-1,1.524-.948,1.56-.89,1.594-.831,1.626-.77,1.655-.709,1.682-.646,1.707-.583,1.73-.519,1.75-.454,1.768-.389,1.784-.323,1.8-.257,1.808-.19,1.816-.123,1.821-.056,1.825,0,.609,0,.609.056,1.825.123,1.821.19,1.816.257,1.808.323,1.8.389,1.784.454,1.768.519,1.75.583,1.73.646,1.707.709,1.682.77,1.655.831,1.626.89,1.594.948,1.56,1,1.524,1.06,1.486,1.114,1.446,1.167,1.4,1.218,1.36,1.267,1.314,1.314,1.267,1.36,1.218,1.4,1.167,1.446,1.114,1.486,1.06,1.524,1,1.56.948,1.594.89,1.626.831,1.655.77,1.682.709,1.707.646,1.73.583,1.75.519,1.768.454,1.784.389,1.8.323,1.808.257,1.816.19,1.821.123,1.825.056Z" transform="translate(-483.876 -39.748)" fill="#0c2461" />
                  <path id="Caminho_9548" data-name="Caminho 9548" d="M489.308,400.5H445.143l.023-2.157.069-2.145.114-2.134.159-2.122.2-2.109.247-2.1.29-2.082.333-2.068.375-2.054.417-2.039.459-2.023.5-2.008.54-1.991.58-1.974.62-1.957.659-1.939.7-1.921.736-1.9.774-1.883.811-1.864.848-1.844.884-1.823.92-1.8.955-1.781.99-1.759,1.025-1.736,1.059-1.713,1.093-1.69,1.126-1.666,1.158-1.642,1.19-1.617,1.222-1.592,1.253-1.566,1.284-1.54,1.315-1.514,1.344-1.487,1.374-1.459,1.4-1.431,1.431-1.4,1.459-1.374,1.487-1.344,1.514-1.315,1.54-1.284,1.566-1.253,1.592-1.222,1.617-1.19,1.642-1.158,1.666-1.126,1.69-1.093,1.713-1.059,1.736-1.025,1.759-.99,1.781-.956,1.8-.92,1.823-.884,1.844-.848,1.864-.811,1.883-.774,1.9-.736,1.921-.7,1.939-.659,1.957-.62,1.974-.58,1.991-.54,2.008-.5,2.023-.459,2.039-.417,2.054-.375,2.068-.333,2.082-.29,2.1-.247,2.109-.2,2.122-.159,2.134-.114,2.146-.069,2.157-.023v44.164l-2.16.042-2.139.124-2.117.2-2.092.284-2.067.361-2.039.438-2.011.512-1.98.585-1.949.657-1.915.727-1.88.8-1.844.862-1.806.928-1.767.992-1.726,1.054-1.683,1.115-1.639,1.174-1.594,1.232-1.547,1.288-1.5,1.343-1.448,1.4-1.4,1.448-1.343,1.5-1.288,1.547-1.232,1.594-1.174,1.639L497.873,371l-1.054,1.726-.992,1.767L494.9,376.3l-.862,1.844-.8,1.88-.727,1.915-.657,1.949-.585,1.981-.512,2.011-.438,2.039-.361,2.067-.284,2.092-.2,2.117-.124,2.139-.042,2.16Z" transform="matrix(-0.017, -1, 1, -0.017, -291.771, 996.923)" fill="#0c2461" />
                </g>
              </svg>
            </Col>
            <Col ></Col>
            <Col md={7} xl={7} className="justify-content-end nopadding">
              <Image src='../../../Image1.png' alt="Home" width='100%' style={{ minWidth: '320px' }} ></Image>
            </Col>
          </Row>
          <Row className="nopaddingWithMarginTop">
            <svg className="nopadding align-self-end" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 981 359.998">
              <g id="Grupo_11234" data-name="Grupo 11234" transform="translate(0 -720.002)">
                <path id="Caminho_9554" data-name="Caminho 9554" d="M489.308,400.5H445.143l.023-2.157.069-2.145.114-2.134.159-2.122.2-2.109.247-2.1.29-2.082.333-2.068.375-2.054.417-2.039.459-2.023.5-2.008.54-1.991.58-1.974.62-1.957.659-1.939.7-1.921.736-1.9.774-1.883.811-1.864.848-1.844.884-1.823.92-1.8.955-1.781.99-1.759,1.025-1.736,1.059-1.713,1.093-1.69,1.126-1.666,1.158-1.642,1.19-1.617,1.222-1.592,1.253-1.566,1.284-1.54,1.315-1.514,1.344-1.487,1.374-1.459,1.4-1.431,1.431-1.4,1.459-1.374,1.487-1.344,1.514-1.315,1.54-1.284,1.566-1.253,1.592-1.222,1.617-1.19,1.642-1.158,1.666-1.126,1.69-1.093,1.713-1.059,1.736-1.025,1.759-.99,1.781-.956,1.8-.92,1.823-.884,1.844-.848,1.864-.811,1.883-.774,1.9-.736,1.921-.7,1.939-.659,1.957-.62,1.974-.58,1.991-.54,2.008-.5,2.023-.459,2.039-.417,2.054-.375,2.068-.333,2.082-.29,2.1-.247,2.109-.2,2.122-.159,2.134-.114,2.146-.069,2.157-.023v44.164l-2.16.042-2.139.124-2.117.2-2.092.284-2.067.361-2.039.438-2.011.512-1.98.585-1.949.657-1.915.727-1.88.8-1.844.862-1.806.928-1.767.992-1.726,1.054-1.683,1.115-1.639,1.174-1.594,1.232-1.547,1.288-1.5,1.343-1.448,1.4-1.4,1.448-1.343,1.5-1.288,1.547-1.232,1.594-1.174,1.639L497.873,371l-1.054,1.726-.992,1.767L494.9,376.3l-.862,1.844-.8,1.88-.727,1.915-.657,1.949-.585,1.981-.512,2.011-.438,2.039-.361,2.067-.284,2.092-.2,2.117-.124,2.139-.042,2.16Z" transform="translate(154.658 679.326)" fill="#fff" />
                <path id="Caminho_9555" data-name="Caminho 9555" d="M713.467,400.548l-1.825-.034-1.823-.1L708,400.246l-1.81-.235-1.8-.3-1.788-.367-1.774-.433-1.756-.5-1.737-.562-1.715-.626-1.691-.688-1.664-.75-1.636-.811-1.6-.87-1.572-.929-1.536-.986-1.5-1.042-1.46-1.1-1.418-1.15-1.375-1.2-1.33-1.251-1.283-1.3-1.234-1.345-1.184-1.39L674,380.985l-1.079-1.473L671.9,378l-.967-1.548-.91-1.583-.851-1.615-.791-1.646-.73-1.673-.667-1.7-.6-1.723-.541-1.744-.476-1.762-.411-1.779-.345-1.793-.279-1.8-.212-1.813L663.97,354l-.078-1.824-.015-1.217,0-.609.056-1.825.123-1.821.19-1.816.257-1.808.323-1.8.389-1.784.454-1.768.519-1.75.583-1.73.647-1.707.709-1.682.771-1.655.831-1.626.89-1.594.948-1.56,1-1.524,1.06-1.486,1.114-1.446,1.167-1.4,1.218-1.36,1.267-1.314,1.314-1.267,1.36-1.218,1.4-1.167,1.446-1.114,1.486-1.06,1.524-1,1.56-.948,1.594-.89,1.626-.831,1.655-.77,1.682-.709,1.707-.647,1.73-.583,1.75-.519,1.768-.454,1.784-.389,1.8-.323,1.807-.257,1.816-.19,1.821-.123,1.825-.056.609,0,.609,0,1.825.056,1.821.123,1.816.19,1.808.257,1.8.323,1.784.389,1.768.454,1.75.519,1.73.583,1.707.647,1.682.709,1.655.77,1.626.831,1.594.89,1.56.948,1.524,1,1.486,1.06,1.446,1.114,1.4,1.167,1.36,1.218,1.314,1.267,1.267,1.314,1.218,1.36,1.167,1.4,1.114,1.446,1.06,1.486,1,1.524.948,1.56.89,1.594.831,1.626.77,1.655.709,1.682.646,1.707.583,1.73.519,1.75.454,1.768.389,1.784.323,1.8.257,1.808.19,1.816.123,1.821.056,1.825,0,.609,0,.609L763,353.391l-.123,1.821-.19,1.816-.257,1.808-.323,1.8-.389,1.784-.454,1.768-.519,1.75-.583,1.73-.646,1.707-.709,1.682-.77,1.655-.831,1.626-.89,1.594-.948,1.56-1,1.524L753.3,380.5l-1.114,1.446-1.167,1.4-1.218,1.36-1.267,1.314-1.314,1.267-1.36,1.218-1.4,1.167-1.446,1.114-1.486,1.06-1.524,1-1.56.948-1.594.89-1.626.831-1.655.77-1.682.709-1.707.646-1.73.583-1.75.519-1.768.454-1.784.389-1.8.323-1.808.257-1.816.19-1.821.123-1.825.056Z" transform="translate(154.658 679.326)" fill="#ff8119" />
                <path id="Caminho_9556" data-name="Caminho 9556" d="M654.38,400.674H555.074V301.368H654.38Z" transform="translate(154.658 679.326)" fill="#0c2461" />
                <path id="Caminho_9557" data-name="Caminho 9557" d="M826.342,400.614h-55.3V301.371h55.3Z" transform="translate(154.658 679.326)" fill="#0c2461" />
                <path id="Caminho_9545" data-name="Caminho 9545" d="M199.538,301.439v99.18l-2.157-.023-2.145-.069-2.134-.114-2.122-.159-2.109-.2-2.1-.247-2.082-.29-2.068-.333-2.054-.375-2.039-.417-2.023-.459-2.008-.5-1.991-.54-1.974-.58-1.957-.62-1.939-.659-1.921-.7-1.9-.736-1.883-.774-1.864-.811-1.844-.848-1.823-.884-1.8-.92-1.781-.956-1.759-.99-1.736-1.025-1.713-1.059-1.69-1.093-1.666-1.126-1.642-1.158L140,380.765l-1.592-1.222-1.566-1.253L135.3,377l-1.514-1.314-1.487-1.344-1.459-1.374-1.431-1.4-1.4-1.431-1.374-1.459-1.344-1.487-1.314-1.514-1.284-1.54-1.253-1.566-1.222-1.592-1.19-1.617-1.158-1.642-1.126-1.666-1.093-1.69-1.059-1.713-1.025-1.736-.991-1.759-.955-1.78-.92-1.8-.884-1.823-.848-1.844-.811-1.864-.774-1.883-.736-1.9-.7-1.921-.659-1.939-.62-1.957-.58-1.974-.54-1.991-.5-2.008-.459-2.023-.417-2.039-.375-2.054-.333-2.068-.29-2.082-.247-2.1-.2-2.109-.159-2.122-.114-2.134-.069-2.146-.023-2.157h99.179Z" transform="translate(154.658 679.326)" fill="#0c2461" />
                <path id="Caminho_9552" data-name="Caminho 9552" d="M210.15,301.245l99.178,99.18H210.15Z" transform="translate(154.658 679.326)" fill="#fff" />
                <path id="Caminho_9553" data-name="Caminho 9553" d="M363.223,400.5l-2.157-.023-2.146-.069-2.134-.114-2.121-.159-2.108-.2-2.1-.247-2.082-.29-2.067-.333-2.053-.375-2.038-.417-2.022-.459-2.006-.5-1.989-.54-1.973-.58-1.955-.62-1.937-.659-1.919-.7-1.9-.736-1.881-.774-1.861-.811-1.841-.848-1.82-.884-1.8-.92-1.778-.955-1.756-.99-1.733-1.025-1.71-1.059-1.687-1.092-1.663-1.126-1.639-1.158-1.614-1.19-1.589-1.222-1.563-1.253-1.537-1.284-1.51-1.314-1.483-1.344-1.456-1.374-1.428-1.4-1.4-1.431-1.37-1.459-1.341-1.487-1.311-1.514-1.281-1.54-1.25-1.566L284,360.863l-1.187-1.617-1.155-1.642-1.122-1.666-1.089-1.69-1.056-1.713L277.37,350.8l-.987-1.759-.953-1.781-.917-1.8-.881-1.823-.845-1.844-.808-1.864-.771-1.883-.734-1.9-.7-1.921-.657-1.939-.618-1.957-.578-1.974-.538-1.991-.5-2.008-.457-2.024-.416-2.039-.374-2.054-.332-2.069-.289-2.083-.246-2.1-.2-2.109-.158-2.122-.113-2.134-.068-2.146-.023-2.157H462.4l-.023,2.157-.069,2.146-.114,2.134-.159,2.122-.2,2.109-.247,2.1-.29,2.083-.333,2.069-.375,2.054-.417,2.039-.459,2.024-.5,2.008-.54,1.991-.58,1.974-.62,1.957-.659,1.939-.7,1.921-.736,1.9-.774,1.883-.811,1.864-.848,1.844-.884,1.823-.92,1.8-.955,1.781-.99,1.759-1.025,1.736-1.059,1.713-1.092,1.69L444.9,357.6l-1.158,1.642-1.19,1.617-1.222,1.592-1.253,1.566-1.284,1.54-1.314,1.514-1.344,1.487-1.374,1.459-1.4,1.431-1.431,1.4-1.459,1.374-1.487,1.344-1.514,1.314-1.54,1.284-1.566,1.253-1.592,1.222-1.617,1.19-1.642,1.158-1.666,1.126-1.69,1.092-1.713,1.059L412.7,387.3l-1.759.99-1.781.955-1.8.92-1.823.884-1.844.848-1.864.811-1.883.774-1.9.736-1.921.7-1.939.659-1.957.62-1.974.58-1.991.54-2.008.5-2.024.459-2.039.417-2.054.375-2.069.333-2.083.29-2.1.247-2.109.2-2.122.159-2.134.114-2.146.069-2.157.023Z" transform="translate(154.658 679.326)" fill="#ff8119" />
                <path id="Caminho_9549" data-name="Caminho 9549" d="M713.467,400.548l-1.825-.034-1.823-.1L708,400.246l-1.81-.235-1.8-.3-1.788-.367-1.774-.433-1.756-.5-1.737-.562-1.715-.626-1.691-.688-1.664-.75-1.636-.811-1.6-.87-1.572-.929-1.536-.986-1.5-1.042-1.46-1.1-1.418-1.15-1.375-1.2-1.33-1.251-1.283-1.3-1.234-1.345-1.184-1.39L674,380.985l-1.079-1.473L671.9,378l-.967-1.548-.91-1.583-.851-1.615-.791-1.646-.73-1.673-.667-1.7-.6-1.723-.541-1.744-.476-1.762-.411-1.779-.345-1.793-.279-1.8-.212-1.813L663.97,354l-.078-1.824-.015-1.217,0-.609.056-1.825.123-1.821.19-1.816.257-1.808.323-1.8.389-1.784.454-1.768.519-1.75.583-1.73.647-1.707.709-1.682.771-1.655.831-1.626.89-1.594.948-1.56,1-1.524,1.06-1.486,1.114-1.446,1.167-1.4,1.218-1.36,1.267-1.314,1.314-1.267,1.36-1.218,1.4-1.167,1.446-1.114,1.486-1.06,1.524-1,1.56-.948,1.594-.89,1.626-.831,1.655-.77,1.682-.709,1.707-.647,1.73-.583,1.75-.519,1.768-.454,1.784-.389,1.8-.323,1.807-.257,1.816-.19,1.821-.123,1.825-.056.609,0,.609,0,1.825.056,1.821.123,1.816.19,1.808.257,1.8.323,1.784.389,1.768.454,1.75.519,1.73.583,1.707.647,1.682.709,1.655.77,1.626.831,1.594.89,1.56.948,1.524,1,1.486,1.06,1.446,1.114,1.4,1.167,1.36,1.218,1.314,1.267,1.267,1.314,1.218,1.36,1.167,1.4,1.114,1.446,1.06,1.486,1,1.524.948,1.56.89,1.594.831,1.626.77,1.655.709,1.682.646,1.707.583,1.73.519,1.75.454,1.768.389,1.784.323,1.8.257,1.808.19,1.816.123,1.821.056,1.825,0,.609,0,.609L763,353.391l-.123,1.821-.19,1.816-.257,1.808-.323,1.8-.389,1.784-.454,1.768-.519,1.75-.583,1.73-.646,1.707-.709,1.682-.77,1.655-.831,1.626-.89,1.594-.948,1.56-1,1.524L753.3,380.5l-1.114,1.446-1.167,1.4-1.218,1.36-1.267,1.314-1.314,1.267-1.36,1.218-1.4,1.167-1.446,1.114-1.486,1.06-1.524,1-1.56.948-1.594.89-1.626.831-1.655.77-1.682.709-1.707.646-1.73.583-1.75.519-1.768.454-1.784.389-1.8.323-1.808.257-1.816.19-1.821.123-1.825.056Z" transform="translate(-579.31 679.326)" fill="#ff8119" />
                <path id="Caminho_9550" data-name="Caminho 9550" d="M630.145,400.674h-75.07V301.368h75.07Z" transform="translate(-555.074 679.326)" fill="#0c2461" />
                <path id="Caminho_9551" data-name="Caminho 9551" d="M826.342,400.614h-55.3V301.371h55.3Z" transform="translate(-579.31 679.326)" fill="#fff" />
                <path id="Caminho_9546" data-name="Caminho 9546" d="M0,99.18,99.178,0H0Z" transform="translate(255.017 980.571) rotate(-90)" fill="#ff8119" />
              </g>
            </svg>
          </Row>
        </Col>
        <Col>
          <Row className={Styles.backgroundLogin} >
            <Row className="d-flex justify-content-center align-self-end ">
            </Row>
            <Row style={{ height: '36%', paddingLeft: '3%' }} className=" d-flex justify-content-center my-5">
              <Col sm={6} md={6} className="boxInputsStyles" >
                <Row className={Styles.textFontBlue + " mt-5 mb-4 mx-2 d-flex flex-row justify-content-between"} >
                  <p style={{ whiteSpace: 'nowrap', width:'70%' }} className='d-flex align-items-center mb-0'>{t("login.title_login_company")}</p>
                  <Col md={2} className="align-self-center" style={{ color: '#707070' }}>
                    <ModalSelectLanguagueController isLogged={false} />
                  </Col>
                </Row>
                <Formik
                  initialValues={{ subdomain: '' }}
                  validate={
                    values => {
                      setSubDomain(values.subdomain);
                      return HomeControlerConst.ValidationInputSubdomain(values, () => { setShowfeedback(true) }, () => { setShowfeedback(false) })
                    }
                  }
                  onSubmit={onSubmitValidationSubdomain}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,

                  }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={validated} >
                      <Row className={Styles.textFontGlay + " mx-2"}>
                        <Form.Label htmlFor="basic-url">{t("login.subdomain_login")}</Form.Label>
                        <InputGroup className="mb-3">
                          <FormControl
                            isInvalid={showfeedback}
                            className="form-control-Default"
                            placeholder="Subdominio"
                            aria-label="subdominio"
                            name="subdomain"
                            aria-describedby="basic-addon2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.subdomain}
                          />
                          <InputGroup.Text id="basic-addon2" style={{ fontWeight: "bold", borderTopRightRadius: "4px", borderBottomRightRadius: "4px" }}>.app.cxpress.io</InputGroup.Text>
                          <Form.Control.Feedback type="invalid" id="feedbackText">
                            {errors.subdomain}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Row>
                      <Row className="mx-2">
                        <Col className="d-flex justify-content-end">
                          <Link
                            to={{
                              pathname: ConstRouters.routers.forgoturl.path,
                            }}
                            style={{ textDecoration: 'none' }}
                          >
                            <p className={Styles.LinkGlay}>
                              {t("login.forgot_url")}
                            </p>
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mb-4 mx-3" >
                        <Button type="submit" className="buttonBlue" style={{ height: '5.5vh' }}>
                          {t('login.button_login')}
                        </Button>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Row >
                <Col className="d-flex justify-content-center my-4">
                  <Row className="d-flex justify-content-center my-4 mx-2" >
                  </Row>
                  <p ><span className={Styles.textFontBlue2}>{t("login.new_cx")} </span>
                    <Link style={{ width: '100%', textDecoration: 'none' }} className=""
                      to={{
                        pathname: ConstRouters.routers.register.path,
                      }}
                    >
                      <span className={Styles.textFontBlue3}>{t("login.register_label")}</span>
                    </Link>
                  </p>
                </Col>
              </Row>
            </Row>
          </Row>
          <Row className={Styles.backgroundLogin2}>
            <Col className="d-flex justify-content-center">
              <p className="align-self-center nopadding">{t("login.all_rights_reserved")}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container >
  );
}

export default HomeSubdomain;
