import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowAlertFeedback } from '../../store/internal';
import { AlertPositiveNegativeInterface } from './indexModel';
import AlertDisconnectSocket from './index';

const AlertDisconnectSocketController: FC<AlertPositiveNegativeInterface> = (props) => {
  const [visibility, setVisibility] = useState(props.visibility);
  const dispatch = useDispatch();

  const displayFunction = () => {
    if (typeof props.visibility === 'string') {
      return props.visibility;
    } else {
      return props.visibility ? 'block' : 'none';
    }
  }

  const refreshWindow = () => {
    dispatch(setShowAlertFeedback({ visibility: false, message: "", signalIcon: false }));
    setVisibility(false);
    window.location.reload();
  }

  useEffect(() => {
    setVisibility(props.visibility);
  }, [props.visibility]);

  return (
    <AlertDisconnectSocket 
      visibility={visibility}
      signalIcon={props.signalIcon}
      setVisibility={setVisibility}
      displayFunction={displayFunction}
      refreshWindow={refreshWindow}
    />
  );
}

export default AlertDisconnectSocketController;
