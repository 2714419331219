import { FC, useEffect, useState } from "react";
import AgentsExtension from ".";
import { AgentsExtensionControllerInterface, AgentsExtensionData, AgentsExtensionRequestInterface } from "./indexModel";
import UserService from "../../../../../../../../services/user-service";
import { AppRequesterController } from "../../../../../../../../services/appRequester/appRequesterController";
import { useDispatch, useSelector } from "react-redux";
import { getExtension } from "../../../../../../../../store/callcenter";
import { useTranslation } from "react-i18next";

const AppRequesterConst = new AppRequesterController();

const AgentsExtensionController: FC<AgentsExtensionControllerInterface> = ({
  callcenterContext,
  setScene,
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const userExtension = useSelector(getExtension);
  
  const [data, setData] = useState<AgentsExtensionData[]>([]);
  const [filteredLength, setFilteredLength] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAgentsExtension();
  }, []);

  const getFilteredData = () => {
    if (!searchTerm) {
      return data;
    } else {
      const filtered = data.filter(item => (
        item.extension?.includes(searchTerm?.toLowerCase()) || 
        item.employee_email?.toLocaleLowerCase()?.includes(searchTerm?.toLowerCase()) || 
        item.employee_name?.toLocaleLowerCase()?.includes(searchTerm?.toLowerCase())
      ));
      setFilteredLength(filtered.length);
      return filtered;
    }
  }

  const getAgentsExtension = async () => {
    const headers = UserService.getHeaders();

    const config = { headers };
    await AppRequesterConst.Get(
        'channel-vozxpress/extensions',
        config,
        (response: Object) => {
        },
        (response: AgentsExtensionRequestInterface) => {
          if (response.status === 200) { 
            const list = [];
            const extensions = response.data.extensions || response.data.extentions;

            extensions?.forEach((item, index) => {
              list.push({
                id: `agent-extension-${index}`,
                extension: item.extension,
                employee_name: item.employee?.name || t('phone_callcenter.main_screen.agents_extension.no_assigned_agent'),
                employee_email: item.employee?.email || undefined,
              });
            });

            setData(list);
            setFilteredLength(list.length);
          }
        },
        (error: Object) => {
        }, null, dispatch, setIsLoading, {}
    );
  }

  const selectExtension = (extension: string) => {
    callcenterContext.instance.setCallInfoDisplay(extension);
    callcenterContext.instance.startCall();
    setScene('keyboard');
  }

  return (
    <AgentsExtension 
      data={data}
      getFilteredData={getFilteredData}
      filteredLength={filteredLength}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      isLoading={isLoading}
      selectExtension={selectExtension}
      userExtension={userExtension}
      t={t}
    />
  );
}

export default AgentsExtensionController;