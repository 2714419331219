import { useEffect, useState } from "react";
import ModalManageTickets from "."
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getShowManageTicketsModal, setShowManageTicketsModal } from "../../../../store/internal";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";

const ModalManageTicketsController = ({ visibility, deleteAllSelected, resolveAllSelected, removeAllAssigned, deleteListTickets, setDeleteListTickets, callback, addAllTags }) => {

    const values: TokenInterface = {
        internal: {
            getShowManageTicketsModal: useSelector(getShowManageTicketsModal)
        }
    }

    useEffect(() => {
        if (values.internal.getShowManageTicketsModal) {
            dispatch(setShowManageTicketsModal(false))
        }
    }, [])

    useEffect(() => {
        setShow(visibility)
    }, [visibility])

    const { t } = useTranslation();
    const [show, setShow] = useState(visibility);
    const dispatch = useDispatch()

    const hide = () => {
        dispatch(setShowManageTicketsModal(false))
    }

    return <ModalManageTickets
        show={show}
        hide={hide}
        t={t}
        deleteAllSelected={deleteAllSelected}
        resolveAllSelected={resolveAllSelected}
        removeAllAssigned={removeAllAssigned}
        deleteListTickets={deleteListTickets}
        setDeleteListTickets={setDeleteListTickets}
        dispatch={dispatch}
        callback={callback}
        addAllTags={addAllTags}
    />
}

export default ModalManageTicketsController