import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Loading from '../../../components/loading/index'

import ListOfClientsController from './listOfClients/indexController';
import ButtonSearchCleanInputController from '../components/buttonSearchCleanInputComponent/indexController';
import FilterOptionComponentController from '../components/filterOptionComponent/indexController';
import { IconUserExclamation } from '@tabler/icons-react';

const HomeClients = ({
    isLoading,
    search,
    setCurrentFilterSearch,
    currentFilterSearch,
    filledCreatingTicket,
    setCurrentState,
    currentState,
    setCurrentPage,
    setAux,
    setCurrentParams,
    filteringTickets,
    cleanList,
    currentParams,
    currentPage,
    aux,
    defaultSearching,
    hasPermission
}) => {
    return (
        <Container fluid className="nopadding">
            <Loading visibility={isLoading}></Loading>
            <div className="wrapper">
                <div className="content pt-0 ps-0">
                    <Row className="nopadding mt-3 ps-3">
                        <Col md={5} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>Clientes</div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>Visualiza os clientes ativos, inativos e que estão em período gratuito</div>
                            </Row>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {
                                hasPermission &&
                                <ButtonSearchCleanInputController
                                    inputPlaceholder={"Buscar cliente"} 
                                    filteringTickets={(e, auxObj, action) => search(e, auxObj, action)} 
                                    setCurrentFilterSearch={setCurrentFilterSearch} 
                                    currentFilterSearch={currentFilterSearch}
                                    filledCreatingTicket={filledCreatingTicket}
                                    setCurrentState={setCurrentState}
                                    currentState={currentState}
                                    setCurrentPage={setCurrentPage}
                                    setAux={setAux}
                                    setCurrentParams={setCurrentParams}
                                />
                            }
                        </Col>
                    </Row>
                    {
                        hasPermission &&
                        <>
                            <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                                <ListGroup variant="flush" >
                                    <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                                    <FilterOptionComponentController
                                        optionNames={["Status", "Data"]}
                                        disableSubOptions={{ 'status': { "resolved": true } }}
                                        currentState={currentState}
                                        setCurrentPage={setCurrentPage}
                                        setCurrentState={setCurrentState}
                                        setCurrentFilterSearch={setCurrentFilterSearch}
                                        setCurrentParams={setCurrentParams}
                                        currentFilterSearch={currentFilterSearch}
                                        setAux={setAux}
                                        filteringTickets={filteringTickets}
                                        cleanList={cleanList}
                                    />
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                            <ListOfClientsController 
                                currentState={currentState} 
                                setCurrentState={setCurrentState}
                                currentParams={currentParams}
                                setCurrentParams={setCurrentParams}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setAux={setAux}
                                aux={aux}
                                defaultSearching={defaultSearching}
                                setCurrentFilterSearch={setCurrentFilterSearch}
                                />
                        </>
                    }
                    {
                        !hasPermission &&
                        <div className="nopadding mt-3 ps-3" 
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '50vh', color: '#0C2461', justifyContent: 'center' }}>
                            <IconUserExclamation size={48} />
                            <span style={{ maxWidth: '300px', fontSize: '22px', fontFamily: 'Montserrat'}}>Você não possui permissão para visualizar esta página</span>
                        </div>
                    }
                    <Row style={{ marginTop: '-25vh' }}>
                    </Row>
                </div>
            </div>
        </Container >
    );
}

export default HomeClients;
