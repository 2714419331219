import { FC, useEffect, useState } from "react";
import { ModalEmailConfirmationControllerInterface } from "./indexModel";
import ModalEmailConfirmation from ".";
import { useDispatch, useSelector } from "react-redux";
import { setShowAccountEmailConfirmationModal } from "../../../../../store/internal_controlpanel";
import { getToken } from "../../../../../store/control_panel";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { setShowAlertFeedback } from "../../../../../store/internal";
import { useNavigate } from "react-router-dom";

const AppRequesterConst = new AppRequesterController();

const ModalEmailConfirmationController: FC<ModalEmailConfirmationControllerInterface> = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector(getToken);

  useEffect(() => {
    if (!props.currentUser) {
      hide();
    }
  }, []);

  const hide = () => {
    dispatch(setShowAccountEmailConfirmationModal(false));
  }

  const submit = async () => {
    if (props.currentUser && !props.currentUser.email_confirmation_at) {
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${token}`
      };

      const body = {
        email: props.currentUser.email,
      };

      await AppRequesterConst.Post(
        '/panel/request-confirmation-panel', body, { headers },
        (response: Object) => { return response; },
        (data: any) => {
          if (data.status === 200) {
            dispatch(setShowAlertFeedback({ message: `Permissões do agente ${props.currentUser.email} atualizadas`, visibility: true, signalIcon: true }));
            if (props.callback) {
              props.callback();
            }
            hide();
          }
        },
        (error: { response: { status: number; data: { message: string }; }; }) => {
          if (error.response.data.message) {
            dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
          }
          else {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
          }
        }, navigate, dispatch, setIsLoading
      );
    }
  }

  return (
    <ModalEmailConfirmation 
      showModal={props.showModal}
      hide={hide}
      currentUser={props.currentUser}
      submit={submit}
      isLoading={isLoading}
    />
  );

}

export default ModalEmailConfirmationController;
