import { Formik } from "formik";
import { Modal, Button, Form, InputGroup, FormControl, Col, Row, FormLabel } from 'react-bootstrap';
import styles from "../styles";
import { useEffect, useState } from "react";
import Loading from "../../../../../components/loading";
import SelectComponent from "../../selectComponent";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenInterfaceControlPanel } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/token";
import { setShowAlertFeedback } from "../../../../../store/internal";
import axios from "axios";
import constsRouters from "../../../../../routes/constsRouter";

const ModalChangeUser = ({ 
  show, 
  onHide, 
  listEmails,
  setListEmails,
  selectEmail,
  setSelectEmail,
  onSelectEmail,
  setOnSelectEmail,
  step,
  setStep,
  isLoading,
  state,
  setEmail,
  showFeedbackEmail,
  setShowFeedbackEmail,
  submitChanges,
  searchForEmail,
  onSetSelectEmail,
  selectedSubdomain,
  setSelectedSubdomain,
  selectedEmailData
}) => {

  return (
    <Modal show={show} onHide={onHide} centered>
      <Loading visibility={isLoading} top="0" left="0"></Loading>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Alterar conta principal</Modal.Title>      
      </Modal.Header>
      <Modal.Body className='mx-3 mb-2'>
        {
          step === 1 &&
          <Row>
            <Col>
            <label className="custom-label">E-mail atual da conta proprietária no Vozxpress</label>
            <span className="custom-span mb-2">{state?.email_user_vx}</span>
            </Col>
          </Row>
        }
        
        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            let errors = {};

            setEmail(values.email)

            if (!values.email) {
              errors['email'] = 'Preencha o e-mail ou nome do user corretamente'
              setShowFeedbackEmail(true)
            } else {
              setShowFeedbackEmail(false)
            }

            return errors

        }}
          onSubmit={() => {
            if(step === 2) {
              return submitChanges()
            } else {
              return searchForEmail()}
            }
          }
        >
          {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} noValidate validated={false}>
                <Row style={styles.textFontGlay} className="d-flex justify-content-left">
                    {listEmails.length > 0 ? (
                      <Col>
                        <Form.Group>
                        <FormLabel>
                          E-mail <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                        </FormLabel>
                        <SelectComponent
                            className={"multiselectForm mb-3"}
                            options={listEmails}
                            disableSearch={true}
                            setOptions={setListEmails}
                            selecteds={selectEmail}
                            setSelecteds={setSelectEmail}
                            singleSelect={true}
                            title={"Selecione o e-mail"}
                            overrideStrings={{
                                "allItemsAreSelected": selectedEmailData?.email || '',
                                "clearSearch": "Limpar pesquisa",
                                "clearSelected": "Limpar email selecionado",
                                "noOptions": "Sem email",
                                "search": "Pesquisar email",
                                "selectAll": "Selecionar todos os emails",
                                "selectAllFiltered": "Selecionar todos os emails (Filtrado)",
                                "selectSomeItems": "Selecione o email",
                                "create": "Criar"
                            }}
                            hasCreateItem={false}
                            hasNullOption={false}
                            onSelect={(value) => onSetSelectEmail(value)}
                            filtering={false}
                            hasAllOption={false}
                        />
                        </Form.Group>
                      </Col>
                    ) : 
                      step === 0 ? (
                      <Row>
                        <Col>
                          Ao alterar a conta principal, serão exibidos, na listagem <b>Atendentes do VX</b>, todos os Atendentes do VX associados a essa conta. Continuar?
                        </Col>
                      </Row>
                    
                    ) 
                    :
                    (  
                      <Col>
                        <Form.Label htmlFor="domain" className='mb-0'>
                          Digite o e-mail ou nome do usuário no Voz que irá ser associado
                        </Form.Label>
                        <InputGroup>
                            <FormControl
                                className="form-control-Default mt-2"
                                placeholder="Informe o Email"
                                aria-label="Informe o Email"
                                aria-describedby="Informe o Email"
                                isInvalid={showFeedbackEmail}
                                size="lg"
                                type="email"
                                id="email"
                                name="email"
                                maxLength={50}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></FormControl>
                            <Form.Control.Feedback type="invalid" id="feedbackdomain">
                                {errors['email']}
                            </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    )}
                </Row>
                { listEmails.length > 0 &&
                  <>
                  <Row className="mb-2">
                  {
                    onSelectEmail.length > 0 && 
                    <Col xs={5}>
                      <label className="custom-label">Subdomínio VX:</label>
                      <span className="custom-span mb-2">{selectedEmailData?.subdomain}</span>
                    </Col>
                  }
                    <Col xs={5}>
                      <label className="custom-label">Subdomínio CX:</label>
                      <span className="custom-span mb-2">{state?.subdomain}</span>
                    </Col>
                  </Row>
                  <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                    <Col>
                      <Form.Label htmlFor="basic-url">Selecione o subdomínio &nbsp; &nbsp;</Form.Label>
                    </Col>
                  </Row>
                  <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                    <Col>
                      <Form.Check name="hasBot" label={'Não alterar subdomínios'} type={'radio'} checked={selectedSubdomain === 'nothing changes'} onChange={() => {setSelectedSubdomain('nothing changes')} } inline/>
                      <Form.Check name="hasBot" label={'Utilizar o subdomínio do CX'} type={'radio'} checked={selectedSubdomain === 'subdomain cx'} onChange={() => {setSelectedSubdomain('subdomain cx')} } inline/>
                      <Form.Check name="hasBot" label={'Utilizar o subdomínio do VX'} type={'radio'} checked={selectedSubdomain === 'subdomain vx'} onChange={() => {setSelectedSubdomain('subdomain vx')} } inline/>
                    </Col>
                  </Row>
                  </>
                }
                <Row className="d-flex justify-content-center mt-3">
                    <Col xs={6} className="d-flex justify-content-start">
                        <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={onHide} >Cancelar</Button>
                    </Col>
                    {step === 0 ? (
                      <Col xs={6} className="d-flex justify-content-end">
                        <Button size='lg' onClick={() => setStep(1)} className="buttonBlue" style={{ width: '56%' }}>Confirmar</Button>
                      </Col>
                    ) : listEmails.length > 0 ? (
                      <Col xs={6} className="d-flex justify-content-end">
                        <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                      </Col>
                    ) : (
                      <Col xs={6} className="d-flex justify-content-end">
                        <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Pesquisar</Button>
                      </Col>
                    )}
                </Row>
            </Form>
          )}

        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalChangeUser;