import { useEffect, useState } from 'react'
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
  Tab,
  Tabs
} from "react-bootstrap"
import Loading from '../../../../components/loading'
import allStyles from "./styles"
import ModalRegisterVozxpress from '../modalRegisterVozxpress'
import ModalChangeUser from './modalChangeUser/indexController'
import MoreInfo from './moreInfo/indexController';
import IntegraAccount from './integrateAccount/indexController';
import ListConfigVozxpress from './listConfigVozxpress'
import ListConfigAccounts from './listConfigAccounts/indexController'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel'
import { getToken } from '../../../../store/token'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'

const AppRequesterConst = new AppRequesterController();

const FormConfigVozxpress = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { state } = useLocation()
  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  const [agents, setAgents] = useState([]);
  const [accountsVx, setAccountsVx] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [moreInfo, setMoreInfo] = useState({});
 
  const [showModalRegister, setShowModalRegister] = useState(false);
  const handleOpenCloseModal = () => {
    setShowModalRegister(!showModalRegister);
  };
  const [showModalChangeUser, setShowModalChangeUser] = useState(false);
  const handleOpenCloseModalChange = () => {
    setShowModalChangeUser(!showModalChangeUser);
    getAgentsWithAccount();
  };

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const handleOpenCloseMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  }

  const [showIntegrateAccount, setShowIntegrateAccount] = useState(false);
  const handleOpenCloseIntegrateAccount = () => {
    setShowIntegrateAccount(!showIntegrateAccount);
  }

  useEffect(() => {
    getAgentsWithAccount();
    getAccountsVx();

  },[props?.new_data, setAgents])
    

  const getAgentsWithAccount = async () => {
    setAgents([]);
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Get(
    `/panel/vozxpress/account/${state.id}/employees-accounts`, { headers },
    (response: Object) => {},
    (data: any) => {
      if(data.status === 200) {
      setAgents([])
      let agents = data.data.employees
      .sort((a, b) => {
          const nameA = a.name ? a.name.toLowerCase() : 'n/a';
          const nameB = b.name ? b.name.toLowerCase() : 'n/a';
          return nameA.localeCompare(nameB);
      });
      setAgents(agents)
      }        
    },
      (error: { response: { status: number } }) => {
      if (error.response?.status === 400) {

      }
      if (error.response?.status === 401) {

      }
      }, navigate, dispatch, setIsLoading, { }
    )
  }

  const getAccountsVx = async () => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Get(
      `/panel/vozxpress/list-account-VX/${state?.user_id_vx}?company_id=${state.id}`, { headers },
      (response: Object) => {},
      (data: any) => {
      if(data.status === 200) {
        setAccountsVx([])
        let accounts = data.data.accountsVX.filter(i => !i.integrated);
        setAccountsVx(accounts)
      }
                
      },
      (error: { response: { status: number } }) => {
        if (error.response?.status === 400) {

        }
        if (error.response?.status === 401) {

        }
      }, navigate, dispatch, setIsLoading, { }
    )
  }

  return (
    <div>
      <Loading left="0" visibility={isLoading}></Loading>
      <Row className='mt-1 noppadding' style={{ display: "flex", alignItems: "center" }}>
        <Col style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: '#0C2461', opacity: '1', display: 'flex', alignItems: 'center' }} md={5} className='mt-2'>
          <div style={{ paddingRight: "20px", color: "black" }}>
            Configuração do VozXpress
          </div>
        </Col>
      </Row>
      <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
      <Row style={allStyles.textFontGlay} className="d-flex justify-content-flex-start">
        <Col sm={true} md='3'>
          <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
            { props.isEditAllowed &&
              <OverlayTrigger
                key='top'
                placement='top'
                overlay={
                  <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                    Alterar user Vozxpress
                  </Tooltip>
                }
              >
                <Button size="lg" className='buttonWhite' style={{ width: '100%', marginTop: '1.5vh' }} onClick={() => handleOpenCloseModalChange()}>Alterar user Vozxpress</Button>
              </OverlayTrigger>            
            }
          </p>
        </Col>
      </Row>
        <ModalRegisterVozxpress
          show={showModalRegister}
          handleClose={handleOpenCloseModal}
          callback={props.updateListAfterDelete}
        />
        <ModalChangeUser  
          show={showModalChangeUser} 
          onHide={handleOpenCloseModalChange}
        />
        <MoreInfo 
          show={showMoreInfo}
          onHide={handleOpenCloseMoreInfo}
          moreInfo={moreInfo}
        />
        <IntegraAccount 
          show={showIntegrateAccount}
          onHide={handleOpenCloseIntegrateAccount}
          accountsVx={accountsVx}
          moreInfo={moreInfo} 
          getAccountsVx={getAccountsVx}
          getAgentsWithAccount={getAgentsWithAccount}
        />
        <Tabs>
          <Tab
            eventKey={'agentes'}
            title={'Agentes do CX'}
            className='ps-0 ms-0'
          >
            <ListConfigVozxpress 
              showIntegrateAccount={showIntegrateAccount}
              setShowIntegrateAccount={setShowIntegrateAccount}
              setMoreInfo={setMoreInfo} 
              setShowMoreInfo={setShowMoreInfo} 
              handleOpenCloseMoreInfo={handleOpenCloseMoreInfo} 
              datas={agents} 
              updateListAfterDelete={getAgentsWithAccount} 
              getAccountsVx={getAccountsVx}
              HasMoreInformation={false} 
              isEditAllowed={props.isEditAllowed} 
              getAgentsWithAccount={getAgentsWithAccount} 
              accountsVx={accountsVx}
            />
          </Tab>
          <Tab
            eventKey={'accounts'}
            title={'Atendentes do VX'}
            className='ps-0 ms-0'
          >
            <ListConfigAccounts 
              setMoreInfo={setMoreInfo} 
              setShowMoreInfo={setShowMoreInfo} 
              handleOpenCloseMoreInfo={handleOpenCloseMoreInfo}
              list={accountsVx} 
              isEditAllowed={props.isEditAllowed} 
              getAccountsVx={getAccountsVx} 
              getAgentsWithAccount={getAgentsWithAccount}
           />
          </Tab>
        </Tabs>
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%' }}></div>
    </div>

  )
}

export default FormConfigVozxpress