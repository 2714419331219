import { Outlet } from "react-router";
import SidebarAppController from "./components/sidebarAppComponent/indexController";

const HomeApp = () => {
    
    return (
        <div style={{ display: "flex" }}>
            <SidebarAppController />
            <Outlet/>
        </div>
    )
}

export default HomeApp;