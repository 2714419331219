import React, { FC, useEffect, useState } from "react";
import { IListOfIntegrationsController } from "./indexModel";
import ListOfIntegrations from ".";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../../../../../core/shared/utils";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { getIdUser, getNameUser, getProfileId } from "../../../../../../store/user";
import { getToken } from "../../../../../../store/token";
import { setShowAlertFeedback, setShowConfirmationModal, setShowEditIntegrationModal } from "../../../../../../store/internal";
import UserService from "../../../../../../services/user-service";
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router";
import { async } from "q";
import { Button, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ListOfIntegrationsController: FC<IListOfIntegrationsController> = (props) => {
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();
    const navigate = useNavigate();

    const [currentIntegrationName, setCurrentIntegrationName] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    // const [currentIntegrationId, setCurrentIntegrationId] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation()

    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
    }

    useEffect(() => {
        Utils.changeActiveScrollBar("ListOfIntegrations");
    }, [props.integrations]);

    const enableDepartmentConfirmation = async () => {

        /* const headers = UserService.getHeaders()

        let JsonSend = {
            "id": currentAgentData.sector_id,
            "name": currentAgentData.sector_name,
            "active": true
        }

        await appRequester.Put(
            '/sector', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: "Setor ativado com sucesso!", visibility: true, signalIcon: true }));
                props.updateListAfterUpdateDepartment();
            },
            (error: { response: { status: number; }; }) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading
        ); */
    }

    const disableDepartmentConfirmation = async () => {

        /* const headers = UserService.getHeaders()

        let JsonSend = {
            "id": currentAgentData.sector_id,
            "name": currentAgentData.sector_name,
            "active": false
        }

        await appRequester.Put(
            '/sector', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: "Setor inativado com sucesso!", visibility: true, signalIcon: true }));
                props.updateListAfterUpdateDepartment();
            },
            (error: { response: { status: number; }; }) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading
        ); */
    }

    const setCurrentIntegration = (e) => {
        setCurrentIntegrationName(e.currentTarget.name);
        props.setCurrentIntegrationId(e.currentTarget.id.split("*ID*")[1]);
    }

    const deleteIntegrationConfirmation = () => {
        dispatch(setShowConfirmationModal({
            visibility: true, text: { "title": t('home_departments.integration_tab.list_of_integration.delete_integration'), "body": t('home_departments.integration_tab.list_of_integration.delete_question'), "id": currentIntegrationName + '?', "buttonReturnText": t('home_departments.integration_tab.list_of_integration.cancel') },
            functionConfirmation: () => deleteIntegration()
        }));

        /* let aux = [];
        aux.push(this.currentTicketId.split("*ID*")[1]);
        this.setDeleteListTickets(aux); */
    }

    const deleteIntegration = async () => {
        props.setCurrentIntegrationId(async (integrationId) => {
            let headers = UserService.getHeaders();

            await AppRequesterConst.Delete(
                "/api-integration/" + integrationId, { headers: headers },
                (response: Object) => {},
                (data) => {
                    dispatch(setShowAlertFeedback({ message: t('home_departments.integration_tab.list_of_integration.delete_success'), visibility: true, signalIcon: true }));
                    props.callback();
                },
                (error: Object) => {
                    dispatch(setShowAlertFeedback({ message: t('home_departments.integration_tab.list_of_integration.unexpected_error'), visibility: true, signalIcon: false }));
                }, navigate, dispatch, setIsLoading/* , { values: values } */
            )
            return integrationId;
        });
    }

    const showEditIntegrationModal = () => {
        dispatch(setShowEditIntegrationModal(true));
    }

    const popoverActions = (
        <Popover /* id={currentAgentData.sector_id}  */ style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={() => showEditIntegrationModal()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.integration_tab.list_of_integration.edit_integration')} </Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={deleteIntegrationConfirmation} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.integration_tab.list_of_integration.delete_integration')} </Button>
            </Popover.Body>
        </Popover>
    );
    
    const updateListFunction = () => {
        setCurrentPage(currentPage + 1);
    }

    return <ListOfIntegrations 
        setCurrentIntegration={setCurrentIntegration}
        updateListfunction={() => updateListFunction()}
        deleteIntegrationConfirmation={deleteIntegrationConfirmation}
        showEditIntegrationModal={showEditIntegrationModal}
        integrations={props.integrations}
        isLoading={isLoading}
        popoverActions={popoverActions}
        showCreateIntegrationModal={props.showCreateIntegrationModal}
        t={t}
    />
}

export default ListOfIntegrationsController;