import './styles.scss';
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { FC } from 'react';
import { ControlPanelHomeSecurityInterface } from './indexModel';

const ControlPanelSecurity: FC<ControlPanelHomeSecurityInterface> = (props) => {

  return (
    <>
      <Container fluid className="controlPanelSecurity nopadding">
        <div className="wrapper">
          <div className="content pt-0 ps-0">
            <Row className="nopadding mt-3 ps-3">
              <Col md={5} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                <Row>
                  <Col className="d-flex justify-content-start align-self-start">
                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>Segurança</div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>Edite suas configurações de segurança</div>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '2vh' }} className="nopadding mt-3 ps-3">
              <div className="security-section">
                <span className="section-title">Aplicativo multifator</span>
                <div className="section-content">
                  { props.hasAppAuth && // Possui app multifator
                    <>
                      <Row className="nopadding">
                        <Button className='btn-remove-config' onClick={() => props.openRemoveConfirmation()}>Remover configuração</Button>
                      </Row>
                    </>
                  }
                  { // Não possui app multifator
                    !props.hasAppAuth &&
                    <>
                      <Formik
                        initialValues={{ code_2fa: '' }}
                        validate={props.validate2FA}
                        onSubmit={props.submitVerify2FA}
                      >
                        {formik => (
                          <Form className="section-form" onSubmit={formik.handleSubmit}>
                            <Row style={{ height: 'fit-content' }} className=" d-flex">
                              <Row className="mb-2 mx-2">
                                <div className='content-step'>
                                  <span className='step'>1. Baixe seu aplicativo de autenticação preferido (Authy, Google Authenticator, Microsoft Authenticator, etc)</span>
                                </div>
                                <div className='content-step'>
                                  <span className='step'>2. Escaneie o código abaixo com o seu aplicativo</span> 
                                  { props.isLoadingQR && <img className='qrcode' src="/assets/chart-loading.png" alt="qr code" /> }
                                  <img className='qrcode' style={{display: props.isLoadingQR ? 'none' : 'block'}} src={props.imageQR} alt="qr code" onLoad={props.onLoadQR}/>
                                  <span className='secret'>Código: {props.secret || '...'}</span>
                                </div>
                                <div className='content-step'>
                                  <span className='step'>3. Digite o código de verificação gerado no seu aplicativo</span>
                                </div>
                              </Row>
                              <Row className="mx-2 font-gray">
                                <InputGroup className="mb-3" style={{ position: 'relative' }}>
                                  <FormControl className="form-control-Default input-code"
                                    isInvalid={props.showFeedback2FA}
                                    placeholder="Código de 6 dígitos no aplicativo"
                                    aria-label="Código de 6 dígitos no aplicativo"
                                    aria-describedby="Código de 6 dígitos no aplicativo"
                                    size="lg"
                                    type="text"
                                    id="user_code_2fa"
                                    name="code_2fa"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.code_2fa || ''}
                                  >
                                  </FormControl>
                                  <Form.Control.Feedback className='error-code' type="invalid" id="feedback_code_2fa">
                                    {formik.errors.code_2fa}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Row>
                              <Row className="section-action mt-3">
                                <Button type="submit" className="buttonBlue btn-save-config" disabled={props.showFeedback2FA || !formik.values.code_2fa}>
                                  Verificar e salvar
                                </Button>
                              </Row>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </>
                  }
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ControlPanelSecurity;
