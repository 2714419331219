type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

const styles = StyleSheet.create({
  divStep: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1vh',
    color: '#0C2461',
    fontFamily: 'Montserrat',
  },
  spanStep: {
    fontWeight: '600',
  },
  imgStyle: {
    width: 166,
    height: 166,
    marginTop: '1vh',
    marginBottom: '1vh',
    marginLeft: '2vh'
  },
  spanSecret: {
    marginLeft: '2vh',
    fontSize: '15px'
  },
  buttonSave: {
    marginLeft: '2vh',
    marginTop: '2vh',
    backgroundColor: '#2C4CA2',
    borderRadius: 3,
    color: '#fff',
    width: 'fit-content',
    outline: 'none',
    border: 'none',
    fontWeight: 400,
    padding: '0.8vh 2vh',
    fontFamily: 'Montserrat',
  },
  inputCode: {
    marginLeft: '2vh',
    maxWidth: '210px'
  },
  errorCode: {
    position: 'absolute', 
    bottom: '-20px',
    marginLeft: '2vh',
  },
  buttonbRemove: {
    backgroundColor: '#FFF',
    marginTop: '3vh',
    borderRadius: 3,
    color: '#FF0000',
    width: 'fit-content',
    outline: 'none',
    border: '1px solid #FF0000',
    fontWeight: 400,
    padding: '0.8vh 2vh',
    fontFamily: 'Montserrat',
  },
  textFontGlay: {
    textAlign: 'left',
    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#707070',
    opacity: '1',
  },
});

export default styles;
