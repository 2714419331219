import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

const MultifactorTab = ({
  isLoading,
  t,
  allowedAuthentications,
  availableAuth,
  getAuthStatus,
  changeStatus
}) => {

  return (
    <>
      <Row className="" id="securityMFA" style={{ marginTop: '1vh', padding: 0 }}>
        <Col>
          <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '2vh', marginBottom: '1vh' }}>
            {t('configurations.security.tab_mfa.content_title')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ width: '740px', marginTop: '-0.5vh' }}>
          <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>
            {t('configurations.security.tab_mfa.content_description')}
          </p>
        </Col>
        {
          availableAuth ?
          availableAuth.filter(item => allowedAuthentications.includes(item.name)).map(item => (
            <div className="switch-input" key={item.id}>
              <span>{t(`configurations.security.${item.name}`)}</span>
              { 
                <OverlayTrigger
                  key='top'
                  placement="top"
                  overlay={
                    <Tooltip id="tootip" className="tooltip-arrow-Top">
                      {getAuthStatus(item.id) ? t('configurations.security.check_active') : t('configurations.security.check_inactive')}
                    </Tooltip>
                  }
                >
                <label className="switch">
                  <input 
                    disabled={isLoading}
                    type="checkbox" 
                    checked={getAuthStatus(item.id) || false} 
                    onChange={() => changeStatus(!getAuthStatus(item.id), item.id)} 
                    />
                  <span className="slider round"></span>
                </label>
                </OverlayTrigger>
              }
            </div>
          ))
          :
          <></>
        }
      </Row>
    </>
  );

}

export default MultifactorTab;