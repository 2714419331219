import React, { FC, useEffect, useState } from "react";
import IntegrationTab from ".";
import { IIntegrationTabController } from "./indexModel";
import { useDispatch, useSelector } from "react-redux";
import { setShowCreateIntegrationModal } from "../../../../../store/internal";
import BackendConstants from "../../../../../core/constants/backend-constants";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import UserService from "../../../../../services/user-service";
import { useNavigate } from "react-router";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/token";
import { useTranslation } from "react-i18next";

const IntegrationTabController: FC<IIntegrationTabController> = (props) => {
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();
    const navigate = useNavigate();

    const [currentIntegrationId, setCurrentIntegrationId] = useState(null);
    const [integrations, setIntegrations] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentState, setcurrentState] = useState("");
    const [currentLimit] = useState(BackendConstants.limit);
    const [currentParams, SetCurrentParams] = useState({});

    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false);

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        let controller = new AbortController();
        setIntegrations([]);

        setcurrentState((state) => {
            getAllIntegrations(controller);
            return "";
        });

        return () => {
            controller.abort();
        }
    }, [currentState]);

    const getAllIntegrations = async (controller: AbortController) => {
        let signal = controller.signal;
        let params = {};

        const headers = UserService.getHeaders();
        const config = { headers, params, signal };

        await AppRequesterConst.Get(
            "/api-integration",
            config,
            (response: Object) => {},
            (data) => {
                if ((data.status === 200 || data.status === 204) && data.data.data.length > 0) {
                    setIntegrations(data.data.data);
                }
            },
            (error: Object) => {
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const callback = () => {
        setcurrentState("update");
    }

    const showCreateIntegrationModal = () => {
        dispatch(setShowCreateIntegrationModal(true));
    }

    return <IntegrationTab
        showCreateIntegrationModal={showCreateIntegrationModal}
        integrations={integrations}
        callback={callback} 
        setCurrentIntegrationId={setCurrentIntegrationId}   
        currentIntegrationId={currentIntegrationId} 
        isLoading={isLoading}
        t={t}
    />
}

export default IntegrationTabController; 