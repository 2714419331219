import { createSlice } from '@reduxjs/toolkit'

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState: {
    hasBot: false
  },
  reducers: {
    setChatbot: (state, action) => {
      state.hasBot = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {setChatbot} = chatbotSlice.actions

export const getChatbot = (state: { chatbot: {hasBot: boolean} }) => (state.chatbot?.hasBot);

export default chatbotSlice.reducer

