import React, { FC, useEffect, useState } from 'react';
import ModalRegisterDomain from '.';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
// import { getToken } from '../../../../../store/control_panel';
import { getShowRegisterDomainModal, setShowAlertFeedback, setShowRegisterDomainModal } from '../../../../../store/internal';
import { getToken } from '../../../../../store/token';
import { IModalRegisterDomainController } from './indexModel';

const ModalRegisterDomainController: FC<IModalRegisterDomainController> = (props) => {
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const AppRequesterConst = new AppRequesterController()  

    const [showDomainFeedback, setShowDomainFeedback] = useState(false); 

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }, 
        control_panel: {
            getShowRegisterDomainModal: useSelector(getShowRegisterDomainModal)
        }
    }

    useEffect(() => {
        if (values.control_panel.getShowRegisterDomainModal) {
            setShow(true);
        } else {
            setShow(false);
        }

    }, [values.control_panel.getShowRegisterDomainModal]);

    const hide = () => {
        dispatch(setShowRegisterDomainModal(false));
        clearFields();
    }

    const clearFields = () => {
        
    }

    const validate = (values) => {
        let errors = {};

        if (!values.domain || values.domain?.length === 0) {
            setShowDomainFeedback(true);
            errors['domain'] = "Domínio não pode estar vazio."
        } else {
            setShowDomainFeedback(false);
        }

        return errors;
    }

    const onPost = async (valuesForm) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + values.token.value,
        };

        valuesForm = Object.assign(valuesForm, { company_id: props.companyId });

        await AppRequesterConst.Post(
            '/channel-email/authenticate-domain', valuesForm, { headers },
            (response: Object) => {return response;},
            (data) => {
                dispatch(setShowAlertFeedback({ message: "Domínio cadastrado!", visibility: true, signalIcon: true }));
                // this.callbackModalCustomers(data.data.consumers[0].user_id);
                props.callbackUpdateList();
                hide();
            },
            (error: { response: { status: number; data: { message: string; }; }; }) => {
                if (error.response.status === 400) {
                    // dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } /* else if (error.response.status === 422) {
                    this.dispatch(setShowAlertFeedback({ message: "O campo de observações deve ter no máximo 100 caracteres.", visibility: true, signalIcon: false }));
                }  */else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, () => {}
        ).finally(() => {
        });
    }

    return <ModalRegisterDomain 
        show={show} 
        hide={hide}     
        onPost={onPost}   
        validate={validate}
        showDomainFeedback={showDomainFeedback}
    />
};

export default ModalRegisterDomainController;