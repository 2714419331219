import React from "react"

export class TicketNotifyService {
    private id;
    private clickNot: boolean

    public setID(id: string) {
        return this.id = id
    }

    public getId() {
        return this.id
    }

    public setClick(clickNot: boolean) {
        return this.clickNot = clickNot
    }

    public getClickNot() {
        return this.clickNot
    }
}

export const ticketNotify = new TicketNotifyService();

export const TicketNotifyContext = React.createContext(ticketNotify)