import React, { FC } from 'react';
import ImgsViewer from "react-images-viewer";
import { IImageViewer } from './indexModel';
import { Button, Col, Row } from 'react-bootstrap';
import './styles.css';

const ImageViewer: FC<IImageViewer> = (props) => {   

    const DownloadButton = () => {
        const downloadUrl = (e) => {
            var link = document.getElementById("download-image");
            link.click();
        }

        return (
            <>
                <a href={props.attachmentImages[props.currentImgViewer].url} hidden download={props.attachmentImages[props.currentImgViewer].filename} target='_blank' id="download-image"></a>
                <Button style={{ background: 'none', border: 'none' }} onClick={downloadUrl}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                        <path d="M7 11l5 5l5 -5" />
                        <path d="M12 4l0 12" />
                    </svg>
                </Button>
            </>
            
        );
    }

    return (
        <>
            {
                props.attachmentImages.map((attachment, index: number) => {
                    if (attachment === undefined)
                        return <></>;
                    else 
                        return <Col key={index} className="me-2 my-1 px-0" 
                            style={{ backgroundColor: 'white', borderRadius: '3px', // height: '120px', 
                                minWidth: '130px', maxWidth: '130px', cursor: 'pointer'
                            }} /* draggable download_url={attachment.url} */
                        >
                            <img src={attachment.url} /* draggable={false} */ onClick={() => props.onClickImage(index)} style={{ width: '100%', height: '84px', objectFit: 'cover' }} />
                            <Row className='mx-0'
                                onClick={() => props.onClickImage(index)} 
                                style={{
                                    backgroundColor: '#707070', color: '#F9F9F9',
                                    // height: '30%',
                                    height: '36px',
                                    borderRadius: '3px',
                                }} /* draggable download_url={attachment.url} */
                            >
                                <div className="" name="lastMessageTicket"
                                    style={{
                                        height: '35%',
                                        overflowY: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1',
                                        lineClamp: '1',
                                        WebkitBoxOrient: 'vertical',
                                        wordBreak: 'break-word',
                                        font: 'var( --px10_13-font-Roboto)'
                                    }}>
                                    {attachment.filename}
                                </div>
                                <div className="" name="lastMessageTicket"
                                    style={{
                                        height: '35%',
                                        overflowY: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1',
                                        lineClamp: '1',
                                        WebkitBoxOrient: 'vertical',
                                        wordBreak: 'break-word',
                                        font: 'var( --px10_13-font-Roboto)'
                                    }}>
                                    {attachment.type}
                                </div>
                            </Row>
                        </Col>

                })
            }
            { props.showImgViewer && 
                <div id="image-viewer" className='nopadding'>
                    <ImgsViewer 
                        imgs={props.attachmentImages.map((item) => { return { src: item.url } })}
                        isOpen={props.showImgViewer}
                        currImg={props.currentImgViewer}
                        onClickPrev={() => { 
                            if (props.currentImgViewer > 0)
                                props.setCurrentImgViewer(props.currentImgViewer - 1);
                        }}
                        onClickNext={() => {
                            if (props.currentImgViewer < props.attachmentImages.length - 1)
                                props.setCurrentImgViewer(props.currentImgViewer + 1);
                        }}
                        onClose={() => props.setShowImgViewer(false)}
                        showImgCount
                        showThumbnails
                        onClickThumbnail={index => props.setCurrentImgViewer(index)}
                        showCloseBtn
                        backdropCloseable
                        customControls={[
                            <DownloadButton />
                        ]}
                    />
                </div>
            }
        </>
    );
}

export default ImageViewer;