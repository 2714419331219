import React, { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux'


import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';


import { 
  setShowEditTriggerTypeModal, 
  setShowAlertFeedback 
} from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { ModalEditTriggerTypeInterface } from './indexModel';
import { ModalEditTriggerTypeController } from './indexController';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController();

const ModalEditTriggerType: FC<ModalEditTriggerTypeInterface> = (props) => {
  const {t} = useTranslation();
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [validated] = useState(false);
  
  const [name, setName] = useState(props.data.name);
  const [type_isActive, setType_isActive] = useState((props.data && props.data.active != null) ? props.data.active : true);
  
  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFeedbackName, setShowFeedbackName] = useState(false);

  const ModalEditTriggerTypeControllerConst = new ModalEditTriggerTypeController(dispatch, setShowEditTriggerTypeModal, setModalShow);

  useEffect(() => {
    setModalShow(props.visibility);
    setType_isActive((props.data && props.data.active != null) ? props.data.active : true);

    setShowFeedbackName(false)
  }, [props.visibility, props.data])
  
  return (
    <Modal show={modalShow} onHide={() => ModalEditTriggerTypeControllerConst.Hide()} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {t("home_message_trigger.list_of_trigger_types.edit_type")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{name: (props.data && props.data.name != null) ? props.data.name : '',  }}
            validate={values => {
              let errors = {};
              if (!values.name) {
                errors["name"] = t("home_message_trigger.modal_register_message_trigger.fill_in_field");
                  setShowFeedbackName(true);
              } else {
                  setShowFeedbackName(false);
              }

              setName(values.name)

              return errors;
            }}

            onSubmit={async () => {
              let JsonSend_sector = {
                "name": name,
                "active": type_isActive,
                "id": props.data.id
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await AppRequesterConst.Put(
                '/message-sending-type', JsonSend_sector, { headers },
                (response: Object) => {
                  return response;
                },
                (data: { data: Object; }) => {
                  dispatch(setShowAlertFeedback({ message: "Informações salvas com sucesso!", visibility: true, signalIcon: true }));
                  props.callback(data.data);
                  ModalEditTriggerTypeControllerConst.Hide();
                },
                (error: {response:{data:{code_cxpress}}}) => {
                  dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                },navigate,dispatch, setIsLoading,
                () => {},
                {error403: () => {
                  dispatch(setShowAlertFeedback({ message: "Nome de tipo já cadastrado!", visibility: true, signalIcon: false }));
                }}, 
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="name">{t("home_message_trigger.list_of_trigger_types.name")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={showFeedbackName}
                        placeholder={t("home_message_trigger.list_of_trigger_types.name_label")}
                        aria-label={t("home_message_trigger.list_of_trigger_types.name_label")}
                        aria-describedby={t("home_message_trigger.list_of_trigger_types.name_label")}
                        size='lg'
                        type='name'
                        id="name"
                        name="name"
                        autoComplete='given-name'
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackname">
                        {errors.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                  <Col sm={true} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.list_of_trigger_types.trigger_type")} &nbsp; &nbsp;</Form.Label>
                    <Form.Check name="radioIsAdministrator" label={t("home_message_trigger.list_of_trigger_types.no")} type={'radio'} checked={!type_isActive} onChange={() => { setType_isActive(false) }} inline></Form.Check>
                    <Form.Check name="radioIsAdministrator" label={t("home_message_trigger.list_of_trigger_types.yes")} type={'radio'} checked={type_isActive} onChange={() => { setType_isActive(true) }} inline></Form.Check>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-4">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => ModalEditTriggerTypeControllerConst.Hide()} >{t("home_message_trigger.list_of_trigger_types.cancel")}</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t("home_message_trigger.list_of_trigger_types.save")}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditTriggerType;
