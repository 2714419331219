import { FC, useEffect, useState } from "react";
import ModalEditTemplateMessage from ".";
import { IModalEditTemplateMessageController } from "./indexModal";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { getShowEditTemplateMessage, getShowTemplateTagModal, setShowAlertFeedback, setShowEditTemplateMessage, setShowTemplateTagModal } from "../../../../../../store/internal";
import { AppRequesterControlPanelController } from "../../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import { FormValues } from "../formOfModalsTemplateMessage/indexModel";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../../../store/token";

const ModalEditTemplateMessageController:FC<IModalEditTemplateMessageController> = (props) => {
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const appRequesterConst = new AppRequesterControlPanelController();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [preSelectedWhatsapp, setPreSelectedWhatsapp] = useState(null);

    const [tags, setTags] = useState([]);

    const values: TokenInterface = {
        internal: {
            getShowEditTemplateMessage: useSelector(getShowEditTemplateMessage),
            getShowTemplateTagModal: useSelector(getShowTemplateTagModal),
        },
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        if (values.internal.getShowEditTemplateMessage)
            setShowModal(true);
        else {
          setShowModal(false);
          setTags([]);
        }
    }, [values.internal.getShowEditTemplateMessage]);

    useEffect(() => {
      const controller = new AbortController();

      if (values.internal.getShowEditTemplateMessage && props.template) {
        // getTemplateMessage(props.template.id, controller);
        getWhatsapp(controller, props.template.whatsapp_keys_id);
      }

      if (props.template?.tags)
        setTags(props.template.tags);
      else 
        setTags([]);

      return () => {
        controller.abort();
      }
    }, [values.internal.getShowEditTemplateMessage, props.template]);

    const getWhatsapp = async (controller: AbortController, whatsapp_keys_id: string) => {
      const signal = controller.signal;

      const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + values.token.value
      }

      await appRequesterConst.Get(
          `/channel-whatsapp/whatsapp-key/${whatsapp_keys_id}`, { headers, signal },
          (response: Object) => { },
          (data: any) => {
              if (data.status === 200 && data.data.whatsAppKey) {
                setPreSelectedWhatsapp({
                  value: data.data.whatsAppKey.id,
                  label: data.data.whatsAppKey.phone_number,
                });
              }
          },
          (error: { response: { status: number } }) => {
              if (error?.response?.status === 400) {

              }
              if (error?.response?.status === 401) {

              }
          }, navigate, dispatch, setIsLoading, () => {}
      )
  }

    const getTemplateMessage = async (templateId: string, controller: AbortController) => {
      let signal = controller.signal;

      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + values.token.value
      };

      await appRequesterConst.Get(
        `/template-message/whatsapp/${templateId}`, { headers, signal },
        (response: Object) => {
          return response;
        },
        (data) => {},
        (error: Object) => {
          dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
        }, navigate, dispatch, setIsLoading,
        () => { },
        /* {
          error422: () => {
            dispatch(setShowAlertFeedback({ message: "Existem campos não selecionados!", visibility: true, signalIcon: false }));
          }
        } */
      );
    }

    const hide = () => {
      if (values.internal.getShowTemplateTagModal)
        dispatch(setShowTemplateTagModal(false))
      else 
        dispatch(setShowEditTemplateMessage(false));
    }

    const putTemplateMessage = async (formValues: FormValues, templateId: string) => {
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + values.token.value
        };

        await appRequesterConst.Put(
          `/template-message/whatsapp/${templateId}`, formValues, { headers },
          (response: Object) => {
            return response;
          },
          (data) => {
            dispatch(setShowAlertFeedback({ message: "Template editada com sucesso!", visibility: true, signalIcon: true }));
            hide();
            setTags([]);
            props.callback();
          },
          (error: Object) => {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
          }, navigate, dispatch, setIsLoading,
          () => { },
          {
            error500: () => {
              dispatch(setShowAlertFeedback({ message: "Arquivo não pôde ser aceito!", visibility: true, signalIcon: false }));
            }
          }
        );
      }

    return <ModalEditTemplateMessage 
      showModal={showModal}
      hide={hide}
      onSubmit={putTemplateMessage}
      currentCompany={props.currentCompany}
      dispatch={dispatch}
      tags={tags}
      setTags={setTags}
      template={props.template}
      preSelectedWhatsapp={preSelectedWhatsapp}
    />
}

export default ModalEditTemplateMessageController;