import { FC, useEffect, useState } from "react";
import { FilterOptionComponentControllerInterface } from "./indexModel";
import { useTranslation } from "react-i18next";
import constsApi from "../../../../services/constsApi";
import { MessageTriggerStatus } from "../../../../core/enums/message-trigger-status";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataConsumerInterface, DataMessageSendingType, DataSectorActivesInterface, DataTagIdInterface, DataUserInterface, TokenInterface, UserInterface } from "../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../store/token";
import UserService from "../../../../services/user-service";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import FilterOptionComponent from ".";
import { TicketStatus } from "../../../../core/enums/ticket-status";
import { optionSelect } from "../selectComponent/indexModel";
import { registerLocale } from "react-datepicker"; 
import { ptBR, pt, es, enGB, fr } from "date-fns/locale";

registerLocale('pt-br', ptBR);
registerLocale('pt-pt', pt);
registerLocale('es', es);
registerLocale('en-US', enGB);
registerLocale('fr', fr);
// setDefaultLocale("pt");

const FilterOptionComponentController: FC<FilterOptionComponentControllerInterface> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();

    const [isLoading, setIsLoading] = useState(false);
    const [optionNames] = useState(props.optionNames);

    const [selectedChannel, setSelectedChannel] = useState([]);
    const [channelOptions, setChannelOptions] = useState(
        props.limitedOptionNameChannel?
        props.limitedOptionNameChannel:
        [
            { label: t('filter_option.channel_email'), value: constsApi.channel[0].id },
            { label: "Facebook", value: constsApi.channel[3].id },
            { label: "Instagram", value: constsApi.channel[4].id },
            { label: t('filter_option.channel_telephony'), value: constsApi.channel[1].id },
            { label: "Webchat", value: constsApi.channel[5].id },
            { label: "WhatsApp", value: constsApi.channel[2].id },
        ]);
    const [channelOptionsChatbot, setChannelOptionsChatbot] = useState(
        [
            { label: "Facebook", value: constsApi.channel[3].id },
            { label: "Instagram", value: constsApi.channel[4].id },
            { label: t('filter_option.channel_telephony'), value: constsApi.channel[1].id },
            { label: "Webchat", value: constsApi.channel[5].id },
            { label: "WhatsApp", value: constsApi.channel[2].id },
        ]);

    const [selectedBot, setSelectedBot] = useState([]);
    const [botOptions, setBotOptions] = useState(
        [
            { label: t('filter_option.bot_yes'), value: '1' },
            { label: t('filter_option.bot_no'), value: '0' },
        ]);

    const [selectedStatus, setSelectedStatus] = useState([]);
    const [statusOptions, setStatusOptions] = useState(
        [
            { label: t('filter_option.status_new'), value: constsApi.status[4].id },
            { label: t('filter_option.status_opened'), value: constsApi.status[0].id },
            { label: t('filter_option.status_pending'), value: constsApi.status[2].id },
            { label: t('filter_option.status_hold'), value: constsApi.status[3].id },
        ]);

    const [agentOptions, setAgentOptions] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);

    const [departmentOptions, setDepartmentsOptions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const [triggerTypeOptions, setTriggerTypeOptions] = useState([]);
    const [selectedTriggerType, setSelectedTriggerType] = useState([]);

    const [responsibleOptions, setResponsibleOptions] = useState([]);
    const [selectedResponsible, setSelectedResponsible] = useState([]);

    const [tagsOptions, setTagsOptions] = useState([]);
    const [consumerTagsOptions, setConsumerTagsOptions] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedConsumerTags, setSelectedConsumerTags] = useState([]);

    const [ticketTaskTagsOptions, setTicketTaskTagsOptions] = useState([]);
    const [selectedTicketTaskTags, setSelectedTicketTaskTags] = useState([]);

    const [ticketTaskStatusOptions, setTicketTaskStatusOptions] = useState(
        [
            { label: t('ticket_status.new'), value: TicketStatus.New },
            { label: t('ticket_status.open'), value: TicketStatus.Open },
            { label: t('ticket_status.pending'), value: TicketStatus.Pending },
            { label: t('ticket_status.on_hold'), value: TicketStatus.OnHold },
            { label: t('ticket_status.delayed'), value: TicketStatus.OverdueTicketTask},
            { label: t('ticket_status.resolved'), value: TicketStatus.Resolved },
            { label: t('ticket_status.abandoned'), value: TicketStatus.Abandoned },
        ]);
    const [selectedTicketTaskStatus, setSelectedTicketTaskStatus] = useState([]);

    const [selectedTriggerStatus, setSelectedTriggerStatus] = useState([]);
    const [triggerStatusOptions, setTriggerStatusOptions] = useState(
        [
            { label: t('filter_option.trigger_status_triggered'), value: MessageTriggerStatus.Triggered },
            { label: t('filter_option.trigger_status_scheduled'), value: MessageTriggerStatus.Scheduled },
            { label: t('filter_option.trigger_status_canceled'), value: MessageTriggerStatus.Canceled },
        ]);

    const [selectedAssignedTask, setSelectedAssignedTask] = useState([]);
    const [assignedTaskOptions, setAssignedTaskOptions] = useState([]);

    const [selectedRequesterTask, setSelectedRequesterTask] = useState([]);
    const [requesterTaskOptions, setRequesterTaskOptions] = useState([]);

    const [consumerOptions, setConsumerOptions] = useState([]);
    const [selectedConsumer, setSelectedConsumer] = useState([]);
    const [selectedChatbot, setSelectedChatbot] = useState([]);
    const [chatbotOptions, setChatbotOptions] = useState([]);

    const [selectedAutomation, setSelectedAutomation] = useState([]);
    const [automationOptions, setAutomationOptions] = useState([]);
    const [automationAllOptions, setAutomationAllOptions] = useState([]);

    const [currentFilterDate, setCurrentFilterDate] = useState(props.firstAndCurrentDay ? props.firstAndCurrentDay : [null, null]);
    const [startDate, endDate] = currentFilterDate;

    const [showTooltipConsumers, setShowTooltipConsumers] = useState(false);
    const [showTooltipSetor, setShowTooltipSetor] = useState(false);
    const [showTooltipAgents, setShowTooltipAgents] = useState(false);
    const [showTooltipTags, setShowTooltipTags] = useState(false);
    const [showTooltipResponsible, setShowTooltipResponsible] = useState(false);
    const [showTooltipType, setShowTooltipType] = useState(false);
    const [showTooltipBot, setShowTooltipBot] = useState(false);
    const [showTooltipAutomation, setShowTooltipAutomation] = useState(false);
    const [showTooltipChannel, setShowTooltipChannel] = useState(false);
    const [showTooltipStatus, setShowTooltipStatus] = useState(false);
    const [showTooltipTriggerStatus, setShowTooltipTriggerStatus] = useState(false);
    const [showTooltipChatbot, setShowTooltipChatbot] = useState(false);
    const [showTooltipAssignedTask, setShowTooltipAssignedTask] = useState(false);
    const [showTooltipRequesterTask, setShowTooltipRequesterTask] = useState(false);
    const [showTooltipTicketTaskTags, setShowTooltipTicketTaskTags] = useState(false);
    const [showTooltipTicketTaskStatus, setShowTooltipTicketTaskStatus] = useState(false);

    const [resetingMultiSelectFilter, setResetingMultiSelectFilter] = useState(false);
    
    const currentLngStorage = localStorage.getItem('i18nextLng');
    const [language] = useState(currentLngStorage  || '');

    /**
     * Date in pick date to bold
     */
    const [updateInputDateActive, setUpdateInputDateActive] = useState(false);

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }
    }

    /**
     * Set bold to calendar font
     */
    useEffect(() => {
        setUpdateInputDateActive((isUpdateInputDate) => {
            if (isUpdateInputDate) {
                document.getElementById("dateInputCalendarId").className = "dateInputCalendarActive ps-0";
            }
            return isUpdateInputDate;
        });
    }, [updateInputDateActive]);

    useEffect(() => {
        exportFilters()
    }, [selectedChannel, selectedAgents, selectedDepartments, selectedTags, currentFilterDate, selectedStatus])

    useEffect(() => {
        let filterAutomation = selectedChannel.map(item => { 
            if (item.value === '33be81d8-88ac-4e08-bb4d-cf69fd23267f') {
                return 'whatsapp_key';
            } 
            else if (item.value === '4a7b71bb-6432-4794-ad11-f978b067871d') {
                return 'webchat_key';
            }
            else if (item.value === 'aa1cea93-23de-46aa-af14-d6f766acf5c8') {
                return 'facebook_key';
            }
            else if (item.value === 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2') {
                return 'instagram_key';
            }
            else if (item.value === '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a') {
                return 'email_key';
            } else {
                return 'phone_key';
            }
        })
        setSelectedAutomation([])
        setAutomationOptions(automationAllOptions);
        setAutomationOptions(filterAutomationByChannel(automationAllOptions, filterAutomation));
    }, [selectedChannel])

    useEffect(() => {
        if (props.values_token_value) {
            if(props.screen === 'view_departments' || props.screen === 'view_tags' || props.screen === 'view_message_trigger' || props.screen === 'home_tickets') { // canal, bot, atribuido, status, setor, data
                getAgents();
            } else if (props.screen === 'home_consumers') { // ConsumerTags, AtribuidoConsumidores
                getAgents();
                getConsumerTags();
            } else if (props.screen === 'home_dashboard_chatbot' || props.screen === 'home_history_chatbot') { // chatbot
                getBotCxpress();
            } else if (props.screen === 'home_dashboard') {
                getAgents();
                getConsumers();
                getTags();
            } else if (props.screen === 'home_history_tickets') {
                getAgents();
                getTags();
            } else if (props.screen === 'home_message_trigger') {
                getAgents();
                getNotFilteredTriggerTypes();
            } else if (props.screen === 'home_tasks') {
                getAgents();
                getTicketTaskTags();
            }
            getAutomations();

            if (props.values_company_id && props.values_user.profile_id !== constsApi.profile[1].id) {
                getDepartments(); // chamada nas telas de view_tags, view_agents
            }
            else {
                getDepartmentsFromAgent();
            }
        }

        if ((props.disableSubOptions && props.disableSubOptions.status && props.disableSubOptions.status.resolved === false) || props.disableSubOptions === undefined) {
            setStatusOptions(state => {
                const letaux = state.concat([{ label: t('ticket_status.resolved'), value: constsApi.status[1].id }, { label: t('ticket_status.abandoned'), value: constsApi.status[5].id }])
                return letaux;
            });
        } else if ((props.disableSubOptions && props.disableSubOptions.status && props.disableSubOptions.status.history === true)) {
            let historyStatusOptions = [
                { label: t('ticket_status.resolved'), value: constsApi.status[1].id }, 
                { label: t('ticket_status.abandoned'), value: constsApi.status[5].id }
            ]
            setStatusOptions(historyStatusOptions);
        }

        if (props.firstAndCurrentDay) {
            callbackFiltering(document.getElementById("filterButton"));
        }
    }, [])

    useEffect(() => {
        if (props.fixedColumns) {
            fixColumns(props.fixedColumns);
        }
    }, [props.fixedColumns]);

    useEffect(() => {
        if (props.yoursTickets) {
            setSelectedAgents([{ label: props.values_user.name + " " + (props.values_user.name && props.values_user.lastname ? props.values_user.lastname : ""), value: props.values_user.id }]);
        } else {
            setSelectedAgents([]);
        }
    }, [props.yoursTickets && props.yoursTickets]);

    useEffect(() => {
        if (props.newTickets) {
            setSelectedStatus([{ label: "Novo", value: constsApi.status[4].id }]);
            setSelectedAgents([{ label: "Não atribuído", value: "NA" }]);
        } else {
            setSelectedStatus([]);
            setSelectedAgents([]);
        }
    }, [props.newTickets && props.newTickets]);

    useEffect(() => {
        if (props.setFilterDates) {
            props.setFilterDates(currentFilterDate);
        }

        if (props.setTag) {
            if (selectedTags.length > 0) {
                props.setTag(selectedTags.map((item) => {
                    return item.value
                }));
                setShowTooltipTags(false)
            } else {
                props.setTag(null);
                setShowTooltipTags(false)
            }
        }

        if (props.setChannel) {
            if (selectedChannel.length > 0) {
                props.setChannel(selectedChannel.map((item) => {
                    return item.value
                }));
            } else {
                props.setChannel(null);
            }
        }

        if (props.setDepartmentId) {
            if (selectedDepartments.length > 0) {
                props.setDepartmentId(selectedDepartments.map((item) => {
                    return item.value
                }));
                setShowTooltipSetor(false)
            } else {
                props.setDepartmentId(null);
                setShowTooltipSetor(false)
            }
        }

        if (props.setEmployee) {
            if (selectedAgents.length > 0) {
                props.setEmployee(selectedAgents.map((item) => {
                    return item.value
                }));
                setShowTooltipAgents(false)
            } else {
                props.setEmployee(null);
                setShowTooltipAgents(false)
            }
        }

        if (props.setConsumer) {
            if (selectedConsumer.length > 0) {
                props.setConsumer(selectedConsumer.map((item) => {
                    return item.user_id
                }));
                setShowTooltipConsumers(false)
            } else {
                props.setConsumer(null);
                setShowTooltipConsumers(false)
            }
        }
    }, [currentFilterDate, selectedChannel, selectedDepartments, selectedTags, selectedAgents, selectedConsumer]);

    useEffect(() => {
        if (props?.resetingFilters) {
            setSelectedChannel([]);
            setSelectedAgents([]);
            setSelectedDepartments([]);
            setSelectedTags([]);
            setCurrentFilterDate([null, null]);
            props?.setResetingFilters(false);
        }
    }, [props?.resetingFilters])

    const getNotFilteredTriggerTypes = async () => {
        /* const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + props.values_token_value
        }; */
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            "/message-sending-type",
            { headers: headers, params: {} },
            (response: Object) => { },
            (data: DataMessageSendingType) => {
                if (data.status === 200 && data.data.messageSendingTypes) {
                    let messageSendingTypes = [];
                    data.data.messageSendingTypes.map((item) => {
                        messageSendingTypes.push({ label: item.name, value: item.id });
                    });
                    setTriggerTypeOptions(state => {
                        return state.concat(messageSendingTypes);
                    });

                }
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getDepartments = async () => {
        /* const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + props.values_token_value
        }; */
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/sector/actives/', { headers, params: { companyid: props.values_company_id } },
            (response: Object) => { },
            (data: DataSectorActivesInterface) => {
                if (data.status === 200 && data.data.sectors) {
                    let departments = [];
                    data.data.sectors.map((item) => {
                        departments.push({ label: item.name, value: item.id });
                    });
                    setDepartmentsOptions(state => {
                        return state.concat(departments);
                    });

                }
            }, (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getDepartmentsFromAgent = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/agent/sector-employee',
            { headers },
            (response: Object) => {
                return response;
            },
            (data: any) => {
                if (data.status === 200 && data.data.data.data) {
                    let departments = [];
                    data.data.data.data.map((item) => {
                        departments.push({ label: item.name, value: item.id });
                    });
                    setDepartmentsOptions(state => {
                        return state.concat(departments);
                    });
                }
            }, (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const getAgents = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/agent',
            { headers },
            (response: Object) => {
                return response;
            },
            (data: DataUserInterface) => {
                if (data.status === 200 && data.data.employees) {
                    let agents = [];
                    data.data.employees.map((item) => {
                        agents.push({ label: item.name, value: item.user_id });
                    });
                    setAgentOptions(state => {
                        return state.concat(agents);
                    });
                    setResponsibleOptions(state => {
                        return state.concat(agents);
                    });
                    setAssignedTaskOptions(assigned => {
                        return assigned.concat(agents);
                    });
                    setRequesterTaskOptions(requester => {
                        return requester.concat(agents);
                    });
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getTicketTaskTags = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            `/tag?isFilter=true`, { headers },
            (response: Object) => { },
            (data: { data: { tags: { name: string, id: string }[] }, status: number }) => {
                let auxObj = [];
                if (data.status === 200) {
                    data.data.tags.forEach((element) => {
                        auxObj.push({ label: element.name, value: element.id });
                    });
                }

                setTicketTaskTagsOptions(auxObj);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const getTags = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            `/tag?is_active=true`, { headers },
            (response: Object) => { },
            (data: DataTagIdInterface) => {
                if (data.status === 200 && data.data.tags) {
                    let tags = [];
                    data.data.tags.map((item) => {
                        tags.push({ label: item.name, value: item.id });
                    });
                    setTagsOptions(state => {
                        return state.concat(tags);
                    });
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getConsumerTags = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/consumer-tag', { headers },
            (response: Object) => { },
            (data: DataTagIdInterface) => {
                if (data.status === 200 && data.data.tags) {
                    let tags = [];
                    data.data.tags.map((item) => {
                        tags.push({ label: item.name, value: item.id });
                    });
                    setConsumerTagsOptions(state => {
                        return state.concat(tags);
                    });
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getConsumers = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/consumer', { headers },
            (response: Object) => { },
            (data: DataConsumerInterface) => {
                if (data.status === 200 && data.data.consumers) {
                    let consumers = [];
                    data.data.consumers.map((item) => {
                        consumers.push({ label: item.name, value: item.user_id, user_id: item.user_id });
                    });
                    setConsumerOptions(state => {
                        return state.concat(consumers);
                    });
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getBotCxpress = async () => {

        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            "/bot-cxpress",
            { headers: headers, params: {} },
            (response: Object) => { },
            (data) => {
                if (data.status === 200 && data.data.bot_cxpress) {
                    let chatbot = [];
                    data.data.bot_cxpress.map((item) => {
                        if (item.bot_enable == true) {
                            chatbot.push({ label: item.bot_name, value: item.id });
                        }
                    });
                    setChatbotOptions(state => {
                        return state.concat(chatbot);
                    });
                }
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAutomations = async () => {

        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            'channel/keys', { headers },
            (response: Object) => {
            return response;
            },
            (data) => {

            if (data.status === 200) {
                let result = data.data.channel_keys.map((item) => ({
                label: (
                    item.whatsapp_key ? `${item.name} - ${item.whatsapp_key.phone_number}` : item.name
                ),
                value: item 
                }));
                setAutomationOptions(result);
                setAutomationAllOptions(result);
            }
            },
            (error: Object) => {
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const multiselectFilterUsers = async (options: optionSelect[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let users = [];
            await AppRequesterConst.Get(
                '/user', { headers, params: params },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data.data.users) {
                        data.data.users.map((item) => {
                            users.push({ ...item, label: item.name, value: item.user_id });
                        });
                    }
                },
                (error: Object) => { }, navigate, dispatch, () => { }, { values: values }

            );

            return users;
        }
    }

    const multiselectFilterAgents = async (options: optionSelect[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let users = [];
            await AppRequesterConst.Get(
                '/agent', { headers, params: params },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data.data.employees) {
                        data.data.employees.map((item) => {
                            users.push({ ...item, label: item.name, value: item.user_id });
                        });
                    }
                },
                (error: Object) => { }, navigate, dispatch, () => { }, { values: values }

            );

            return users;
        }
    }

    const fixColumns = (columns: any) => {
        /*
       let channel = "Todos os canais";
       let responsible = "Todos";

      if (columns.fixedChannel) {
           constsApi.channel.map((element) => {
               if (element.id === columns.fixedChannel) {
                   channel = element.name;
               }
           });
       onSelectDropdownChannel(channel);
       }

       if (columns.fixedResponsibleName) {
           responsible = columns.fixedResponsibleName + "*trigger_responsible_id*";
           onSelectDropdownTriggerResponsible(responsible);
       }
       */
    }

    const onSelectDropdownDate = (value: any) => {
        if (document.getElementById("dateInput") != null) {
            if (value === null) {
                setCurrentFilterDate([null, null]);
            } else {
                setCurrentFilterDate(value);
            }
        }
    }

    const resetFilters = () => {
        onSelectDropdownDate(null);
        setResetingMultiSelectFilter(true);
    }

    const handleCleanList = () => {
        props.cleanList();
        resetFilters();

        if (props.firstAndCurrentDay)
            setCurrentFilterDate(props.firstAndCurrentDay);
    }

    const callbackFiltering = (e: Object) => {
        const auxObj = {};
        const dataObj = {};

        if (props.values_internal_getShowEditTicketsModal === true) {
            resetFilters();
        }

        let channelValue = []
        let channelLabel = []
        let tagsValue = []
        let tagsLabel = []
        let statusValue = []
        let statusLabel = []
        let agentsValue = []
        let agentsLabel = []
        let departmentsValue = []
        let departmentsLabel = []
        let triggerTypeValue = []
        let triggerTypeLabel = []
        let responsibleValue = []
        let responsibleLabel = []
        let triggerStatusValue = []
        let triggerStatusLabel = []
        let consumerStatusValue = []
        let consumerStatusLabel = []
        let chatbotStatusValue = []
        let chatbotStatusLabel = []
        let assignedTaskValue = [];
        let assignedTaskLabel = [];
        let requesterTaskValue = [];
        let requesterTaskLabel = [];
        let ticketTaskStatusValue = [];
        let ticketTaskStatusLabel = [];
        let ticketTaskTagsValue = [];
        let ticketTaskTagsLabel = [];
        let automationsValue = [];
        let automationsLabel = [];

        selectedAutomation.map((item) => {
            automationsValue.push(item.value.id)
            automationsLabel.push(item.label)
        })

        selectedChannel.map((item) => {
            channelValue.push(item.value)
            channelLabel.push(item.label)
        })

        selectedTags.map((item) => {
            tagsValue.push(item.value)
            tagsLabel.push(item.label)
        })

        selectedConsumerTags.map((item) => {
            tagsValue.push(item.value)
            tagsLabel.push(item.label)
        })

        selectedStatus.map((item) => {
            statusValue.push(item.value)
            statusLabel.push(item.label)
        })

        selectedAgents.map((item) => {
            agentsValue.push(item.value)
            agentsLabel.push(item.label)
        })

        selectedDepartments.map((item) => {
            departmentsValue.push(item.value)
            departmentsLabel.push(item.label)
        })

        selectedTriggerType.map((item) => {
            triggerTypeValue.push(item.value)
            triggerTypeLabel.push(item.label)
        })

        selectedResponsible.map((item) => {
            responsibleValue.push(item.value)
            responsibleLabel.push(item.label)
        })

        selectedTriggerStatus.map((item) => {
            triggerStatusValue.push(item.value)
            triggerStatusLabel.push(item.label)
        })

        selectedConsumer.map((item) => {
            consumerStatusValue.push(item.value)
            consumerStatusLabel.push(item.label)
        })

        selectedChatbot.map((item) => {
            chatbotStatusValue.push(item.value)
            chatbotStatusLabel.push(item.label)
        })

        selectedAssignedTask.map((item) => {
            assignedTaskValue.push(item.value);
            assignedTaskLabel.push(item.label);
        });

        selectedRequesterTask.map((item) => {
            requesterTaskValue.push(item.value);
            requesterTaskLabel.push(item.label);
        });

        selectedTicketTaskStatus.map((item) => {
            ticketTaskStatusValue.push(item.value);
            ticketTaskStatusLabel.push(item.label);
        });

        selectedTicketTaskTags.map((item) => {
            ticketTaskTagsValue.push(item.value);
            ticketTaskTagsLabel.push(item.label);
        });

        if (selectedChannel.length > 0) {
            auxObj["channel"] = channelValue.toString()
            dataObj["channel"] = channelLabel.toString()
        }

        if (selectedAutomation.length > 0) {
            auxObj["automation"] = automationsValue.toString()
            dataObj["automation"] = automationsLabel.toString()
        }

        if (selectedBot.length > 0) {
            if (selectedBot[0].value === "1")
                auxObj["bot"] = true;
            else
                auxObj["bot"] = false;
            dataObj["bot"] = selectedBot[0].label;
        }

        if (selectedStatus.length > 0) {
            auxObj["status"] = statusValue.toString();
            dataObj["status"] = statusLabel.toString();
        }

        if (selectedAgents.length > 0) {
            auxObj["employee"] = agentsValue.toString();
            dataObj["employee"] = agentsLabel.toString();
        }

        if (currentFilterDate[0] !== null) {
            auxObj["start_date"] = new Date(currentFilterDate[0]).toISOString().split("T")[0];
            dataObj["start_date"] = new Date(currentFilterDate[0]);
        } else if (props.firstAndCurrentDay !== undefined) {
            if (props.firstAndCurrentDay[0] !== null) {
                auxObj["start_date"] = new Date(props.firstAndCurrentDay[0]).toISOString().split("T")[0];
                dataObj["start_date"] = new Date(props.firstAndCurrentDay[0]);
            }
        }

        if (currentFilterDate[1] !== null) {
            auxObj["end_date"] = new Date(currentFilterDate[1]).toISOString().split("T")[0];
            dataObj["end_date"] = new Date(currentFilterDate[1]);
        } else if (props.firstAndCurrentDay !== undefined) {
            if (props.firstAndCurrentDay[1] !== null) {
                auxObj["end_date"] = new Date(props.firstAndCurrentDay[1]).toISOString().split("T")[0];
                dataObj["end_date"] = new Date(props.firstAndCurrentDay[1]);
            }
        }

        if (selectedDepartments.length > 0) {
            // auxObj["sector"] = departmentsValue.toString();
            // dataObj["sector"] = departmentsLabel.toString();

            if(selectedDepartments.length === departmentOptions.length) {
                auxObj["is_all_sectors"] = true;
                dataObj["is_all_sectors"] = true;
            } else {
                auxObj["sector"] = departmentsValue.toString();
                dataObj["sector"] = departmentsLabel.toString();
            }
        }

        if (selectedTriggerType.length > 0) {
            auxObj["type"] = triggerTypeValue.toString();
            dataObj["type"] = triggerTypeLabel.toString();
        }

        if (selectedResponsible.length > 0) {
            auxObj["employee"] = responsibleValue.toString();
            dataObj["employee"] = responsibleLabel.toString();
        }

        if (selectedTags.length > 0) {
            if(selectedTags.length === tagsOptions.length) {
                auxObj["allTags"] = true;
                dataObj["allTags"] = true;
            } else {
                let label = "tag";
                if (props.optionNames.includes("Tags")) {
                    label = "tags";
                }
    
                auxObj[label] = tagsValue.toString();
                dataObj[label] = tagsLabel.toString();
            }
        }

        if (selectedConsumerTags.length > 0) {
            if(selectedConsumerTags.length === consumerTagsOptions.length) {
                auxObj["allTags"] = true;
                dataObj["allTags"] = true;
            } else {
                let label = "tags";

                auxObj[label] = tagsValue.toString();
                dataObj[label] = tagsLabel.toString();
            }
        }

        if (selectedTriggerStatus.length > 0) {
            auxObj["status"] = triggerStatusValue.toString();
            dataObj["status"] = triggerStatusLabel.toString();
        }

        if (selectedConsumer.length > 0) {
            auxObj["consumer"] = consumerStatusValue.toString();
            dataObj["consumer"] = consumerStatusLabel.toString();
        }

        if (selectedChatbot.length > 0) {
            auxObj["bot_id"] = chatbotStatusValue.toString();
            dataObj["bot_id"] = chatbotStatusLabel.toString();
        }

        if (selectedAssignedTask.length > 0) {
            auxObj['assigned_user_id'] = assignedTaskValue.toString();
            dataObj['assigned_user_id'] = assignedTaskLabel.toString();
        }
        
        if (selectedRequesterTask.length > 0) {
            auxObj['requester_user_id'] = requesterTaskValue.toString();
            dataObj['requester_user_id'] = requesterTaskLabel.toString();
        }

        if (selectedTicketTaskTags.length > 0) {
            if(selectedTicketTaskTags.length === ticketTaskTagsOptions.length) {
                auxObj["allTags"] = true;
                dataObj["allTags"] = true;
            } else {
                auxObj['tags_id'] = ticketTaskTagsValue.toString();
                dataObj['tags_id'] = ticketTaskTagsLabel.toString(); 
            }
        }

        if (selectedTicketTaskStatus.length > 0) {
            auxObj['status_id'] = ticketTaskStatusValue.toString();
            dataObj['status_id'] = ticketTaskStatusLabel.toString(); 
        }

        props.dashboardMetrics == true ? props.filteringTickets(e, auxObj, dataObj) : props.filteringTickets(e, auxObj);
    }

    const exportFilters = () => {
        if(props.setFilters !== undefined) {
            let filter = {selectedChannel, selectedAgents, selectedDepartments, selectedTags, selectedStatus, dates: currentFilterDate}
            props.setFilters(filter);
        }
    }

    const filterAutomationByChannel = (arr: any, arr_filter: any) => {
        if (arr_filter.length === 0) {
            return arr;
        }
        let newArray = [];
        for (const item of arr) {
            let result;
            for (const subItem of arr_filter) {
                if (item.value[subItem]) {
                    result = item;
                }
            }
            if (result) {
                newArray.push(result);
            }
        }
        return newArray;
    }

    return <FilterOptionComponent 
        optionNames={optionNames}
        selectedConsumer={selectedConsumer}
        showTooltipConsumers={showTooltipConsumers}
        setShowTooltipConsumers={setShowTooltipConsumers}
        consumerOptions={consumerOptions}
        setConsumerOptions={setConsumerOptions}
        setSelectedConsumer={setSelectedConsumer}
        multiselectFilterUsers={multiselectFilterUsers}
        resetingMultiSelectFilter={resetingMultiSelectFilter}
        setResetingMultiSelectFilter={setResetingMultiSelectFilter}
        selectedChatbot={selectedChatbot}
        showTooltipChatbot={showTooltipChatbot}
        setShowTooltipChatbot={setShowTooltipChatbot}
        chatbotOptions={chatbotOptions}
        setChatbotOptions={setChatbotOptions}
        setSelectedChatbot={setSelectedChatbot}
        selectedAssignedTask={selectedAssignedTask}
        showTooltipAssignedTask={showTooltipAssignedTask}
        setShowTooltipAssignedTask={setShowTooltipAssignedTask}
        assignedTaskOptions={assignedTaskOptions}
        setAssignedTaskOptions={setAssignedTaskOptions}
        setSelectedAssignedTask={setSelectedAssignedTask}
        multiselectFilterAgents={multiselectFilterAgents}
        callbackFiltering={callbackFiltering}
        handleCleanList={handleCleanList}
        selectedChannel={selectedChannel}
        showTooltipChannel={showTooltipChannel}
        setShowTooltipChannel={setShowTooltipChannel}
        channelOptions={channelOptions}
        setChannelOptions={setChannelOptions}
        channelOptionsChatbot={channelOptionsChatbot}
        setChannelOptionsChatbot={setChannelOptionsChatbot}
        setSelectedChannel={setSelectedChannel}
        selectedBot={selectedBot}
        showTooltipBot={showTooltipBot}
        setShowTooltipBot={setShowTooltipBot}
        showTooltipAutomation={showTooltipAutomation}
        setShowTooltipAutomation={setShowTooltipAutomation}
        botOptions={botOptions}
        setBotOptions={setBotOptions}
        setSelectedBot={setSelectedBot}
        selectedAgents={selectedAgents}
        showTooltipAgents={showTooltipAgents}
        setShowTooltipAgents={setShowTooltipAgents}
        agentOptions={agentOptions}
        setAgentOptions={setAgentOptions}
        setSelectedAgents={setSelectedAgents}
        selectedStatus={selectedStatus}
        showTooltipStatus={showTooltipStatus}
        setShowTooltipStatus={setShowTooltipStatus}
        statusOptions={statusOptions}
        setStatusOptions={setStatusOptions}
        setSelectedStatus={setSelectedStatus}
        selectedDepartments={selectedDepartments}
        showTooltipSetor={showTooltipSetor}
        departmentOptions={departmentOptions}
        setDepartmentsOptions={setDepartmentsOptions}
        setSelectedDepartments={setSelectedDepartments}
        startDate={startDate}
        endDate={endDate}
        onSelectDropdownDate={onSelectDropdownDate}
        setUpdateInputDateActive={setUpdateInputDateActive}
        currentFilterDate={currentFilterDate}
        setCurrentFilterDate={setCurrentFilterDate}
        selectedTriggerType={selectedTriggerType}
        showTooltipType={showTooltipType}
        setShowTooltipType={setShowTooltipType}
        triggerTypeOptions={triggerTypeOptions}
        setTriggerTypeOptions={setTriggerTypeOptions}
        setSelectedTriggerType={setSelectedTriggerType}
        selectedResponsible={selectedResponsible}
        showTooltipResponsible={showTooltipResponsible}
        setShowTooltipResponsible={setShowTooltipResponsible}
        responsibleOptions={responsibleOptions}
        setResponsibleOptions={setResponsibleOptions}
        setSelectedResponsible={setSelectedResponsible}
        selectedTriggerStatus={selectedTriggerStatus}
        showTooltipTriggerStatus={showTooltipTriggerStatus}
        setShowTooltipTriggerStatus={setShowTooltipTriggerStatus}
        triggerStatusOptions={triggerStatusOptions}
        setTriggerStatusOptions={setTriggerStatusOptions}
        setSelectedTriggerStatus={setSelectedTriggerStatus}
        selectedTags={selectedTags}
        selectedConsumerTags={selectedConsumerTags}
        showTooltipTags={showTooltipTags}
        setShowTooltipTags={setShowTooltipTags}
        tagsOptions={tagsOptions}
        consumerTagsOptions={consumerTagsOptions}
        setTagsOptions={setTagsOptions}
        setConsumerTagsOptions={setConsumerTagsOptions}
        setSelectedTags={setSelectedTags}
        setSelectedConsumerTags={setSelectedConsumerTags}
        t={t}
        setShowTooltipSetor={setShowTooltipSetor} 
        setSelectedRequesterTask={setSelectedRequesterTask} 
        selectedRequesterTask={selectedRequesterTask} 
        showTooltipRequesterTask={showTooltipRequesterTask} 
        setShowTooltipRequesterTask={setShowTooltipRequesterTask} 
        requesterTaskOptions={requesterTaskOptions} 
        setRequesterTaskOptions={setRequesterTaskOptions}    
        isLoading={isLoading}
        showTooltipTicketTaskTags={showTooltipTicketTaskTags}
        setShowTooltipTicketTaskTags={setShowTooltipTicketTaskTags}
        ticketTaskTagsOptions={ticketTaskTagsOptions}
        setTicketTaskTagsOptions={setTicketTaskTagsOptions}
        selectedTicketTaskTags={selectedTicketTaskTags}
        setSelectedTicketTaskTags={setSelectedTicketTaskTags} 
        ticketTaskStatusOptions={ticketTaskStatusOptions} 
        setTicketTaskStatusOptions={setTicketTaskStatusOptions} 
        selectedTicketTaskStatus={selectedTicketTaskStatus} 
        setSelectedTicketTaskStatus={setSelectedTicketTaskStatus} 
        showTooltipTicketTaskStatus={showTooltipTicketTaskStatus} 
        setShowTooltipTicketTaskStatus={setShowTooltipTicketTaskStatus}
        isChatbot={props.isChatbot}
        automationOptions={automationOptions}
        setAutomationOptions={setAutomationOptions}
        selectedAutomation={selectedAutomation}
        setSelectedAutomation={setSelectedAutomation}
        language={language}
        currentState={props?.currentState}
        currentViewFilter={props.currentViewFilter}
    />
}

export default FilterOptionComponentController;