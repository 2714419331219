import { /*Button,*/ Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
//import Utils from '../../../../core/shared/utils';

const DataDashboard = ({
  printPDF,
  sessionBot,
  totalMessages,
  retentionRate,
  nps,
  t
}) => {

  return (
    <Container className="nopadding">
      <Row className="justify-content-center" style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>

        <Col md={2} style={{ width: printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
          <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
            <Row style={{ marginTop: "10px", paddingRight: '0' }}>
              <Col md={9} className="d-flex justify-content-start">
                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                  {t('dashboard_chatbot.total_sessions')}
                </p>
              </Col>
              {printPDF ?
                <></>
                :
                <Col className="nopadding d-flex justify-content-end">
                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip'>
                        {t('dashboard_chatbot.total_sessions_tooltip')}
                      </Tooltip>
                    }
                  >
                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                      <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                  </OverlayTrigger>
                </Col>
              }
            </Row>
            <Row >
              <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                {sessionBot ?
                  sessionBot
                  :
                  0
                }
                {/* totalTickets != null ?
                  totalTickets.toLocaleString('pt-BR') :
                  <>0</> */}
              </Col>
            </Row>
          </Row>
        </Col>

        <Col md={2} style={{ width: printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
          <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
            <Row style={{ marginTop: "10px", paddingRight: '0' }}>
              <Col md={10} className="d-flex justify-content-start">
                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                  {t('dashboard_chatbot.total_messages')}
                </p>
              </Col>
              {printPDF ?
                <></>
                :
                <Col className="nopadding d-flex justify-content-end">
                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip'>
                        {t('dashboard_chatbot.total_messages_tooltip')}
                      </Tooltip>
                    }
                  >
                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                      <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                  </OverlayTrigger>
                </Col>
              }
            </Row>
            <Row>
              <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                {totalMessages ?
                  totalMessages
                  :
                  0
                }
              </Col>
            </Row>
          </Row>
        </Col>

        <Col md={2} style={{ width: printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
          <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
            <Row style={{ marginTop: "10px", paddingRight: '0' }}>
              <Col md={10} className="d-flex justify-content-start">
                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                  {t('dashboard_chatbot.retention_rate')}
                </p>
              </Col>
              {printPDF ?
                <></>
                :
                <Col className="nopadding d-flex justify-content-end">
                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip'>
                        {t('dashboard_chatbot.retention_rate_tooltip')}
                      </Tooltip>
                    }
                  >
                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                      <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                  </OverlayTrigger>
                </Col>
              }
            </Row>
            <Row>
              <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                {retentionRate ?
                  retentionRate.toLocaleString('pt-BR')
                  :
                  0
                }%

              </Col>
            </Row>
          </Row>
        </Col>

        <Col md={2} style={{ width: printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
          <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
            <Row style={{ marginTop: "10px", paddingRight: '0' }}>
              <Col md={10} className="d-flex justify-content-start">
                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                  {t('dashboard_chatbot.satisfaction_survey')}
                </p>
              </Col>
              {printPDF ?
                <></>
                :
                <Col className="nopadding d-flex justify-content-end">
                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip'>
                        {t('dashboard_chatbot.satisfaction_survey_tooltip')} {t('dashboard_chatbot.satisfaction_survey_tooltip_scale')}
                      </Tooltip>
                    }
                  >
                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                      <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                  </OverlayTrigger>
                </Col>
              }
            </Row>
            <Row>
              <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                {nps ?
                  nps
                  :
                  0
                }
              </Col>
            </Row>
          </Row>
        </Col>

      </Row>
    </Container>
  );
}

export default DataDashboard;