import React from 'react';

import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import InfiniteScroll from 'react-infinite-scroll-component';

import Loading from '../../../../../components/loading'
import Utils from '../../../../../core/shared/utils';
import { ListName, OrderBy, SortBy } from '../../../../../core/enums/order-sort-by';
import ButtonOrderByList from '../../../components/buttonOrderByList';
import { TicketFilterType } from '../../../../../core/enums/ticket-filter-type';
import ChannelLabel from '../../../components/channelLabel';
import ModalSendTicketCopy from '../../../components/modalSendTicketCopy';

const ListOfChatbot = ({
    isLoading,
    tickets,
    props,
    t,
    showEditModal,
    formatedDate,
    currentTicketId,
    showSendTicketCopyModal,
    ticketModal,
    setCurrentTicketInfo,
    selectModalTicket
}) => {

    const popover = (data) => (
        <Popover id={currentTicketId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={(e) => selectModalTicket(e, data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {t("home_tickets.list_tickets.action_popover.send_ticket_copy")} </Button>
            </Popover.Body >
        </Popover >
    );

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            {showSendTicketCopyModal && <ModalSendTicketCopy token={props.values.token.value} visibility={showSendTicketCopyModal} data={ticketModal}></ModalSendTicketCopy>}
            <div style={{ overflow: 'auto', margin: '2.5vh 0.5vw 0px', padding: '0' }} className="ps-3">
                {
                    tickets && tickets.length > 0 ?
                        <ListGroup variant="flux" className="TicketsRow">
                            <ListGroup.Item className="TicketsRowCollums mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                                <Row name="listOfTicketsHead">
                                    <Col md={1} className="nopadding d-flex" style={{ width: '7%' }}>
                                        {t("home_tickets.list_tickets.headers.id")}
                                    </Col>
                                    <Col md={4} className="nopadding d-flex" style={{ width: '14%' }}>
                                        Chatbot
                                    </Col>
                                    <Col md={2} style={{ width: '13%' }} className="nopadding">
                                        {/* {props.dataText === undefined ? "Data de criação" : props.dataText} */}
                                        {props.ticketStateLevelType === TicketFilterType.Deleted || props.ticketStateLevelType === TicketFilterType.Finalized ?
                                            t("history_tickets.finalized_date") :
                                            <ButtonOrderByList
                                                defaultOrder={OrderBy.desc}
                                                title={props.dataText === undefined ? t("home_tickets.list_tickets.headers.order_by_create_date_title") : props.dataText}
                                                columnIdName={
                                                    // props.ticketStateLevelType && props.ticketStateLevelType === TicketFilterType.Finalized? 
                                                    //     SortBy.updated_date:
                                                    // props.ticketStateLevelType && props.ticketStateLevelType === TicketFilterType.Deleted?
                                                    //     SortBy.deleted_date:
                                                    SortBy.updated_level2_date
                                                }
                                                callbackOrderBy={props.callbackOrderByTickets}
                                                listName={ListName.ListOfTickets}
                                            />
                                        }
                                    </Col>
                                    {props.requesterUserActiveColumn === true &&
                                        <Col md={1} className="nopadding" style={{ width: '14%' }} >
                                            {t("home_tickets.list_tickets.headers.requester")}
                                        </Col>
                                    }
                                    <Col md={1} className="nopadding" style={{ width: '9%' }}>
                                        {t("home_tickets.list_tickets.headers.channel")}
                                    </Col>
                                    <Col className='d-flex justify-content-end' >
                                        {t("home_tickets.list_tickets.headers.action")}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <div id="listOfTickets" style={{
                                overflow: 'auto', height: props.height ? props.height : '48vh', display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <InfiniteScroll
                                    dataLength={tickets.length}
                                    next={props.updateListfunction}
                                    hasMore={true}
                                    loader={<h4> </h4>}
                                    scrollableTarget={"listOfTickets"}
                                    onScroll={() => Utils.hidePopoverOnScroll(ListName.ListOfTickets)}

                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow">
                                            {
                                                tickets.map((item, index) => {
                                                    let date: Date | null = null;
                                                    let hour: string = null;
                                                    let newDate: Date | null = null;

                                                    if (props.ticketStateLevelType === TicketFilterType.Deleted) {
                                                        newDate = new Date(item.deleted_at);
                                                    } else if (props.ticketStateLevelType === TicketFilterType.Finalized) {
                                                        newDate = new Date(item.finished_at);
                                                    } else {
                                                        if (item.updated_level2_at) {
                                                            newDate = new Date(item.updated_level2_at);
                                                        } else {
                                                            newDate = new Date(item.updated_at);
                                                        }
                                                    }

                                                    date = newDate;
                                                    hour = `${newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()}:${newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()}:${newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds()}`;

                                                    let channels = "N/A";
                                                    let channelId = item.channel_id;
                                                    switch (item.channel_id) {
                                                        case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a':
                                                            // channels = 'E-mail';
                                                            channels = t("channels_names.email");
                                                            break;
                                                        case '6977996d-11d1-44cc-a863-2e1d9c24e046':
                                                            // channels = 'Telefonia';
                                                            channels = t("channels_names.telephony");
                                                            break;
                                                        case '33be81d8-88ac-4e08-bb4d-cf69fd23267f':
                                                            // channels = 'WhatsApp';
                                                            channels = t("channels_names.whatsapp");
                                                            break;
                                                        case 'aa1cea93-23de-46aa-af14-d6f766acf5c8':
                                                            // channels = 'Facebook';
                                                            channels = t("channels_names.facebook");
                                                            break;
                                                        case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2':
                                                            // channels = 'Instagram';
                                                            channels = t("channels_names.instagram");
                                                            break;
                                                        case '4a7b71bb-6432-4794-ad11-f978b067871d':
                                                            // channels = 'Webchat';
                                                            channels = t("channels_names.webchat");
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    return (
                                                        <ListGroup.Item style={{ color: '#707070' }} id={item.id} key={item.id}>
                                                            <Row className='TicketsRowHeight'>
                                                                <Col md={1} className={'align-self-center nopadding' + (item.bold ? ' bold-list-item' : '')} onClick={showEditModal} style={{ cursor: 'pointer', width: '7%' }}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    #{item.ticket_reference_id}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                #{item.ticket_reference_id}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4} className={'align-self-center nopadding' + (item.bold ? ' bold-list-item' : '')} onClick={showEditModal} style={{ cursor: 'pointer', width: '14%' }}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item.bot_cxpress_name ? item.bot_cxpress_name : "Sem Chatbot"}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {item.bot_cxpress_name ? item.bot_cxpress_name : "Sem Chatbot"}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={2} className={'align-self-center nopadding' + (item.bold ? ' bold-list-item' : '')} onClick={showEditModal} style={{ cursor: 'pointer', width: '13%' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {formatedDate(date)} - {hour}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {formatedDate(date)} - {hour}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>

                                                                {props.requesterUserActiveColumn === true &&
                                                                    <Col md={1} className={'align-self-center nopadding' + (item.bold ? ' bold-list-item' : '')} onClick={showEditModal} style={{ cursor: 'pointer', width: '14%' }}>
                                                                        <div className='oneLineClampEllipsis'>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id='tooltip'>
                                                                                        {item.requester_user != null ?
                                                                                            <>{item.requester_user.name} {item.requester_user.lastname ? item.requester_user.lastname : ""}</>
                                                                                            :
                                                                                            <>N/A</>
                                                                                        }
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {item.requester_user && item.requester_user.name ? item.requester_user.name + (item.requester_user.lastname ? " " + item.requester_user.lastname : "") : <>N/A</>}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </Col>
                                                                }
                                                                <Col md={1} className={'d-flex align-self-center nopadding' + (item.bold ? ' bold-list-item' : '')} onClick={showEditModal} style={{ cursor: 'pointer', width: '9%' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {channels != null ?
                                                                                    <>{channels}</>
                                                                                    // ChannelLabel(channels, channelId, { color: '#fff' })
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {channels != null ?
                                                                                // <>{channels}</>
                                                                                ChannelLabel(channels, channelId, { color: item.bold ? "#1E1E1E" : 'rgb(112, 112, 112)' })
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <Col className='d-flex justify-content-end ps-0' >
                                                                    <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item)} >
                                                                        <Button id={item.ticket_reference_id + "*ID*" + item.id} onClick={(e) => setCurrentTicketInfo(e)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <circle cx="12" cy="12" r="1" />
                                                                                <circle cx="12" cy="19" r="1" />
                                                                                <circle cx="12" cy="5" r="1" />
                                                                            </svg>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    );
                                                })
                                            }
                                        </ListGroup>

                                    }
                                </InfiniteScroll>
                            </div>
                        </ListGroup>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col className='d-flex justify-content-center' md={1}>
                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                <Col className='d-flex justify-content-center'>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>
                                        <span>{t("home_tickets.list_tickets.body.empty_list")}</span>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        </>
    );

}

export default ListOfChatbot;