type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

const styles = StyleSheet.create({

  textFontGlay: {
    textAlign: 'left',
    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#707070',
    opacity: '1',
    marginBottom: '1.2vh',
  },

  textBlack: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
  },

  textBlackSmaller: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.4em + 0.6vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
    marginTop: '8%',
  },
  inputGray: {
    height: '1vh',
  },

  textBlackSmallerCustom: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.4em + 0.6vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
    marginTop: '8%',
    cursor: 'pointer'
  },
});

export default styles;






