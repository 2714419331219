import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button,
    Tabs,
    Tab,
    Container,
} from "react-bootstrap"
import allStyles from "./styles"
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'
import ModalRegisterFacebookPageController from "../modalRegisterFacebookPage/indexController"
import { useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"


const AppRequesterConst = new AppRequesterController();
const FormConfigHistoric = ({
    logsAgent,
    logsCompany,
    formatDate,
    activeTab,
    setActiveTab,
    logsChanel,
    chanelLabel,
    logsContract,
    actionLabel,
    updateListFunctionCompany,
    updateListFunctionAgents,
    updateListFunctionChannel,
    updateListFunctionContract,
    fieldLabel,
    dataExibition,
    statusValueLabel
}) => {

    return (
        <>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Histórico de ações
                    </div>
                </Col>
            </Row>

            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Row style={{ marginLeft: '0', marginBottom: '0.5rem', marginTop: '1rem' }}>

                <Tabs
                    id="historicTabs"
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key)}
                    className="mb-3"
                >
                    <Tab eventKey="contrato" title="Contrato">
                        <div style={{ overflowY: 'auto', height: '56vh', overflowX: 'hidden', margin: 0 }} id="listOfContacts">
                            <InfiniteScroll
                                dataLength={logsContract.length}
                                next={updateListFunctionContract}
                                hasMore={true}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfContacts"}
                            >
                            {logsContract.map((item) => (
                                <Container key={item}>
                                    <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center", }}>
                                        <Col
                                            style={{
                                                textAlign: 'left',
                                                font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat',
                                                letterSpacing: '0px',
                                                color: '#0C2461',
                                                opacity: '1',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            md={5}
                                            className="mt-2"
                                        >
                                            <div style={{ paddingRight: "20px", color: "black", margin: 0 }}>
                                                {item?.control_panel_employee_email ? item?.control_panel_employee_email : 'N/A'}
                                            </div>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <div
                                                className="d-flex justify-content-end"
                                                style={{
                                                    background: '#EEF3FF',
                                                    borderRadius: '10px',
                                                    color: '#4A69BD',
                                                    padding: '5px 10px',
                                                }}
                                            >
                                                Contrato
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col
                                            style={{
                                                textAlign: 'left',
                                                font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                letterSpacing: '0px',
                                                color: '#0C2461',
                                                opacity: '1',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            md={5}
                                            className="mt-2"
                                        >
                                            <div style={{ paddingRight: "20px", color: "black" }}>
                                                {actionLabel(item)}
                                            </div>
                                        </Col>
                                    </Row> */}
                                    <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                                        <Col
                                            style={{
                                                textAlign: 'left',
                                                font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                letterSpacing: '0px',
                                                color: '#0C2461',
                                                opacity: '1',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            className="mt-2"
                                        >
                                            <div style={{ paddingRight: "20px", color: "black", margin: 0 }}>
                                                Alterou {fieldLabel(item) + " de "}<span style={{ color: '#4A69BD' }}>{item.field === 'started_date' ? item.old_value ? dataExibition(item.old_value) : 'N/A' : item.old_value ? statusValueLabel(item.old_value) : 'N/A'}</span> para <span style={{ color: '#4A69BD' }}>{item.field === 'started_date' ? dataExibition(item.new_value) : item.new_value ? statusValueLabel(item.new_value) : 'N/A'}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-0 noppadding" style={{ display: "flex", alignItems: "center", }}>

                                        <Col
                                            style={{
                                                textAlign: 'left',
                                                font: 'normal normal calc(0.2em + 0.7vw) Montserrat',
                                                letterSpacing: '0px',
                                                color: '#707070',
                                                opacity: '1',
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '0.3rem'
                                            }}
                                            className="mt-2"
                                        >
                                            <div style={{ color: '#707070' }}>{formatDate(item.created_at)}</div>
                                        </Col>
                                    </Row>
                                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
                                </Container>
                            ))}
                            </InfiniteScroll>
                        </div>
                    </Tab>
                    <Tab eventKey="agente" title="Agente">
                        <div id="listOfAgentsInHistory" style={{ overflowY: 'auto', height: '56vh', overflowX: 'hidden' }}>
                            <InfiniteScroll
                                dataLength={logsAgent.length}
                                next={updateListFunctionAgents}
                                hasMore={true}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfAgentsInHistory"}
                            >
                                {logsAgent.map((item) => (
                                    <Container key={item}>
                                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                md={5}
                                                className="mt-2"
                                            >
                                                <div style={{ paddingRight: "20px", color: "black" }}>
                                                    {item?.control_panel_employee_email ? item?.control_panel_employee_email : 'N/A'}
                                                </div>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <div
                                                    className="d-flex justify-content-end"
                                                    style={{
                                                        background: '#EEF3FF',
                                                        borderRadius: '10px',
                                                        color: '#4A69BD',
                                                        padding: '5px 10px',
                                                    }}
                                                >
                                                    Agentes
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {(item.action !== 'DELETE' && item.field !== 'password') &&
                                            <Col

                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                md={5}
                                                className="mt-2"
                                            >
                                                    <div style={{ paddingRight: "20px", color: '#4A69BD' }}>
                                                        {item?.employee_name}
                                                </div>
                                            </Col>
                                            }
                                        </Row>
                                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                className="mt-2"
                                            >
                                                {item.action === 'DELETE' &&

                                                    <div style={{ paddingRight: "20px", color: "black" }}>
                                                        Deletou o agente <span style={{ color: '#4A69BD' }}>{item?.employee_name}</span>
                                                    </div>
                                                }
                                                {item.action === 'REPLACE' &&

                                                    <div style={{ paddingRight: "20px", color: "black" }}>
                                                        {item.field === 'password' ? <>Alterou a senha do agente <span style={{ color: '#4A69BD' }}>{item?.employee_name}</span></> :
                                                        <>Alterou {fieldLabel(item) + " de "}<span style={{ color: '#4A69BD' }}>{item.old_value ? statusValueLabel(item.old_value) : 'N/A'}</span> para <span style={{ color: '#4A69BD' }}>{item.new_value ? statusValueLabel(item.new_value) : 'N/A'}</span></>}
                                                </div>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-0 noppadding" style={{ display: "flex", alignItems: "center", }}>

                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.7vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#707070',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '0.3rem'
                                                }}
                                                className="mt-2"
                                            >
                                                <div style={{ color: '#707070' }}>{formatDate(item.created_at)}</div>
                                            </Col>
                                        </Row>
                                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
                                    </Container>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </Tab>
                    <Tab eventKey="canais" title="Canais">
                        <div style={{ overflowY: 'auto', height: '56vh', overflowX: 'hidden' }} id="listOfChannels">
                            <InfiniteScroll
                                dataLength={logsChanel.length}
                                next={updateListFunctionChannel}
                                hasMore={true}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfChannels"}
                            >
                                {logsChanel.map((item) => (
                                    <Container key={item}>
                                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                md={5}
                                                className="mt-2"
                                            >
                                                <div style={{ paddingRight: "20px", color: "black" }}>
                                                    {item?.control_panel_employee_email ? item?.control_panel_employee_email : 'N/A'}
                                                </div>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <div
                                                    className="d-flex justify-content-end"
                                                    style={{
                                                        background: '#EEF3FF',
                                                        borderRadius: '10px',
                                                        color: '#4A69BD',
                                                        padding: '5px 10px',
                                                    }}
                                                >
                                                    {chanelLabel(item)}
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                md={5}
                                                className="mt-2"
                                            >
                                                <div style={{ paddingRight: "20px", color: "black" }}>
                                                    {actionLabel(item)}
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                className="mt-2"
                                            >
                                                {item.action === 'REPLACE' &&

                                                <div style={{ paddingRight: "20px", color: "black" }}>
                                                        Alterou {fieldLabel(item) + " de "}<span style={{ color: '#4A69BD' }}>{item.old_value ? statusValueLabel(item.old_value) : 'N/A '}</span> para <span style={{ color: '#4A69BD' }}>{item.new_value?.toString().length > 40
                                                            ? `${item.new_value.substring(0, 40)}...`
                                                            : statusValueLabel(item.new_value)}</span>
                                                </div>
                                                }
                                                {item.action === 'DELETE' &&
                                                    (item?.template_message_id ?

                                                    <div style={{ paddingRight: "20px", color: "black" }}>
                                                        Deletou a template <span style={{ color: '#4A69BD' }}>{item?.name}</span>
                                                    </div>
                                                    :
                                                    <div style={{ paddingRight: "20px", color: "black" }}>
                                                        Deletou a página <span style={{ color: '#4A69BD' }}>{item?.name}</span>
                                                    </div>)
                                                }

                                                {item.action === 'CREATE' &&
                                                    <div style={{ paddingRight: "20px", color: "black" }}>
                                                        Criou a página <span style={{ color: '#4A69BD' }}>{item.new_value?.toString().length > 40
                                                            ? `${item.new_value.substring(0, 40)}...`
                                                            : item.new_value}</span>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Row className="mt-0 noppadding" style={{ display: "flex", alignItems: "center", }}>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.7vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#707070',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '0.3rem'
                                                }}
                                                className="mt-2"
                                            >
                                                <div style={{ color: '#707070' }}>{formatDate(item.created_at)}</div>
                                            </Col>
                                        </Row>
                                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
                                    </Container>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </Tab>
                    <Tab eventKey="empresa" title="Empresa">
                        <div id="listInCompanies" style={{ overflowY: 'auto', height: '56vh', overflowX: 'hidden' }}>
                            <InfiniteScroll
                                dataLength={logsCompany.length}
                                next={updateListFunctionCompany}
                                hasMore={true}
                                loader={<h4> </h4>}
                                scrollableTarget={"listInCompanies"}
                            >
                                {logsCompany.map((item) => (
                                    <Container key={item}>
                                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                md={5}
                                                className="mt-2"
                                            >
                                                <div style={{ paddingRight: "20px", color: "black" }}>
                                                    {item?.control_panel_employee_email ? item?.control_panel_employee_email : 'N/A'}
                                                </div>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <div
                                                    className="d-flex justify-content-end"
                                                    style={{
                                                        background: '#EEF3FF',
                                                        borderRadius: '10px',
                                                        color: '#4A69BD',
                                                        padding: '5px 10px',
                                                    }}
                                                >
                                                    Empresa
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                md={5}
                                                className="mt-2"
                                            >
                                                <div style={{ paddingRight: "20px", color: "black" }}>
                                                    {actionLabel(item)}
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.8vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#0C2461',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                className="mt-2"
                                            >
                                                <div style={{ paddingRight: "20px", color: "black" }}>
                                                    Alterou {fieldLabel(item) + " de "}<span style={{ color: '#4A69BD' }}>{statusValueLabel(item.old_value)}</span> para <span style={{ color: '#4A69BD' }}>{statusValueLabel(item.new_value)}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-0 noppadding" style={{ display: "flex", alignItems: "center", }}>

                                            <Col
                                                style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal calc(0.2em + 0.7vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#707070',
                                                    opacity: '1',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '0.3rem'
                                                }}
                                                className="mt-2"
                                            >
                                                <div style={{ color: '#707070' }}>{formatDate(item.created_at)}</div>
                                            </Col>
                                        </Row>
                                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
                                    </Container>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </Tab>
                </Tabs>
            </Row>

        </>
    )
}

export default FormConfigHistoric