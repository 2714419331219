import React, { useEffect, useState } from 'react';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ModalEditFacebookPage from '.';

const AppRequesterConst = new AppRequesterController();
const ModalEditFacebookPageController = ({ show, handleClose, data, getFacebookById }) => {
    const [validated] = useState(false);
    const [bot, setBot] = useState(data?.has_bot === 'true' ? true : false)
    const [showfeedbackName, setShowfeedbackName] = useState(false);
    const [showfeedbackId, setShowfeedbackId] = useState(false);
    const [showfeedbackToken, setShowfeedbackToken] = useState(false);
    const [showfeedbackPostback, setShowfeedbackPostback] = useState(false);
    const [showfeedbackBotUrl, setShowfeedbackBotUrl] = useState(false);
    const [showfeedbackApiUrl, setShowfeedbackApiUrl] = useState(false);
    const [showfeedbackIdNlp, setShowfeedbackIdNlp] = useState(false);

    const [pageName, setPageName] = useState('');
    const [pageId, setPageId] = useState('');
    const [pageToken, setPageToken] = useState('');
    const [pageTokenPostback, setPagePostback] = useState('');
    const [pageBotUrl, setPageBotUrl] = useState('');
    const [pageApiUrl, setPageApiUrl] = useState('');
    const [pageIdNlp, setPageIdNlp] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setBot(data?.has_bot)
    }, [data])

    const cancelButton = () => {
        clearFields();
        handleClose();
    }

    const clearFields = () => {
        setShowfeedbackName(false);
        setShowfeedbackId(false);
        setShowfeedbackToken(false);
        setShowfeedbackPostback(false);
        setShowfeedbackBotUrl(false);
        setShowfeedbackApiUrl(false);
        setShowfeedbackIdNlp(false);
        setPageName('');
        setPageId('');
        setPageToken('');
        setPagePostback('');
        setPageBotUrl('');
        setPageApiUrl('');
        setBot(false);
        setPageIdNlp('');
    }

    return <ModalEditFacebookPage
        show={show}
        handleClose={handleClose}
        data={data}
        clearFields={clearFields}
        validated={validated}
        showfeedbackName={showfeedbackName}
        showfeedbackId={showfeedbackId}
        showfeedbackToken={showfeedbackToken}
        showfeedbackPostback={showfeedbackPostback}
        showfeedbackBotUrl={showfeedbackBotUrl}
        showfeedbackApiUrl={showfeedbackApiUrl}
        showfeedbackIdNlp={showfeedbackIdNlp}
        bot={bot}
        setBot={setBot}
        setShowfeedbackName={setShowfeedbackName}
        setShowfeedbackId={setShowfeedbackId}
        setShowfeedbackToken={setShowfeedbackToken}
        setShowfeedbackPostback={setShowfeedbackPostback}
        setShowfeedbackBotUrl={setShowfeedbackBotUrl}
        setShowfeedbackApiUrl={setShowfeedbackApiUrl}
        setShowfeedbackIdNlp={setShowfeedbackIdNlp}
        pageName={pageName}
        pageId={pageId}
        pageToken={pageToken}
        pagePostback={pageTokenPostback}
        pageBotUrl={pageBotUrl}
        pageApiUrl={pageApiUrl}
        pageIdNlp={pageIdNlp}
        setPageName={setPageName}
        setPageId={setPageId}
        setPageToken={setPageToken}
        setPagePostback={setPagePostback}
        setPageBotUrl={setPageBotUrl}
        setPageApiUrl={setPageApiUrl}
        setPageIdNlp={setPageIdNlp}
        AppRequesterConst={AppRequesterConst}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        navigate={navigate}
        dispatch={dispatch}
        cancelButton={cancelButton}
        getFacebookById={getFacebookById}
    />
};

export default ModalEditFacebookPageController;