import { Button, Col, Container, Form, FormControl, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import allStyles from "./styles";
import { Formik } from "formik";
import { FC } from "react";
import { setShowCreateParamModal } from "../../../../../../store/internal";
import { ICreateParamAuxiliarModal } from "./indexModal";

const CreateParamAuxiliarModal: FC<ICreateParamAuxiliarModal> = (props) => {
    return (
        <>
          <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
              <Modal.Title style={allStyles.textBlack}>
                    {props.t('home_departments.integration_tab.create_param_auxiliar.create_param')}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginLeft: "6%", marginRight: "4%", marginBottom: "2%" }}>
              <Container className="nopadding">
                <Formik
                        initialValues={{ name: '', label: '', information: '' }}
                    validate={values => {
                        var specialChars = /[^a-zA-Z0-9(-)(_)]/g;
                        let errors = {};

                        if (values.name.length === 0) {
                            errors['name'] = props.t('home_departments.integration_tab.create_param_auxiliar.validate.empty_name'); 
                            props.setShowFeedbackName(true);
                        } else if (values.name.includes(" ")) {
                            errors['name'] = props.t('home_departments.integration_tab.create_param_auxiliar.validate.name_space'); 
                            props.setShowFeedbackName(true);
                        } else if (values.name.match(specialChars)) {
                            errors['name'] = props.t('home_departments.integration_tab.create_param_auxiliar.name_special_char'); 
                            props.setShowFeedbackName(true);
                        } else if (props.integrationParams && props.integrationParams.find((param) => param.name === values.name)) {
                            errors['name'] = props.t('home_departments.integration_tab.create_param_auxiliar.used_name'); 
                          props.setShowFeedbackName(true);
                        } else {
                            props.setShowFeedbackName(false);
                        }

                        if (!values.information) {
                            errors["information"] = props.t('home_departments.integration_tab.create_param_auxiliar.validate.empty_name');
                            props.setShowFeedbackInformation(true);
                        } else {
                            props.setShowFeedbackInformation(false);
                        }

                        /* if (values.label.length === 0) {
                            errors['label'] = "Campo não pode estar vazio."; 
                            props.setShowFeedbackLabel(true);
                        } else {
                            props.setShowFeedbackLabel(false);
                        } */

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        props.setIntegrationParams((params) => {
                            if (params)
                                params.push({ name: values.name, label: values.label, information: values.information });

                            return params;
                        });
                        
                        props.dispatch(setShowCreateParamModal(false));
                    }}    
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,                
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={false}>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                        <Form.Label htmlFor="name">{props.t('home_departments.integration_tab.create_param_auxiliar.form_name.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={props.showFeedbackName}
                                                placeholder={props.t('home_departments.integration_tab.create_param_auxiliar.form_name.placeholder')}
                                                aria-label={props.t('home_departments.integration_tab.create_param_auxiliar.form_name.placeholder')}
                                                aria-describedby={props.t('home_departments.integration_tab.create_param_auxiliar.form_name.placeholder')}
                                            size='lg'
                                            type='name'
                                            id="name"
                                            name="name"
                                            maxLength={100}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackname">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                {/* <Col sm={true}>
                                    <Form.Label htmlFor="name">Nome<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={props.showFeedbackLabel}
                                            placeholder="Informe o nome do parâmetro"
                                            aria-label="Informe o nome do parâmetro"
                                            aria-describedby="Informe o nome do parâmetro"
                                            size='lg'
                                            type='label'
                                            id="label"
                                            name="label"
                                            maxLength={100}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.label}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbacklabel">
                                            {errors.label}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col> */}
                            </Row>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Form.Label htmlFor="name">{props.t('home_departments.integration_tab.create_param_auxiliar.form_info.title')}
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tooltip'>
                                                    {props.t('home_departments.integration_tab.create_param_auxiliar.form_info.tooltip')}
                                                </Tooltip>
                                            }
                                        >
                                            <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={props.showFeedbackInformation}
                                            placeholder={props.t('home_departments.integration_tab.create_param_auxiliar.form_info.placeholder')}
                                            aria-label={props.t('home_departments.integration_tab.create_param_auxiliar.form_info.placeholder')}
                                            aria-describedby={props.t('home_departments.integration_tab.create_param_auxiliar.form_info.placeholder')}
                                            size='lg'
                                            type='information'
                                            id="information"
                                            name="information"
                                            as="textarea"
                                            style={{ maxHeight: "10vh", minHeight: "10vh", height: "10vh" }}
                                            maxLength={200}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.information}
                                            htmlSize={200}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackurl">
                                            {errors.information}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Row>
                            <Row className="d-flex justify-content-center mt-4">
                              <Col xs={6} className="d-flex justify-content-start">
                                        <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => props.hideCreateParamModal()} >{props.t('home_departments.integration_tab.create_param_auxiliar.cancel')}</Button>
                              </Col>
                              <Col xs={6} className="d-flex justify-content-end">
                                        <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{props.t('home_departments.integration_tab.create_param_auxiliar.save')}</Button>
                              </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
              </Container>
          </Modal.Body>
        </>
      );
}

export default CreateParamAuxiliarModal;