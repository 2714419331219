import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { CallcenterService } from '../../services/callcenter/callcenter-service';
import { Ticket } from '../../domain/entities/ticket';
import { CallStatusEvent } from '../../services/callcenter/callcenter-service.model';

export const CallcenterContext = createContext<CallcenterContextType>(null);

export const CallcenterProvider = ({ children }) => {
  const [instance, setInstance] = useState<CallcenterService>(null);
  const [showPhoneCallcenter, setShowPhoneCallcenter] = useState(false);
  const [cancelReconnection, setCancelReconnection] = useState(false);
  
  const [sessionStatus, setSessionStatus] = useState<'sent_request' | 'disconnected' | 'connected' | 'connecting'>('disconnected');
  const [sessionCallcenterId, setSessionCallcenterId] = useState(undefined as string);
  const [sessionCredentials, setSessionCredentials] = useState({ username: '', password: '', display_name: '' });
  const [sessionTransportError, setSessionTransportError] = useState(false);
  
  const [eventType, setEventType] = useState(undefined as string);
  const [eventDescription, setEventDescription] = useState(undefined as string);

  const [callStatusEvent, setCallStatusEvent] = useState<CallStatusEvent>(undefined);
  const [callStatusDirection, setCallStatusDirection] = useState<undefined | 'incoming' | 'outgoing'>(undefined);
  const [callStatusDuration, setCallStatusDuration] = useState(undefined as number);
  const [callStatusIncomingNumber, setCallStatusIncomingNumber] = useState(undefined as string);
  const [callStatusIncomingName, setCallStatusIncomingName] = useState(undefined as string);
  const [callStatusIsMuted, setCallStatusIsMuted] = useState(false);
  const [callStatusIsHold, setCallStatusIsHold] = useState(false);
  const [callStatusIsActive, setCallStatusIsActive] = useState(false);
  const [callStatusStartDate, setCallStatusStartDate] = useState(undefined as Date);

  const [callInfoDisplay, setCallInfoDisplay] = useState(undefined as string);
  const [callInfoDestiny, setCallInfoDestiny] = useState(undefined as string);
  const [callInfoDestinyTransfer, setCallInfoDestinyTransfer] = useState(undefined as string);
  const [callInfoIdResponse, setCallInfoIdResponse] = useState(undefined as string);
  const [callInfoIsTransferAssisted, setCallInfoIsTransferAssisted] = useState(false);
  
  const [ticketInfo, setTicketInfo] = useState(undefined as Ticket);

  const ProviderValue = {
    instance, setInstance,
    showPhoneCallcenter, setShowPhoneCallcenter,
    cancelReconnection, setCancelReconnection,

    sessionStatus, setSessionStatus,
    sessionCallcenterId, setSessionCallcenterId,
    sessionCredentials, setSessionCredentials,
    sessionTransportError, setSessionTransportError,

    eventType, setEventType,
    eventDescription, setEventDescription,

    callStatusEvent, setCallStatusEvent,
    callStatusDirection, setCallStatusDirection,
    callStatusDuration, setCallStatusDuration,
    callStatusIncomingNumber, setCallStatusIncomingNumber,
    callStatusIncomingName, setCallStatusIncomingName,
    callStatusIsMuted, setCallStatusIsMuted,
    callStatusIsHold, setCallStatusIsHold,
    callStatusIsActive, setCallStatusIsActive,
    callStatusStartDate, setCallStatusStartDate,

    callInfoDisplay, setCallInfoDisplay,
    callInfoDestiny, setCallInfoDestiny,
    callInfoDestinyTransfer, setCallInfoDestinyTransfer,
    callInfoIdResponse, setCallInfoIdResponse,
    callInfoIsTransferAssisted, setCallInfoIsTransferAssisted,

    ticketInfo, setTicketInfo,
  }

  return (
    <CallcenterContext.Provider value={ProviderValue}>
      {children}
    </CallcenterContext.Provider>
  );
}

export type CallcenterContextType = {
  instance: CallcenterService;
  setInstance: Dispatch<SetStateAction<CallcenterService>>;

  showPhoneCallcenter: boolean;
  setShowPhoneCallcenter: Dispatch<SetStateAction<boolean>>;

  cancelReconnection: boolean;
  setCancelReconnection: Dispatch<SetStateAction<boolean>>;


  sessionStatus: 'sent_request' | 'disconnected' | 'connected' | 'connecting';
  setSessionStatus: Dispatch<SetStateAction<'sent_request' | 'disconnected' | 'connected' | 'connecting'>>;

  sessionCallcenterId: string;
  setSessionCallcenterId: Dispatch<SetStateAction<string>>;

  sessionCredentials: { username: string, password: string, display_name: string };
  setSessionCredentials: Dispatch<SetStateAction<{ username: string, password: string, display_name: string}>>;

  sessionTransportError: boolean;
  setSessionTransportError: Dispatch<SetStateAction<boolean>>;

  
  eventType: string;
  setEventType: Dispatch<SetStateAction<string>>;

  eventDescription: string;
  setEventDescription: Dispatch<SetStateAction<string>>;


  callStatusEvent: CallStatusEvent;
  setCallStatusEvent: Dispatch<SetStateAction<CallStatusEvent>>;
  
  callStatusDirection: undefined | 'incoming' | 'outgoing';
  setCallStatusDirection: Dispatch<SetStateAction<undefined | 'incoming' | 'outgoing'>>;

  callStatusDuration: number;
  setCallStatusDuration: Dispatch<SetStateAction<number>>;

  callStatusIncomingNumber: string;
  setCallStatusIncomingNumber: Dispatch<SetStateAction<string>>;
  
  callStatusIncomingName: string;
  setCallStatusIncomingName: Dispatch<SetStateAction<string>>;

  callStatusIsMuted: boolean;
  setCallStatusIsMuted: Dispatch<SetStateAction<boolean>>;

  callStatusIsHold: boolean;
  setCallStatusIsHold: Dispatch<SetStateAction<boolean>>;

  callStatusIsActive: boolean;
  setCallStatusIsActive: Dispatch<SetStateAction<boolean>>;

  callStatusStartDate: Date;
  setCallStatusStartDate: Dispatch<SetStateAction<Date>>;


  callInfoDisplay: string;
  setCallInfoDisplay: Dispatch<SetStateAction<string>>;

  callInfoDestiny: string;
  setCallInfoDestiny: Dispatch<SetStateAction<string>>;

  callInfoDestinyTransfer: string;
  setCallInfoDestinyTransfer: Dispatch<SetStateAction<string>>;

  callInfoIdResponse: string;
  setCallInfoIdResponse: Dispatch<SetStateAction<string>>;

  callInfoIsTransferAssisted: boolean;
  setCallInfoIsTransferAssisted: Dispatch<SetStateAction<boolean>>;

  
  ticketInfo: Ticket;
  setTicketInfo: Dispatch<SetStateAction<Ticket>>;
}
