import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button,
} from "react-bootstrap"
import allStyles from "./styles"
import ListConfigDomainController from "./listConfigDomain/indexController"
import { FC } from "react"
import { IFormConfigDomain } from "./indexModel"
import ModalRegisterDomainController from "./modalRegisterDomain/indexController"

const FormConfigDomain: FC<IFormConfigDomain> = (props) => {
    return (
        <>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                    className="mt-2"
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Autorização de domínios
                    </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Row style={allStyles.textFontGlay} className="d-flex justify-content-flex-start mt-2" >
                {
                    props.isEditAllowed &&
                    <Col sm={true} md={3}>
                        <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                            <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                        Adicionar domínio
                                    </Tooltip>
                                }
                            >
                                <Button size="lg" className="buttonWhite" style={{ width: '100%', marginTop: '1.5vh' }} onClick={props.handleOpenModal}>Adicionar domínio</Button>
                            </OverlayTrigger>
                        </p>
                    </Col>
                }
            </Row>
            <ModalRegisterDomainController companyId={props.companyId} callbackUpdateList={props.callbackUpdateList} />
            <ListConfigDomainController domainList={props.domains} companyId={props.companyId} callbackUpdateList={props.callbackUpdateList} isEditAllowed={props.isEditAllowed} />
        </>
    )
}

export default FormConfigDomain