import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useWhoIsViewing = () => {

    const socket = useContext(SocketContext);

    const [whoIsViewing, setWhoIsViewing] = useState<any>()

    useEffect(() => {
        socket.socket?.on('who-is-viewing', (data) => setWhoIsViewing(data))

        return () => {
            socket.socket?.off('who-is-viewing', setWhoIsViewing)
        }
    }, [socket.socket?.active])

    return { whoIsViewing }
}

export default useWhoIsViewing