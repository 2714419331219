import React, { useState, useEffect, FC } from 'react';
import { useDispatch } from "react-redux";

import { setShowEditTypeAgent } from '../../../../../store/internal'
import { useNavigate } from 'react-router-dom';
import { ModalEditTokenChatbot } from './indexModel';
import EditAgentType from './index'
import constsApi from "../../../../../services/constsApi";

const EditAgentTypeController: FC<ModalEditTokenChatbot> = (props) => {
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [agentAdministrator, setAgentAdministrator] = useState(false);

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setModalShow(props.visibility);
    if(props.data) {
      setAgentAdministrator(props.data.profile_id === constsApi.profile[0].id)
    }
  }, [props.visibility, props.data]);

  const Hide = () => {
    dispatch(setShowEditTypeAgent(false));
  }

  return (
    <EditAgentType
      modalShow={modalShow}
      Hide={Hide}
      props={props}
      dispatch={dispatch}
      navigate={navigate}
      setIsLoading={setIsLoading}
      agentAdministrator={agentAdministrator}
      setAgentAdministrator={setAgentAdministrator}
    />
  );
}

export default EditAgentTypeController;