import React, { useState, useEffect, FC } from 'react';
import { ModalSendTicketCopyInterface } from './indexModel';
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import styles from './styles';
import { ISendTicketCopyBodyController, ModalSendTicketCopyController } from './indexController';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/loading';
import { useTranslation } from 'react-i18next';

const ModalSendTicketCopy: FC<ModalSendTicketCopyInterface> = (props) => {
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(props.visibility);
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ctrlConstructorBody: ISendTicketCopyBodyController = {
    dispatch, navigate, props,
    setModalShow,
    setEmails, emails,
    setIsLoading,
    t
  };

  const ctrl = new ModalSendTicketCopyController(ctrlConstructorBody);

  useEffect(() => {
    setModalShow(props.visibility);
    return () => {};
  }, [props.visibility]);

  const submit = async (values: ISendTicketCopy) => {
    ctrl.submit(values);
  }

  return (
    <>
      { isLoading && <Loading visibility={isLoading} customStyle={{ zIndex: '9999', top: '0', left: '0' }} /> }
      <Modal show={modalShow} onHide={() => ctrl.hide()} aria-labelledby="" size="sm" centered={true} >
        <Modal.Header closeButton style={styles.header}>
          <Modal.Title style={styles.textBlack}>
            {t('components.modals.send_ticket_copy.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.body}>
          <Container>
            <div style={styles.modalInfo}>
              <span style={styles.modalInfoTitle}>{t('components.modals.send_ticket_copy.subject_label')}: </span>
              {t('components.modals.send_ticket_copy.subject_value')} {props.data?.subject} (#{props.data?.ticket_reference_id})
            </div>
            <Formik 
              initialValues={{ message: ''}}
              onSubmit={submit}
            >
              {({ values, errors, handleSubmit, handleChange, handleBlur }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <div style={styles.formItem}>
                    <Form.Label htmlFor='emails'>
                      {t('components.modals.send_ticket_copy.emails_label')}
                      <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black', marginLeft: '3px' }}>*</span>
                    </Form.Label>
                    <Tags
                      name="emails"
                      className="form-control-Default-text-area2 mb-3"
                      placeholder={t('components.modals.send_ticket_copy.emails_placeholder')}
                      onChange={(e)=>ctrl.onChangeEmails(e)}
                      settings={{ pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i }}
                      required
                    />
                  </div>

                  <div style={styles.formItem}>
                    <Form.Label htmlFor='message'>{t('components.modals.send_ticket_copy.message_label')}</Form.Label>
                    <InputGroup>
                      <FormControl 
                        style={{ height: '10vh' }}
                        className="form-control-Default-text-area2"
                        placeholder={t('components.modals.send_ticket_copy.message_placeholder')}
                        aria-label={t('components.modals.send_ticket_copy.message_placeholder')}
                        aria-describedby={t('components.modals.send_ticket_copy.message_placeholder')}
                        as='textarea'
                        type='text'
                        name='message'
                        id='form_message'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                      />
                      <Form.Control.Feedback type="invalid" id="feedbackmessage">
                        {errors.message}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </div>

                  <Row style={{ ...styles.textFontGlay, ...styles.formButtons }} className="d-flex justify-content-center mt-4">
                    <Col md={6} className="d-flex justify-content-start">
                      <Button size='lg' className="buttonWhite" style={{ width: '50%' }} onClick={() => ctrl.hide()}>
                        {t('components.modals.send_ticket_copy.btn_cancel')}
                      </Button>
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                      <Button type='submit' size='lg' className="buttonBlue" style={{ width: '50%' }} disabled={emails.length === 0 || isLoading}>
                        {t('components.modals.send_ticket_copy.btn_send')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

interface ISendTicketCopy {
  message?: string;
}

export default ModalSendTicketCopy;