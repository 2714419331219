import { Formik } from "formik";
import { t } from "i18next";
import { Col, Row, Form, Button } from "react-bootstrap";
import ListOFAgentsController from "../../../homeAgents/listOfAgents/indexController";

const NotificationTicketTab = ({

    onSubmit,
    validate,
    ableNotification,
    setAbleNotification,
    callbackModalAgents,
    currentAgents,
    currentHasMoreInformation,
    setCurrentAgentFunction,
    callbackOrderByEmployees,
    updateListAfterDelete,
    updateListfunction,
    showCreateAgent,
    setEmployes,
    setAllMarked

}) => {
    return (
        <>
            <Row>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                    }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setValues,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div style={{ overflow: 'auto', overflowX: 'hidden', height: '70vh', flexDirection: 'column', display: 'flex' }}>
                                <Row style={{ /* height: '36%' */ }} className=" d-flex justify-content-start my-3">
                                    <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                        <Row>
                                            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.notification.title')}</p>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="html" >{ableNotification ? t('configurations_ticket.notification.disable') : t('configurations_ticket.notification.able')}</Form.Label>
                                            <Col md={1} style={{ width: '5%', marginTop: '0.4vh' }}>
                                                <Form.Check
                                                    type='checkbox'
                                                    id={"checkbox"}
                                                    checked={ableNotification}
                                                    onChange={() => setAbleNotification(!ableNotification)}
                                                    className="form-check-input-2"
                                                />
                                            </Col>
                                            <Col >
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '0.4vh', marginLeft: '5px' }}>
                                                    {ableNotification ?
                                                        t('configurations_ticket.notification.active')
                                                        :
                                                        t('configurations_ticket.notification.desactive')
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        {ableNotification ?
                                            <>
                                                <Row>
                                                    <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.notification.select_agent_title')}</p>
                                                </Row>
                                                <Row>
                                                    <Col >
                                                        <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '0.4vh' }}>
                                                            {t('configurations_ticket.notification.select_agent_subtitle')}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <></>
                                        }
                                    </Col>
                                </Row>
                                {ableNotification ?
                                    <ListOFAgentsController datas={currentAgents} updateListAfterDelete={updateListAfterDelete} updateListfunction={updateListfunction} HasMoreInformation={currentHasMoreInformation} showCreateAgent={showCreateAgent}
                                        setCurrentAgentFunction={(value) => setCurrentAgentFunction(value)} callbackModalAgents={callbackModalAgents} callbackOrderByEmployees={(column, order) => callbackOrderByEmployees(column, order)} isNotification={true}
                                        setEmployes={setEmployes} setAllMarked={setAllMarked} />
                                    :
                                    <></>
                                }
                            </div>
                            <Row /* style={allStyles.textFontGlay} */ className="d-flex mt-4">
                                <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                    <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => { document.location.reload(); }} >{t('configurations_ticket.survey_tab.cancel')}</Button>
                                </Col>
                                <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                    <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{t('configurations_ticket.survey_tab.save')}</Button>
                                </Col>
                            </Row>
                        </Form>

                    )}

                </Formik>
            </Row>
        </>
    )
}

export default NotificationTicketTab;