import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useTicketBlock = () => {

    const socket = useContext(SocketContext);

    const [ticketBlock, setTicketBlock] = useState<any>()

    useEffect(() => {

        socket.socket?.on('ticket-block', (data) => setTicketBlock(data))

        return () => {
            socket.socket?.off('ticket-block', setTicketBlock)
        }

    }, [socket.socket?.active])

    return { ticketBlock }
}

export default useTicketBlock