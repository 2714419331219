import React, { ChangeEventHandler, FC } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { ISelectComponent } from './indexModel';

const SelectComponent:FC<ISelectComponent> = (props) => {
    return (
        <MultiSelect
            className={props.className !== undefined? props.className: ""}
            options={props.tempOptions && props.tempOptions.length > 0 ? props.tempOptions : []}
            value={props.tempSelecteds && props.tempSelecteds.length > 0 ? props.tempSelecteds : []}
            onChange={(value) => props.onChange(value)}
            closeOnChangedValue={props.singleSelect}
            hasSelectAll={props.hasAllOption && !props.singleSelect}
            labelledBy={props.title}
            overrideStrings={
                props.overrideStrings
            }
            ItemRenderer={({option, onClick}) => props.customItemOption({option, onClick})}
            filterOptions={props.filterOptions? props.filterOptions: undefined}
            disableSearch={props.disableSearch? props.disableSearch: false}
            disabled={props.disabled? props.disabled: false}
            valueRenderer={props.customValueRenderer}
        />
    );
}

export default SelectComponent;