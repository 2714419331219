import React, { FC } from 'react';

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';

import SelectComponent from '../selectComponent';
import PhoneInput from 'react-phone-input-2';
import pt from 'react-phone-input-2/lang/pt.json'
import { IModalEditPhone } from './indexModal';
import {
  setShowEditEmailModal,
  setShowAlertFeedback
} from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { useTranslation } from 'react-i18next';
import SelectComponentController from '../selectComponent/indexController';

const ModalEditPhone: FC<IModalEditPhone> = (props) => {
  return (
    <Modal show={props.modalShow} onHide={() => props.Hide()} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          { props.t("components.modal_phone.title") }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{
              numero: props.number ? props.number : ""
            }}
            validate={values => {
            }}

            onSubmit={props.onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={props.validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center mb-4">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{ props.t("components.modal_phone.label_number") }</Form.Label>
                    {/* <PhoneInputDropdown phoneNumber={values.phone} values={values} setValues={setValues} type={PhoneType.Phone} tag={phoneTag} setPhoneError={setPhoneError} /> */}
                    <PhoneInput
                      placeholder={props.t("components.modal_phone.phone_input_placeholder")}
                      value={values?.numero}
                      onChange={(e) => props.SetCustomers_phone(e)}
                      onBlur={handleBlur}
                      inputProps={{
                        name: 'Telefone',
                        required: true,
                        border: 'none',
                        style: { width: '100%', font: ' var(--px15_19-font-Roboto)', height: '32px', border: props.phoneBorder }
                      }}
                      country={'br'}
                      // onlyCountries={['br', 'pt']}
                      localization={pt}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{ props.t("components.modal_phone.sector_label") }<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}></span></Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      className={"multiselectForm mb-3"}
                      options={props.options_departments}
                      setOptions={props.setOptions_departments}
                      selecteds={props.selected_departments}
                      setSelecteds={props.setSelected_departments}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={props.t("components.modal_phone.empty_sector")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.t("components.modal_phone.selected_sector"),
                          "clearSearch": props.t("components.modal_phone.clear_search_sector"),
                          "clearSelected": props.t("components.modal_phone.clear_selected_sector"),
                          "noOptions": props.t("components.modal_phone.no_options_sector"),
                          "search": props.t("components.modal_phone.search_sector"),
                          "selectAll": props.t("components.modal_phone.select_all_sector"),
                          "selectAllFiltered": props.t("components.modal_phone.select_all_filtered_sector"),
                          "selectSomeItems": props.t("components.modal_phone.select_some_itens_sector"),
                          "create": props.t("components.modal_phone.create_sector"),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={true}
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-3">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => props.Hide()} >{ props.t("components.modal_phone.btn_exit") }</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{ props.t("components.modal_phone.btn_save") }</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditPhone;
