import { useSelector } from 'react-redux'
import { Col, Container, Row, Tab, Tabs, Button } from "react-bootstrap";
import { Channels } from "../../../../core/enums/channels";
import Loading from "../../../../components/loading";
import CallNotificationBar from "../../components/callNotificationBar";
import { getShowAcquirePlatformComponent, getShowCallNotificationBar, getShowUpdatedBot } from "../../../../store/internal";
import AcquirePlatformNotificationBar from "../../components/acquirePlatformNotificationBar";
import EditTokenChatbot from "../../components/modalEditTokenChatbot/indexController";
import ListBots from "./listOfBots/indexController";
import { ContractStatus } from "../../../../core/enums/contract-status";
import TokenChatbot from './tokenComponent/indexController';
import ChannelLabel from "../../components/channelLabel";
import ButtonStatusAgent from "../../components/buttonStatusAgent";
import ButtonListNotificationController from "../../../../components/buttonListNotification/indexController";
import GeneralController from './general/indexController';

const HomeBotxpress = ({
  t,
  isLoading,
  setIsLoading,
  values,
  data,
  dataBot,
  filterDataBot,
  getBotCxpress,
  sendConfirmation,
  handleClick,
  dataCompany,
  getCompanyId,
  activeTab,
  setActiveTab
}) => {
  return (
    <>
      <Loading visibility={isLoading}></Loading>
      <EditTokenChatbot token={values.token.value} id={values.company.id} visibility={useSelector(getShowUpdatedBot)}></EditTokenChatbot>
      <Container fluid className="nopadding">
        <div className="wrapper">
          {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubChatbots} className="nopadding" /> */}
          <div className="content ps-0 pt-0">
            <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
            <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
            <Row className="nopadding mt-3 ps-4">
              <Col md={7} style={{ marginBottom: '2vh' }}>
                <Row>
                  <Col className="d-flex justify-content-start align-self-start ps-0">
                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{ t("configurations.chatbots.title_welcome") }</div>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <div className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{ t("configurations.chatbots.title_here") }</div>
                </Row>
              </Col>
              {values.company.contractStatusId === ContractStatus.Active ?
                <Col className="d-flex justify-content-end">
                  {/* <TokenChatbot></TokenChatbot> */}
                  <ButtonListNotificationController />
                  <ButtonStatusAgent />
                </Col>
                :
                <Col className="d-flex justify-content-end">
                  <ButtonListNotificationController />
                  <ButtonStatusAgent />
                </Col>
              }
            </Row>
            {values.chatbot.hasBot && (data[0]?.isAllowedToSendMessage === true || data[0]?.isAllowedToSendMessage === undefined) ?
              <Row className="ms-0 me-1 ps-4 ContentTabs">
                <Tabs mountOnEnter={true} unmountOnExit={true} activeKey={activeTab} id="channelsTabs" style={{ padding: 0, margin: '3vh 0px 0px 0px' }} onSelect={(key) => setActiveTab(key)}>
                  {values.company.contractStatusId === ContractStatus.Active ?
                    <Tab eventKey={"33be81d8-88ac-4e08-bb4d-cf69fd23267f"} title={ChannelLabel("Whatsapp", Channels.WhatsApp)} className='ps-0 ms-0'>
                      <ListBots datas={filterDataBot(Channels.WhatsApp)} channel={Channels.WhatsApp} updateList={getBotCxpress} dataCompany={dataCompany} setActiveTab={setActiveTab} />
                    </Tab>
                    :
                    <></>
                  }
                  <Tab eventKey={"4a7b71bb-6432-4794-ad11-f978b067871d"} title={ChannelLabel("Webchat", Channels.Webchat)} className='ps-0 ms-0'>
                    <ListBots datas={filterDataBot(Channels.Webchat)} channel={Channels.Webchat} updateList={getBotCxpress} dataCompany={dataCompany} setActiveTab={setActiveTab} />
                  </Tab>
                  {values.company.contractStatusId === ContractStatus.Active ?
                    <Tab eventKey={"aa1cea93-23de-46aa-af14-d6f766acf5c8"} title={ChannelLabel("Facebook", Channels.Facebook)} className='ps-0 ms-0'>
                      <ListBots datas={filterDataBot(Channels.Facebook)} channel={Channels.Facebook} updateList={getBotCxpress} dataCompany={dataCompany} setActiveTab={setActiveTab} />
                    </Tab>
                    :
                    <></>
                  }
                  {values.company.contractStatusId === ContractStatus.Active ?
                    <Tab eventKey={"f28cb4a4-e42b-425a-8011-59d5f9c1d0f2"} title={ChannelLabel("Instagram", Channels.Instagram)} className='ps-0 ms-0'>
                      <ListBots datas={filterDataBot(Channels.Instagram)} channel={Channels.Instagram} updateList={getBotCxpress} dataCompany={dataCompany} setActiveTab={setActiveTab} />
                    </Tab>
                    :
                    <></>
                  }
                  {values.company.contractStatusId === ContractStatus.Active ?
                    <Tab eventKey={"6977996d-11d1-44cc-a863-2e1d9c24e046"} title={ChannelLabel('Voicebot', 'voicebot')} className='ps-0 ms-0'>
                      <ListBots datas={filterDataBot(Channels.Telefonia, true)} channel={Channels.Telefonia} updateList={getBotCxpress} dataCompany={dataCompany} setActiveTab={setActiveTab} voice={true} />
                    </Tab>
                    :
                    <></>
                  }
                  <Tab eventKey={"general"} title={ChannelLabel(t("configurations.chatbots.general.general_title_tab"), 'general', { iconLarger: false })} style={{ marginTop: '4vh', padding: 0 }}>
                    <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '5vh', display: 'flex', alignItems: 'center' }}>
                      {t("configurations.chatbots.general.general_title_internal")}
                    </p>
                    <GeneralController isLoading={isLoading} setIsLoading={setIsLoading} dataCompany={dataCompany} getCompanyId={getCompanyId} />
                  </Tab>

                </Tabs>

              </Row>
              :
              <Row className="nopadding mt-3 ps-4">
                <p className='ps-0' style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#1E1E1E', marginTop: '5vh' }}>{ t("configurations.chatbots.request_chatbot_integration") }</p>
                {values.company.contractStatusId === ContractStatus.Active ?
                  <p className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{ t("configurations.chatbots.requestChatbotLabel1") }</p>
                  :
                  <p className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{ t("configurations.chatbots.requestChatbotLabel2") }</p>

                }
                <hr style={{ border: "1px solid #E8E8E8" }} />
                <Button type='submit' size='lg' className="buttonBlue" style={{ width: '25%' }} onClick={sendConfirmation}>{ t("configurations.chatbots.requestChatbotButton") }</Button>

                {values.company.contractStatusId === ContractStatus.Active ?
                  <p className='ps-0' style={{ font: 'var(--px15_19_Bold-font)', color: '#4A69BD', marginTop: '1vh' }}><span>{ t("configurations.chatbots.has_token") } </span>
                    <span className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070', textDecoration: "underline", cursor: 'pointer' }} onClick={() => handleClick(values.updatedBot)}>{ t("configurations.chatbots.use_your_token") }</span>
                  </p>
                  :
                  <></>
                }
              </Row>
            }
          </div>
        </div>
      </Container>
    </>
  );
}

export default HomeBotxpress;