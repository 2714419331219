import { useState } from "react"
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import constsApi from "../../../../../services/constsApi"

const MasterAccess = ({
    masterAcess,
    isLoading,
    changeStatus,
    t
}) => {   
    return (
        <>
            <Row className="" id="masterAccess" style={{ marginTop: '1vh', padding: 0 }}>
                <Col>
                    <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: "#0C2461", marginTop: '4vh', marginBottom: '1vh' }}>
                        {t('configurations.security.tab_master.content_title')}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={6} style={{ width: '740px', marginTop: '-0.5vh' }}>
                    <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>
                        {t("configurations.security.tab_master.content_description")}
                    </p>
                </Col>
                    <div className="switch-input">
                        <span>{t("configurations.security.tab_master.title")}</span>
                        {
                            <OverlayTrigger
                                key='top'
                                placement="top"
                                overlay={
                                    <Tooltip id="tootip" className="tooltip-arrow-Top">
                                        {masterAcess ? t('configurations.security.check_active') : t('configurations.security.check_inactive') }
                                    </Tooltip>
                                }
                            >
                                <label className="switch">
                                    <input
                                        disabled={isLoading}
                                        type="checkbox"
                                        checked={masterAcess}
                                        onChange={() => changeStatus(masterAcess)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </OverlayTrigger>
                        }
                    </div>
                </Row>
            </>
    )
}

export default MasterAccess