import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Container, Form, FormLabel, InputGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import UserService from '../../../../../services/user-service';
import { DataCompanyInterface } from '../../../../../services/requestsInterfacesModel';

import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import gregorian_pt_br from "react-date-object/locales/gregorian_pt_br";
import InputIcon from 'react-multi-date-picker/components/input_icon';

import './styles.css';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { getChatbot } from '../../../../../store/chatbot';

const ScheduleServiceTab = ({
    initSchedule,
    endSchedule,
    activeSchedule,
    activeWeekDays,
    messageOffService,
    messageOnService,
    scheduleDays,
    setInitSchedule,
    setEndSchedule,
    setActiveSchedule,
    setScheduleDays,
    setMessageOffService,
    setMessageOnService,
    navigate,
    dispatch,
    props,
    updateForm,
    setShowFeedbackMessageOffService,
    setShowFeedbackMessageOnService,
    setShowFeedbackInit,
    showFeedbackInit,
    setShowFeedbackEnd,
    showFeedbackEnd,
    values,
    AppRequesterConst,
    fullTime,
    CheckWeekDay,
    HourInputForm,
    copyAllWeekDays,
    handleMessageOffService,
    handleMessageOnService,
    datePickerRef,
    showFeedbackMessageOffService,
    showFeedbackMessageOnService,
    t,
    transferService,
    enableTransferService,
    disableTransferService,
    hasBot,
}) => {

    return (
        <div>
            <Row style={{ marginTop: '1vh', padding: 0 }}>
                { updateForm &&
                    <Formik
                        initialValues={{
                            init_sunday: initSchedule.sunday,
                            init_monday: initSchedule.monday,
                            init_tuesday: initSchedule.tuesday,
                            init_wednesday: initSchedule.wednesday,
                            init_thursday: initSchedule.thursday,
                            init_friday: initSchedule.friday,
                            init_saturday: initSchedule.saturday,

                            end_sunday: endSchedule.sunday,
                            end_monday: endSchedule.monday,
                            end_tuesday: endSchedule.tuesday,
                            end_wednesday: endSchedule.wednesday,
                            end_thursday: endSchedule.thursday,
                            end_friday: endSchedule.friday,
                            end_saturday: endSchedule.saturday,

                            activeSchedule: activeSchedule,
                            sunday_active: activeWeekDays.sunday,
                            monday_active: activeWeekDays.monday,
                            tuesday_active: activeWeekDays.tuesday,
                            wednesday_active: activeWeekDays.wednesday,
                            thursday_active: activeWeekDays.thursday,
                            friday_active: activeWeekDays.friday,
                            saturday_active: activeWeekDays.saturday,

                            messageOffService: messageOffService,
                            messageOnService: messageOnService,
                        }}
                        validate={values => {
                            let errors = {};
                            setActiveSchedule(values.activeSchedule);
                            setInitSchedule({
                                sunday: values.init_sunday === null ? "00:00:00" : values.init_sunday,
                                monday: values.init_monday === null ? "00:00:00" : values.init_monday,
                                tuesday: values.init_tuesday === null ? "00:00:00" : values.init_tuesday,
                                wednesday: values.init_wednesday === null ? "00:00:00" : values.init_wednesday,
                                thursday: values.init_thursday === null ? "00:00:00" : values.init_thursday,
                                friday: values.init_friday === null ? "00:00:00" : values.init_friday,
                                saturday: values.init_saturday === null ? "00:00:00" : values.init_saturday
                            });
                            setEndSchedule({
                                sunday: values.end_sunday === null ? "23:59:00" : values.end_sunday,
                                monday: values.end_monday === null ? "23:59:00" : values.end_monday,
                                tuesday: values.end_tuesday === null ? "23:59:00" : values.end_tuesday,
                                wednesday: values.end_wednesday === null ? "23:59:00" : values.end_wednesday,
                                thursday: values.end_thursday === null ? "23:59:00" : values.end_thursday,
                                friday: values.end_friday === null ? "23:59:00" : values.end_friday,
                                saturday: values.end_saturday === null ? "23:59:00" : values.end_saturday
                            });

                            // validandno messagem fora
                            setMessageOffService((messageOff) => {
                                if (messageOff?.length > 255) {
                                    setShowFeedbackMessageOffService(true);
                                    errors['messageOffService'] = t('home_departments.schedule_service_tab.validate.message_off_service');
                                } else if (messageOff === "" || messageOff === null) {
                                    setShowFeedbackMessageOffService(true);
                                    errors['messageOffService'] = t('home_departments.schedule_service_tab.empty_field');
                                } else {
                                    setShowFeedbackMessageOffService(false);
                                }
                                return messageOff;
                            });

                            // validando messagem dentro
                            setMessageOnService((messageOn) => {
                                if (messageOn?.length > 500) {
                                    setShowFeedbackMessageOnService(true);
                                    errors['messageOnService'] = t('home_departments.schedule_service_tab.validate.message_on_service');
                                } else if(messageOn === "" || messageOn === null) {
                                    setShowFeedbackMessageOnService(true);
                                    errors['messageOnService'] = t('home_departments.schedule_service_tab.empty_field');
                                } else {
                                    setShowFeedbackMessageOnService(false);
                                }
                                return messageOn;
                            });

                            for (const init in initSchedule) {
                                let newError = {};
                                let initDay = "init_" + init;
                                let endDay = "end_" + init;

                                // if ((initSchedule[init] === null && endSchedule[init] !== null || initSchedule[init] !== null && endSchedule[init] === null) || (initSchedule[init] === undefined && endSchedule[init] !== undefined || initSchedule[init] !== undefined && endSchedule[init] === undefined)) {
                                // if (!initSchedule[init] && endSchedule[init] || initSchedule[init] && !endSchedule[init]) {
                                if (!values[initDay] && values[endDay] || values[initDay] && !values[endDay]) {
                                    // if (!initSchedule[init]) {
                                    if (!values[initDay]) {
                                        newError[initDay] = true;
                                        errors[initDay] = t('home_departments.schedule_service_tab.validate.field_required');
                                        setShowFeedbackInit(Object.assign(showFeedbackInit, newError));
                                    } else {
                                        newError[endDay] = true;
                                        errors[endDay] = t('home_departments.schedule_service_tab.validate.field_required');
                                        setShowFeedbackEnd(Object.assign(showFeedbackEnd, newError));
                                    }
                                } else if (values[initDay] && values[endDay] && values[initDay] >= values[endDay]) {
                                    newError[initDay] = true;
                                    newError[endDay] = true;
                                    setShowFeedbackInit(Object.assign(showFeedbackInit, newError));
                                    setShowFeedbackEnd(Object.assign(showFeedbackEnd, newError));

                                    errors[initDay] = t('home_departments.schedule_service_tab.validate.decrease_hour');
                                    errors[endDay] = t('home_departments.schedule_service_tab.validate.increase_hour');
                                } else {
                                    newError[initDay] = false;
                                    newError[endDay] = false;

                                    setShowFeedbackInit(Object.assign(showFeedbackInit, newError));
                                    setShowFeedbackEnd(Object.assign(showFeedbackEnd, newError));
                                }
                            }

                            return errors;
                        }}

                        onSubmit={async (valuesForm: {
                                init_sunday: string,
                                init_monday: string,
                                init_tuesday: string,
                                init_wednesday: string,
                                init_thursday: string,
                                init_friday: string,
                                init_saturday: string,

                                end_sunday: string,
                                end_monday: string,
                                end_tuesday: string,
                                end_wednesday: string,
                                end_thursday: string,
                                end_friday: string,
                                end_saturday: string,

                                activeSchedule: boolean,
                                sunday_active: boolean,
                                monday_active: boolean,
                                tuesday_active: boolean,
                                wednesday_active: boolean,
                                thursday_active: boolean,
                                friday_active: boolean,
                                saturday_active: boolean,

                                messageOffService: string,
                                messageOnService: string,
                            }
                        ) => {
                            let non_attendance_days = [];
                            scheduleDays?.map((day) => {
                                let formatedDay = day.format("YYYY-MM-DD");

                                // se a formato da data estiver errado a data não será enviada
                                if (formatedDay.length !== 0)
                                    non_attendance_days.push(formatedDay);
                                /* else 
                                    dispatch(setShowAlertFeedback({ visibility: true, signalIcon: false, message: "Datas editadas no formato errado não serão enviadas." })); */
                            });

                            const formatHour = (hour: string) => {
                                if (hour)
                                    return hour.split(":").slice(0, 2).join(":");
                                return hour;
                            }

                            let opening_hours = {};
                            const nonHours = { start_time: null, end_time: null };
                            // if (valuesForm.activeSchedule) {
                                opening_hours = {
                                    sunday: valuesForm.sunday_active? 
                                        {
                                            start_time: formatHour(valuesForm.init_sunday) === "" ? null : formatHour(valuesForm.init_sunday),
                                            end_time: formatHour(valuesForm.end_sunday) === "" ? null : formatHour(valuesForm.end_sunday),
                                        }: nonHours,
                                    monday: valuesForm.monday_active? 
                                        {
                                            start_time: formatHour(valuesForm.init_monday) === "" ? null : formatHour(valuesForm.init_monday),
                                            end_time: formatHour(valuesForm.end_monday) === "" ? null : formatHour(valuesForm.end_monday),
                                        }: nonHours,
                                    tuesday: valuesForm.tuesday_active? 
                                        {
                                            start_time: formatHour(valuesForm.init_tuesday) === "" ? null : formatHour(valuesForm.init_tuesday),
                                            end_time: formatHour(valuesForm.end_tuesday) === "" ? null : formatHour(valuesForm.end_tuesday),
                                        }: nonHours,
                                    wednesday: valuesForm.wednesday_active? 
                                        {
                                            start_time: formatHour(valuesForm.init_wednesday) === "" ? null : formatHour(valuesForm.init_wednesday),
                                            end_time: formatHour(valuesForm.end_wednesday) === "" ? null : formatHour(valuesForm.end_wednesday),
                                        }: nonHours,
                                    thursday: valuesForm.thursday_active? 
                                        {
                                            start_time: formatHour(valuesForm.init_thursday) === "" ? null : formatHour(valuesForm.init_thursday),
                                            end_time: formatHour(valuesForm.end_thursday) === "" ? null : formatHour(valuesForm.end_thursday),
                                        }: nonHours,
                                    friday: valuesForm.friday_active? 
                                        {
                                            start_time: formatHour(valuesForm.init_friday) === "" ? null : formatHour(valuesForm.init_friday),
                                            end_time: formatHour(valuesForm.end_friday) === "" ? null : formatHour(valuesForm.end_friday),
                                        }: nonHours,
                                    saturday: valuesForm.saturday_active? 
                                        {
                                            start_time: formatHour(valuesForm.init_saturday) === "" ? null : formatHour(valuesForm.init_saturday),
                                            end_time: formatHour(valuesForm.end_saturday) === "" ? null : formatHour(valuesForm.end_saturday),
                                        }: nonHours
                                }
                            /* } else {
                                opening_hours = {
                                    sunday: nonHours,
                                    monday: nonHours,
                                    tuesday: nonHours,
                                    wednesday: nonHours,
                                    thursday: nonHours,
                                    friday: nonHours,
                                    saturday: nonHours
                                }
                            } */

                            const headers = UserService.getHeaders();

                            const jsonSend = {
                                id: values.company.id,
                                non_attendance_days: non_attendance_days,
                                opening_hours: opening_hours,
                                use_opening_hours: valuesForm.activeSchedule,
                                message_opening_hours: messageOffService,
                                message_in_attendance: messageOnService,
                                transfer_outside_opening_hours: transferService,
                            };

                            await AppRequesterConst.Put(
                                '/company', jsonSend, { headers },
                                () => { },
                                (data: DataCompanyInterface) => {
                                    if (data.status === 200) {
                                        /* setTimeout(() => {
                                            dispatch(setShowAlertFeedback({ visibility: true, signalIcon: true, message: "Alterações salvas." }));
                                        }, 2000); */
                                        dispatch(setShowAlertFeedback({ visibility: true, signalIcon: true, message: t('home_departments.schedule_service_tab.changes_saved') }));
                                    }
                                },
                                (error: any) => { 
                                    dispatch(setShowAlertFeedback({ visibility: true, signalIcon: false, message: t('errors.defaultErrorMessage') }));
                                 }, navigate, dispatch, props.setIsLoading
                            );
                        }}
                    >
                        {({
                            values,
                            errors,
                            // touched,
                            setFieldTouched,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div style={{ overflow: 'auto', overflowX: 'hidden', height: '68vh', flexDirection: 'column', display: 'flex' }}>
                                    <Row>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('home_departments.schedule_service_tab.days_on_service')}</p>
                                        <Col md={6} style={{ width: '740px', marginTop: '-1vh' }}>
                                            <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t('home_departments.schedule_service_tab.days_on_service_subtitle')}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputGroup className='d-flex align-items-center'>
                                            <Form.Check
                                                type='checkbox'
                                                id="activeSchedule"
                                                defaultChecked={activeSchedule}
                                                onChange={handleChange}
                                                onClick={() => {
                                                    let active = document.getElementById("activeSchedule") as HTMLInputElement;
                                                    values.activeSchedule = active.checked;

                                                    if (active.checked === false)
                                                        Object.assign(values, ...fullTime());
                                                }}
                                                className="form-check-input-2 d-flex align-items-center"
                                            />
                                            <FormLabel 
                                                onClick={() => {
                                                    let active = document.getElementById("activeSchedule") as HTMLInputElement;
                                                    active.click();
                                                }}
                                                className='ms-1' style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#000', margin: '0px', userSelect: 'none' }}>
                                                {t('home_departments.schedule_service_tab.enable_service_time')}
                                            </FormLabel>
                                        </InputGroup>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={2} >
                                            <InputGroup style={{ width: '6.5vw' }}>
                                                <Table className='scheduleTable'> 
                                                    <thead>
                                                        <tr className='TicketsRowCollums'>
                                                            <th> </th>
                                                            <th className='tableColHead'> {t('home_departments.schedule_service_tab.start')} </th>
                                                            <th className='tableColHead'> {t('home_departments.schedule_service_tab.end')} </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className='TicketsRowCollums'>
                                                            <td>
                                                                {CheckWeekDay("sunday_active", values.sunday_active, handleChange, values, t('home_departments.schedule_service_tab.days.sun'))}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_sunday", values, showFeedbackInit, errors)} 
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_sunday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                        </tr>
                                                        <tr className='TicketsRowCollums'>
                                                            <td style={{ textAlign: 'center' }}>     
                                                                {CheckWeekDay("monday_active", values.monday_active, handleChange, values, t('home_departments.schedule_service_tab.days.mon'))}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_monday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_monday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                            <td style={{ border: 'none' }}>
                                                                {/* <Row className='mb-4'> */}
                                                                    <span style={{  alignItems: 'center !important', display: 'flex' }}>
                                                                        <span>
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                    {t('home_departments.schedule_service_tab.copy_time')}
                                                                                    </Tooltip>
                                                                                }
                                                                                placement='top'
                                                                            >
                                                                            <Button onClick={() => { copyAllWeekDays(values, setFieldTouched); }} disabled={!values.activeSchedule && !values.monday_active} style={{ background: 'transparent', border: 'none', padding: '0px', alignItems: 'center' }}>
                                                                                    <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2C4CA2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                        <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                                                                                        <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                                                                    </svg>
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </span>
                                                                        <div className='d-flex align-items-center ms-1'> 
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                    {t('home_departments.schedule_service_tab.copy_time')}
                                                                                    </Tooltip>
                                                                                }
                                                                                placement='top'
                                                                            >
                                                                            <Button onClick={() => { copyAllWeekDays(values, setFieldTouched); }} disabled={!values.activeSchedule && !values.monday_active} style={{ font: "calc(0.3rem + 0.55vw) Roboto", color: '#000', border: 'none', background: "transparent", padding: '0px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                                {t('home_departments.schedule_service_tab.copy_from_monday')}
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </span>
                                                                {/* </Row> */}
                                                            </td>
                                                        </tr>
                                                        <tr className='TicketsRowCollums'>
                                                            <td style={{ textAlign: 'center' }}> 
                                                                {CheckWeekDay("tuesday_active", values.tuesday_active, handleChange, values, t('home_departments.schedule_service_tab.days.tue'))}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_tuesday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_tuesday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                        </tr>
                                                        <tr className='TicketsRowCollums'>
                                                            <td style={{ textAlign: 'center' }}> 
                                                                {CheckWeekDay("wednesday_active", values.wednesday_active, handleChange, values, t('home_departments.schedule_service_tab.days.wed'))}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_wednesday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_wednesday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                        </tr>
                                                        <tr className='TicketsRowCollums'>
                                                            <td style={{ textAlign: 'center' }}> 
                                                                {CheckWeekDay("thursday_active", values.thursday_active, handleChange, values, t('home_departments.schedule_service_tab.days.thu'))}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_thursday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_thursday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                        </tr>
                                                        <tr className='TicketsRowCollums'>
                                                            <td style={{ textAlign: 'center' }}> 
                                                                {CheckWeekDay("friday_active", values.friday_active, handleChange, values, t('home_departments.schedule_service_tab.days.fri'))}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_friday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_friday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                        </tr>
                                                        <tr className='TicketsRowCollums'>
                                                            <td style={{ textAlign: 'center' }}> 
                                                                {CheckWeekDay("saturday_active", values.saturday_active, handleChange, values, t('home_departments.schedule_service_tab.days.sat'))}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_saturday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_saturday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                {/* <Table>
                                                    <thead>
                                                        <tr className='TicketsRowCollums'>
                                                            <th> </th>
                                                            <th style={{ textAlign: 'center' }}>
                                                                { CheckWeekDay("sunday_active", activeSchedule, handleChange, values, "Dom") }
                                                            </th>
                                                            <th style={{ textAlign: 'center' }}>     
                                                                { CheckWeekDay("monday_active", activeSchedule, handleChange, values, "Seg") }
                                                            </th>
                                                            <th style={{ textAlign: 'center' }}> 
                                                                { CheckWeekDay("tuesday_active", activeSchedule, handleChange, values, "Ter") }
                                                            </th>
                                                            <th style={{ textAlign: 'center' }}> 
                                                                { CheckWeekDay("wednesday_active", activeSchedule, handleChange, values, "Qua") }
                                                            </th>
                                                            <th style={{ textAlign: 'center' }}> 
                                                                { CheckWeekDay("thursday_active", activeSchedule, handleChange, values, "Qui") }
                                                            </th>
                                                            <th style={{ textAlign: 'center' }}> 
                                                                { CheckWeekDay("friday_active", activeSchedule, handleChange, values, "Sex") }
                                                            </th>
                                                            <th style={{ textAlign: 'center' }}> 
                                                                { CheckWeekDay("saturday_active", activeSchedule, handleChange, values, "Sab") }
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className='TicketsRowCollums'>
                                                            <td className='tableColHead'> Início </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_sunday", values, showFeedbackInit, errors, setFieldTouched)} 
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_monday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_tuesday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_wednesday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_thursday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_friday", values, showFeedbackInit, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "init_saturday", values, showFeedbackInit, errors)}
                                                            </td>
                                                        </tr>
                                                        <tr className='TicketsRowCollums'>
                                                            <td className='tableColHead'> Fim </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_sunday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_monday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_tuesday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_wednesday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_thursday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_friday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                            <td>
                                                                {HourInputForm(handleBlur, handleChange, "end_saturday", values, showFeedbackEnd, errors)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table> */}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    {/* <Row className='mb-4'>
                                        <span style={{  alignItems: 'center !important', display: 'flex' }}>
                                            <span>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip>
                                                            Copiar horário da Segunda para os demais dias da semana
                                                        </Tooltip>
                                                    }
                                                    placement='top'
                                                >
                                                    <Button onClick={(e) => { copyAllWeekDays(values, setFieldTouched); }} disabled={!values.activeSchedule && !values.monday_active} style={{ background: 'transparent', border: 'none', padding: '0px', alignItems: 'center' }}>
                                                        <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2C4CA2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                                                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                                        </svg>
                                                    </Button>
                                                </OverlayTrigger>
                                            </span>
                                            <span>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip>
                                                            Copiar horário da Segunda para os demais dias da semana
                                                        </Tooltip>
                                                    }
                                                    placement='top'
                                                >
                                                    <Button onClick={(e) => { copyAllWeekDays(values, setFieldTouched); }} disabled={!values.activeSchedule && !values.monday_active} style={{ font: "calc(0.3rem + 0.55vw) Roboto", color: '#000', border: 'none', background: "transparent", padding: '0px' }}>
                                                        Copiar da Segunda 
                                                    </Button>
                                                </OverlayTrigger>
                                            </span>
                                        </span>
                                    </Row> */}
                                    <Row>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{t('home_departments.schedule_service_tab.days_off_service')}</p>
                                        <Col md={6} style={{ width: '740px', marginTop: '-1vh' }}>
                                            <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t('home_departments.schedule_service_tab.days_off_service_subtitle')}</p>
                                        </Col>
                                    </Row>
                                    <Row className='TicketsRowCollums'>
                                        <Col md={2} className='d-flex align-items-center'>
                                            {/* <FormLabel>
                                                Calendário:
                                            </FormLabel> */}
                                            <InputGroup className="mb-3" style={{ width: '6.5vw' }}>
                                                <Col>
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        plugins={[
                                                            <DatePanel header={t('home_departments.schedule_service_tab.dates_days')} sort="date" />
                                                        ]} 
                                                        value={scheduleDays} 
                                                        multiple 
                                                        onChange={(selectedDates: DateObject[]) => { 
                                                            setScheduleDays(selectedDates); 
                                                        }}
                                                        locale={gregorian_pt_br}
                                                        ref={datePickerRef}
                                                        calendarPosition='top-left'
                                                        containerClassName='calendaryStyle'
                                                        weekDays={[t('home_departments.schedule_service_tab.days.sun'), t('home_departments.schedule_service_tab.days.mon'), t('home_departments.schedule_service_tab.days.tue'), t('home_departments.schedule_service_tab.days.wed'), t('home_departments.schedule_service_tab.days.thu'), t('home_departments.schedule_service_tab.days.fri'), t('home_departments.schedule_service_tab.days.sat')]}
                                                        months={[t('home_departments.schedule_service_tab.months.jan'), t('home_departments.schedule_service_tab.months.feb'), t('home_departments.schedule_service_tab.months.mar'), t('home_departments.schedule_service_tab.months.apr'), t('home_departments.schedule_service_tab.months.may'), t('home_departments.schedule_service_tab.months.jun'), t('home_departments.schedule_service_tab.months.jul'), t('home_departments.schedule_service_tab.months.aug'), t('home_departments.schedule_service_tab.months.sep'), t('home_departments.schedule_service_tab.months.oct'), t('home_departments.schedule_service_tab.months.nov'), t('home_departments.schedule_service_tab.months.dec')]}
                                                        monthYearSeparator=" "
                                                        renderButton={(direction, handleClick) => (
                                                            <>
                                                                {direction === 'right'?
                                                                    <button className={'react-datepicker__navigation react-datepicker__navigation--next calendar-month-button'} onClick={handleClick}>
                                                                        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next calendar-month-icon"></span>
                                                                    </button>:
                                                                    <button className='react-datepicker__navigation react-datepicker__navigation--previous calendar-month-button' onClick={handleClick}>
                                                                        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous calendar-month-icon"></span>
                                                                    </button>
                                                                }
                                                            </>
                                                        )}
                                                        render={<InputIcon/>}
                                                        mapDays={({ date, selectedDate, isSameDate }) => {
                                                            let props = { style: {}};
                                                            // let today = new DateObject();
                                                        
                                                            /* data atual */
                                                            /* if (isSameDate(date, today)) {
                                                                props.style = { backgroundColor: "#fff", color: '#000' };
                                                            } */
                                                            
                                                            (selectedDate as DateObject[]).map((selected) => {
                                                                if (isSameDate(date, selected as DateObject)) 
                                                                    props.style = { 
                                                                        ...props.style, 
                                                                        backgroundColor: "#4A69BD", 
                                                                        color: '#fff',
                                                                        borderRadius: "3px",
                                                                    }
                                                            });
                                                        
                                                            return props;
                                                        }}
                                                        highlightToday={false}
                                                        // disabled={!values.activeSchedule}
                                                    ></DatePicker>
                                                </Col>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    {hasBot === true &&
                                    <Row>
                                        <Col className='mt-3'>
                                            <div className="switch-input" >
                                                <span style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{t('home_departments.schedule_service_tab.transfer_service')}</span>
                                                {
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id='tooltip'>
                                                                { transferService ?
                                                                    t('home_departments.schedule_service_tab.transfer_service_tooltip_active') :
                                                                    t('home_departments.schedule_service_tab.transfer_service_tooltip_inactive')
                                                                }
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </OverlayTrigger>
                                                }
                                                <label className="switch">
                                                    <input
                                                        disabled={props.isLoading}
                                                        type="checkbox"
                                                        checked={transferService}
                                                        onChange={transferService ? () => disableTransferService() : () => enableTransferService()}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    }
                                    <Row className='mt-4'>
                                        <FormLabel style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                                            {t('home_departments.schedule_service_tab.off_service_form.message')}
                                        </FormLabel>
                                        <InputGroup className='d-flex align-items-center' style={{ width: '60%' }}>
                                            <Form.Control className="form-control-Default-text-area"
                                                isInvalid={showFeedbackMessageOffService}
                                                placeholder={t('home_departments.schedule_service_tab.off_service_form.placeholder')}
                                                aria-label={t('home_departments.schedule_service_tab.off_service_form.placeholder')}
                                                aria-describedby={t('home_departments.schedule_service_tab.off_service_form.placeholder')}
                                                as="textarea"
                                                id="message_off_service"
                                                name="message_off_service"
                                                onChange={(e) => { handleMessageOffService(e, handleChange) }}
                                                onBlur={handleBlur}
                                                defaultValue={values.messageOffService}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid" id="feedbackOffService">
                                                {errors.messageOffService}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                    <Row className='mt-4 mb-4'>
                                        <FormLabel style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                                            {t('home_departments.schedule_service_tab.on_service_form.message')}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {t('home_departments.schedule_service_tab.on_service_form.tooltip_message')}
                                                        </Tooltip>
                                                    }
                                            >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                        </FormLabel>
                                        <InputGroup className='d-flex align-items-center' style={{ width: '60%' }}>
                                            <Form.Control className="form-control-Default-text-area"
                                                isInvalid={showFeedbackMessageOnService}
                                                placeholder={t('home_departments.schedule_service_tab.on_service_form.placeholder')}
                                                aria-label={t('home_departments.schedule_service_tab.on_service_form.placeholder')}
                                                aria-describedby={t('home_departments.schedule_service_tab.on_service_form.placeholder')}
                                                as="textarea"
                                                id="message_on_service"
                                                name="message_on_service"
                                                onChange={(e) => handleMessageOnService(e, handleChange) }
                                                onBlur={handleBlur}
                                                defaultValue={values.messageOnService}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid" id="feedbackOnService">
                                                {errors.messageOnService}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Row>
                                </div>
                                <Row 
                                    style={{
                                        textAlign: 'left',
                                        font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                        letterSpacing: '0px',
                                        color: '#707070',
                                        opacity: '1',
                                    }} 
                                    className="d-flex mt-4"
                                >
                                    <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                        <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => { document.location.reload(); }} >{t('home_departments.schedule_service_tab.cancel')}</Button>
                                    </Col>
                                    <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                        <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{t('home_departments.schedule_service_tab.save')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Row>
        </div>
    );
}

export default ScheduleServiceTab;