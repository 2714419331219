import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalRegisterCustomFields from ".";
import { useTranslation } from "react-i18next";

const ModalRegisterCustomFieldsController = ({ show, handleClose, callback }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [validated] = useState(false);
    const [name, setName] = useState('');
    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [type, setType] = useState([
        { 'label': t('configurations_consumers.modal_register_custom_field.simple_text_label'), 'value': t('configurations_consumers.modal_register_custom_field.simple_text_value') },
        { 'label': t('configurations_consumers.modal_register_custom_field.multiline_text_label'), 'value': t('configurations_consumers.modal_register_custom_field.multiline_text_value') },
    ]);

    const [selectedType, setSelectedType] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedsOptions, setSelectedsOptions] = useState([])
    const [/*defaultValue*/, setDefaultValue] = useState('')
    const [labelledOptions, setLabelledOptions] = useState([])
    const [placeholder, setPlaceholder] = useState('')
    const [showFeedbackPlaceholder, setShowFeedbackPlaceholder] = useState(false)
    const entityId = 'b93b0740-a77d-4474-aad9-7aa0044d5654'

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const clearFields = () => {
        setName('')
        setShowFeedbackName(false)
        setSelectedType([])
        setSelectedsOptions([])
        setSelectedOptions([])
        setPlaceholder('')
        setShowFeedbackPlaceholder(false)
        handleClose()
    }

    const onChangeTags = (e: { detail: { tagify: { getCleanValue: Function; }; }; }) => {
        setSelectedOptions(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        }));
    }

    useEffect(() => {
        if (selectedOptions.length > 0) {
            setLabelledOptions(selectedOptions ? selectedOptions?.map((option, index) => {
                return ({ label: option, value: option })
            }) : [])
        }

    }, [selectedOptions])

    return <ModalRegisterCustomFields
        show={show}
        clearFields={clearFields}
        type={type}
        setName={setName}
        setType={setType}
        setSelectedOptions={setSelectedOptions}
        setDefaultValue={setDefaultValue}
        setPlaceholder={setPlaceholder}
        setShowFeedbackName={setShowFeedbackName}
        setShowFeedbackPlaceholder={setShowFeedbackPlaceholder}
        placeholder={placeholder}
        entityId={entityId}
        selectedType={selectedType}
        labelledOptions={labelledOptions}
        selectedsOptions={selectedsOptions}
        dispatch={dispatch}
        callback={callback}
        navigate={navigate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showFeedbackName={showFeedbackName}
        validated={validated}
        setSelectedType={setSelectedType}
        onChangeTags={onChangeTags}
        selectedOptions={selectedOptions}
        showFeedbackOptionList={selectedOptions}
        setLabelledOptions={setLabelledOptions}
        setSelectedsOptions={setSelectedsOptions}
        showFeedbackPlaceholder={showFeedbackPlaceholder}
        name={name}
    />


}

export default ModalRegisterCustomFieldsController;