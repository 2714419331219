import { useEffect, useState } from "react"
import General from "."
import UserService from "../../../../../services/user-service"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import { TokenInterface } from "../../../../../services/requestsInterfacesModel"
import { useDispatch, useSelector } from "react-redux"
import { getIdCompany } from "../../../../../store/company"
import { setShowAlertFeedback } from "../../../../../store/internal"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const AppRequesterConst = new AppRequesterController()

const GeneralController = (props) => {
    const {t} = useTranslation();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany),
        },
    };

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const enableDetailedMessage = async () => {
        const JsonSend = {}

        if (props.showName === true) {
            props.setShowName(false)
            JsonSend['enable'] = false
        } else {
            props.setShowName(true)
            JsonSend['enable'] = true
        }

        const headers = UserService.getHeaders()

        await AppRequesterConst.Patch(
            'company/update-name-in-message/' + values.company.id, JsonSend, { headers },
            (response: Object) => { },
            (data) => {
                if (props.showName === false) {
                    dispatch(setShowAlertFeedback({ message: t('home_channels.channel_tabs.general.display_settings_enabled'), visibility: true, signalIcon: true }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t('home_channels.channel_tabs.general.display_settings_disabled'), visibility: true, signalIcon: true }));
                }
            },
            (error: { response: { status: number, data: { message: string } } }) => {
                dispatch(setShowAlertFeedback({ message: t('errors.TicketErrorMessage'), visibility: true, signalIcon: false }));
            },
            navigate, dispatch, props.setIsLoading, () => { }
        )
    }

    return <General
        enableDetailedMessage={enableDetailedMessage}
        isLoading={props.isLoading}
        showName={props.showName}
        t={t}
    />
}

export default GeneralController