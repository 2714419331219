import { useState } from "react";
import CallInstructions from ".";
import { IconArrowRampRight, IconArrowUpRight, IconFishHook, IconPhoneOutgoing, IconRepeatOnce, IconUsers, IconUsersGroup } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const CallInstructionsController = () => {
  const {t} = useTranslation();
  
  const instructions = [
    {
      icon: <IconPhoneOutgoing color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.external_call'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.external_call_message')} <br /><strong>0 + ddd + {t('phone_callcenter.main_screen.call_instructions.number')}</strong>.</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.external_call_message')} 0 + ddd + ${t('phone_callcenter.main_screen.call_instructions.number')}`,
    },
    {
      icon: <IconPhoneOutgoing color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.international_call'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.international_call_message')} <br /><strong>* + DDI + DDD + {t('phone_callcenter.main_screen.call_instructions.number')}</strong>.</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.international_call_message')} * + DDI + DDD + ${t('phone_callcenter.main_screen.call_instructions.number')}`,
    },
    {
      icon: <IconArrowUpRight color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.direct_transfer'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.direct_transfer_message_a')} <strong># #</strong>{t('phone_callcenter.main_screen.call_instructions.direct_transfer_message_b')}</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.direct_transfer_message_a')} # #${t('phone_callcenter.main_screen.call_instructions.direct_transfer_message_b')}`,
    },
    {
      icon: <IconArrowRampRight color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.assisted_transfer'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.assisted_transfer_message_a')} <strong>* 2</strong>{t('phone_callcenter.main_screen.call_instructions.assisted_transfer_message_b')}</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.assisted_transfer_message_a')} *2${t('phone_callcenter.main_screen.call_instructions.assisted_transfer_message_b')}`,
    },
    {
      icon: <IconUsersGroup color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.conference'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.conference_message_a')} <strong>*5</strong>{t('phone_callcenter.main_screen.call_instructions.conference_message_b')}</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.conference_message_a')} *5${t('phone_callcenter.main_screen.call_instructions.conference_message_b')}`,
    },
    {
      icon: <IconFishHook color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.call_capture'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.call_capture_message_a')} <strong>**1 + {t('phone_callcenter.main_screen.call_instructions.call_capture_message_b')}</strong> {t('phone_callcenter.main_screen.call_instructions.call_capture_message_c')}</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.call_capture_message_a')} **1 + ${t('phone_callcenter.main_screen.call_instructions.call_capture_message_b')} ${t('phone_callcenter.main_screen.call_instructions.call_capture_message_c')}`,
    },
    {
      icon: <IconUsers color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.call_supervision'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.call_supervision_message_a')} <strong>**2 + {t('phone_callcenter.main_screen.call_instructions.call_supervision_message_b')}</strong> {t('phone_callcenter.main_screen.call_instructions.call_supervision_message_c')} 
      {t('phone_callcenter.main_screen.call_instructions.call_supervision_message_d')}
      {t('phone_callcenter.main_screen.call_instructions.call_supervision_message_e')}</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.call_capture_message_c')} **2 + ${t('phone_callcenter.main_screen.call_instructions.call_supervision_message_b')} ${t('phone_callcenter.main_screen.call_instructions.call_supervision_message_c')} ${t('phone_callcenter.main_screen.call_instructions.call_supervision_message_d')} ${t('phone_callcenter.main_screen.call_instructions.call_supervision_message_e')}`,
    },
    {
      icon: <IconRepeatOnce color="#4a69bd" size={20} stroke={1.5} />,
      title: t('phone_callcenter.main_screen.call_instructions.repeat_last_call'),
      content: <>{t('phone_callcenter.main_screen.call_instructions.repeat_last_call_message')} <strong>*1</strong>.</>,
      text: `${t('phone_callcenter.main_screen.call_instructions.repeat_last_call_message')} *1`,
    },
  ];

  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [filteredLength, setFilteredLength] = useState(instructions.length);

  const getFilteredInstructions = () => {
    if (!searchTerm) {
      return instructions;
    } else {
      const filtered = instructions.filter(item => (
        item.title?.toLocaleLowerCase()?.includes(searchTerm?.toLowerCase()) || 
        item.text?.toLocaleLowerCase()?.includes(searchTerm?.toLowerCase())
      ));
      setFilteredLength(filtered.length);
      return filtered;
    }
  }

  return (
    <CallInstructions 
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      filteredLength={filteredLength}
      getFilteredInstructions={getFilteredInstructions}
      t={t}
    />
  );
}

export default CallInstructionsController;