import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { FC, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalViewMessageTriggerControllerInterface, WhatsappKey } from "./indexModel";
import { getCleanMessageEditor, setCleanMessageEditor, setShowAlertFeedback, setShowEditMessageTriggerModal } from "../../../../store/internal";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import constsApi from "../../../../services/constsApi";
import Utils from "../../../../core/shared/utils";
import ModalViewMessageTrigger from ".";
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { useTranslation } from 'react-i18next';
import { BlockTicketContext } from "../../../../core/context/block-ticket-context";

const ModalViewMessageTriggerController: FC<ModalViewMessageTriggerControllerInterface> = (props) => {
  const {t} = useTranslation();
  const block_ticket = useContext(BlockTicketContext)
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState(null as string || null);
  const [showfeedbackDate, setShowfeedbackDate] = useState(false);

  const [hour, setHour] = useState(null);
  const [showfeedbackHour, setShowfeedbackHour] = useState(false);

  const cleanMessageEditor = useSelector(getCleanMessageEditor);
  const [messageContentHtml, setMessageContentHtml] = useState(null);
  const [/*showfeedbackMessage*/, setShowfeedbackMessage] = useState(false);

  /** { name: string, id: string, url: string }[] */
  const [previewFiles, setPreviewFiles] = useState(props?.data?.attachments? props.data.attachments: []); 
  const [tempAttachmentIds, setTempAttachmentIds] = useState([] as string[]);
  const [file_name, setFile_name] = useState(props.data ? props.data.file_name : "")
  const [file_id, setFile_id] = useState(null as string || null);

  const [selected_type, setSelected_type] = useState((props.data) ? [{ label: props.data.type, value: props.data.type_id }] : []);
  const [viewTriggerModal, setViewTriggerModal] = useState(props.viewTriggerModal);

  const [selected_channel, setSelected_channel] = useState((props.data) ? [{ label: props.data.channel, value: props.data.channel_id }] : []);

  const [selected_template, setSelected_template] = useState([]);

  const [selected_email, setSelected_email] = useState([]);
  // const [templateMessage, setTemplateMessage] = useState(null);
  const [hasWhatsapp, sethasWhatsapp] = useState(false);
  const [options_templates, setOptions_templates] = useState([]);
  const [email_principal, setOptions_email_principal] = useState([])
  const [options_email, setOptions_email] = useState([])
  const [hasEmail, setHasEmail] = useState(false)
  const [whatsAppKeysId, setSelected_whatsappKey] = useState<WhatsappKey>(null)
  // const [templateMessageId, setSelected_templateMessageId] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [selected_atendimento, setSelected_atendimento] = useState([])
  const [triageConfirmation, setTriageConfirmation] = useState(selected_template[0]?.has_triage === true ? true : false)

  const [errorSelectType, setErrorSelectType] = useState(false);
  const [errorSelectChannel, setErrorSelectChannel] = useState(false);
  const [errorSelectRecipients, setErrorSelectRecipients] = useState(false);
  const [errorSelectWhatsappTemplate, setErrorSelectWhatsappTemplate] = useState(false);

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AppRequesterConst = new AppRequesterController();
  
  const [isClicked, setIsClicked] = useState(true);
  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 15728640,
    disabled: viewTriggerModal === true ? true : selected_channel[0] ? false : true,
    noClick: isClicked,
    noDrag: isClicked,
    onDrop: (file) => {
      const formData = new FormData()
      
      formData.append("file", file[0])
      formData.append("chanel_id", selected_channel[0].value)
      
      const headers = {
        "Content-Type": "multipart/form-data; charset=utf-8",
        "Authorization": "Bearer " + props.token
      };
      
      AppRequesterConst.Post(
        '/message-sending/upload', formData, { headers },
        (response: Object) => {
          return response;
        },
        (data: { data: { id: string; message: string; }; }) => {
          dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.upload_sucess"), visibility: true, signalIcon: true }));
          setIsClicked(true)
          setFile_id(data.data.id);
        },
        (error: { response: { status: number; data: { message: any[], code_cxpress: number; }; }; }) => {
          setIsClicked(true);

          if (error.response.status === 422) {
            dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.upload_error"), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }, navigate, dispatch, setIsLoading
        );
      },
      onDropRejected: (reject) => {
        if (reject[0].errors[0].code === "file-too-large") {
          dispatch(setShowAlertFeedback({ visibility: true, message: "O tamanho do arquivo excede 15MB.", signalIcon: false }));
        }
      },
    accept: { 'text/csv': ['.csv'] },
  });
  
  const [options_types, setOptions_types] = useState(props.dataTriggerType ? props.dataTriggerType.map((item, index) => {
    return { label: item.name, value: item.id }
  }) : []);
  
  const [options_channel, setOptions_channel] = useState([
    { label: "E-mail", value: constsApi.channel[0].id },
    { label: "Whatsapp", value: constsApi.channel[2].id },
  ]);

  const [options_atendimento, setOptions_atendimento] = useState([
    { label: t("home_message_trigger.modal_register_message_trigger.agent"), value: "agente" },
    { label: t("home_message_trigger.modal_register_message_trigger.chatbot"), value: "chatbot" }
  ]);

  useEffect(() => {
    if (props.data?.attachments)
      setPreviewFiles(props.data?.attachments);
    else  
      setPreviewFiles([]);
  }, [props.data?.attachments]);

  // template message e whatsapp keys
  useEffect(() => {
    setSelected_template((template) => {
      if (props.templateMessageList && props.templateMessageList.length > 0 && props.data?.template_message_id) {
        let finded = props.templateMessageList?.find((item) => item.id === props.data.template_message_id);
        if (finded) {
          template = [ Object.assign(finded, { value: finded.id, label: finded.title_and_number ? finded.title_and_number : finded.message }) ];
        }
      } else {
        template = [];
      }

      // seleção inicial do whatsapp_keys
      if (template && template[0])
        setSelected_whatsappKey({ whatsapp_keys_id: template[0].whatsapp_keys_id });
      else 
        setSelected_whatsappKey(null);

      return template;
    });
  }, [props.data?.template_message, props.templateMessageList, modalShow]);

  // preenchendo dados do modal, ignorar template message e whatsapp_keys
  useEffect(() => {
    if (props.data && props.data !== null && props.data !== undefined) {
      setOptions_types(props.dataTriggerType ? props.dataTriggerType.map((item, index) => {
        return { label: item.name, value: item.id }
      }) : []);
  
      setSelected_type((props.data) ? [{ label: props.data.type, value: props.data.type_id }] : []);
      
      setSelected_channel((props.data) ? [{ label: props.data.channel, value: props.data.channel_id }] : []);
      props.data && props.data.channel_id === constsApi.channel[2].id ? sethasWhatsapp(true) : sethasWhatsapp(false);
      
      setOptions_templates(props.templateMessageList ? props.templateMessageList.filter(item => item.quick_message === false)
      .map((item, index) => {
        // return { label: item.title_and_number, value: item.message, /* whatsappkeyid */ whatsapp_keys_id: item.whatsapp_keys_id, id: item.id }
        return Object.assign(item, { label: item.title_and_number, value: item.message, whatsapp_keys_id: item.whatsapp_keys_id, whatsappkeyid : item.whatsapp_keys_id, id: item.id, is_bot_nlp: item?.is_bot_nlp, has_triage: item?.has_triage });
      }) : [])
  
      setOptions_email(props.emailList ? props.emailList/* .filter(item => item.active === true) */
        .map((item, index) => {
          return { label: item.email, value: item.id }
      }) : []);
  
      setOptions_email_principal(props.emailPrincipal ? props.emailPrincipal.filter((item) => item.email_suport).map((item) => {
        return { label: item.email, value: item.id }
      }) : [])
  
      setViewTriggerModal(props.viewTriggerModal);
      setModalShow(props.visibility);
      if (props.data && props.data.date_scheduled !== null) {
        setDate(Utils.formatedDate(new Date(props.data.date_scheduled)));
        setHour(Utils.formatTime((props.data.date_scheduled).toString()));
      }

      setTriageConfirmation(props?.data?.has_triage === true && props?.data?.is_active_triage === true ? true : false)

      setSelected_whatsappKey(props?.data?.whatsapp_keys_id)
      // setSelected_templateMessageId(props?.data?.template_message_id)
      setSelected_atendimento(props?.data?.response_message_level === 1 ? [{ label: "Chatbot", value: "chatbot" }] : props?.data?.response_message_level === 2 ? [{ label: "Agente", value: "agente" }] : []);
    } else { // no caso de não houver dados o modal não é mostrado, também quando é recarregado com o modal aberto
      hide();
    }
  }, [props.visibility, props.viewTriggerModal, props.data, props.emailList]);

  useEffect(() => {
    if (file_name === "") {
      setFile_name(props.data ? props.data.file_name : "")
    }

    acceptedFiles.map(file => {
      const returnedTarget = Object.assign(file, { isLoading: true });
      file = returnedTarget;
    });
    setFiles(acceptedFiles);

  }, [props.visibility, acceptedFiles, file_name, files]);

  useEffect(() => {
    if (props.data && props.data.message !== null) {
      setMessageContentHtml(props.data && props.data.message);
    }
  }, [props.data && props.data.message]);

  useEffect(() => {
    selected_channel[0] && selected_channel[0].value === constsApi.channel[2].id ? sethasWhatsapp(true) : sethasWhatsapp(false);
  }, [selected_channel]);

  useEffect(() => {
    selected_channel[0] && selected_channel[0].value === "3f3fa530-fd3e-4018-ab98-4d9c3cd5406a" ? setHasEmail(true) : setHasEmail(false)

    if(props.emailList){
      setSelected_email(props?.emailList?.filter(item => item?.id === props?.data?.email_id).map((item, index) => {
        return { label: item?.email, value: item?.id }
      }))
    }

    if (props?.data?.status_id === "db6048ca-5d24-4168-a7fb-1c611958eaaf" || props?.data?.status_id === "e793cbc0-48c5-4d4f-b81b-78d70ddfc848") {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

  }, [selected_channel])

  useEffect(() => {
    block_ticket.setIsAllowedMessage(false);
    block_ticket.setReadOnlyEditorComponent(true);
  }, [])

  const selectType = (value: string | any[]) => {
    if (value.length > 0) {
      setSelected_type([value[value.length - 1]]);
    } else {
      setSelected_type([]);
    }
  }

  const selectChannel = (value: string | any[]) => {
    if (value.length > 0) {
      setSelected_channel([value[value.length - 1]]);
      deleteFile(files);
    }
    else {
      setSelected_channel([]);
    }
  }

  const selectTemplate = (value: string | any[]) => {
    if (value.length > 0) {
      setSelected_template([value[value.length - 1]]);
      setSelected_whatsappKey({ whatsapp_keys_id: value[value.length - 1].whatsapp_keys_id })
    } else {
      setSelected_template([]);
      // setTemplateMessage({ message: "" });
    }
  }

  const callbackGetStringHtml = (parenthef = null, htmlString?: string) => {
    setMessageContentHtml(htmlString);
  }

  const changeToText = (html: string) => {
    let blocksFromHTML = convertFromHTML(html);
    let newEditorState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    let stateHtml = EditorState.createWithContent(newEditorState)
    let text = convertToRaw(stateHtml.getCurrentContent()).blocks.map((item: { text: string; }, index: any) => {
      return item.text
    })
    return text.join('\n')
  }

  const hide = () => {
    setFile_name("")
    dispatch(setCleanMessageEditor(false));
    setSelected_channel([]);
    setSelected_type([]);
    setSelected_template([]);
    files.length > 0 ? deleteFile(files) : setFiles([]);
    dispatch(setShowEditMessageTriggerModal(false));
    setModalShow(false);
    block_ticket.setIsAllowedMessage(true);
    block_ticket.setReadOnlyEditorComponent(false);
  }

  const deleteFile = (fileName: string | any[]) => {
    setIsClicked(false)
    let newFiles = files.filter((item) => item.path !== fileName);
    acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
    setFile_name(null)
    setFiles(newFiles);
  }

  const converterData = (data: string) => {
    let separaData = data.split("/")
    let dataFormatada = separaData[1] + "/" + separaData[0] + "/" + separaData[2]
    let Data = new Date(dataFormatada)
    return (Data)
  }

  const converterHora = (hora: string) => {
    let separaHora = hora.split(":")
    let Data = new Date(2000, 1, 1, parseInt(separaHora[0]), parseInt(separaHora[1]))
    return (Data)
  }

  const dataToTimestamp = (data: string, hora: string) => {
    let separaData = data.split("/")
    let separaHora = hora.split(":")
    let timestamp = new Date(`${separaData[2]}/${separaData[1]}/${separaData[0]} ${separaHora[0]}:${separaHora[1]}`)
    return timestamp.toISOString()
  }

  const attachmentIdsCallback = (ids: []) => {
    setTempAttachmentIds(ids);
  }

  const enableTriageConfirmation = () => {
    if (triageConfirmation === false) {
      setTriageConfirmation(true)
    } else {
      setTriageConfirmation(false)
    }
  }

  return (
    <ModalViewMessageTrigger 
      scheduledDate={props.data.date_scheduled}
      files={files}
      deleteFile={deleteFile}
      data={props.data}
      modalShow={modalShow}
      hide={hide}
      isLoading={false}
      setIsLoading={setIsLoading}
      date={date}
      setDate={setDate}
      hour={hour}
      setHour={setHour}
      setShowfeedbackDate={setShowfeedbackDate}
      converterData={converterData}
      converterHora={converterHora}
      messageContentHtml={messageContentHtml}
      // templateMessage={templateMessage}
      selected_channel={selected_channel}
      dataToTimestamp={dataToTimestamp}
      changeToText={changeToText}
      hasEmail={hasEmail}
      selected_type={selected_type}
      selected_email={selected_email}
      email_principal={email_principal}
      hasWhatsapp={hasWhatsapp}
      whatsAppKeysId={whatsAppKeysId}
      // templateMessageId={templateMessageId}
      file_id={file_id}
      tempAttachmentIds={tempAttachmentIds}
      token={props.token}
      dispatch={dispatch}
      callback={props.callback}
      navigate={navigate}
      validated={false}
      viewTriggerModal={viewTriggerModal}
      options_types={options_types}
      setOptions_types={setOptions_types}
      setSelected_type={setSelected_type}
      options_channel={options_channel}
      setOptions_channel={setOptions_channel}
      setSelected_channel={setSelected_channel}
      selectChannel={selectChannel}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      acceptedFiles={acceptedFiles}
      file_name={file_name}
      disabled={disabled}
      options_email={options_email}
      setOptions_email={setOptions_email}
      setSelected_email={setSelected_email}
      selected_template={selected_template}
      options_templates={options_templates}
      setOptions_templates={setOptions_templates}
      setSelected_template={setSelected_template}
      selectTemplate={selectTemplate}
      callbackGetStringHtml={callbackGetStringHtml}
      visibility={props.visibility}
      attachmentIdsCallback={attachmentIdsCallback}
      showfeedbackHour={showfeedbackHour}
      setShowfeedbackHour={setShowfeedbackHour}
      showfeedbackDate={showfeedbackDate}
      setShowfeedbackMessage={setShowfeedbackMessage}
      AppRequesterConst={AppRequesterConst} 
      cleanMessageEditor={cleanMessageEditor}
      selected_atendimento={selected_atendimento}
      setSelected_atendimento={setSelected_atendimento}
      triageConfirmation={triageConfirmation}
      // setTriageConfirmation={setTriageConfirmation}
      options_atendimento={options_atendimento}
      setOptions_atendimento={setOptions_atendimento}
      enableTriageConfirmation={enableTriageConfirmation}
      setErrorSelectType={setErrorSelectType}
      errorSelectType={errorSelectType}
      errorSelectChannel={errorSelectChannel}
      setErrorSelectChannel={setErrorSelectChannel}
      errorSelectRecipients={errorSelectRecipients}
      setErrorSelectRecipients={setErrorSelectRecipients}
      setErrorSelectWhatsappTemplate={setErrorSelectWhatsappTemplate}
      errorSelectWhatsappTemplate={errorSelectWhatsappTemplate}
    />
  );
}

export default ModalViewMessageTriggerController;