import { FC, useEffect, useState } from "react";
import { IFormConfigTemplateMessagesControlPanelController } from "./indexModel";
import FormConfigTemplateMessagesControlPanel from ".";
import { useDispatch, useSelector } from "react-redux";
import { setShowAlertFeedback, setShowCreateTemplateMessage } from "../../../../../store/internal";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/control_panel";
import { useNavigate } from "react-router-dom";

const FormConfigTemplateMessagesControlPanelController: FC<IFormConfigTemplateMessagesControlPanelController> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [aux, setAux] = useState([]);
    const [currentState, setCurrentState] = useState("");
    const [currentParams, setCurrentParams] = useState({});

    const [currentTemplate, setCurrentTemplate] = useState(null);
    
    const [datas, setDatas] = useState([]);

    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();
    let navigate = useNavigate();

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
    };

    useEffect(() => {
        const controller = new AbortController();
        if (props.currentCompany) {
            setCurrentState((state) => {
                getAllTemplateMessages(controller, props.currentCompany.company_id);
                return "";
            });
        }

        return () => {
            controller.abort();
        }
    }, [props.currentCompany, currentState]);

    const callbackForList = () => {
        setCurrentState("updating");
    }

    const getAllTemplateMessages = async (controller: AbortController, companyId: string) => {
        const signal = controller.signal;

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/template-message/whatsapp/${companyId}?noChangeValue=true`, { headers, /* signal */ },
            (response: Object) => {},
            (data: any) => {
                if(data?.status === 200 && data?.data?.templateMessages?.length > 0) {
                    setDatas(data.data.templateMessages);
                }
            },
            (error: { response: { status: number } }) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                /* if (error?.response?.status === 400) {

                } else if (error?.response?.status === 401) {

                } else {
                    
                } */
            }, navigate, dispatch, setIsLoading, { }
        )
    }

    const search = (e: Object, auxObj: Object, action: string) => {
        setCurrentPage(1);
        setAux([])

        auxObj = {}

        if (action === "clean") {
            setCurrentFilterSearch("")
        } else if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }

        setCurrentState("searching");
        if (currentState === "searching") {
            setCurrentState("");
        }

        setCurrentParams(auxObj);
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const filteringTemplates = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj: Object, action: string) => {
        setCurrentPage(1)
        setAux([])
        if (document.getElementById("listOfTemplateMessages") !== null) {
            document.getElementById("listOfTemplateMessages").scrollTop = 0
        }

        let name = ""
        if (e.currentTarget) {
            name = e.currentTarget.name
        } else if (e.target) {
            name = e.target.name
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("")
            auxObj = {}
            setCurrentState("searching")
            if(currentState === "searching") {
                setCurrentState("")
            }
        } else if (name === "searchButtonName" || name === "searchButtonName") {
            auxObj = {}

            setCurrentState("searching")
            if (currentState === "searching") {
                setCurrentState("")
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch
            }
        } else {
            setCurrentState("filtering")

            if (currentState === "filtering") {
                setCurrentState("")
            }
        }

        setCurrentParams(auxObj)
    }

    const showCreateTemplateMessage = () => {
        dispatch(setShowCreateTemplateMessage(true));
    }

    return <FormConfigTemplateMessagesControlPanel 
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        currentFilterSearch={currentFilterSearch}
        search={search}
        setCurrentState={setCurrentState}
        currentState={currentState}
        setCurrentPage={setCurrentPage}
        setAux={setAux}
        setCurrentParams={setCurrentParams}
        cleanList={cleanList}
        filteringTemplates={filteringTemplates}
        setCurrentFilterSearch={setCurrentFilterSearch}
        showCreateTemplateMessage={showCreateTemplateMessage}
        currentCompany={props.currentCompany}
        datas={datas}
        callbackForList={callbackForList}
        currentTemplate={currentTemplate}
        setCurrentTemplate={setCurrentTemplate}
        isEditAllowed={props.isEditAllowed}
    />
}

export default FormConfigTemplateMessagesControlPanelController;