import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"


const useBotCxpressUpdatedFiles = () => {

    const socket = useContext(SocketContext);

    const [updatedFiles, setUpdatedFiles] = useState<any>()

    useEffect(() => {
        socket.socket?.on('botcxpress-updated-files', (data) => setUpdatedFiles(data.botCxpress));

        return () => {
            socket.socket?.off('botcxpress-updated-files', setUpdatedFiles)
        }
    }, [socket.socket?.active])

    return { updatedFiles }
}

export default useBotCxpressUpdatedFiles