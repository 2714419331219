import { FC, useEffect } from "react";
import { IFormConfigTemplateMessagesControlPanel } from "./indexModel";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ModalCreateTemplateMessageController from "./modalCreateTemplateMessage/indexController";
import ListOfTemplateMessagesController from "./listOfTemplateMessages/indexController";
import Loading from "../../../../../components/loading";
import allStyles from './styles'
import ModalEditTemplateMessageController from "./modalEditTemplateMessage/indexController";

const FormConfigTemplateMessagesControlPanel: FC<IFormConfigTemplateMessagesControlPanel> = (props) => {
    return (
        <>
            <Loading visibility={props.isLoading}></Loading>
            <ModalCreateTemplateMessageController currentCompany={props.currentCompany} callback={props.callbackForList} />
            <ModalEditTemplateMessageController currentCompany={props.currentCompany} callback={props.callbackForList} template={props.currentTemplate} setIsLoading={props.setIsLoading} />
            {/* <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                    className="mt-2"
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Configurações de Template Whatsapp
                    </div>
                </Col>
                {/* <Col className="d-flex justify-content-end">
                    <ButtonSearchCleanInputController
                        inputPlaceholder={"Buscar template message"} 
                        filteringTickets={(e, auxObj, action) => props.search(e, auxObj, action)} 
                        setCurrentFilterSearch={props.setCurrentFilterSearch} 
                        currentFilterSearch={props.currentFilterSearch}
                        filledCreatingTicket={null}
                        setCurrentState={props.setCurrentState}
                        currentState={props.currentState}
                        setCurrentPage={props.setCurrentPage}
                        setAux={props.setAux}
                        setCurrentParams={props.setCurrentParams}
                    />
                </Col>
            </Row> */}
            {/* <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div> */}
            <Row style={allStyles.textFontGlay} className="d-flex justify-content-flex-start mt-2" >
                {
                    props.isEditAllowed &&
                    <Col sm={true} md={3}>
                        <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                            <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                        Cadastrar Template Message
                                    </Tooltip>
                                }
                            >
                                <Button size="lg" className="buttonWhite" style={{ width: '100%', marginTop: '1.5vh' }} onClick={props.showCreateTemplateMessage}>Adicionar Template Whatsapp</Button>
                            </OverlayTrigger>
                        </p>
                    </Col>
                }
            </Row>
            <ListOfTemplateMessagesController currentCompany={props.currentCompany} datas={props.datas} setCurrentTemplate={props.setCurrentTemplate} callback={props.callbackForList} isEditAllowed={props.isEditAllowed} />
        </>
    );
}

export default FormConfigTemplateMessagesControlPanel;