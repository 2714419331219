export class AcquirePlatformNotificationBarController {
    private visibility: boolean

    constructor(visibility: boolean) {
        this.visibility = visibility
    }
    
    showNotificationBar() {
        if (this.visibility) {
            return 'block';
        } else {
            return 'none';
        }
    }
}