import { AxiosRequestConfig } from 'axios';
import { NavigateFunction } from "react-router-dom";

import { AuthenticationInterface, BodyRegisterCompanyInterface, BodySendEmailWithConfirmationInterface } from './authenticationModel'
import { ErrorsInterface } from '../errorsInterfaceModel'

import { AppRequesterController } from '../appRequester/appRequesterController';

const AppRequestConst = new AppRequesterController();

export class AuthenticationRequests implements AuthenticationInterface {
    RegisterCompany = async (body: BodyRegisterCompanyInterface, headers: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, errorsFunctions?: ErrorsInterface) => {
        await AppRequestConst.Post(
            '/authentication/register', body, headers,
            (response:Object) => {
                responseFunction(response)
                return response
            },
            (data: Object) => {
                thenFunction(data)
            },
            (error: Object) => {
                catchFunction(error)
            }, navigate, dispatch, setIsLoading
        )

        // await Api.post('/authentication/register', body, headers).then((response) => {
        //     responseFunction(response);
        //     return response;
        // }).then(data => {
        //     thenFunction(data);
        // }).catch((error) => {
        //     catchFunction(error);
        //     switch (error.response.status) {
        //         case constsApi.errors.error400.number:
        //             errorsFunctions.error400();
        //             break;
        //         case constsApi.errors.error422.number:
        //             errorsFunctions.error422();
        //             break;
        //         case constsApi.errors.error404.number:
        //             errorsFunctions.error404();
        //             break;

        //         default:
        //             break;
        //     }
        // });
    }

    SendEmailWithConfirmation = async (body: BodySendEmailWithConfirmationInterface, headers: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, errorsFunctions?: ErrorsInterface) => {
        await AppRequestConst.Post(
            '/authentication/request-comfirmation', body, headers,
            (response: Object) => {
                responseFunction(response)
                return response
            },
            (data: Object) => {
                thenFunction(data)
             },
            (error: Object) => {
                catchFunction(error)
             }, navigate, dispatch, setIsLoading
        )
        
        // await Api.post('/authentication/request-comfirmation', body, headers).then((response) => {
        //     responseFunction(response);
        //     return response;
        // }).then(data => {
        //     thenFunction(data);
        // }).catch((error) => {
        //     catchFunction(error);
        //     switch (error.response.status) {
        //         case constsApi.errors.error400.number:
        //             errorsFunctions.error400();
        //             break;
        //         case constsApi.errors.error422.number:
        //             errorsFunctions.error422();
        //             break;
        //         case constsApi.errors.error404.number:
        //             errorsFunctions.error404();
        //             break;

        //         default:
        //             break;
        //     }
        // });
    }
}


