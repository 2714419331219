import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useBotCxpressStarted = () => {

    const socket = useContext(SocketContext);

    const [botStarted, setBotStarted] = useState<any>()

    useEffect(() => {
        socket.socket?.on("botcxpress-started", (data) => setBotStarted(data))

        return () => {
            socket.socket?.off("botcxpress-started", setBotStarted)
        }
    }, [socket.socket?.active])

    return { botStarted }
}

export default useBotCxpressStarted