export enum StatusNotification {
    Solicitante = "419b9305-43db-44b1-a1ae-fbc7f4320ed4",
    Setor = "8c316398-2b8d-4866-b8d1-4928dbf23739",
    Atribuido = "e6cb92f7-8f29-4190-8204-ff6ca7037210",
    Seguidores = "0bff5c4a-8033-403f-8293-a418ee829c47",
    Status = "b802d887-26da-44d0-9493-cbec7c1c6cf9",
    Tags = "3b16a4f3-142c-42dc-8a34-2d1b1b7cbd49",
    AssuntoTicket = "f3ecbf15-dfb4-43d1-b888-91be7318c1b4",
    dataLimite = "d6c91542-d28f-4ee6-9f71-01bd1139d595",
    HoraLimite = "e8c53e75-89c2-422f-a731-c15769add44d",
    Observacao = '76ab5d38-cf23-4566-b41d-f05f3cfaf704',
}

export enum TriggerType {
    Add = "add",
    Remove = "remove",
    Replace = "replace",
    Notification = "notification"
}