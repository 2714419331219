import { FC, useEffect, useState } from "react";
import { IIntegrationParamsComponentController } from "./indexModel";
import { useDispatch, useSelector } from "react-redux";
import { getShowCreateParamModal, setShowConfirmationModal, setShowCreateParamModal } from "../../../../../../store/internal";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import IntegrationParamsComponent from ".";
import { useTranslation } from "react-i18next";

const IntegrationParamsComponentController: FC<IIntegrationParamsComponentController> = (props) => {
    const dispatch = useDispatch();

    const [dragged, setDragged] = useState<number | null>(null);
    // posição do mouse
    const [mouse, setMouse] = useState<[number, number]>([0, 0]);
    const [dropZone, setDropZone] = useState(0);
    const [selectedParam, setSelectedParam] = useState(null);

    const [showCreateParam, setShowCreateParam] = useState(false);

    const { t } = useTranslation()

    const values: TokenInterface = {
        internal: {
            getShowCreateParamModal: useSelector(getShowCreateParamModal),
        }
    }

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            setMouse([e.x, e.y]);
        };

        document.addEventListener("mousemove", handler);

        return () => document.removeEventListener("mousemove", handler);
    }, []);

    useEffect(() => {
        if (dragged !== null) {
            // dropzones
            const elements = Array.from(document.getElementsByClassName("drop-zone-param"));
            // posição dos dropzones
            const positions = elements.map((e) => e.getBoundingClientRect().top);
            // distancia do mouse para a posição do dropzone
            const absDifferences = positions.map((v) => Math.abs(v - mouse[1]));

            // drozone mais próximo do mouse, é onde será soltado o item arrastado
            let result = absDifferences.indexOf(Math.min(...absDifferences));

            if (result > dragged) result += 1;

            setDropZone(result);
        }
    }, [dragged, mouse]);

    // reordenando lista
    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (dragged !== null) {
                e.preventDefault();
                setDragged(null);

                props.setInitParams((params) => {
                    return reorderList(params, dragged, dropZone);
                });
            }
        };

        document.addEventListener("mouseup", handler);
        return () => document.removeEventListener("mouseup", handler);
    });
    
    // visibilidade da criação de parâmetro
    useEffect(() => {
        if (values.internal.getShowCreateParamModal) {
            setShowCreateParam(true);
        } else {
            setShowCreateParam(false);
        }
    }, [values.internal.getShowCreateParamModal]);

    // puxando item para baixo
    const reorderListForward = <T,>(l: T[], start: number, end: number) => {
        const temp = l[start];
      
        for (let i=start; i<end; i++) {
          l[i] = l[i+1];
        }
        l[end - 1] = temp;
      
        return l;
    };

    // puxando item para cima
    const reorderListBackward = <T,>(l: T[], start: number, end: number) => {
        const temp = l[start];
      
        for (let i = start; i > end; i--) {
          l[i] = l[i - 1];
        }
      
        l[end] = temp;
      
        return l;
    };

    // selecionando direção da reordenação do item
    const reorderList = <T,>(l: T[], start: number, end: number) => {
        if (start < end) 
            return reorderListForward([...l], start, end);
        else if (start > end) 
            return reorderListBackward([...l], start, end);

        return l;
    };

    const clickDown = () => {
        let start = null;
        props.initParams.map((item, index) => {
            if (item.name === selectedParam)
                start = index;
        });
        

        props.setInitParams((params) => {
            if (start && start < params.length - 1) {
                let temp = params[start];
                params[start] = params[start + 1];
                params[start + 1] = temp;
            }

            return params;
        });
    }

    const clickUp = () => {
        let start = null;
        props.initParams.find((item, index) => {
            if (item.name === selectedParam)
                start = index;
        });

        if (start && start > 0)
            props.setInitParams((params) => {
                let temp = params[start];
                params[start] = params[start - 1];
                params[start - 1] = temp;

                return params;
            });
    }

    const deleteParamConfirmation = () => {
        dispatch(setShowConfirmationModal({
            visibility: true, text: { "title": t('home_departments.integration_tab.integration_params.delete_param_confirmation.title'), "body": t('home_departments.integration_tab.integration_params.delete_param_confirmation.body'), "id": selectedParam + '?', "buttonReturnText": t('home_departments.integration_tab.integration_params.delete_param_confirmation.cancel') },
            functionConfirmation: () => deleteParam()
        }));

        /* let aux = [];
        aux.push(this.currentTicketId.split("*ID*")[1]);
        this.setDeleteListTickets(aux); */
    }

    const deleteParam = () => {
        props.setInitParams((params) => {
            return params.filter((param) => param.name !== selectedParam);
        });
    }

    const hideCreateParamModal = () => {
        dispatch(setShowCreateParamModal(false));
        setShowCreateParam(false);
    }

    return <IntegrationParamsComponent 
        initParams={props.initParams}
        setInitParams={props.setInitParams}
        dragged={dragged}
        setDragged={setDragged}
        mouse={mouse}
        dropZone={dropZone}
        selectedParam={selectedParam}
        setSelectedParam={setSelectedParam} 
        clickDown={clickDown} 
        clickUp={clickUp}    
        showCreateParamModal={showCreateParam}
        hideCreateParamModal={hideCreateParamModal}
        deleteParamConfirmation={deleteParamConfirmation}
        t={t}
    />
}

export default IntegrationParamsComponentController;