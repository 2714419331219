import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../../store/token'
import { getIdCompany } from '../../../store/company'
import { getIdEmployee } from '../../../store/employee'
import { getIdUser, getNameUser, getProfileId } from '../../../store/user'
import { setRegisterAgentsModal, getShowConfirmationModal, getShowEditTicketsModal, getShowAcquirePlatformComponent, setShowConfirmationModal, setShowAlertFeedback, setShowEditAgentsModal, getShowEditAgentsModal, getShowRegisterAgentsModal, } from "../../../store/internal";
import { AppRequesterController } from '../../../services/appRequester/appRequesterController';
import BackendConstants from '../../../core/constants/backend-constants';
import UserService from '../../../services/user-service';
import { TokenInterface } from '../../../services/requestsInterfacesModel';
import { AgentData } from './indexModel';
import HomeAgents from '.';
import { useTranslation } from 'react-i18next';
import { verifyCode } from '../../../services/codeCxpressInterface';

const AppRequesterConst = new AppRequesterController();

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const HomeAgentsController = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            quantityAgents: useSelector(getShowAcquirePlatformComponent).quantityAgents,
            getShowRegisterAgentsModal: useSelector(getShowRegisterAgentsModal),
            getShowEditAgentsModal: useSelector(getShowEditAgentsModal),
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [currentAgents, setCurrentAgents] = useState(undefined);
    const [/*currentAgent*/, setCurrentAgent] = useState([]);
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
    const [currentParams, SetCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [currentPage, SetCurrentPage] = useState(1);
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limit);
    const [currentHasMoreInformation, /*SetCurrentHasMoreInformation*/] = useState(true);
    const [currentState, setCurrentState] = useState("");
    //const [hasRemainingAgents, setHasRemainingAgents] = useState(false);
    const [creatingAgent, setCreatingAgent] = useState(false);

    const { t } = useTranslation();

    // quando o componente é montado os modais devem estar com visibilidade desabilitada
    useEffect(() => {
        if (values.internal.getShowRegisterAgentsModal) {
            dispatch(setRegisterAgentsModal(false));
        }

        if (values.internal.getShowEditAgentsModal) {
            dispatch(setShowEditAgentsModal(false));
        }
    }, []); 

    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value === null) {
        } else {
            SetCurrentParams((params) => {
                setCurrentState((state) => {
                    getAllAgentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);
                    return state;
                });
                return params;
            });
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState]);

    useEffect(() => {
        if (creatingAgent) {
            getHasRemainingAgents();
            setCreatingAgent(false);
        }
    }, [creatingAgent]);

    const getAllEmployees = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/agent', { headers, signal, params: params },
            (response: Object) => { },
            (data: AgentData) => {
                if (data.status === 200) {
                    setAux((state) => {

                        if (!state) {
                            state = [];
                        }

                        let aux = state.concat(data.data.employees);
                        setAux(aux);
                        setCurrentAgents(aux);
                        return state;
                    });
                } else if (aux.length === 0/*  && data.status !== 204 */) {
                    setCurrentAgents(undefined);
                }

            },
            (error: { response: { status: number; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    /**
     * Função para envio de email
     */
    const requestEmail = async () => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/company/send-updateAccount-email-request', { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_agents.general.request_email.message'), visibility: true, signalIcon: true }));
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                if (error.response !== undefined) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    // if (error.response.status === 400) {

                    // }
                    // if (error.response.status === 401) {

                    // }
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getHasRemainingAgents = async () => {
        let currentLimit, currentTotal;

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/agent', { headers, params: {} },
            (response: Object) => { },
            (data: AgentData) => {
                if (data.status === 200) {
                    currentLimit = data.data.total_limit;
                    currentTotal = data.data.total;
                }
            },
            (error: { response: { status: number; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
        if (currentLimit - currentTotal <= 0) {
            dispatch(setShowConfirmationModal({
                visibility: true,
                text: { title: t('home_agents.general.get_has_remain_agents.title'), body: t('home_agents.general.get_has_remain_agents.body'), id: "", buttonReturnText: t('home_agents.general.get_has_remain_agents.cancel'), buttonConfirmationText: t('home_agents.general.get_has_remain_agents.request_contact') },
                functionConfirmation: requestEmail
            }));
        } else {
            SetCurrentPage(1);
            // setAux([]);
            dispatch(setRegisterAgentsModal(true));
        }
    }

    const setCurrentAgentFunction = (value: any[]) => {
        setCurrentAgent(value);
    }

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    };

    const search = (e: any, auxObj: Object, action: string) => {
        SetCurrentPage(1);
        setAux([]);

        let name = "";
        if (e.currentTarget) {
            name = e.currentTarget.name;
        } else if (e.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            SetCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        SetCurrentParams(auxObj);
    }

    const updateListAfterDelete = () => {
        setAux([]);
        SetCurrentPage(1);

        setCurrentState("deleting");

        if (currentState === "deleting") {
            setCurrentState("");
        }
    };

    const getAllAgentsToPagination = (params: { limit: number; page: number; }, controller: AbortController) => {
        getAllEmployees(params, controller);
    }

    const showCreateAgent = () => {
        setCreatingAgent(true);
    }

    const callbackModalAgents = () => {
        SetCurrentPage(1);
        setAux([]);

        const controller = new AbortController();
        getAllAgentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);
    }

    const callbackOrderByEmployees = (column: any, order: any) => {
        SetCurrentPage(1);
        setAux([]);
        const auxObj = {};

        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order;
        }

        if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }
        
        setCurrentState(column + order);
        SetCurrentParams(auxObj);
        document.getElementById("ListOfAgents").scrollTop = 0;
    }

    return <HomeAgents
        values={values}
        isLoading={isLoading}
        currentAgents={currentAgents}
        currentHasMoreInformation={currentHasMoreInformation}
        SetCurrentFilterSearch={SetCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        callbackModalAgents={callbackModalAgents}
        callbackOrderByEmployees={callbackOrderByEmployees}
        search={search}
        setCurrentAgentFunction={setCurrentAgentFunction}
        showCreateAgent={showCreateAgent}
        updateListAfterDelete={updateListAfterDelete}
        updateListfunction={updateListfunction}
        t={t}
    />
}

export default HomeAgentsController;