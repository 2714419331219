import { FC } from 'react';
import { AlertPositiveNegativePropsInterface } from './indexModel';
import styles from './styles';
import { IconAlertCircle, IconRefresh } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const AlertDisconnectSocket: FC<AlertPositiveNegativePropsInterface> = (props) => {

  const {t} = useTranslation()

  return (
    <div style={{ display: props.displayFunction(), ...styles.customContainer }} className="">
      <div className="d-flex align-items-center me-0" style={styles.rowAlert}>
        <div className="d-flex justify-content-start align-items-center" style={{ width: 'fit-content', marginRight: '15px' }}>
          <IconAlertCircle size={26} color="#ffa500" />
        </div>
        <div className="d-flex justify-content-start nopadding" style={styles.primary}>
          {t('socket.instability_message')}
          <span style={styles.secondary}>{t('socket.verify_message')}</span>
        </div>
          <div className="d-flex justify-content-end">
            <IconRefresh size={20} color="#707070" style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => props.refreshWindow()}/>
          </div>
      </div>
    </div>
  );
}

export default AlertDisconnectSocket;
