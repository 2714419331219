import React, { useState, FC, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
    useDispatch,
    useSelector
} from 'react-redux'

// import { CallCenter } from "../../../../core/context/callcenter-context";

import { setShowEditTicketsModal, setShowCallNotificationBar, setShowMeetButton, setUpdateButtonNumberOfTickets } from '../../../../store/internal'
import { getOwner_id } from '../../../../store/callcenter';
import { CallNotificationBarInterface } from './indexModel';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { CallNotificationBarController } from './indexController';

const CallNotificationBar: FC<CallNotificationBarInterface> = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [visibility, setVisibility] = useState(props.visibility);
    // const callcenter = useContext(CallCenter);
    // const [callCenterState, setCallCenterState] = useState(callcenter.getState());
    // const [currentTicketCallCenter, setCurrentTicketCallCenter] = useState(callcenter.getTicketInfo());
    const CallNotificationBarControllerConst = new CallNotificationBarController(dispatch, setShowCallNotificationBar, setShowMeetButton, null/*setCurrentTicketCallCenter*/, null/*setCallCenterState*/, 
        visibility, setUpdateButtonNumberOfTickets, props, null/*currentTicketCallCenter*/, navigate, setShowEditTicketsModal);
    const [phonenumber, setPhonenumber] = useState(/*CallNotificationBarControllerConst.showPhone(callCenterState)*/123456789);
    const values: TokenInterface = {
        callcenter: {
            owner_id: useSelector(getOwner_id),
        }
    };

    useEffect(() => {
        setVisibility(props.visibility);
    }, [props.visibility]);

    // useEffect(() => {
    //     callcenter.addCallcenterCallback('homeTickets', (state, ticketState) => CallNotificationBarControllerConst.onCallcenterUpdate(state, ticketState, setPhonenumber))
    //     if (callCenterState.session.register_status === 'disconnected' && values.callcenter.owner_id) {
    //         callcenter.register((show: boolean) => { dispatch(setShowMeetButton(show)); dispatch(setShowCallNotificationBar(show)) })
    //     }
    //     return () => {
    //         callcenter.removeCallcenterCallback('homeTickets')
    //     }

    // }, [callcenter])

    return (
        <div style={{ display: CallNotificationBarControllerConst.showNotificationBar(), marginRight: '-1vw', cursor: 'pointer' }} onClick={() => CallNotificationBarControllerConst.showEditModal()}>
            <div className="d-flex align-items-center" style={{ background: '#CEF3F3 0% 0% no-repeat padding-box', height: 'max(35px, 3.2vh)', opacity: '1' }}>
                <div className="ms-4">
                    <svg style={{ width: '15px', height: '15px' }} xmlns="http://www.w3.org/2000/svg" width="18.71" height="18.71" viewBox="0 0 18.717 18.716">
                        <path id="telephone" d="M4.274,1.553A.793.793,0,0,0,3.087,1.48L1.878,2.69a2.007,2.007,0,0,0-.526,2.07,20.55,20.55,0,0,0,4.876,7.73,20.551,20.551,0,0,0,7.73,4.876,2.007,2.007,0,0,0,2.07-.526l1.21-1.21a.793.793,0,0,0-.074-1.187l-2.7-2.1a.793.793,0,0,0-.678-.143l-2.562.64A2.041,2.041,0,0,1,9.286,12.3L6.413,9.431a2.041,2.041,0,0,1-.538-1.938l.641-2.562a.793.793,0,0,0-.143-.678ZM2.2.6A2.041,2.041,0,0,1,5.259.788l2.1,2.7a2.04,2.04,0,0,1,.368,1.748L7.086,7.8a.793.793,0,0,0,.208.752l2.874,2.874a.793.793,0,0,0,.753.208l2.561-.64a2.041,2.041,0,0,1,1.748.368l2.7,2.1a2.041,2.041,0,0,1,.191,3.054l-1.21,1.21a3.248,3.248,0,0,1-3.365.821,21.8,21.8,0,0,1-8.2-5.17,21.8,21.8,0,0,1-5.17-8.2A3.252,3.252,0,0,1,1,1.807L2.205.6Z" transform="translate(0 0)" fill="#1e1e1e" />
                    </svg>
                    <span
                        style={{
                            textAlign: 'left',
                            font: 'var(--px15_19-font)',
                            letterSpacing: '0px',
                            color: '#1E1E1E',
                            marginLeft: '6px',
                        }}
                    >{phonenumber}</span>

                    <span
                        style={{
                            textAlign: 'left',
                            font: 'var(--px15_19_Bold-font)',
                            letterSpacing: '0px',
                            color: '#1E1E1E',
                        }}
                    >
                        #123{/*currentTicketCallCenter && currentTicketCallCenter.ticket_reference_id*/}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CallNotificationBar;