import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../../../components/loading";
import RegisterForm from "../registerForm";
import './styles.css'

const HomeRegisterMobile = () => {
    const [isLoading, /*setIsLoading*/] = useState(false);

    const getRegisterText = (): JSX.Element => {
        const language = localStorage.getItem('i18nextLng');
    
        if (language === 'en') {
            return (
                <div className="registerMobileHeader ps-0" style={{lineHeight: '5vh'}}>
                    Start your <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>15-day</span> <span className="registerMobileOrangeLabel">free trial</span><br/>
                    on the most <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>intuitive</span><br/> 
                    <span className="registerMobileOrangeLabel">conversation management</span><br/> 
                    platform on the market.
                </div>
            );
        } else if (language === 'es') {
            return (
                <div className="registerMobileHeader ps-0" style={{lineHeight: '5vh'}}>
                    Comienza tu <span className="registerMobileOrangeLabel">prueba gratuita</span><br /> 
                    de <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>15 días en la plataforma</span><br /> 
                    de <span className="registerMobileOrangeLabel">gestión de conversaciones</span><br /> 
                    más <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>intuitiva</span> del mercado.
                </div>
            );
        } else if (language === 'fr') {
            return (
                <div className="registerMobileHeader ps-0" style={{lineHeight: '5vh'}}>
                    Démarrez votre <span className="registerMobileOrangeLabel">essai gratuit</span><br />
                    de <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>15 jours sur la plateforme</span><br /> 
                    de <span className="registerMobileOrangeLabel">gestion de conversations</span><br /> 
                    la plus <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>intuitive</span> du marché.
                </div>
            );
        } else {
            return (
                <div className="registerMobileHeader ps-0" style={{lineHeight: '5vh'}}>
                    Comece seu  <span className="registerMobileOrangeLabel">teste grátis</span> <br/>
                    de <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>15 dias na plataforma</span> <br/>
                    de <span className="registerMobileOrangeLabel">gestão de conversas</span> <br/>
                    mais <span style={{font: 'var(--px21_33_Bold-Montserrat)'}}>intuitiva </span> do mercado.
                </div>
            );
        }
    }

    return (
        <Container fluid style={{ backgroundColor: '#232960'}} className="nopadding">
            <Loading visibility={isLoading}/>
            <Col className="nopadding" style={{ height: '100vh' }}>
                <Row className="d-flex nopadding align-items-end ms-3" style={{ height: '7%', width: '43vw' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ps-0" width={'100%'} viewBox="0 0 138.477 27.146">
                        <g id="CXpress-logo" transform="translate(-58.408 -58.421)">
                            <g id="Grupo_12683" data-name="Grupo 12683" transform="translate(109.821 63.492)">
                            <path id="Caminho_10178" data-name="Caminho 10178" d="M300.949,93.729l-2.841-3.237a.952.952,0,0,1,0-1.234l2.585-2.841a.92.92,0,0,0-.675-1.537h-.373a.943.943,0,0,0-.7.326l-2.282,2.655a.925.925,0,0,1-1.374.023l-3.33-3.7a.921.921,0,0,0-1.3-.047l-.14.116a.921.921,0,0,0-.047,1.3l3.213,3.563a.936.936,0,0,1,.163,1,5.99,5.99,0,0,1-2.026,2.352,6.072,6.072,0,0,1-3.726.955,7.579,7.579,0,0,1-3.283-.675,5,5,0,0,1-2.166-1.933,5.431,5.431,0,0,1-.768-2.911,5.792,5.792,0,0,1,.7-2.911,4.609,4.609,0,0,1,1.979-1.909,6.491,6.491,0,0,1,3-.675,5.961,5.961,0,0,1,1.141.093,1.906,1.906,0,0,0,2.072-1l.047-.07a.434.434,0,0,0-.21-.605h0a7.22,7.22,0,0,0-3.05-.629,9.825,9.825,0,0,0-4.424.955,6.9,6.9,0,0,0-2.911,2.678,7.961,7.961,0,0,0-1.025,4.075,7.631,7.631,0,0,0,1.071,4.1,6.994,6.994,0,0,0,3.1,2.678,10.94,10.94,0,0,0,4.7.931,9.974,9.974,0,0,0,3.889-.722,15.854,15.854,0,0,0,3.47-2.724.918.918,0,0,1,1.351.07l2.305,2.678a.907.907,0,0,0,.7.326h.489A.915.915,0,0,0,300.949,93.729Z" transform="translate(-279.2 -80.2)" fill="#fff"/>
                            <path id="Caminho_10179" data-name="Caminho 10179" d="M381.2,113.819V99.149h2.4l.023,2.212a4.582,4.582,0,0,1,1.933-1.886,6.183,6.183,0,0,1,2.98-.675,6.765,6.765,0,0,1,3.12.7,5.213,5.213,0,0,1,2.119,1.956,5.449,5.449,0,0,1,.745,2.887,5.61,5.61,0,0,1-.745,2.887,5.3,5.3,0,0,1-2.1,1.956,6.667,6.667,0,0,1-3.1.7,6.433,6.433,0,0,1-2.957-.675,4.588,4.588,0,0,1-1.956-1.863h-.023v6.45H381.2Zm9.617-6.613a3.451,3.451,0,0,0,1.4-1.164,3.2,3.2,0,0,0,0-3.4,3.147,3.147,0,0,0-1.4-1.164,5.218,5.218,0,0,0-4.1,0,3.452,3.452,0,0,0-1.4,1.164,3.2,3.2,0,0,0,0,3.4,3.147,3.147,0,0,0,1.4,1.164,4.81,4.81,0,0,0,2.049.419A4.421,4.421,0,0,0,390.817,107.206Z" transform="translate(-357.449 -94.469)" fill="#fff"/>
                            <path id="Caminho_10180" data-name="Caminho 10180" d="M451.5,99.5a4.167,4.167,0,0,1,1.118,2.7l-2.142.582a2.98,2.98,0,0,0-.675-1.746,1.952,1.952,0,0,0-1.49-.629,2.5,2.5,0,0,0-2.1,1.025,4.527,4.527,0,0,0-.768,2.794v5.053H443V98.9h2.049l.3,1.956h.023a4.17,4.17,0,0,1,1.444-1.723,3.792,3.792,0,0,1,2.142-.629A3.531,3.531,0,0,1,451.5,99.5Z" transform="translate(-404.858 -94.239)" fill="#fff"/>
                            <path id="Caminho_10181" data-name="Caminho 10181" d="M492.023,109.132a5.852,5.852,0,0,1-2.515-2,4.979,4.979,0,0,1-.908-2.934,5.135,5.135,0,0,1,.815-2.841A5.589,5.589,0,0,1,491.72,99.4a7.856,7.856,0,0,1,3.353-.7,7.3,7.3,0,0,1,3.26.7,5.136,5.136,0,0,1,2.212,1.933,5.041,5.041,0,0,1,.792,2.818,4.862,4.862,0,0,1-.07.885H491.161a3.011,3.011,0,0,0,1.49,2.142,5.619,5.619,0,0,0,3.12.815,7.417,7.417,0,0,0,2.7-.489,4.861,4.861,0,0,0,1.933-1.234l1.281,1.211a5.711,5.711,0,0,1-2.492,1.723,9.619,9.619,0,0,1-3.586.652A9.38,9.38,0,0,1,492.023,109.132Zm6.823-5.728a2.845,2.845,0,0,0-1.164-2.119,4.711,4.711,0,0,0-5.263.023,2.919,2.919,0,0,0-1.234,2.072l7.661.023Z" transform="translate(-439.84 -94.392)" fill="#fff"/>
                            <path id="Caminho_10182" data-name="Caminho 10182" d="M551.823,109.588a6.739,6.739,0,0,1-2.235-1.141A3.376,3.376,0,0,1,548.4,106.7l1.7-.768a3.067,3.067,0,0,0,1.63,1.677,6.291,6.291,0,0,0,2.957.652,5.746,5.746,0,0,0,2.352-.419q.908-.419.908-1.118a.992.992,0,0,0-.652-.885,6.611,6.611,0,0,0-1.979-.512l-2.212-.256a7.118,7.118,0,0,1-2.957-.931,2.2,2.2,0,0,1-.978-1.933,2.562,2.562,0,0,1,.652-1.77,3.835,3.835,0,0,1,1.77-1.141,8.078,8.078,0,0,1,2.608-.4,7.132,7.132,0,0,1,3.19.7,4.182,4.182,0,0,1,1.979,1.863l-1.607.838a2.875,2.875,0,0,0-1.4-1.257,4.973,4.973,0,0,0-2.212-.489,4.221,4.221,0,0,0-1.979.419c-.512.279-.792.605-.792,1a.9.9,0,0,0,.536.815,6.414,6.414,0,0,0,1.746.466l2.259.279a8.028,8.028,0,0,1,3.213,1,2.3,2.3,0,0,1,1.048,2.049,2.709,2.709,0,0,1-.7,1.84,4.326,4.326,0,0,1-1.956,1.211,8.845,8.845,0,0,1-2.818.419A13.612,13.612,0,0,1,551.823,109.588Z" transform="translate(-485.716 -94.546)" fill="#fff"/>
                            <path id="Caminho_10183" data-name="Caminho 10183" d="M605.923,109.588a6.738,6.738,0,0,1-2.235-1.141A3.376,3.376,0,0,1,602.5,106.7l1.7-.768a3.067,3.067,0,0,0,1.63,1.677,6.291,6.291,0,0,0,2.957.652,5.746,5.746,0,0,0,2.352-.419q.908-.419.908-1.118a.991.991,0,0,0-.652-.885,6.61,6.61,0,0,0-1.979-.512l-2.212-.256a7.117,7.117,0,0,1-2.957-.931,2.2,2.2,0,0,1-.978-1.933,2.562,2.562,0,0,1,.652-1.77,3.836,3.836,0,0,1,1.77-1.141,8.079,8.079,0,0,1,2.608-.4,7.132,7.132,0,0,1,3.19.7,4.182,4.182,0,0,1,1.979,1.863l-1.607.838a2.875,2.875,0,0,0-1.4-1.257,4.973,4.973,0,0,0-2.212-.489,4.219,4.219,0,0,0-1.979.419c-.512.279-.792.605-.792,1a.9.9,0,0,0,.536.815,6.415,6.415,0,0,0,1.746.466l2.259.279a8.029,8.029,0,0,1,3.213,1,2.3,2.3,0,0,1,1.048,2.049,2.709,2.709,0,0,1-.7,1.84,4.325,4.325,0,0,1-1.956,1.211,8.845,8.845,0,0,1-2.818.419A13.609,13.609,0,0,1,605.923,109.588Z" transform="translate(-527.218 -94.546)" fill="#fff"/>
                            </g>
                            <g id="Grupo_12684" data-name="Grupo 12684" transform="translate(58.408 58.421)">
                            <path id="Caminho_10184" data-name="Caminho 10184" d="M164.476,123.728a.725.725,0,1,0-.792-.652A.715.715,0,0,0,164.476,123.728Z" transform="translate(-139.167 -107.41)" fill="#fff"/>
                            <path id="Caminho_10185" data-name="Caminho 10185" d="M173.276,122.928a.725.725,0,1,0-.792-.652A.728.728,0,0,0,173.276,122.928Z" transform="translate(-145.918 -106.796)" fill="#fff"/>
                            <path id="Caminho_10186" data-name="Caminho 10186" d="M226.219,73.717a10.3,10.3,0,0,0-1.746-1.444c-1.746-1.211-4.913-1.42-4.378-1.723a22.384,22.384,0,0,1,4.145-.233.107.107,0,0,0,.023-.21,16.775,16.775,0,0,0-5.169.047,10.545,10.545,0,0,0-2.608.908.4.4,0,0,0,.233.745c3.633-.349,8.1,1.49,9.384,2.072C226.219,73.926,226.312,73.81,226.219,73.717Z" transform="translate(-179.51 -67.248)" fill="#fff"/>
                            <path id="Caminho_10187" data-name="Caminho 10187" d="M105.612,69.383a19.469,19.469,0,0,0-2.7-4.052c-.116-.116-.21-.256-.326-.373a27.5,27.5,0,0,0-3.726-3.283c.349-.116.745-.256,1.164-.419,4.191-1.56,6.59-1.677,6.59-1.467.023.605-1.84,1.863-1.257,2.072.419.163,1.56-1.63,4.215-2.305,1.607-.419,2.981-.163,2.724-.582-.536-.908-5.519-.652-9.384.419-2.026.559-3.865,1.164-5.216,1.653A10.688,10.688,0,0,0,93.038,60a9.975,9.975,0,0,0-2.026.21,9.581,9.581,0,0,0-1.374.466c-1.048-.279-3.912-1.025-7.125-1.537-3.959-.629-8.942-.373-9.384.605-.21.442,1.141.047,2.771.279,2.724.4,4.028,2.026,4.424,1.84.536-.279-1.444-1.327-1.49-1.933-.023-.21,2.375-.326,6.706.768,1.141.279,2.049.489,2.608.605a11.321,11.321,0,0,0-1.188.652c-1.4.885-2.072,2.049-3.213,3.376-1.071,1.583-1.909,2.608-2.678,3.982h-.023A4.767,4.767,0,0,0,77.39,72.2c-.047.093.093.186.163.116l3.283-2.561a19.21,19.21,0,0,0-1.048,2.422c-.512,1.4-.768,4.028,1.094,7.638,1.909,2.818,4.238,5.752,13.017,5.752,7.1,0,9.733-1.351,11.643-4.028a10.035,10.035,0,0,0,1.746-5.728,17.721,17.721,0,0,0-1.514-5.961l3.4,2.119a.112.112,0,0,0,.163-.14A4.632,4.632,0,0,0,105.612,69.383Zm-3.307,5.472a3.507,3.507,0,0,1-2.235-.093,4.921,4.921,0,0,1,.186,1.048,4.336,4.336,0,0,1-4.122,5.03,4.437,4.437,0,0,1-5.123-4.145,2.819,2.819,0,0,1-.023-.442,4.708,4.708,0,0,1-3.47.768c-3.912-.652-5.193-4.843-3.935-7.964,1.3-3.26,3.307-5.123,6.52-4.68a5.768,5.768,0,0,1,2.189.792L94.272,66.4a1.514,1.514,0,0,0,1.677-.047l2-1.467a1.715,1.715,0,0,1,1-.326c1.956.047,3.4,1.653,4.448,3.423C104.611,70.012,105.356,73.9,102.306,74.855Z" transform="translate(-69.685 -58.421)" fill="#fff"/>
                            <path id="Caminho_10188" data-name="Caminho 10188" d="M221,94.058a6.523,6.523,0,0,0-3.283-1.351,34.707,34.707,0,0,0-3.726-.07.222.222,0,0,0-.093.419l2.515,1.49,3.237,1.84a.227.227,0,0,0,.279-.349l-1.514-1.723a.231.231,0,0,1,.233-.373l2.235.512C221.069,94.523,221.208,94.2,221,94.058Z" transform="translate(-177.597 -84.655)" fill="#fff"/>
                            <path id="Caminho_10189" data-name="Caminho 10189" d="M220.8,83.885a.141.141,0,0,0,0,.279,35,35,0,0,1,4.4.885,16.528,16.528,0,0,1,1.653.559.146.146,0,0,0,.14-.256A8.062,8.062,0,0,0,220.8,83.885Z" transform="translate(-182.89 -77.859)" fill="#fff"/>
                            <path id="Caminho_10190" data-name="Caminho 10190" d="M67.837,73.247a10.351,10.351,0,0,0-2.7-.605,16.157,16.157,0,0,0-5.146.512c-.116.023-.093.21.047.21a20.439,20.439,0,0,1,4.145-.21c.582.233-2.561.768-4.168,2.166a11.24,11.24,0,0,0-1.583,1.63.1.1,0,0,0,.14.14c1.234-.722,5.472-3.027,9.1-3.074A.4.4,0,0,0,67.837,73.247Z" transform="translate(-58.408 -69.294)" fill="#fff"/>
                            <path id="Caminho_10191" data-name="Caminho 10191" d="M80.819,93.219a.212.212,0,0,0,.07-.256.253.253,0,0,0-.233-.163,34.022,34.022,0,0,0-3.7.489,6.325,6.325,0,0,0-3.12,1.7.229.229,0,0,0,.21.4l2.166-.768a.226.226,0,0,1,.256.349l-1.327,1.886a.225.225,0,0,0,.326.3l3-2.189Z" transform="translate(-70.18 -84.795)" fill="#fff"/>
                            <path id="Caminho_10192" data-name="Caminho 10192" d="M76.088,85.423a.141.141,0,0,0-.023-.279,8.1,8.1,0,0,0-6.008,2.142.144.144,0,0,0,.163.233c.4-.21.908-.466,1.583-.745A36.769,36.769,0,0,1,76.088,85.423Z" transform="translate(-67.311 -78.908)" fill="#fff"/>
                            <path id="Caminho_10193" data-name="Caminho 10193" d="M162.112,135.8a4.072,4.072,0,0,1-2.864,1.653,5.166,5.166,0,0,1-3.47-1.374.331.331,0,0,0-.466.023.354.354,0,0,0,0,.466,5.927,5.927,0,0,0,3.7,1.56h.256a4.665,4.665,0,0,0,3.33-1.909.332.332,0,0,0-.047-.466A.306.306,0,0,0,162.112,135.8Z" transform="translate(-132.681 -117.69)" fill="#fff"/>
                            <path id="Caminho_10194" data-name="Caminho 10194" d="M145.372,100.409a1.708,1.708,0,1,0-1.537,1.863A1.717,1.717,0,0,0,145.372,100.409Z" transform="translate(-122.508 -89.447)" fill="#fff"/>
                            <path id="Caminho_10195" data-name="Caminho 10195" d="M181.509,97.073a1.708,1.708,0,1,0,1.863,1.537A1.717,1.717,0,0,0,181.509,97.073Z" transform="translate(-151.659 -88.066)" fill="#fff"/>
                            </g>
                        </g>
                    </svg>
                </Row>
                <Row className="d-flex align-items-center nopadding mx-3" style={{ height: '31%' }}>
                    {getRegisterText()}
                </Row>
                <Row className="d-flex nopadding backgroundRegisterMobile pt-4" style={{ height: '62%' }}>
                    <Col className="mx-3" style={{}}>
                        <RegisterForm isMobile={true}/>
                    </Col>
                </Row>
            </Col>
        </Container>
    );
}

export default HomeRegisterMobile;