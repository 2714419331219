import { useEffect, useState } from "react"
import AppAuthTab from "."
import { useTranslation } from "react-i18next";


const AppAuthTabController = (props: any) => {

    const { t } = useTranslation();

    const [imageQR, setImageQR] = useState(null);
    const [secret, setSecret] = useState(null);
    const [showFeedback2FA, setShowFeedback2FA] = useState(false);
    const [isLoadingQR, setIsLoadingQR] = useState(true);

    useEffect(() => {
        setIsLoadingQR(true);
        setImageQR(null);
        setSecret(null);

        if (!props.employeeHasAuthApp) {
            const fetchSecret = async () => {
                const data = await props.generateAppCode();
                setImageQR(data?.qr);
                setSecret(data?.secret);
            }
            fetchSecret();
        }
    }, [props.employeeHasAuthApp]);

    const verifyCode = async (req_values: { code_2fa: string }) => {
        await props.verifyAppCode(req_values?.code_2fa);
    }

    const revokeCode = async () => {
        await props.openRemoveConfirmation();
    }

    const onLoadQR = () => {
        setIsLoadingQR(false);
    }

    const validate2FA = (values: any) => {
        const errors = {};
        if (isNaN(values.code_2fa)) {
            errors['code_2fa'] = t('login.error_2fa_only_numbers');
            setShowFeedback2FA(true);
            changeDocument('feedback_code_2fa', 'login.error_2fa_only_numbers');
        } else if (!values.code_2fa || String(values.code_2fa).length === 0) {
            errors['code_2fa'] = t('login.error_fill_field');
            setShowFeedback2FA(true);
            changeDocument('feedback_code_2fa', 'login.error_fill_field');
        } else if (String(values.code_2fa).length !== 6) {
            errors['code_2fa'] = t('login.error_2fa_length');
            setShowFeedback2FA(true);
            changeDocument('feedback_code_2fa', 'login.error_2fa_length');
        } else {
            setShowFeedback2FA(false);
            changeDocument('feedback_code_2fa', '');
        }
        return errors;
    }

    const changeDocument = (element_id: string, tag_i18n: string) => {
        const element = document.getElementById(element_id);
        if (element) {
            if (tag_i18n) {
                document.getElementById(element_id).innerHTML = t(tag_i18n);
            } else {
                document.getElementById(element_id).innerHTML = '';
            }
        }
    }

    return (
        <AppAuthTab
            t={t}
            employeeHasAuthApp={props.employeeHasAuthApp}
            revokeCode={revokeCode}
            validate2FA={validate2FA}
            verifyCode={verifyCode}
            onLoadQR={onLoadQR}
            imageQR={imageQR}
            secret={secret}
            showFeedback2FA={showFeedback2FA}
            isLoadingQR={isLoadingQR}
        />
    )
}

export default AppAuthTabController