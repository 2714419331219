import React, { FC, useEffect } from "react";
import { IIntegrationTab } from "./indexModel";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ListOfIntegrationsController from "./listOfIntegrations/indexController";
import CreateIntegrationModalController from "./createIntegrationModal/indexController";
import EditIntegrationModalController from "./editIntegrationModal/indexController";
import Loading from "../../../../../components/loading";

const IntegrationTab: FC<IIntegrationTab> = (props) => {
    return (
        <>
            <CreateIntegrationModalController callback={props.callback} />
            <EditIntegrationModalController callback={props.callback} currentIntegrationId={props.currentIntegrationId} />
            <Loading visibility={props.isLoading} />
            <Row id="buttonCreateDepartment" style={{ marginTop: '4vh', padding: 0 }}>
                <Col md={3} >
                    <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                        {props.t('home_departments.integration_tab.general.create_integration')}
                        <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                    {props.t('home_departments.integration_tab.general.tooltip')}
                                </Tooltip>
                            }
                        >
                            <Button className="mx-3 nopadding buttonTicketsSec" onClick={ props.showCreateIntegrationModal }>
                                <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                    <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                    <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>
                            </Button>
                        </OverlayTrigger>
                    </p>
                </Col>
            </Row>
            <ListOfIntegrationsController integrations={props.integrations} callback={props.callback} setCurrentIntegrationId={props.setCurrentIntegrationId} showCreateIntegrationModal={props.showCreateIntegrationModal} />
        </>
    );
}

export default IntegrationTab;