import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

export interface ReadNotificationInterface {
    user_id: string
}
const useReadNotificationTicketSocket = () => {

    const socket = useContext(SocketContext)

    const ticket_read_notification = new BroadcastChannel('ticket_read_notification')

    useEffect(() => {
        socket.socket?.on('read-ticket-notification', (data) => ticket_read_notification.postMessage(data))

        return () => {
            socket.socket?.off('read-ticket-notification', () => { })
        }
    }, [socket.socket?.active])

    return { ticket_read_notification }
}

export default useReadNotificationTicketSocket