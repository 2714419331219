import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";

export interface IDataDashboard {
    printPDF: boolean,
    t: TFunction<"translation", undefined, "translation">,
    totalTickets: number,
    inProgressTickets: number,
    newTickets: number,
    averageTicketsSolved: number,
    totalConsumers: number,
    averageTicketsTime: number,
    convertNumToTime: (num: number) => string,
    averageWaitingTime: number,
    dropoutRate: number,
    openModalListConsumers: () => void,
    showModalListConsumers: boolean,
    setShowListConsumers: Dispatch<SetStateAction<boolean>>,
    currentParams: any,
    showModalListTickets: boolean,
    openModalListTickets: (dashboardButton?: DashboardButton) => void,
    setShowListTickets: Dispatch<SetStateAction<boolean>>,
}

export interface IDataDashboardController {
    totalTickets,
    inProgressTickets,
    newTickets,
    totalConsumers,
    averageTicketsTime,
    averageWaitingTime,
    dropoutRate,
    averageTicketsSolved,
    printPDF,
    currentParams: any,
    setCurrentParams: Dispatch<SetStateAction<{}>>,
}

export enum DashboardButton {
    TotalOfTicket = "totalOfTickets",
    InProgress = "inProgress",
    New = "new",
    TotalOfConsumers = "totalOfConsumers",
}