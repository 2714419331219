import { useDispatch, useSelector } from "react-redux";
import Keyboard from ".";
import { getExtension, getPhoneNumber } from "../../../../../../../../store/callcenter";
import { FC, useEffect, useState } from "react";
import { KeyboardControllerInterface } from "./indexModel";
import UserService from "../../../../../../../../services/user-service";
import { AppRequesterController } from "../../../../../../../../services/appRequester/appRequesterController";
import { AgentsExtensionData, AgentsExtensionRequestInterface } from "../AgentsExtension/indexModel";
import { useTranslation } from "react-i18next";

const AppRequesterConst = new AppRequesterController();

const KeyboardController: FC<KeyboardControllerInterface> = ({ callcenterContext }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const extension = useSelector(getExtension);
  const preferred_number = useSelector(getPhoneNumber);

  const [showTransfer, setShowTransfer] = useState(false);
  const [extensions, setExtensions] = useState<AgentsExtensionData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toTransfer, setToTransfer] = useState(null as string);

  useEffect(() => {
    getAgentsExtension();
  }, []);

  useEffect(() => {
    setToTransfer(null);
  }, [showTransfer]);

  useEffect(() => {
      setShowTransfer(false);
  }, [callcenterContext.callStatusIsActive]);

  const changeDisplay = (evt: { target: { textContent: string } }): void  => {
    const digit = evt.target.textContent;
    const display = callcenterContext.callInfoDisplay;

    callcenterContext.instance.sendDTMF(digit);
    callcenterContext.instance.setCallInfoDisplay(display + digit);
  }

  const omitCharTerms = (event: KeyboardEvent): boolean => {
    const allowed = [
      'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9',
      'Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
      'NumpadMultiply', 'Backspace'
    ];
    if (allowed.includes(event.code)) {
      const display = callcenterContext.callInfoDisplay;
      
      if (event.code !== 'Backspace') {
        callcenterContext.instance.sendDTMF(event.key);
        callcenterContext.instance.setCallInfoDisplay(display + event.key);
      } else {
        const temp_display = display.slice(0,  display.length - 1);
        callcenterContext.instance.setCallInfoDisplay(temp_display);
      }
      return true;
    }
    return false;
  }

  const clearDisplay = () => {
    const display = callcenterContext.callInfoDisplay;
    callcenterContext.instance.setCallInfoDisplay(display.slice(0, display.length - 1));
  }

  const showDisplay = (): boolean => {
    switch (callcenterContext.callStatusDirection) {
      case 'incoming': {
        return (
          callcenterContext.callStatusIsActive ||
          ['ringing'].includes(callcenterContext.callStatusEvent)
        );
      }
      case 'outgoing': {
        return (
          callcenterContext.callStatusIsActive ||
          ['trying', 'calling'].includes(callcenterContext.callStatusEvent)
        );
      }
      default:
        return false;
    }
  }

  const formatDuration = (time = 0) => {
    const sec_num = parseInt(String(time), 10)
    const hours   = Math.floor(sec_num / 3600)
    const minutes = Math.floor(sec_num / 60) % 60
    const seconds = sec_num % 60

    return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":");
  }

  const getAgentsExtension = async () => {
    const headers = UserService.getHeaders();

    const config = { headers };
    await AppRequesterConst.Get(
        'channel-vozxpress/extensions',
        config,
        (response: Object) => {
        },
        (response: AgentsExtensionRequestInterface) => {
          if (response.status === 200) { 
            const list = [];
            const extensions = response.data.extensions || response.data.extentions;

            extensions?.forEach((item, index) => {
              if (item.extension !== extension) {
                list.push({
                  id: `agent-extension-${index}`,
                  extension: item.extension,
                  employee_name: item.employee?.name || '',
                  employee_email: item.employee?.email || undefined,
                });
              }
            });

            setExtensions(list);
          }
        },
        (error: Object) => {
        }, null, dispatch, setIsLoading, {}
    );
  }

  const handleTransfer = (type: 'direct' | 'assisted') => {
    if (toTransfer) {
      console.log('transfer', type, toTransfer);
      const command = type === 'direct' ? '##' : '*2';
      callcenterContext.instance.transferCall(command, toTransfer);
      setShowTransfer(false);
    }
  }
  
  return (
    <Keyboard 
      extension={extension}
      preferred_number={preferred_number}
      callcenterContext={callcenterContext}
      changeDisplay={changeDisplay}
      omitCharTerms={omitCharTerms}
      clearDisplay={clearDisplay}
      showDisplay={showDisplay}
      formatDuration={formatDuration}
      showTransfer={showTransfer}
      setShowTransfer={setShowTransfer}
      extensions={extensions}
      toTransfer={toTransfer}
      setToTransfer={setToTransfer}
      handleTransfer={handleTransfer}
      t={t}
    />
  );
}

export default KeyboardController;