import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationsPtBr from './locales/pt-BR/translations.json';
import translationsPtPt from './locales/pt-PT/translations.json';
import translationsEnUk from './locales/en/translations.json';
import translationsEs from './locales/es/translations.json';

const resources = {
  'pt-BR': { translations: translationsPtBr },
  'pt-PT': { translations: translationsPtPt },
  'en': { translations: translationsEnUk },
  'es': { translations: translationsEs },
}

const i18nConfig = {
  resources,
  fallbackLng: ['pt-BR', 'pt-PT', 'pt', 'en', 'es'],
  defaultNS: 'translations',
  detection: {
    order: [
      'localStorage',
      'navigator',
      'sessionStorage',
      'htmlTag',
      'path',
      'subdomain',
    ],
  },

  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nConfig);

export default i18n;
