import MoreInfo from "./index";

const MoreInfoController = (props) => {
  
  return (
    <MoreInfo 
      show={props.show}
      onHide={props.onHide}
      moreInfo={props.moreInfo}
    />
  )
}

export default MoreInfoController;