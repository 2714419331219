import './styles.scss';
import { FC } from 'react';
import {
  Col,
  Container,
  Row,
  Form,
  FormControl,
  InputGroup,
  Button,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { LoginControlPanelProps } from './indexModel';
import Loading from '../../../components/loading';
import { IconEye, IconEyeClosed, IconEyeOff } from '@tabler/icons-react';

const LoginControlPanel: FC<LoginControlPanelProps> = (props) => {
  return (
    <>
      <Container fluid className='nopadding loginControlPanel'>
        {props.isLoading && <Loading visibility={props.isLoading}></Loading>}
        <Row className='nopadding'>
          <Col className='nopadding'>
            <Row className='nopadding align-items-center body-content'>
              <div className='d-flex justify-content-center'>
                <Col className='box-content' sm={3} md={3}>
                  <div className='d-flex align-items-center'>
                    <div className='validateLogo'>
                      <img src='/Logo.svg' alt='' />
                    </div>
                    <Col className='d-flex justify-content-end px-1 box-title'>
                      { props.scene === 'login' &&  <>Login</> }
                      { (props.scene === 'signup' || props.scene === 'signup_success') &&  <>Cadastro</> }
                      { (props.scene === 'verify_2fa' || props.scene === 'config_2fa') &&  <>Segurança</> }
                    </Col>
                  </div>
                  {
                    props.scene === 'login' &&
                    <>
                      <Formik
                        initialValues={{ email: '', password: '' }}
                        validate={props.validateLogin}
                        onSubmit={props.submitLogin}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <Form className='loginForm'
                            onSubmit={handleSubmit}
                            noValidate
                            validated={props.validated}
                          >
                            <Row className='d-flex justify-content-center'>
                                <Row className='form-item mt-4'>
                                  <Form.Label htmlFor='email' className='px-1'>E-mail</Form.Label>
                                  <InputGroup className='mb-3 px-1'>
                                    <FormControl
                                      className='form-control-Default'
                                      isInvalid={props.showfeedbackEmail}
                                      placeholder='Informe seu e-mail'
                                      aria-label='Informe seu e-mail'
                                      aria-describedby='Informe seu e-mail'
                                      size='lg'
                                      type='email'
                                      id='email'
                                      name='email'
                                      autoComplete='email'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    ></FormControl>
                                  </InputGroup>
                                </Row>
                                <Row className='form-item mt-2'>
                                  <Form.Label htmlFor='password' className='px-1'>Senha</Form.Label>
                                  <InputGroup className='mb-3 px-1'>
                                    <FormControl
                                      className='form-control-Default'
                                      isInvalid={props.showfeedbackPassword}
                                      placeholder='Informe sua senha'
                                      aria-label='Informe sua senha'
                                      aria-describedby='Informe sua senha'
                                      size='lg'
                                      type='password'
                                      id='user_password'
                                      name='password'
                                      autoComplete='password'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />
                                    <span className="showHidePassword">
                                      { props.showPassword ? 
                                        <IconEye color='#707070' onClick={() => props.clickPassword()} />
                                        :
                                        <IconEyeOff color='#707070' onClick={() => props.clickPassword()} /> 
                                      }
                                    </span>
                                    <Form.Control.Feedback className='feedbackError' type='invalid' id='feedbackPassword'>{errors.password}</Form.Control.Feedback>
                                  </InputGroup>
                                </Row>
                                <Row className='mt-3'>
                                  <Form.Control.Feedback type='invalid' id='feedbackEmail'>{errors.email}</Form.Control.Feedback>
                                  <div className='px-1 actionButtons'>
                                    <Button type='submit' className='buttonBlue'>Entrar</Button>
                                    <Button className='buttonFirstAccess' onClick={() => props.setScene('signup')}>
                                      Primeiro acesso
                                    </Button>
                                  </div>
                                </Row>
                                <Row className="mt-3">
                                  <div className="changeMethod d-flex align-content-center justify-content-center">
                                    <span onClick={() => props.goToForgotPassword()}>Esqueci a senha</span>
                                  </div>
                                </Row>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </>
                  }
                  {
                    props.scene === 'signup' &&
                    <>
                      <Formik
                        initialValues={{ email: '', password: '', confirmPassword: '' }}
                        validate={props.validateSignup}
                        onSubmit={props.submitSignup}
                      >
                        {(formik) => (
                          <Form className='loginForm'
                            onSubmit={formik.handleSubmit}
                            noValidate
                            validated={props.validated}
                          >
                            <Row className='d-flex justify-content-center'>
                              <Row className='form-item mt-3'>
                                <Form.Label htmlFor='email' className='px-1'>E-mail</Form.Label>
                                <InputGroup className='mb-3 px-1'>
                                  <FormControl
                                    className='form-control-Default'
                                    isInvalid={props.showfeedbackEmail}
                                    placeholder='Informe seu e-mail'
                                    aria-label='Informe seu e-mail'
                                    aria-describedby='Informe seu e-mail'
                                    size='lg'
                                    type='email'
                                    id='email'
                                    name='email'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                  ></FormControl>
                                  <Form.Control.Feedback type='invalid' id='feedbackEmail'>{formik.errors.email}</Form.Control.Feedback>
                                </InputGroup>
                              </Row>
                              <Row className='form-item mt-2'>
                                <Form.Label htmlFor='password' className='px-1'>Senha</Form.Label>
                                <InputGroup className='mb-3 px-1'>
                                  <FormControl
                                    className='form-control-Default'
                                    isInvalid={props.showfeedbackPassword}
                                    placeholder='Informe sua senha'
                                    aria-label='Informe sua senha'
                                    aria-describedby='Informe sua senha'
                                    size='lg'
                                    type='password'
                                    id='user_password'
                                    name='password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                  ></FormControl>
                                  <span className="showHidePassword">
                                    { props.showPassword ? 
                                      <IconEyeClosed color='#707070' onClick={() => props.clickPassword()} /> 
                                      :
                                      <IconEye color='#707070' onClick={() => props.clickPassword()} /> 
                                    }
                                  </span>
                                  <div className='d-flex justify-content-end align-self-start'>
                                  </div>
                                    <Form.Control.Feedback type='invalid' id='feedbackPassword' tooltip>{formik.errors.password}</Form.Control.Feedback>
                                </InputGroup>
                              </Row>
                              <Row className='form-item mt-2'>
                                <Form.Label htmlFor='confirmPassword' className='px-1'>Confirmar Senha</Form.Label>
                                <InputGroup className='mb-3 px-1'>
                                  <FormControl
                                    className='form-control-Default'
                                    isInvalid={props.showfeedbackConfirmPassword}
                                    placeholder='Confirme sua senha'
                                    aria-label='Confirme sua senha'
                                    aria-describedby='Confirme sua senha'
                                    size='lg'
                                    type='password'
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                  ></FormControl>
                                  <span className="showHidePassword">
                                    { props.showConfirmPassword ? 
                                      <IconEyeClosed color='#707070' onClick={() => props.clickConfirmPassword()} /> 
                                      :
                                      <IconEye color='#707070' onClick={() => props.clickConfirmPassword()} /> 
                                    }
                                  </span>
                                  <div className='d-flex justify-content-end align-self-start'>
                                  </div>
                                  <Form.Control.Feedback type='invalid' id='feedbackConfirmPassword' tooltip>{formik.errors.confirmPassword}</Form.Control.Feedback>
                                </InputGroup>
                              </Row>
                              <Row className='mt-3'>
                                <Form.Control.Feedback type='invalid' id='feedbackEmail'>{formik.errors.email}</Form.Control.Feedback>
                                <div className='px-1 actionButtons'>
                                  <Button type='submit' className='buttonBlue'>Cadastrar</Button>
                                  <Button className='buttonFirstAccess' onClick={() => props.setScene('login')}>
                                    Voltar para o login
                                  </Button>
                                </div>
                              </Row>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </>
                  }
                  {
                    props.scene === 'signup_success' &&
                    <>
                      <div className='confirmed'>
                        <span className="title mt-2 mb-3">Conta criada com sucesso!</span>
                        <p className="description">
                          Você receberá um e-mail para confirmar a criação de sua conta.
                        </p>
                        <Button className='buttonFirstAccess' onClick={() => props.setScene('login')}>Fazer login</Button>
                      </div>
                    </>
                  }
                  {
                    props.scene === 'confirm-email' &&
                    <>
                      <div className='confirmed'>
                        <span className="title mt-2 mb-3">Você ainda não confirmou sua conta!</span>
                        <p className="description">
                          Um link foi enviado ao seu e-mail para confirmar a criação de sua conta. Verifique-o para fazer a confirmação.
                        </p>
                        <Button className='buttonFirstAccess' onClick={() => props.setScene('login')}>Voltar para o login</Button>
                      </div>
                    </>
                  }
                  {
                    props.scene === 'verify_2fa' &&
                    <>
                      <div className="mt-2 info2fa">
                        { props.type2fa === 'email' && 
                          <span>Um código de autenticação foi enviado para seu e-mail. Digite-o abaixo para seguir com o login na aplicação</span>
                        }
                        { props.type2fa === 'app' && 
                          <span>Digite o código gerado no seu aplicativo de autenticação para seguir com o login na aplicação</span>
                        }
                      </div>
                      <Formik
                        initialValues={{ code_2fa: '' }}
                        validate={props.validate2FA}
                        onSubmit={props.submitVerify2FA}
                      >
                        {formik => (
                          <Form 
                            className='verify2faForm'
                            onSubmit={formik.handleSubmit}
                            validated={props.validated}
                          >
                            <Row className='d-flex justify-content-center'>
                              <Row className='form-item mt-4'>
                                  <InputGroup className='mb-3 px-1'>
                                    <FormControl
                                      className='form-control-Default'
                                      isInvalid={props.showfeedback2FA}
                                      placeholder={props.type2fa === 'app' ? 'Informe o código gerado no aplicativo' : 'Informe o código recebido no e-mail'}
                                      aria-label={props.type2fa === 'app' ? 'Informe o código gerado no aplicativo' : 'Informe o código recebido no e-mail'}
                                      aria-describedby={props.type2fa === 'app' ? 'Informe o código gerado no aplicativo' : 'Informe o código recebido no e-mail'}
                                      size='lg'
                                      type='text'
                                      id='code_2fa'
                                      name='code_2fa'
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.code_2fa}
                                    ></FormControl>
                                    <Form.Control.Feedback type='invalid' id='feedback_code_2fa'>{formik.errors.code_2fa}</Form.Control.Feedback>
                                </InputGroup>
                              </Row>
                              <Row className='mt-3'>
                                <div className='px-1 actionButtons'>
                                  <Button type='submit' className='buttonBlue'>Verificar</Button>
                                  <Button className='buttonFirstAccess' onClick={() => props.setScene('login')}>
                                    Voltar para o login
                                  </Button>
                                </div>
                              </Row>
                              {
                                (props.type2fa === 'app' || (props.type2fa === 'email' && props.hasApp2FA)) &&
                                <Row className="mt-3 nopadding" >
                                  <div className="changeMethod">
                                    { props.type2fa === 'app' &&
                                      <span onClick={() => props.changeDefault2FA('email')}>Receber código por e-mail</span>
                                    }
                                    {
                                      props.type2fa === 'email' && props.hasApp2FA &&
                                      <span onClick={() => props.changeDefault2FA('app')}>Utilizar aplicativo de autenticação</span>
                                    }
                                  </div>
                                </Row>
                              }
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </>
                  }
                  {
                    props.scene === 'config_2fa' &&
                    <>
                      <Row className="mt-3 nopadding">
                        <span className="info mb-3">Você não possui aplicativo de autenticação configurado.</span>
                        <div className="changeMethod">
                          <span onClick={() => props.changeDefault2FA('email')}>Receber código por e-mail</span>
                        </div>
                      </Row>
                    </>
                  }
                </Col>
              </div>
            </Row>
            <Row className='nopadding footer-content'>
              <Col className='d-flex justify-content-center align-items-center pt-3'>
                <p className='footer-text'>
                  <span>Esta é uma área exclusiva e de acesso restrito. </span>
                  <span>2022 Cxpress. Todos os direitos reservados</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginControlPanel;
