import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import allStyles from './styles';
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../store/token';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';

const AppRequesterConst = new AppRequesterController()

const ModalEditarAccount = ({ show, handleClose, data}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [validated] = useState(false);
    const [password, setPassword] = useState('');
    const [showFeedbackPassword, setShowFeedbackPassword] = useState(false);
    const [account, setAccount] = useState(undefined)
    const [showPassword, setShowPassword] = useState(false)

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }
    
    useEffect(() => {
        setAccount(data?.vozxpressAccount)
    }, [data?.vozxpressAccount])


    const ClickToViewPassword = () => {
        setShowPassword(!showPassword);
        
        if ((document.getElementById("password") as HTMLInputElement).type === "text") {
            (document.getElementById("password") as HTMLInputElement).type = "password";
        } else {
            (document.getElementById("password") as HTMLInputElement).type = "text";
        }
    }
    
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Editar Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ email: account?.email, password: account?.password , token: values?.token?.value}}
                    validate={values => {
                        let errors = {};
                        setPassword(values.password);

                        if (!values.password) {
                            errors["password"] = 'Preencha este campo';
                            setShowFeedbackPassword(true);
                        } else if (
                            values.password.length<8
                        ) {
                            errors["password"] = 'Senha inválida';
                            setShowFeedbackPassword(true);
                        } else {
                            setShowFeedbackPassword(false);
                        }

                        return errors;
                    }}
                    onSubmit={values => {
                        const JsonSend = {
                            "password": password,
                        };
                        
                        const headers = {
                          "Content-Type": "application/json; charset=utf-8",
                          "Authorization": "Bearer " + values.token
                        };
                    
                        AppRequesterConst.Put(
                          `/panel/vozxpress/account/${account.id}/reset-password`,
                          JsonSend,
                          { headers: headers },
                          (response: Object) => {
                            return response;
                          },
                          (data:Object) => {
                          },
                          (error:Object) => {
                          },
                          navigate,
                          dispatch,
                          setIsLoading
                        );
                        handleClose();
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="email">Endereço de e-mail</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            disabled={true}
                                            placeholder="Informe o endereço de e-mail"
                                            aria-label="Informe o endereço de e-mail"
                                            aria-describedby="Informe o endereço de e-mail"
                                            size='lg'
                                            type='email'
                                            id="email"
                                            name="email"
                                            autoComplete='email'
                                            maxLength={100}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackemail">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col sm={true}>
                                    <Form.Label htmlFor="password">Senha<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={showFeedbackPassword}
                                            placeholder="Informe a senha"
                                            aria-label="Informe a senha"
                                            aria-describedby="Informe a senha"
                                            size='lg'
                                            type='password'
                                            id="password"
                                            name="password"
                                            autoComplete='password'
                                            maxLength={100}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        <div className="d-flex justify-content-end align-self-start">
                                        <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', zIndex: "5" }} onClick={ClickToViewPassword}>
                                            {showPassword ?
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="12" cy="12" r="2" />
                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                            </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <line x1="3" y1="3" x2="21" y2="21" />
                                                <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                                <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                            </svg>
                                            }
                                        </Button>
                                        </div>
                                        <Form.Control.Feedback type="invalid" id="feedbackPassword">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={handleClose} >Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditarAccount;
