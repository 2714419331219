import { useEffect, useState } from "react"
import { Brokers } from "../../../../../core/enums/brokers"
import { Button, Col, Form, FormControl, FormLabel, InputGroup, Modal, Row } from "react-bootstrap"
import { Formik } from "formik"
import styles from '../styles'
import SelectComponent from "../../selectComponent"
import UserService from "../../../../../services/user-service"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AppRequesterControlPanelController } from "../../../../../services/appRequesterControlPanel/appRequesterControlPanelController"
import { setShowAlertFeedback } from "../../../../../store/internal"

interface BrokerInterface {
    label: string,
    value: string
}

const ModalRegisterWhastapp = ({ show, onHide, company_data, getWhatsAppById }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [validated, setValidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [hasBot, setHasbot] = useState(false)
    const [selectedBroker, setSelectedBrokers] = useState([])

    const [onSelectBroker, setOnSelectBroker] = useState([])
    
    const [companyCNPJ, setCompanyCnpj] = useState("")
    const [nameAccount, setNameAccount] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [tokenAuth, setTokenAuth] = useState("")
    const [sid, setSid] = useState("")
    const [botNlp, setBotNlp] = useState("")
    const [apiUrl, setApiUrl] = useState("")
    const [urlBot, setUrlBot] = useState("")
    const [postBackUrl, setPostBackUrl] = useState("")

    const [showFeedbackNameAccount, setShowFeedbackNameAccount] = useState(false)
    const [showFeedbackPhoneNumber, setShowFeedbackPhoneNumber] = useState(false)
    const [showFeedbackTokenAuth, setShowFeedbackTokenAuth] = useState(false)
    const [showFeedbackSid, setShowFeedbackSid] = useState(false)
    const [showFeedbackBotNlp, setShowFeedbackBotNlp] = useState(false)
    const [showFeedbackUrlBot, setShowFeedbackUrlBot] = useState(false)
    const [showFeedbackApiUrl, setShowFeedbackApiUrl] = useState(false)
    const [showFeedbackPostBackUrl, setShowFeedbackPostBackUrl] = useState(false)

    const AppRequesterConst = new AppRequesterControlPanelController()

    const [optionsBroker, setOptionsBroker] = useState([
        { label: "Twilio", value: Brokers.Twilio},
        { label: "Positus", value: Brokers.Positus },
        { label: "Meta", value: Brokers.Meta},
        { label: "Smarters", value: Brokers.Smarters }
    ])

    useEffect(() => {
        if (company_data) {
            setCompanyCnpj(company_data.company_cnpj)
        }
    }, [company_data])

    const clearInput = () => {
        setShowFeedbackBotNlp(false)
        setShowFeedbackUrlBot(false)
        setShowFeedbackTokenAuth(false)
        setShowFeedbackNameAccount(false)
        setShowFeedbackPhoneNumber(false)
        setShowFeedbackSid(false)
        setShowFeedbackUrlBot(false)
        setShowFeedbackPostBackUrl(false)

        setCompanyCnpj("")
        setNameAccount("")
        setPhoneNumber("")
        setTokenAuth("")
        setSid("")
        setBotNlp("")
        setApiUrl("")
        setUrlBot("")
        setPostBackUrl("")
        setHasbot(false)
        setOnSelectBroker([])
        setSelectedBrokers([])

        onHide()
    }

    const selectBrokerId = (value: string | any[]) => {
        if (value.length > 0) {
            if (value[value.length - 1].value === 'NA') {
                setOnSelectBroker([])
            } else {
                setOnSelectBroker([value[value.length - 1]])
            }
        } else {
            setOnSelectBroker([])
        }
    }

    return (
        <Modal show={show} onHide={clearInput} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Adicionar novo número</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    // enableReinitialize={true}
                    initialValues={{ 
                        nameAccount: "", 
                        phoneNumber: "", 
                        tokenAuth: "", 
                        sid: "", 
                        botNlp: "",
                        apiUrl: "",
                        urlBot: "",
                        postBackUrl: "" 
                    }}
                    validate={(values) => {
                        let errors = {};

                        setNameAccount(values.nameAccount)
                        setPhoneNumber(values.phoneNumber)
                        setTokenAuth(values.tokenAuth)
                        setSid(values.sid)
                        setBotNlp(values.botNlp)
                        setApiUrl(values.apiUrl)
                        setUrlBot(values.urlBot)
                        setPostBackUrl(values.postBackUrl)

                        if (!values.nameAccount) {
                            errors['nameAccount'] = 'Preencha o nome da conta corretamente'
                            setShowFeedbackNameAccount(true)
                        } else {
                            setShowFeedbackNameAccount(false)
                        }

                        if (!values.phoneNumber) {
                            errors['phoneNumber'] = 'Preencha o número corretamente'
                            setShowFeedbackPhoneNumber(true)
                        } else {
                            setShowFeedbackPhoneNumber(false)
                        }

                        if (!values.tokenAuth) {
                            errors['tokenAuth'] = 'Preencha o token de autorização corretamente'
                            setShowFeedbackTokenAuth(true)
                        } else {
                            setShowFeedbackTokenAuth(false)
                        }

                        if (!values.sid) {
                            errors['sid'] = 'Preencha o sid corretamente'
                            setShowFeedbackSid(true)
                        } else {
                            setShowFeedbackSid(false)
                        }


                        

                        // if (!values.apiUrl) {
                        //     errors['apiUrl'] = 'Preencha o URL da API corretamente'
                        //     setShowFeedbackApiUrl(true)
                        // } else {
                        //     setShowFeedbackApiUrl(false)
                        // }

                        if (hasBot) {

                            if (values.botNlp && !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i.test(values.botNlp)) {
                                errors['botNlp'] = 'Prencha com um ID válido'
                                setShowFeedbackBotNlp(true)
                            }  else {
                                setShowFeedbackBotNlp(false)
                            }

                            if (!values.urlBot) {
                                errors['urlBot'] = 'Preencha o URL do Bot corretamente'
                                setShowFeedbackUrlBot(true)
                            } else {
                                setShowFeedbackUrlBot(false)
                            }

                            if (!values.postBackUrl) {
                                errors['postBackUrl'] = 'Preencha o PostBackUrl corretamente'
                                setShowFeedbackPostBackUrl(true)
                            } else {
                                setShowFeedbackPostBackUrl(false)
                            }
                        } else {

                        }

                        return errors

                    }}
                    onSubmit={async () => {
                        let JsonSend = {
                            cnpj: company_data.company_cnpj,
                            hasBot: hasBot,
                        }

                        if (nameAccount !== '') {
                            JsonSend['subAccountName'] = nameAccount
                        }

                        if (phoneNumber !== '') {
                            JsonSend['phoneNumber'] = phoneNumber.toString()
                        }

                        if (tokenAuth !== '') {
                            JsonSend['authToken'] = tokenAuth
                        }

                        if (sid !== '') {
                            JsonSend['sid'] = sid
                        }


                        if (apiUrl !== '') {
                            JsonSend['apiUrl'] = apiUrl
                        }

                        JsonSend['brokerId'] = onSelectBroker[0]?.value

                        if (hasBot) {
                            if (botNlp !== '') {
                                JsonSend['nlp_id'] = botNlp
                            }

                            if (urlBot !== '') {
                                JsonSend['urlBot'] = urlBot
                            }
    
                            if (postBackUrl !== '') {
                                JsonSend['postBackUrl'] = postBackUrl
                            }

                        } else {
                            JsonSend['nlp_id'] = undefined
                            JsonSend['urlBot'] = undefined
                            JsonSend['postBackUrl'] = undefined
                        }

                        const headers = UserService.getHeaders()
                        const router = '/channel-whatsapp/save-whatsapp-keys'
                        const config = { headers }

                        await AppRequesterConst.Post(
                            router, JsonSend, config,
                            (response: Object) => { },
                            (data: { status: number }) => {
                                if (data.status === 200) {
                                    dispatch(setShowAlertFeedback({ message: "Whatsapp key cadastrado com sucesso.", visibility: true, signalIcon: true }))
                                    clearInput()
                                    getWhatsAppById()
                                }
                            },
                            (error: { response: { status: number; data: { status: number; code_cxpress: number, message: { context: { label: string } } } } }) => {
                                // console.log(error.response.data.message, "Aqui")
                                if (error.response.data.code_cxpress === 212) {
                                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))

                                } else if (error?.response?.status === 422) {
                                    if (error?.response?.data?.message[0]?.path[0] === 'nlp_id') {
                                        dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp é inválido.', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.message[0]?.path[0] === 'brokerId') {
                                        dispatch(setShowAlertFeedback({ message: 'O Broker não foi informado.', visibility: true, signalIcon: false }))
                                    }
                                } else if (error?.response?.data?.code_cxpress === 216) {
                                    dispatch(setShowAlertFeedback({ message: 'Bot não encontrado', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 217) {
                                    dispatch(setShowAlertFeedback({ message: 'Bot está cadastrado em uma empresa diferente', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 218) {
                                    dispatch(setShowAlertFeedback({ message: 'Canal do bot não é whatsapp', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 219) {
                                    dispatch(setShowAlertFeedback({ message: 'Prompt não foi criado', visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.data?.code_cxpress === 220) {
                                    dispatch(setShowAlertFeedback({ message: 'Bot está associado a outra whatsapp key', visibility: true, signalIcon: false }))
                                }
                                else {
                                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
                                }


                            }, navigate, dispatch, setIsLoading
                        )

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Col>
                                    <Form.Label htmlFor="name">
                                        Nome da conta <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="nameAccount"
                                            isInvalid={showFeedbackNameAccount}
                                            className="form-control-Default"
                                            placeholder="Informe o nome da conta"
                                            aria-label="Informe o nome da conta"
                                            aria-describedby="Informe o nome da conta"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackSubAccountName">
                                            {errors.nameAccount}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label htmlFor="number">
                                        Número <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="phoneNumber"
                                            isInvalid={showFeedbackPhoneNumber}
                                            className="form-control-Default"
                                            placeholder="Informe o número"
                                            aria-label="Informe o número"
                                            aria-describedby="Informe o número"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackPhoneNumber">
                                            {errors.phoneNumber}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Form.Label htmlFor="token">
                                    Token de autorização <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        id="tokenAuth"
                                        isInvalid={showFeedbackTokenAuth}
                                        className="form-control-Default"
                                        placeholder="Informe o token de autorização"
                                        aria-label="Informe o token de autorização"
                                        aria-describedby="Informe o token de autorização"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackTokenAuth">
                                        {errors.tokenAuth}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel htmlFor="sid">
                                    Sid <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="sid"
                                        isInvalid={showFeedbackSid}
                                        className="form-control-Default"
                                        placeholder="Informe o sid"
                                        aria-label="Informe o sid"
                                        aria-describedby="Informe o sid"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackSid">
                                        {errors.sid}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    URL da API
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="apiUrl"
                                        isInvalid={showFeedbackApiUrl}
                                        className="form-control-Default"
                                        placeholder="Informe o URL da API"
                                        aria-describedby="Informe o URL da API"
                                        aria-label="Informe o URL da API"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackApiUrl">
                                        {errors.apiUrl}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left">
                                <FormLabel>
                                    Broker <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                </FormLabel>
                                <SelectComponent
                                    className={"multiselectForm mb-3"}
                                    options={optionsBroker}
                                    disableSearch={true}
                                    setOptions={setOptionsBroker}
                                    selecteds={selectedBroker}
                                    setSelecteds={setSelectedBrokers}
                                    singleSelect={true}
                                    title={"Broke"}
                                    overrideStrings={{
                                        "allItemsAreSelected": "",
                                        "clearSearch": "Limpar pesquisa",
                                        "clearSelected": "Limpar broker selecionado",
                                        "noOptions": "Sem broker",
                                        "search": "Pesquisar broker",
                                        "selectAll": "Selecionar todos os brokers",
                                        "selectAllFiltered": "Selecionar todos os brokers (Filtrado)",
                                        "selectSomeItems": "Selecione o broker",
                                        "create": "Criar"
                                    }}
                                    hasCreateItem={false}
                                    hasNullOption={false}
                                    onSelect={(value) => selectBrokerId(value)}
                                    filtering={false}
                                    hasAllOption={false}
                                />
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Col>
                                    <Form.Label htmlFor="basic-url">Possui bot? &nbsp; &nbsp;</Form.Label>
                                    <Form.Check name="hasBot" label={'Não'} type={'radio'} checked={!hasBot} onChange={() => {setHasbot(false)}} inline/>
                                    <Form.Check name="hasBot" label={'Sim'} type={'radio'} checked={hasBot} onChange={() => {setHasbot(true)}} inline/>
                                </Col>
                            </Row>
                            {hasBot ?
                                    <>
                                        <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                            <FormLabel>
                                                ID do Bot NLP
                                            </FormLabel>
                                            <InputGroup>
                                                <FormControl
                                                    id="botNlp"
                                                    isInvalid={showFeedbackBotNlp}
                                                    className="form-control-Default"
                                                    placeholder="Informe o ID do Bot NLP"
                                                    aria-label="Informe o ID do Bot NLP"
                                                    aria-describedby="Informe o ID do Bot NLP"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                </FormControl>
                                                <Form.Control.Feedback type="invalid" id="feedbackBotNlp">
                                                    {errors.botNlp}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Row>
                                        <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                            <FormLabel>
                                                URL Bot <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                            </FormLabel>
                                            <InputGroup>
                                                <FormControl
                                                    id="urlBot"
                                                    isInvalid={showFeedbackUrlBot}
                                                    className="form-control-Default"
                                                    placeholder="URL Bot"
                                                    aria-label="URL Bot"
                                                    aria-describedby="URL Bot"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                </FormControl>
                                                <Form.Control.Feedback type="invalid" id="feedbackUrlBot">
                                                    {errors.urlBot}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Row>
                                        <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                            <Form.Label>
                                                PostBackUrl <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                            </Form.Label>
                                            <InputGroup>
                                                <FormControl
                                                    id="postBackUrl"
                                                    isInvalid={showFeedbackPostBackUrl}
                                                    className="form-control-Default"
                                                    placeholder="PostBackUrl"
                                                    aria-label="PostBackUrl"
                                                    aria-describedby="PostBackUrl"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                </FormControl>
                                                <Form.Control.Feedback type="invalid" id="feedbackPostBackUrl">
                                                    {errors.postBackUrl}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Row>
                                    </> 
                                : 
                                    <></>
                            }
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => clearInput()}>Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size="lg" className="buttonBlue" style={{ width: '56%' }} type="submit">Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default ModalRegisterWhastapp