import { FC } from 'react';
import './styles.scss';
import { AgentsExtensionInterface } from './indexModel';
import Loading from '../../../../../../../../components/loading';
import { IconPhone, IconUsers } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AgentsExtension: FC<AgentsExtensionInterface> = ({
  data,
  isLoading,
  getFilteredData,
  filteredLength,
  searchTerm,
  setSearchTerm,
  selectExtension,
  userExtension,
  t,
}) => {

  return (
    <div className="scene-extensions">
      <span className='scene-title'>{t('phone_callcenter.main_screen.agents_extension.agents_terminals')}</span>
      {isLoading && <Loading visibility={isLoading} noBootstrap={true} customStyle={{ position: 'relative' }} />}
      { !isLoading && data.length > 0 &&
        <div className="search-agent">
          <input 
            type="text" 
            className="form-control" 
            placeholder={t('phone_callcenter.main_screen.agents_extension.search')}
            value={searchTerm} 
            onChange={e => setSearchTerm(e.target.value)} 
          />
        </div>
      }
      {
        !isLoading && data.length === 0 && 
        <div className='scene-list-empty'>
          <IconUsers size={42} color="#4a69bd" />
          <span className='empty-text'>{t('phone_callcenter.main_screen.agents_extension.your_terminals')}</span>
        </div>
      }
      {
        !isLoading && filteredLength === 0 && 
        <div className='scene-list-empty'>
          <IconUsers size={42} color="#4a69bd" />
          <span className='empty-text'>{t('phone_callcenter.main_screen.agents_extension.no_terminal_found')}</span>
        </div>
      }
      {
        !isLoading && data.length > 0 &&
        <div className="scene-list">
          {getFilteredData().map(item => {
            let itemClass = filteredLength < 5 ? 'scene-list-item' : 'scene-list-item item-scroll';

            if (userExtension === item.extension) {
              itemClass += ' self-extension';
            }
            
            return (
              <div className={itemClass} key={item.id}>
                <div className="list-item-details">
                  <span className="details-extension">{ item.extension }</span>
                  <span className="details-name">{ item.employee_name }</span>
                </div>
                {
                  userExtension !== item.extension &&
                  <span className="list-item-action">
                    <OverlayTrigger placement="left" overlay={<Tooltip>{t('phone_callcenter.main_screen.agents_extension.call_to_this_terminal')}</Tooltip>}>
                      <IconPhone onClick={() => selectExtension(item.extension)} />
                    </OverlayTrigger>
                  </span>
                }
              </div>
            );
          })}
        </div>
      }
    </div>
  );
}

export default AgentsExtension;