import { IconRotateClockwise } from "@tabler/icons-react";
import { Formik } from "formik";
import { t } from "i18next";
import { FC } from "react";
import { Button, Col, FormControl, OverlayTrigger, Row, Tooltip, Form, InputGroup } from "react-bootstrap";
import InputMask from 'react-input-mask';

const HumanSurveyTab = ({
    ableDisable,
    setAbleDisable,
    ableDisableNpsAbandoned,
    setAbleDisableNpsAbandoned,
    hour,
    initialAgentMessage,
    setInitialAgentMessage,
    showFeedbackInitialAgentMessage,
    setShowFeedbackInitialAgenteMessage,
    finalAgentMessage,
    setFinalAgentMessage,
    showFeedbackFinalAgentMessage,
    setShowFeedbackFinalAgenteMessage,
    resetAgentMessages,
    converterMinuto,
    converterHora,
    onSubmit,
    convertNumToHour,
    lifeTime,
    resetInitialAgentMessage,
    resetFinalAgentMessage,
    showFeedbackTime,
    setShowFeedbackTime,
    validate,
    timeStringHuman
}) => {
    return (
        <>
            <Row>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        hour: hour,
                        initial_agent_message: initialAgentMessage,
                        final_agent_message: finalAgentMessage,
                        /* npsWaitTime !== null ? convertMinToHour(npsWaitTime) : '00:00' */
                    }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setValues,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div style={{ overflow: 'auto', overflowX: 'hidden', height: '70vh', flexDirection: 'column', display: 'flex' }}>
                                <Row style={{ /* height: '36%' */ }} className=" d-flex justify-content-start my-3">
                                    <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                        <Row>
                                            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.survey_tab.human_survey_title')}</p>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="html" >{ableDisable ? t('configurations_ticket.survey_tab.disable') : t('configurations_ticket.survey_tab.enable')} {t('configurations_ticket.survey_tab.human_survey_description')}</Form.Label>
                                            <Col md={1} style={{ width: '5%', marginTop: '0.4vh' }}>
                                                <Form.Check
                                                    type='checkbox'
                                                    id={"checkbox"}
                                                    checked={ableDisable}
                                                    onChange={() => setAbleDisable(!ableDisable)}
                                                    className="form-check-input-2"
                                                />
                                            </Col>
                                            <Col >
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '0.4vh', marginLeft: '5px' }}>
                                                    {ableDisable ?
                                                        t('configurations_ticket.survey_tab.enabled_survey')
                                                        :
                                                        t('configurations_ticket.survey_tab.disabled_survey')
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="html" >{ableDisableNpsAbandoned ? t('configurations_ticket.survey_tab.disable') : t('configurations_ticket.survey_tab.enable')} {t('configurations_ticket.survey_tab.human_survey_abandoned_description')}</Form.Label>
                                            <Col md={1} style={{ width: '5%', marginTop: '0.4vh' }}>
                                                <Form.Check
                                                    type='checkbox'
                                                    id={"checkbox"}
                                                    checked={ableDisableNpsAbandoned}
                                                    onChange={() => setAbleDisableNpsAbandoned(!ableDisableNpsAbandoned)}
                                                    className="form-check-input-2"
                                                />
                                            </Col>
                                            <Col >
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '0.4vh', marginLeft: '5px' }}>
                                                    {ableDisableNpsAbandoned ?
                                                        t('configurations_ticket.survey_tab.enabled_survey_abandoned')
                                                        :
                                                        t('configurations_ticket.survey_tab.disabled_survey_abandoned')
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="html" >{t('configurations_ticket.survey_tab.time_wait_survey_human')}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {t('configurations_ticket.survey_tab.time_tooltip_human_wait')} {/* {convertNumToHour(lifeTime)} */}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <InputMask
                                                mask="99:99"
                                                maskChar="_"
                                                value={values.hour}
                                                onChange={handleChange}
                                            >
                                                {(inputProps: any) => (
                                                    <InputGroup className="mb-3" style={{ width: '8vw' }}>
                                                        <FormControl className="form-control-Default  text-center"
                                                            placeholder="HH:MM"
                                                            aria-label="HH:MM"
                                                            aria-describedby="HH:MM"
                                                            type='text'
                                                            id="hour"
                                                            name="hour"
                                                            isInvalid={ableDisable ? showFeedbackTime : false}
                                                            disabled={!ableDisable}
                                                        >
                                                        </FormControl>
                                                        <Form.Control.Feedback
                                                            type="invalid"
                                                            id="feedbackHour"
                                                        >
                                                            {errors.hour}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                )}
                                            </InputMask>
                                            <Col className='mt-3' style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#0C2461' }}>
                                                {timeStringHuman}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Label htmlFor="initial_agent_msg">
                                                {t('configurations_ticket.survey_tab.initial_message_human')}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {t('configurations_ticket.survey_tab.initial_message_tooltip')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key="buttonInitialAgentMessage"
                                                    overlay={
                                                        <Tooltip >
                                                            <>{t('configurations_ticket.survey_tab.reset_message')}</>
                                                        </Tooltip>
                                                    }
                                                    placement="bottom"
                                                >
                                                    <Button className="px-2" style={{ color: '#9e9e9e', background: 'none', border: 'none' }} onClick={(e) => resetInitialAgentMessage(initialAgentMessage, setInitialAgentMessage)} >
                                                        <IconRotateClockwise />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <FormControl
                                                className="form-control-Default-text-area ms-3"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                placeholder={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-label={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-describedby={t('configurations_ticket.survey_tab.message_placeholder')}
                                                size='sm'
                                                type='textarea'
                                                id="initial_agent_message"
                                                name="initial_agent_message"
                                                // maxLength={1000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.initial_agent_message}
                                                isInvalid={showFeedbackInitialAgentMessage && !resetAgentMessages}
                                                // disabled={resetAgentMessages}
                                                disabled={!ableDisable}
                                            />
                                            <Form.Control.Feedback type="invalid" className="ms-1" id="feedebackInitialAgentMessage">
                                                {errors['initial_agent_message']}
                                            </Form.Control.Feedback>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="final_agent_message">
                                                {t('configurations_ticket.survey_tab.final_message_human')}
                                                <OverlayTrigger
                                                    key="buttonFinalBotMessage"
                                                    overlay={
                                                        <Tooltip >
                                                            <>{t('configurations_ticket.survey_tab.reset_message')}</>
                                                        </Tooltip>
                                                    }
                                                    placement="bottom"
                                                >
                                                    <Button className="px-2" style={{ color: '#9e9e9e', background: 'none', border: 'none' }} onClick={(e) => resetFinalAgentMessage(finalAgentMessage, setFinalAgentMessage)} >
                                                        <IconRotateClockwise />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <FormControl
                                                className="form-control-Default-text-area ms-3"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                placeholder={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-label={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-describedby={t('configurations_ticket.survey_tab.message_placeholder')}
                                                size='sm'
                                                type='text'
                                                // componentClass="textarea"
                                                id="final_agent_message"
                                                name="final_agent_message"
                                                // maxLength={1000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.final_agent_message}
                                                isInvalid={showFeedbackFinalAgentMessage && !resetAgentMessages}
                                                // disabled={resetAgentMessages}
                                                disabled={!ableDisable}
                                            />
                                            <Form.Control.Feedback type="invalid" className="ms-1" id="feedebackFinalAgentMessage">
                                                {errors['final_agent_message']}
                                            </Form.Control.Feedback>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <Row /* style={allStyles.textFontGlay} */ className="d-flex mt-4">
                                <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                    <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => { document.location.reload(); }} >{t('configurations_ticket.survey_tab.cancel')}</Button>
                                </Col>
                                <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                    <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{t('configurations_ticket.survey_tab.save')}</Button>
                                </Col>
                            </Row>

                        </Form>
                    )}
                </Formik>
            </Row>
        </>
    )
}

export default HumanSurveyTab;