import { useDispatch, useSelector } from "react-redux";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataCompanyInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getShowConfirmationModal, getShowMeetButton } from "../../../../store/internal";
import { getOwner_id } from "../../../../store/callcenter";
import UserService from "../../../../services/user-service";
import HomeChannels from "./index";
import { useTranslation } from "react-i18next";

const AppRequesterConst = new AppRequesterController();

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const HomeConfigurationsTicketsController = (props: any) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [showFeedbackTime, setShowFeedbackTime] = useState(false);
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [qntHours, setQntHours] = useState(null as number || null);
    // const [reload, setReload] = useState(false);
    const [consumerDowntime, setConsumerDowntime] = useState(null as number || null);
    const [lifeTime, setLifeTime] = useState(null as number || null);
    const [lifeTimeLevelOne, setLifeTimeLevelOne] = useState(null as number || null);
    const [enableNps, setEnableNps] = useState(false);
    const [enableNpsAbandoned, setEnableNpsAbandoned] = useState(true);
    const [enableNpsLevelOne, setEnableNpsLevelOne] = useState(false);
    const [npsWaitTime, setNpsWaitTime] = useState(null as number || null);
    const [npsWaitTimeLevelOne, setNpsWaitTimeLevelOne] = useState(null as number || null);
    const [npsBotInitialMessage, setNpsBotInitialMessage] = useState("" || null);
    const [npsBotFinalMessage, setNpsBotFinalMessage] = useState("" || null);
    const [npsAgentInitialMessage, setNpsAgentInitialMessage] = useState("" || null);
    const [npsAgentFinalMessage, setNpsAgentFinalMessage] = useState("" || null);
    // const [ticketAlertInternal, setTicketAlertInternal] = useState([]);
    // const [ticketAlertView, setTicketAlertView] = useState([]);
    const [ticketLimitPerAgent, setTicketLimitPerAgent] = useState(null)
    const [ticketAutomaticDistribution, setTicketAutomaticDistribution] = useState(null)
    const [noSectorDistributeTicket, setNoSectorDistributeTicket] = useState(null)
    const [messageFinish, setMessageFinish] = useState(null)
    const [hourWaitSurvey, setHourWaitSurvey] = useState("" || null)
    const [inactivityMessage, setInactivityMessage] = useState("" || null)
    const [enableInactivityMessage, setEnableInactivityMessage] = useState(true)
    const [sendEmailNewTicket, setSendEmailNewTicket] = useState(false)

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        }
    };

    useEffect(() => {
        const controller = new AbortController();

        getLifeTime(controller);
        //getTicketAlert();

        return () => {
            controller.abort();
        }
    }, []);

    const convertMinToHour = (num: number) => {
        return num / 60;
    }

    const getLifeTime = async (controller: AbortController) => {

        const headers = UserService.getHeaders(), signal = controller.signal;

        await AppRequesterConst.Get(
            '/company', { headers },
            (response: Object) => { },
            (data: DataCompanyInterface) => {
                setIsLoading(true)
                if (data.status === 200) {
                    setConsumerDowntime(data.data.companies[0].consumer_downtime)
                    setLifeTime(data.data.companies[0].ticket_life_time);
                    setLifeTimeLevelOne(data.data.companies[0].ticket_life_time_level_one);
                    setEnableNps(data.data.companies[0].enable_nps);
                    setEnableNpsAbandoned(data.data.companies[0].configs.enable_nps_abandoned)
                    setEnableNpsLevelOne(data.data.companies[0].enable_nps_level_one);
                    setNpsWaitTime(data.data.companies[0].nps_wait_time);
                    setNpsWaitTimeLevelOne(data.data.companies[0].nps_wait_time_level_one);
                    setTicketLimitPerAgent(data.data.companies[0].ticket_limit_per_agent)
                    setTicketAutomaticDistribution(data.data.companies[0].ticket_agent_automatic_distribution)
                    setNoSectorDistributeTicket(data.data.companies[0].distribute_ticket_without_sector)
                    setMessageFinish(data?.data?.companies[0]?.finish_ticket_message)
                    setNpsAgentInitialMessage(data.data.companies[0]?.nps_agent_initial_message);
                    setNpsAgentFinalMessage(data.data.companies[0]?.nps_agent_final_message);
                    setNpsBotInitialMessage(data.data.companies[0]?.nps_bot_initial_message);
                    setNpsBotFinalMessage(data.data.companies[0]?.nps_bot_final_message);
                    setHourWaitSurvey(data.data.companies[0]?.configs?.nps_n1_time_to_await_response)
                    setInactivityMessage(data.data.companies[0]?.configs?.inactivity_message)
                    setEnableInactivityMessage(data.data.companies[0]?.configs?.send_inactivity_message)
                    setSendEmailNewTicket(data.data.companies[0]?.configs?.send_email_on_new_ticket)
                }
                setIsLoading(false)
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }


    /*const getTicketAlert = async () => {
        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // };

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/ticket-alert', { headers },
            (response: Object) => { },
            (data: DataTicketAlertInterface) => {
                if(data.status === 200){
                    setTicketAlertInternal(data.data.ticket_alerts);
                    setTicketAlertView(data.data.ticket_alerts);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading
        );
        /* await Api.get('/ticket-alert', { headers }).then((response) => {
            return response;
        }).then(data => {
            setTicketAlertInternal(data.data.ticket_alerts);
            setTicketAlertView(data.data.ticket_alerts);
        }).catch((error) => {
            console.log(error.response);
            if (error.response !== undefined) {
                console.log(error.response);
                if (error.response.status === 400) {
                    //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                    //setShowfeedback(true);
                }
                if (error.response.status === 401) {
                    //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                    //setShowfeedbackPassword(true);
                }
            }
        }); 
    }*/

    // const deleteAlert = (e: any) => {
    //     setTicketAlertInternal(state => {
    //         if (e.target != null) {
    //             state[e.target.id.split("*ID*")[1]] = { ...state[e.target.id.split("*ID*")[1]], isDelete: true };
    //             return state;
    //         }
    //         else {
    //             return state;
    //         }
    //     });

    //     setTicketAlertView(state => {
    //         if (e.target != null) {
    //             state.splice(e.target.id.split("*ID*")[1], 1);
    //             return state;
    //         }
    //         else {
    //             return state;
    //         }
    //     });
    //     if (reload === false) {
    //         setReload(true)
    //     } else {
    //         setReload(false)
    //     }
    // }

    return <HomeChannels
        t={t}
        isLoading={isLoading}
        lifeTime={lifeTime}
        lifeTimeLevelOne={lifeTimeLevelOne}
        setLifeTime={setLifeTime}
        consumerDowntime={consumerDowntime}
        // setHtml={setHtml}
        showFeedbackTime={showFeedbackTime}
        setShowFeedbackTime={setShowFeedbackTime}
        values={values}
        // html={html}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        enableNps={enableNps}
        enableNpsAbandoned={enableNpsAbandoned}
        enableNpsLevelOne={enableNpsLevelOne}
        npsWaitTime={npsWaitTime}
        npsWaitTimeLevelOne={npsWaitTimeLevelOne}
        ticketLimitPerAgent={ticketLimitPerAgent}
        ticketAutomaticDistribution={ticketAutomaticDistribution}
        noSectorDistributeTicket={noSectorDistributeTicket}
        showFeedbackMessage={showFeedbackMessage}
        setShowFeedbackMessage={setShowFeedbackMessage}
        messageFinish={messageFinish}
        setMessageFinish={setMessageFinish}
        npsBotInitialMessage={npsBotInitialMessage} 
        npsBotFinalMessage={npsBotFinalMessage} 
        npsAgentInitialMessage={npsAgentInitialMessage} 
        npsAgentFinalMessage={npsAgentFinalMessage}
        hourWaitSurvey={hourWaitSurvey}
        inactivityMessage={inactivityMessage}
        enableInactivityMessage={enableInactivityMessage}
        convertMinToHour={convertMinToHour}
        sendEmailNewTicket={sendEmailNewTicket}
    />
}

export default HomeConfigurationsTicketsController