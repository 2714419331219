import { IconCopy } from "@tabler/icons-react";
import { FC } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IClipboardButton } from "./indexModel";

const ClipboardButton: FC<IClipboardButton> = (props) => {
    return (
        <>
            <span style={{ display: 'inline-block', width: '30%' }} className='me-3'>
                <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                        <Tooltip id={'tooltip-clickboard-'} >
                            {props.clicked? 
                                props.message:
                                <>Clique para copiar</>
                            }
                        </Tooltip>
                    }
                >
                    <Button onClick={props.clickClipboard} style={{ background: 'none', border: 'none' }}>
                        <IconCopy size={24} color='#707070' />
                    </Button>
                </OverlayTrigger>
            </span>
        </>
    );
}

export default ClipboardButton;