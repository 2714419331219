import './styles.scss';
import { useContext } from 'react';
import { CallcenterContext } from '../../../../../../core/context/callcenter-context';
import { IconPhoneCheck, IconPhoneX } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ReceivingCall = () => {
  const context = useContext(CallcenterContext);
  const {t} = useTranslation()

  const accept = () => {
    context.instance.acceptCall();
    context.setShowPhoneCallcenter(true);
  }

  const hangup = () => {
    context.instance.hangup();
  }

  const formatDuration = (time = 0) => {
    const sec_num = parseInt(String(time), 10)
    const hours   = Math.floor(sec_num / 3600)
    const minutes = Math.floor(sec_num / 60) % 60
    const seconds = sec_num % 60

    return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":");
  }

  const getClassNumber = () => {
    return context.callStatusEvent === 'in-call' ? 'number in-call-progress' : 'number';
  }

  return (
    <div className="phoneReceivingCall">
      <div className="info">
        { context.callStatusDirection === 'outgoing' && <span className={getClassNumber()}>{context.callInfoDestiny || t('phone_callcenter.receiving_call.unknown_number')}</span>}
        { context.callStatusDirection === 'incoming' && <span className={getClassNumber()}>{context.callStatusIncomingNumber || t('phone_callcenter.receiving_call.unknown_number')}</span>}
        { context.callStatusEvent === 'in-call' && <span className="duration">{formatDuration(context.callStatusDuration)}</span>}
      </div>
      <div className="action">
        { 
          context.callStatusEvent === 'ringing' &&
          <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.receiving_call.answer')}</Tooltip>}>
            <span className="btnAction answer" onClick={() => accept()}><IconPhoneCheck color='#fff' /></span>
          </OverlayTrigger>
        }
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.receiving_call.hang_up')}</Tooltip>}>
          <span className="btnAction hangup" onClick={() => hangup()}><IconPhoneX color='#fff' /></span>
        </OverlayTrigger>
      </div>
    </div>
  );
}

export default ReceivingCall;
