type CSSProperties = {
    [key: string]: React.CSSProperties;
};

export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
        return styles;
    };
};

const styles = StyleSheet.create({
    box: {
        background: "#E5ECFF 0% 0% no-repeat padding-box",
        borderRadius: "10px",
        opacity: "1",
    },
    title: {
        textAlign: 'center',
        font: 'var(--px15_20_Bold-font)',
        letterSpacing: '0px',
        color: '#1E1E1E',
        opacity: '1',
    }, 
    text: {
        textAlign: 'center',
        font: 'var(--px16_19-font)',
        letterSpacing: '0px',
        color: '#1E1E1E',
        opacity: '1',
    },
    button: {
        width: '22vw'
    }
});

export default styles;