import { useState } from 'react'
import FormConfigFacebook from '.'

const FormConfigFacebookController = (props) => {

    const [showModal, setShowModal] = useState(false)

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    return <FormConfigFacebook
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        data={props.data}
        updateListAfterDelete={props.updateListAfterDelete}
        cnpj={props.cnpj}
        getFacebookById={props.getFacebookById}
        isEditAllowed={props.isEditAllowed}
    />
}

export default FormConfigFacebookController