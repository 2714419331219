import React, { useRef, useState } from 'react';
import { Button, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../../components/loading';
import './styles.css';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import HomeMessageTriggerReportPDFController from './exportPdf/indexController';

const HomeMessageTriggerReport = ({
    isLoading,
    triggerInfos,
    formatedScheduledDate,
    triggerResult,
    printReport,
    reportDatas,
    t,
    show,
    handleClose,
    componentRef,
    printPDF,
    printIsInvalid,
    printIsValid,
}) => {

    return (
        <>  
            <Modal show={show} onHide={handleClose} size="lg" centered ref={componentRef}>
                    <Modal.Header closeButton={!printPDF}>
                        <Row style={{ width: '100%' }}>
                            <Col md={8} style={{ marginTop: '1vh' }}>
                                <Modal.Title style={{ marginLeft: '1.0rem' }}>{t('home_message_trigger.home_message_trigger_report.active_trigger_report')}</Modal.Title>
                            </Col>
                            {printPDF ?
                                <div className="logoMessageTrigger2">
                                    <img src="/Logo.svg" alt="" />
                                </div>
                                :
                                <Col md className="justify-content-end" style={{ marginTop: '-0.8vh' }}>
                                    <img src="/Logo.svg" alt="" />
                                </Col>
                            }

                        </Row>
                    </Modal.Header>
                <Modal.Body style={{ marginTop: '-4vh' }}>
                    <Container fluid className="nopadding">
                            <Loading visibility={isLoading} />
                            <div className='wrapper'>
                                <div className='content py-0 px-3'>
                                    {/* <Row className="nopadding mt-3 ps-4">
                            <Col md={7} style={{ marginRight: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start ps-0">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>
                                            {t('home_message_trigger.home_message_trigger_report.active_trigger_report')}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                                <Row className='ps-0 mt-4 nopadding'>
                                        <Col style={{ width: '10vw', background: 'transparent', marginLeft: '0.4vw' }}>
                                            <Row name="reportHead" className='mt-1' style={{ font: 'var(--px15_19-font-Roboto)' }}>
                                                <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '27%', font: 'var(--px15_19-font-Roboto)' }}>
                                                    {t('home_message_trigger.home_message_trigger_report.type')}
                                                </Col>
                                                <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '12%' }}>
                                                    {t('home_message_trigger.home_message_trigger_report.date_trigger')}
                                                </Col>
                                            </Row>
                                            {triggerInfos ?
                                                <Row name="reportBody" className='mt-1' style={{ font: 'var(--px15_19_Bold-font-Roboto)' }}>
                                                    <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '27%' }}>
                                                        {triggerInfos.type}
                                                    </Col>
                                                    <Col md={4} className="nopadding d-flex justify-content-start" style={{ width: '40%' }}>
                                                        {formatedScheduledDate()}
                                                    </Col>
                                                </Row> : null
                                            }
                                        </Col>
                                    </Row>
                                    <Row className='ps-0 mt-4 nopadding'>
                                        <Col style={{ width: '10vw', background: 'transparent', marginLeft: '0.4vw' }}>
                                            <Row name="reportHead" className='mt-1' style={{ font: 'var(--px15_19-font-Roboto)' }}>
                                                <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '12%', font: 'var(--px15_19-font-Roboto)', marginRight: '1rem' }}>
                                                    {t('home_message_trigger.home_message_trigger_report.total_send')}
                                                </Col>
                                                <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '12%' }}>
                                                    {t('home_message_trigger.home_message_trigger_report.success')}
                                                </Col>
                                                <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '10%' }}>
                                                    {t('home_message_trigger.home_message_trigger_report.failure')}
                                                </Col>
                                                <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '14%' }}>
                                                    {t('home_message_trigger.list_of_messages_triggers.conversion')}
                                                </Col>
                                                <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '13%' }}>
                                                    {/* Canal */}
                                                    {t('home_message_trigger.list_of_messages_triggers.channel')}
                                                </Col>
                                            </Row>
                                            <Row name="reportBody" className='mt-1 mb-2 pb-3' style={{ font: 'var(--px15_19_Bold-font-Roboto)', borderBottom: '5px solid #000' }}>
                                                {triggerResult ?
                                                    <>
                                                        <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '12%' }}>
                                                            {triggerResult.total_sent}
                                                        </Col>
                                                        <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '12%' }}>
                                                            {triggerResult.sent_success}
                                                        </Col>
                                                        <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '10%' }}>
                                                            {triggerResult.sent_failure}
                                                        </Col>
                                                        <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '14%' }}>
                                                            {triggerResult.total_answers}
                                                        </Col>
                                                        <Col md={1} className="nopadding d-flex justify-content-start" style={{ width: '13%' }}>
                                                            {triggerInfos ?
                                                                triggerInfos.channel : "N/A"
                                                            }
                                                        </Col>
                                                    </> : null
                                                }
                                                {printPDF ?
                                                    <></>
                                                    :
                                                    <Col md={6} className="nopadding d-flex justify-content-end" style={{ width: '38%' }}>
                                                        <ReactToPrint
                                                            content={() => componentRef.current}
                                                            onBeforeGetContent={() => printIsValid()}
                                                            onAfterPrint={() => printIsInvalid()}
                                                        >
                                                            <PrintContextConsumer>
                                                                {({ handlePrint }) => (
                                                                    <Button className="nopadding" onClick={handlePrint} style={{ background: 'none', border: 'none', color: '#000', font: 'var(--px15_19-font-Roboto)', textDecoration: 'underline' }}>
                                                                        {t('home_message_trigger.home_message_trigger_report.export_pdf')}</Button>
                                                                )}
                                                            </PrintContextConsumer>
                                                        </ReactToPrint>
                                                    </Col>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="ps-0" style={{ margin: '2.5vh 0.5vw 0px', padding: '0' }}>
                                        {
                                            reportDatas !== undefined ?
                                                <ListGroup variant="flux" className="TicketsRow">
                                                    <ListGroup.Item className="TicketsRowCollumsReport mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                                                        <Row name="listOfReportsHead">
                                                            <Col md={2} className="nopadding me-2" style={{ width: '18%'}}>
                                                                {/* Nome */}
                                                                {t("home_message_trigger.home_message_trigger_report.name")}
                                                            </Col>
                                                            <Col md={2} className="nopadding me-2" style={{ width: '18%' }}>
                                                                {/* Contatos */}
                                                                {t("home_message_trigger.home_message_trigger_report.contact")}
                                                            </Col>
                                                            <Col md={2} className="nopadding ms-3" style={{ width: '25%' }}>
                                                                {/* Mensagem */}
                                                                {t("home_message_trigger.home_message_trigger_report.message")}
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                    <div id="listOfReports" >
                                                        <InfiniteScroll
                                                            dataLength={reportDatas.length}
                                                            next={() => { throw new Error("Function not implemented") }}
                                                            hasMore={true}
                                                            loader={<h4> </h4>}
                                                            scrollableTarget={"listOfReports"}
                                                        >
                                                            {
                                                                <ListGroup variant="flush" className="TicketsRow">
                                                                    {

                                                                        reportDatas.map((item, index) => {
                                                                        /** local list vars */

                                                                            return (
                                                                                <ListGroup.Item style={{ color: '#707070' }} id={item.id} key={item.id + index.toString()}>
                                                                                    <Row className='TicketsRowHeightReport my-2'>
                                                                                        <Col md={2} className={'align-self-center nopadding oneLineClampEllipsis me-2'} style={{ width: '18%' }}>
                                                                                            <span>
                                                                                                {item?.consumer_name  || 'N/A' }
                                                                                            </span>
                                                                                        </Col>
                                                                                        <Col md={2} className={'align-self-center nopadding oneLineClampEllipsis me-2'} style={{ width: '18%' }}>
                                                                                            <span>
                                                                                                {item.to ?
                                                                                                    item.to :
                                                                                                    <>N/A</>
                                                                                                }
                                                                                            </span>
                                                                                        </Col>
                                                                                        <Col md={3} className={'align-self-center nopadding ms-3'} style={{ width: '50%' }}>
                                                                                            <span>
                                                                                                {item.message_erro ?
                                                                                                    item.message_erro :
                                                                                                    <>{t("home_message_trigger.home_message_trigger_report.sent_with_success")}</>
                                                                                                }
                                                                                            </span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </ListGroup.Item>
                                                                            );
                                                                        })}
                                                                </ListGroup>

                                                            }
                                                        </InfiniteScroll>
                                                    </div>
                                                </ListGroup>
                                                :
                                                <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                                                    <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                                        <Col className='d-flex justify-content-center' md={4}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chart-infographic" width="4vw" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="7" cy="7" r="4" />
                                                                <path d="M7 3v4h4" />
                                                                <line x1="9" y1="17" x2="9" y2="21" />
                                                                <line x1="17" y1="14" x2="17" y2="21" />
                                                                <line x1="13" y1="13" x2="13" y2="21" />
                                                                <line x1="21" y1="12" x2="21" y2="21" />
                                                            </svg>
                                                        </Col>
                                                    </Row>
                                                    <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                                        <Col md={4} className="d-flex justify-content-center">
                                                            <p style={{ color: '#000', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('home_message_trigger.home_message_trigger_report.no_results')}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        }
                                    </Row>
                                </div>
                            </div>
                        </Container>
                </Modal.Body>
            </Modal>
            <div style={{ display: 'none' }}>

                <HomeMessageTriggerReportPDFController componentRef={componentRef} show={printPDF} reportDatas={reportDatas} triggerInfos={triggerInfos} triggerResult={triggerResult} />
            </div>

        </>
    );
}

export default HomeMessageTriggerReport;