import React, { FC } from 'react';
import { Modal, Button, Col, Row, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './styles';
import { IModalViewDomain } from './indexModel';
import ClipboardButtonController from './clipboardButton/indexController';

const ModalViewDomain: FC<IModalViewDomain> = (props) => {
    return (
        <Modal show={props.show} onHide={props.hide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title style={{ marginBottom: '-2vh' }} className='mx-3'>Detalhes</Modal.Title>
            </Modal.Header>
            <Modal.Body className='mx-3 mb-2'>
                <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Domínio:</span> <span>{ props.data?.domain }</span> 
                    </div>
                </Row>
                {/* <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Subdomínio:</span> <span>em148</span> 
                    </div>
                </Row> */}
                {props.data?.dns_registry &&  Object.keys(props.data?.dns_registry).length > 0 && 
                    <Row style={{ backgroundColor: '#FAFAFA' }}>
                        <div className="ps-3">
                            <ListGroup variant="flush">
                                <ListGroup.Item style={styles.TextRowCollums} name="yupie">
                                    <Row>
                                        <Col md={2} className="nopadding">
                                            <p>Status</p>
                                        </Col>
                                        <Col md={2} className="nopadding">
                                            <p>Tipo</p>
                                        </Col>
                                        <Col md={3} className="nopadding">
                                            <p>Host</p>
                                        </Col>
                                        <Col className="nopadding">
                                            <p>Valor</p>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                            <div style={{ overflowY: 'auto', height: '20vh' }}>
                                <ListGroup variant="flush" className="TicketsRow">
                                    {Object.keys(props.data.dns_registry).map((key, index) => {
                                        let item = props.data.dns_registry[key];

                                        return (
                                            <ListGroup.Item key={index} style={{ color: '#707070', backgroundColor: '#FAFAFA' }}>
                                                <Row className='TicketsRowHeight'>
                                                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item?.valid?
                                                                            <>Verificado</>:
                                                                            <>Pendente</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item?.valid?
                                                                        <>Verificado</>:
                                                                        <>Pendente</>
                                                                    }
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item?.type ?
                                                                            <>{item?.type.toUpperCase()}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    { item?.type.toUpperCase() }
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                        <div style={{ display: 'inline' }}>
                                                            <span style={{ display: 'inline-block', width: '60%' }}>
                                                                <div className="oneLineClampEllipsis">
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id={ 'tooltip' }>
                                                                                {item?.host != null ?
                                                                                    <>{item?.host}</>
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                            <span>
                                                                                {item?.host}
                                                                            </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </span>
                                                            <ClipboardButtonController label={"Host"} text={item?.host} />
                                                        </div>
                                                    </Col>
                                                    <Col className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                        <div style={{ display: 'inline' }}>
                                                            <span style={{ display: 'inline-block', width: '60%' }}>
                                                                <div className="oneLineClampEllipsis">
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id={ 'tooltip' }>
                                                                                {item?.data?
                                                                                    <>{ item?.data }</>
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                            <span>
                                                                                { item?.data }
                                                                            </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </span>
                                                            <ClipboardButtonController label={"Valor"} text={ item?.data } />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </div>
                        </div>
                    </Row>
                }
                <Row className="d-flex justify-content-center mt-4">
                    <Col xs={6} className="d-flex justify-content-start">
                        <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={props.hide} >Fechar</Button>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                        <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} onClick={props.confirmValidateDomain}>Verificar</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ModalViewDomain;