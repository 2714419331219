import { RegisterInterface } from './indexModel'
import Styles from './styles.module.css';
import './../registerForm/styles.css';
import { t } from 'i18next';

export class RegisterControler implements RegisterInterface {

    private setShowPassword:Function
    private showPassword:boolean
    private setStage:Function
    private stage:number
    private setProgress:Function
    private progress:number
    private Setcompany_employee_number:Function

    constructor(setShowPassword:Function, showPassword:boolean, setStage:Function, stage:number, setProgress:Function, progress:number, Setcompany_employee_number:Function) {
        this.setShowPassword = setShowPassword
        this.showPassword = showPassword
        this.setStage = setStage
        this.stage = stage
        this.setProgress = setProgress
        this.progress = progress
        this.Setcompany_employee_number = Setcompany_employee_number
    }

    ClickToViewPassword() {
        if ((document.getElementById("user_password") as HTMLInputElement).type === "text") {
            (document.getElementById("user_password") as HTMLInputElement).type = "password";
        } else {
            (document.getElementById("user_password") as HTMLInputElement).type = "text";
        }
    }

    ClickStage() {
        for (let i = 1; i <= 4; i++) {
            document.getElementById("stage" + i).style.display = "none";
        }
    }

    ValidSelectDropdownCompanyEmployeeNumber(isMobile: boolean) {
        if (document.getElementById("company_employee_number").innerHTML === t("register.select_dropdown")) {
            document.getElementById("feedbackCompany_employee_number").innerHTML = t("register.errors.select_number_employees");
            document.getElementById("feedbackCompany_employee_number").style.display = "block";
            
            if (isMobile) {
                document.getElementById("company_employee_number").className = Styles.dropdownButtonRedBorderRegisterMobile + " dropdownButtonRedBorderFontMobile" + " dropdown-toggle btn btn-primary";
            } else {
                document.getElementById("company_employee_number").className = Styles.dropdownButtonRedBorder + " " + Styles.dropdownButtonRedBorderFontNormal + " dropdown-toggle btn btn-primary";
            }
            return true;
        } else {
            document.getElementById("feedbackCompany_employee_number").style.display = "none";
            document.getElementById("company_employee_number").className = (isMobile? "dropdownButtonRegisterMobile":"dropdownButton") + " dropdown-toggle btn btn-primary " + (isMobile? "dropdownButtonRedBorderFontMobile": "");
            return false;
        }
    }

    OnSelectDropdownCompanyEmployeeNumber(value: string, isMobile: boolean) {
        document.getElementById("company_employee_number").innerHTML = value;
        this.ValidSelectDropdownCompanyEmployeeNumber(isMobile);
    }

    clickToViewPassword() {
        this.setShowPassword(!this.showPassword);
        this.ClickToViewPassword();
    }

    clickNextStage() {
        this.setStage(this.stage + 1);
        this.setProgress(this.progress + 34);
    
        this.ClickStage();
    
        this.setStage((state) => {
          document.getElementById("stage" + state).style.display = "block";
          return state;
        });
    }

    clickPreviousStage() {
        this.setStage(this.stage - 1);
        this.setProgress(this.progress - 34);
    
        this.ClickStage();
    
        this.setStage((state) => {
          document.getElementById("stage" + state).style.display = "block";
          return state;
        });
    
    }

    OnSelectDropdownCompanyEmployeeNumberNoUsed(value: string) {
        this.Setcompany_employee_number(value);
        this.OnSelectDropdownCompanyEmployeeNumber(value, false);
      }

}


