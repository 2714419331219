import { ListGroup, Row, Col, OverlayTrigger, Tooltip, Button, Container } from "react-bootstrap"
import InfiniteScroll from "react-infinite-scroll-component"
import { Channels } from "../../../../../core/enums/channels"
import QuickMessageUpdateController from "../../../components/quickMessageUpdate/indexController"
import Utils from "../../../../../core/shared/utils"
import { ListName } from "../../../../../core/enums/order-sort-by"

const ListOfQuickMessage = ({
    t,
    showQuickMessageModalUpdate,
    handleCloseQuickMessageModalUpdate,
    quickMessageById,
    props,
    setCurrentQuickMessageInfo,
    popover,
}) => {
    
    return (
        <Container fluid className="nopadding">
            <QuickMessageUpdateController modalShow={showQuickMessageModalUpdate} onHide={handleCloseQuickMessageModalUpdate} data={quickMessageById} listWhatsapp={props.listWhatsapp} callBackModalQuickMessage={() => props.callBackModalQuickMessage()} />
            <div style={{ marginTop: '2vh', padding: "0px" }} className="ps-3">
                <ListGroup variant="flush" className="TicketsRow">
                    <ListGroup.Item className="TicketsRowCollums mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                        <Row style={{ padding: "0px" }} name="listOfQuickMessageHead">
                            <Col md={4} className="nopadding">
                                {t('configurations_ticket.list_of_quick_messages.title')}
                            </Col>
                            <Col md={7} className="nopadding">
                                {t('configurations_ticket.list_of_quick_messages.message')}
                            </Col>
                            <Col md={1} className="d-flex justify-content-end">
                                {t('configurations_ticket.list_of_quick_messages.actions')}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <div id="listOfQuickMessage" style={{ overflowY: 'auto', height: '51vh' }}>
                        <InfiniteScroll
                            dataLength={props?.data?.length}
                            next={props.updateListFunction}
                            hasMore={props.HasMoreInformation as boolean}
                            loader={<h4></h4>}
                            scrollableTarget={"listOfQuickMessage"}
                            onScroll={() => Utils.hidePopoverOnScroll(ListName.ListOfQuickMessage)}
                        >
                            {
                                <ListGroup variant="flush" className="TicketsRow">
                                    {
                                        props.data.map((item, index) => {
                                            let channels = t('configurations_ticket.list_of_quick_messages.na')

                                            switch (item.channel_id) {
                                                case Channels.Email:
                                                    channels = t('configurations_ticket.list_of_quick_messages.email')
                                                    break
                                                case Channels.WhatsApp:
                                                    channels = t('configurations_ticket.list_of_quick_messages.whatsapp')
                                                    break
                                                case Channels.Instagram:
                                                    channels = t('configurations_ticket.list_of_quick_messages.instagram')
                                                    break
                                                case Channels.Facebook:
                                                    channels = t('configurations_ticket.list_of_quick_messages.facebook')
                                                    break
                                                case Channels.Webchat:
                                                    channels = t('configurations_ticket.list_of_quick_messages.webchat')
                                                    break
                                                case Channels.Telefonia:
                                                    channels = t('configurations_ticket.list_of_quick_messages.callcenter')
                                                    break
                                                default:
                                                    break 
                                            }

                                            return (
                                                <ListGroup.Item style={{ color: '#707070' }} id={item.id} key={item.id + index.toString()}>
                                                    <Row className="TicketsRowHeight">
                                                        <Col md={4} className={'align-self-center nopadding oneLineClampEllipsis'}>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {item.title}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="me-3">
                                                                    {item.title}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col md={3} className={'align-self-center nopadding oneLineClampEllipsis'}>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {item.message}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="me-3">
                                                                    {item.message}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col className="d-flex justify-content-end">
                                                            <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                <Button id={item.title + "*ID*" + item.id} onClick={(e) => setCurrentQuickMessageInfo(e)} className="nopadding d-flex justify-content-start align-self-center" style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <circle cx="12" cy="12" r="1" />
                                                                        <circle cx="12" cy="19" r="1" />
                                                                        <circle cx="12" cy="5" r="1" />
                                                                    </svg>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                </ListGroup>
                            }
                            
                        </InfiniteScroll>
                    </div>
                </ListGroup>
            </div>
        </Container>
    )
}

export default ListOfQuickMessage