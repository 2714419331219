import { FC, useRef } from "react";
import { IInputColorPicker } from "./indexModel";
import { Button, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { SketchPicker } from 'react-color';

const InputColorPicker: FC<IInputColorPicker> = (props) => {
    // deixando o posicionamento do popover fixo dentro de um row para evitar problema de deslocamento para fora da tela 
    const overlayRef = useRef(null);
    
    const popoverColorPicker = () => {
        return (
          <Popover style={{ border: 'none', position: 'absolute', width: '2vw' }} id="pop-pop" className='px-0'>
            <Popover.Body className='nopadding'>
              <SketchPicker color={props.color} onChange={(color) => { props.setColor(color.hex) }} disableAlpha={true} />
            </Popover.Body>
          </Popover>
        );
    }

    return (
        <>
          <Row 
            className='nopadding py-2 px-2'
            style={{ border: '1px solid #AFAFAF', borderRadius: '5px' }}
          >
            <Col md={11} className='d-flex align-items-center nopadding'>
              <input 
                type="text"
                id="hexColor"
                name="hexColor"
                value={props.color}
                style={{
                  border: 'none',
                  width: '100%'
                }} 
                placeholder="#FFFFFF"
                required
                onChange={(event) => { 
                  // if (event.target.value?.length <= 7)
                    props.setColor(event.target.value) 
                }}
              />
            </Col>
            <Col className='d-flex align-items-center nopadding justify-content-end'>
              <div className='nopadding'>
                <OverlayTrigger rootClose={true} /* show={props.show} onToggle={props.onTogglePopover} */ trigger={['click']} placement="top-start" 
                  overlay={popoverColorPicker()}
                  // container={overlayRef}
                >
                  <Button 
                    className="nopadding" 
                    style={{
                      width: "25px",
                      height: '25px',
                      borderRadius: '5px',
                      opacity: '0px',
                      backgroundColor: props.color,
                      border: '1px solid #AFAFAF'
                    }} 
                    // onClick={props.onToggleButtonClick}
                  />
                </OverlayTrigger>
              </div>
            </Col>
            {/* mantendo overlay do popover fixo */}
            <Col ref={overlayRef} />
          </Row>
        </>
      );
}

export default InputColorPicker;