import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useNewConsumerMessage = () => {

    const socket = useContext(SocketContext);

    const [newConsumerMessage, setNewConsumerMessage] = useState()

    useEffect(() =>  {
        socket.socket?.on('new-consumer-message', (data) => setNewConsumerMessage(data.ticket ? data : data))

        return () => {
            socket.socket?.off('new-consumer-message', setNewConsumerMessage)
        }

    }, [socket.socket?.active])

    return { newConsumerMessage }
}

export default useNewConsumerMessage