import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useEffect, FC } from 'react';
import { LoadingInterface } from './indexModel';

const Loading: FC<LoadingInterface> = (props) => {
    const [visibility, setVisibility] = useState("none");
    const [customStyle, setCustomStyle] = useState({});
    const [rootStyle, setRootStyle] = useState({});
    const [shapeStyle, setShapeStyle] = useState({});

    useEffect(() => {
        if (props.visibility === true) {
            setVisibility("flex");
        } else {
            setVisibility("none");
        }

        setRootStyle({
            backgroundColor: 'transparent', height: props.height == null ? "100%" : props.height, width: props.width == null ? "100%" : props.width, position: 'fixed', zIndex: '10', left: props.left ? props.left : null, top: props.top ? props.top : null
        });

        if (props.customStyle) {
            setCustomStyle(props.customStyle);
        }
        if (props.shapeStyle) {
            setShapeStyle(props.shapeStyle);
        }
    }, [props.visibility, props.height, props.width, props.customStyle]);

    return (
        props.visibility &&
        <Row className="nopadding justify-content-center align-content-center gx-0" style={{ display: visibility, ...rootStyle, ...customStyle }}>
            <div className={ props.noBootstrap ? '' : 'col-md-1'}>
                    <Row style={{margin: "auto", background: "none", display: "block", shapeRendering: "auto", ...shapeStyle}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="84" cy="50" r="10" fill="#0c2461">
                                <animate attributeName="r" repeatCount="indefinite" dur="0.3846153846153846s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
                                <animate attributeName="fill" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#0c2461;#d8e2ff;#afd1ff;#4a69bd;#0c2461" begin="0s"></animate>
                            </circle>
                            <circle cx="16" cy="50" r="10" fill="#0c2461">
                                <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
                            </circle>
                            <circle cx="50" cy="50" r="10" fill="#4a69bd">
                                <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.3846153846153846s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.3846153846153846s"></animate>
                            </circle>
                            <circle cx="84" cy="50" r="10" fill="#afd1ff">
                                <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.7692307692307692s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.7692307692307692s"></animate>
                            </circle>
                            <circle cx="16" cy="50" r="10" fill="#d8e2ff">
                                <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.1538461538461537s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1.5384615384615383s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.1538461538461537s"></animate>
                            </circle>
                        </svg>
                    </Row>
            </div>
        </Row>
    );
}

export default Loading;
