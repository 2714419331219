import { useLocation, useNavigate } from "react-router-dom";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import ListConfigAccounts from "./index";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterfaceControlPanel } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/token";
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../../../store/internal";
import { useState } from "react";

const ListConfigAccountsController = (props) => {

  const [accountAux, setAccountAux] = useState(null);

  const AppRequesterConst = new AppRequesterController()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { state } = useLocation()
  const [isLoading, setIsLoading] = useState(false);

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  const modalIntegrateAccount = (data) => {
    dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Integrar com agente (CX)', "body": 'Ao clicar em continuar será criado um agente no CX (caso ainda não exista um agente associado a esse e-mail de atendente) e feita a sua integração', "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" }, functionConfirmation: () => integrateAccount() }));
  }

  const integrateAccount = async () => {
    let data = {
      company_id: state.id,
      user_id: accountAux?._id,
      owner_id: accountAux.owner,
      name: accountAux.name,
      email: accountAux.email,
      token_integration: accountAux?.token_integration || undefined,
      extension: accountAux?.extension?.extension,
      extension_id: accountAux?.extension?._id,
    }

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    console.log(JSON.stringify(state))
    await AppRequesterConst.Post(
      `/panel/vozxpress/import-account-integration`, data, { headers },
      (response: Object) => {},
      (data: any) => {
        dispatch(setShowAlertFeedback({ message: "Atendente integrado com sucesso.", visibility: true, signalIcon: true}))
        props.getAccountsVx();
        props.getAgentsWithAccount();
      },
      (error: { response: { status: number; data: { message: { message: string, path: [] }, code_cxpress: number; }; } }) => {
        dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
      }, navigate, dispatch, setIsLoading,
    )
  }

  const openMoreInfo = (data) => {
    let info = {
      account:  {
        email: data.email,
        extension: data?.extension?.extension,
        type: 'Account'
      }
    }
    props.setMoreInfo(info);
    props.setShowMoreInfo(true);
  }

  return (
    <ListConfigAccounts 
      list={props.list} 
      isEditAllowed={props.isEditAllowed} 
      setAccountAux={setAccountAux}
      integrateAccount={integrateAccount}
      setMoreInfo={props.setMoreInfo}
      openMoreInfo={openMoreInfo}
      modalIntegrateAccount={modalIntegrateAccount}
    />
  )
}

export default ListConfigAccountsController