import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";
import { Option } from "react-multi-select-component";
import SelectComponent from ".";
import { Channels } from "../../../../core/enums/channels";
import ChannelLabel from "../channelLabel";
import { ISelectComponentController, optionSelect } from "./indexModel";
import { useTranslation } from "react-i18next";

const SelectComponentController: FC<ISelectComponentController> = (props) => {
    const [allSelected, setAllSelected] = useState(false);
    const [tempSelecteds, setTempSelecteds] = useState(props.selecteds);
    const [tempOptions, setTempOptions] = useState(props.options);

    const nullOption = { label: props.nullOptionLabel, value: "NA" };
    const allOption = { label: props.allOptionLabel, value: "all" };

    const { t } = useTranslation();

    // adicionando itens extras, criação, na e todos
    useEffect(() => {
        // add create item option
        if (props.hasCreateItem && props.createItemLabel && props.createItemOnClick) {
            let createItem = { label: props.createItemLabel, value: 'create' };

            if (tempOptions.length === 0 || tempOptions[0].value !== createItem.value) {
                setTempOptions([ createItem, ...tempOptions ]);
            }
        }
        
        if (props.hasNullOption && props.nullOptionLabel) { 
            if (tempOptions.length === 0 || tempOptions[0].value !== nullOption.value) {
                setTempOptions( [ nullOption, ...tempOptions] );
            }
        } 
    }, [tempOptions]);

    useEffect(() => {
        if (props?.options?.length > 0) {
            setTempOptions(props.options);
        } else {
            setTempOptions([]);
        }

        // Se a listagem ficou vazia pela seleção de todos então não é preciso
        if (allSelected === false) {
            if (props?.selecteds?.length > 0) {
                setTempSelecteds(props.selecteds);
            } else {
                setTempSelecteds([]);
            }
        } else {
            setAllSelected(false);
        }
    }, [props.options, props.selecteds]);

    // verifica se as duas listas (options e selecteds) tem o  mesmo tamanho, e se algum elemento selecionado não estiver nas opcoes é uma inconsistencia
    useEffect(() => {
        if (props.singleSelect === false && props.options && props.selecteds && props.options.length === props.selecteds.length) {
            let commonItens = [];

            props.options.map((option) => {
                props.selecteds.map((selected) => {
                    option.value === selected.value && commonItens.push(option);
                });
            });

            // itens em comum deveria ter o mesmo tamanho de toda a listagem disponível
            if (commonItens.length !== props.options.length)
                onChange(commonItens);
        }
    }, [props.singleSelect, props.options, props.selecteds]);

    // esvazia a listagem no caso temp no caso da listagem de dados estiver vazia
    useEffect(() => {
        if (props.isReseting) {
            setTempSelecteds([]);
            props.setSelecteds([]);
            props.setIsReseting(false);
        }
    }, [props.isReseting]);

    useEffect(() => {
        // se o na é selecionado em um formulário de seleção única, a lista é esvaziada
        if (props.isFormNoFilter && props.singleSelect && tempSelecteds.length > 0 && tempSelecteds[0].value === nullOption.value) {
            props.setSelecteds([]);
            setTempSelecteds([]);
        }

        // Todos os itens selecionados é o mesmo que um filtro receber vazio
        if (!props.isFormNoFilter && !props.singleSelect && tempSelecteds.length === tempOptions.length && props.title !== 'Tags' && props.title !== 'Setor') {
            props.setSelecteds([]);
            setAllSelected(true); // impedir o reset da listagem de selecionados pelo useeffect
        } else if (!props.isFormNoFilter && !props.singleSelect && tempSelecteds.length === tempOptions.length && props.title === 'Tags' && tempSelecteds.length > 0) {
            setAllSelected(true); // impedir o reset da listagem de selecionados pelo useeffect
        }
    }, [tempSelecteds]);

    // força a selação do item, usado no aceitar em tickets
    useEffect(() => {
        if (props.selecteds.length > 0 && props.selecteds[0].force_select) {
            // sem a chave force_select
            let item = {label: props.selecteds[0].label, value: props.selecteds[0].value};

            setTempSelecteds([item]);
            props.setSelecteds([item]);
        }
    }, [props.selecteds]);


    const customItemOption = ({
        option,
        onClick,
    }/*: IDefaultItemRendererProps*/) => {
        const fontWeight = (props.hasCreateItem && option.value === "create")? 'bold': "";

        return (
            <div className={`item-renderer`}>
                <input
                    type="checkbox"
                    onChange={
                        props.hasCreateItem && option.value === "create"? 
                            props.createItemOnClick : 
                            onClick
                    }
                />
                {/* <span style={(this.props.hasCreateItem && option.value === "create" ) ? { fontWeight: 'bold' } : {}}>{option.label}</span> */}
                { option.channel_icon ? ChannelLabel(option.label, option.channel_icon as Channels, { color: "rgb(112, 112, 112)", fontWeight: fontWeight }) : ChannelLabel(option.label, option.value as Channels, { color: "rgb(112, 112, 112)", fontWeight: fontWeight })}
            </div>
        );
    }

    const customValueRendererChannel = (selecteds): ReactNode => {
        return (
            <span>
                { 
                    selecteds.map((selected) => {
                        if (selected.channel_icon) {
                            return ( <> { ChannelLabel(selected.label, selected.channel_icon as Channels, { color: "#1E1E1E" }) } </> );    
                        } else {
                            return ( <> { ChannelLabel(selected.label, selected.value as Channels, { color: "#1E1E1E" }) } </> );
                        }
                    }) 
                }
            </span>
        );
    }

    // texto com (criar consumidor) para o dropdown de consumidor em tickets
    const customValueRendererIsCreatingConsumer = (selecteds): ReactNode => {
        return (
            <span>
                { 
                    selecteds.map((selected) => {
                        return ( 
                            <div className='d-flex' style={{ height: '100%', alignItems: "center" }}>
                                <span className='pe-2 align-items-center'>
                                    { selected.label }
                                </span>
                                <span style={{ font: 'var(--px15_19_Bold-font-Roboto)' }}>
                                    {t('home_tickets.messages_component.create_consumer_on_ticket')}
                                </span>
                            </div>
                        );
                    }) 
                }
            </span>
        );
    }

    const onChange = (value: optionSelect []) => {
        if (props.singleSelect) {
            if (value.length > 0) {
                props.setSelecteds([value[value.length - 1]]);
                setTempSelecteds([value[value.length - 1]]);

                setTempSelecteds((selecteds) => {
                    addItemNotInOptions(tempOptions[0], setTempOptions);

                    if (selecteds[0].value !== nullOption.value && selecteds[0].value !== allOption.value)
                        addItemNotInOptions(selecteds[0], props.setOptions);
                    return selecteds;
                });

            } else {
                props.setSelecteds([]);
                setTempSelecteds([]);
            }
        } else {
            props.setSelecteds(value);
            setTempSelecteds(value);
        }

        if (props.onSelect) {
            props.onSelect(value);
        }
    }

    /**
     * search item in search
     * using in seaarch from back
     * add to list if not founded
     */
    const addItemNotInOptions = (selected: optionSelect, setList: Dispatch<SetStateAction<optionSelect[]>>) => {
        setList((list) => {
            if (!list.find((item) => item.value === selected.value) && selected.key !== "NA") {
                list.splice(1, 0, selected);
            }
            return list;
        });
    }

    return <SelectComponent 
        tempOptions={tempOptions}
        tempSelecteds={tempSelecteds}
        singleSelect={props.singleSelect}
        onChange={onChange}
        hasAllOption={props.hasAllOption}
        title={props.title}
        overrideStrings={props.overrideStrings}
        customItemOption={customItemOption}
        selecteds={props.selecteds}
        customValueRenderer={
            (props.singleSelect && props.isChannel && props.selecteds.length > 0)? 
                customValueRendererChannel : 
                (
                    props.isCreatingConsumer?
                        customValueRendererIsCreatingConsumer:
                        null
                )
        } 
        className={props.className} 
        disabled={props.disabled} 
        isChannel={props.isChannel}  
        filterOptions={props.filterOptions}
        disableSearch={props.disableSearch}  
    />
}

export default SelectComponentController;