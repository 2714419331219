import React, { ChangeEventHandler, FC, MouseEventHandler } from 'react';
import { Col, Row, OverlayTrigger, Tooltip, Button, Container } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import { FilterOptionComponentInterface } from './indexModel';

import './styles.css';
import SelectComponentController from '../selectComponent/indexController';
import { TicketFilterType } from '../../../../core/enums/ticket-filter-type';

interface PropsInputCalendar {
    children?: React.ReactNode;
    value?: string;
    placeholder?: string,
    placeholderText?: string;
    onClick?: MouseEventHandler<HTMLInputElement>;
    // onClick?: MouseEventHandler<HTMLLabelElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const FilterOptionComponent: FC<FilterOptionComponentInterface> = (props) => {
    /**
     * Custom Calendar Input, support icon calendar
     */
    const CustomInputCalendar = React.forwardRef<HTMLDivElement, PropsInputCalendar>(
        (props, ref) => {
            return (
                <Container>
                    <Row
                        style={{ /*backgroundColor: 'green',*/ padding: '0 8px', height:'5.6vh' }}
                        id="dateInput"
                        className='dateInputTickets'
                        onClick={props.onClick}
                        ref={ref}
                    >
                        <Col className="d-flex align-items-center ms-0 ps-0 nopadding" md={10}>
                            <input className='dateInputCalendar ps-0' id="dateInputCalendarId" type="text" onChange={props.onChange} value={props.value} placeholder={props.placeholderText || props.placeholder} >
                            </input>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-end nopadding m-0' md={2}>
                            <svg id="ic-calendar" xmlns="http://www.w3.org/2000/svg" width="2.5vh" viewBox="0 0 24 24">
                                <path id="Caminho_445" data-name="Caminho 445" d="M0,0H24V24H0Z" fill="none" />
                                <rect id="Retângulo_608" data-name="Retângulo 608" width="16" height="16" rx="2" transform="translate(4 5)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Linha_31" data-name="Linha 31" y2="4" transform="translate(16 3)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Linha_32" data-name="Linha 32" y2="4" transform="translate(8 3)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Linha_33" data-name="Linha 33" x2="16" transform="translate(4 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <rect id="Retângulo_609" data-name="Retângulo 609" width="2" height="2" transform="translate(8 15)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </svg>
                        </Col>
                    </Row>
                </Container>
            );
        },
    );

    return (
        <Row className='flex-nowrap'>
            <Col md={2} style={{ color: '#0C2461', width: '10.5%', paddingRight: '0' }} className="align-self-center ms-3">
                <p className='nopadding'>{`${props.t('filter_option.filtrar_por')}`}</p>
            </Col>
            {
                props.optionNames.map((optionName, index) => {

                    switch (optionName) {
                        case "Canal":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedChannel && props.selectedChannel[0] ?
                                            props.selectedChannel[0].label : `${props.t('filter_option.canal')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Canal"
                                    show={props.showTooltipChannel}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding"
                                        onMouseEnter={() => props.setShowTooltipChannel(true)}
                                        onMouseLeave={() => props.setShowTooltipChannel(false)}>
                                        <div className={"nopadding"} onClick={() => props.setShowTooltipChannel(false)}>
                                            <SelectComponentController
                                                options={props?.isChatbot ? props.channelOptionsChatbot : props.channelOptions}
                                                setOptions={props?.isChatbot ? props.setChannelOptionsChatbot : props.setChannelOptions}
                                                selecteds={props.selectedChannel}
                                                setSelecteds={props.setSelectedChannel}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Canal"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedCanal')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchCanal')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedCanal')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsCanal')}`,
                                                        "search": `${props.t('filter_option.searchCanal')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllCanal')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredCanal')}`,
                                                        "selectSomeItems": `${props.t('filter_option.canal')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                                isChannel
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "Automação":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedBot && props.selectedBot[0] ?
                                                props.selectedBot[0].label : `Automação`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Automação"
                                    show={props.showTooltipAutomation}
                                >
                                    <Col key={index} md={2} style={{ width: '16%' }} className="nopadding ps-2"
                                        onMouseEnter={() => props.setShowTooltipAutomation(true)}
                                        onMouseLeave={() => props.setShowTooltipAutomation(false)}>
                                        <div className={"nopadding"} onClick={() => props.setShowTooltipAutomation(false)}>
                                            <SelectComponentController
                                                options={props.automationOptions}
                                                setOptions={props.setAutomationOptions}
                                                selecteds={props.selectedAutomation}
                                                setSelecteds={props.setSelectedAutomation}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Automação"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedBot')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchBot')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedBot')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsBot')}`,
                                                        "search": `${props.t('filter_option.searchBot')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllBot')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredBot')}`,
                                                        "selectSomeItems": `${props.t('components.filter_option_component.option_names.automation')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );    
                        case "Bot":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedBot && props.selectedBot[0] ?
                                                props.selectedBot[0].label : `${props.t('filter_option.bot')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Bot"
                                    show={props.showTooltipBot}
                                >
                                    <Col key={index} md={1} style={{ width: '7%' }} className="nopadding ps-2"
                                        onMouseEnter={() => props.setShowTooltipBot(true)}
                                        onMouseLeave={() => props.setShowTooltipBot(false)}>
                                        <div className={"nopadding"} onClick={() => props.setShowTooltipBot(false)}>
                                            <SelectComponentController
                                                options={props.botOptions}
                                                setOptions={props.setBotOptions}
                                                selecteds={props.selectedBot}
                                                setSelecteds={props.setSelectedBot}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Bot"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedBot')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchBot')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedBot')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsBot')}`,
                                                        "search": `${props.t('filter_option.searchBot')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllBot')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredBot')}`,
                                                        "selectSomeItems": `${props.t('filter_option.bot')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "AtribuidoConsumidores":
                        case "Atribuido":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedAgents && props.selectedAgents[0] ?
                                            props.selectedAgents[0].label : `${props.t('filter_option.atribuicao')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Atribuido"
                                    show={props.showTooltipAgents}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className={['your', 'new'].includes(props?.currentState) ? 'none' : 'nopadding ps-2'}
                                        onMouseEnter={() => props.setShowTooltipAgents(true)}
                                        onMouseLeave={() => props.setShowTooltipAgents(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipAgents(false)}>
                                            <SelectComponentController
                                                options={props.agentOptions}
                                                setOptions={props.setAgentOptions}
                                                selecteds={props.selectedAgents}
                                                setSelecteds={props.setSelectedAgents}
                                                singleSelect={optionName === 'AtribuidoConsumidores' ? true : false}
                                                isFormNoFilter={false}
                                                title={"Atribuição"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedAgente')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchAgente')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedAgente')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsAgente')}`,
                                                        "search": `${props.t('filter_option.searchAgente')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllAgente')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredAgente')}`,
                                                        "selectSomeItems": `${props.t('filter_option.atribuicao')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "Status":
                            return (
                                props.currentViewFilter == TicketFilterType.Deleted ? 
                                    <></>
                                    :
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                props.selectedStatus && props.selectedStatus[0] ?
                                                    props.selectedStatus[0].label : `${props.t('filter_option.status')}`
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="Status"
                                        show={props.showTooltipStatus}
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className={props?.currentState === 'new' ? 'none' : "nopadding ps-2"}
                                            onMouseEnter={() => props.setShowTooltipStatus(true)}
                                            onMouseLeave={() => props.setShowTooltipStatus(false)}>
                                            <div className={"nopadding"} onClick={() => props.setShowTooltipStatus(false)}>
                                                <SelectComponentController
                                                    options={props.statusOptions}
                                                    setOptions={props.setStatusOptions}
                                                    selecteds={props.selectedStatus}
                                                    setSelecteds={props.setSelectedStatus}
                                                    singleSelect={false}
                                                    isFormNoFilter={false}
                                                    title={"Status"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedStatus')}`,
                                                            "clearSearch": `${props.t('filter_option.clearSearchStatus')}`,
                                                            "clearSelected": `${props.t('filter_option.clearSelectedStatus')}`,
                                                            "noOptions": `${props.t('filter_option.noOptionsStatus')}`,
                                                            "search": `${props.t('filter_option.searchStatus')}`,
                                                            "selectAll": `${props.t('filter_option.selectAllStatus')}`,
                                                            "selectAllFiltered": `${props.t('filter_option.selectAllFilteredStatus')}`,
                                                            "selectSomeItems": `${props.t('filter_option.status')}`,
                                                            "create": `${props.t('filter_option.create')}`,
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    hasAllOption={true}
                                                    isReseting={props.resetingMultiSelectFilter}
                                                    setIsReseting={props.setResetingMultiSelectFilter}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                
                            );
                        case "Setor":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedDepartments && props.selectedDepartments[0] ?
                                                props.selectedDepartments[0].label : `${props.t('filter_option.setor')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Setor"
                                    show={props.showTooltipSetor}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2"
                                        onMouseEnter={() => props.setShowTooltipSetor(true)}
                                        onMouseLeave={() => props.setShowTooltipSetor(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipSetor(false)}>
                                            <SelectComponentController
                                                options={props.departmentOptions}
                                                setOptions={props.setDepartmentsOptions}
                                                selecteds={props.selectedDepartments}
                                                setSelecteds={props.setSelectedDepartments}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Setor"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedSetor')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchSetor')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedSetor')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsSetor')}`,
                                                        "search": `${props.t('filter_option.searchSetor')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllSetor')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredSetor')}`,
                                                        "selectSomeItems": `${props.t('filter_option.setor')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "Data":
                            return (
                                <Col key={index} md={2} style={{ width: '16%' }} className="nopadding ps-2">
                                    <DatePicker
                                        className="dateInputTickets"
                                        // id="dateInput"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={props.t('filter_option.date')}
                                        selectsRange={true}
                                        startDate={props.startDate}
                                        endDate={props.endDate}
                                        onChange={(value) => props.onSelectDropdownDate(value)}
                                        calendarClassName="calendaryStyle"
                                        onSelect={() => { props.setUpdateInputDateActive(true) /* document.getElementById("dateInputCalendarId").className = "dateInputCalendarActive"; */ }}
                                        onCalendarClose={() => {
                                            if (props.currentFilterDate[0] !== null && props.currentFilterDate[1] === null)
                                            props.setCurrentFilterDate([null, null]);
                                            props.setUpdateInputDateActive(false);
                                        }}
                                        customInput={<CustomInputCalendar />}
                                        language={props.language}
                                    />
                                </Col>
                            );
                        case "Tipo":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedTriggerType && props.selectedTriggerType[0] ?
                                            props.selectedTriggerType[0].label : `${props.t('filter_option.tipo')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Tipo"
                                    show={props.showTooltipType}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding pe-2"
                                        onMouseEnter={() => props.setShowTooltipType(true)}
                                        onMouseLeave={() => props.setShowTooltipType(false)}>
                                        <div className={"nopadding"} onClick={() => props.setShowTooltipType(false)}>
                                            <SelectComponentController
                                                options={props.triggerTypeOptions}
                                                setOptions={props.setTriggerTypeOptions}
                                                selecteds={props.selectedTriggerType}
                                                setSelecteds={props.setSelectedTriggerType}
                                                singleSelect={false}
                                                isFormNoFilter={true}
                                                title={"Tipo"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedTipo')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchTipo')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedTipo')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsTipo')}`,
                                                        "search": `${props.t('filter_option.searchTipo')}`,
                                                        "selectAll": `${props.t('filter_option.allItemsAreSelectedTipo')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredTipo')}`,
                                                        "selectSomeItems": `${props.t('filter_option.tipo')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "Responsável":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedResponsible && props.selectedResponsible[0] ?
                                                props.selectedResponsible[0].label : `${props.t('filter_option.responsavelTooltip')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Responsável"
                                    show={props.showTooltipResponsible}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2"
                                        onMouseEnter={() => props.setShowTooltipResponsible(true)}
                                        onMouseLeave={() => props.setShowTooltipResponsible(false)}>
                                        <div className={"nopadding"} onClick={() => props.setShowTooltipResponsible(false)}>
                                            <SelectComponentController
                                                options={props.responsibleOptions}
                                                setOptions={props.setResponsibleOptions}
                                                selecteds={props.selectedResponsible}
                                                setSelecteds={props.setSelectedResponsible}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Responsável"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedResponsavel')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchResponsavel')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedResponsavel')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsResponsavel')}`,
                                                        "search": `${props.t('filter_option.searchResponsavel')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllResponsavel')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredResponsavel')}`,
                                                        "selectSomeItems": `${props.t('filter_option.responsavel')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "TriggerStatus":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedTriggerStatus && props.selectedTriggerStatus[0] ?
                                            props.selectedTriggerStatus[0].label : `${props.t('filter_option.triggerStatus')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="TriggerStatus"
                                    show={props.showTooltipTriggerStatus}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2"
                                        onMouseEnter={() => props.setShowTooltipTriggerStatus(true)}
                                        onMouseLeave={() => props.setShowTooltipTriggerStatus(false)}>
                                        <div className={"nopadding"} onClick={() => props.setShowTooltipTriggerStatus(false)}>
                                            <SelectComponentController
                                                options={props.triggerStatusOptions}
                                                setOptions={props.setTriggerStatusOptions}
                                                selecteds={props.selectedTriggerStatus}
                                                setSelecteds={props.setSelectedTriggerStatus}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Status"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedTriggerStatus')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchTriggerStatus')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedTriggerStatus')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsTriggerStatus')}`,
                                                        "search": `${props.t('filter_option.searchTriggerStatus')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllTriggerStatus')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredTriggerStatus')}`,
                                                        "selectSomeItems": `${props.t('filter_option.triggerStatus')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "Tags":
                        case "Tag":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedTags && props.selectedTags[0] ?
                                            props.selectedTags[0].label : `${props.t('filter_option.tags')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Tag"
                                    show={props.showTooltipTags}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2"
                                        onMouseEnter={() => props.setShowTooltipTags(true)}
                                        onMouseLeave={() => props.setShowTooltipTags(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipTags(false)}>
                                            <SelectComponentController
                                                options={props.tagsOptions}
                                                setOptions={props.setTagsOptions}
                                                selecteds={props.selectedTags}
                                                setSelecteds={props.setSelectedTags}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Tags"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedTag')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchTag')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedTag')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsTag')}`,
                                                        "search": `${props.t('filter_option.searchTag')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllTag')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredTag')}`,
                                                        "selectSomeItems": `${props.t('filter_option.tags')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                            case "ConsumerTags":
                                return (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                props.selectedConsumerTags && props.selectedConsumerTags[0] ?
                                                props.selectedConsumerTags[0].label : `${props.t('filter_option.tags')}`
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="Tag"
                                        show={props.showTooltipTags}
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2"
                                            onMouseEnter={() => props.setShowTooltipTags(true)}
                                            onMouseLeave={() => props.setShowTooltipTags(false)}>
    
                                            <div className={"nopadding"} onClick={() => props.setShowTooltipTags(false)}>
                                                <SelectComponentController
                                                    options={props.consumerTagsOptions}
                                                    setOptions={props.setConsumerTagsOptions}
                                                    selecteds={props.selectedConsumerTags}
                                                    setSelecteds={props.setSelectedConsumerTags}
                                                    singleSelect={false}
                                                    isFormNoFilter={false}
                                                    title={"Tags"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedTag')}`,
                                                            "clearSearch": `${props.t('filter_option.clearSearchTag')}`,
                                                            "clearSelected": `${props.t('filter_option.clearSelectedTag')}`,
                                                            "noOptions": `${props.t('filter_option.noOptionsTag')}`,
                                                            "search": `${props.t('filter_option.searchTag')}`,
                                                            "selectAll": `${props.t('filter_option.selectAllTag')}`,
                                                            "selectAllFiltered": `${props.t('filter_option.selectAllFilteredTag')}`,
                                                            "selectSomeItems": `${props.t('filter_option.tags')}`,
                                                            "create": `${props.t('filter_option.create')}`,
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    hasAllOption={true}
                                                    isReseting={props.resetingMultiSelectFilter}
                                                    setIsReseting={props.setResetingMultiSelectFilter}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                );
                        case "Consumer":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedConsumer && props.selectedConsumer[0] ?
                                            props.selectedConsumer[0].label : `${props.t('filter_option.consumidores')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Consumer"
                                    show={props.showTooltipConsumers}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2"
                                        onMouseEnter={() => props.setShowTooltipConsumers(true)}
                                        onMouseLeave={() => props.setShowTooltipConsumers(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipConsumers(false)}>
                                            <SelectComponentController className="nopadding"
                                                options={props.consumerOptions}
                                                setOptions={props.setConsumerOptions}
                                                selecteds={props.selectedConsumer}
                                                setSelecteds={props.setSelectedConsumer}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={"Consumidores"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedConsumer')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchConsumer')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedConsumer')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsConsumer')}`,
                                                        "search": `${props.t('filter_option.searchConsumer')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllConsumer')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredConsumer')}`,
                                                        "selectSomeItems": `${props.t('filter_option.consumidores')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                filterOptions={props.multiselectFilterUsers}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "Chatbot":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedChatbot && props.selectedChatbot[0] ?
                                            props.selectedChatbot[0].label : `${props.t('filter_option.bot')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="Chatbot"
                                    show={props.showTooltipChatbot}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding pe-2"
                                        onMouseEnter={() => props.setShowTooltipChatbot(true)}
                                        onMouseLeave={() => props.setShowTooltipChatbot(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipChatbot(false)}>
                                            <SelectComponentController className="nopadding"
                                                options={props.chatbotOptions}
                                                setOptions={props.setChatbotOptions}
                                                selecteds={props.selectedChatbot}
                                                setSelecteds={props.setSelectedChatbot}
                                                singleSelect={true}
                                                isFormNoFilter={false}
                                                title={"Chatbot"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedBot')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchBot')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedBot')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsBot')}`,
                                                        "search": `${props.t('filter_option.searchBot')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllBot')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredBot')}`,
                                                        "selectSomeItems": `${props.t('filter_option.bot')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                filterOptions={props.multiselectFilterUsers}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "assigned_task":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedAssignedTask && props.selectedAssignedTask[0] ?
                                            props.selectedAssignedTask[0].label : props.t('filter_option.assignedTaskTooltip')
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="assigned_task"
                                    show={props.showTooltipAssignedTask}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding pe-2"
                                        onMouseEnter={() => props.setShowTooltipAssignedTask(true)}
                                        onMouseLeave={() => props.setShowTooltipAssignedTask(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipAssignedTask(false)}>
                                            <SelectComponentController className="nopadding"
                                                options={props.assignedTaskOptions}
                                                setOptions={props.setAssignedTaskOptions}
                                                selecteds={props.selectedAssignedTask}
                                                setSelecteds={props.setSelectedAssignedTask}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={props.t('filter_option.assignedTask')}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": props.t('filter_option.allItemsAreSelectedAssignedTask'),
                                                        "clearSearch": props.t('filter_option.clearSearchAssignedTask'),
                                                        "clearSelected": props.t('filter_option.clearSelectedAssignedTask'),
                                                        "noOptions": props.t('filter_option.noOptionsAssignedTask'),
                                                        "search": props.t('filter_option.searchAssignedTask'),
                                                        "selectAll": props.t('filter_option.selectAllAssignedTask'),
                                                        "selectAllFiltered": props.t('filter_option.selectAllFilteredAssignedTask'),
                                                        "selectSomeItems": props.t('filter_option.selectSomeItemsAssignedTask'),
                                                        "create": props.t('filter_option.create'),
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                filterOptions={props.multiselectFilterAgents}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "requester_task":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedRequesterTask && props.selectedRequesterTask[0] ?
                                            props.selectedRequesterTask[0].label : props.t('filter_option.requesterTaskTooltip')
                                        }</Tooltip>}
                                    placement={"top"}
                                    key="requester_task"
                                    show={props.showTooltipRequesterTask}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding pe-2"
                                        onMouseEnter={() => props.setShowTooltipRequesterTask(true)}
                                        onMouseLeave={() => props.setShowTooltipRequesterTask(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipRequesterTask(false)}>
                                            <SelectComponentController className="nopadding"
                                                options={props.requesterTaskOptions}
                                                setOptions={props.setRequesterTaskOptions}
                                                selecteds={props.selectedRequesterTask}
                                                setSelecteds={props.setSelectedRequesterTask}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={props.t('filter_option.requesterTask')}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": props.t('filter_option.allItemsAreSelectedRequesterTask'),
                                                        "clearSearch": props.t('filter_option.clearSearchRequesterTask'),
                                                        "clearSelected": props.t('filter_option.clearSelectedRequesterTask'),
                                                        "noOptions": props.t('filter_option.noOptionsRequesterTask'),
                                                        "search": props.t('filter_option.searchRequesterTask'),
                                                        "selectAll": props.t('filter_option.selectAllRequesterTask'),
                                                        "selectAllFiltered": props.t('filter_option.selectAllFilteredRequesterTask'),
                                                        "selectSomeItems": props.t('filter_option.selectSomeItemsRequesterTask'),
                                                        "create": props.t('filter_option.create'),
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                filterOptions={props.multiselectFilterAgents}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "TicketTaskTags":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedTicketTaskTags && props.selectedTicketTaskTags[0] ?
                                            props.selectedTicketTaskTags[0].label : `${props.t('filter_option.ticketTaskTagsTooltip')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key={props.t('filter_option.ticketTaskTags')}
                                    show={props.showTooltipTicketTaskTags}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding pe-2"
                                        onMouseEnter={() => props.setShowTooltipTicketTaskTags(true)}
                                        onMouseLeave={() => props.setShowTooltipTicketTaskTags(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipTicketTaskTags(false)}>
                                            <SelectComponentController
                                                options={props.ticketTaskTagsOptions}
                                                setOptions={props.setTicketTaskTagsOptions}
                                                selecteds={props.selectedTicketTaskTags}
                                                setSelecteds={props.setSelectedTicketTaskTags}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={props.t('filter_option.ticketTaskTags')}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedTicketTaskTags')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearclearSearchTicketTaskTagschTag')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedTicketTaskTags')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsTicketTaskTags')}`,
                                                        "search": `${props.t('filter_option.searchTicketTaskTags')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllTicketTaskTags')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredTicketTaskTags')}`,
                                                        "selectSomeItems": `${props.t('filter_option.ticketTaskTags')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                        case "TicketTaskStatus":
                            return (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{
                                            props.selectedTicketTaskStatus && props.selectedTicketTaskStatus[0] ?
                                            props.selectedTicketTaskStatus[0].label : `${props.t('filter_option.ticketTaskStatusTooltip')}`
                                        }</Tooltip>}
                                    placement={"top"}
                                    key={props.t('filter_option.ticketTaskStatus')}
                                    show={props.showTooltipTicketTaskStatus}
                                >
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding pe-2"
                                        onMouseEnter={() => props.setShowTooltipTicketTaskStatus(true)}
                                        onMouseLeave={() => props.setShowTooltipTicketTaskStatus(false)}>

                                        <div className={"nopadding"} onClick={() => props.setShowTooltipTicketTaskStatus(false)}>
                                            <SelectComponentController
                                                options={props.ticketTaskStatusOptions}
                                                setOptions={props.setTicketTaskStatusOptions}
                                                selecteds={props.selectedTicketTaskStatus}
                                                setSelecteds={props.setSelectedTicketTaskStatus}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title={props.t('filter_option.ticketTaskStatus')}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": `${props.t('filter_option.allItemsAreSelectedTicketTaskStatus')}`,
                                                        "clearSearch": `${props.t('filter_option.clearSearchTicketTaskStatus')}`,
                                                        "clearSelected": `${props.t('filter_option.clearSelectedTicketTaskStatus')}`,
                                                        "noOptions": `${props.t('filter_option.noOptionsTicketTaskStatus')}`,
                                                        "search": `${props.t('filter_option.searchTicketTaskStatus')}`,
                                                        "selectAll": `${props.t('filter_option.selectAllTicketTaskStatus')}`,
                                                        "selectAllFiltered": `${props.t('filter_option.selectAllFilteredTicketTaskStatus')}`,
                                                        "selectSomeItems": `${props.t('filter_option.ticketTaskStatus')}`,
                                                        "create": `${props.t('filter_option.create')}`,
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption={true}
                                                isReseting={props.resetingMultiSelectFilter}
                                                setIsReseting={props.setResetingMultiSelectFilter}
                                            />
                                        </div>
                                    </Col>
                                </OverlayTrigger>
                            );
                    }
                })
            }
            <Col md={1} className="nopadding ps-2">
                <Button id="filterButton" className="buttonTicketsSec2" onClick={(e) => props.callbackFiltering(e)} disabled={props.isLoading}>
                    {props.t('filter_option.btn_filter')}
                </Button>
            </Col>
            <Col style={{ margin: 'auto 0 auto auto' }} className="d-flex justify-content-end align-self-end">
                <Button className="nopadding buttonTicketsSec4" onClick={(e) => props.handleCleanList()}>
                    <svg id="Grupo_11452" data-name="Grupo 11452" xmlns="http://www.w3.org/2000/svg" width="60%" viewBox="0 0 24 24">
                        <path id="Caminho_9734" data-name="Caminho 9734" d="M0,0H24V24H0Z" fill="none" />
                        <line id="Linha_394" data-name="Linha 394" x1="12" y2="12" transform="translate(6 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <line id="Linha_395" data-name="Linha 395" x2="12" y2="12" transform="translate(6 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                </Button>
            </Col>
        </Row>
    );
}

export default FilterOptionComponent;