import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../../../../../components/loading';
import './styles.scss';
import { getExtension } from '../../../../../../../../store/callcenter';
import UserService from '../../../../../../../../services/user-service';
import { DataChannelVozxpressHistoricInterface } from '../../../../../../../../services/requestsInterfacesModel';
import { AppRequesterController } from '../../../../../../../../services/appRequester/appRequesterController';
import { useEffect, useState } from 'react';
import { IconHistory, IconPhoneIncoming, IconPhoneOutgoing } from '@tabler/icons-react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController();

const CallHistory = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [recentCalls, setRecentCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userExtension = useSelector(getExtension);

  useEffect(() => {
    getCallHistory(userExtension);
  }, []);
  
  const getCallHistory = async (ramal: string) => {
    const headers = UserService.getHeaders();

    const config = { headers };
    await AppRequesterConst.Get(
        "channel-vozxpress/historic",
        config,
        (response: Object) => {
        },
        (data: DataChannelVozxpressHistoricInterface) => {
          if (data.status === 200) {
            const calls = [];
            data.data.historic.map((call, i) => {
              const date = new Date(call.date);
              calls.push({
                id: i,
                sent: call.origin === ramal ? true : false,
                number: call.origin === ramal ? call.destiny : call.origin,
                date: date.toLocaleString(),
                type: call.type,
                duration:  call.duration,
                status: call.status,
              });
            });
            setRecentCalls(calls);
          }
        },
        (error: Object) => {
        }, null, dispatch, setIsLoading, {}
    );
  }

  const formatDuration = (time = 0) => {
    const sec_num = parseInt(String(time), 10)
    const hours   = Math.floor(sec_num / 3600)
    const minutes = Math.floor(sec_num / 60) % 60
    const seconds = sec_num % 60

    return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":");
  }

  const formatNumber = (number: string) => {
    number = number || '';
    if (number.length <= 11 && !number.startsWith('55')) {
      return formatPhoneNumberIntl("+55" + number);
    } else {
      return formatPhoneNumberIntl("+" + number);
    }
  }

  return (
    <div className="phoneCallcenterHistory">
      <span className='phoneCallcenterHistory-title'>{t('phone_callcenter.main_screen.call_history.recent_calls')}</span>
      {isLoading && <Loading visibility={isLoading} noBootstrap={true} customStyle={{ position: 'relative' }} />}
      {
        !isLoading && recentCalls.length === 0 && 
        <div className='phoneCallcenterHistory-emptyList'>
          <IconHistory size={42} color="#4a69bd" />
          <span className='phoneCallcenterHistory-emptyList-text'>{t('phone_callcenter.main_screen.call_history.no_recent_calls')}</span>
        </div>
      }
      {
        !isLoading && recentCalls.length > 0 &&
        <div className="phoneCallcenterHistory-list">
          {recentCalls.map(item => {
            let tooltip_text = ''
            if (item.sent) {
              tooltip_text = `${t('phone_callcenter.main_screen.call_history.outgoing_call')} (${item.status === 'answered' ? t('phone_callcenter.main_screen.call_history.answered') : t('phone_callcenter.main_screen.call_history.not_answered')})`;
            } else {
              tooltip_text = `${t('phone_callcenter.main_screen.call_history.received_call')} (${item.status === 'answered' ? t('phone_callcenter.main_screen.call_history.answered') : t('phone_callcenter.main_screen.call_history.not_answered')})`;
            }

            const classItem = recentCalls.length < 6 ? 'phoneCallcenterHistory-item' : 'phoneCallcenterHistory-item item-scroll';
            
            return (
              <div className={classItem} key={`historic_item_${item.id}`}>
                <span className={"phoneCallcenterHistory-item-icon " + item.status}>
                  <OverlayTrigger placement="left" overlay={<Tooltip>{tooltip_text}</Tooltip>}>
                    { item.sent ? <IconPhoneOutgoing /> : <IconPhoneIncoming />}
                  </OverlayTrigger>
                </span>
                <div className="phoneCallcenterHistory-item-details">
                  <span className="phoneCallcenterHistory-item-number">
                  { item.type !== 'internal' ? formatNumber(item.number) : item.number }
                  </span>
                  <span className="phoneCallcenterHistory-item-date">
                    { item.date }
                  </span>
                </div>
                <div className="phoneCallcenterHistory-item-duration">
                  { formatDuration(item.duration) }
                </div>
              </div>
            );
          })}
        </div>
      }
    </div>
  );
}

export default CallHistory;
