import { useEffect, useState } from "react"
import { Button, Popover, OverlayTrigger } from "react-bootstrap"
import TemplateStatusAgent from "../templateStatusAgent"
import { StatusAgent } from "../../../../core/enums/status-agent"
import { useSelector } from "react-redux"
import { getIdUser, getNameUser, getProfileId } from "../../../../store/user"
import { IconPointFilled } from "@tabler/icons-react";
import './style.css';
import useStatusEmployee from "../../../../hooks/useStatusEmployee"
import employee, { getIdEmployee, getIdStatusEmployee } from "../../../../store/employee"

const ButtonStatusAgent = () => {
    const { statusEmployee } = useStatusEmployee();

    const values = {
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        employee: {
            id: useSelector(getIdEmployee),
            status_employee_id: useSelector(getIdStatusEmployee)
        }
    };

    const [showPopover, setShowPopover] = useState(false);
    const [status, setStatus] = useState<string>();
    const [isMaster, setIsMaster] = useState(false);

    const nameAgent = values?.user?.name?.split(' ');


    useEffect(() => {
        setIsMaster(values?.user?.profile_id === '5f2a7f61-c784-4b1a-93f5-ec989686ba58');
        if (statusEmployee?.user_id === values.user.id) {
            setStatus(statusEmployee.status_employee_id)
        } else {
            setStatus(values.employee.status_employee_id)
        }
    }, [statusEmployee?.status_employee_id])
  
    const showTemplateMessage = () => {
        if (showPopover === false) {
            setShowPopover(true)
        } else {
            setShowPopover(false)
        }
    }
 
    const popOverStatusAgent = (    
        <Popover id={"popOverStatusAgent"} style={{ border: 'none' }} data-trigger="focus">
            <Popover.Body className="nopadding">
                <TemplateStatusAgent showPopover={setShowPopover}/>
            </Popover.Body>
        </Popover>
    )

    return(
        <OverlayTrigger rootClose={showPopover} onToggle={() => showTemplateMessage()} show={showPopover} trigger={['click']} placement="bottom-start" overlay={popOverStatusAgent}>
            <Button style={{ height: "41px", marginLeft: "5px", border: "none", backgroundColor: "#EEf3FF", borderRadius: "4px", display: "flex", alignItems: "center", padding: "0px", paddingRight: "5px", paddingLeft: "5px" }}>
                {
                    status === StatusAgent.Online ?  
                        <span style={{ padding: "0px", margin: "0px" }}>
                            <IconPointFilled stroke={1.5} color="#1e8e3e" className="svg-status-online" />
                        </span>
                    :
                    status === StatusAgent.Offline ?
                        <span style={{ padding: "0px", margin: "0px" }}>
                            <IconPointFilled stroke={1.5} color="#ed1c24" className="svg-status-offline" />
                        </span>
                    :
                    status === StatusAgent.Ausente ?
                        <span style={{ padding: "0px", margin: "0px" }}>
                            <IconPointFilled stroke={1.5} color="#fff200" className="svg-status-ausente" />
                        </span>
                    :
                        <></>
                }
                <span style={{ fontSize: "0.8rem", color: "#242526", fontWeight: "bold", padding: "8px" }}>
                    { (isMaster) ? '[Master] ' + nameAgent[0] : nameAgent ? nameAgent[0] : ""}
                </span>
            </Button>
        </OverlayTrigger>
    )
}

export default ButtonStatusAgent