import React from 'react';
import { useSelector } from 'react-redux'
import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalEditTriggerType from "../../components/modalEditTriggerType"
import Utils from '../../../../core/shared/utils';
import { getShowEditTriggerTypeModal } from '../../../../store/internal';
import ButtonOrderByList from '../../components/buttonOrderByList';
import { ListName } from '../../../../core/enums/order-sort-by';

const ListOfTriggerTypes = ({
    t,
    values,
    callback,
    currentData,
    triggerTypes,
    callbackOrderBy,
    actionsActiveColumn,
    updateListfunction,
    popover,
    handleStatusTypeFunction,
    setCurrentTriggerInfo,
    showCreateTriggerType,
}) => {
    return (
        <>
            <ModalEditTriggerType token={values.token.value} visibility={useSelector(getShowEditTriggerTypeModal)} callback={callback} data={currentData}></ModalEditTriggerType>
            <div style={{ margin: '2.5vh 0.5vw 0px', padding: '0' }} className="ps-0 ms-0">
                {
                    triggerTypes !== undefined ?
                        <ListGroup variant="flux" className="TicketsRow">
                            <ListGroup.Item className="TicketsRowCollums mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                                {/* right padding for scrollbar */}
                                <Row name="listOfTriggerTypesHead">
                                    <Col md={1} className="nopadding me-3" style={{ width: '14%' }}>
                                        <ButtonOrderByList title={t("home_message_trigger.list_of_trigger_types.type")} columnIdName='name' callbackOrderBy={callbackOrderBy} />
                                    </Col>
                                    <Col md={1} className="nopadding d-flex justify-content-center">
                                        <ButtonOrderByList title="Status" columnIdName='active' callbackOrderBy={callbackOrderBy} />
                                    </Col>
                                    {actionsActiveColumn === true &&
                                        <Col className='d-flex justify-content-end' >
                                            {t('home_message_trigger.list_of_messages_triggers.action')}
                                        </Col>
                                    }
                                </Row>
                            </ListGroup.Item>
                            <div id="listOfTriggerTypes" style={{ overflowY: 'auto', height: '49vh' }}>
                                <InfiniteScroll                              
                                    dataLength={triggerTypes.length}
                                    next={updateListfunction}
                                    hasMore={true}
                                    loader={<h4> </h4>}
                                    scrollableTarget={"listOfTriggerTypes"}
                                    onScroll={ () => { Utils.hidePopoverOnScroll(ListName.ListOfTriggerTypes) } }
                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow">
                                            {
                                                triggerTypes.map((item, index) => {    
                                                    return (
                                                        <ListGroup.Item style={{ color: '#707070' }} id={item.id} key={item.id}>
                                                            <Row className='TicketsRowHeight'>
                                                                <Col md={1} className={'align-self-center nopadding me-3'} onClick={() => {}/* showEditModal */} style={{ cursor: 'pointer', width: '14%' }}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item.name}
                                                                                </Tooltip>
                                                                            }   
                                                                        >
                                                                            <span>{item.name}</span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={1} className={'align-self-center nopadding justify-content-center'} onClick={() => {throw new Error("Function not implemented")}/* showEditModal */} style={{ cursor: 'pointer', /* width: '10%'  */}}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.active?
                                                                                    <>{t("home_message_trigger.list_of_trigger_types.active")}</>:
                                                                                    <>{t("home_message_trigger.list_of_trigger_types.inativo")}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }   
                                                                    >
                                                                        <div /* style={{width: '100%'}} */> 
                                                                            {item.active?
                                                                                <div className="d-flex justify-content-center py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                                        borderRadius: '3px',
                                                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                        letterSpacing: '0px',
                                                                                        color: '#0C2461',
                                                                                    }}>
                                                                                    {t("home_message_trigger.list_of_trigger_types.active")}
                                                                                </div> : 
                                                                                <div className="d-flex justify-content-center py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#FFD4B0 0% 0% no-repeat padding-box',
                                                                                        borderRadius: '3px',
                                                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                        letterSpacing: '0px',
                                                                                        color: '#FF8119',
                                                                                    }}>
                                                                                    {t("home_message_trigger.list_of_trigger_types.inativo")}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                {actionsActiveColumn === true && 
                                                                    <Col className='d-flex justify-content-end' >
                                                                        <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item.active, handleStatusTypeFunction)} >
                                                                            <Button id={item.id + "*ID*" + item.active.toString()} name={item.name} onClick={(e) => setCurrentTriggerInfo(e)} className='nopadding d-flex justify-content-end align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <circle cx="12" cy="12" r="1" />
                                                                                    <circle cx="12" cy="19" r="1" />
                                                                                    <circle cx="12" cy="5" r="1" />
                                                                                </svg>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </ListGroup.Item>
                                                    );
                                                })}
                                        </ListGroup>

                                    }
                                </InfiniteScroll>
                            </div>
                        </ListGroup>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1}>
                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, <Button className="buttonAsText3 nopadding mb-1" onClick={showCreateTriggerType}>{t("home_message_trigger.list_of_trigger_types.add_type")}</Button></p>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        </>
    );

}

export default ListOfTriggerTypes;