import { FC } from "react";
import { IModalGeneralType } from "./indexModal";
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import allStyles from "./styles";
import { Field, FieldArray, Formik } from "formik";
import { setShowAlertFeedback } from "../../../../../../store/internal";
import { useDispatch } from "react-redux";

const ModalGeneralType: FC<IModalGeneralType> = (props) => {
  const dispatch = useDispatch();

  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton style={{ marginLeft: "4%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {props.t('home_tickets.messages_component.message_parameters')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container className="nopadding">
        <Formik
          initialValues={{arr: props.generalTypesVariables}}
          onSubmit={async (values) => {
            let valid = true;
            values.arr.forEach((element) => {
              if (element.tag_value === '')  {
                valid = false;
                dispatch(setShowAlertFeedback({ message: props.t('home_tickets.messages_component.fill_in_all_filds'), visibility: true, signalIcon: false}))
                return
              }
            });
            if (valid) {
              let auxiMessage = props.auxiTemplateMessage.message
              const templateParams = [];
              values.arr.forEach((element) => {
                auxiMessage = auxiMessage.replace(`{{${element.tag_number}}}`, element.tag_value);
                templateParams.push({"name": element.alias, "value": element.tag_value, "id": element.id})
              })
              props.auxiTemplateMessage.message = auxiMessage;
              props.setTemplateParamsGeneralType(templateParams)
              props.setTemplateMessage(props.auxiTemplateMessage)
              props.onHide();
            }
          }}
          
        >
          {({ values, handleSubmit, handleBlur }) => (
            <Form onSubmit={handleSubmit} noValidate validated={false}>
              <FieldArray name='variables'>
                {_ => (
                  <>
                    {values.arr.length > 0 && values.arr.map((item, index) => (
                      <Row key={index}>
                        <Col>
                        <Form.Label><span style={{ font: 'normal bold normal calc(0.4em + 0.9vw) Montserrat' }}>{item.alias}</span></Form.Label>
                        {/* <label htmlFor={`arr.${index}.name`}>{item.name}</label> */}
                        <InputGroup className="mb-3">
                        <Field
                          style={allStyles.customInput}
                          required={true}
                          name={`arr.${index}.tag_value`}
                          placeholder={values.arr[index].alias}
                          type="text"
                          onBlur={handleBlur}
                          maxLength={50}
                          />
                        </InputGroup>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </FieldArray>
              <Row className="d-flex justify-content-center mt-4">
                <Col xs={6} className="d-flex justify-content-start">
                  <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={ () => props.onHide() } >{props.t('home_tickets.messages_component.cancel')}</Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                  <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{props.t('home_tickets.messages_component.save')}</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default ModalGeneralType;