
import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';

import Tags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css"
import SelectComponentController from '../selectComponent/indexController';
import { IModalRegisterDepartments } from './indexModel';
import { FC } from 'react';

const ModalRegisterDepartments: FC<IModalRegisterDepartments> = (props) => {
  return (
    <Modal show={props.modalShow} onHide={props.Hide} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {props.t('home_departments.modal_register_departments.create_department')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{ name: '' }}
            validate={props.validate}
            onSubmit={props.onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={props.validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="name">{props.t('home_departments.modal_register_departments.form_name.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={props.showfeedbackName}
                        placeholder={props.t('home_departments.modal_register_departments.form_name.placeholder')}
                        aria-label={props.t('home_departments.modal_register_departments.form_name.placeholder')}
                        aria-describedby={props.t('home_departments.modal_register_departments.form_name.placeholder')}
                        size='lg'
                        type='name'
                        id="department_name"
                        name="name"
                        autoComplete='given-name'
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackname">
                        {errors.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{props.t('home_departments.modal_register_departments.form_participant_agents.label')}</Form.Label>
                    <SelectComponentController
                      className={"multiselectForm mb-3"}
                      options={props.options_agents}
                      setOptions={props.setOptions_agents}
                      selecteds={props.selected_agents}
                      setSelecteds={props.setSelected_agents}
                      singleSelect={false}
                      isFormNoFilter={true}
                      title={props.t('home_departments.modal_register_departments.form_participant_agents.placeholder')}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.allItemsAreSelected'),
                          "clearSearch": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.clearSearch'),
                          "clearSelected": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.clearSelected'),
                          "noOptions": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.noOptions'),
                          "search": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.search'),
                          "selectAll": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.selectAll'),
                          "selectAllFiltered": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.selectAllFiltered'),
                          "selectSomeItems": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.selectSomeItems'),
                          "create": props.t('home_departments.modal_register_departments.form_participant_agents.override_strings.create'),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={true}
                    />
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{props.t('home_departments.modal_register_departments.form_department_emails.label')}</Form.Label>
                    {/* <pre>{JSON.stringify(selected_emails)}</pre> */}
                    <SelectComponentController
                      className={"multiselectForm mb-3"}
                      options={props.options_emails}
                      setOptions={props.setOptions_emails}
                      selecteds={props.selected_emails}
                      setSelecteds={props.setSelected_emails}
                      singleSelect={false}
                      isFormNoFilter={true}
                      title={props.t('home_departments.modal_register_departments.form_department_emails.placeholder')}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.allItemsAreSelected'),
                          "clearSearch": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.clearSearch'),
                          "clearSelected": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.clearSelected'),
                          "noOptions": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.noOptions'),
                          "search": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.search'),
                          "selectAll": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.selectAll'),
                          "selectAllFiltered": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.selectAllFiltered'),
                          "selectSomeItems": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.selectSomeItems'),
                          "create": props.t('home_departments.modal_register_departments.form_department_emails.override_strings.create'),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={true}
                    />
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{props.t('home_departments.modal_register_triage.automation_select.automation_label')}{/* <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span> */}</Form.Label>
                    {/* <pre>{JSON.stringify(selectedChatbots)}</pre> */}
                    <SelectComponentController
                      //  multiselectFormError
                      className={'multiselectForm mb-3'}
                      options={props.channelKeys}
                      setOptions={props.setChannelKeys}
                      selecteds={props.selectedChannelKeys}
                      setSelecteds={props.setSelectedChannelKeys}
                      singleSelect={false}
                      isFormNoFilter={true}
                      title={props.t('home_departments.modal_register_triage.automation_select.placeholder')}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.t('home_departments.modal_register_triage.automation_select.override_strings.allItemsAreSelected'),
                          "clearSearch": props.t('home_departments.modal_register_triage.automation_select.override_strings.clearSearch'),
                          "clearSelected": props.t('home_departments.modal_register_triage.automation_select.override_strings.clearSelected'),
                          "noOptions": props.t('home_departments.modal_register_triage.automation_select.override_strings.noOptions'),
                          "search": props.t('home_departments.modal_register_triage.automation_select.override_strings.search'),
                          "selectAll": props.t('home_departments.modal_register_triage.automation_select.override_strings.selectAll'),
                          "selectAllFiltered": props.t('home_departments.modal_register_triage.automation_select.override_strings.selectAllFiltered'),
                          "selectSomeItems": props.t('home_departments.modal_register_triage.automation_select.override_strings.selectSomeItems'),
                          "create": props.t('home_departments.modal_register_triage.automation_select.override_strings.create'),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={true}
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col sm={true} style={allStyles.textFontGlay} >
                    <Form.Label htmlFor="basic-url">{props.t('home_departments.modal_register_departments.keywords')}</Form.Label>
                    <Tags
                      className="form-control-Default-text-area tag-min-height mb-3"
                      placeholder={props.t('home_departments.modal_register_departments.keywords_placeholder')}
                      onChange={(e) => props.onChangeTags(e)}
                    />
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                  <Col sm={true} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="basic-url">{props.t('home_departments.modal_register_departments.active_department')} &nbsp; &nbsp;</Form.Label>
                    <Form.Check name="radioIsAdministrator" label={props.t('home_departments.modal_register_departments.no')} type={'radio'} checked={!props.department_isActive} onChange={() => { props.setDepartment_isActive(false) }} inline></Form.Check>
                    <Form.Check name="radioIsAdministrator" label={props.t('home_departments.modal_register_departments.yes')} type={'radio'} checked={props.department_isActive} onChange={() => { props.setDepartment_isActive(true) }} inline defaultChecked></Form.Check>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-4">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={props.Hide} >{props.t('home_departments.modal_register_departments.cancel')}</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} disabled={props.isLoading}>{props.t('home_departments.modal_register_departments.save')}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalRegisterDepartments;
