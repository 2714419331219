import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import { TabComponentInterface } from './indexModel'

const TabComponent: FC<TabComponentInterface> = (props) => {
    return (
        <>
            {props.hasActivation ?
                <>
                    <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#1E1E1E', marginTop: '5vh' }}>{props.principalTextFalse}</p>
                    <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '2vh' }}>{props.secondaryTextFalse}</p>
                    <hr style={{ border: "1px solid #E8E8E8" }} />
                    <Button type='submit' size='lg' className="buttonBlue" style={{ width: '25%' }} onClick={props.onClick}>{props.textButton}</Button>
                </>
                :
                <>
                    <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#1E1E1E', marginTop: '5vh' }}>{props.principalTextTrue}</p>
                    <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '2vh' }}>{props.secondaryTextTrue}</p>
                    {/* <hr style={{ border: "1px solid #E8E8E8" }} /> */}
                    {props.children}
                </>
            }
        </>
    )
}

export default TabComponent