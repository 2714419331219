import { BodyInterface } from "./indexModel";

export class NewPasswordController {
    private setShowfeedbackPassword: Function
    private setShowfeedbackPassword2: Function
    private setShowPassword1: Function
    private setShowPassword2: Function
    private showPassword1: boolean
    private showPassword2: boolean
    private t: any

    constructor(setShowfeedbackPassword: Function, setShowfeedbackPassword2: Function, setShowPassword1: Function, setShowPassword2: Function, showPassword1: boolean, showPassword2: boolean, t: any) {
        this.setShowfeedbackPassword = setShowfeedbackPassword
        this.setShowfeedbackPassword2 = setShowfeedbackPassword2
        this.setShowPassword1 = setShowPassword1
        this.setShowPassword2 = setShowPassword2
        this.showPassword1 = showPassword1
        this.showPassword2 = showPassword2
        this.t = t
    }

    ValidateNewPassword(values: BodyInterface) {
        let errors = {};

        if (!values.password) {
            errors['password'] = this.t('new_password.validate.fill_field');

            this.setShowfeedbackPassword(true);
        }
        else if (values.password.length > 0 && values.password.length <= 4) {
            errors['password'] = this.t('new_password.validate.password_min_length');
            this.setShowfeedbackPassword(true);
        } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(values.password)) {
            errors['password'] = this.t('new_password.validate.password_special_char');
            this.setShowfeedbackPassword(true);
        } else if (!/[A-Z]/.test(values.password)) {
            errors['password'] = this.t('new_password.validate.password_uppercase');
            this.setShowfeedbackPassword(true);
        } else if (!/[a-z]/.test(values.password)) {
            errors['password'] = this.t('new_password.validate.password_lowercase');
            this.setShowfeedbackPassword(true);
        } else if (!/\d/.test(values.password)) {
            errors['password'] = this.t('new_password.validate.password_number');
            this.setShowfeedbackPassword(true);
        }
        else {
            this.setShowfeedbackPassword(false);
        }

        if (!values.password2) {
            errors['password2'] = this.t('new_password.validate.fill_field');
            this.setShowfeedbackPassword2(true);

        } else if (values.password !== values.password2) {
            errors['password2'] = this.t('new_password.validate.password_not_match');
            this.setShowfeedbackPassword2(true);
        }
        else {
            this.setShowfeedbackPassword2(false);
        }
        return errors;
    }

    clickPassword() {
        if ((document.getElementById("user_password") as HTMLInputElement).type === "text") {
            (document.getElementById("user_password") as HTMLInputElement).type = "password";
        } else {
            (document.getElementById("user_password") as HTMLInputElement).type = "text";
        }

        this.setShowPassword1(!this.showPassword1);
    };

    clickPassword2() {
        if ((document.getElementById("user_password2") as HTMLInputElement).type === "text") {
            (document.getElementById("user_password2") as HTMLInputElement).type = "password";
        } else {
            (document.getElementById("user_password2") as HTMLInputElement).type = "text";
        }

        this.setShowPassword2(!this.showPassword2);
    };
}