import { FC, useEffect, useState } from "react";
import constsRouters from "../../../../../routes/constsRouter";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { setShowAlertFeedback, setShowModalUnifyConsumers } from "../../../../../store/internal";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../../../store/token";
import ModalUnifyConsumers from ".";
import { IModalUnifyConsumersController } from "./indexModal";
import { useTranslation } from "react-i18next";
import { excludedFieldsFromUnification } from "../viewAnalysisSimilarConsumers/indexModel";

const ModalUnifyConsumersController:FC<IModalUnifyConsumersController> = (props) => {
    const [showModal, setShowModal] = useState(props.visibility);
    // const [data, setData] = useState(undefined);
    const [currentConsumer, setCurrentConsumer] = useState(null);
    const [similarConsumers, setSimilarConsumers] = useState(undefined);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appRequester = new AppRequesterController();

    const { t } = useTranslation()

    const values = {
        token: {
            value: useSelector(getToken),
        }
    }

    useEffect(() => {
        if (props.currentConsumer && props.similarConsumers) {
            let consumerData = {};

            let fields = Object.keys(props.currentConsumer);

            fields.map((field, i_field) => {
                if (props.currentConsumer[field] !== null && !excludedFieldsFromUnification.includes(field))
                    consumerData[field] = props.currentConsumer[field];
            });

            setCurrentConsumer(consumerData);
            setSimilarConsumers(props.similarConsumers);
        }
    }, [props.currentConsumer, props.similarConsumers]);

    useEffect(() => {
        if (currentConsumer && similarConsumers && props.visibility)
            setShowModal(props.visibility);
    }, [props.visibility, currentConsumer, similarConsumers]); 

    const hide = () => {
        setShowModal(false);
        dispatch(setShowModalUnifyConsumers(false));
    }

    const gotoUnifyPage = (e) => {
        hide();
        navigate(constsRouters.routers.consumersViewAnalysisSimilar.path, { state: { similarConsumers: similarConsumers, currentConsumer: currentConsumer, originRedirectPage: props.originRedirectPage } });
    }

    const unifyConsumersConfirm = async (/* originRedirectPage, location */) => {
        if (currentConsumer) {
            // removendo name dos campos customizados
            currentConsumer['custom_fields'] = currentConsumer['custom_fields'].map((item) => {
                return ({
                    "custom_field_id": item.custom_field_id,
                    "value": item.value
                });
            });

            let JsonSend = {
              consumer: currentConsumer,
              idsConsumersDuplicates: similarConsumers.map((item) => { return item.id }),
            };
            if (location?.state?.currentConsumer?.consumer_id) {
                JsonSend.idsConsumersDuplicates.push(location?.state?.currentConsumer?.consumer_id)
            }

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + values.token.value,
            };

            let consumerUserId = null;

            await appRequester.Post(
                '/consumer/unification', JsonSend, { headers },
                (response: Object) => {return response;},
                (data: { data: { consumers: { user_id: string; name: string }[]; ConsumersDuplicates: any[] }; }) => {
                    dispatch(setShowAlertFeedback({ message: `${t('home_consumers.unification.modal_unify.unify_consumers_confirm.message')}`, visibility: true, signalIcon: true }));
                    // this.callbackModalCustomers(data.data.consumers[0].user_id);
                    consumerUserId = data.data.consumers[0].user_id;
                    hide();
                    
                    // retornando para tickets com o backup, exceto para página de consumidores
                    if (location?.state && location.state.originRedirectPage === "create_ticket") {
                        let createItemData = JSON.parse(localStorage.getItem("create_ticket_data"));
                        let requesterUser = Object.assign(data.data.consumers[0], {label: data.data.consumers[0].name, value: data.data.consumers[0].user_id});

                        localStorage.setItem('create_ticket_data', JSON.stringify(Object.assign(createItemData, { currentRequester_user: requesterUser })));
                        navigate(constsRouters.routers.createTicket.path, { state: { similarConsumers: undefined, currentConsumer: null, originRedirectPage: props.originRedirectPage } });
                    } else if (location?.state?.originRedirectPage === "edit_ticket") {
                        let editItemData = JSON.parse(localStorage.getItem("edit_ticket_data"));
                        let requesterUser = Object.assign(data.data.consumers[0], {label: data.data.consumers[0].name, value: data.data.consumers[0].user_id});

                        localStorage.setItem('edit_ticket_data', JSON.stringify(Object.assign(editItemData, { currentRequester_user: requesterUser })));
                        navigate(constsRouters.routers.tickets.path, { state: { similarConsumers: undefined, currentConsumer: null, originRedirectPage: props.originRedirectPage } });
                    } else if (location?.state?.originRedirectPage === "consumer_page") {
                        navigate(constsRouters.routers.consumers.path, { state: { originRedirectPage: props.originRedirectPage } });
                    } else if (location?.state?.originRedirectPage === "view_consumer_page") {
                        navigate(constsRouters.routers.consumersView.path, { state: Object.assign({ originRedirectPage: props.originRedirectPage }, data.data.consumers[0]) });
                    }
                },
                (error: { response: { status: number; data: { message: string; }; }; }) => {
                    console.log(error);
                    if (error.response.status === 400) {
                        dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                    } /* else if (error.response.status === 422) {
                        this.dispatch(setShowAlertFeedback({ message: "O campo de observações deve ter no máximo 100 caracteres.", visibility: true, signalIcon: false }));
                    }  */else {
                        dispatch(setShowAlertFeedback({ message: `${t('home_consumers.unification.modal_unify.unify_consumers_confirm.error')}`, visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, () => {}
            ).finally(() => {
                if (consumerUserId)
                    props.callbackModalCustomers(consumerUserId);
            });
        }
    }

    const consumerFieldName = (field: string) => {
        let name;
        switch (field) {
            case 'name':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.name')}`;
                break;
            case 'document_number':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.document_number')}`;
                break;
            case 'email':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.email')}`;
                break;
            case 'phone':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.phone')}`;
                break;
            case 'whatsapp':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.whatsapp')}`;
                break;
            case 'instagram':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.instagram')}`;
                break;
            case 'twitter':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.twitter')}`;
                break;
            case 'facebook':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.facebook')}`;
                break;
            case 'youtube':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.youtube')}`;
                break;
            case 'address':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.address')}`;
                break;
            case 'observations':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.observations')}`;
                break;
            case 'webchat':
                name = `${t('home_consumers.unification.modal_unify.consumer_field_name.webchat')}`;
                break;
            default:
                name = field;
                break;
        }

        return name;
    }

    return <ModalUnifyConsumers 
        showModal={showModal}
        hide={hide}
        gotoUnifyPage={gotoUnifyPage}
        consumerFieldName={consumerFieldName}
        unifyConsumersConfirm={unifyConsumersConfirm} 
        originRedirectPage={props.originRedirectPage} 
        currentConsumer={currentConsumer}
        t={t}
    />
}

export default ModalUnifyConsumersController;