import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Channels } from "../../../../core/enums/channels"
import { setShowAlertFeedback, setShowConfirmationModal } from '../../../../store/internal'
import QuickMessageUpdate from '.'
import { useTranslation } from "react-i18next"
import { Spinner } from "react-bootstrap"
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController"
import { TokenInterface } from "../../../../services/requestsInterfacesModel"
import { getToken } from "../../../../store/token"
import { useDropzone } from "react-dropzone"
// import UserService from '../../../../services/user-service'
// import { setShowAlertFeedback } from '../../../../store/internal'

const QuickMessageUpdateController = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const [title, setTitle] = useState("")
    // const [message, setMessage] = useState("")

    const [validated] = useState(false)
    const [feedbackTitle, setShowFeedbackTitle] = useState(false)
    const [feedbackMessage, setShowFeedbackMessage] = useState(false)
    const [isvideo, setIsVideo] = useState(false)
    const [/*isLoading*/, setIsLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [fileIds, setFileIds] = useState(props?.data?.quick_message_attachment ? [{ id: props.data?.quick_message_attachment?.split('/')?.pop(), name: props.data?.quick_message_attachment?.split('/')?.pop() }] : []);
    const [state, setState] = useState("");
    const [fileLoading, setFileLoading] = useState([]);
    const [disabled, setDisabled] = useState(false)

    const [/*selectedChannel*/, setSelectedChannel] = useState([])
    const [/*selectedPhoneNumber*/, setSelectedPhoneNumber] = useState([])

    // const [optionsChannel, setOptionsChannel] = useState([
    //     { label: "E-mail", value: Channels.Email },
    //     { label: "WhatsApp", value: Channels.WhatsApp },
    //     { label: "Webchat", value: Channels.Webchat },
    //     { label: "Facebook", value: Channels.Facebook },
    //     { label: "Instagram", value: Channels.Instagram },
    //     { label: "Telefonia", value: Channels.Telefonia }
    // ])

    const [currentChannel, setCurrentChannel] = useState("")
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState("")
    const [currentTitle, setCurrentTitle] = useState("")
    const [currentMessage, setCurrentMessage] = useState("")

    // const [selectChannel, setOnSelectChannel] = useState([])
    // const [selectPhoneNumber, setOnSelectPhoneNumber] = useState([])

    const [/*optionsPhoneNumber*/, setOptionsPhoneNumber] = useState([])

    const { t } = useTranslation()

    const AppRequesterConst = new AppRequesterController()

    const values: TokenInterface = {
      token: {
        value: useSelector(getToken)
      },
    };

    useEffect(() => {
        if (props.data != null) {
            setCurrentTitle(props?.data?.title != null ? props?.data?.title : '')
            setCurrentMessage(props?.data?.message != null ? props?.data?.message : '')
            setCurrentChannel(props?.data?.channel_id != null ? props?.data?.channel_id : '')
            setCurrentPhoneNumber(props?.data?.phone_number != null ? props?.data?.phone_number : '')
        }

        if (props.data != null) {
            switch (props.data.channel_id) {
                case Channels.Email:
                    setSelectedChannel([{ label: t('configurations_ticket.modal_quick_message_update.email'), value: Channels.Email }])
                    break;
                case Channels.Facebook:
                    setSelectedChannel([{ label: t('configurations_ticket.modal_quick_message_update.facebook'), value: Channels.Facebook }])
                    break
                case Channels.Instagram:
                    setSelectedChannel([{ label: t('configurations_ticket.modal_quick_message_update.instagram'), value: Channels.Instagram }])
                    break
                case Channels.Telefonia:
                    setSelectedChannel([{ label: t('configurations_ticket.modal_quick_message_update.callcenter'), value: Channels.Telefonia }])
                    break
                case Channels.Webchat:
                    setSelectedChannel([{ label: t('configurations_ticket.modal_quick_message_update.webchat'), value: Channels.Webchat }])
                    break
                case Channels.WhatsApp:
                    setSelectedChannel([{ label: t('configurations_ticket.modal_quick_message_update.whatsapp'), value: Channels.WhatsApp }])
                    break
                default:
                    break
            }
        }

        if (props.data != null) {
            setSelectedPhoneNumber(props?.listWhatsapp ? props?.listWhatsapp.filter(item => item?.phone_number == props?.data?.phone_number).map(item => {
                return { label: item?.phone_number, value: item?.phone_number }
            }) : [])
        }

    }, [props.data, setCurrentTitle, setCurrentMessage, setCurrentPhoneNumber])

    useEffect(() => {
        setOptionsPhoneNumber(props?.listWhatsapp ? props?.listWhatsapp?.map((item) => {
            return { label: item.phone_number, value: item.subaccount_name }
        }) : [])

    }, [props?.listWhatsapp])

    useEffect(() => {
        if (props.data != null) {
            setCurrentTitle(props?.data?.title != null ? props?.data?.title : '')
            setCurrentMessage(props?.data?.message != null ? props?.data?.message : '')
            setCurrentChannel(props?.data?.channel_id != null ? props?.data?.channel_id : '')
            setCurrentPhoneNumber(props?.data?.phone_number != null ? props?.data?.phone_number : '')
            setShowFeedbackTitle(false)
            setShowFeedbackMessage(false)
        }

        if (props?.modalShow === false) {
            clearFields()
        }

    }, [props?.modalShow])

    useEffect(() => {
        if (props?.data != null) {
            props.data?.quick_message_attachment && setFiles([{file_id: props.data?.quick_message_attachment, name: props.data?.quick_message_attachment?.split('/')?.pop(), isLoading: true, path: props.data?.quick_message_attachment?.split('/')?.pop()}])
            props.data?.quick_message_attachment && setFileIds([{ id: props.data?.quick_message_attachment, name: props.data?.quick_message_attachment?.split('/')?.pop() }])
        }
    }, [props.data])

    // const selectChannelId = (value: string | any) => {
    //     if (value.length > 0) {
    //         if (value[value.length - 1].value === "NA") {
    //             setOnSelectChannel([])
    //         } else {
    //             setOnSelectChannel([value[value.length - 1]])
    //         }
    //     } else {
    //         setOnSelectChannel([])
    //     }
    // }

    // const selectPhoneNumberFinal = (value: string | any) => {
    //     if (value?.length > 0) {
    //         if (value[value?.length - 1].value === "NA") {
    //             setOnSelectPhoneNumber([])
    //         } else {
    //             setOnSelectPhoneNumber([value[value?.length - 1]])
    //         }
    //     } else {
    //         setOnSelectPhoneNumber([])
    //     }
    // }

    const handleFileDownload = (file) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.target = '_blank';
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const showFiles = files.map((file, index) => (
        <span key={index}
            style={{
                background: '#E8E8E8 0% 0% no-repeat padding-box',
                borderRadius: '3px',
                opacity: '1',
                marginRight: '0.5vw',
                marginBottom: '1vh',
                whiteSpace: 'pre', display: 'inline-block'
            }}
        >
            <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
                <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </svg>
            <span
                onClick={(e) => { e.stopPropagation(); handleFileDownload(file) }}
                style={{
                    textAlign: 'left',
                    font: 'var(--px10_13-font-Roboto)',
                    letterSpacing: '0px',
                    color: '#1E1E1E',
                    opacity: '1',
                }}
            >
                {file.name}
            </span>
            {file.isLoading ?
                <svg name={file.name} onClick={(e) => { e.stopPropagation(); deleteFile(file.name) }} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
                    <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
                    <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </svg>
                :
                <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
                </Spinner>
            }
        </span>
    ));

    const deleteFile = (fileName: any[]) => {
        let newFiles = files.filter((item) => item.path !== fileName);
        acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
        fileIds.forEach((item) => {
            if (item.name == fileName) {
                fileIds.splice(fileIds.indexOf(item), 1)
            }
        });
        // setIsClicked(false);
        setFiles(newFiles);
    }

    const uploadFile = (file, sameName) => {
        setDisabled(true)
        const formData = new FormData();

        formData.append("file", file[0]);
        // formData.append("chanel_id", selected_channel[0].value)

        const headers = {
            "Content-Type": "multipart/form-data; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        AppRequesterConst.Post(
            'template-message/quick-message/upload/attachment', formData, { headers },
            (response: Object) => {
                return response;
            },
            (data: { data: { id: string; name: string }; }) => {
                if (sameName) {
                    dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_info.changed_file"), visibility: true, signalIcon: true }));
                    setIsVideo(false)
                } else {
                    dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_info.uploaded_file"), visibility: true, signalIcon: true }));
                    setIsVideo(false)
                }
                setFileId(data.data.id, data.data.name);
                setDisabled(false)
            },
            (error: { response: { data: { message: any[]; }; }; }) => {
                if (error.response.data.message) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }));
                }
                setDisabled(false)
            }, navigate, dispatch, setIsLoading,
        );
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxSize: 15728640,
        multiple: false,
        onDrop: (file) => {
            let sameName = false;
            fileIds.map((item) => {
                if (item.name === file[0]?.name) {
                    sameName = true;
                }
            });
            if (sameName) {
                dispatch(setShowConfirmationModal({
                    visibility: true, text: { "title": t("configurations.chatbots.view.bot_info.change_name"), "body": t("configurations.chatbots.view.bot_info.change_body"), "buttonConfirmationText": t("configurations.chatbots.view.bot_info.yes"), "buttonReturnText": t("configurations.chatbots.view.bot_info.no") },
                    functionConfirmation: () => {
                        file.map((item) => {
                            uploadFile([item], sameName);
                        })
                    }
                }))
            } else {
                file.map((item) => {
                    uploadFile([item], sameName);
                })
            }

        },
        onDropRejected: (reject) => {
            if (reject[0].errors[0].code === "file-too-large") {
              dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.file_length'), signalIcon: false }));
              setIsVideo(false);
            } else if (reject[0].errors[0].code === "file-invalid-type" && reject[0].file.type.includes("video")) {
                setIsVideo(true);
            }
        },
        accept: {
        //   'video/mpeg': ['.mpeg'],
        //   'video/mp4': ['.mp4'],
          'audio/mpeg': ['.mpeg', '.mp3'],
          'audio/aac': ['.aac'],
          'audio/amr': ['.amr'],
          'image/jpeg': ['.jpeg'],
          'image/png': ['.png'],
          'text/plain': ['.txt'],
          'text/csv': ['.csv'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        disabled: files.length >= 1,
    });

    useEffect(() => {

        let allFiles = files.concat(acceptedFiles);

        allFiles = [...new Map(allFiles.map(file => [file.name, file])).values()];

        allFiles.map(file => {
            const returnedTarget = Object.assign(file, { isLoading: true });
            file = returnedTarget;
        });

        setFiles(allFiles);

    }, [acceptedFiles, state]);

    useEffect(() => {
        addFileId();
    }, [])

    const setFileId = (id: string, name: string) => {
        let hasName = false
        fileIds.forEach((item) => {
            if (item.name === name) {
                item.id = id
                hasName = true
                fileLoading.splice(fileIds.indexOf(item), 1)
                setState("Editado")
                if (state === "Editado") {
                    setState("")
                }
            }
        });
        if (hasName === false) {
            fileIds.push({ id: id, name: name });
        }
    }

    const addFileId = () => {
        let array = []
        array = files.filter((item) => {
            return item && item.id
        })
        array.map((item) => {
            fileIds.push({ id: item.id, name: item.name });
        })
    }

    const clearFields = () => {
        setCurrentChannel("")
        setCurrentPhoneNumber("")
        setCurrentTitle("")
        setCurrentMessage("")
        setFiles([])
        setFileIds([])
        setDisabled(false)
    }

    return <QuickMessageUpdate
        props={props}
        setShowFeedbackTitle={setShowFeedbackTitle}
        setShowFeedbackMessage={setShowFeedbackMessage}
        feedbackTitle={feedbackTitle}
        feedbackMessage={feedbackMessage}
        validated={validated}
        setIsLoading={setIsLoading}
        currentChannel={currentChannel}
        currentPhoneNumber={currentPhoneNumber}
        currentTitle={currentTitle}
        currentMessage={currentMessage}
        setCurrentChannel={setCurrentChannel}
        setCurrentPhoneNumber={setCurrentPhoneNumber}
        setCurrentTitle={setCurrentTitle}
        setCurrentMessage={setCurrentMessage}
        navigate={navigate}
        dispatch={dispatch}
        setShowAlertFeedback={setShowAlertFeedback}
        t={t}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        showFiles={showFiles}
        isVideo={isvideo}
        fileIds={fileIds}
        disabled={disabled}
        setDisabled={setDisabled}
    />

}

export default QuickMessageUpdateController;