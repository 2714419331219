import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button,
} from "react-bootstrap"
import allStyles from "./styles"
import ListConfigInstagramController from "./listConfigInstagram/indexController"
import ModalRegisterInstagramPageController from "../modalRegisterInstagram/indexController"

const FormConfigInstagram = ({
    showModal,
    handleCloseModal,
    handleOpenModal,
    data,
    updateListAfterDelete,
    cnpj,
    getInstagramById,
    isEditAllowed,
}) => {

    return (
        <>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                    className="mt-2"
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Configurações do Instagram
                    </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Row style={allStyles.textFontGlay} className="d-flex justify-content-flex-start mt-2" >
                {
                    isEditAllowed &&
                    <Col sm={true} md={3}>
                        <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                            <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                        Adicionar página
                                    </Tooltip>
                                }
                            >
                                <Button size="lg" className="buttonWhite" style={{ width: '100%', marginTop: '1.5vh' }} onClick={handleOpenModal}>Adicionar página</Button>
                            </OverlayTrigger>
                        </p>
                    </Col>
                }
            </Row>
            <ModalRegisterInstagramPageController
                show={showModal}
                handleClose={handleCloseModal}
                cnpj={cnpj}
                getInstagramById={getInstagramById}
            />
            <ListConfigInstagramController datas={data} updateListAfterDelete={updateListAfterDelete} HasMoreInformation={false} cnpj={cnpj} getInstagramById={getInstagramById} isEditAllowed={isEditAllowed} />

        </>
    )
}

export default FormConfigInstagram