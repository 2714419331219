import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useTicketDelete = () => {

    const socket = useContext(SocketContext);

    const [ticketDeleted, setTicketDeleted] = useState<any>()

    useEffect(() => {

        socket.socket?.on('ticket-deleted', (data) => setTicketDeleted(data))

        return () => {
            socket.socket?.off('ticket-deleted', setTicketDeleted)
        }

    }, [socket.socket?.active])

    return { ticketDeleted }

}

export default useTicketDelete