import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useTicketUpdate = () => {

    const socket = useContext(SocketContext);

    const [ticketUpdate, setTicketUpdate] = useState<any>()

    useEffect(() => {
        socket.socket?.on('ticket-update', (data) => setTicketUpdate(data.ticket ? data.ticket : data))

        return () => {
            socket.socket?.off('ticket-update', setTicketUpdate)
        }
    }, [socket.socket?.active])

    return { ticketUpdate }
}

export default useTicketUpdate