import { useEffect, useState } from "react";
import { Button, Popover } from "react-bootstrap";
import ControlPanelAccounts from ".";
import { IconFileText, IconFileUnknown, IconKey, IconUserCog, IconUserEdit, IconBuildingWarehouse, IconEye } from "@tabler/icons-react";
import { AppRequesterController } from "../../../services/appRequester/appRequesterController";
import { TokenInterfaceControlPanel } from "../../../services/requestsInterfacesModel";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../store/token";
import { useNavigate } from "react-router-dom";
import { setShowAccountEmailConfirmationModal, setShowAccountInvalidApp2FAModal, setShowEditUserPermissionsModal } from "../../../store/internal_controlpanel";
import { ControlPanelAccountInterface } from "./indexModel";
import constsApi from "../../../services/constsApi";
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../store/internal";
import UserService from "../../../services/user-service";
import { getUserControlPanelPermissions } from "../../../store/user_controlpanel";

const AppRequesterConst = new AppRequesterController();

const ControlPanelAccountsController = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<ControlPanelAccountInterface>(null);
  const userPermissions: { id: string, created_at: string }[] = useSelector(getUserControlPanelPermissions);

  const permissions = {
    master: {
      id: constsApi.permissions_controlpanel.master,
      name: 'Administrador geral',
      icon: <IconUserCog stroke={1.5} />
    },
    contract: {
      id: constsApi.permissions_controlpanel.contract,
      name: 'Configurar contrato',
      icon: <IconFileText stroke={1.5} />
    },
    company: {
      id: constsApi.permissions_controlpanel.company,
      name: 'Configurar empresa',
      icon: <IconBuildingWarehouse stroke={1.5} />
    },
    keys: {
      id: constsApi.permissions_controlpanel.keys,
      name: 'Configurar páginas/chaves',
      icon: <IconKey stroke={1.5} />
    },
    agents: {
      id: constsApi.permissions_controlpanel.agents,
      name: 'Configurar agentes',
      icon: <IconUserEdit stroke={1.5} />
    },
    read_all: {
      id: constsApi.permissions_controlpanel.read_all,
      name: 'Somente visualização',
      icon: <IconEye stroke={1.5} />
    }
  };

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  const getPermission = (permission_id: string): { id: String, name: String, icon: any } => {
    switch (permission_id) {
      case permissions.master.id:
        return permissions.master;
      case permissions.contract.id:
        return permissions.contract;
      case permissions.company.id:
        return permissions.company;
      case permissions.keys.id:
        return permissions.keys;
      case permissions.agents.id:
        return permissions.agents;
      case permissions.read_all.id:
        return permissions.read_all;
      default:
        return { id: 'null', name: 'null', icon: <IconFileUnknown stroke={1.5} /> };
    }
  }

  const getUsers = async () => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Get(
        '/panel/credentials', { headers },
        (response: Object) => {
        },
        (response: any) => {
          setUsers(response?.data?.users);
        },
        (error: { response: { status: number } }) => {
          if (error.response.status === 400) {

          }
          if (error.response.status === 401) {

          }
        }, navigate, dispatch, setIsLoading, { }
    );
  }

  useEffect(() => {
    getUsers();
  }, []);

  const openModal = (modal: string) => {
    switch (modal) {
      case 'EditPermissions':
        dispatch(setShowEditUserPermissionsModal(true));
        break;
      case 'EmailConfirmation':
        dispatch(setShowAccountEmailConfirmationModal(true));
        break;
      case 'InvalidApp2FA':
        dispatch(setShowAccountInvalidApp2FAModal(true));
        break;
      default:
        break;
    }
  }

  const callbackAccounts = () => {
    setUsers([])
    getUsers()
  }

  const deleteAccountConfirmation = () => {
    dispatch(setShowConfirmationModal({
      visibility: true, text: { "title": 'Excluir conta', "body": 'Deseja realmente excluir essa conta?', 'id': '', "buttonReturnText": 'Cancelar', "buttonConfirmationText": 'Excluir' },
      functionConfirmation: deleteAccount
    }))
  }

  const deleteAccount = async () => {
    const headers = UserService.getHeaders();

    const config = {
      headers: headers,
      data: {
        id: currentUser?.id
      }
    }

    await AppRequesterConst.Delete(
      'panel/credentials', config,
      (response: Object) => { },
      (data: Object) => {
        dispatch(setShowAlertFeedback({ message: 'Conta deletada com sucesso', signalIcon: true, visibility: true }))
        callbackAccounts()
      },
      (error) => {
        dispatch(setShowAlertFeedback({ message: 'Erro ao deletar conta', signalIcon: false, visibility: true }))
      },
      navigate,
      dispatch,
      setIsLoading,
      () => { },
    );
  }
  
  const popover = (
    <Popover  style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
      <Popover.Body className="nopadding">
        <Button onClick={() => openModal('EditPermissions')} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Alterar permissões</Button>
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
        <Button onClick={() => openModal('EmailConfirmation')} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Emaill de confirmação</Button>
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
        <Button onClick={(e) => openModal('InvalidApp2FA')} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Invalidar aplicativo multifator</Button>
        {userPermissions[0].id === constsApi.permissions_controlpanel.master && 
          <>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
            <Button onClick={deleteAccountConfirmation} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir conta</Button>
          </>
        }
      </Popover.Body>
    </Popover>
  );

  return (
    <ControlPanelAccounts 
      users={users}
      popover={popover}
      isLoading={isLoading}
      permissions={permissions}
      getPermission={getPermission}
      currentUser={currentUser}
      setCurrentUser={setCurrentUser}
      getUsers={getUsers}
    />
  );

}

export default ControlPanelAccountsController;
