import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getProfileId } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";
import { useTranslation } from "react-i18next";
import TriageTab from ".";
import { useState } from "react";
import { setShowCreateTriageModal } from "../../../../../store/internal";

const TriageTabController = () => {

    const values: TokenInterface = {
        user: {
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
    };

    const [/*isLoading*/, setIsLoading] = useState(false);
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [refreshTriage, setRefreshTriage] = useState(false);


    const callbackModalTriage = () => {
        setRefreshTriage(true);
    }

    const showCreateTriage = () => {
        dispatch(setShowCreateTriageModal(true));
    }

    return <TriageTab
        values={values}
        t={t}
        callbackModalTriage={callbackModalTriage}
        showCreateTriage={showCreateTriage}
        setIsLoading={setIsLoading}
        refreshTriage={refreshTriage}
        setRefreshTriage={setRefreshTriage}
    />;
}

export default TriageTabController;