import React, { FC } from "react";

import allStyles from "./styles";

import { Modal, Row, Container, Col, Button, Form, InputGroup, FormControl, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';

import MaskController from "../../../../core/shared/mask-controller";

import SelectComponentController from "../selectComponent/indexController";
import LocalizedPhoneInputController from "../localizedPhoneInput/indexController";
import { ModalEditCunsumersInterface } from "./indexModel";
import TagsComponent from "../tagsComponent";


const ModalEditConsumers: FC<ModalEditCunsumersInterface> = (props) => {
  return (
    <Modal
      show={props.modalShow}
      onHide={props.hide}
      aria-labelledby=""
      size="lg"
      centered={true}
    >
      <Modal.Header
        closeButton
        style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}
      >
        <Modal.Title style={allStyles.textBlack}>
          {props.t('home_consumers.modal_edit.edit_data')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}
      >
        <Container>
          <Formik
            initialValues={{
              name: props.customersName,
              email: props.customersEmail,
              cpf: props.customersCpf,
              phone: props.customersPhone,
              address: props.customersAddress,
              observations: props.customersObservations,
              whatsapp: props.customersWhatshapp,
              instagram: props.customers_instagram,
              twitter: props.customersTwitter,
              facebook: props.customers_facebook,
              youtube: props.customersYoutube,
              input: props.textareaStates,
              textarea: props.textareaStates,
            }}
            validate={props.validate}
            onSubmit={props.onSubmit}
          >
            {({
              values,
              setValues,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={props.validated}>
                <Row
                  style={allStyles.textFontGlay}
                  className=" d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="name">
                      {props.t('home_consumers.modal_edit.form_name.name')}
                      <span
                        style={{
                          font: "normal bold normal calc(0.2em + 0.7vw) Montserrat",
                          color: "black",
                        }}
                      >
                        *
                      </span>
                    </Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={props.showFeedbackName}
                        placeholder={props.t('home_consumers.modal_edit.form_name.placeholder')}
                        aria-label={props.t('home_consumers.modal_edit.form_name.placeholder')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_name.placeholder')}
                        size="lg"
                        type="name"
                        id="customers_name"
                        name="name"
                        autoComplete='given-name'
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      ></FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackname">
                        {errors.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="email">{props.t('home_consumers.modal_edit.form_email.email')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={props.showFeedbackEmail}
                        placeholder={props.t('home_consumers.modal_edit.form_email.placeholder')}
                        aria-label={props.t('home_consumers.modal_edit.form_email.placeholder')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_email.placeholder')}
                        size="lg"
                        type="email"
                        id="customers_email"
                        name="email"
                        autoComplete='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      ></FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackemail">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className=" d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{props.t('defaults.cpfcnpj_nif')}</Form.Label>
                    <InputMask
                      mask={MaskController.maskCpfCnpj(values.cpf, props.returnLanguage())}
                      maskChar={MaskController.maskCharCpfCnpj(values.cpf, props.returnLanguage())}
                      value={values.cpf}
                      onChange={handleChange}
                    >
                      {(inputProps: any) => (
                        <InputGroup>
                          <FormControl
                            isInvalid={props.showFeedbackCpfCnpj}
                            className="form-control-Default"
                            placeholder={`${props.t('home_consumers.modal_edit.form_document_number.placeholder_a')} ${props.t('defaults.cpfcnpj_nif')} ${props.t('home_consumers.modal_edit.form_document_number.placeholder_b')}`}
                            aria-label={`${props.t('home_consumers.modal_edit.form_document_number.placeholder_a')}${props.t('defaults.cpfcnpj_nif')} ${props.t('home_consumers.modal_edit.form_document_number.placeholder_b')}`}
                            aria-describedby={`${props.t('home_consumers.modal_edit.form_document_number.placeholder_a')} ${props.t('defaults.cpfcnpj_nif')} ${props.t('home_consumers.modal_edit.form_document_number.placeholder_b')}`}
                            type="text"
                            size="lg"
                            id="customers_cpf"
                            name="cpf"
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            id="feedbackcpf"
                          >
                            {errors.cpf}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    </InputMask>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.form_phone.phone')}</Form.Label>
                    {/* <PhoneInputDropdown phoneNumber={values.phone} values={values} setValues={setValues} type={PhoneType.Phone} tag={phoneTag} setPhoneError={setPhoneError} /> */}
                    <LocalizedPhoneInputController
                      placeholder={props.t('home_consumers.modal_edit.form_phone.placeholder')}
                      value={values.phone}
                      onChange={(e) => props.setCustomersPhone(e)}
                      handleBlur={handleBlur}
                      phoneBorder={props.phoneBorder}
                    />
                    <div className='invalid-feedback' style={{ display: props.showfeedbackPhone ? "block" : "none", height: '0.75rem' }}>
                      {props.t('home_consumers.modal_edit.form_phone.invalid_number')}
                    </div>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className=" d-flex justify-content-center"
                >
                  <Col md={12}>
                    <Form.Label htmlFor="address">{props.t('home_consumers.modal_edit.form_address.address')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={props.showfeedback}
                        placeholder={props.t('home_consumers.modal_edit.form_address.placeholder')}
                        aria-label={props.t('home_consumers.modal_edit.form_address.placeholder')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_address.placeholder')}
                        size="lg"
                        type="text"
                        id="customers_address"
                        name="address"
                        autoComplete='street-address'
                        maxLength={100}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackaddress"
                      >
                        {errors.address}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  className="d-flex justify-content-center"
                  style={{ height: "10vh" }}
                >
                  <Col md={12} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.form_observations.observations')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default-text-area"
                        style={{ height: '10vh' }}
                        isInvalid={props.showfeedback}
                        as="textarea"
                        rows={10}
                        placeholder={props.t('home_consumers.modal_edit.form_observations.placeholder')}
                        aria-label={props.t('home_consumers.modal_edit.form_observations.placeholder')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_observations.placeholder')}
                        size="lg"
                        type="text"
                        id="customers_observations"
                        name="observations"
                        maxLength={200}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.observations}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackobservations"
                      >
                        {errors.observations}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col md={12}>
                    <Form.Label
                      style={allStyles.textBlackSmaller}
                      htmlFor="basic-url"
                    >
                      {props.t('home_consumers.modal_edit.aditional_infos')}
                    </Form.Label>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                  <Col sm={true} >
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.associate_agent')}</Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      options={props.optionsAgents && props.optionsAgents.length > 0 ? props.optionsAgents : []}
                      setOptions={props.setOptionsAgents}
                      selecteds={props.selectedAgent}
                      setSelecteds={props.setSelectedAgent}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={props.t('home_consumers.modal_edit.select_agent')}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.selectedAgent[0] ? props.selectedAgent[0].label : "",
                          "clearSearch": props.t('home_consumers.modal_edit.clearSearch'),
                          "clearSelected": props.t('home_consumers.modal_edit.clearSelected'),
                          "noOptions": props.t('home_consumers.modal_edit.noOptions'),
                          "search": props.t('home_consumers.modal_edit.search'),
                          "selectAll": props.t('home_consumers.modal_edit.selectAll'),
                          "selectAllFiltered": props.t('home_consumers.modal_edit.selectAllFiltered'),
                          "selectSomeItems": props.t('home_consumers.modal_edit.selectSomeItems'),
                          "create": props.t('home_consumers.modal_edit.create'),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                      disabled={false}
                    />
                  </Col>
                </Row>

                {/* TAGS */}
                <Row style={allStyles.textFontGlay}
                  className="d-flex justify-content-center">
                    <Col sm={true}>
                      <Col style={{marginBottom:'1vh'}}>
                          {props.t("home_tickets.home_create_ticket.tags_field_label")}
                      </Col>
                      <Col>
                          <TagsComponent
                              tags={props.listTags} currentTags={props.customers_tags && props.customers_tags[0] !== null ? props.customers_tags : []} setCurrentTags={props.selectTags} ></TagsComponent>
                      </Col>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center" style={{ marginTop: '-2rem' }}>
                  <Col md={12}>
                    <Form.Label
                      style={allStyles.textBlackSmaller}
                      htmlFor="basic-url"
                    >
                      {props.t('home_consumers.modal_edit.form_social_media.social_media')}
                    </Form.Label>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.form_social_media.whatsapp')}</Form.Label>
                    {/* <PhoneInputDropdown type={PhoneType.WhatsApp} phoneNumber={values.whatsapp} values={values} setValues={setValues} tag={whatsappTag} setPhoneError={setWhatsappError} /> */}
                    <LocalizedPhoneInputController
                      placeholder={props.t('home_consumers.modal_edit.form_social_media.placeholder_whatsapp')}
                      value={values.whatsapp}
                      onChange={(e) => props.setCustomersWhatshapp(e)}
                      handleBlur={handleBlur}
                      phoneBorder={props.whatsappBorder}
                    />
                    <div className='invalid-feedback' style={{ display: props.showfeedbackWhatsApp ? "block" : "none", height: '0.75rem' }}>
                      {props.t('home_consumers.modal_edit.form_social_media.invalid_number')}
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.form_social_media.instagram')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        //disabled={true}
                        className="form-control-Default"
                        isInvalid={props.showfeedback}
                        placeholder={props.t('home_consumers.modal_edit.form_social_media.placeholder_instagram')}
                        aria-label={props.t('home_consumers.modal_edit.form_social_media.placeholder_instagram')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_social_media.placeholder_instagram')}
                        size="lg"
                        type="text"
                        id="customers_instagram"
                        name="instagram"
                        maxLength={30}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.instagram}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackinstagram"
                      >
                        {errors.instagram}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                    <Col md={6}>
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.form_social_media.twitter')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={props.showfeedback}
                        placeholder={props.t('home_consumers.modal_edit.form_social_media.placeholder_twitter')}
                        aria-label={props.t('home_consumers.modal_edit.form_social_media.placeholder_twitter')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_social_media.placeholder_twitter')}
                        size="lg"
                        type="text"
                        id="customers_Twitter"
                        name="twitter"
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.twitter}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackTwitter"
                      >
                        {errors.twitter}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.form_social_media.facebook')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        //disabled={true}
                        className="form-control-Default"
                        isInvalid={props.showfeedback}
                        placeholder={props.t('home_consumers.modal_edit.form_social_media.placeholder_facebook')}
                        aria-label={props.t('home_consumers.modal_edit.form_social_media.placeholder_facebook')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_social_media.placeholder_facebook')}
                        size="lg"
                        type="text"
                        id="customers_facebook"
                        name="facebook"
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.facebook}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackfacebook"
                      >
                        {errors.facebook}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{props.t('home_consumers.modal_edit.form_social_media.youtube')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={props.showfeedback}
                        placeholder={props.t('home_consumers.modal_edit.form_social_media.placeholder_youtube')}
                        aria-label={props.t('home_consumers.modal_edit.form_social_media.placeholder_youtube')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_social_media.placeholder_youtube')}
                        size="lg"
                        type="text"
                        id="customers_youtube"
                        name="youtube"
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.youtube}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackyoutube"
                      >
                        {errors.youtube}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                  </Col>
                </Row>
                <Col md={12} style={{ marginTop: '-2rem' }}>
                  <Form.Label
                    style={allStyles.textBlackSmallerCustom}
                    htmlFor="basic-url"
                    onClick={props.toggleContent}
                  >
                    {props.t('home_consumers.modal_edit.custom_fields')}
                    <span style={{ marginLeft: '3px', transition: 'transform 0.3s', color: '#4a69bd', fontSize: '15px' }}>
                      {props.showContent ?
                        <svg id="Grupo_11467" data-name="Grupo 11467" xmlns="http://www.w3.org/2000/svg" width="22.383" height="22.383" viewBox="0 0 16.383 16.383">
                          <path id="Caminho_9741" data-name="Caminho 9741" d="M0,16.383H16.383V0H0Z" fill="none" />
                          <path id="Caminho_9742" data-name="Caminho 9742" d="M6,13.1,10.1,9l4.1,4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </svg> :
                        <svg id="Grupo_11489" data-name="Grupo 11489" xmlns="http://www.w3.org/2000/svg" width="22.383" height="22.383" viewBox="0 0 16.383 16.383">
                          <path id="Caminho_9741" data-name="Caminho 9741" d="M0,0H16.383V16.383H0Z" fill="none" />
                          <path id="Caminho_9742" data-name="Caminho 9742" d="M6,9l4.1,4.1,4.1-4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </svg>
                      }
                    </span>
                  </Form.Label>
                  {props.showContent && (
                    (props.customFields !== null && props.customFields !== undefined && props.customFields.length > 0 ? props.customFields.map((customField) => {
                      if (customField !== undefined || customField !== null) {
                        switch (customField.type) {
                          case "d31ebd9e-af9f-4559-b04a-729439218a6f": //text
                            return (<>
                              <Col md={12} style={allStyles.textFontGlay}>
                                <Form.Label htmlFor="basic-url">{customField.name}</Form.Label>
                                <InputGroup>
                                  <FormControl
                                    className="form-control-Default"
                                    isInvalid={props.showfeedback}
                                    placeholder={customField.placeholder}
                                    aria-label={customField.placeholder}
                                    aria-describedby={customField.placeholder}
                                    size="lg"
                                    type="text"
                                    id="customers_input"
                                    name="input"
                                    maxLength={50}
                                    onChange={(e) => props.changeCustomField(customField, e)}
                                    onBlur={handleBlur}
                                    value={props.textareaStates[customField.id]}
                                  ></FormControl>
                                  <Form.Control.Feedback
                                    type="invalid"
                                    id="feedbackinput"
                                  >
                                    {errors.input}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </>

                            )
                          case "2982a738-4de3-440a-8ed1-cc1fdb51bc9b": //textarea
                            return (<>
                              <Col md={12} style={allStyles.textFontGlay}>
                                <Form.Label htmlFor="basic-url">{customField.name}</Form.Label>

                                <InputGroup>
                                  <FormControl
                                    className="form-control-Default-text-area"
                                    style={{ height: '10vh' }}
                                    isInvalid={props.showfeedback}
                                    as="textarea"
                                    rows={10}
                                    placeholder={customField.placeholder}
                                    aria-label={customField.placeholder}
                                    aria-describedby={customField.placeholder}
                                    size="lg"
                                    type="text"
                                    id={customField.id}
                                    name="textarea"
                                    maxLength={500}
                                    onChange={(e) => props.changeCustomField(customField, e)}
                                    onBlur={handleBlur}
                                    value={props.textareaStates[customField.id]}
                                  ></FormControl>
                                  <Form.Control.Feedback
                                    type="invalid"
                                    id="feedbacktextarea"
                                  >
                                    {errors.textarea}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </>)
                        }
                      }
                    }) : 
                      <Col>
                        <span style={allStyles.textFontGlay}>{props.t("home_consumers.view_consumers.no_options")}</span>
                      </Col>
                  )
                  )}
                </Col>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center mt-4"
                >
                  <Col md={6} className="d-flex justify-content-start">
                    <Button
                      size="lg"
                      className="buttonWhite"
                      style={{ width: "50%" }}
                      onClick={props.hide}
                    >
                      {props.t('home_consumers.modal_edit.cancel')}
                    </Button>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      size="lg"
                      className="buttonBlue"
                      style={{ width: "50%" }}
                    >
                      {props.t('home_consumers.modal_edit.save')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditConsumers;
