import { FC, useState } from "react";
import CreateParamAuxiliarModal from ".";
import { setShowCreateParamModal } from "../../../../../../store/internal";
import { useDispatch } from "react-redux";
import { ICreateParamAuxiliarModalController } from "./indexModal";
import { useTranslation } from "react-i18next";

const CreateParamAuxiliarModalController: FC<ICreateParamAuxiliarModalController> = (props) => {
    const dispatch = useDispatch();

    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [showFeedbackLabel, setShowFeedbackLabel] = useState(false);
    const [showFeedbackInformation, setShowFeedbackInformation] = useState(false);

    const { t } = useTranslation();

    /* const hideCreateParamModal = () => {
        props.dispatch(setShowCreateParamModal(false));
    } */

    return <CreateParamAuxiliarModal 
        showFeedbackName={showFeedbackName}
        setShowFeedbackName={setShowFeedbackName}
        showFeedbackLabel={showFeedbackLabel}
        setShowFeedbackLabel={setShowFeedbackLabel}
        showFeedbackInformation={showFeedbackInformation}
        setShowFeedbackInformation={setShowFeedbackInformation}
        dispatch={dispatch} 
        integrationParams={props.integrationParams} 
        setIntegrationParams={props.setIntegrationParams}   
        hideCreateParamModal={props.hideCreateParamModal}
        t={t}
    />
}

export default CreateParamAuxiliarModalController;