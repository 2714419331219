import { useContext } from "react"
import { SocketContext } from "../core/context/socket-context"

const useUpdateStatusEmployee = () => {

    const socket = useContext(SocketContext);

    const requestAgentStatus = (user_id: string, status_employee: string, company_id: string) => {
        socket?.socket?.emit("update-status-employee", { user_id, status_employee, company_id })
    }

    return { requestAgentStatus }
}

export default useUpdateStatusEmployee;