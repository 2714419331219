import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import UserService from "../../../../../services/user-service"
import BackendConstants from "../../../../../core/constants/backend-constants"
import HomeQuickMessage from "."
import { useTranslation } from "react-i18next"

const HomeQuickMessageController = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const AppRequesterConst = new AppRequesterController()

    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentState, setCurrentState] = useState("")
    const [/*currentQuickMessage*/, setCurrentQuickMessage] = useState(undefined)
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)
    const [listWhatsapp, setListWhatsapp] = useState([])
    const [currentParams, setCurrentParams] = useState({})
    const [/*currenHasMoreInformation*/, setCurrentHasMoreInformation] = useState(true)
    const [aux, setAux] = useState([])
    const [showQuickMessageModalRegister, setShowQuickMassageModalRegister] = useState(false)

    const { t } = useTranslation()

    const handleCloseQuickMessageModalRegister = () => setShowQuickMassageModalRegister(false)
    const handleShowQuickMessageModalRegister = () => setShowQuickMassageModalRegister(true)

    // const controller = new AbortController()

    useEffect(() => {
        const controller = new AbortController()

        if (props.values === null) {

        } else {
            setCurrentParams((params) => {
                setCurrentState((state) => {
                    getAllInformationsToPagination(currentParams, { limit: currentLimit, page: currentPage }, controller)
                    return state
                })
                return params
            })
        }

        return () => {
            controller.abort()
        }

    }, [currentPage, currentState])


    const getAllQuickMessages = async (params: { limit: number; page: number }, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            "template-message/quick-message", { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200 && data) {
                    setCurrentHasMoreInformation(true)
                    setAux((state) => {
                        let aux = state.concat(data.data.quickMessages)
                        setAux(aux)
                        setCurrentQuickMessage(aux)
                        return state
                    })
                } else if (aux.length === 0) {
                    setCurrentQuickMessage(undefined)
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { values: props.values }
        )
    }

    const getListWhatsapp = async (controller: AbortController) => {
        const headers = UserService.getHeaders()
        const signal = controller.signal

        await AppRequesterConst.Get(
            '/channel-whatsapp/whatsapp-numbers', { headers, signal },
            (response: Object) => { },
            (data) => {
                if (data.status === 200 && data.data.whatsapp_numbers.length !== 0) {
                    setListWhatsapp(data.data.whatsapp_numbers)
                }
            },
            (error) => {

            }, navigate, dispatch, setIsLoading, { values: props.values }
        )
    }

    const updateListAfterDelete = () => {
        setAux([])
        setCurrentPage(1)

        setCurrentState("deleting")

        if (currentState == "deleting") {
            setCurrentState("")
        }
    }

    const updateListFunction = () => {
        setCurrentPage(currentPage + 1)
    }

    const getAllInformationsToPagination = (currentParams, params: { limit: number; page: number }, controller: AbortController) => {
        getAllQuickMessages(params, controller)
        getListWhatsapp(controller)
    }

    const callBackModalQuickMessage = () => {
        setCurrentPage(1)
        setAux([])
        const controller = new AbortController()
        getAllInformationsToPagination(currentParams, { limit: currentLimit, page: currentPage }, controller)
    }

    return <HomeQuickMessage
        isLoading={isLoading}
        showQuickMessageModalRegister={showQuickMessageModalRegister}
        handleCloseQuickMessageModalRegister={handleCloseQuickMessageModalRegister}
        handleShowQuickMessageModalRegister={handleShowQuickMessageModalRegister}
        listWhatsapp={listWhatsapp}
        aux={aux}
        updateListFunction={updateListFunction}
        updateListAfterDelete={updateListAfterDelete}
        callBackModalQuickMessage={callBackModalQuickMessage}
        t={t}
    />
}

export default HomeQuickMessageController