import { Dispatch } from "react";

export interface IViewAnalysisSimilarConsumers {
    currentConsumerId: string,
    gotoConsumersTickets: (e) => void,
    confirmationRemoveFromAnalysis: (e) => void,
    similarConsumers: any [],
    currentConsumer: any,
    isLoading: boolean,
    filteringConsumers: (e) => void,
    currentFilterSearch: string[],
    setCurrentFilterSearch: Dispatch<any>,
    phoneNumber: string,
    openModalPhone: any,
    setOpenModalPhone: Function,
    gotoConsumersPage: (e) => void,
    data: any [],
    consumerFieldName: (string) => string,
    isLargerList: boolean,
    setCurrentConsumerInfo: (e) => void,
    unifyConsumerConfirm: (e) => void,
    customFields: any [],
    colPositions: any,
    scrollAll: (e) => void,
    popoverConsumer: any,
    t: any
}

// dados do consumidor não visualizados na criação, edição e unificação
export const excludedFieldsFromUnification = [
    "total_of_tickets", "lastname", "id", 'company_id', 'created_at', 'updated_at', 'deleted_at', 
    'user_id', 'employee_user_id', 'instagram_user', 'facebook_user', 'created_by_employee', 'tags', 'consumer_id'];