import { FC } from "react";
import { IFormOfModalsTemplateMessage } from "./indexModel";
import { Button, Col, Container, Form, FormControl, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Formik } from "formik";
import allStyles from './styles';
import AttachmentsInMessageController from "../attachmentsInMessage/indexController";
import ListTagsComponentController from "./listTagsComponent/indexController";
import SelectComponentController from "../../../../../homeApp/components/selectComponent/indexController";

const FormOfModalsTemplateMessage: FC<IFormOfModalsTemplateMessage> = (props) => {
    return (
        <Container>
            <Formik
                initialValues={props.initialValues}
                validate={values => props.validate(values)}
                onSubmit={(values) => props.onSubmit(values)}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="title">Título<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="form-control-Default"
                                        isInvalid={props.showFeedbackTitle}
                                        placeholder="Informe o título da template"
                                        aria-label="Informe o título da template"
                                        aria-describedby="Informe o título da template"
                                        size='lg'
                                        type='title'
                                        id="title"
                                        name="title"
                                        autoComplete='given-name'
                                        maxLength={100}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbacktitle">
                                        {errors.title}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                            {/* SelectComponent da cx */}
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">Whatsapp<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <SelectComponentController
                                    className={"multiselectForm mb-3"}
                                    options={props.whatsappKeys}
                                    setOptions={props.setWhatsappKeys}
                                    selecteds={props.selectedWhatsappKey}
                                    setSelecteds={props.setSelectedWhatsappKey}
                                    singleSelect={true}
                                    isFormNoFilter={true}
                                    title={"Selecione o número de whatsapp"}
                                    overrideStrings={
                                        {
                                        "allItemsAreSelected": "Todos os números selecionados",
                                        "clearSearch": "Limpar pesquisa",
                                        "clearSelected": "Limpar números selecionados",
                                        "noOptions": "Sem números",
                                        "search": "Pesquisar números",
                                        "selectAll": "Selecionar todos os números",
                                        "selectAllFiltered": "Selecionar todos os números (Filtrado)",
                                        "selectSomeItems": "Selecione o número",
                                        "create": "Criar",
                                        }
                                    }
                                    hasCreateItem={false}
                                    hasNullOption={true}
                                    // nullOptionLabel={"Sem número"}
                                    hasAllOption={false}
                                    // onSelect={selectChannel}
                                />
                            </Col>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="message">Mensagem<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl 
                                        style={{ height: '10vh' }}
                                        className="form-control-Default-text-area"
                                        isInvalid={props.showFeedbackMessage}
                                        placeholder="Preencha a mensagem da template"
                                        aria-label="Preencha a mensagem da template"
                                        aria-describedby="Preencha a mensagem da template"
                                        size='lg'
                                        type='text'
                                        as="textarea"
                                        id="message"
                                        name="message"
                                        // autoComplete='given-name'
                                        maxLength={1024}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.message}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackmessage">
                                        {errors.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Form.Label htmlFor="name">
                                Variáveis
                                <OverlayTrigger
                                    key='top'
                                    placement='top'
                                    overlay={
                                        <Tooltip id='tooltip'>
                                            Exemplo de como usar uma variável: {/* &#x7B;&#x7B;nome&#x7D;&#x7D; ou  */}&#x7B;&#x7B;1&#x7D;&#x7D;
                                            {/* Insira o nome ou o número da variável entre '&#x7B;&#x7B;&#x7D;&#x7D;'. Ou insira somente o nome sem '&#x7B;&#x7B;&#x7D;&#x7D;'. */}
                                        </Tooltip>
                                    }
                                    >
                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="ms-2">
                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </OverlayTrigger>
                              <Button className="mx-3 nopadding buttonTicketsSec" onClick={props.showCreateTagModal}>
                                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                        <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Button>
                            </Form.Label>
                            <InputGroup className="mb-3" style={{  }}>
                                <ListTagsComponentController initTags={props.tags} setInitTags={props.setTags} />
                            </InputGroup>
                        </Row>
                        <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                            <AttachmentsInMessageController setAttachmentsFilesIds={props.attachmentIdsCallback} previewFiles={props.previewImage? [props.previewImage]: []} />
                        </Row>

                        {/* <Row className="d-flex justify-content-center">
                            <Col sm={true} style={allStyles.textFontGlay}>
                                <Form.Label htmlFor="basic-url">Agente Administrador? &nbsp; &nbsp;</Form.Label>
                                <Form.Check name="radioIsAdministrator" label={'Não'} type={'radio'} checked={!agent_isAdministrator} onChange={() => { SetAgent_isAdministrator(false) }} inline></Form.Check>
                                <Form.Check name="radioIsAdministrator" label={'Sim'} type={'radio'} checked={agent_isAdministrator} onChange={() => { SetAgent_isAdministrator(true) }} inline></Form.Check>
                            </Col>
                        </Row> */}
                        <Row className="d-flex justify-content-center mt-4">
                            <Col xs={6} className="d-flex justify-content-start">
                                <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => props.hide()} >Cancelar</Button>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                                <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default FormOfModalsTemplateMessage;