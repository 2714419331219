export class ModalEditTriggerTypeController {
    private dispatch: Function
    private setShowEditTriggerTypeModal: Function
    private setModalShow: Function

    constructor(dispatch: Function, setShowEditTriggerTypeModal: Function, setModalShow: Function) {
        this.dispatch = dispatch
        this.setShowEditTriggerTypeModal = setShowEditTriggerTypeModal
        this.setModalShow = setModalShow
    }

    Hide() {
        this.dispatch(this.setShowEditTriggerTypeModal(false));
        this.setModalShow(false);
    }
}