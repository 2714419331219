import { FC, useEffect, useState } from "react";
import HumanSurveyTab from ".";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../../store/company";
import UserService from "../../../../../services/user-service";
import { t } from "i18next";
import { setShowAlertFeedback } from "../../../../../store/internal";

const HumanSurveyTabController = ({
    lifeTime,
    enableNps,
    enableNpsAbandoned,
    npsWaitTime,
    npsAgentInitialMessage,
    npsAgentFinalMessage,

}) => {

    const AppRequesterConst = new AppRequesterController();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        }
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [hour, setHour] = useState("");
    const [showFeedbackTime, setShowFeedbackTime] = useState(false);

    const [ableDisable, setAbleDisable] = useState(enableNps);
    const [ableDisableNpsAbandoned, setAbleDisableNpsAbandoned] = useState(enableNpsAbandoned);

    const [initialAgentMessage, setInitialAgentMessage] = useState("");
    const [showFeedbackInitialAgentMessage, setShowFeedbackInitialAgenteMessage] = useState(false);

    const [finalAgentMessage, setFinalAgentMessage] = useState("");
    const [showFeedbackFinalAgentMessage, setShowFeedbackFinalAgenteMessage] = useState(false);

    const [resetAgentMessages, setResetAgentMessages] = useState(false);
    const [timeStringHuman, setTimeStringHuman] = useState("")
    const [/* isLoading */, setIsLoading] = useState(false)

    useEffect(() => {
      extendedHours(hour, showFeedbackTime, setTimeStringHuman)
    }, [hour])

    useEffect(() => {
        setHour(npsWaitTime !== null ? convertMinToHour(npsWaitTime) : '00:00');
        setAbleDisable(enableNps);
        setInitialAgentMessage(npsAgentInitialMessage? npsAgentInitialMessage: "");
        setFinalAgentMessage(npsAgentFinalMessage? npsAgentFinalMessage: "");
        setResetAgentMessages(false);
        setAbleDisableNpsAbandoned(enableNpsAbandoned)
    }, [enableNps, npsWaitTime, npsAgentInitialMessage, npsAgentFinalMessage, enableNpsAbandoned])

    const extendedHours = (setTime: any, feedbackTime: any, timeString: Function) => {
      if(setTime){
        if (!feedbackTime && setTime !== "00:00") {

          const hours = parseInt(setTime.split(":")[0]);
          const minutes = setTime.split(":")[1];

          let auxTime = "";

          if (hours > 0) {
            auxTime += `${hours} ${t("configurations_ticket.survey_tab.hour")}${hours > 1 ? 's' : ''}`;
          }

          if(minutes.split("")[1] === '_'){ /* verifica se o ultimo digito ainda está para ser preenchido, e coloca o espaço vazio */
            auxTime += `${auxTime ? ` ${t("configurations_ticket.survey_tab.label_and")} ` : ''}${minutes}_ ${t("configurations_ticket.survey_tab.minute")}${parseInt(minutes) > 1 ? 's' : ''}`;
          }
          else{
            if (parseInt(minutes) > 0) {
                auxTime += `${auxTime ? ` ${t("configurations_ticket.survey_tab.label_and")} ` : ''}${parseInt(minutes)} ${t("configurations_ticket.survey_tab.minute")}${parseInt(minutes) > 1 ? 's' : ''}`;
              }
          }
          timeString(auxTime);
        }
        else{
          timeString("") /* não mostrar nada caso em que der erro ou estiver 00:00 */
        }
      }
    }

    const converterMinuto = (hora: string) => {
        let separaHora = hora.split(":")
        let Minutos = parseInt(separaHora[0]) * 60 + parseInt(separaHora[1])
        return (Minutos)
    }

    const converterHora = (hora: string) => {
        let separaHora = hora.split(":")
        let Data = new Date(2000, 1, 1, parseInt(separaHora[0]), parseInt(separaHora[1]))
        return (Data)
    }

    const convertNumToHour = (num: number) => {
        // var hour = Math.floor(num);
        // var dec = num - hour;
        // var min = 1 / 60;
        // dec = min * Math.round(dec / min);

        // var minute = Math.floor(dec * 60) + '';
        // if (minute.length < 2) {
        //     minute = '0' + minute;
        // }

        // return hour + ':' + minute

        const hour = Math.floor(num / 60);
        const minute = num % 60;
        
        const formattedHour = hour < 10 ? '0' + hour : hour;
        const formattedMinute = minute < 10 ? '0' + minute : minute;
        
        return formattedHour + ':' + formattedMinute;
    }

    const convertMinToHour = (num: number) => {
        var dec = num / 60;
        var hour = Math.floor(dec);
        var min = dec - hour
        min = 60 * min;

        if (hour.toString().length < 2) {
            var hora = '0' + hour
        }
        if (min.toString().length < 2) {
            var minute = '0' + min
        }

        return (hora ? hora : hour) + ':' + (minute ? minute : min)
    }

    const validate = (values) => {
        let errors = {};
        setHour(values.hour);
        setInitialAgentMessage(values.initial_agent_message);
        setFinalAgentMessage(values.final_agent_message);

        if (!values.hour && !ableDisable) {
          setShowFeedbackTime(false)
        }
        else if (!values.hour && ableDisable) {

          errors['hour'] = t('configurations_ticket.survey_tab.validate.hour');
          setShowFeedbackTime(true);
        } else if (converterHora(values.hour).toString() === "Invalid Date" ||
          parseInt(values.hour.split(":")[0]) > parseInt(convertNumToHour(lifeTime).split(":")[0]) ||
          parseInt(values.hour.split(":")[1]) > 59) {

          errors["hour"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTime)}`;
          setShowFeedbackTime(true);

        } else if (parseInt(values.hour.split(":")[0]) === parseInt(convertNumToHour(lifeTime).split(":")[0]) &&
          parseInt(values.hour.split(":")[1]) > parseInt(convertNumToHour(lifeTime).split(":")[1])) {

          errors["hour"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTime)}`;
          setShowFeedbackTime(true);

        } else {
          setShowFeedbackTime(false);
        }

        if (resetAgentMessages) { 
          setShowFeedbackInitialAgenteMessage(false);
        } else if (values.initial_agent_message?.length > 1000) {
          setShowFeedbackInitialAgenteMessage(true);
          errors['initial_agent_message'] = t('configurations_ticket.survey_tab.validate.message_limit_max');
        } else if (values.initial_agent_message === "") {
          setShowFeedbackInitialAgenteMessage(true);
          errors['initial_agent_message'] = t('configurations_ticket.survey_tab.validate.message_limit_min');
        } else {
          setShowFeedbackInitialAgenteMessage(false);
        }

        if (resetAgentMessages) {
          setShowFeedbackFinalAgenteMessage(false);
        } else if (values.final_agent_message?.length > 1000) {
          setShowFeedbackFinalAgenteMessage(true);
          errors['final_agent_message'] = t('configurations_ticket.survey_tab.validate.message_limit_max');
        } else if (values.final_agent_message === "") {
          setShowFeedbackFinalAgenteMessage(true);
          errors['final_agent_message'] = t('configurations_ticket.survey_tab.validate.message_limit_min');
        } else {
          setShowFeedbackFinalAgenteMessage(false);
        }

        return errors;
    }

    const onSubmit = async () => {
        let JsonSend = {
            id: values.company.id,
            enable_nps: ableDisable,
            nps_wait_time: ableDisable? converterMinuto(hour) : undefined,
            enable_nps_abandoned: ableDisableNpsAbandoned
            /* nps_bot_reset_message: resetBotMessages,
            nps_agent_reset_message: resetAgentMessages, */
        }

        if (initialAgentMessage.length > 0) {
            JsonSend['nps_agent_initial_message'] = initialAgentMessage;
        }

        if (finalAgentMessage.length > 0) {
            JsonSend['nps_agent_final_message'] = finalAgentMessage;
        }

        const headers = UserService.getHeaders();
        await AppRequesterConst.Put(
            '/company', JsonSend, { headers: headers },
            (response: Object) => { },
            (data) => {
                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.message'), visibility: true, signalIcon: true }));

                /* if (data.status === 200 && data?.data?.company ) {
                  let initialAgentMsg = data.data.company.nps_agent_initial_message;
                  let finalAgentnMsg = data.data.company.nps_agent_final_message;
                  let initialBotMsg = data.data.company.nps_bot_initial_message;
                  let finalBotMsg = data.data.company.nps_bot_final_message;
    
                  setResetAgentMessages(false);
                  setResetBotMessages(false);
    
                  setInitialAgentMessage(initialAgentMsg);
                  setFinalAgentMessage(finalAgentnMsg);
                  setInitialBotMessage(initialBotMsg);
                  setFinalBotMessage(finalBotMsg);
                } */
            },
            (error: any) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } else if (error.response.status === 500 && error.response.data.code_cxpress === 1201) {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error500'), visibility: true, signalIcon: false }));
                } else if (error.response.status === 422 && error.response.data.message[0].path[0] === 'nps_wait_time_level_one') {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error422'), visibility: true, signalIcon: false }));
                } else if (error.response.status === 422 && error.response.data.message[0].path[0] !== 'nps_wait_time_level_one') {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.hour_error'), visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error'), visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading,
        );
    }

    const resetInitialAgentMessage = (values, setValues) => {
        setInitialAgentMessage(t('configurations_ticket.survey_tab.nps_default_messages.initial_agent'));
    }

    const resetFinalAgentMessage = (values, setValues) => {
    // values['final_agent_message'] = t('configurations_ticket.survey_tab.nps_default_messages.final_agent');
        setFinalAgentMessage(t('configurations_ticket.survey_tab.nps_default_messages.final_agent'));
    }

    return (
        <HumanSurveyTab 
            ableDisable={ableDisable}
            setAbleDisable={setAbleDisable}
            ableDisableNpsAbandoned={ableDisableNpsAbandoned}
            setAbleDisableNpsAbandoned={setAbleDisableNpsAbandoned}
            hour={hour}
            initialAgentMessage={initialAgentMessage}
            setInitialAgentMessage={setInitialAgentMessage}
            showFeedbackInitialAgentMessage={showFeedbackInitialAgentMessage}
            setShowFeedbackInitialAgenteMessage={setShowFeedbackInitialAgenteMessage}
            finalAgentMessage={finalAgentMessage}
            setFinalAgentMessage={setFinalAgentMessage}
            showFeedbackFinalAgentMessage={showFeedbackFinalAgentMessage}
            setShowFeedbackFinalAgenteMessage={setShowFeedbackFinalAgenteMessage}
            resetAgentMessages={resetAgentMessages}
            converterMinuto={converterMinuto}
            converterHora={converterHora}
            onSubmit={onSubmit}
            convertNumToHour={convertNumToHour}
            lifeTime={lifeTime}
            resetInitialAgentMessage={resetInitialAgentMessage}
            resetFinalAgentMessage={resetFinalAgentMessage}
            showFeedbackTime={showFeedbackTime}
            setShowFeedbackTime={setShowFeedbackTime}
            validate={validate}
            timeStringHuman={timeStringHuman}
        />
    )
}

export default HumanSurveyTabController;