import { Dispatch, SetStateAction, createContext, useState } from "react";

export const StatusAgentContext = createContext<StatusAgentContextType>(null)

export const StatusAgentProvider = ({ children }) => {
    const [userId, setUserId] = useState<string>()
    const [statusEmployee, setStatusEmployee] = useState<string>()
    const [companyId, setCompanyId] = useState<string>()
    const [notificationAgent, setNotificationAgent] = useState<boolean>()

    // setNotificationStatusAgent = setStatusEmployee

    const ProviderValues = {
        userId, 
        setUserId,
        statusEmployee,
        setStatusEmployee,
        companyId,
        setCompanyId,
        notificationAgent,
        setNotificationAgent
    }
    
    return (
        <StatusAgentContext.Provider value={ProviderValues}>
            {children}
        </StatusAgentContext.Provider>
    )
}


export type StatusAgentContextType = {
    userId: string;
    setUserId: Dispatch<SetStateAction<string>>;
    statusEmployee: string;
    setStatusEmployee: Dispatch<SetStateAction<string>>;
    companyId: string;
    setCompanyId: Dispatch<SetStateAction<string>>;
    notificationAgent: boolean;
    setNotificationAgent: Dispatch<SetStateAction<boolean>>
}