import React from 'react';
import allStyles from './styles';
import { Modal, Row, Container, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { setShowAlertFeedback, } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import UserService from '../../../../services/user-service';

const AppRequesterConst = new AppRequesterController();

const ModalEditTags = ({
    modalShow,
    Hide,
    data,
    Validate,
    tagName,
    tagIsActive,
    token,
    dispatch,
    callback,
    navigate,
    setIsLoading,
    showFeedbackName,
    setTagIsActive,
    setTagName,
    t,
    consumer,
    tagConsumer
}) => {
    return (
        <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" centered={true}>
            <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
                <Modal.Title style={allStyles.textBlack}>
                    {t('modal_edit_tag.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
                <Container>
                    <Formik
                        initialValues={{
                            name: (data && data.name != null) ? data.name : '',
                        }}
                        validate={values => {
                            setTagName(values.name)
                            return Validate(values)
                        }}
                        onSubmit={async () => {
                            let JsonSend = {
                                "id": data.id,
                                "name": tagName,
                                "active": tagIsActive,
                            }

                            const headers = UserService.getHeaders()

                            // const headers = {
                            //     "Content-Type": "application/json; charset=utf-8",
                            //     "Authorization": "Bearer " + props.token
                            // };

                            await AppRequesterConst.Put(
                                consumer || tagConsumer ? '/consumer-tag' : '/tag', JsonSend, { headers },
                                (response: Object) => {
                                    return response;
                                },
                                (data: { data: { user_id: string; }; }) => {
                                    dispatch(setShowAlertFeedback({ message: t('modal_edit_tag.put_sucess'), signalIcon: true, visibility: true }));
                                    if (callback !== undefined) {
                                        callback(data.data.user_id);
                                    }
                                    Hide();
                                },
                                (error: { response: { status: number, data:{code_cxpress: number} }; }) => {
                                    if (error.response !== undefined) {
                                        if (error.response.status === 400) { /*"Tag não salva. Existe uma tag com esse nome no sistema."*/
                                            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                                        } else {
                                            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                                        }
                                    }
                                }, navigate, dispatch, setIsLoading
                            );
                            /* await Api.put('/tag', JsonSend, { headers }).then((response) => {
                                return response;
                            }).then(data => {
                                dispatch(setShowAlertFeedback({ message: `Tag atualizada com sucesso!`, signalIcon: true, visibility: true }));
                                props.callback();
                                Hide();
                            }).catch((error) => {
                                if (error.response !== undefined) {
                                    if (error.response.status === 400) {
                                        dispatch(setShowAlertFeedback({ message: "Tag não salva. Existe uma tag com esse nome no sistema.", visibility: true, signalIcon: false }));
                                    }
                                    else {
                                        dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                                    }
                                }
                            }); */
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                                    <Col md={12}>
                                        <Form.Label htmlFor="name">{t('modal_edit_tag.label_name')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <FormControl className="form-control-Default"
                                                isInvalid={showFeedbackName}
                                                placeholder={t('modal_edit_tag.name_placeholder')}
                                                aria-label={t('modal_edit_tag.name_placeholder')}
                                                aria-describedby={t('modal_edit_tag.name_placeholder')}
                                                size='lg'
                                                type='name'
                                                id="tags_name"
                                                name="name"
                                                autoComplete='given-name'
                                                maxLength={50}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name.trim()}
                                            >
                                            </FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbackname">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                                    <Col md={12}>
                                        <Form.Label htmlFor="basic-url">{t('modal_edit_tag.tag_active_label')} &nbsp; &nbsp;</Form.Label>
                                        <Form.Check name="radioIsAdministrator" label={t('modal_edit_tag.no')} type={'radio'} checked={!tagIsActive} onChange={() => { setTagIsActive(false) }} inline></Form.Check>
                                        <Form.Check name="radioIsAdministrator" label={t('modal_edit_tag.yes')} type={'radio'} checked={tagIsActive} onChange={() => { setTagIsActive(true) }} inline></Form.Check>
                                    </Col>
                                </Row>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center mt-4">
                                    <Col className="d-flex justify-content-center">
                                        <Button size='lg' className="buttonWhite" style={{ width: '70%' }} onClick={() => Hide()} >{t('modal_edit_tag.cancel')}</Button>
                                    </Col>
                                    <Col className="d-flex justify-content-center" >
                                        <Button type='submit' size='lg' className="buttonBlue" style={{ width: '70%' }}>{t('modal_edit_tag.save')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditTags;