import { createSlice } from '@reduxjs/toolkit';

export const internalControlPanelSlice = createSlice({
  name: 'internal_controlpanel',
  initialState: {
    showEditUserPermissionsModal: false,
    showAccountEmailConfirmationModal: false,
    showAccountInvalidApp2FAModal: false,
  },
  reducers: {
    setShowEditUserPermissionsModal: (state, action) => {
      state.showEditUserPermissionsModal = action.payload;
    },
    setShowAccountEmailConfirmationModal: (state, action) => {
      state.showAccountEmailConfirmationModal = action.payload;
    },
    setShowAccountInvalidApp2FAModal: (state, action) => {
      state.showAccountInvalidApp2FAModal = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setShowEditUserPermissionsModal,
  setShowAccountEmailConfirmationModal,
  setShowAccountInvalidApp2FAModal,
} = internalControlPanelSlice.actions;

export const getShowEditUserPermissionsModal = (state: State) => getState(state, 'showEditUserPermissionsModal');
export const getShowAccountEmailConfirmationModal = (state: State) => getState(state, 'showAccountEmailConfirmationModal');
export const getShowAccountInvalidApp2FAModal = (state: State) => getState(state, 'showAccountInvalidApp2FAModal');

export default internalControlPanelSlice.reducer;

const getState = (state: State, action: Action) => {
  if (state.internal_controlpanel[action] !== undefined) {
    return state.internal_controlpanel[action];
  } else {
    return internalControlPanelSlice.getInitialState()[action];
  }
}

type State = {
  internal_controlpanel: {
    showEditUserPermissionsModal: boolean;
    showAccountEmailConfirmationModal: boolean;
    showAccountInvalidApp2FAModal: boolean;
  };
}

type Action = (
  'showEditUserPermissionsModal' | 
  'showAccountEmailConfirmationModal' |
  'showAccountInvalidApp2FAModal'
);
