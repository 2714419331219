import './styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Row, Col, Button, Container } from 'react-bootstrap';
import React, { FC } from 'react';
import Loading from '../../../components/loading';
import { ValidateControlPanelEmailInterface } from './indexModel';

const ValidateControlPanelEmail: FC<ValidateControlPanelEmailInterface> = (props) => {

  return (
    <Container fluid className='nopadding validateControlPanel'>
      { props.isLoading && <Loading visibility={props.isLoading} /> }
      {
        !props.isLoading && 
        <Row className='nopadding'>
          <Col className='nopadding'>
            <Row className='nopadding align-items-center body-content'>
              <div className='d-flex justify-content-center'>
                <Col className='box-content' sm={3} md={3}>
                  <div className='d-flex align-items-center'>
                    <div className='validateLogo'>
                      <img src='/Logo.svg' alt='Logo Cxpress'/>
                    </div>
                  </div>
                  { 
                    props.validation && 
                    <div className='confirmed'>
                      <span className="title mt-2 mb-3">Conta confirmada!</span>
                      <p className="description">
                        Sua conta foi confirmada! Clique no botão abaixo para fazer login.
                      </p>
                      <Button className='buttonFirstAccess' onClick={() => props.goToLogin()}>Fazer login</Button>
                    </div>
                  }
                  {
                    !props.validation &&
                    <div className='not-confirmed'>
                      <span className="title mt-2 mb-3">Link inválido!</span>
                      <p className="description mb-3">
                        Não foi possível confirmar a sua conta no momento. Verifique a situação de sua conta com um administrador do painel de configurações.
                      </p>
                      <Button className='buttonFirstAccess' onClick={() => props.goToLogin()}>Voltar para o login</Button>
                    </div>
                  }
                </Col>
              </div>
            </Row>
            <Row className='nopadding footer-content'>
              <Col className='d-flex justify-content-center align-items-center pt-3'>
                <p className='footer-text'>
                  <span>Esta é uma área exclusiva e de acesso restrito. </span>
                  <span className='align-self-center nopadding'>2022 Cxpress. Todos os direitos reservados</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    </Container>
  );
}

export default ValidateControlPanelEmail;
