import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import allStyles from './styles';

import { Container, Row, Col, Image, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Loading from "../../components/loading";
import { Formik } from 'formik';

import ModalConfirmation from '../../components/modalConfirmation';
import { getShowConfirmationModal } from '../../store/internal';
import { ILoginHome } from './indexModel';
import { useSelector } from 'react-redux';

const LoginHome: FC<ILoginHome> = (props) => {
  return (
    <>
      <ModalConfirmation text={useSelector(getShowConfirmationModal).text} visibility={useSelector(getShowConfirmationModal).visibility} funtionConfirmation={useSelector(getShowConfirmationModal).functionConfirmation}></ModalConfirmation>
      <Container fluid >
        <Loading visibility={props.isLoading}></Loading>
        <Row style={{ height: '100vh' }} className='d-flex'>
          <Col style={allStyles.backgroundImage}>
            <Row style={{ height: '100%' }}>
              <div style={{ position: 'absolute', width: "fit-content", padding: '0' }}>
                <img src="/Logo_Branca.svg" alt="" width={'90%'} />
              </div>
              <Col md={4} xl={4} className="d-flex justify-content-center" style={{ marginTop: '15vh', marginLeft: '3vh' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="169.181" height="182.157" viewBox="0 0 169.181 182.157">
                  <g id="Grupo_11236" data-name="Grupo 11236" transform="translate(-110 -178.643)">
                    <path id="Caminho_5464" data-name="Caminho 5464" d="M489.308,400.5H445.143l.023-2.157.069-2.145.114-2.134.159-2.122.2-2.109.247-2.1.29-2.082.333-2.068.375-2.054.417-2.039.459-2.023.5-2.008.54-1.991.58-1.974.62-1.957.659-1.939.7-1.921.736-1.9.774-1.883.811-1.864.848-1.844.884-1.823.92-1.8.955-1.781.99-1.759,1.025-1.736,1.059-1.713,1.093-1.69,1.126-1.666,1.158-1.642,1.19-1.617,1.222-1.592,1.253-1.566,1.284-1.54,1.315-1.514,1.344-1.487,1.374-1.459,1.4-1.431,1.431-1.4,1.459-1.374,1.487-1.344,1.514-1.315,1.54-1.284,1.566-1.253,1.592-1.222,1.617-1.19,1.642-1.158,1.666-1.126,1.69-1.093,1.713-1.059,1.736-1.025,1.759-.99,1.781-.956,1.8-.92,1.823-.884,1.844-.848,1.864-.811,1.883-.774,1.9-.736,1.921-.7,1.939-.659,1.957-.62,1.974-.58,1.991-.54,2.008-.5,2.023-.459,2.039-.417,2.054-.375,2.068-.333,2.082-.29,2.1-.247,2.109-.2,2.122-.159,2.134-.114,2.146-.069,2.157-.023v44.164l-2.16.042-2.139.124-2.117.2-2.092.284-2.067.361-2.039.438-2.011.512-1.98.585-1.949.657-1.915.727-1.88.8-1.844.862-1.806.928-1.767.992-1.726,1.054-1.683,1.115-1.639,1.174-1.594,1.232-1.547,1.288-1.5,1.343-1.448,1.4-1.4,1.448-1.343,1.5-1.288,1.547-1.232,1.594-1.174,1.639L497.873,371l-1.054,1.726-.992,1.767L494.9,376.3l-.862,1.844-.8,1.88-.727,1.915-.657,1.949-.585,1.981-.512,2.011-.438,2.039-.361,2.067-.284,2.092-.2,2.117-.124,2.139-.042,2.16Z" transform="translate(-335.143 -122.674)" fill="#ff8119" />
                    <path id="Caminho_5467" data-name="Caminho 5467" d="M713.467,400.548l1.825-.034,1.823-.1,1.818-.168,1.81-.235,1.8-.3,1.788-.367,1.774-.433,1.756-.5,1.737-.562,1.715-.626,1.691-.688,1.664-.75,1.636-.811,1.6-.87,1.572-.929,1.536-.986,1.5-1.042,1.46-1.1,1.418-1.15,1.375-1.2,1.33-1.251,1.283-1.3,1.234-1.345,1.184-1.39,1.132-1.432,1.079-1.473L755.035,378l.967-1.548.91-1.583.851-1.615.791-1.646.73-1.673.667-1.7.6-1.723.541-1.744.476-1.762.411-1.779.345-1.793.279-1.8.212-1.813.146-1.82.078-1.824.015-1.217,0-.609L763,348.525l-.123-1.821-.19-1.816-.257-1.808-.323-1.8-.389-1.784-.454-1.768-.519-1.75-.583-1.73-.647-1.707-.709-1.682-.771-1.655-.831-1.626-.89-1.594-.948-1.56-1-1.524-1.06-1.486-1.114-1.446-1.167-1.4-1.218-1.36-1.267-1.314-1.314-1.267-1.36-1.218-1.4-1.167-1.446-1.114-1.486-1.06-1.524-1-1.56-.948-1.594-.89-1.626-.831-1.655-.77-1.682-.709-1.707-.647-1.73-.583-1.75-.519-1.768-.454-1.784-.389-1.8-.323-1.807-.257-1.816-.19-1.821-.123-1.825-.056-.609,0-.609,0-1.825.056-1.821.123-1.816.19-1.808.257-1.8.323-1.784.389-1.768.454-1.75.519-1.73.583-1.707.647-1.682.709-1.655.77-1.626.831-1.594.89-1.56.948-1.524,1-1.486,1.06-1.446,1.114-1.4,1.167-1.36,1.218-1.314,1.267-1.267,1.314-1.218,1.36-1.167,1.4-1.114,1.446-1.06,1.486-1,1.524-.948,1.56-.89,1.594-.831,1.626-.77,1.655-.709,1.682-.646,1.707-.583,1.73-.519,1.75-.454,1.768-.389,1.784-.323,1.8-.257,1.808-.19,1.816-.123,1.821-.056,1.825,0,.609,0,.609.056,1.825.123,1.821.19,1.816.257,1.808.323,1.8.389,1.784.454,1.768.519,1.75.583,1.73.646,1.707.709,1.682.77,1.655.831,1.626.89,1.594.948,1.56,1,1.524,1.06,1.486,1.114,1.446,1.167,1.4,1.218,1.36,1.267,1.314,1.314,1.267,1.36,1.218,1.4,1.167,1.446,1.114,1.486,1.06,1.524,1,1.56.948,1.594.89,1.626.831,1.655.77,1.682.709,1.707.646,1.73.583,1.75.519,1.768.454,1.784.389,1.8.323,1.808.257,1.816.19,1.821.123,1.825.056Z" transform="translate(-483.876 -39.748)" fill="#fff" />
                  </g>
                </svg>
              </Col>
              <Col></Col>
              <Col md={7} xl={7} className=" justify-content-end nopadding">
                <Image src='../../../Image2a.png' alt="Login" width='100%' style={{ minWidth: '320px' }} ></Image>
              </Col>
            </Row>
            <Row className="nopaddingWithMarginTop">
              <svg className="nopadding  align-self-end" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 981 359.998">
                <g id="Grupo_11260" data-name="Grupo 11260" transform="translate(0 -720.002)">
                  <g id="Grupo_11244" data-name="Grupo 11244">
                    <path id="Caminho_5465" data-name="Caminho 5465" d="M654.38,400.674H555.074V301.368H654.38Z" transform="translate(326.62 679.326)" fill="#4a69bd" />
                    <path id="Caminho_5466" data-name="Caminho 5466" d="M210.15,301.245l99.178,99.18H210.15Z" transform="translate(147.85 679.575)" fill="#ff8119" />
                  </g>
                  <path id="Caminho_5462" data-name="Caminho 5462" d="M1919.98,719.036h-360l.035,5.09.106,5.073.175,5.055.244,5.037.313,5.019.382,5,.449,4.981.517,4.961.584,4.941.65,4.92.716,4.9.782,4.878.847,4.856.911,4.833.976,4.81,1.039,4.787,1.1,4.763,1.166,4.738,1.228,4.714,1.29,4.688,1.351,4.662,1.412,4.636,1.472,4.61,1.532,4.582,1.592,4.555,1.651,4.527,1.71,4.5,1.768,4.469,1.825,4.44,1.883,4.41,1.939,4.379,2,4.349,2.052,4.317,2.107,4.286,2.162,4.253,2.216,4.221,2.27,4.187,2.324,4.154,2.377,4.12,2.429,4.085,2.482,4.05,2.533,4.015,2.584,3.979,2.635,3.942,2.686,3.906,2.735,3.868,2.785,3.83,2.834,3.792,2.882,3.753,2.93,3.714,2.977,3.675,3.025,3.635,3.071,3.594,3.117,3.553,3.163,3.511,3.208,3.469,3.253,3.427,3.3,3.384,3.341,3.341,3.384,3.3,3.427,3.253,3.469,3.208,3.511,3.163,3.553,3.117,3.594,3.071,3.635,3.024,3.675,2.977,3.714,2.93,3.753,2.882,3.792,2.833,3.83,2.785,3.868,2.735,3.906,2.685,3.942,2.635,3.979,2.584,4.015,2.533,4.05,2.482,4.085,2.429,4.12,2.377,4.154,2.324,4.187,2.27,4.221,2.216,4.253,2.162,4.286,2.107,4.317,2.052,4.349,2,4.38,1.939,4.41,1.883,4.44,1.825,4.469,1.768,4.5,1.71,4.527,1.651,4.555,1.592,4.582,1.532,4.61,1.472,4.636,1.412,4.662,1.351,4.688,1.29,4.714,1.228,4.738,1.165,4.763,1.1,4.787,1.039,4.81.976,4.833.912,4.856.847,4.878.782,4.9.716,4.92.65,4.941.584,4.961.517,4.981.449,5,.381,5.019.313,5.037.244,5.055.175,5.073.105,5.09.035v-360Z" transform="translate(-1559.979 0.966)" fill="#4a69bd" />
                </g>
              </svg>
            </Row>
          </Col>
          <Col>
            <Row style={allStyles.backgroundLogin}>
              <Row className="d-flex justify-content-center align-self-end" style={{ margin: "0 0 -3vh 0", padding: "0", }}>
                <Col md={6} sm={6} className="nopadding">
                  <p style={allStyles.companyName}>
                    { props.companyName }
                  </p>
                </Col>
              </Row>
              {
                props.stepLogin &&
                <Formik
                  initialValues={{ email: '', password: '' }}
                  validate={values => {
                    props.setUserEmail(values.email);
                    props.setUserPassword(values.password);
                    return props.validateLogin(values);
                  }}
                  onSubmit={props.submitLogin}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                      <Row style={{ height: 'fit-content' }} className=" d-flex justify-content-center my-4">
                        <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                          <Row style={allStyles.textFontBlue} className="mt-4 mb-3 mx-2">
                            <Col sm={3} md={3}><p>{ props.t('login.title_login') }</p></Col>
                            <Col className="d-flex justify-content-end align-self-start">
                              <p>
                                <Link style={{ width: '100%', textDecoration: 'none' }} className=""
                                  to={{
                                    pathname: "/forgotpassword",
                                  }}
                                >
                                  <span style={allStyles.LinkGlay2}>{ props.t('login.link_forgot_login') }</span>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                          <Row style={allStyles.textFontGlay} className="mx-2">
                            <Form.Label htmlFor="email">{ props.t('login.label_email') }</Form.Label>
                            <InputGroup className="mb-3">
                              <FormControl className="form-control-Default"
                                isInvalid={props.showFeedbackEmail}
                                placeholder={props.t('login.placeholder_email')}
                                aria-label={props.t('login.placeholder_email')}
                                aria-describedby={props.t('login.placeholder_email')}
                                size='lg'
                                type='email'
                                id="userEmail"
                                name="email"
                                autoComplete='email'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              >
                              </FormControl>
                              <Form.Control.Feedback type="invalid" id="feedbackemail">
                                {errors.email}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Row>
                          <Row style={allStyles.textFontGlay} className="mx-2">
                            <Form.Label htmlFor="basic-url">{ props.t('login.label_password') }</Form.Label>
                            <InputGroup className="mb-3">
                              <FormControl
                                isInvalid={props.showFeedbackPassword}
                                className="form-control-Default"
                                placeholder={props.t('login.placeholder_password')}
                                aria-label={props.t('login.placeholder_password')}
                                aria-describedby={props.t('login.placeholder_password')}
                                type="password"
                                size='lg'
                                id="userPassword"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              <div className="d-flex justify-content-end align-self-start">
                                <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', zIndex: "5" }} onClick={() => props.clickPassword()}>
                                  {props.showPassword ?
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <circle cx="12" cy="12" r="2" />
                                      <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <line x1="3" y1="3" x2="21" y2="21" />
                                      <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                      <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                    </svg>
                                  }
                                </Button>
                              </div>
                              <Form.Control.Feedback type="invalid" id="feedbackpassword">
                                {errors.password}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Row>
                          <Row className="mb-4 mt-2 mx-3" >
                            <Button type="submit" style={{ height: '5vh' }} className="buttonBlue">{ props.t('login.button_login') }</Button>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              }
              {
                props.step2FA &&
                <Formik
                  initialValues={{ code_2fa: '', type: 'normal' }}
                  validate={values => {
                    props.validate2FA(values)
                  }}
                  onSubmit={props.verify2FA}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                      <Row style={{ height: 'fit-content' }} className=" d-flex justify-content-center my-4">
                        <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                          <Row style={allStyles.textFontBlue} className="mt-4 mb-2 mx-2">
                            <Col><p>{props.t('login.title_2fa')}</p></Col>
                          </Row>
                          <Row className="mb-2 mx-2">
                            <Col style={allStyles.LinkGlay}>
                              {props.defaultIsEmail2FA && <p>{ props.t('login.info_2fa_email') }</p>}
                              {props.defaultIsApp2FA && <p>{ props.t('login.info_2fa_app') }</p>}
                            </Col>
                          </Row>
                          <Row style={allStyles.textFontGlay} className="mx-2">
                            <InputGroup className="mb-3" style={{ position: 'relative' }}>
                              <FormControl className="form-control-Default"
                                isInvalid={props.showFeedback2FA}
                                placeholder={props.defaultIsEmail2FA ? props.t('login.placeholder_2fa_email') : props.t('login.placeholder_2fa_app')}
                                aria-label={props.defaultIsEmail2FA ? props.t('login.placeholder_2fa_email') : props.t('login.placeholder_2fa_app')}
                                aria-describedby={props.defaultIsEmail2FA ? props.t('login.placeholder_2fa_email') : props.t('login.placeholder_2fa_app')}
                                size="lg"
                                type="text"
                                id="user_code_2fa"
                                name="code_2fa"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.code_2fa || ''}
                              >
                              </FormControl>
                              <Form.Control.Feedback type="invalid" id="feedback_code_2fa" style={{ position: 'absolute', bottom: '-20px' }}>
                                {errors.code_2fa}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Row>
                          <Row className="mb-4 mt-3 mx-3">
                            <Button type="submit" style={{ height: '5vh' }} className="buttonBlue" disabled={props.showFeedback2FA || !values.code_2fa}>
                              { props.t('login.button_2fa') }
                            </Button>
                          </Row>
                          {
                            props.stateCompanyHasApp2FA && props.employeeHasApp2FA && props.defaultIsEmail2FA &&
                            <Row className='mb-4 mt-3 mx-3'>
                              <span style={allStyles.linkChangeMethod} onClick={() => props.changeDefault2FA('app')} >{ props.t('login.button_use_app') }</span>
                            </Row>
                          }
                          {
                            props.companyHasEmail2FA && props.defaultIsApp2FA &&
                            <Row className='mb-4 mt-3 mx-3'>
                              <span
                                style={allStyles.linkChangeMethod}
                                onClick={() => props.changeDefault2FA('email')} >
                                { props.t('login.button_use_email') }
                              </span>
                            </Row>
                          }
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              }
              {
                props.stepConfig2FA &&
                <Row style={{ height: 'fit-content' }} className=" d-flex justify-content-center my-4">
                  <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                    <Row style={allStyles.textFontBlue} className="mt-4 mb-2 mx-2">
                      <Col><p>{props.t('login.title_2fa')}</p></Col>
                    </Row>
                    <Row className="mb-2 mx-2">
                      <Col style={allStyles.LinkGlay}>
                        <p>{props.t('login.info_config_2fa_app')}</p>
                      </Col>
                    </Row>
                    <Row className="mb-4 mt-3 mx-3">
                      <Button onClick={props.configApp} style={{ height: '5vh' }} className="buttonBlue">
                        {props.t('login.button_config_2fa')}
                      </Button>
                    </Row>
                    {
                      props.companyHasEmail2FA && props.defaultIsApp2FA &&
                      <Row className='mb-4 mt-3 mx-3'>
                        <span
                          style={allStyles.linkChangeMethod}
                          onClick={() => props.changeDefault2FA('email')} >
                          { props.t('login.button_use_email') }
                        </span>
                      </Row>
                    }
                    <Row className='mb-4 mt-3 mx-3'>
                      <span style={allStyles.linkChangeMethod} onClick={props.backLogin} >{props.t('login.button_back_login')}</span>
                    </Row>
                  </Col>
                </Row>
              }
              {
                props.stepConfigApp &&
                <Formik
                  initialValues={{ code_2fa: '', type: 'config_app' }}
                  validate={values => {
                    props.validate2FA(values)
                  }}
                  onSubmit={props.verify2FA}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                      <Row style={{ height: 'fit-content' }} className=" d-flex justify-content-center my-4">
                        <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                          <Row style={allStyles.textFontBlue} className="mt-4 mb-2 mx-2">
                            <Col><p>{props.t('login.title_2fa')}</p></Col>
                          </Row>
                          <Row className="mb-2 mx-2">
                            <div style={allStyles.divStep}>
                              <span style={allStyles.spanStep}>{props.t('configurations.security.tab_app.step1')}</span>
                            </div>
                            <div style={allStyles.divStep}>
                              <span style={allStyles.spanStep}>{props.t('configurations.security.tab_app.step2')}</span>
                              {props.isLoadingQR && <img style={allStyles.imgStyle} src="/assets/chart-loading.png" alt="qr code" />}
                              <img style={{ ...allStyles.imgStyle, display: props.isLoadingQR ? 'none' : 'block' }} src={props.dataConfigApp.qr} alt="qr code" onLoad={props.onLoadQR} />
                              <span style={allStyles.spanSecret}>Código: {props.dataConfigApp?.secret}</span>
                            </div>
                            <div style={allStyles.divStep}>
                              <span style={allStyles.spanStep}>{props.t('configurations.security.tab_app.step3')}</span>
                            </div>
                          </Row>
                          <Row style={allStyles.textFontGlay} className="mx-2">
                            <InputGroup className="mb-3" style={{ position: 'relative' }}>
                              <FormControl className="form-control-Default"
                                isInvalid={props.showFeedback2FA}
                                placeholder={props.t('login.placeholder_2fa_app')}
                                aria-label={props.t('login.placeholder_2fa_app')}
                                aria-describedby={props.t('login.placeholder_2fa_app')}
                                size="lg"
                                type="text"
                                id="user_code_2fa"
                                name="code_2fa"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.code_2fa || ''}
                              >
                              </FormControl>
                              <Form.Control.Feedback type="invalid" id="feedback_code_2fa" style={{ position: 'absolute', bottom: '-20px' }}>
                                {errors.code_2fa}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Row>
                          <Row className="mb-4 mt-3 mx-3">
                            <Button type="submit" style={{ height: '5vh' }} className="buttonBlue" disabled={props.showFeedback2FA || !values.code_2fa}>
                              {props.t('configurations.security.tab_app.button_save')}
                            </Button>
                          </Row>
                          {
                            props.companyHasEmail2FA && props.defaultIsApp2FA &&
                            <Row className='mb-4 mt-3 mx-3'>
                              <span
                                style={allStyles.linkChangeMethod}
                                onClick={() => props.changeDefault2FA('email')} >
                                { props.t('login.button_use_email') }
                              </span>
                            </Row>
                          }
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              }
            </Row>
            <Row style={allStyles.backgroundLogin2} >
              <Col className="d-flex justify-content-center ">
                <p className="align-self-center nopadding">{props.t('login.copyright')}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container >
    </>
  );
}

export default LoginHome;