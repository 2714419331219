import React from "react"

export class EmailService {
    private emailCC;
    private emailCCO;

    public setEmailCC(emailCC: Array<string>) {
        return this.emailCC = emailCC
    }

    public setEmailCCO(emailCCO: Array<string>) {
        return this.emailCCO = emailCCO
    }

    public getEmailCC() {
        return this.emailCC
    }

    public getEmailCCO() {
        return this.emailCCO
    }
}

export const email = new EmailService();

export const EmailContext = React.createContext(email)