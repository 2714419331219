// import { ApiUrls } from "../enums/api-url";

/*
 * Altere o valor da constante abaixo para apontar
 * para a URL correta do backend da aplicação.
 * Leia o arquivo "src/core/enums/api-url.ts" para entender melhor
 * o valor de cada modo.
 */
const BACKEND_URL = process.env.REACT_APP_API_URL;

export { BACKEND_URL };
