import { useEffect, useState } from 'react'
import FormConfigHistoric from '.'
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../store/token';
import BackendConstants from '../../../../core/constants/backend-constants';

const FormConfigHistoricController = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController()

    const [activeTab, setActiveTab] = useState('contrato')

    const [logsCompany, setLogsCompany] = useState([])
    const [logsAgent, setLogsAgent] = useState([])
    const [logsChanel, setLogsChanel] = useState([])
    const [logsContract, setLogsContract] = useState([])
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)

    const [logsCompanyPage, setLogsCompanyPage] = useState(1)
    const [logsAgentPage, setLogsAgentPage] = useState(1)
    const [logsChanelPage, setLogsChanelPage] = useState(1)
    const [logsContractPage, setLogsContractPage] = useState(1)

    let { state } = useLocation()

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        setLogsCompanyPage((page) => {
            getHistoryCompanyById({ limit: currentLimit, page: page });
            return page
        })
    }, [logsCompanyPage])

    useEffect(() => {
        setLogsAgentPage((page) => {
            getHistoryAgentById({ limit: currentLimit, page: page });
            return page
        })
    }, [logsAgentPage])

    useEffect(() => {
        setLogsChanelPage((page) => {
            getHistoryChanelById({ limit: currentLimit, page: page });
            return page
        })
    }, [logsChanelPage])

    useEffect(() => {
        setLogsContractPage((page) => {
            getHistoryContractById({ limit: currentLimit, page: page });
            return page
        })
    }, [logsContractPage])

    const updateListFunctionCompany = () => {
        setLogsCompanyPage(logsCompanyPage + 1)
    }

    const updateListFunctionAgents = () => {
        setLogsAgentPage(logsAgentPage + 1)
    }

    const updateListFunctionChannel = () => {
        setLogsChanelPage(logsChanelPage + 1)
    }

    const updateListFunctionContract = () => {
        setLogsContractPage(logsContractPage + 1)
    }

    const getHistoryCompanyById = async (params: { limit: number, page: number }) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await AppRequesterConst.Get(
            `/panel/logs/company/${state.id}`, { headers, params },
            (response: Object) => { },
            (data: any) => {
                if ((data?.status === 200 || data?.status === 204) && data?.data?.logs?.length > 0) {
                    setLogsCompany((state) => {
                        state = state.concat(data.data.logs);
                        return state;
                    });
                    /* setAux((state) => {
                        let aux = state.concat(data?.data?.logs)
                        setAux(aux)
                        setLogsCompany(aux)
                        return state
                    }) */
                } /* else if (aux.length === 0) {
                    setLogsCompany(undefined)
                } */
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, props.setIsLoading, {}
        )
    }

    const getHistoryAgentById = async (params: { limit: number, page: number }) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await AppRequesterConst.Get(
            `/panel/logs/employee/company/${state.id}`, { headers, params },
            (response: Object) => { },
            (data: any) => {
                if ((data?.status === 200 || data?.status) && data?.data?.log_agents?.length > 0) {
                    setLogsAgent((state) => {
                        state = state.concat(data.data.log_agents);
                        return state;
                    })
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, props.setIsLoading, {}
        )
    }

    const getHistoryChanelById = async (params: { limit: number, page: number }) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await AppRequesterConst.Get(
            `/panel/logs/channel/${state.id}`, { headers, params },
            (response: Object) => { },
            (data: any) => {
                if ((data?.status === 200 || data?.status === 204) && data?.data?.logs?.length > 0) {
                    setLogsChanel((state) => {
                        state = state.concat(data.data.logs);
                        return state;
                    })
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, props.setIsLoading, {}
        )
    }

    const getHistoryContractById = async (params: { limit: number, page: number }) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await AppRequesterConst.Get(
            `/panel/logs/contract/${state.id}`, { headers, params },
            (response: Object) => { },
            (data: any) => {
                if ((data?.status === 200 || data?.status === 204) && data?.data?.logs?.length > 0) {
                    setLogsContract((logsState) => {
                        logsState = logsState.concat(data.data.logs);
                        return logsState;
                    });
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, props.setIsLoading, {}
        )
    }

    const dataExibition = (dataString) => {
        const dataOriginal = new Date(dataString).toISOString()
        const partes = dataOriginal.split(/[-T:.Z]/);

        const dataFormatada = `${partes[2]}/${partes[1]}/${partes[0]} ${partes[3]}:${partes[4]}`;

        return dataFormatada;
    };

    const formatDate = (date: string) => {
        const new_date = new Date(date);
        return `${new_date.toLocaleDateString('pt-BR')} às ${new_date.toLocaleTimeString('pt-BR').slice(0,5)}`;
    };

    const chanelLabel = (item) => {
        if (item?.channel_id === 'aa1cea93-23de-46aa-af14-d6f766acf5c8') {
            return 'Facebook';
        }
        else if (item?.channel_id === 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2') {
            return 'Instagram';
        }
        else if (item?.channel_id === '33be81d8-88ac-4e08-bb4d-cf69fd23267f') {
            return 'Whatsapp'
        }
        else if (item?.channel_id === '4a7b71bb-6432-4794-ad11-f978b067871d') {
            return 'Webchat'
        }
        else if (item?.template_message_id) {
            return 'Whatsapp'
        }
        else {
            return 'N/A'
        }
    }

    const actionLabel = (item) => {
        if (item.action === 'REPLACE') {
            return 'Edição';
        }
        else if (item.action === 'CREATE') {
            return 'Criação';
        }
        else if (item.action === 'DELETE') {
            return 'Exclusão'
        }
        else {
            return 'N/A'
        }
    }

    const statusValueLabel = (value: string) => {
        if (value === 'e62b6586-6ff9-4389-9028-2c1b82036862') {
            return 'Inativo';
        }
        else if (value === '345ca1be-ac4c-45f5-b6f3-3c7924fc2d6b') {
            return 'Ativo';
        }
        else if (value === 'true') {
            return 'Sim';
        }
        else if (value === 'false') {
            return 'Não';
        }
        else if (value === 'ec2f67da-d7b0-4486-85a7-b8f297c5dc3c') {
            return 'Agente';
        }
        else if (value === '1d9a359a-910e-45a0-9bfd-29eedf814b12') {
            return 'Administrador';
        }
        else if (value === 'a90baf90-b2b4-4ed9-a268-4be4f6addb1c') {
            return 'Proprietario';
        }
        else if (value === '5f2a7f61-c784-4b1a-93f5-ec989686ba58') {
            return 'Master';
        }
        else {
            if (value.toString().length > 40) {
                return `${value.substring(0, 40)}...`
            }
            else {

                return value;
            }
        }
    }

    const fieldLabel = (item) => {
        if (item.field === "auth_token") {
            return 'token de autenticação';
        }
        else if (item.field === 'channels') {
            return 'canais';
        }
        else if (item.field === 'name') {
            return 'nome';
        }
        else if (item.field === 'profile_id') {
            return 'a permissão';
        }
        else if (item.field === 'access_token') {
            return 'token de acesso';
        }
        else if (item.field === 'broker_id') {
            return 'id do broker';
        }
        else if (item.field === 'quantity_agents') {
            return 'quantidade de agentes';
        }
        else if (item.field === 'has_bot') {
            return 'possui bot';
        }
        else if (item.field === 'phone_number') {
            return 'número de telefone';
        }
        else if (item.field === 'started_date') {
            return 'data inicial';
        }
        else if (item.field === 'expiration_date') {
            return 'data de expiração'
        }
        else if (item.field === 'contract_status_id') {
            return 'status da empresa'
        }
        else if (item.field === 'company_id') {
            return 'id da compania'
        }
        else if (item.field === 'consumer_custom_fields_limit') {
            return 'quantidade de campos customizados de consumidores'
        }
        else if (item.field === 'ticket_custom_fields_limit') {
            return 'quantidade de campos customizados de tickets'
        }
        else if (item.field === 'ticket_custom_fields_limit') {
            return 'quantidade de campos customizados de tickets'
        }
        else if (item.field === 'ticket_custom_fields_limit') {
            return 'quantidade de campos customizados de tickets'
        }
        else if (item.field === 'ticket_custom_fields_limit') {
            return 'quantidade de campos customizados de tickets'
        }
        else if (item.field === 'page_id') {
            return 'id da página'
        }
        else if (item.field === 'page_name') {
            return 'nome da página'
        }
        else if (item.field === 'acess_token') {
            return 'token de acesso'
        }
        else if (item.field === 'bot_url') {
            return 'url do bot'
        }
        else if (item.field === 'api_url') {
            return 'url da api'
        }
        else if (item.field === 'is_active') {
            return 'está ativa'
        }
        else if (item.field === 'user_id') {
            return 'id do usuário'
        }
        else if (item.field === 'subaccount_name') {
            return 'nome'
        }
        else if (item.field === 'auth_token_cxpress') {
            return 'token de acesso da Cxpress'
        }
        else if (item.field === 'auth_token_client') {
            return 'token de acesso do cliente'
        }
        else if (item.field === 'webchat_id') {
            return 'id do chatbot'
        }
        else if (item.field === 'subdomain') {
            return 'subdominio'
        }
        else if (item.field === 'employee_amount') {
            return 'subdominio'
        }
        else if (item.field === 'ticket_life_time') {
            return 'quantidade de Agentes'
        }
        else if (item.field === 'use_opening_hours') {
            return 'horário de atendimento ativo'
        }
        else if (item.field === 'use_opening_hours') {
            return 'dias de atendimento'
        }
        else if (item.field === 'url_send_message_cxpress') {
            return 'url da cxpress'
        }
        else if (item.field === 'url_send_message_client') {
            return 'url da api do cliente'
        }
        else if (item.field === 'message_opening_hours') {
            return 'mensagem de fora do horário de atendimento'
        }
        else if (item.field === 'enable_nps') {
            return 'NPS ativo'
        }
        else if (item.field === 'nps_wait_time') {
            return 'tempo de finalização da NPS'
        }
        else if (item.field === 'message_in_attendance') {
            return 'mensagem dentro do horário de atendimento'
        }
        else if (item.field === 'ticket_life_time_level_one') {
            return 'tempo de vida útil do ticket N1'
        }
        else if (item.field === 'enable_2fa') {
            return '2FA ativo'
        }
        else if (item.field === 'ticket_automatic_distribution') {
            return 'distribuição automática de ticket'
        }
        else if (item.field === 'ticket_limit_per_agent') {
            return 'limite de ticket por agente'
        }
        else if (item.field === 'no_sector_distribute_ticket') {
            return 'distribuição automática de ticket'
        }
        else if (item.field === 'enable_employee_master') {
            return 'agenter master ativo'
        }
        else if (item.field === 'show_name_in_messages') {
            return 'exibir nome do agente na mensagem'
        }
        else if (item.field === 'ticket_limit_per_agent') {
            return 'limite de ticket por agente'
        }
        else if (item.field === null) {
            return 'N/A'
        }
        else {
            return item.field
        }
    }

    return <FormConfigHistoric
        logsAgent={logsAgent}
        logsCompany={logsCompany}
        logsChanel={logsChanel}
        logsContract={logsContract}
        formatDate={formatDate}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        chanelLabel={chanelLabel}
        actionLabel={actionLabel}
        updateListFunctionCompany={updateListFunctionCompany}
        updateListFunctionAgents={updateListFunctionAgents}
        updateListFunctionChannel={updateListFunctionChannel}
        updateListFunctionContract={updateListFunctionContract}
        fieldLabel={fieldLabel}
        dataExibition={dataExibition}
        statusValueLabel={statusValueLabel}
    />
}

export default FormConfigHistoricController