import { useTranslation } from "react-i18next";
import { setShowAlertFeedback, setShowLinkModal } from "../../../../store/internal"
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { IModalEditPhoneController } from "./indexModal";
import ModalEditLink from ".";

const ModalEditLinkController: FC<IModalEditPhoneController> = (props) => {

    const { t } = useTranslation();
    const [/*isLoading*/, setIsLoading] = useState(false)
    const [validated] = useState(false);

    const [/*customers_phone*/, SetCustomers_phone] = useState(null as string || null);
    const [phoneBorder, /*setPhoneBorder*/] = useState('')
    const [selected_departments, setSelected_departments] = useState([]);
    const [modalShow, setModalShow] = useState(props.visibility);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setModalShow(props.visibility);
    }, [props.visibility])


    const Hide = () => {
        dispatch(setShowLinkModal(false));
        setModalShow(false);
    }

    const copyToClipboard = (e) => {
        var copyText = e
        navigator.clipboard.writeText(copyText)
        dispatch(setShowAlertFeedback({ message: t('home_channels.clipboard_copy'), visibility: true, signalIcon: true }));
    }

    return <ModalEditLink
        modalShow={modalShow}
        Hide={Hide}
        number={props?.number}
        phoneId={props?.phoneId}
        selected_departments={selected_departments}
        token={props?.token}
        getListWhatsapp={props?.getListWhatsapp}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        validated={validated}
        SetCustomers_phone={SetCustomers_phone}
        t={t}
        phoneBorder={phoneBorder}
        copyToClipboard={copyToClipboard}
    />
}

export default ModalEditLinkController