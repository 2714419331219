import { createSlice } from '@reduxjs/toolkit';

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    id: null as string | null,
    status_employee_id: null as string | null,
    authentication_method_default: null as string | null,
    has_app_authentication: false as boolean | null,
    employee_language: null as string | null,
    has_phone_integration: false as boolean | null,
  },
  reducers: {
    setIdEmployee: (state, action) => {
      state.id = action.payload;
    },
    setIdStatusEmployee: (state, action) => {
      state.status_employee_id = action.payload;
    },
    setDefaultAuthMethodEmployee: (state, action) => {
      state.authentication_method_default = action.payload;
    },
    setHasAuthAppEmployee: (state, action) => {
      state.has_app_authentication = action.payload;
    },
    setLanguageEmployee: (state, action) => {
      state.employee_language = action.payload;
    },
    setHasPhoneIntegrationEmployee: (state, action) => {
      state.has_phone_integration = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setIdEmployee, 
  setIdStatusEmployee,
  setDefaultAuthMethodEmployee,
  setHasAuthAppEmployee,
  setLanguageEmployee,
  setHasPhoneIntegrationEmployee,
} = employeeSlice.actions;

export const getIdEmployee = (state: { employee: { id: string; }; }) => (state.employee?.id);
export const getIdStatusEmployee = (state: { employee: { status_employee_id: string } }) => (state.employee?.status_employee_id);
export const getDefaultAuthMethodEmployee = (state: { employee: { authentication_method_default: string | null } }) => (state.employee?.authentication_method_default);
export const getHasAuthAppEmployee = (state: { employee: { has_app_authentication: boolean | null } }) => (state.employee?.has_app_authentication);
export const getLanguageEmployee = (state: { employee: { employee_language: string | null } }) => (state?.employee?.employee_language);
export const getHasPhoneIntegrationEmployee = (state: { employee: { has_phone_integration: boolean | null } }) => (state.employee?.has_phone_integration);

export default employeeSlice.reducer;
