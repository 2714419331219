import { useEffect, useState } from "react"
import UserService from "../../../../../services/user-service";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";

import { getIdCompany } from '../../../../../store/company'

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/token";
import SignatureTab from ".";
import { useTranslation } from "react-i18next";

const AppRequesterConst = new AppRequesterController();

const SignatureTabController = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [/*isLoading*/, setIsLoading] = useState(false);

    const { t } = useTranslation()

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        token: {
            value: useSelector(getToken),
        }
    };

    const [signatureId, setSignatureId] = useState("");
    const [html, setHtml] = useState(undefined);

    const htmlExample =
        `<div style="margin-bottom: 8px;">
            <p style="font: normal normal bold calc(0.2em + 0.8vw) Montserrat; color: #0C2461;">
                {{name-employee}}
            </p>
            <p style="font: normal normal bold calc(0.3rem + 0.55vw) Roboto; color: #707070; margin-top: -2vh;">
                {{name-sector}}
            </p>
            <p style="font: normal normal calc(0.3rem + 0.55vw) Roboto; color: #0C2461; margin-top: -2vh;">
                {{role-employee}}
            </p>
            <p style="font: normal normal normal calc(0.3rem + 0.55vw) Roboto; color: #707070; margin-top: -1vh;"> Email:
                <a href="mailto:agente@contato.com" style="font: normal normal normal calc(0.3rem + 0.55vw) Roboto; color: #707070;">
                    {{email-employee}}
                </a>
            </p>
            <p style="font: normal normal normal calc(0.3rem + 0.55vw) Roboto; color: #707070; margin-top: -2vh;">Whatsapp:
                <a href="tel:+55 87 99999 9999" style="font: normal normal normal calc(0.3rem + 0.55vw) Roboto; color: #707070;">
                    {{phone-employee}}
                </a>
            </p>
            <img src='https://blog.cxpress.io/wp-content/uploads/2022/11/CXpress-logo-2-300x99.png' alt=''  style="margin-top: -2vh; height: 75px !important;" />
        </div>`;

    /* const [htmlView, setHtmlView] = useState(
        <div style={{ marginBottom: '8px' }}>
            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                Nome do Agente
            </p>
            <p style={{ font: 'normal normal bold calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '-2vh' }}>
                Setor de operações
            </p>
            <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '-1vh' }}> Email:
                <a href="mailto:agente@contato.com" style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>
                    agente@contato.com
                </a>
            </p>
            <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '-2vh' }}>Whatsapp:
                <a href="tel:+55 87 99999 9999" style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>
                    +55 87 99999 9999
                </a>
            </p>
            <img src='https://blog.cxpress.io/wp-content/uploads/2022/11/CXpress-logo-2-300x99.png' alt='' height={'75'} style={{ marginTop: '-2vh' }} />
        </div>
    ) */

    useEffect(() => {
        insertHtmlExample(html);
    }, [html, signatureId]);

    useEffect(() => {
        getSignature();
    }, [])

    const insertHtmlExample = (html: string) => {
        let htmlExample = document.getElementById("html_example");


        if (htmlExample !== null) {
            if (html !== "") {
                let divContent = document.createElement("div");
                divContent.innerHTML = html;

                htmlExample.innerHTML = "";
                htmlExample.appendChild(divContent);
            } else {
                htmlExample.innerHTML = "";
            }
        }
    }

    const getSignature = async () => {

        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/company/signature/' + values.company.id, { headers },
            // '/company/signature_fields', { headers },
            (response: Object) => { },
            (data) => {
                if (data.status === 200) {
                    setSignatureId(data.data.signature?.id);

                    if (data.data.signature?.html)
                        setHtml(data.data.signature?.html);
                    else
                        setHtml(htmlExample);
                } else {
                    setHtml(htmlExample);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    return <SignatureTab
        html={html}
        setHtml={setHtml}
        signatureId={signatureId}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        values={values}
        AppRequesterConst={AppRequesterConst}
        t={t}
    />

}

export default SignatureTabController;