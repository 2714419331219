import React from 'react';

import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Loading from '../../../../../components/loading'

import allStyles from "../styles"
import { IconBrandFacebook } from '@tabler/icons-react';
import ModalEditFacebookPageController from '../../modalEditFacebookPage/indexController';

const ListConfigFacebook = ({
    props,
    isLoading,
    popover,
    getAccountById,
    data,
    showModalEdit,
    handleCloseModalEdit,
    openPopover,
    infos,
    getFacebookById,
    isEditAllowed,
}) => {
    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <div className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={allStyles.TextRowCollums}>
                        <Row name="ListOfAgentsHead">
                            <Col md={3} className="nopadding">
                                <p>Nome da página</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>Possui bot?</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>Ativo?</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                Data da criação
                            </Col>
                            <Col className='justify-content-end d-flex'>
                                {isEditAllowed && <p>Ações</p>}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    infos?.length !== 0 ?
                        <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
                            {
                                <ListGroup variant="flush" className="TicketsRow">
                                    {infos
                                        .slice()
                                        .sort((a, b) => {
                                            const nameA = a.employee_name ? a.employee_name.toLowerCase() : 'n/a';
                                            const nameB = b.employee_name ? b.employee_name.toLowerCase() : 'n/a';
                                            return nameA.localeCompare(nameB);
                                        })
                                        .map((item, index) => {
                                            return (
                                                <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                                    <Row className='TicketsRowHeight'>
                                                        <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item?.pageName != null ?
                                                                                <>{item?.pageName}</>
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.pageName}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item?.hasBot ?
                                                                                <>Sim</>
                                                                                :
                                                                                <>Não</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.hasBot ?
                                                                            <>Sim</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item?.isActive ?
                                                                                <>Sim</>
                                                                                :
                                                                                <>Não</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.isActive ?
                                                                            <>Sim</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {new Date(item?.createdAt).toLocaleDateString()} {/* Formata a data no formato de data normal */}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {new Date(item?.createdAt).toLocaleDateString()} {/* Formata a data no formato de data normal */}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col className='d-flex justify-content-end' style={{ marginLeft: '2.3rem' }} >
                                                            {
                                                                isEditAllowed &&
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                    <Button onClick={() => openPopover(item.pageId, item.id, item.pageName)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            }
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={2} className="d-flex justify-content-center">
                                    <IconBrandFacebook size={80} color="#4a69bd" />
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '2%', backgroundColor: '#F9F9F9' }}>
                                <Col md={6}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, adicione uma página do Facebook</p>
                                </Col>
                            </Row>
                        </div>
                }
                <ModalEditFacebookPageController
                    show={showModalEdit}
                    handleClose={handleCloseModalEdit}
                    data={data}
                    getFacebookById={getFacebookById}
                />
            </div>
        </>
    );

}

export default ListConfigFacebook;