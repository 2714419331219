import { Row, Col, Button, InputGroup, Form, Tooltip, OverlayTrigger } from "react-bootstrap"
import { Formik } from "formik"
import UserService from "../../../../../services/user-service";
import { setShowAlertFeedback } from "../../../../../store/internal";

// import allStyles from '../styles';

const SignatureTab = ({
    html,
    setHtml,
    signatureId,
    dispatch,
    navigate,
    setIsLoading,
    values,
    AppRequesterConst,
    t,
}) => {

    return (
        <>
            <Row>
                {
                    html !== undefined?
                    <Formik
                        initialValues={{ html: html }}
                        validate={values => {
                            let errors = {};
                            setHtml(values.html);

                            return errors;
                        }}

                        onSubmit={async () => {

                            let JsonSend = {
                                id: values.company.id,
                                signature: {
                                    html: html,
                                    field_ids: [
                                        "b0da9a1b-c45d-479d-a741-b087e70885bf",
                                        "fac0c1c2-9eac-4975-b074-8bc3b70a962e",
                                        "33832aea-288b-49bf-94eb-527fd3974c84",
                                        "9987ef3d-ea18-4f83-a133-50402dd4d248",
                                        "f38f3693-bda0-4122-9956-b0f04c1aa6c4"
                                    ]
                                }
                            }

                            if (signatureId !== '') {
                                JsonSend.signature['id'] = signatureId;
                            }

                            const headers = UserService.getHeaders()

                            if (html === "") {
                                await AppRequesterConst.Post(
                                    '/company/remove_signature/' + signatureId, { id: signatureId }, { headers },
                                    (response: Object) => { },
                                    (data) => {
                                        dispatch(setShowAlertFeedback({ message: t('configurations_ticket.signature_tab.post.message'), visibility: true, signalIcon: true }));
                                    },
                                    (error: any) => { 
                                        dispatch(setShowAlertFeedback({ message: t('configurations_ticket.signature_tab.post.error'), visibility: true, signalIcon: false }));
                                    }, navigate, dispatch, setIsLoading
                                );
                            } else {
                                await AppRequesterConst.Put(
                                    '/company', JsonSend, { headers: headers },
                                    (response: Object) => { },
                                    (data: Object) => {
                                        dispatch(setShowAlertFeedback({ message: t('configurations_ticket.signature_tab.put.message'), visibility: true, signalIcon: true }));
                                    },
                                    (error: Object) => {
                                        dispatch(setShowAlertFeedback({ message: t('configurations_ticket.signature_tab.put.error'), visibility: true, signalIcon: false }));
                                    }, navigate, dispatch, setIsLoading
                                );
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div style={{ overflow: 'auto', overflowX: 'hidden', height: '65vh', flexDirection: 'column', display: 'flex' }}>
                                    <Row style={{ height: '36%' }} className=" d-flex justify-content-start my-3">
                                        <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                            <Row>
                                                    <Form.Label className="d-flex align-items-center" htmlFor="html">{t('configurations_ticket.signature_tab.html')}
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id='tooltip'>
                                                                {t('configurations_ticket.signature_tab.html_tooltip_a')} {'{{name-employee}}'}, {'{{role-employee}}'}, {'{{email-employee}}'}, {'{{phone-employee}}'} e {'{{name-sector}}'} {t('configurations_ticket.signature_tab.html_tooltip_b')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                    <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }} >{t('configurations_ticket.signature_tab.warn_message')}</p>
                                                <InputGroup className="mb-3" >
                                                    <Form.Control className="form-control-Default-text-area"
                                                        style={{ height: '20vh' }}
                                                        as="textarea"
                                                            placeholder={t('configurations_ticket.signature_tab.placeholder_html')}
                                                            aria-label={t('configurations_ticket.signature_tab.placeholder_html')}
                                                            aria-describedby={t('configurations_ticket.signature_tab.placeholder_html')}
                                                        size='lg'
                                                        type='text'
                                                        // componentClass="textarea"
                                                        id="html"
                                                        name="html"
                                                        maxLength={500000}
                                                        onChange={handleChange}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Tab") {
                                                                let input = document.getElementById("html") as HTMLTextAreaElement;
                                                                let start = input.selectionStart, end = input.selectionEnd;

                                                                input.setRangeText('\t', start, end, 'end');
                                                                
                                                                e.preventDefault();
                                                                return false;
                                                            }
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.html}
                                                    >
                                                    </Form.Control>
                                                </InputGroup>
                                            </Row>
                                            <Row>
                                                    <Form.Label htmlFor="html" >{t('configurations_ticket.signature_tab.visual_example')}
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    {t('configurations_ticket.signature_tab.tooltip_visual_example')}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </OverlayTrigger>
                                                </Form.Label>
                                                <Col className="mx-3" style={{ border: '2px solid #F9F9F9', borderColor: '#F9F9F9', borderRadius: '5px' }}>
                                                    <div className="mx-2 mt-2" id="html_example">
                                                    </div>
                                                    {/* <div className="mx-2 mt-2">
                                                        {htmlView}
                                                    </div> */}
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <Row className="d-flex mt-4">
                                    <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                            <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => { document.location.reload(); }} >{t('configurations_ticket.signature_tab.cancel')}</Button>
                                    </Col>
                                    {
                                        html === ""?
                                        <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                                    <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{t('configurations_ticket.signature_tab.delete')}</Button>
                                        </Col>:
                                        <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                                    <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{t('configurations_ticket.signature_tab.save')}</Button>
                                        </Col>
                                    }
                                </Row>
                            </Form>
                        )}
                    </Formik>:
                    <></>
                }
            </Row>
        </>
    )
}

export default SignatureTab