import ColorControls from "./index"

const ColorPickerController = ({editorState, colors, onToggle}) => {
  return (
    <ColorControls
      editorState={editorState}
      onToggle={onToggle} 
      colors={colors}       
    />
  )
}

export default ColorPickerController;