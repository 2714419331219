import { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  setShowCreateEmailModal,
} from '../../../../store/internal'
import { ModalRegisterEmailInterface } from './indexModel';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import ModalRegisterEmail from '.';
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController();

const ModalRegisterEmailController: FC<ModalRegisterEmailInterface> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [/*isLoading*/, setIsLoading] = useState(false)
  const [validated] = useState(false);

  const [email, setEmail] = useState(null as string || null);
  const [showfeedbackEmail, setShowfeedbackEmail] = useState(false);
  const [email_isActive, setEmail_isActive] = useState(true);

  const [selected_departments, setSelected_departments] = useState([]);

  const [modalShow, setModalShow] = useState(props.visibility);

  const [options_departments, setOptions_departments] = useState(props.departments ? props.departments : []);

  useEffect(() => {
    setModalShow(props.visibility);
    setOptions_departments(props.departments ? props.departments : []);
    setSelected_departments([]);

    setShowfeedbackEmail(false);
  }, [props.visibility, props.departments])

  const selectDepartment = (value: string | any[]) => {
    if(value.length > 0) {
      setSelected_departments([value[value.length - 1]]);
    } else {
      setSelected_departments([]);
    }
  }

  const Hide = () => {
    dispatch(setShowCreateEmailModal(false));
    setModalShow(false);
    setEmail_isActive(true)
  }

  return <ModalRegisterEmail
    modalShow={modalShow}
    Hide={Hide}
    options_departments={options_departments}
    setOptions_departments={setOptions_departments}
    selected_departments={selected_departments}
    setSelected_departments={setSelected_departments}
    email={email}
    setEmail={setEmail}
    email_isActive={email_isActive}
    setEmail_isActive={setEmail_isActive}
    setShowfeedbackEmail={setShowfeedbackEmail}
    showfeedbackEmail={showfeedbackEmail}
    validated={validated}
    setIsLoading={setIsLoading}
    dispatch={dispatch}
    navigate={navigate}
    props={props}
    AppRequesterConst={AppRequesterConst}
    t={t}
  />
}

export default ModalRegisterEmailController;