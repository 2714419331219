import { FC, SetStateAction, useEffect, useState } from "react";
import Utils from "../../../../../../core/shared/utils";
import { TicketInterface } from "../../../../homeTickets/indexModel";
import { ModalHistoryLogControllerInterface } from "./indexModel";
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import tabStyles from './styles.module.css';
import { CurrentTicketHistoryLogInterface, GetAllTicketsInterface, TicketsHistoryInterface, TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../../store/token";
import UserService from "../../../../../../services/user-service";
import { ErrorRequestController } from "../../../../../../services/errorsInterfaceModel";
import ModalHistoryLog from ".";
import { useTranslation } from "react-i18next";

const ModalHistoryLogController: FC<ModalHistoryLogControllerInterface> = (props) => {
    /* private setLastTicketsHistoryList: Function
    private setClassCurrentTicket: Function
    private setClassLastTickets: Function
    private tabStyles: { [key: string]: string; }
    private setCurrentPage: Function
    private currentPage: number
    private setAux: Function
    private setCurrentFilterSearch: Function
    private setCurrentState: Function
    private currentState: string
    private currentFilterSearch
    private setCurrentParams: Function
    private props

    constructor(setLastTicketsHistoryList: Function, setClassCurrentTicket: Function, setClassLastTickets: Function, tabStyles: { [key: string]: string; }, setCurrentPage: Function, 
        currentPage: number, setAux: Function, setCurrentFilterSearch: Function, setCurrentState: Function, currentState: string, currentFilterSearch, setCurrentParams: Function, props) {
        setLastTicketsHistoryList = setLastTicketsHistoryList
        setClassCurrentTicket = setClassCurrentTicket
        setClassLastTickets = setClassLastTickets
        tabStyles = tabStyles
        setCurrentPage = setCurrentPage
        currentPage = currentPage
        setAux = setAux
        setCurrentFilterSearch = setCurrentFilterSearch
        setCurrentState = setCurrentState
        currentState = currentState
        currentFilterSearch = currentFilterSearch
        setCurrentParams = setCurrentParams
        props = props
    } */

    const AppRequesterConst = new AppRequesterController();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activeTabClass = tabStyles['nav-link'] + " " + tabStyles['nav'] + " " + tabStyles['active'];
    const normalTabClass = tabStyles['nav-link'] + " " + tabStyles['nav'];
    const [classCurrentTicket, setClassCurrentTicket] = useState(activeTabClass);
    const [classLastTickets, setClassLastTickets] = useState(normalTabClass);

    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentTicketHistoryList, setCurrentTicketHistoryList] = useState(null);
    const [lastTicketsHistoryList, setLastTicketsHistoryList] = useState(null);
    
    const [currentState, setCurrentState] = useState("");
    const [/*aux*/, setAux] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [currentLimit] = useState(BackendConstants.limitMin);
    //const [currentLimit] = useState(4);
    const [/*currentParams*/, setCurrentParams] = useState({});
    
    const [isLoading, setIsLoading] = useState(false);

    /* const ModalHistoryLogControllerConst = new ModalHistoryLogController(setLastTicketsHistoryList, setClassCurrentTicket, setClassLastTickets, tabStyles, setCurrentPage, currentPage, setAux,
        setCurrentFilterSearch, setCurrentState, currentState, currentFilterSearch, setCurrentParams, props); */

    const values: TokenInterface = {
        user: {
            name: null, lastname: null, profile_id: null,
        },
        company: {
            id: null,
        },
        token: {
            value: useSelector(getToken)
        },
    };

    useEffect(() => {
        if (props.requester !== null) {
            setCurrentState((state) => {
                setCurrentParams((currentParams) => {
                    getHistoryTickets(props.requester.user_id, currentParams);
                    return currentParams;
                });
                return state;
            });
        }
    }, [currentPage, currentState]);

    useEffect(() => {
        setCurrentFilterSearch(null);

        if (props.requester !== null) {
            setCurrentState((state) => {
                setCurrentParams((currentParams) => {
                    getHistoryTickets(props.requester.user_id, currentParams);
                    return currentParams;
                });
                return state;
            });
        }
    }, [props.requester]);

    useEffect(() => {
        if (props.currentTicket !== null) {
            setCurrentState((state) => {
                setCurrentParams((params) => {
                    getHistoryCurrentTicket(props.currentTicket.id, params);
                    return params;
                });
                return state;
            });
        }
    }, [props.currentTicket]);

    // get tickets to all, agent tickets, finalized or deleted
    const getHistoryTickets = async (userId: string, params: {}) => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/user/' + userId + "/tickets", { headers, params: params },
            (response: Object) => { },
            (data : GetAllTicketsInterface | TicketsHistoryInterface) => {
                if (data.status === 200 && data.data.tickets.length > 0) {
                    setLastTicketsHistoryList(data.data.tickets);
                    /* setAux((state) => {
                        if (state.length > 0 && state.length >= 50) {
                            return listValidator(state, data.data.tickets);
                        } else {
                            setLastTicketsHistoryList(data.data.tickets);
                            return data.data.tickets;
                        }
                    }); */
                } else /* if (aux.length === 0) */ {
                    setLastTicketsHistoryList(undefined);
                }
            },
            (error:ErrorRequestController) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getHistoryCurrentTicket = async (ticketId: string, params: {}) => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/ticket/' + ticketId + "/log", { headers, params: params },
            (response: Object) => { },
            (data: CurrentTicketHistoryLogInterface) => {
                if (data.status === 200 && data.data && data.data.logTicket.length > 0) {
                    setCurrentTicketHistoryList(data.data.logTicket.map((log) => {
                        return {
                            action: log.action,
                            ticket_id: log.ticket_id,
                            created_at: log.created_at,
                            new_value: log.new_value === "Novo" ? t('home_tickets.history_log.status.new') :
                                       log.new_value === "Aberto" ? t('home_tickets.history_log.status.open') :
                                       log.new_value === "Em espera" ? t('home_tickets.history_log.status.on_hold') :
                                       log.new_value === "Pendente" ? t('home_tickets.history_log.status.pending') :
                                       log.new_value === "Resolvido" ? t('home_tickets.history_log.status.resolved') :
                                       log.new_value === "Abandonado" ? t('home_tickets.history_log.status.abandoned') : log.new_value,
                            old_value: log.old_value === "Novo" ? t('home_tickets.history_log.status.new') :
                                       log.old_value === "Aberto" ? t('home_tickets.history_log.status.open') :
                                       log.old_value === "Em espera" ? t('home_tickets.history_log.status.on_hold') :
                                       log.old_value === "Pendente" ? t('home_tickets.history_log.status.pending') :
                                       log.old_value === "Resolvido" ? t('home_tickets.history_log.status.resolved') :
                                       log.old_value === "Abandonado" ? t('home_tickets.history_log.status.abandoned') : log.old_value,
                            employee_name: log.employee_name === "Sistema" ? t('home_tickets.history_log.system') : log.employee_name,
                            field: log.field === "Solicitante" ? t('home_tickets.history_log.field.requester') : 
                                   log.field === "Setor" ? t('home_tickets.history_log.field.sector')  :
                                   log.field === "Atribuído" ? t('home_tickets.history_log.field.assigned')  :
                                   log.field === "Seguidores" ? t('home_tickets.history_log.field.followers')  :
                                   log.field === "Status" ? t('home_tickets.history_log.field.status')  :
                                   log.field === "Tags" ? t('home_tickets.history_log.field.tags')  :
                                   log.field === "Assunto" ? t('home_tickets.history_log.field.subject')  :
                                   log.field === "Data Limite" ? t('home_tickets.history_log.field.deadline_date')  :
                                   log.field === "Hora Limite" ? t('home_tickets.history_log.field.deadline_time')  : 
                                   log.field === "Assunto do ticket" ? t('home_tickets.history_log.field.ticket_subject')  : log.field
                        };
                    }));
                } else {
                    setCurrentTicketHistoryList(undefined);
                }
            },
            (error:ErrorRequestController) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const propagationClickTabLastTickets = () => {
        let lastTicketsButton = document.getElementById("historyTicketsTabs-tab-last_tickets");

        lastTicketsButton.click();
    }

    const listValidator = (state: any[], tickets: Array<TicketInterface>) => {
        let aux2 = [];

        for (let i = 0; i < tickets.length; i++) {
            let validador = true;
            for (let j = 0; j < state.length; j++) {
                if ((state[j].id === tickets[i].id)) {
                    validador = false;
                }
            }

            if (validador) {
                aux2.push(tickets[i]);
            }
        }

        let concatArrays = state.concat(aux2);
        setLastTicketsHistoryList(concatArrays);
        return concatArrays;
    }

    /* tabActive(tabSelected: string) {
        if (tabSelected === "last_tickets") {

        }
    } */

    const tabActive = (selectedTab: string) => {  
        if (selectedTab === "current_ticket") {
            setClassCurrentTicket(activeTabClass);
            setClassLastTickets(normalTabClass);
        } else {
            setClassCurrentTicket(normalTabClass);
            setClassLastTickets(activeTabClass);
        }
        
    }

    // globalizar esta função
    const formatedDate = (date: Date) => {
        const day = Utils.addLeftZeros(date.getDate());
        const month = Utils.addLeftZeros(date.getMonth() + 1);
        const year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
        /* setAux((prevState) => {
            if (prevState.length > (49 * currentPage)) {
                setCurrentPage(currentPage + 1);
                return prevState;
            } else {
                return prevState;
            }
        }); */
    }

    const filteringTickets = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj: {}, action: string) => {
        setCurrentPage(1);
        setAux([]);
        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        let name = "";
        if (e.currentTarget) {
            name = e.currentTarget.name;
        } else if (e.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        setCurrentParams(auxObj);
    }

    const openSelectedTicket = (e: { currentTarget: { id: string; }; }) => {
        if (props.getTicket)
            props.getTicket( { id: e.currentTarget.id }, {});
    }

    return <ModalHistoryLog
        showHistoryLog={props.showHistoryLog}
        requester={props.requester}
        currentTicket={props.currentTicket} 
        isLoading={isLoading} 
        tabActive={tabActive} 
        classCurrentTicket={classCurrentTicket} 
        currentTicketHistoryList={currentTicketHistoryList} 
        formatedDate={formatedDate} 
        classLastTickets={classLastTickets} 
        filteringTickets={filteringTickets} 
        setCurrentFilterSearch={setCurrentFilterSearch} 
        currentFilterSearch={currentFilterSearch} 
        lastTicketsHistoryList={lastTicketsHistoryList} 
        openSelectedTicket={openSelectedTicket}    
        t={t}
        setShowPopoverHistoryLog={props.setShowPopoverHistoryLog}
    />
}

export default ModalHistoryLogController;