import { Button, Col, ListGroup, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap"
import styles from "../styles"

const ListConfigAccounts = (props) => {

  const popoverOptions = (data) => (
    <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
      <Popover.Body className="nopadding">
        <Button onClick={() => props.modalIntegrateAccount() } className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Integrar com agente (CX)</Button>
        <div>
          <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
          <Button onClick={()=> {props.openMoreInfo(data)} } className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Mais informações</Button>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <div  className="ps-3">
      <ListGroup variant="flush">
        <ListGroup.Item style={styles.textRowCollums}>
          <Row name="ListOfAgentsHead">
            <Col md={3} className="nopadding">
              <p>Nome</p>
            </Col>
            <Col md={7} className="nopadding">
              <p>E-mail</p>
            </Col>
            <Col md={2} className='nopadding'>
              { props.isEditAllowed && <p>Ações</p>}
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>

      {
        props.list &&
        <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
          <ListGroup variant="flush" className="TicketsRow">
            {props.list.map((item, index) => (
              <ListGroup.Item key={index} style={{ color: '#707070' }}>
                <Row className='TicketsRowHeight'>
                  <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                    <div className="oneLineClampEllipsis me-3">
                      <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                          <Tooltip id='tooltip'>
                            {item?.name ?
                              <>{item?.name}</>
                              :
                              <>N/A</>
                            }
                          </Tooltip>
                        }
                        >
                        <span>{item.name}</span>
                      </OverlayTrigger>
                    </div>
                  </Col>

                  <Col md={6} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                    <div className="oneLineClampEllipsis me-6">
                      <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                          <Tooltip id='tooltip'>
                            {item?.email ?
                              <>{item?.email}</>
                              :
                              <>N/A</>
                            }
                          </Tooltip>
                        }
                        >
                        <span>{item.email}</span>
                      </OverlayTrigger>
                    </div>
                  </Col>

                  <Col md={1} className='d-flex justify-content-end' style={{marginLeft:'2.3rem'}} >
                  {
                    props.isEditAllowed &&
                    <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popoverOptions(item)}>
                      <Button onClick={() => props.setAccountAux(item)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="1" />
                            <circle cx="12" cy="19" r="1" />
                            <circle cx="12" cy="5" r="1" />
                        </svg>
                      </Button>
                    </OverlayTrigger>
                }
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      }
    </div>
  )
}

export default ListConfigAccounts