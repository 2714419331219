type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  }
}

const styles = StyleSheet.create({
  rowAlert: {
    width: '100% ',
    padding: '15px',
    minHeight: '6vh',
    background: '#ffffffeb',
    borderRadius: '3px',
    boxShadow: '0px 3px 6px #00000029',
    opacity: '1',
  },

  customContainer: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    width: 'fit-content',
    paddingRight: 0,
    paddingLeft: 0,
    maxWidth: '400px',
    zIndex: 2000,
  },

  timestamp: {
    fontWeight: 'normal',
    color: '#c1c1c1',
    fontSize: 'smaller',
    marginTop: '5px',
  },
});

export default styles;
