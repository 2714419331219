import { FC } from 'react';
import './styles.scss';
import { CallInstructionsInterface } from './indexModel';
import { IconInfoCircle } from '@tabler/icons-react';

const CallInstructions: FC<CallInstructionsInterface> = ({
  searchTerm,
  setSearchTerm,
  getFilteredInstructions,
  filteredLength,
  t,
}) => {

  return (
    <div className="scene-instructions">
      <span className='scene-title'>{t('phone_callcenter.main_screen.call_instructions.instructions_for_use')}</span>
      <div className="search-instruction">
        <input 
          type="text" 
          className="form-control" 
          placeholder={t('phone_callcenter.main_screen.call_instructions.search')}
          value={searchTerm} 
          onChange={e => setSearchTerm(e.target.value)} 
        />
      </div>
      {
        filteredLength === 0 && 
        <div className='scene-list-empty'>
          <IconInfoCircle size={42} color="#4a69bd" />
          <span className='empty-text'>{t('phone_callcenter.main_screen.call_instructions.no_instruction_found')}</span>
        </div>
      }
      <div className="topics">
        {getFilteredInstructions().map((item, index) => {
          return (
            <div className="topic-item" key={`instructions-${index}`}>
              <div className="topic-header">
                {item.icon}
                <span className="topic-title">{item.title}</span>
              </div>
              <div className="topic-content">
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CallInstructions;