import React from 'react';

import allStyles from './styles';

import { Modal, Row, Container, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';

import { setShowAlertFeedback, setShowUpdatedBot } from '../../../../../store/internal'
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';

const AppRequesterConst = new AppRequesterController();

const RecoverAgentPassword = ({
  modalShow,
  Hide,
  props,
  dispatch,
  navigate,
  setIsLoading,
  showPassword,
  clickPassword,
  setSenha,
  senha,
  generateLink

}) => {

  return (
    <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" centered >
      <Modal.Header style={{ padding: '1rem 1rem 0rem 1rem' }} closeButton>
      </Modal.Header>
      <Modal.Header style={{ padding: '0rem 1.45rem' }}>
        <Modal.Title style={allStyles.textBlack}>
          Recuperar senha
          <p className="mt-2" style={allStyles.textFontGlay}>
            {`Deseja enviar o email de recuperação de senha para `}
            <a style={allStyles.textFontBlack}>{props.data.email}</a>
            {' ou apenas gerar um link?'}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Formik
            initialValues={{
              senha: "",
            }}
            validate={values => {
              let errors = {};
              setSenha(values.senha)
              return errors;
            }}
            onSubmit={async () => {

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              const JsonSend = {
                "company_id": props.data.company_id,
                "email": props.data.email
              };

              await AppRequesterConst.Post(
                `/panel/request-reset-password`, JsonSend, { headers },
                (response: Object) => { },
                (data: any) => {
                  if (data.status === 200) {
                    dispatch(setShowAlertFeedback({ message: `E-mail enviado com sucesso!`, visibility: true, signalIcon: true }));
                  }
                  props.callback()
                  Hide();
                },
                (error: { response: { status: number } }) => {
                  if (error.response.status === 400) {

                  }
                  if (error.response.status === 401) {

                  }
                }, navigate, dispatch, setIsLoading,
              );
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>

                <Row style={allStyles.textFontGlay} className="mt-3">
                  <Col md={3} >
                    <Button size='lg' className="buttonWhite2" style={{ width: '100%' }} onClick={() => Hide()} >Cancelar</Button>
                  </Col>
                  <Col md={1}>
                  </Col>
                  <Col md={4} className="d-flex">
                    <Button type='submit' size='lg' className="buttonBlue2WithoutBold" style={{ width: '100%' }}>Enviar e-mail</Button>
                  </Col>
                  <Col md={1}>
                  </Col>
                  <Col md={3} className="d-flex justify-content-end align-self-end">
                    <Button size='lg' className="buttonBlue2WithoutBold" style={{ width: '100%' }} onClick={() => {generateLink(props.data.company_id, props.data.email) }}>Gerar link</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default RecoverAgentPassword;