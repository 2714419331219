import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

export interface TaskNotification {
    created_at: string;
    deleted_at: string;
    field_ticket_id: string;
    id: string;
    read_at: string;
    sender_id: string;
    task_id: string;
    ticket_id: string;
    trigger_type: string;
    updated_at: string;
    user_id: string;
}

const useTaskNotificationSocket = () => {

    const socket = useContext(SocketContext)

    const task_notification = new BroadcastChannel('task_notification')

    useEffect(() => {
        socket.socket?.on('task-notification', (data: TaskNotification) => task_notification.postMessage(data.user_id))

        return () => {
            socket.socket?.off('task-notification', () => { })
        }

    }, [socket.socket?.active])

    return { task_notification }
}

export default useTaskNotificationSocket