import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getShowCreateDepartmentModal, getShowCreateIntegrationModal, getShowCreateTriageModal, getShowEditDepartmentModal, getShowEditIntegrationModal, getShowEditTriageModal, getShowMeetButton, setShowCreateDepartmentModal, setShowCreateIntegrationModal, setShowCreateTriageModal, setShowEditDepartmentModal, setShowEditIntegrationModal, setShowEditTriageModal } from '../../../../store/internal';
import { getToken } from '../../../../store/token';
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user';
import { getIdCompany } from '../../../../store/company';

import { getOwner_id } from '../../../../store/callcenter';
import BackendConstants from '../../../../core/constants/backend-constants';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import UserService from '../../../../services/user-service';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import HomeDepartments from './index';
import { useTranslation } from 'react-i18next';

const HomeDepartmentsController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appRequester = new AppRequesterController();
  const [isLoading, setIsLoading] = useState(false);
  //const [showAssociateTickets, setShowAssociateTickets] = useState();

  const [aux, setAux] = useState([]);
  const [currentDepartments, setCurrentDepartments] = useState(undefined);
  const [departmentsKeywords, /*setDepartmentsKeywords*/] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentState, setCurrentState] = useState("");
  const [currentParams, setCurrentParams] = useState({});
  const [currentLimit, /*setCurrentLimit*/] = useState(BackendConstants.limit);
  const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
  const [currentHasMoreInformation, setCurrentHasMoreInformation] = useState(true);

  const [eventKey, setEventKey] = useState('departments')

  const { t } = useTranslation();

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    callcenter: {
      owner_id: useSelector(getOwner_id),
      buttonShowPhone: useSelector(getShowMeetButton)
    },
    internal: {
      getShowEditDepartmentModal: useSelector(getShowEditDepartmentModal),
      getShowCreateDepartmentModal: useSelector(getShowCreateDepartmentModal),
      getShowEditTriageModal: useSelector(getShowEditTriageModal),
      getShowCreateTriageModal: useSelector(getShowCreateTriageModal),
      getShowEditIntegrationModal: useSelector(getShowEditIntegrationModal),
      getShowCreateIntegrationModal: useSelector(getShowCreateIntegrationModal),
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    setCurrentParams((params) => {
      setCurrentState((state) => {
        getAllDepartmentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllDepartments, controller);
        return state;
      });
      return params;
    });

    return () => {
      controller.abort();
    }
  }, [currentPage, currentState]);

  // fechando modais abertos ao carregar a página
  useEffect(() => {
    if (values.internal.getShowEditDepartmentModal) {
        dispatch(setShowEditDepartmentModal(false));
    }

    if (values.internal.getShowCreateDepartmentModal) {
      dispatch(setShowCreateDepartmentModal(false));
    }

    if (values.internal.getShowEditTriageModal) {
      dispatch(setShowEditTriageModal(false));
    }

    if (values.internal.getShowCreateTriageModal) {
      dispatch(setShowCreateTriageModal(false));
    }

    if (values.internal.getShowEditIntegrationModal) {
      dispatch(setShowEditIntegrationModal(false));
    }

    if (values.internal.getShowCreateIntegrationModal) {
      dispatch(setShowCreateIntegrationModal(false));
    }
}, []);

  const getAllDepartments = async (params: { limit: number; page: number; }, controller: AbortController) => {
    const headers = UserService.getHeaders();
    const signal = controller.signal;

    await appRequester.Get(
      '/sector', { headers, signal, params: params },
      (response: Object) => { },
      (data) => {
        if (data.status === 200) {
          setCurrentHasMoreInformation(true);
          setAux((state) => {
            let aux;
            if (data.data.sectors) {
              aux = state.concat(data.data.sectors);
            } else {
              aux = state.concat(data.data);
            }
            setCurrentDepartments(aux);
            return aux;
          });
        } else if (aux.length === 0 && data.status !== 204) {
          setCurrentDepartments(undefined);
        }

      },
      (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getAllDepartmentsToPagination = (params: { limit: number; page: number; }, getAllDepartments: Function, controller: AbortController) => {
    return getAllDepartments(params, controller);
  }

  const showCreateDepartment = () => {
    setCurrentPage(1);
    setAux([]);
    dispatch(setShowCreateDepartmentModal(true));
  }

  const callbackOrderByDepartments = (column: any, order: any) => {
    setCurrentPage(1);
    setAux([]);
    const auxObj = {};

    if (column != null) {
      auxObj["sort_by"] = column;
    }

    if (order != null) {
      auxObj["order_by"] = order;
    }

    if (currentFilterSearch !== null && currentFilterSearch !== "") {
      auxObj["search"] = currentFilterSearch;
    }
    
    setCurrentState(column + order);
    setCurrentParams(auxObj);
    if (document.getElementById("ListOfDepartments"))
      document.getElementById("ListOfDepartments").scrollTop = 0;
  }

  const search = (e: any, auxObj: Object, action: string) => {
    setCurrentPage(1);
    setAux([]);
    setCurrentDepartments(undefined);

    let name = "";
    if (e.currentTarget) {
      name = e.currentTarget.name;
    } else if (e.target) {
      name = e.target.name;
    }

    if (name === "cleanSearchButtonName" || action === "clean") {
      setCurrentFilterSearch("");
      auxObj = {};
      setCurrentState("searching");
      if (currentState === "searching") {
        setCurrentState("");
      }
    } else if (name === "searchButtonName" || name === "searchInputName") {
      auxObj = {};

      setCurrentState("searching");
      if (currentState === "searching") {
        setCurrentState("");
      }

      if (currentFilterSearch !== null && currentFilterSearch !== "") {
        auxObj["search"] = currentFilterSearch;
      }
    } else {
      setCurrentState("filtering");

      if (currentState === "filtering") {
        setCurrentState("");
      }
    }

    setCurrentParams(auxObj);
  }

  const updateListFunction = () => {
    setCurrentPage(currentPage + 1);
  };

  const updateListAfterUpdateDepartment = () => {
    setAux([]);
    setCurrentPage(1);
    setCurrentDepartments(undefined);

    if (document.getElementById("ListOfDepartments") != null) {
      document.getElementById("ListOfDepartments").scrollTop = 0;
    }

    setCurrentState("deactivating");

    if (currentState === "deactivating") {
      setCurrentState("");
    }
  };

  const callbackModalDepartment = (currentParams: {}, getAllDepartments: Function) => {
    setCurrentPage(1);
    setAux([]);

    const controller = new AbortController();
    getAllDepartmentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllDepartments, controller);
  }

  return (
    <HomeDepartments
      isLoading={isLoading}
      callbackModalDepartment={callbackModalDepartment}
      currentParams={currentParams}
      getAllDepartments={getAllDepartments}
      search={search}
      setCurrentFilterSearch={setCurrentFilterSearch}
      currentFilterSearch={currentFilterSearch}
      values={values}
      showCreateDepartment={showCreateDepartment}
      currentDepartments={currentDepartments}
      departmentsKeywords={departmentsKeywords}
      updateListFunction={updateListFunction}
      updateListAfterUpdateDepartment={updateListAfterUpdateDepartment}
      currentHasMoreInformation={currentHasMoreInformation}
      callbackOrderByDepartments={callbackOrderByDepartments}
      setIsLoading={setIsLoading}
      t={t}
      eventKey={eventKey}
      setEventKey={setEventKey}
      setCurrentState={setCurrentState}
    />
  );
}


export default HomeDepartmentsController;
