import React, { FC } from 'react';

import { Row, Col, Popover, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import InfiniteScroll from 'react-infinite-scroll-component';

import { IListOfTemplateMessages } from './indexModel';
import Loading from '../../../../../../components/loading';
import Utils from '../../../../../../core/shared/utils';
import { ListName } from '../../../../../../core/enums/order-sort-by';
import { IconMessageCog } from '@tabler/icons-react';
import allStyles from './styles';

const ListOfTemplateMessages: FC<IListOfTemplateMessages> = (props) => {
    const popover = (
        <Popover id={props.currentTemplateId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={(e) => props.showEditTemplateMessage(e)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Editar template message</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={() => props.deleteConfirmation()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir template message</Button>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <Loading visibility={props.isLoading}></Loading>
            <div style={{ marginTop: '2vh' }} className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={allStyles.TextRowCollums}>
                        <Row name="ListOfTemplateMessagesHead">
                            <Col md={4} className="nopadding">
                                Título
                            </Col>
                            <Col md={4} className="nopadding">
                                Whatsapp
                            </Col>
                            {/* <Col md={4} className="nopadding">
                                Mensagem
                            </Col> */}
                            {/* <Col md={2} className="nopadding">
                                Tags?
                            </Col> */}
                            <Col className='d-flex justify-content-end '>
                                {props.isEditAllowed && <p>Ações</p>}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    props.datas?.length > 0 ?
                        <div id="ListOfTemplateMessages" style={{ overflowY: 'auto', height: '40vh' }}>
                            <InfiniteScroll
                                dataLength={props.datas.length}
                                next={props.updateListfunction}
                                hasMore={props.HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"ListOfTemplateMessages"}
                                onScroll={ () => { Utils.hidePopoverOnScroll(ListName.ListOfTemplateMessages) } }
                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {props.datas.map((item, index) => {
                                            
                                            return (
                                                <ListGroup.Item style={{ color: '#707070' }} id={item.title + "*ID*" + item.id} key={"keyAll" + index.toString()} >
                                                    <Row className='TicketsRowHeight'>
                                                        <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.title + "*ID*" + item.id} /* onClick={(e) => (e)} */>
                                                            <div className='oneLineClampEllipsis'>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            <span>
                                                                                { item.title_and_number.split(" - ")[0] }
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                    { item.title_and_number.split(" - ")[0] }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.title + "*ID*" + item.id} /* onClick={(e) => (e)} */>
                                                            <div className='oneLineClampEllipsis'>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            <span>
                                                                                { item.title_and_number.split(" - ")[1] }
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                    { item.title_and_number.split(" - ")[1] }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        {/* <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + "*ID*" + item.id} >
                                                            <div className='oneLineClampEllipsis'>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            <span>
                                                                                {item.message}
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item.message}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col> */}
                                                        <Col className='d-flex justify-content-end' >
                                                            {
                                                                props.isEditAllowed &&
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                    <Button id={item.title + "*ID*" + item.id} onClick={(e) => props.setCurrentTemplateInfo(e)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>);
                                        })}
                                    </ListGroup>
                                }
                            </InfiniteScroll>
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1} className="d-flex justify-content-center">
                                    <IconMessageCog size={80} color="#4a69bd" />
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                                <Col md={6}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateTemplateMessage}>ADICIONAR TEMPLATE MESSAGE</Button></p>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        </>
    );

}

export default ListOfTemplateMessages;