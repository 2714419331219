import { useCallback, useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AppRequesterController } from "../services/appRequester/appRequesterController"
import UserService from "../services/user-service"

const AppRequesterConst = new AppRequesterController()

const useNotificationSearchRequest = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const headers = UserService.getHeaders()

    const [isLoading, setIsLoading] = useState(false)
    const [currentFilterSearch, setCurrentFilterSearch] = useState("") 
    const [notifications, setNotifications] = useState({})


    const getAllTickets = useCallback(async () => {
        const router = !currentFilterSearch ? `/notification` : `/notification/?search=${currentFilterSearch}`
        
        await AppRequesterConst.Get(
            router, { headers },
            (response: Object) => {

            },
            (data: any) => {
                if (data.status === 200) {
                    setNotifications(data.data)
                }
            },
            (error: Object) => {

            }, navigate, dispatch, setIsLoading, { }
        )
    
    }, [currentFilterSearch])

    // useEffect(() => {
    //     getAllTickets()
    // }, [getAllTickets])

    return { setCurrentFilterSearch, currentFilterSearch, notifications, isLoading, refetchtGetAllTickets: getAllTickets }
}

export default useNotificationSearchRequest