import { FC, useEffect, useState } from "react"
import FilterOptionComponent from "."
import { FilterOptionComponentInterface } from "./indexModel"
import constsApi from "../../../../services/constsApi";
import { ContractStatus } from "../../../../core/enums/contract-status";
import { MessageTriggerStatus } from "../../../../core/enums/message-trigger-status";

const FilterOptionComponentController: FC<FilterOptionComponentInterface> = ({
    optionNames,
    disableSubOptions,
    filteringTickets,
    cleanList,
    firstAndCurrentDay,
    dashboardMetrics
}) => {

    // const [optionNames] = useState()

    const [selectedChannel, setSelectedChannel] = useState([]);
    const [channelOptions, setChannelOptions] = useState(
        [
            { label: "E-mail", value: constsApi.channel[0].id },
            { label: "Facebook", value: constsApi.channel[3].id },
            { label: "Instagram", value: constsApi.channel[4].id },
            { label: "Telefone", value: constsApi.channel[1].id },
            { label: "Webchat", value: constsApi.channel[5].id },
            { label: "WhatsApp", value: constsApi.channel[2].id },
        ]);

    const [selectedBot, setSelectedBot] = useState([]);
    const [botOptions, setBotOptions] = useState(
        [
            { label: "Sim", value: '1' },
            { label: "Não", value: '0' },
        ]);

    const [selectedStatus, setSelectedStatus] = useState([]);
    const [statusOptions, setStatusOptions] = useState(
        [
            { label: "Ativo", value: ContractStatus.Active },
            { label: "Inativo", value: ContractStatus.Inactive },
            { label: "Período grátis", value: ContractStatus.Trial },
            { label: "Fim da gratuidade", value: ContractStatus.EndTrial },
        ]);
    
    const [agentOptions, setAgentOptions] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);

    const [departmentOptions, setDepartmentsOptions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const [triggerTypeOptions, setTriggerTypeOptions] = useState([]);
    const [selectedTriggerType, setSelectedTriggerType] = useState([]);

    const [responsibleOptions, setResponsibleOptions] = useState([]);
    const [selectedResponsible, setSelectedResponsible] = useState([]);

    const [tagsOptions, setTagsOptions] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [selectedTriggerStatus, setSelectedTriggerStatus] = useState([]);
    const [triggerStatusOptions, setTriggerStatusOptions] = useState(
        [
            { label: "Disparado", value: MessageTriggerStatus.Triggered },
            { label: "Programado", value: MessageTriggerStatus.Scheduled },
            { label: "Cancelado", value: MessageTriggerStatus.Canceled },
        ]);

    const [consumerOptions, setConsumerOptions] = useState([]);
    const [selectedConsumer, setSelectedConsumer] = useState([]);

    const [currentFilterDate, setCurrentFilterDate] = useState(firstAndCurrentDay ? firstAndCurrentDay : [null, null]);
    const [startDate, endDate] = currentFilterDate;

    /**
     * Date in pick date to bold
     */
    const [updateInputDateActive, setUpdateInputDateActive] = useState(false);

    /**
     * Set bold to calendar font
     */
    useEffect(() => {
        setUpdateInputDateActive((isUpdateInputDate) => {
            if (isUpdateInputDate) {
                document.getElementById("dateInputCalendarId").className = "dateInputCalendarActive ps-0";
            }
            return isUpdateInputDate;
        });
    }, [updateInputDateActive])

    useEffect(() => {
        if ((disableSubOptions && disableSubOptions.status && disableSubOptions.status.resolved === false) || disableSubOptions === undefined) {
            setStatusOptions(state => {
                const letaux = state.concat([{ label: "Ativo", value: ContractStatus.Active }])
                return letaux;
            });
        }

        if (firstAndCurrentDay) {
            callbackFiltering(document.getElementById("filterButton"));
        }
    }, [])

    const onSelectDropdownDate = (value: any) => {
        if (document.getElementById("dateInput") != null) {
            if (value === null) {
                setCurrentFilterDate([null, null]);
            } else {
                setCurrentFilterDate(value);
            }
        }
    }

    const resetFilters = () => {
        setSelectedStatus([]);
        onSelectDropdownDate(null);
    }

    const handleCleanList = () => {
        cleanList();
        resetFilters();
    }

    const callbackFiltering = (e: Object) => {
        const auxObj = {};
        const dataObj = {};

        if (selectedStatus.length > 0) {
            auxObj["contract_status_id"] = selectedStatus[0].value;
            dataObj["contract_status_id"] = selectedStatus[0].label;
        }

        if (currentFilterDate[0] !== null) {
            auxObj["start_date"] = new Date(currentFilterDate[0]).toISOString().split("T")[0];
            dataObj["start_date"] = new Date(currentFilterDate[0]);
        } else if (firstAndCurrentDay !== undefined) {
            if (firstAndCurrentDay[0] !== null) {
                auxObj["start_date"] = new Date(firstAndCurrentDay[0]).toISOString().split("T")[0];
                dataObj["start_date"] = new Date(firstAndCurrentDay[0]);
            }
        }

        if (currentFilterDate[1] !== null) {
            auxObj["end_date"] = new Date(currentFilterDate[1]).toISOString().split("T")[0];
            dataObj["end_date"] = new Date(currentFilterDate[1]);
        } else if (firstAndCurrentDay !== undefined) {
            if (firstAndCurrentDay[1] !== null) {
                auxObj["end_date"] = new Date(firstAndCurrentDay[1]).toISOString().split("T")[0];
                dataObj["end_date"] = new Date(firstAndCurrentDay[1]);
            }
        }
        dashboardMetrics == true ? filteringTickets(e, auxObj, dataObj) : filteringTickets(e, auxObj);

    }

    return (
        <FilterOptionComponent
            optionNames={optionNames}
            handleCleanList={handleCleanList}
            channelOptions={channelOptions}
            setChannelOptions={setChannelOptions}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            botOptions={botOptions}
            setBotOptions={setBotOptions}
            selectedBot={selectedBot}
            setSelectedBot={setSelectedBot}
            selectedAgents={selectedAgents}
            agentOptions={agentOptions}
            setAgentOptions={setAgentOptions}
            setSelectedAgents={setSelectedAgents}
            statusOptions={statusOptions}
            setStatusOptions={setStatusOptions}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedDepartments={selectedDepartments}
            departmentOptions={departmentOptions}
            setDepartmentsOptions={setDepartmentsOptions}
            setSelectedDepartments={setSelectedDepartments}
            startDate={startDate}
            endDate={endDate}
            onSelectDropdownDate={onSelectDropdownDate}
            setUpdateInputDateActive={setUpdateInputDateActive}
            currentFilterDate={currentFilterDate}
            setCurrentFilterDate={setCurrentFilterDate}
            selectedTriggerType={selectedTriggerType}
            triggerTypeOptions={triggerTypeOptions}
            setTriggerTypeOptions={setTriggerTypeOptions}
            setSelectedTriggerType={setSelectedTriggerType}
            selectedResponsible={selectedResponsible}
            responsibleOptions={responsibleOptions}
            setResponsibleOptions={setResponsibleOptions}
            setSelectedResponsible={setSelectedResponsible}
            triggerStatusOptions={triggerStatusOptions}
            setTriggerStatusOptions={setTriggerStatusOptions}
            selectedTriggerStatus={selectedTriggerStatus}
            setSelectedTriggerStatus={setSelectedTriggerStatus}
            selectedTags={selectedTags}
            tagsOptions={tagsOptions}
            setTagsOptions={setTagsOptions}
            setSelectedTags={setSelectedTags}
            selectedConsumer={selectedConsumer}
            consumerOptions={consumerOptions}
            setConsumerOptions={setConsumerOptions}
            setSelectedConsumer={setSelectedConsumer}
            callbackFiltering={callbackFiltering}
        />
    )
}

export default FilterOptionComponentController