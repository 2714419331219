import { FC, useEffect, useState } from 'react';
import { FormConfigClientInterface } from './indexModel';
import './styles.scss';
import { ContractStatus } from '../../../../core/enums/contract-status';
import InputMask from 'react-input-mask';
import MaskController from '../../../../core/shared/mask-controller';

const FormConfigClient: FC<FormConfigClientInterface> = (props) => {

  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const getCompanyStatus = (): string => {
    switch (data?.contract_status_id) {
      case ContractStatus.Active:
        return 'Ativa';
      case ContractStatus.Inactive:
        return 'Inativa';
      case ContractStatus.Trial:
        return 'Período grátis';
      case ContractStatus.EndTrial:
        return 'Fim da gratuidade';
      default:
        return 'N/A';
    }
  }

  const formatDate = (date: string): string => {
    return date ? new Date(date).toLocaleString('pt-BR') : 'N/A';
  }

  const getValue = (value: any) => {
    return value ? value : 'N/A';
  }

  return (
    <div className="form-config-client">
      <div className="section">
        <div className="header">
          <span className="title">Dados da empresa</span>
        </div>
        <div className="section-content">
          <div className="line">
            <div className="item">
              <label>Nome da empresa</label>
              <input className="form-control" type="text" readOnly={true} value={getValue(data?.company_name)} disabled />
            </div>
            <div className="item">
              <label>Subdomínio na Cxpress</label>
              <input className="form-control" type="text" readOnly={true} value={getValue(data?.company_subdomain)} disabled />
            </div>
          </div>
          <div className="line">
            <div className="item">
              <label>CNPJ</label>
              <InputMask
                mask={MaskController.maskCpfCnpj(data?.company_cnpj || '')} disabled={true} readOnly={true}
                maskChar={MaskController.maskCharCpfCnpj(data?.company_cnpj || '')}
                value={data?.company_cnpj || 'N/A'}>
                  {(inputProps) => <input {...inputProps} className="form-control" type="text" disabled /> }
              </InputMask>
            </div>
            <div className="item">
              <label>Data de cadastro</label>
              <input className="form-control" type="text" readOnly={true} value={formatDate(data?.created_at)} disabled />
            </div>
          </div>
          <div className="line">
            <div className="item">
              <label>Situação cadastral</label>
              <input className="form-control" type="text" readOnly={true} value={getCompanyStatus()} disabled />
            </div>
            <div className="item">
              <label>Data de ativação</label>
              <input className="form-control" type="text" readOnly={true} value={formatDate(data?.started_date)} disabled />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="header">
          <span className="title">Dados do proprietário</span>
        </div>
        <div className="section-content">
          <div className="line">
            <div className="item">
              <label>Nome do proprietário</label>
              <input className="form-control" type="text" readOnly={true} value={getValue(data?.owner_firstname)} disabled />
            </div>
            <div className="item">
              <label>E-mail</label>
              <input className="form-control" type="text" readOnly={true} value={getValue(data?.owner_email)} disabled />
            </div>
          </div>
          <div className="line">
            <div className="item">
              <label>Telefone</label>
              <input className="form-control" type="text" readOnly={true} value={getValue(data?.owner_phone)} disabled />
            </div>
            <div className="item">
              <label>Data de ativação da conta</label>
              <input className="form-control" type="text" readOnly={true} value={formatDate(data?.owner_email_confimation_at)} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormConfigClient;
