import { FC } from 'react';
import { Button, Col, Collapse, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import Loading from '../../../../components/loading';
import { getShowAcquirePlatformComponent, getShowCallNotificationBar, getShowEditConsumersModal, getShowModalConsumersSimilarData, getShowRegisterCustomersModal } from '../../../../store/internal';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ButtonSearchCleanInput from '../../components/buttonSearchCleanInputComponent';
import CallNotificationBar from '../../components/callNotificationBar';
import ModalEditConsumersController from '../../components/modalEditConsumers/indexController';
import ModalRegisterConsumersController from '../../components/modalRegisterConsumers/indexController';
import allStyles from './styles';
import TagsComponent from '../../components/tagsComponent';
import ModalConsumersSimilarDataController from '../../homeConsumers/unificationConsumers/modalConsumersSimilarData/indexController';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';

import { IHomeCreateTicket } from './indexModel';
import { useSelector } from 'react-redux';
import ButtonCountNumberTicketsController from '../components/buttonCountNumberTickets/indexController';
import MessagesComponentController from '../../components/messagesComponent/indexController';
import SelectComponentController from '../../components/selectComponent/indexController';

const HomeCreateTicket: FC<IHomeCreateTicket> = (props) => {
    return (
        <Container fluid className="nopadding" style={{ overflow: 'hidden' }}>
            <Loading visibility={props.isLoading}></Loading>
            <ModalRegisterConsumersController token={props.values.token.value} visibility={useSelector(getShowRegisterCustomersModal)} callback={(userIdCustomer) => props.callbackModalCustomers(userIdCustomer)} setCurrentConsumer={props.setCurrentConsumer} setSimilarConsumers={props.setSimilarConsumers} />
            <ModalEditConsumersController token={props.values.token.value} visibility={useSelector(getShowEditConsumersModal)} callback={(userIdCustomer) => props.callbackModalCustomers(userIdCustomer)} data={props.currentConsumersData} setCurrentConsumer={props.setCurrentConsumer} setSimilarConsumers={props.setSimilarConsumers} />
            <ModalConsumersSimilarDataController originRedirectPage='create_ticket' visibility={useSelector(getShowModalConsumersSimilarData)} similarConsumers={props.similarConsumers} currentConsumer={props.currentConsumer} />
            <div className="wrapper">
                {/* <SidebarController currentPage={SidebarButtonPage.Tickets} isClosingCreateTicket={props.isClosingCreateTicket} setIsClosingCreateTicket={props.setIsClosingCreateTicket} closeWithoutSavingConfirmation={(e) => props.closeWithoutSaving(e)} filledCreatingTicket={props.filledCreatingTicket} setFilledCreatingTicket={props.setFilledCreatingTicket} closeCreateTicketButton={props.closeCreateTicketButton}></SidebarController> */}
                <div className="content py-0 ps-0">
                    <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                    <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                    <Row className="nopadding mt-3 ps-3" >
                        <Col md={7}>
                            <ButtonCountNumberTicketsController closeWithoutSavingConfirmation={(e: any) => props.closeWithoutSaving(e)} numberOfAllTickets={props.numberOfAllTickets} numberOfYoursTickets={props.numberOfYoursTickets} numberOfNewTickets={props.numberOfNewTickets} numberOfUnreadTickets={props.numberOfUnreadTickets}></ButtonCountNumberTicketsController>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ButtonSearchCleanInput closeWithoutSaving={(e) => props.closeWithoutSaving(e)} isClosingCreateTicket={props.isClosingCreateTicket} setIsClosingCreateTicket={props.setIsClosingCreateTicket} filledCreatingTicket={props.filledCreatingTicket} closeWithoutSavingConfirmation={(e) => props.closeWithoutSaving(e)} inputPlaceholder={props.t("home_tickets.button_search_placeholder")} filteringTickets={() => props.filteringTicketsToTicketsPage()} setCurrentFilterSearch={props.setCurrentFilterSearch} currentFilterSearch={props.currentFilterSearch} setCurrentState={props.setCurrentState}></ButtonSearchCleanInput>
                            <ButtonListNotificationController />
                            <ButtonStatusAgent />
                        </Col>
                    </Row>
                    <div className='ps-3'>
                        <Row style={{ marginTop: useSelector(getShowAcquirePlatformComponent).visibility ? '2.5vh' : '4vh' }}>
                            <Row className="ps-4">
                                <Col style={{ color: '#0C2461', font: 'var(--px15_19_Bold-font)' }}>
                                    {props.t("home_tickets.home_create_ticket.create_ticket_label")}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '1vh', marginBottom: '0.4vh' }} className="mx-0">
                                <Col style={{ /* paddingRight: '2vw' */ }}>
                                    {/* atualizado com a alteração de adriano */}
                                    {/* <MessagesComponent currentRequester_user={currentRequester_user} currentSubject={currentSubject} name={values.user.name + (values.user.lastname ? (" " + values.user.lastname) : "")} agent={values.user} ticketIdAfterCreated={ticketIdAfterCreated} currentTicket={null} onPostTicket={postTickets} showSubjectError={showSubjectError} updateList={() => { }}></MessagesComponent> */}
                                    <MessagesComponentController isCreatingTicket currentChannelId={props.currentChannel} ticketChannel={props.currentChannel} setTicketChannel={props.setCurrentChannel} currentRequester_user={props.currentRequester_user} currentSubject={props.currentSubject} name={props.values.user.name + (props.values.user.lastname ? (" " + props.values.user.lastname) : "")} agent={props.values.user} currentTicket={null} showSubjectError={props.showSubjectError} onPostTicket={props.postTickets} updateList={() => { }} showHistoryButton={false} showOptionsButton={false} setFilesIds={props?.setFilesIds} setSelectedConsumers={props.setSelectedConsumers} onSelectRequester={props.onSelectRequester} setCurrentRequester_user={props.setCurrentRequester_user}></MessagesComponentController>
                                </Col>
                                <Col md={3} style={{ maxWidth: '22vw', marginRight: '1vw', marginLeft: '1vw', backgroundColor: '#f8f9fa', font: 'var(--px15_19-font-Roboto)', overflowY: 'auto', overflowX: 'hidden', height: useSelector(getShowAcquirePlatformComponent).visibility ? '76vh' : '80vh' }}>
                                    <Row className="mt-2" style={allStyles.dropdownOptionRow}>
                                        <Row className="mx-1 mb-1 mt-1">
                                            <Col className="align-self-center">
                                                {props.t("home_tickets.home_create_ticket.requester_field_label")}
                                            </Col>
                                            {
                                                props?.selectedConsumers[0]?.created_by_employee === "false" && (props.selectedConsumers?.length > 0 && (props.selectedConsumers[0]?.profile_id === null || props.selectedConsumers[0]?.profile_id === undefined) ) &&
                                                    <Col md={7} className="d-flex justify-content-end" style={{ height: '70%' }}>
                                                        <Button onClick={() => props.onClickButtonEdit()} id={"editConsumerButtonId"} /* style={ (props.selectedConsumers?.length > 0 && (props.selectedConsumers[0]?.profile_id === null || props.selectedConsumers[0]?.profile_id === undefined) )? { display: 'inline' }: { display: 'none' }} */ /* style={{ display: 'none' }} */ className="buttonAsText">{props.t("home_tickets.home_create_ticket.edit_button")}</Button>
                                                    </Col>
                                            }
                                        </Row>
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col>
                                                <SelectComponentController
                                                    options={props.consumersOptions}
                                                    setOptions={props.setConsumersOptions}
                                                    selecteds={props.selectedConsumers}
                                                    setSelecteds={props.setSelectedConsumers}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t("home_tickets.home_create_ticket.select_component.requester.field_label")}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", {
                                                                items_name: props.t("home_tickets.home_create_ticket.requesters_field_label")
                                                            }),
                                                            "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                            "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                            "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                            "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                            "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                            "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                            "selectSomeItems": props.t("home_tickets.home_create_ticket.requester_field_label"),
                                                            "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                        }
                                                    }
                                                    hasCreateItem={true}
                                                    filterOptions={props.multiselectFilterUsers}
                                                    hasNullOption={false}
                                                    createItemLabel={props.t("home_tickets.home_create_ticket.select_component.create_consumer")}
                                                    createItemOnClick={() => props.customCreateRequesterButtonClick()}
                                                    onSelect={props.onSelectRequester}
                                                    hasAllOption={false}
                                                    isCreatingConsumer={props.currentRequester_user?.email && props.currentRequester_user?.user_id === undefined? true: false}
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={{ marginBottom: '1vh' }}>
                                        <Row className="mx-1 mb-1 mt-1">
                                            <Col>
                                                {props.t("home_tickets.home_create_ticket.department_field_label")}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col>
                                                <SelectComponentController
                                                    options={props.departmentsOptions}
                                                    setOptions={props.setDepartmentsOptions}
                                                    selecteds={props.selectedDepartments}
                                                    setSelecteds={props.setSelectedDepartments}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t("home_tickets.home_create_ticket.select_component.requester.field_label")}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                            "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                            "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                            "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                            "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                            "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                            "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                            "selectSomeItems": props.t("home_tickets.home_create_ticket.department_field_label"),
                                                            "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    filterOptions={props.multiselectFilterDepartments}
                                                    hasNullOption={false}
                                                    onSelect={props.onSelectDepartment}
                                                    hasAllOption={false}
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={allStyles.dropdownOptionRow}>
                                        <Row className="mx-1 mb-1 mt-1" >
                                            <Col className="align-self-center">
                                                {props.t("home_tickets.home_create_ticket.assigned_field_label")}
                                            </Col>
                                            <Col md={7} className="d-flex justify-content-end" >
                                                <Button onClick={() => props.onClickButtonAccept(props.values)} size='sm' className="buttonAsText">
                                                    {props.t("home_tickets.home_create_ticket.accept_assigned")}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col>
                                                <SelectComponentController
                                                    options={props.agentsOptions}
                                                    setOptions={props.setAgentsOptions}
                                                    selecteds={props.selectedAgents}
                                                    setSelecteds={props.setSelectedAgents}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t("home_tickets.home_create_ticket.assigned_field_label")}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                            "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "selectSomeItems": props.t("home_tickets.home_create_ticket.assigned_field_label"),
                                                            "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    filterOptions={props.multiselectFilterAgents}
                                                    hasNullOption={true}
                                                    nullOptionLabel={props.t("home_tickets.home_create_ticket.null_option")}
                                                    onSelect={(value) => props.onSelectAssigned(value)}
                                                    hasAllOption={false}
                                                />
                                            </Col>
                                        </Row>
                                    </Row>

                                    <Row style={allStyles.dropdownOptionRow}>
                                        <Row className="mx-1 mb-1 mt-1">
                                            <Col className="align-self-center">
                                                {props.t("home_tickets.home_create_ticket.followers_field_label")}
                                            </Col>
                                            <Col md={7} className="d-flex justify-content-end">
                                                <Button onClick={() => props.onClickButtonFollow(props.values)} size='sm' className="buttonAsText">{props.t("home_tickets.home_create_ticket.follower_button")}</Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col>
                                                <TagsComponent tags={props.followers} onSearch={props.onSearchFollowers} abortConst={props.abortController} onSelect={() => { props.setFilledCreatingTicket && props.setFilledCreatingTicket(true); }} currentTags={props.currentFollowers} setCurrentTags={props.selectFollowers}></TagsComponent>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={allStyles.dropdownOptionRow}>
                                        <Row className="mx-1 mb-1 mt-1">
                                            <Col>
                                                {props.t("home_tickets.home_create_ticket.status_field_label")}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col>
                                                <SelectComponentController
                                                    options={props.statusOptions}
                                                    setOptions={() => { }}
                                                    selecteds={props.selectedStatus}
                                                    setSelecteds={props.setSelectedStatus}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t("home_tickets.home_create_ticket.status_field_label")}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                            "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                            "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                            "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                            "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                            "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                            "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                            "selectSomeItems": props.t("home_tickets.home_create_ticket.status_field_label"),
                                                            "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    onSelect={props.onSelectStatus}
                                                    disableSearch={true}
                                                    hasAllOption={false}
                                                />
                                            </Col>
                                        </Row>
                                    </Row>

                                    <Row style={allStyles.dropdownOptionRow}>
                                        <Row className="mx-1 mb-1 mt-1">
                                            <Col sm={8} md={8}>
                                                {props.t("home_tickets.home_create_ticket.channel_field_label")}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col sm={12} md={12}>
                                                <SelectComponentController
                                                    options={props.channelOptions}
                                                    setOptions={() => { }}
                                                    selecteds={props.selectedChannels}
                                                    setSelecteds={props.setSelectedChannels}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t("home_tickets.home_create_ticket.channel_field_label")}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.channels_field_label") }),
                                                            "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                            "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.channels_field_label") }),
                                                            "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.channels_field_label") }),
                                                            "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.channels_field_label") }),
                                                            "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.channels_field_label") }),
                                                            "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.channels_field_label") }),
                                                            "selectSomeItems": props.t("home_tickets.home_create_ticket.channel_field_label"),
                                                            "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    onSelect={props.onSelectChannel}
                                                    disableSearch={true}
                                                    hasAllOption={false}
                                                    isChannel
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={allStyles.dropdownOptionRow}>
                                        <Row className="mx-1 mb-1 mt-1">
                                            <Col>
                                                {props.t("home_tickets.home_create_ticket.tags_field_label")}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col>
                                                <TagsComponent tags={props.tags} onSelect={() => { props.setFilledCreatingTicket && props.setFilledCreatingTicket(true); }} currentTags={props.currentTags} setCurrentTags={props.selectTags}></TagsComponent>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={allStyles.dropdownOptionRow}>
                                        <Row className="mx-1 mb-1 mt-1" >
                                            <div style={{ font: 'var(--px15_19_Bold-font)' }}>
                                                {props.t("home_tickets.home_create_ticket.subject_ticket")}
                                            </div>
                                        </Row>
                                        <Row className="mx-1 mb-1 mt-1" >
                                            <Col className="d-flex justify-content-start">
                                                <textarea
                                                    id="textAreaAssunto"
                                                    value={props.currentSubject}
                                                    onChange={(e) => { props.limiterChangeTextArea(e.target.value) }}
                                                    style={{ border: '1px solid #BCBCBC' }}
                                                ></textarea>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={{ marginBottom: '1vh' }}>
                                        <Col className="">
                                            <Row className="mx-1 mb-1 mt-1">
                                                <div
                                                    style={{ font: 'var(--px15_19_Bold-font)', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                                    onClick={props.toggleContent}
                                                >
                                                    { props.t("home_tickets.home_create_ticket.custom_fields") }
                                                    <span style={{ marginLeft: '5px', transition: 'transform 0.3s', color: '#4a69bd', fontSize: '15px' }}>
                                                        {props.showContent ?
                                                            <svg id="Grupo_11467" data-name="Grupo 11467" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                                                                <path id="Caminho_9741" data-name="Caminho 9741" d="M0,16.383H16.383V0H0Z" fill="none" />
                                                                <path id="Caminho_9742" data-name="Caminho 9742" d="M6,13.1,10.1,9l4.1,4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg> :
                                                            <svg id="Grupo_11489" data-name="Grupo 11489" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                                                                <path id="Caminho_9741" data-name="Caminho 9741" d="M0,0H16.383V16.383H0Z" fill="none" />
                                                                <path id="Caminho_9742" data-name="Caminho 9742" d="M6,9l4.1,4.1,4.1-4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        }
                                                    </span>
                                                </div>
                                            </Row>
                                            <Collapse in={props.showContent}>
                                                <Row style={{ marginBottom: '-1vh' }}>
                                                    {(props.customFields !== null && props.customFields !== undefined && props.customFields.length > 0 ? props.customFields.map((customField) => {
                                                        if (customField !== undefined || customField !== null) {
                                                            switch (customField.type) {
                                                                case "d31ebd9e-af9f-4559-b04a-729439218a6f": //text
                                                                    return (<>
                                                                        <Row className="mx-1 mb-1 mt-2">
                                                                            <Col>
                                                                                {customField.required? '* ': ''}{customField.name}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mx-1 mb-2 mt-1">
                                                                            <InputGroup>
                                                                                <FormControl
                                                                                    placeholder={customField.placeholder}
                                                                                    type='text'
                                                                                    id={customField.id}
                                                                                    name={customField.name}
                                                                                    value={props.textareaStates[customField.id]}
                                                                                    onChange={(e) => props.changeCustomField(e, customField)}
                                                                                    maxLength={50}
                                                                                    style={{ font: 'var(--px15_19-font-Roboto)', height: '5vh' }}
                                                                                    isInvalid={props.customFieldIdErrors[customField.id]}
                                                                                >
                                                                                </FormControl>
                                                                                <Form.Control.Feedback type="invalid" id="feedbackcustomField">
                                                                                    { props.t("home_tickets.custom_fields_for_forms.required_field") }
                                                                                </Form.Control.Feedback>
                                                                            </InputGroup>
                                                                        </Row></>
                                                                    )
                                                                case "2982a738-4de3-440a-8ed1-cc1fdb51bc9b": //textarea
                                                                    return (<>
                                                                        <Row className="mx-1 mb-1 mt-2">
                                                                            <Col>
                                                                                {customField.required? '* ': ''}{customField.name}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mx-1 mb-2 mt-1">
                                                                            <InputGroup>
                                                                                <FormControl
                                                                                    placeholder={customField.placeholder}
                                                                                    as="textarea" 
                                                                                    type="text"
                                                                                    id={customField.id}
                                                                                    name={customField.name}
                                                                                    value={props.textareaStates[customField.id]}
                                                                                    onChange={(e) => props.changeCustomField(e, customField)}
                                                                                    maxLength={500}
                                                                                    isInvalid={props.customFieldIdErrors[customField.id]}
                                                                                >
                                                                                </FormControl>
                                                                                <Form.Control.Feedback type="invalid" id="feedbackcustomField">
                                                                                    { props.t("home_tickets.custom_fields_for_forms.required_field") }
                                                                                </Form.Control.Feedback>
                                                                            </InputGroup>
                                                                        </Row></>)
                                                            }
                                                        }
                                                    }) :
                                                        <Row className="mx-1 mb-1 mt-2">
                                                            <Col>
                                                                {props.t("home_tickets.home_create_ticket.no_options")}
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Row>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row style={allStyles.dropdownOptionRow}>
                                        <Col className="d-flex justify-content-start">
                                            <div className="mx-3 my-2" style={{ font: 'var(--px15_19_Bold-font)' }}>{props.t("home_tickets.home_create_ticket.date")}</div>
                                            <div className="my-2" style={{ font: 'var(--px15_19-font)' }}>{props.formatedDate(props.date)}</div>
                                        </Col>
                                    </Row>
                                    <Row style={allStyles.dropdownOptionRow} className="">
                                        <Row className="mx-1" >
                                            <Col className="d-flex justify-content-start">
                                                <Button type='submit' className="buttonBlue" onClick={props.postTickets} disabled={props.showSubjectError}>{props.t("home_tickets.home_create_ticket.save_ticket")}</Button>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={allStyles.dropdownOptionRow} className="mx-1">
                                        <Col className="d-flex justify-content-center">
                                            <Button type='submit' size='sm' className="buttonAsText2" name="buttonCloseWithoutSaving" id="buttonCloseWithoutSaving" onClick={(e) => props.closeWithoutSaving(e)}>
                                                <svg id="Grupo_11288" className="mx-1" data-name="Grupo 11288" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Caminho_9686" data-name="Caminho 9686" d="M0,0H24V24H0Z" fill="none" />
                                                    <line id="Linha_208" data-name="Linha 208" x2="14" transform="translate(5 12)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_209" data-name="Linha 209" x2="6" y2="6" transform="translate(5 12)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_210" data-name="Linha 210" y1="6" x2="6" transform="translate(5 6)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                                {props.t("home_tickets.home_create_ticket.without_save")}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row >
                        </Row >
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default HomeCreateTicket;