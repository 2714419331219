import { useSelector } from 'react-redux'
import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from '../../../components/loading/index'
import ListOFAgentsController from './listOfAgents/indexController';
import { getShowRegisterAgentsModal, getShowCallNotificationBar, getShowAcquirePlatformComponent } from "../../../store/internal";
import ModalRegisterAgentsController from '../components/modalRegisterAgents/indexController';
import AcquirePlatformComponent from '../components/acquirePlatformComponent';
import constsApi from '../../../services/constsApi'
import CallNotificationBar from '../components/callNotificationBar';
import ButtonSearchCleanInput from '../components/buttonSearchCleanInputComponent';
import AcquirePlatformNotificationBar from '../components/acquirePlatformNotificationBar';
import ButtonStatusAgent from '../components/buttonStatusAgent';
import ButtonListNotificationController from '../../../components/buttonListNotification/indexController';


const HomeAgents = ({
    values,
    isLoading,
    currentAgents,
    currentHasMoreInformation,
    SetCurrentFilterSearch,
    currentFilterSearch,
    callbackModalAgents,
    callbackOrderByEmployees,
    search,
    setCurrentAgentFunction,
    showCreateAgent,
    updateListAfterDelete,
    updateListfunction,
    t
}) => {

    return (
        <>
            <Container fluid className="nopadding">
                <Loading visibility={isLoading}></Loading>
                <ModalRegisterAgentsController token={values.token.value} visibility={useSelector(getShowRegisterAgentsModal)} callback={callbackModalAgents} />
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.Agents}></SidebarController> */}
                    <div className="content pt-0 ps-0">
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        <Row className="nopadding mt-3 ps-3">
                            <Col md={5} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_agents.general.welcome_main')}</div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('home_agents.general.welcome_sub')}</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ButtonSearchCleanInput inputPlaceholder={t('filter_option.searchAgente')} filteringTickets={(e, auxObj, action) => search(e, auxObj, action)} setCurrentFilterSearch={SetCurrentFilterSearch} currentFilterSearch={currentFilterSearch} />
                                <ButtonListNotificationController/>
                                <ButtonStatusAgent/>
                            </Col>
                        </Row>
                        <Row className="ps-3" id="buttonCreateAgent" style={{ marginTop: '5vh', padding: 0, margin: '1vw 0vw 0px 0vw' }}>
                            <Col md={3} style={{ marginLeft: '1vw' }}>
                                <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                                    {t('home_agents.general.page_title')}
                                    {
                                        ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id)) &&
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                    {t('home_agents.general.create_agent')}
                                                </Tooltip>
                                            }
                                        >



                                                <Button className="mx-3 nopadding buttonTicketsSec" onClick={showCreateAgent}>
                                                <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                    <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                    <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                            </Button>

                                        </OverlayTrigger>
                                    }
                                </p>
                            </Col>
                        </Row>
                        <ListOFAgentsController datas={currentAgents} updateListAfterDelete={updateListAfterDelete} updateListfunction={updateListfunction} HasMoreInformation={currentHasMoreInformation} showCreateAgent={showCreateAgent}
                            setCurrentAgentFunction={(value) => setCurrentAgentFunction(value)} callbackModalAgents={callbackModalAgents} callbackOrderByEmployees={(column, order) => callbackOrderByEmployees(column, order)} />
                        <Row style={{ marginTop: '-25vh' }}>
                            <AcquirePlatformComponent visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} text={t('home_agents.general.acquire_platform_agents')} />
                        </Row>
                    </div>
                </div>
            </Container >
        </>
    );
}

export default HomeAgents;
