import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NewPasswordControlPanel from ".";
import { useEffect, useState } from "react";
import { AppRequesterController } from "../../../services/appRequester/appRequesterController";
import { BodyNewPasswordInterface } from "./indexModel";
import constsRouters from "../../../routes/constsRouter";
import { setShowAlertFeedback } from "../../../store/internal";

const AppRequesterConst = new AppRequesterController();

const NewPasswordControlPanelController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState(null as string || null);
  const [confirmPassword, setConfirmPassword] = useState(null as string || null);
  const [token, setToken] = useState(null as string || null);
  const [isLoading, setIsLoading] = useState(false);
  const [scene, setScene] = useState<'new-password'|'change-success'|'invalid-token'>('new-password');

  const [showFeedbackPassword, setShowFeedbackPassword] = useState(false);
  const [showFeedbackConfirmPassword, setShowFeedbackConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    document.title = `Painel Cxpress | Redefenir senha`;
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    if (code) {
      setToken(code);
    } else {
      navigate("/");
    }
  }, [token]);

  const clickPassword = () => {
    if ((document.getElementById("password") as HTMLInputElement).type === "text") {
      (document.getElementById("password") as HTMLInputElement).type = "password";
    } else {
      (document.getElementById("password") as HTMLInputElement).type = "text";
    }
  
    setShowPassword(!showPassword);
  };

  const clickConfirmPassword = () => {
    if ((document.getElementById("confirm_password") as HTMLInputElement).type === "text") {
      (document.getElementById("confirm_password") as HTMLInputElement).type = "password";
    } else {
      (document.getElementById("confirm_password") as HTMLInputElement).type = "text";
    }
  
    setShowConfirmPassword(!showConfirmPassword);
  };

  const submitNewPassword = async () => {
    if (validated) {
      const body = { password };
  
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
      };
  
      await AppRequesterConst.Post(
        '/panel/reset-password-panel', body, { headers },
        (response: Object) => { },
        (response: any) => {
          if(response.data !== undefined) {
            setScene('change-success');
          }
        }, 
        (error: { response: { data: any }}) => {
          if(error.response.data.message[0].message.includes("password")){
            dispatch(setShowAlertFeedback({ message: 'A senha deve conter pelo menos uma letra minúscula, uma letra maiúscula, um dígito, um caractere especial e não deve conter números em sequência.', visibility: true, signalIcon: false }));
          }
          else {
            setScene('invalid-token');
            dispatch(setShowAlertFeedback({ message: 'Falha ao redefinir senha', visibility: true, signalIcon: false }));
          }
          // if (error.response !== undefined) {
          //   console.log(error.response);
          // }
        },
        navigate, dispatch, setIsLoading,
      );
    }
  }

  const validateNewPassword = (values: BodyNewPasswordInterface) => {
    const errors = {};

    setPassword(values.password);
    setConfirmPassword(values.confirm_password);

    if (!values.password) {
      errors['password'] = 'Preencha este campo';
      setShowFeedbackPassword(true);
    } else if (values.password.length > 0 && values.password.length <= 4) {
      errors['password'] = 'A senha deve ter pelo menos 5 caracteres';
      setShowFeedbackPassword(true);
    } else {
      setShowFeedbackPassword(false);
    }

    if (!values.confirm_password) {
      errors['confirm_password'] = 'Preencha este campo';
      setShowFeedbackConfirmPassword(true);
    } else if (values.password !== values.confirm_password) {
      errors['confirm_password'] = 'As duas senhas devem ser iguais';
      setShowFeedbackConfirmPassword(true);
    } else {
      setShowFeedbackConfirmPassword(false);
    }

    setValidated(!errors['password'] && !errors['confirm_password']);
    
    return errors;
  }

  const goToLogin = () => {
    navigate(constsRouters.routers.home.path);
  }

  return (
    <NewPasswordControlPanel 
      isLoading={isLoading}
      validateNewPassword={validateNewPassword}
      submitNewPassword={submitNewPassword}
      showPassword={showPassword}
      showConfirmPassword={showConfirmPassword}
      clickPassword={clickPassword}
      clickConfirmPassword={clickConfirmPassword}
      showFeedbackPassword={showFeedbackPassword}
      showFeedbackConfirmPassword={showFeedbackConfirmPassword}
      validated={validated}
      scene={scene}
      goToLogin={goToLogin}
    />
  );
};

export default NewPasswordControlPanelController;
