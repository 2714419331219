import { FC } from 'react';
import './styles.scss';

import { IconMenu2, IconLogout2 } from "@tabler/icons-react";
import { SidebarPanelInterface } from './indexModel';
import ModalConfirmation from '../../../../components/modalConfirmation';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SidebarPanel: FC<SidebarPanelInterface> = (props) => {

  return (
    <>
      <ModalConfirmation 
        text={props.confirmationModal.text} 
        visibility={props.confirmationModal.visibility} 
        funtionConfirmation={props.confirmationModal.funtionConfirmation}
        functionConfirmation={props.confirmationModal.functionConfirmation}
        functionCancellation={props.confirmationModal.functionCancellation}
      ></ModalConfirmation>
      <nav className={props.sidebarOpened ? "control-panel-sidebar" : "control-panel-sidebar closed"}>
        <div className="header">
          <div className="logo">
            <img src="/Logo_Branca2.svg" alt="" />
          </div>
          <IconMenu2 className='showHideIcon' color='#fff' stroke={1.5} onClick={() => props.showHideSidebar()} />
        </div>
        <ul className="menu">
          {props.getPages().filter(menu => menu.show).map(menu => {
            const class_li = menu.id === props.currentPage ? 'active' : '';

            return (
              <OverlayTrigger key={menu.id} placement='right' overlay={<Tooltip id='tooltip'>{menu.name}</Tooltip>}>
                <li className={class_li} onClick={() => props.menuAction(menu.id)}>
                  <div className="menu-item">
                    {menu.icon}
                    <span className="menu-name">{menu.name}</span>
                  </div>
                </li>
              </OverlayTrigger>
            );
          })}
          <OverlayTrigger key='logout' placement='right' overlay={<Tooltip id='tooltip'>Sair</Tooltip>}>
            <li onClick={() => props.menuAction('logout')}>
              <div className="menu-item">
                <IconLogout2 className="menu-icon" stroke={1.5} />
                <span className="menu-name">Sair</span>
              </div>
            </li>
          </OverlayTrigger>
        </ul>
      </nav>
    </>
  );
}

export default SidebarPanel;
