import './styles.scss'
import React from "react";
import { Editor, EditorState } from 'draft-js';

function StyleButton(props) {
  
  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.label);
  };

  return (
    <span className='colorItem' onMouseDown={onToggle}>
      <div className='empty'></div>
      <div className={`colorView ${props.active ? 'active' : ''}`} style={{backgroundColor: props.color}}></div>
    </span>
  );
}

const ColorControls = ({editorState, colors, onToggle}) => {
  var currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="colorSets">
      {colors.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.label)}
          onToggle={onToggle}
          label={type.label}
          color={type.color}
        />
      )}
    </div>
  );
};

export default ColorControls;