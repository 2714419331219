import React, { FC } from "react";

import allStyles from "./styles";

import { Modal, Row, Container, Col, Button, ListGroup, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { IModalListConsumers } from "./indexModel";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const ModalListConsumers: FC<IModalListConsumers> = (props) => {
  return (
    <Modal
        show={props.modalShow}
        onHide={props.hide}
        aria-labelledby=""
        size="lg"
        centered={true}
    >
        <Modal.Header
            closeButton
            style={{ marginLeft: "4%", marginTop: "1%", marginBottom: "-1%" }}
        >
            <Modal.Title style={allStyles.textBlack}>
                { props.t('dashboard.modal_list_consumers.consumers') }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body
            style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}
        >
            <Container>
                <Row>
                    <ListGroup variant="flush" className="px-0">
                        <ListGroup.Item className="TicketsRowCollums">
                            <Row name="ListOfConsumersHead">
                                <Col md={3} className="nopadding">
                                    <p>{props.t('home_consumers.list_of_consumers.name')} </p>
                                </Col>
                                <Col md={3} className="nopadding">
                                    <p>{props.t('home_consumers.list_of_consumers.email')}</p>
                                </Col>
                                <Col md={3} className="nopadding">
                                    <p>{props.t('defaults.cpfcnpj_nif')}</p>
                                </Col>
                                <Col md={3} className="nopadding">
                                    <p>{props.t('home_consumers.list_of_consumers.phone')}</p>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                    {
                        props.consumers.length > 0 ?
                            <div id="ListOfConsumers" className="px-0" style={{ overflowY: 'auto', height: '56vh' }}>
                                <InfiniteScroll
                                    dataLength={props.consumers.length}
                                    next={() => {}}
                                    hasMore={false}
                                    loader={<h4> </h4>}
                                    scrollableTarget={"ListOfConsumers"}
                                    // onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfConsumers) }}
                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow" >
                                            {props.consumers.map((item, index) => {
                                                return (

                                                    <ListGroup.Item style={{ color: '#707070' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} key={"keyAll" + index.toString()} >
                                                        <Row className='TicketsRowHeight'>
                                                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id}>
                                                                <div className="oneLineClampEllipsis">
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.name != null ?
                                                                                    <>{item.name} {item.name && item.lastname ? item.lastname : ""}</>
                                                                                    :
                                                                                    <>{props.t('home_consumers.list_of_consumers.na')}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.name ? item.name : <>N/A</>} {item.name && item.lastname ? item.lastname : ""}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id}>
                                                                <div className="oneLineClampEllipsis me-3">
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.email != null ?
                                                                                    <>{item.email}</>
                                                                                    :
                                                                                    <>{props.t('home_consumers.list_of_consumers.na')}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.email ? item.email : <>{props.t('home_consumers.list_of_consumers.na')}</>}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.document_number != null ?
                                                                                    <>{item.document_number.length <= 11 ? item.document_number.slice(0, 3) + "." + item.document_number.slice(3, 6) + "." + item.document_number.slice(6, 9) + "-" + item.document_number.slice(9, 11) : item.document_number.slice(0, 2) + "." + item.document_number.slice(2, 5) + "." + item.document_number.slice(5, 8) + "/" + item.document_number.slice(8, 12) + '-' + item.document_number.slice(12, 14)}</>
                                                                                    :
                                                                                    <>{props.t('home_consumers.list_of_consumers.na')}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.document_number != null ?
                                                                                <>{item.document_number.length <= 11 ? item.document_number.slice(0, 3) + "." + item.document_number.slice(3, 6) + "." + item.document_number.slice(6, 9) + "-" + item.document_number.slice(9, 11) : item.document_number.slice(0, 2) + "." + item.document_number.slice(2, 5) + "." + item.document_number.slice(5, 8) + "/" + item.document_number.slice(8, 12) + '-' + item.document_number.slice(12, 14)}</>
                                                                                :
                                                                                <>{props.t('home_consumers.list_of_consumers.na')}</>
                                                                            }
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.phone != null ?
                                                                                <>{formatPhoneNumberIntl("+" + item.phone)}</>
                                                                                :
                                                                                <>{props.t('home_consumers.list_of_consumers.na')}</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item.phone != null ?
                                                                            <>{formatPhoneNumberIntl("+" + item.phone)}</>
                                                                            :
                                                                            <>{props.t('home_consumers.list_of_consumers.na')}</>
                                                                        }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                );

                                            })}
                                        </ListGroup>
                                    }
                                </InfiniteScroll>
                            </div>
                            :
                            <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                                <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                    <Col md={1} className="d-flex justify-content-center">
                                        <svg id="Grupo_11212" data-name="Grupo 11212" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                            <path id="Caminho_9563" data-name="Caminho 9563" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_157" data-name="Elipse 157" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_9564" data-name="Caminho 9564" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_9565" data-name="Caminho 9565" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_9566" data-name="Caminho 9566" d="M21,21V19a4,4,0,0,0-3-3.85" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </Col>
                                </Row>
                                <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '5%', backgroundColor: '#F9F9F9' }}>
                                    <Col md={4}>
                                        <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{ props.t('home_consumers.list_of_consumers.no_results').slice(0, -1) }</p>
                                    </Col>
                                </Row>
                            </div>
                    }
                </Row>
                <Row
                    style={allStyles.textFontGlay}
                    className="d-flex justify-content-center mt-4"
                >
                    <Col md={6} className="d-flex justify-content-start">
                        <Button
                            // type="submit"
                            size="lg"
                            className="buttonBlue"
                            style={{ width: "50%" }}
                            onClick={props.exportConsumersCsv}
                            disabled={props.isLoading}
                        >
                            { props.t('dashboard.modal_list_consumers.export_consumers') }
                        </Button>
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                        <Button
                            size="lg"
                            className="buttonWhite"
                            style={{ width: "50%" }}
                            onClick={props.hide}
                        >
                            { props.t('dashboard.modal_list_consumers.close') }
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>
  );
};

export default ModalListConsumers;
