import React, { FC, useEffect, useState } from 'react';
import ModalViewDomain from '.';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../../store/control_panel';
import { getShowViewDomainModal, setShowAlertFeedback, setShowConfirmationModal, setShowViewDomainModal } from '../../../../../store/internal';
import { IModalViewDomainController } from './indexModel';

const ModalViewDomainController: FC<IModalViewDomainController> = (props) => {
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const appRequesterConst = new AppRequesterController()  

    const [data, setData] = useState(null);

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }, 
        control_panel: {
            getShowViewDomainModal: useSelector(getShowViewDomainModal)
        }
    }

    useEffect(() => {
        if (values.control_panel.getShowViewDomainModal) {
            setShow(true);
        } else {
            setShow(false);
        }

    }, [values.control_panel.getShowViewDomainModal]);

    useEffect(() => {
        /* let array = [];

        for (let index = 0; index < 30; index++) {
            array.push({
                status: 'Pendente' + index,
                type: 'CNAME' + index,
                host: 'hostsjdflkjlkwjfljkdlkfjsdlkjf0298430294ui32094ipidf' + index,
                value: 'valueatumalacalksdjfo328749832uo3ij32orj23ojreo32j4o23i4u347' + index,
                id: index
            });            
        }

        setData(array); */
        const controller = new AbortController();

        if (show && props.domainId) {
            getDomain(controller, props.domainId);
        } else {
            dispatch(setShowViewDomainModal(false));
        }

        return () => {
            controller.abort();
        }
    }, [show, props.domainId]);

    const getDomain = async (controller: AbortController, domainId: string) => {
        let signal = controller.signal;

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + values.token.value,
        };

        await appRequesterConst.Get(
            `/channel-email/domain/${domainId}`, { headers, signal },
            (response: Object) => {return response;},
            (data) => {
                if ((data.status === 200 || data.status === 204) && data.data?.companyEmailDomain) {
                    setData(data.data.companyEmailDomain);
                }
            },
            (error: { response: { status: number; data: { message: string; }; }; }) => {
                /* if (error.response.status === 400) {
                    // dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                } */
            }, navigate, dispatch, () => {}, {}
        ).finally(() => {});
    }

    const confirmValidateDomain = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Verificar domínio', "body": `Continuar com a verificação do domínio ${data?.domain}?`, "buttonReturnText": "Cancelar", "replaceText": data?.domain? data?.domain: "", buttonConfirmationText: "Confirmar" }, functionConfirmation: validateDomain }));
    }

    const validateDomain = async () => {
        let params = {
            dns_registry_id: data?.dns_registry_id
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + values.token.value,
        };

        await appRequesterConst.Post(
            `/channel-email/validate-domain`, params, { headers },
            (response: Object) => { return response; },
            (data) => {
                if (data.data?.valid)
                    dispatch(setShowAlertFeedback({ message: "Domínio verificado com sucesso!", visibility: true, signalIcon: true }));
                else 
                    dispatch(setShowAlertFeedback({ message: "Domínio não verificado. Verifique as configurações de DNS.", visibility: true, signalIcon: false }));

                props.callbackList();
                hide();
            },
            (error: { response: { status: number; data: { message: string; }; }; }) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                /* if (error.response.status === 400) {
                    // dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                } */
            }, navigate, dispatch, () => {}
        ).finally(() => {});
    }

    const hide = () => {
        dispatch(setShowViewDomainModal(false));
    }

    return <ModalViewDomain 
        show={show} 
        hide={hide}   
        data={data}     
        dispatch={dispatch}
        confirmValidateDomain={confirmValidateDomain}
    />
};

export default ModalViewDomainController;