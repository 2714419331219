import React from "react";

import allStyles from "./styles";

import { Modal, Row, Container, Col, Button, Form, InputGroup, FormControl, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import { setShowAlertFeedback, setShowModalConsumersSimilarData } from '../../../../store/internal'
import MaskController from "../../../../core/shared/mask-controller";
import { verifyCode } from "../../../../services/codeCxpressInterface";

import LocalizedPhoneInputController from "../localizedPhoneInput/indexController";
import TagsComponent from "../tagsComponent";
import SelectComponentController from "../selectComponent/indexController";

const ModalRegisterConsumers = ({
  modalShow,
  Hide,
  textareaStates,
  SetCustomers_name,
  SetCustomers_email,
  SetCustomers_cpf,
  SetCustomers_address,
  SetCustomers_observations,
  SetCustomers_instagram,
  SetCustomers_twitter,
  SetCustomers_facebook,
  SetCustomers_youtube,
  Validate,
  customers_name,
  customers_email,
  customers_youtube,
  customers_cpf,
  customers_phone,
  customers_address,
  customers_observations,
  customers_whatshapp,
  customers_instagram,
  customers_twitter,
  customers_facebook,
  customFieldsIds,
  props,
  AppRequesterConst,
  dispatch,
  navigate,
  isLoading,
  setIsLoading,
  setPhoneBorder,
  setShowfeedbackPhone,
  setWhatsappBorder,
  setShowfeedbackWhatsApp,
  validated,
  showfeedbackName,
  showfeedbackEmail,
  showfeedbackCpfCnpj,
  showfeedbackPhone,
  showfeedbackWhatsApp,
  t,
  SetCustomers_phone,
  phoneBorder,
  showfeedback,
  SetCustomers_whatshapp,
  whatsappBorder,
  toggleContent,
  showContent,
  customFields,
  changeCustomField,
  optionsAgents,
  setOptionsAgents,
  selectedAgent,
  setSelectedAgent,
  selectedEmployees,
  optionsEmployees,
  setOptionsEmployees,
  setSelectedEmployees,
  returnLanguage,
  selectTags,
  currentTags,
  Tags,
  countryPhoneCodes,
}) => {

  
  return (
    <Modal
      show={modalShow}
      onHide={Hide}
      aria-labelledby=""
      size="lg"
      centered={true}
    >
      <Modal.Header
        closeButton
        style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}
      >
        <Modal.Title style={allStyles.textBlack}>
          {t('home_consumers.modal_register.create_consumer')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}
      >
        <Container>
          <Formik
            initialValues={{
              name: "",
              email: "",
              cpf: "",
              phone: "",
              address: "",
              observations: "",
              whatsapp: "",
              instagram: "",
              twitter: "",
              facebook: "",
              youtube: "",
              input: textareaStates,
              textarea: textareaStates,
              //tags: ""
            }}
            validate={(values) => {
              SetCustomers_name(values.name);
              SetCustomers_email(values.email);
              SetCustomers_cpf(values.cpf);
              // SetCustomers_phone(values.phone);
              SetCustomers_address(values.address);
              SetCustomers_observations(values.observations);
              // SetCustomers_whatshapp(values.whatsapp);
              SetCustomers_instagram(values.instagram);
              SetCustomers_twitter(values.twitter);
              SetCustomers_facebook(values.facebook);
              SetCustomers_youtube(values.youtube);
              return Validate(values)
            }}
            onSubmit={async () => {
              let JsonSend = {
                name: customers_name,
                "custom_fields": [],
                "tags": []
              };

              if (customers_email !== "") {
                JsonSend["email"] = customers_email;
              }
              if (customers_youtube !== "") {
                JsonSend["youtube"] = customers_youtube;
              }
              if (customers_cpf !== "") {
                JsonSend["document_number"] = customers_cpf.replace(/\D/g, "");
              }

              if (selectedEmployees.length !== 0) {
                JsonSend["employee_user_id"] = selectedEmployees[0].value
              } else {
                JsonSend["employee_user_id"] = undefined
              }

              if (customers_phone != null && customers_phone !== "" && !countryPhoneCodes.includes(customers_phone)) {
                JsonSend["phone"] = customers_phone.replace(/\D/g, "");
              }


              if (customers_instagram !== "") {
                JsonSend["instagram_user"] = customers_instagram;
              }

              if (customers_twitter !== "") {
                JsonSend["twitter"] = customers_twitter;
              }

              if (customers_facebook !== "") {
                JsonSend["facebook_user"] = customers_facebook;
              }

              if (customers_observations !== "") {
                JsonSend["observations"] = customers_observations;
              }

              if (customers_address !== "") {
                JsonSend["address"] = customers_address;
              }

              if (customers_whatshapp && customers_whatshapp !== "" && !countryPhoneCodes.includes(customers_whatshapp)) {
                JsonSend["whatsapp"] = customers_whatshapp.replace(/\D/g, "");
              }

              if (customFieldsIds) {
                customFieldsIds.map((customFieldId) => {
                  JsonSend["custom_fields"].push({ custom_field_id: customFieldId, value: [textareaStates[customFieldId]] })
                })
              }

              if (selectedAgent.length > 0) {
                JsonSend["employee_user_id"] = selectedAgent[0].value;
              }

              if (currentTags.length !== 0) {
                if (currentTags[0] !== undefined) {
                    let auxArray = [];
                    currentTags.map((elem) => {
                        auxArray.push(elem.id);
                    })
                    JsonSend['tags'] = auxArray;
                }
            }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + props.token,
              };

              await AppRequesterConst.Post(
                '/consumer', JsonSend, { headers },
                (response: Object) => { return response; },
                (data: { data: { consumers: { user_id: string; }[]; ConsumersDuplicates: any[] }; }) => {
                  if (data?.data?.ConsumersDuplicates) {
                    dispatch(setShowAlertFeedback({ message: `${t('home_consumers.modal_register.duplicate_consumers')}`, visibility: true, signalIcon: false }));
                    dispatch(setShowModalConsumersSimilarData(true));
                    Hide();

                    // listagem de consumidores similares
                    props.setCurrentConsumer(JsonSend);
                    props.setSimilarConsumers(data.data.ConsumersDuplicates);
                  } else {
                    dispatch(setShowAlertFeedback({ message: `${t('home_consumers.modal_register.success_create')}`, visibility: true, signalIcon: true }));
                    props.callback(data.data.consumers[0].user_id);
                    Hide();
                  }
                },
                (error: { response: { status: number; data: { message: { message: string, path: [] }, code_cxpress: number; }; }; }) => {
                  if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    /* } else if (error.response.status === 422) {
                      dispatch(setShowAlertFeedback({ message: "O campo de observações deve ter no máximo 100 caracteres.", visibility: true, signalIcon: false })); */
                  } else if (error.response.status === 422) {
                    if (error.response.data.message !== undefined) {
                      if (error.response.data.message[0]?.path[0] === "phone") {
                        setPhoneBorder('1px solid red')
                        setShowfeedbackPhone(true)
                      } else if (error.response.data.message[0]?.path[0] === "phone") {
                        setWhatsappBorder('1px solid red')
                        setShowfeedbackWhatsApp(true)
                      } else if (error.response.data.message[0]?.path[0] === "document_number") {
                        dispatch(setShowAlertFeedback({ message: t('errors.invalidDocument'), visibility: true, signalIcon: false }));
                        showfeedbackCpfCnpj(true)
                      }
                    } else {
                      dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
                      // dispatch(setShowAlertFeedback({ message: error.response.data.message[0].message, visibility: true, signalIcon: false }));
                    }
                  } else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                  }
                }, navigate, dispatch, setIsLoading
              );
            }}
          >
            {({
              values,
              setValues,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row
                  style={allStyles.textFontGlay}
                  className=" d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="customers_name">
                      {t('home_consumers.modal_register.form_name.name')}
                      <span
                        style={{
                          font: "normal bold normal calc(0.2em + 0.7vw) Montserrat",
                          color: "black",
                        }}
                      >
                        *
                      </span>
                    </Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={showfeedbackName}
                        placeholder={t('home_consumers.modal_register.form_name.placeholder')}
                        aria-label={t('home_consumers.modal_register.form_name.placeholder')}
                        aria-describedby={t('home_consumers.modal_register.form_name.placeholder')}
                        size="lg"
                        type="name"
                        id="customers_name"
                        name="name"
                        autoComplete='given-name'
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      ></FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackname">
                        {errors.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="customers_email">{t('home_consumers.modal_register.form_email.email')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={showfeedbackEmail}
                        placeholder={t('home_consumers.modal_register.form_email.placeholder')}
                        aria-label={t('home_consumers.modal_register.form_email.placeholder')}
                        aria-describedby={t('home_consumers.modal_register.form_email.placeholder')}
                        size="lg"
                        type="email"
                        id="customers_email"
                        name="email"
                        autoComplete='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      ></FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackemail">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className=" d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="customers_cpf">{t('defaults.cpfcnpj_nif')}</Form.Label>
                    <InputMask
                      mask={MaskController.maskCpfCnpj(values.cpf, returnLanguage())}
                      maskChar={MaskController.maskCharCpfCnpj(values.cpf, returnLanguage())}
                      value={values.cpf}
                      onChange={handleChange}
                    >
                      {(inputProps: any) => (
                        <InputGroup>
                          <FormControl
                            isInvalid={showfeedbackCpfCnpj}
                            className="form-control-Default"
                            placeholder={`${t('home_consumers.modal_register.form_document_number.placeholder_a')} ${t('defaults.cpfcnpj_nif')} ${t('home_consumers.modal_register.form_document_number.placeholder_b')}`}
                            aria-label={`${t('home_consumers.modal_register.form_document_number.placeholder_a')} ${t('defaults.cpfcnpj_nif')} ${t('home_consumers.modal_register.form_document_number.placeholder_b')}`}
                            aria-describedby={`${t('home_consumers.modal_register.form_document_number.placeholder_a')} ${t('defaults.cpfcnpj_nif')} ${t('home_consumers.modal_register.form_document_number.placeholder_b')}`}
                            type="text"
                            size="lg"
                            id="customers_cpf"
                            name="cpf"
                            onBlur={handleBlur}
                            required={true}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            id="feedbackcpf"
                          >
                            {errors.cpf}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    </InputMask>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{t('home_consumers.modal_register.form_phone.phone')}</Form.Label>
                    {/* <PhoneInputDropdown phoneNumber={values.phone} type={PhoneType.Phone} values={values} setValues={setValues} tag={phoneTag} setPhoneError={setPhoneError} /> */}
                    <LocalizedPhoneInputController 
                      placeholder={t('home_consumers.modal_register.form_phone.placeholder')} 
                      value={values.phone} 
                      onChange={(e) => SetCustomers_phone(e)} 
                      handleBlur={handleBlur} 
                      phoneBorder={phoneBorder}                    
                    />
                    <div className='invalid-feedback' style={{ display: showfeedbackPhone ? "block" : "none", height: '0.75rem' }}>
                      {t('home_consumers.modal_register.form_phone.invalid_number')}
                    </div>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className=" d-flex justify-content-center"
                >
                  <Col md={12}>
                    <Form.Label htmlFor="customers_address">{t('home_consumers.modal_register.form_address.address')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={showfeedback}
                        placeholder={t('home_consumers.modal_register.form_address.placeholder')}
                        aria-label={t('home_consumers.modal_register.form_address.placeholder')}
                        aria-describedby={t('home_consumers.modal_register.form_address.placeholder')}
                        size="lg"
                        type="text"
                        id="customers_address"
                        name="address"
                        autoComplete='street-address'
                        maxLength={100}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackaddress"
                      >
                        {errors.address}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  className="d-flex justify-content-center"
                  style={{ height: "10vh" }}
                >
                  <Col md={12} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="customers_observations">{t('home_consumers.modal_register.form_observations.observations')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default-text-area"
                        style={{ height: '10vh' }}
                        isInvalid={showfeedback}
                        as="textarea"
                        rows={10}
                        placeholder={t('home_consumers.modal_register.form_observations.placeholder')}
                        aria-label={t('home_consumers.modal_register.form_observations.placeholder')}
                        aria-describedby={t('home_consumers.modal_register.form_observations.placeholder')}
                        size="lg"
                        type="text"
                        id="customers_observations"
                        name="observations"
                        maxLength={200}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.observations}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackobservations"
                      >
                        {errors.observations}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col md={12}>
                    <Form.Label
                      style={allStyles.textBlackSmaller}
                      htmlFor="basic-url"
                    >
                      {t('home_consumers.modal_register.aditional_infos')}
                    </Form.Label>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                  <Col sm={true} >
                    <Form.Label htmlFor="basic-url">{t('home_consumers.modal_register.associate_agent')}</Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      options={optionsAgents && optionsAgents.length > 0 ? optionsAgents : []}
                      setOptions={setOptionsAgents}
                      selecteds={selectedAgent}
                      setSelecteds={setSelectedAgent}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t('home_consumers.modal_register.select_agent')}
                      overrideStrings={
                        {
                          "allItemsAreSelected": selectedAgent[0] ? selectedAgent[0].label : "",
                          "clearSearch": t('home_consumers.modal_register.clearSearch'),
                          "clearSelected": t('home_consumers.modal_register.clearSelected'),
                          "noOptions": t('home_consumers.modal_register.noOptions'),
                          "search": t('home_consumers.modal_register.search'),
                          "selectAll": t('home_consumers.modal_register.selectAll'),
                          "selectAllFiltered": t('home_consumers.modal_register.selectAllFiltered'),
                          "selectSomeItems": t('home_consumers.modal_register.selectSomeItems'),
                          "create": t('home_consumers.modal_register.create'),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                      disabled={false}
                    />
                  </Col>
                </Row>

              {/* CAMPO DE TAGS */}
                <Row style={allStyles.textFontGlay}
                  className="d-flex justify-content-center">
                    <Col sm={true}>
                      <Col style={{marginBottom:'1vh'}}>
                          {t("home_tickets.home_create_ticket.tags_field_label")}
                      </Col>
                      <Col>
                          <TagsComponent
                              tags={Tags} currentTags={props.listTags} onSelect={props.listTags} setCurrentTags={selectTags}></TagsComponent>
                      </Col>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center" style={{ marginTop: '-2rem' }}>
                  <Col md={12}>
                    <Form.Label
                      style={allStyles.textBlackSmaller}
                      htmlFor="basic-url"
                    >
                      {t('home_consumers.modal_register.form_social_media.social_media')}
                    </Form.Label>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{t('home_consumers.modal_register.form_social_media.whatsapp')}</Form.Label>
                    {/* <PhoneInputDropdown type={PhoneType.WhatsApp} phoneNumber={values.whatsapp} values={values} setValues={setValues} tag={whatsappTag} setPhoneError={setWhatsappError} /> */}
                    <LocalizedPhoneInputController 
                      placeholder={t('home_consumers.modal_register.form_social_media.placeholder_whatsapp')} 
                      value={values.whatsapp} 
                      onChange={(e) => SetCustomers_whatshapp(e)} 
                      handleBlur={handleBlur} 
                      phoneBorder={whatsappBorder} 
                    />
                    <div className='invalid-feedback' style={{ display: showfeedbackWhatsApp ? "block" : "none", height: '0.75rem' }}>
                      {t('home_consumers.modal_register.form_social_media.invalid_number')}
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="customers_instagram">{t('home_consumers.modal_register.form_social_media.instagram')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        //disabled={true}
                        className="form-control-Default"
                        isInvalid={showfeedback}
                        placeholder={t('home_consumers.modal_register.form_social_media.placeholder_instagram')}
                        aria-label={t('home_consumers.modal_register.form_social_media.placeholder_instagram')}
                        aria-describedby={t('home_consumers.modal_register.form_social_media.placeholder_instagram')}
                        size="lg"
                        type="text"
                        id="customers_instagram"
                        name="instagram"
                        maxLength={30}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.instagram}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackinstagram"
                      >
                        {errors.instagram}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>

                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="customers_Twitter">{t('home_consumers.modal_register.form_social_media.twitter')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={showfeedback}
                        placeholder={t('home_consumers.modal_register.form_social_media.placeholder_twitter')}
                        aria-label={t('home_consumers.modal_register.form_social_media.placeholder_twitter')}
                        aria-describedby={t('home_consumers.modal_register.form_social_media.placeholder_twitter')}
                        size="lg"
                        type="text"
                        id="customers_Twitter"
                        name="twitter"
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.twitter}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackTwitter"
                      >
                        {errors.twitter}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>

                  <Col md={6}>
                    <Form.Label htmlFor="customers_facebook">{t('home_consumers.modal_register.form_social_media.facebook')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        //disabled={true}
                        className="form-control-Default"
                        isInvalid={showfeedback}
                        placeholder={t('home_consumers.modal_register.form_social_media.placeholder_facebook')}
                        aria-label={t('home_consumers.modal_register.form_social_media.placeholder_facebook')}
                        aria-describedby={t('home_consumers.modal_register.form_social_media.placeholder_facebook')}
                        size="lg"
                        type="text"
                        id="customers_facebook"
                        name="facebook"
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.facebook}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackfacebook"
                      >
                        {errors.facebook}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="customers_youtube">{t('home_consumers.modal_register.form_social_media.youtube')}</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="form-control-Default"
                        isInvalid={showfeedback}
                        placeholder={t('home_consumers.modal_register.form_social_media.placeholder_youtube')}
                        aria-label={t('home_consumers.modal_register.form_social_media.placeholder_youtube')}
                        aria-describedby={t('home_consumers.modal_register.form_social_media.placeholder_youtube')}
                        size="lg"
                        type="text"
                        id="customers_youtube"
                        name="youtube"
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.youtube}
                      ></FormControl>
                      <Form.Control.Feedback
                        type="invalid"
                        id="feedbackyoutube"
                      >
                        {errors.youtube}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}></Col>
                </Row>
                <Col md={12} style={{ marginTop: '-2rem' }}>
                  <Form.Label
                    style={allStyles.textBlackSmallerCustom}
                    htmlFor="basic-url"
                    onClick={toggleContent}
                  >
                    {t('home_consumers.modal_register.custom_fields')}
                    <span style={{ marginLeft: '3px', transition: 'transform 0.3s', color: '#4a69bd', fontSize: '15px' }}>
                      {showContent ?
                        <svg id="Grupo_11467" data-name="Grupo 11467" xmlns="http://www.w3.org/2000/svg" width="22.383" height="22.383" viewBox="0 0 16.383 16.383">
                          <path id="Caminho_9741" data-name="Caminho 9741" d="M0,16.383H16.383V0H0Z" fill="none" />
                          <path id="Caminho_9742" data-name="Caminho 9742" d="M6,13.1,10.1,9l4.1,4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </svg> :
                        <svg id="Grupo_11489" data-name="Grupo 11489" xmlns="http://www.w3.org/2000/svg" width="22.383" height="22.383" viewBox="0 0 16.383 16.383">
                          <path id="Caminho_9741" data-name="Caminho 9741" d="M0,0H16.383V16.383H0Z" fill="none" />
                          <path id="Caminho_9742" data-name="Caminho 9742" d="M6,9l4.1,4.1,4.1-4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </svg>
                      }
                    </span>
                  </Form.Label>
                  {showContent && (
                    (customFields !== null && customFields !== undefined && customFields.length > 0 ? customFields.map((customField) => {
                      if (customField !== undefined || customField !== null) {
                        switch (customField.type) {
                          case "d31ebd9e-af9f-4559-b04a-729439218a6f": //text
                            return (<>
                              <Col md={12} style={allStyles.textFontGlay}>
                                <Form.Label htmlFor="basic-url">{customField.name}</Form.Label>
                                <InputGroup>
                                  <FormControl
                                    className="form-control-Default"
                                    isInvalid={showfeedback}
                                    placeholder={customField.placeholder}
                                    aria-label={customField.placeholder}
                                    aria-describedby={customField.placeholder}
                                    size="lg"
                                    type="text"
                                    id="customers_input"
                                    name="input"
                                    maxLength={50}
                                    onChange={(e) => changeCustomField(customField, e)}
                                    onBlur={handleBlur}
                                  ></FormControl>
                                  <Form.Control.Feedback
                                    type="invalid"
                                    id="feedbackinput"
                                  >
                                    {errors.input}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </>

                            )
                          case "2982a738-4de3-440a-8ed1-cc1fdb51bc9b": //textarea
                            return (<>
                              <Col md={12} style={allStyles.textFontGlay}>
                                <Form.Label htmlFor="basic-url">{customField.name}</Form.Label>

                                <InputGroup>
                                  <FormControl
                                    className="form-control-Default-text-area"
                                    style={{ height: '10vh' }}
                                    isInvalid={showfeedback}
                                    as="textarea"
                                    rows={10}
                                    placeholder={customField.placeholder}
                                    aria-label={customField.placeholder}
                                    aria-describedby={customField.placeholder}
                                    size="lg"
                                    type="text"
                                    id={customField.id}
                                    name="textarea"
                                    maxLength={500}
                                    onChange={(e) => changeCustomField(customField, e)}
                                    onBlur={handleBlur}
                                  ></FormControl>
                                  <Form.Control.Feedback
                                    type="invalid"
                                    id="feedbacktextarea"
                                  >
                                    {errors.textarea}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                            </>)
                        }
                      }
                    }) : 
                      <Col>
                        <span style={allStyles.textFontGlay}>{t("home_consumers.view_consumers.no_options")}</span>
                      </Col>
                      )
                  )}
                </Col>
                <Row
                  style={allStyles.textFontGlay}
                  className="d-flex justify-content-center mt-4"
                >
                  <Col xs={6}>
                    <Button
                      size="lg"
                      className="buttonWhite"
                      style={{ width: "56%" }}
                      onClick={Hide}
                    >
                      {t('home_consumers.modal_register.cancel')}
                    </Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      size="lg"
                      className="buttonBlue"
                      style={{ width: "56%" }}
                      disabled={isLoading}
                    >
                      {t('home_consumers.modal_register.save')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalRegisterConsumers;
