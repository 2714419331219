import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useDisconnectOldTabs = () => {

    const socket = useContext(SocketContext);

    const [disconnectOldTabs, setDisconnectOldTabs] = useState<boolean>(false);

    useEffect(() => {
        socket.socket?.on('disconnect-old-tabs', (data) => setDisconnectOldTabs(true))

        return () => {
            socket.socket?.off('disconnect-old-tabs', (data) => setDisconnectOldTabs(false))
        }
    }, [socket.socket?.active])

    return { disconnectOldTabs }
}

export default useDisconnectOldTabs