import React, {useState} from "react";
import constsApi from "../services/constsApi";

import { AppInterface } from "./indexModel";

export const AppContext = React.createContext<AppInterface>({});

export const AppProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => {
    /* const [contractStatus, setContractStatus] = useState(null);
    const [limitDays, setLimitDays] = useState(null);
    const [quantityAgents, setQuantityAgents] = useState(null); */
    
    const [htmlMessageContent, setHtmlMessageContent] = useState(null as string || null);
    const [currentMessageChannel, setCurrentMessageChannel] = useState(constsApi.channel[0].id);
    const [isMessageInternalResponse, setIsMessageInternalResponse] = useState(false);
    const [attachmentFiles, setAttachmentFiles] = useState([] as File[]);
    const [templateMessageId, setTemplateMessageId] = useState(null as string || null);

    return (
        <AppContext.Provider 
            value={{
                /* contractStatus, setContractStatus,
                limitDays, setLimitDays,
                quantityAgents, setQuantityAgents, */
                
                htmlMessageContent, setHtmlMessageContent,
                currentMessageChannel, setCurrentMessageChannel,
                isMessageInternalResponse, setIsMessageInternalResponse,
                attachmentFiles, setAttachmentFiles,
                templateMessageId, setTemplateMessageId,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export const useAppContext = () => React.useContext(AppContext);

