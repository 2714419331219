import { useCallback, useState } from "react"
import { AppRequesterController } from "../services/appRequester/appRequesterController"
import UserService from "../services/user-service"
import { setShowAlertFeedback } from "../store/internal"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const AppRequesterConst = new AppRequesterController()

const useNotificationRequest = () => {

    const headers = UserService.getHeaders()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [isLoading, setIsLading] = useState(false)

    const readNotificationRequest = useCallback(async (id?: string) => {
        let JsonSend = {
            id: id
        }
        
        await AppRequesterConst.Put(
            `notification/read`, JsonSend, { headers },
            (response: Object) => {
                return response
            },
            (data) => {
                dispatch(setShowAlertFeedback({ message: t("modal_template_list_notification.notification_marked_as_read"), visibility: true, signalIcon: true }))
            },
            (error) => {

            }, navigate, dispatch, setIsLading
        )
    }, [])

    return { readNotificationRequest, isLoading }
}

export default useNotificationRequest