import { Col, Container, Row, Form, InputGroup, FormControl, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController";
import { Formik } from "formik";
import allStyles from "../botInfo/styles";
import UserService from "../../../../../../services/user-service";
import { setShowAlertFeedback } from "../../../../../../store/internal";

import { IconInfoCircle } from '@tabler/icons-react' 
import SelectComponentController from "../../../../components/selectComponent/indexController";

const AppRequesterConst = new AppRequesterController();

const BotConfig = ({
  overflowMessage,
  setOverflowMessage,
  showOverflowMessageErrorFeedback,
  setShowOverflowMessageErrorFeedback,
  initialChatbotMessage,
  setInitialChatbotMessage,
  showInitialChatbotMessageErrorFeedback,
  setShowInitialChatbotMessageErrorFeedback,
  setIsLoading,
  t,
  goToListOfBots,
  dataBot,
  navigate,
  dispatch,
  temperature,
  setTemperature,
  setBotEnable,
  botEnable,
  ableDisable,
  setAbleDisable,
  understandAudio,
  setUnderstandAudio,
  selectedAudioReply,
  setSelectedAudioReply,
  audioReplyOptions,
  setAudioReplyOptions,
  isWhatsappChannel,
  hasWhatsappKeys,
  directAttendance,
  setDirectAttendance
}) => {

  return (
    <Container className='nopadding'>
      <Row className='nopadding'>
        <Col className='nopadding'>
          <Formik
            initialValues={{
              overflowMessage: dataBot.state.data.init_level2_message,
              temperature: dataBot.state.data.temperature,
              bot_enable: dataBot.state.data.bot_enable,
              initialChatbotMessage: dataBot.state.data.initial_message
            }}
            validate={(values) => {
              let errors = {};
              setOverflowMessage(values.overflowMessage);
              setTemperature(values.temperature)
              // setBotEnable(values.bot_enable)
              setInitialChatbotMessage(values.initialChatbotMessage);
              // if (values.overflowMessage.length === 0) {
              //   errors['overflowMessage'] = t("configurations.chatbots.view.bot_info.fill_field")
              //   setShowOverflowMessageErrorFeedback(true);
              // } else {
              //   setShowOverflowMessageErrorFeedback(false);
              // }

              return errors;
            }}
            onSubmit={async () => {
              const jsonSend = {
                temperature: temperature,
                initial_message: initialChatbotMessage ? initialChatbotMessage : null,
                // understand_audio: understandAudio,
                direct_attendance: directAttendance,
              };

              if(understandAudio) {
                jsonSend['audio_reply_type'] = selectedAudioReply[0]['value'];
              }

              if(overflowMessage) {
                jsonSend['init_level2_message'] = overflowMessage;
              }


              // if(ableDisable && isWhatsappChannel === true) {
              //   jsonSend['bot_enable'] = true;
              // } else {
              //   jsonSend['bot_enable'] = ableDisable;
              // }

              const headers = UserService.getHeaders();

              if (dataBot.state.data) {
                const botId = dataBot.state.data.id;
                goToListOfBots(false, botId);
                await AppRequesterConst.Put(
                  `/bot-cxpress/${botId}/settings`, jsonSend, { headers },
                  (response: Object) => { },
                  (data) => {
                    dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_config.updated_overflow_message"), visibility: true, signalIcon: true }));
                  },
                  (error: { response: { status: number; data: { message: string, code_cxpress: number } }; }) => {
                    if (error.response.data.code_cxpress === 1623) {
                      dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_config.1623_error"), visibility: true, signalIcon: false }));
                    } else if (error.response.data.code_cxpress === 1624) {
                      dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_config.1624_error"), visibility: true, signalIcon: false }));
                    } else {
                      dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }));
                    }

                    if (error.response?.status === 400) {
                      dispatch(setShowOverflowMessageErrorFeedback(true))
                    }
                  }, navigate, dispatch, setIsLoading
                )
              }
            }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

              }) => (
                <Form onSubmit={handleSubmit} >
                {/* {isWhatsappChannel && hasWhatsappKeys &&
                  <>
                    <Row>
                      <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations.chatbots.view.bot_config.enable_chatbot')}
                      {
                        <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip'>
                                    { t('configurations.chatbots.view.bot_config.enable_chatbot_tooltip') }
                                </Tooltip>
                            }
                        >
                            <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem', marginLeft: '0.2rem' }}>
                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            </svg>
                        </OverlayTrigger>
                      }
                    </p>
                    </Row>
                    <Row>
                      <div className="switch-input" >
                        <Form.Label htmlFor="html" > {t('configurations.chatbots.view.bot_config.the_chatbot_is')} {ableDisable ? t('configurations.chatbots.view.bot_config.enabled') : t('configurations.chatbots.view.bot_config.disabled')}</Form.Label>
                        <label className="switch" style={{ marginLeft: '1vw' }}>
                          <input
                            disabled={false}
                            type="checkbox"
                            checked={ableDisable}
                            onChange={() => setAbleDisable(!ableDisable)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </Row>
                  </>
                } */}
                  <div className="bot-config" style={{ overflow: 'auto', overflowX: 'hidden', height: '60vh', flexDirection: 'column', display: 'flex' }}>
                    <div className="mt-4">
                      <Form.Label htmlFor="name" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                        {t("configurations.chatbots.view.bot_config.bot_temperature_title")}
                        <span style={{ marginLeft: "0.5vw" }}>
                          {
                            <OverlayTrigger
                              key='top'
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {t("configurations.chatbots.view.bot_config.bot_temperature")}
                                </Tooltip>
                              }
                            >
                              <IconInfoCircle/>
                              {/* <IconInfoSquareRounded/> */}
                            </OverlayTrigger>
                          }
                        </span>
                      </Form.Label>
                      <Row style={{ height: '36%' }} className=" d-flex justify-content-start mt-1">
                        <Col sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Form.Range min={0} max={1} step={0.01} style={{ marginLeft: "0.3vw" }} id="temperature" bsPrefix={'form-range'} value={values.temperature} onChange={handleChange}/>
                          <InputGroup style={{ width: "8vw" }}>
                            <Form.Control
                              disabled={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.temperature}
                              style={{ backgroundColor: "transparent", border: "none" }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-4 switch-input">
                      <Form.Label htmlFor="name" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                        {t("configurations.chatbots.view.bot_config.bot_direct_transfer_cxpress")}
                        <span style={{ marginLeft: "0.5vw" }}>
                          {
                            <OverlayTrigger
                              key='top'
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {t("configurations.chatbots.view.bot_config.bot_direct_transfer_cxpress_tooltip")}
                                </Tooltip>
                              }
                            >
                              <IconInfoCircle/>
                            </OverlayTrigger>
                          }
                        </span>
                      </Form.Label>
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                          <Tooltip id='tooltip'>
                            {directAttendance ?
                              t("configurations.chatbots.view.bot_config.enabled") :
                              t("configurations.chatbots.view.bot_config.disabled")
                            }
                            </Tooltip>
                            }
                          >
                          <label className="switch" style={{ marginLeft: '1vw' }}>
                            <input
                              disabled={false}
                              type="checkbox"
                              checked={directAttendance}
                              onChange={() => setDirectAttendance(!directAttendance)}
                            />
                            <span className="slider round"></span>
                          </label>
                          </OverlayTrigger>
                    </div>
                    {/* <div className="mt-4 switch-input">
                      <Form.Label htmlFor="audioReplyType" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>
                        {t("configurations.chatbots.view.bot_config.bot_understand_audio_title")}
                        <span style={{ marginLeft: "0.5vw" }}>
                          {
                            <OverlayTrigger
                              key='top'
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {t("configurations.chatbots.view.bot_config.bot_understand_audio_info")}
                                </Tooltip>
                              }
                            >
                              <IconInfoCircle/>
                            </OverlayTrigger>
                          }
                        </span>
                      </Form.Label>
                          <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                              <Tooltip id='tooltip'>
                                  {understandAudio ?
                                  t("configurations.chatbots.view.bot_config.enabled") :
                                  t("configurations.chatbots.view.bot_config.disabled")
                                  }
                              </Tooltip>
                              }
                          >
                          <label className="switch" style={{ marginLeft: '1vw' }}>
                            <input
                              disabled={false}
                              type="checkbox"
                              checked={understandAudio}
                              onChange={() => setUnderstandAudio(!understandAudio)}
                            />
                            <span className="slider round"></span>
                          </label>
                          </OverlayTrigger>
                          {understandAudio && <div style={{width: '450px'}}>
                            <SelectComponentController
                                className={"multiselectForm"}
                                options={audioReplyOptions}
                                setOptions={setAudioReplyOptions}
                                selecteds={selectedAudioReply}
                                setSelecteds={setSelectedAudioReply}
                                singleSelect={true}
                                isFormNoFilter={true}
                                title={"audio reply type"}
                                overrideStrings={{}}
                                disableSearch={true}
                                hasCreateItem={false}
                                hasNullOption={false}
                                hasAllOption={false}
                                disabled={!understandAudio}
                              />
                          </div>}
                    </div> */}
                    <Row style={{ height: '36%' }} className=" d-flex justify-content-start my-3">
                      <Col sm={7}>
                      <Form.Label htmlFor="name" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{ t("configurations.chatbots.view.bot_config.initial_chatbot_message") }<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}></span> </Form.Label>
                        <InputGroup className="mb-2">
                          <FormControl className="form-control-Default-text-area"
                            style={{ height: '20vh' }}
                            size='lg'
                            type='text'
                            as="textarea"
                            isInvalid={showInitialChatbotMessageErrorFeedback}
                            placeholder={t("configurations.chatbots.view.bot_config.inform_initial_chatbot_message")}
                            aria-label={t("configurations.chatbots.view.bot_config.inform_initial_chatbot_message")}
                            aria-describedby={t("configurations.chatbots.view.bot_config.inform_initial_chatbot_message")}
                            id="initialChatbotMessage"
                            name="initialChatbotMessage"
                            autoComplete='initialChatbotMessage'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.initialChatbotMessage}
                            maxLength={4096}
                          >
                          </FormControl>
                          <Form.Control.Feedback type="invalid" id="feedbackName">
                            {errors.initialChatbotMessage}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row style={{ height: '36%' }} className=" d-flex justify-content-start my-3">
                      <Col sm={7}>
                      <Form.Label htmlFor="name" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{ t("configurations.chatbots.view.bot_config.overflow_message") }<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}></span> </Form.Label>
                      <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{t("configurations.chatbots.view.bot_config.bot_info_subtitle")}</p>
                        <InputGroup className="mb-2">
                          <FormControl className="form-control-Default-text-area"
                            style={{ height: '20vh' }}
                            size='lg'
                            type='text'
                            as="textarea"
                            isInvalid={showOverflowMessageErrorFeedback}
                            // placeholder={t("configurations.chatbots.view.bot_config.inform_overflow_message")}
                            aria-label={t("configurations.chatbots.view.bot_config.inform_overflow_message")}
                            aria-describedby={t("configurations.chatbots.view.bot_config.inform_overflow_message")}
                            id="overflowMessage"
                            name="overflowMessage"
                            autoComplete='overflowMessage'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.overflowMessage}
                            maxLength={4096}
                          >
                          </FormControl>
                          <Form.Control.Feedback type="invalid" id="feedbackName">
                            {errors.overflowMessage}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row style={{ height: '36%' }} className="d-flex justify-content-start my-3">
                    <Col style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                      <Row
                        style={allStyles.textFontGlay}
                        className="d-flex justify-content-start mt-4"
                      >
                        <Col xs={3}>
                          <Button
                            size="lg"
                            className="buttonWhiteChatbot"
                            onClick={() => goToListOfBots()}
                          >
                            { t("configurations.chatbots.view.bot_info.back") }
                          </Button>
                        </Col>
                        <Col xs={3} className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            size="lg"
                            className="buttonBlue"
                          >
                            { t("configurations.chatbots.view.bot_config.save") }
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )
            }
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}

export default BotConfig;