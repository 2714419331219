import { t } from 'i18next';
import { HomeInterface, BodyInterface } from './indexModel'

export class HomeControler implements HomeInterface {
    ValidationInputSubdomain = (values: BodyInterface, FeedbackShow: Function, FeedbackHide: Function) => {
        let errors = {};
        if (!values.subdomain) {
            errors['subdomain'] = t("login.error_fill_field");
            FeedbackShow();
        } else if (
            !/^[A-Z0-9]{2,40}$/i.test(values.subdomain)
        ) {
            errors['subdomain'] = t("login.invalid_subdomain");
            FeedbackShow();
        } else {
            FeedbackHide();
        }
        return errors;
    }
}


