import React, { FC } from "react";
import { IListTagsComponent } from "./indexModel";
import { Button, Col, Container, Form, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import './styles.css';

const ListTagsComponent: FC<IListTagsComponent> = (props) => {
    
    return (
        <>  
            <Container className="px-0">
                {props.initTags && props.initTags.length > 0? 
                    <Row >
                        <Col md={10} /* className="px-0" */>
                            {props.dragged !== null && (
                                <div 
                                    style={{
                                        left: `${props.mouse[0]}px`,
                                        top: `${props.mouse[1]}px`,
                                        width: '10vw',
                                        backgroundColor: '#f0f8ff',
                                    }}
                                    className="floating-param integration-param oneLineClampEllipsis">
                                    {props.initTags[props.dragged].alias}
                                </div>
                                
                            )}

                            <div className='integration-params nopadding'>
                                <ListGroup variant="flush" >
                                    <ListGroup.Item className={"integration-param drop-zone-param "+ (props.dragged === null || props.dropZone !== 0? "hidden": "")} style={{ cursor: 'pointer', /* minHeight: '4rem', */ /* backgroundColor: '#fff' */ }} key={'drop-zone'} ></ListGroup.Item>

                                    {props.initTags.map((value, index) => {
                                        return (
                                            props.dragged !== index && 
                                                <>
                                                    <ListGroup.Item className="integration-param px-0" id={"itemParamList" + (index + 1)}
                                                        // onClick={() => { props.setSelectedTag(value.alias ? value.alias : value.id) }}
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            props.setDragged(index);
                                                            props.setSelectedTag(value.alias ? value.alias : value.id);
                                                        }}
                                                        style={{ cursor: 'pointer', /* minHeight: '4rem', */ backgroundColor: (props.selectedTag === (value.alias || value.id)) && '#f0f8ff' }} key={index + 1}
                                                    >
                                                        <Row style={{ color: '#0C2461', height: '0.4rem' }} className="d-flex align-items-center">
                                                            <Col md={2}>
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id={"checkboxes" + index}
                                                                    checked={props.selectedTag === (value.alias || value.id)}
                                                                    className="form-check-input-2"
                                                                    onMouseDown={(e) => e.stopPropagation()}
                                                                    onChange={() => {
                                                                        if (props.selectedTag === (value.alias || value.id)) {
                                                                            props.setSelectedTag(null);
                                                                        } else {
                                                                            props.setSelectedTag(value.alias || value.id);
                                                                        }
                                                                    }}
                                                                    style={{ paddingLeft: '2.5px' }}
                                                                />
                                                            </Col>
                                                            <Col md={2}>
                                                                <div className='ms-1 oneLineClampEllipsis nopadding'>
                                                                    <span>
                                                                        {index + 1}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='ms-1 oneLineClampEllipsis nopadding'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id={'tooltip*ID*' + value.alias}>
                                                                                {value.alias}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {value.alias}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='ms-1 oneLineClampEllipsis nopadding'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id={'tooltip*ID*' + value.tag}>
                                                                                {value.tag}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {value.tag}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={8} className="d-flex justify-content-center ps-4">
                                                                <div className=' nopadding oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id={'tooltip*ID*label*' + value.name}>
                                                                                {value.label}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span className="">
                                                                            {value.label}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col> */}
                                                        </Row>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className={"integration-param drop-zone-param "+ (props.dragged === null || props.dropZone !== index + 1? "hidden": "")} style={{ cursor: 'pointer', /* minHeight: '4rem', */ /* backgroundColor: '#fff' */ }} key={'drop-zone2'} ></ListGroup.Item>
                                                </>
                                        )
                                    })}
                                </ListGroup>
                            </div>
                        </Col>
                        <Col className="" md={1}>
                            <Row style={{ height: '100%', /* width: '2vw' */ }} className="d-flex justify-content-center">
                                {/* <Row className="d-flex align-items-end">
                                    <Button onClick={props.clickUp} style={{ background: 'none', border: 'none' }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-up-filled" width="2vw" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2C4CA2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v7a2 2 0 0 0 2 2h4l.15 -.005a2 2 0 0 0 1.85 -1.995l-.001 -7h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z" strokeWidth="0" fill="#2C4CA2" />
                                        </svg>
                                    </Button>
                                </Row>
                                <Row className="d-flex align-items-start">
                                    <Button onClick={props.clickDown} style={{ background: 'none', border: 'none' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-down-filled" width="2vw" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2C4CA2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M10 2l-.15 .005a2 2 0 0 0 -1.85 1.995v6.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-6.999a2 2 0 0 0 -2 -2h-4z" strokeWidth="0" fill="#2C4CA2" />
                                        </svg>
                                    </Button>
                                </Row> */}
                                <Row className="d-flex align-items-end">
                                    <Button onClick={props.deleteTagConfirmation} disabled={props.selectedTag === null? true: false} style={{ background: 'none', border: 'none' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="2vw" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2C4CA2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 7l16 0" />
                                            <path d="M10 11l0 6" />
                                            <path d="M14 11l0 6" />
                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                        </svg>
                                    </Button>
                                </Row>
                            </Row>
                        </Col>
                    </Row>:
                    <Row style={{ overflow: 'hidden', height: '10vh', backgroundColor: '#F9F9F9', width: '100%' }} className="align-items-center mx-0">
                        <Col className="align-items-center" style={{  }}>
                            <Row className="justify-content-center" style={{ }}>
                                <Col md={1} className="d-flex justify-content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-layers-linked" width="4vw" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#4a69bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M19 8.268a2 2 0 0 1 1 1.732v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h3" />
                                        <path d="M5 15.734a2 2 0 0 1 -1 -1.734v-8a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-3" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                <Col md={4} className="d-flex justify-content-center">
                                    <span style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem variáveis</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </Container>
        </>
    );
}

export default ListTagsComponent;