import { FC, useEffect, useState } from "react";
import { IListOfTemplateMessagesController } from "./indexModel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ListOfTemplateMessages from ".";
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../../../store/company";
import { getIdEmployee } from "../../../../../../store/employee";
import { getIdUser, getNameUser, getProfileId } from "../../../../../../store/user";
import { getToken } from "../../../../../../store/control_panel";
import Utils from "../../../../../../core/shared/utils";
import { setShowAlertFeedback, setShowConfirmationModal, setShowEditTemplateMessage } from "../../../../../../store/internal";

const ListOfTemplateMessagesController: FC<IListOfTemplateMessagesController> = (props) => {
    let navigate = useNavigate();
    //const [confirmationText, setConfirmationText] = useState({ "title": '', "body": '', 'id': '' });
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();

    const [currentTemplateId, setCurrentTemplateId] = useState('');
    const [currentTemplateName, setCurrentTemplateName] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
    };

    useEffect(() => {
        Utils.changeActiveScrollBar("ListOfTemplateMessages");
    }, [props.datas]);

    const deleteTemplateMessages = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Delete(
            `/template-message/whatsapp/${currentTemplateId}`, { headers },
            (response: Object) => {},
            (data: any) => {
                dispatch(setShowAlertFeedback({
                    message: `Template message ${currentTemplateName} excluído com sucesso!`, signalIcon: true, visibility: true
                }));

                props.callback();
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                } else if (error?.response?.status === 401) {

                } else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading
        )
    }

    const deleteConfirmation = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Excluir Template Message', "body": 'Tem certeza que deseja excluir a template message', "id": ' ' + currentTemplateName + '?', "buttonReturnText": "Cancelar" }, functionConfirmation: () => deleteTemplateMessages() }));
    }

    const setCurrentTemplateInfo = (e: { currentTarget: { id: string; }; }) => {
        let info = e.currentTarget.id.split("*ID*");

        setCurrentTemplateId(info[1]);
        setCurrentTemplateName(info[0]);
        let template = props.datas.find((item) => item.id === info[1]);
        props.setCurrentTemplate(template);
    }

    const showEditTemplateMessage = (e/* e: { currentTarget: { parentElement: { parentElement: { id: string; }; }; }; } */) => {
        // setCurrentTemplateInfo(e);
        dispatch(setShowEditTemplateMessage(true));
    }

    return (
        <ListOfTemplateMessages 
            currentTemplateId={currentTemplateId} 
            showEditTemplateMessage={showEditTemplateMessage} 
            deleteConfirmation={deleteConfirmation} 
            isLoading={isLoading} 
            datas={props.datas} 
            updateListfunction={() => {}} 
            HasMoreInformation={true} 
            setCurrentTemplateInfo={setCurrentTemplateInfo} 
            showCreateTemplateMessage={() => {}}        
            isEditAllowed={props.isEditAllowed}
        />
    );
}

export default ListOfTemplateMessagesController;