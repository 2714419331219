import { Button, Col, Container, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import Loading from "../../../components/loading";
import { Formik } from "formik";
import { IconEyeClosed, IconEye, IconEyeOff } from "@tabler/icons-react";
import { FC } from "react";
import { NewPasswordControlPanelInterface } from "./indexModel";

import './styles.scss';

const NewPasswordControlPanel: FC<NewPasswordControlPanelInterface> = (props) => {
  return (
    <>
      <Container fluid className='nopadding newPasswordControlPanel'>
        {props.isLoading && <Loading visibility={props.isLoading} />}
        <Row className='nopadding'>
          <Col className='nopadding'>
            <Row className='nopadding align-items-center body-content'>
              <div className='d-flex justify-content-center'>
                <Col className='box-content' sm={3} md={3}>
                  <div className='d-flex align-items-center'>
                    <div className='validateLogo'>
                      <img src='/Logo.svg' alt='' />
                    </div>
                    <Col className='d-flex justify-content-end px-1 box-title'>
                      Redefenir senha
                    </Col>
                  </div>
                  {
                    props.scene === 'new-password' &&
                    <Formik
                      initialValues={{ password: '', confirm_password: '' }}
                      validate={props.validateNewPassword}
                      onSubmit={props.submitNewPassword}
                    >
                      {formik => (
                        <Form className='newpasswordForm'
                          onSubmit={formik.handleSubmit}
                          noValidate
                          validated={props.validated}
                        >
                          <Row className='d-flex justify-content-center'>
                              <Row className='form-item mt-4'>
                                <Form.Label htmlFor='password' className='px-1'>Crie uma nova senha</Form.Label>
                                <InputGroup className='mb-3 px-1'>
                                  <FormControl
                                    className='form-control-Default'
                                    isInvalid={props.showFeedbackPassword}
                                    placeholder='Informe sua nova senha'
                                    aria-label='Informe sua nova senha'
                                    aria-describedby='Informe sua nova senha'
                                    size='lg'
                                    type='password'
                                    id='password'
                                    name='password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                  ></FormControl>
                                  <span className="showHidePassword">
                                    { props.showPassword ? 
                                      <IconEye color='#707070' onClick={() => props.clickPassword()} /> 
                                      :
                                      <IconEyeOff color='#707070' onClick={() => props.clickPassword()} /> 
                                    }
                                  </span>
                                  <Form.Control.Feedback type='invalid' id='feedbackPassword'>
                                    {formik.errors.password}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Row>
                              <Row className='form-item mt-2'>
                                <Form.Label htmlFor='confirm_password' className='px-1'>Confirmar nova senha</Form.Label>
                                <InputGroup className='mb-3 px-1'>
                                  <FormControl
                                    className='form-control-Default'
                                    isInvalid={props.showFeedbackConfirmPassword}
                                    placeholder='Confirme sua nova senha'
                                    aria-label='Confirme sua nova senha'
                                    aria-describedby='Confirme sua nova senha'
                                    size='lg'
                                    type='password'
                                    id='confirm_password'
                                    name='confirm_password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirm_password}
                                  ></FormControl>
                                  <span className="showHidePassword">
                                    { props.showConfirmPassword ? 
                                      <IconEye color='#707070' onClick={() => props.clickConfirmPassword()} /> 
                                      :
                                      <IconEyeOff color='#707070' onClick={() => props.clickConfirmPassword()} /> 
                                    }
                                  </span>
                                  <div className='d-flex justify-content-end align-self-start'>
                                  </div>
                                  <Form.Control.Feedback type='invalid' id='feedbackConfirmPassword'>{formik.errors.confirm_password}</Form.Control.Feedback>
                                </InputGroup>
                              </Row>
                              <Row className='mt-3'>
                                <div className='px-1 actionButtons'>
                                  <Button type='submit' className='buttonBlue px-1' disabled={!props.validated}>Salvar nova senha</Button>
                                  <Button className='buttonFirstAccess' onClick={() => props.goToLogin()}>
                                    Voltar para o login
                                  </Button>
                                </div>
                              </Row>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  }
                  {
                    props.scene === 'change-success' &&
                    <>
                      <div className='confirmed'>
                        <span className="title mt-2 mb-3">Nova senha configurada!</span>
                        <p className="description">
                          Sua senha foi alterada com sucesso e está pronta para uso
                        </p>
                        <Button className='buttonFirstAccess' onClick={() => props.goToLogin()}>Voltar para o login</Button>
                      </div>
                    </>
                  }
                  {
                    props.scene === 'invalid-token' &&
                    <>
                      <div className='confirmed'>
                        <span className="title mt-2 mb-3">Token inválido!</span>
                        <p className="description">
                          Seu código de redefinição é inválido ou expirou. Solicite um novo email de redefinição de senha
                        </p>
                        <Button className='buttonFirstAccess' onClick={() => props.goToLogin()}>Voltar para o login</Button>
                      </div>
                    </>
                  }
                </Col>
              </div>
            </Row>
            <Row className='nopadding footer-content'>
              <Col className='d-flex justify-content-center align-items-center pt-3'>
                <p className='footer-text'>
                  <span>Esta é uma área exclusiva e de acesso restrito. </span>
                  <span>2022 Cxpress. Todos os direitos reservados</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewPasswordControlPanel;
