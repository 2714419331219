import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../services/requestsInterfacesModel";
import UserService from "../services/user-service"
import { getIdUser } from "../store/user";
import { setShowAlertFeedback, setSoundNotification, setVisualNotification } from "../store/internal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AppRequesterController } from "../services/appRequester/appRequesterController";
import { useNavigate } from "react-router-dom";

const AppRequesterConst = new AppRequesterController()

const useTypeNotificationRequest = (somTicket, somMessage, visualTicket, visualMessage) => {
    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser),
        }
    };
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const headers = UserService.getHeaders()

    const typeNotificationRequest = async () => {


        let JsonSend = {
            user_id: values?.user.id,
        }

        if (somTicket) {
            JsonSend['notification_sound_origin'] = "2fc3c59e-edc5-4a00-92c9-f90c72de634a"
            dispatch(setSoundNotification("2fc3c59e-edc5-4a00-92c9-f90c72de634a"))
        } else if (somMessage) {
            JsonSend['notification_sound_origin'] = "101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42"
            dispatch(setSoundNotification("101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42"))
        }
        else {
            dispatch(setSoundNotification(null))
        }

        if (visualTicket) {
            JsonSend['notification_visual_origin'] = "2fc3c59e-edc5-4a00-92c9-f90c72de634a"
            dispatch(setVisualNotification("2fc3c59e-edc5-4a00-92c9-f90c72de634a"))
        }
        else if (visualMessage) {
            JsonSend['notification_visual_origin'] = "101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42"
            dispatch(setVisualNotification("101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42"))
        }
        else {
            dispatch(setVisualNotification(null))

        }

        await AppRequesterConst.Put(
            `/agent/type-notification-origin`, JsonSend, { headers },
            (response: Object) => {
                return response
            },
            (data) => {
            },
            (error) => {
                dispatch(setShowAlertFeedback({ message: t("modal_template_list_notification.an_unexpected_error_occurred"), visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading
        )
    }

    return { typeNotificationRequest, isLoading }
}

export default useTypeNotificationRequest