import { FC, useEffect, useState } from 'react'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom"
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel'
import { getToken } from '../../../../store/token'
import FormConfigCompany from '.'
import { setShowAlertFeedback, setShowConfirmationModal } from '../../../../store/internal'
import { AppRequesterControlPanelController } from '../../../../services/appRequesterControlPanel/appRequesterControlPanelController'
import { useTranslation } from 'react-i18next'
import { FormConfigCompanyControllerInterface } from './indexModel'


const AppRequesterConst = new AppRequesterControlPanelController()

const FormConfigCompanyController: FC<FormConfigCompanyControllerInterface> = (props) => {

    const [validated] = useState(false)
    const [selectedPages, setSelectedNumber] = useState([])
    const [optionsNumber, setOptionsNumber] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState([])
    const [token, setToken] = useState([])
    const [show, setShow] = useState(false)
    const [companyId, setCompanyId] = useState()
    const [companyEmail, setCompanyEmail] = useState()
    const [companySubdomain, setCompanySubdomain] = useState()
    const [linkAtivation, setLinkAtivation] = useState()
    const [buttonCopyUrl, setButtonCopyUrl] = useState(false)
    const [validationToken, setValidationToken] = useState<Array<String>>()

    const [showfeedbackToken, setShowFeedbackToken] = useState(false)
    const [showfeedbackTokenTranscription, setShowFeedbackTokenTranscription] = useState(false)
    const [tokenTranscription, setTokenTranscription] = useState("")

    const [selectBroker, setOnSelectBroker] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { t } = useTranslation()

    const handleClose = () => {
        setShow(false)
        setButtonCopyUrl(false)
    }
    const handleShow = () => setShow(true)

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        if (props.data) {
            setCompanyEmail(props.data.email)
            setCompanySubdomain(props.data_subdomain.subdomain)
            setCompanyId(props.data.id)
        }
    }, [props])

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copyTextToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text)
            setButtonCopyUrl(true)
            dispatch(setShowAlertFeedback({ message: "Link copiado para área de transferência", visibility: true, signalIcon: true }))
        } catch (e) {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um problema, tente novamente mais tarde", visibility: true, signalIcon: false }))
        }
    }

    const resendActivationEmail = (email, subdomain) => {
        const JsonSend = {
            email: email,
            subdomain: subdomain
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        AppRequesterConst.Post(
            `/authentication/request-comfirmation`, JsonSend, { headers: headers },
            (response: Object) => {
                return response;
            },
            (data) => {
                dispatch(setShowAlertFeedback({ message: "Reenvio realizado com sucesso", visibility: true, signalIcon: true }))
            },
            (error: Object) => {  }, navigate, dispatch, setIsLoading
        )
    }

    const generateLinkAtivation = (email, company_id) => {
        const JsonSend = {
            email: email,
            company_id: company_id
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        AppRequesterConst.Post(
            `/validateemail`, JsonSend, { headers },
            (response: Object) => {
                return response;
            },
            (data) => {
                setLinkAtivation(data.data.link)
                handleShow()
            },
            (error: Object) => {  }, navigate, dispatch, setIsLoading
        )
    }

    const resendEmail = () => {
        dispatch(setShowConfirmationModal({
            visibility: true,
            text: { title: 'Reenviar e-mail de ativação', body: "Deseja enviar o email de ativação de conta para ", id: `${companyEmail}?`, 'buttonReturnText': 'Cancelar', buttonConfirmationText: 'Enviar e-mail', closeButton: false },
            functionConfirmation: () => resendActivationEmail(companyEmail, companySubdomain)
        }));
    };

    const activeCompany = () => {
        dispatch(setShowConfirmationModal({
            visibility: true,
            text: { title: 'Ativar empresa', body: "Deseja ativar a conta de proprietário ", id: `${companyEmail}?`, 'buttonReturnText': 'Cancelar', buttonConfirmationText: 'Ativar', closeButton: false },
            functionConfirmation: () => generateLinkAtivation(companyEmail, companyId)
        }));
    };

    return (
        <FormConfigCompany
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            // handleCadastroPagina={handleCadastroPagina}
            resendEmail={resendEmail}
            tokenGPT={props?.company_data?.token_chatgpt}
            setShowFeedbackToken={setShowFeedbackToken}
            showfeedbackToken={showfeedbackToken}
            setToken={setToken}
            token={token}
            navigate={navigate}
            dispatch={dispatch}
            setIsLoading={setIsLoading}
            id={props?.data?.company_id}
            acessToken={values?.token?.value}
            name={props?.datas?.name}
            getCompany={props.getCompany}
            activeCompany={activeCompany}
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
            linkAtivation={linkAtivation}
            copyTextToClipboard={copyTextToClipboard}
            buttonCopyUrl={buttonCopyUrl}
            t={t}
            isActiveCompany={props.isActiveCompany}
            showfeedbackTokenTranscription={showfeedbackTokenTranscription}
            setShowFeedbackTokenTranscription={setShowFeedbackTokenTranscription}
            setValidationToken={setValidationToken}
            validationToken={validationToken}
            token_transcription={props?.company_data?.token_transcription}
            setTokenTranscription={setTokenTranscription}
            tokenTranscription={tokenTranscription}
        />
    )
}

export default FormConfigCompanyController