import { Formik } from 'formik';
import React from 'react';

import { Channels } from "../../../../../../core/enums/channels";
import { Col, Row, Form, InputGroup, FormControl, Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import allStyles from './styles';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../../services/user-service';

import { setPromptUpdatedNlp, setShowAlertFeedback } from '../../../../../../store/internal'
import SelectComponentController from '../../../../components/selectComponent/indexController';

const AppRequesterConst = new AppRequesterController();

const BotInfo = ({
  botName,
  prompt,
  sourceText,
  setBotName,
  setPrompt,
  setSourceText,
  setShowBotNameErrorFeedback,
  setShowPromptErrorFeedback,
  values,
  channel,
  fileIds,
  dataBot,
  dispatch,
  goToListOfBots,
  navigate,
  setIsLoading,
  showBotNameErrorFeedback,
  showPromptErrorFeedback,
  getRootProps,
  getInputProps,
  showFiles,
  optionsBotVersion,
  setOptionsBotVersion,
  selectedBotVersion,
  setSelectedBotVersion,
  maxToken,
  getTokens,
  t,
  setActiveTab,
  bot
}) => {
  return (
    <>
      <Container className='nopadding'>
        {/* <Loading visibility={isLoading}></Loading> */}
        <Row className='nopadding'>
          <Col className='nopadding'>
            <Formik
              initialValues={{
                botName: botName,
                prompt: prompt,
              }}
              validate={(values) => {
                let errors = {};
                setBotName(values.botName);
                setPrompt(values.prompt);
                if (values.botName.length === 0) {
                  errors['botName'] = t("configurations.chatbots.view.bot_info.fill_field")
                  setShowBotNameErrorFeedback(true);
                } else {
                  setShowBotNameErrorFeedback(false);
                }

                if (values.prompt.length === 0) {
                  errors['prompt'] = t("configurations.chatbots.view.bot_info.fill_field")
                  setShowPromptErrorFeedback(true);
                } else if (getTokens(values.prompt) > selectedBotVersion[0].value) {

                  errors['prompt'] = t("configurations.chatbots.view.bot_info.max_len")
                  setShowPromptErrorFeedback(true);
                } else {
                  setShowPromptErrorFeedback(false);
                }

                return errors;
              }}
              onSubmit={async () => {
                const JsonSend = {
                  "bot_name": botName,
                  "company_id": values.company.id,
                  "behavioral_rules": prompt,
                  "gpt_version_id": selectedBotVersion[0].id,
                  "is_voice_bot": dataBot?.state?.voice
                };

                if (channel === Channels.Webchat) {
                  JsonSend["channel_id"] = channel;
                }

                if (channel === Channels.Facebook) {
                  JsonSend["channel_id"] = channel;
                }

                if (channel === Channels.WhatsApp) {
                  JsonSend["channel_id"] = channel;
                }

                if (channel === Channels.Instagram) {
                  JsonSend["channel_id"] = channel;
                }

                if (channel === Channels.Telefonia) {
                  JsonSend["channel_id"] = channel;
                }

                if (fileIds.length > 0) {
                  JsonSend["files"] = fileIds.map((item) => {
                    if (item.file_id) {
                      return { "file_id": item.file_id }
                    } else {
                      return { "gfsId": item.id, "name": item.name }
                    }
                  });
                }

                const headers = UserService.getHeaders();
                if (dataBot.state.data) {
                  dispatch(setPromptUpdatedNlp(false))
                  
                  goToListOfBots(false, dataBot.state.data.id)

                  JsonSend["bot_cxpress_id"] = dataBot.state.data.id

                  await AppRequesterConst.Put(
                    '/bot-cxpress', JsonSend, { headers },
                    (response: Object) => { },
                    (data) => {
                      setActiveTab(JsonSend["channel_id"])
                      // dispatch(setShowAlertFeedback({ message: "Chatbot atualizado com sucesso!", visibility: true, signalIcon: true }));
                    },
                    (error: { response: { status: number; data: { message: string } }; }) => {
                      if (error.response.data.message) {
                        dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                      } else {
                        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }));
                      }

                      if (error.response?.status === 400) {
                        dispatch(setPromptUpdatedNlp(true))
                        bot.postMessage(true)
                      }
                    }, navigate, dispatch, setIsLoading
                  );
                } else {
                  await AppRequesterConst.Post(
                    '/bot-cxpress', JsonSend, { headers },
                    (response: Object) => { },
                    (data) => {
                      // dispatch(setShowAlertFeedback({ message: "Chatbot cadastrado com sucesso!", visibility: true, signalIcon: true }));
                      dispatch(setPromptUpdatedNlp(false))
                      goToListOfBots(false, data.data.bot_cxpress.id)
                      setActiveTab(JsonSend["channel_id"])
                    },
                    (error: { response: { status: number; data: { message: string } }; }) => {
                      if (error.response.data.message) {
                        dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                      } else {
                        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }));
                      }
                      goToListOfBots()
                    }, navigate, dispatch, setIsLoading
                  );
                }
              }
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

              }) => (
                <Form onSubmit={handleSubmit} /* noValidate validated={validated} */>
                  <div style={{ overflow: 'auto', overflowX: 'hidden', height: '60vh', flexDirection: 'column', display: 'flex' }}>
                    <Row style={{ height: '36%' }} className=" d-flex justify-content-start my-3">
                      <Col style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                        <Row className="mx-2">
                          <Col sm={6}>
                            <Form.Label htmlFor="name" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{ t("configurations.chatbots.view.bot_info.name") }<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span> </Form.Label>
                            <InputGroup className="mb-2">
                              <FormControl className="form-control-Default"
                                isInvalid={showBotNameErrorFeedback}
                                placeholder={t("configurations.chatbots.view.bot_info.inform_name")}
                                aria-label={t("configurations.chatbots.view.bot_info.inform_name")}
                                aria-describedby={t("configurations.chatbots.view.bot_info.inform_name")}
                                size='lg'
                                type='name'
                                id="bot_name"
                                name="botName"
                                autoComplete='botName'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.botName}
                              >
                              </FormControl>
                              <Form.Control.Feedback type="invalid" id="feedbackName">
                                {errors.botName}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                          <Col sm={6}>
                            <Form.Label htmlFor="name" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{t("configurations.chatbots.view.bot_info.version")}</Form.Label>
                            {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                            <SelectComponentController
                              className={"multiselectForm"}
                              options={optionsBotVersion}
                              setOptions={setOptionsBotVersion}
                              selecteds={selectedBotVersion}
                              setSelecteds={setSelectedBotVersion}
                              singleSelect={true}
                              isFormNoFilter={true}
                              title={"gpt-3.5-turbo"}
                              overrideStrings={
                                {
                                  // "allItemsAreSelected": "Versão selecionada.",
                                  // "clearSearch": "Limpar pesquisa",
                                  // "clearSelected": "Limpar versões selecionados",
                                  "noOptions": t("configurations.chatbots.view.bot_info.no_versions"),
                                  // "search": "Pesquisar versão",
                                  // "selectAll": "Selecionar todas as versões",
                                  // "selectAllFiltered": "Selecionar todas as versões (Filtrado)",
                                  "selectSomeItems": "gpt-3.5-turbo",
                                  // "create": "Criar",
                                }
                              }
                              disableSearch={true}
                              hasCreateItem={false}
                              hasNullOption={false}
                              hasAllOption={false}
                            />
                          <a className='ps-0' target='_blank' href='https://openai.com/pricing' style={{ font: 'var(--px15_19_Bold-font)', color: '#4A69BD', marginTop: '1vh' }} rel="noreferrer">
                            <span className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070', textDecoration: "underline", cursor: 'pointer' }}>{ t("configurations.chatbots.view.bot_info.verify_prices") }</span>
                          </a>
                          </Col>
                        </Row>
                        <Row className="mx-2">
                          <Form.Label htmlFor="basic-url" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{t("configurations.chatbots.view.bot_info.prompt")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip'>
                                  {t("configurations.chatbots.view.bot_info.insert_prompt")}
                                </Tooltip>
                              }
                            >
                              <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                              </svg>
                            </OverlayTrigger>
                          </Form.Label>
                          <InputGroup className="mb-1">
                            <Form.Control className="form-control-Default-text-area"
                              style={{ height: '20vh' }}
                              isInvalid={showPromptErrorFeedback}
                              as="textarea"
                              placeholder={t("configurations.chatbots.view.bot_info.placeholder_prompt")}
                              aria-label={t("configurations.chatbots.view.bot_info.placeholder_prompt")}
                              aria-describedby={t("configurations.chatbots.view.bot_info.placeholder_prompt")}
                              size='lg'
                              type='text'
                              // componentClass="textarea"
                              id="prompt"
                              name="prompt"
                              autoComplete='nome'
                              maxLength={500000}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.prompt}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" id="prompt">
                              {errors.prompt}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Row>
                        <Row className="mx-2">
                          <Form.Label htmlFor="documents" style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461' }}>{t("configurations.chatbots.view.bot_info.documents")}
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip'>
                                  {t("configurations.chatbots.view.bot_info.supported_files")}
                                </Tooltip>
                              }
                            >
                              <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                              </svg>
                            </OverlayTrigger>
                          </Form.Label>
                          <div className='mb-2' style={allStyles.changeEditorDropzone}>
                            <div {...getRootProps({ className: 'dropzone' })}
                              style={allStyles.dragDropzone}>
                              <input {...getInputProps({})} />
                              {showFiles?.length > 0 ?
                                <div name="showFilesName" className="ps-2 py-1 pt-2">
                                  <div style={{ display: 'block' }}>
                                    {showFiles}
                                  </div>
                                </div>
                                :
                                <div style={{ textDecoration: 'underline' }}>
                                  { t("configurations.chatbots.view.bot_info.drop_files") }
                                </div>
                              }
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <Row style={{ height: '36%' }} className="d-flex justify-content-start my-3">
                    <Col style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                      <Row
                        style={allStyles.textFontGlay}
                        className="d-flex justify-content-center mt-4"
                      >
                        <Col xs={3}>
                          <Button
                            size="lg"
                            className="buttonWhiteChatbot"
                            style={{ width: "56%" }}
                            onClick={() => goToListOfBots()}
                          >
                            { t("configurations.chatbots.view.bot_info.back") }
                          </Button>
                        </Col>
                        <Col xs={3} className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            size="lg"
                            className="buttonBlue"
                            style={{ width: "56%" }}
                          >
                            { t("configurations.chatbots.view.bot_info.save") }
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BotInfo;