/*
 * Enumerador responsável por conter os nomes e valores dos canais 
 * SUPORTADOS PELO SISTEMA.
 */

export enum Channels {
    Email = '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a',
    Telefonia = '6977996d-11d1-44cc-a863-2e1d9c24e046',
    WhatsApp = '33be81d8-88ac-4e08-bb4d-cf69fd23267f',
    Facebook = 'aa1cea93-23de-46aa-af14-d6f766acf5c8',
    Instagram = 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2',
    Webchat = '4a7b71bb-6432-4794-ad11-f978b067871d',
    NotAssigned = "N/A"
}

export const ChannelsMultiselectOptions = [
    { label: "E-mail", value: Channels.Email },
    { label: "Telefonia", value: Channels.Telefonia },
    { label: "WhatsApp", value: Channels.WhatsApp},
    { label: "Facebook", value: Channels.Facebook},
    { label: "Instagram", value: Channels.Instagram },
    { label: "Webchat", value: Channels.Webchat },
]

export const ChannelsMultiselectOptionsTicket = [
    { label: "E-mail", value: Channels.Email },
    { label: "Telefonia", value: Channels.Telefonia },
    { label: "WhatsApp", value: Channels.WhatsApp},
    { label: "Facebook", value: Channels.Facebook},
    { label: "Instagram", value: Channels.Instagram }
]
