import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconMessage2, IconRobotFace, IconSettings, IconSpeakerphone } from "@tabler/icons-react";
import { Channels } from "../../../../core/enums/channels";

const ChannelLabel = (title: string, channelId: any, style?: any) => {
    const stroke = style?.color? style.color: "#4A69BD";
    const iconLen = style?.iconLarger ? "24" : "18";
    const iconChannel = () => {

        switch (channelId) {
            case 'voicebot':
                return <IconSpeakerphone stroke={1} size={iconLen} color={stroke} />
            case 'general':
                return <IconSettings stroke={1} size={iconLen} color={stroke} />
            case Channels.WhatsApp:
                return (
                    <svg id="Grupo_11499" data-name="Grupo 11499"
                        xmlns="http://www.w3.org/2000/svg" width={iconLen}
                        height={iconLen} viewBox="0 0 17.117 17.117">
                        <path id="Caminho_9745" data-name="Caminho 9745"
                            d="M0,0H17.117V17.117H0Z" fill="none" />
                        <path id="Caminho_9746" data-name="Caminho 9746"
                            d="M3,15.843l1.177-2.71A6.419,6.419,0,1,1,6.6,15.2L3,15.843"
                            transform="translate(-0.86 -0.865)"
                            fill="none" stroke={stroke}
                            strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="1" />
                        <path id="Caminho_9747" data-name="Caminho 9747"
                            d="M9,9.57a.357.357,0,1,0,.713,0V8.857a.357.357,0,1,0-.713,0V9.57a3.566,3.566,0,0,0,3.566,3.566h.713a.357.357,0,0,0,0-.713h-.713a.357.357,0,0,0,0,.713"
                            transform="translate(-2.581 -2.438)"
                            fill="none" stroke={stroke}
                            strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="1" />
                    </svg>
                );
            case Channels.Telefonia:
                return (
                    <svg id="Grupo_11310" data-name="Grupo 11310"
                        xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen}
                        viewBox="0 0 18.692 18.692">
                        <path id="Caminho_9669" data-name="Caminho 9669"
                            d="M0,0H18.692V18.692H0Z" fill="none" />
                        <path id="Caminho_9670" data-name="Caminho 9670"
                            d="M4.558,4H7.673L9.231,7.894,7.284,9.063a8.567,8.567,0,0,0,3.894,3.894l1.168-1.947,3.894,1.558v3.115a1.558,1.558,0,0,1-1.558,1.558A12.462,12.462,0,0,1,3,5.558,1.558,1.558,0,0,1,4.558,4"
                            transform="translate(-0.663 -0.885)" fill="none"
                            stroke={stroke} strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1" />
                        <line id="Linha_197" data-name="Linha 197" y1="3.894" x2="3.894"
                            transform="translate(11.683 3.115)" fill="none"
                            stroke={stroke} strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1" />
                        <path id="Caminho_9671" data-name="Caminho 9671"
                            d="M16,4h3.115V7.115" transform="translate(-3.538 -0.885)"
                            fill="none" stroke={stroke} strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1" />
                    </svg>
                );
            case Channels.Email:
                return (
                    <svg id="Grupo_11322" data-name="Grupo 11322"
                        xmlns="http://www.w3.org/2000/svg" width={iconLen}
                        height={iconLen} viewBox="0 0 18.69 18.69">
                        <path id="Caminho_9699" data-name="Caminho 9699"
                            d="M0,0H18.69V18.69H0Z" fill="none" />
                        <rect id="Retângulo_18003"
                            data-name="Retângulo 18003" width="13.897"
                            height="10.897" rx="2"
                            transform="translate(2 3.793)" fill="none"
                            stroke={stroke} strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1" />
                        <path id="Caminho_9700" data-name="Caminho 9700"
                            d="M3,7l7,4.7,7-4.7"
                            transform="translate(-1.048 -1.559)"
                            fill="none" stroke={stroke}
                            strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="1" />
                    </svg>
                );
            case Channels.Webchat:
                return (
                    <svg id="Grupo_12897" data-name="Grupo 12897" xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen} viewBox="0 0 17.974 17.974">
                        <path id="Caminho_10311" data-name="Caminho 10311" d="M0,0H17.974V17.974H0Z" fill="none" />
                        <path id="Caminho_10312" data-name="Caminho 10312" d="M18.238,11.238,15.991,8.991H10.749A.749.749,0,0,1,10,8.242V3.749A.749.749,0,0,1,10.749,3h6.74a.749.749,0,0,1,.749.749v7.489" transform="translate(-2.511 -0.753)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                        <path id="Caminho_10313" data-name="Caminho 10313" d="M11.238,13.745v1.5a.749.749,0,0,1-.749.749H5.247L3,18.238V10.749A.749.749,0,0,1,3.749,10h1.5" transform="translate(-0.753 -2.511)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    </svg>
                );

            case Channels.Facebook:
                return (
                    <svg id="Grupo_11496" data-name="Grupo 11496" xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen} viewBox="0 2 17.281 17.281">
                        <path id="Caminho_9748" data-name="Caminho 9748" d="M0,0H17.281V17.281H0Z" fill="none" />
                        <path id="Caminho_9749" data-name="Caminho 9749" d="M7,8.04v2.88H9.16v5.04h2.88V10.92H14.2l.72-2.88H12.04V6.6a.72.72,0,0,1,.72-.72h2.16V3H12.76a3.6,3.6,0,0,0-3.6,3.6V8.04H7" transform="translate(-1.96 -0.84)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    </svg>
                );
            case Channels.Instagram:
                return (
                    <svg id="Grupo_11497" data-name="Grupo 11497" xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen} viewBox="0 1 18.91 18.91">
                        <path id="Caminho_9750" data-name="Caminho 9750" d="M0,0H18.91V18.91H0Z" fill="none" />
                        <rect id="Retângulo_18238" data-name="Retângulo 18238" width="13" height="13" rx="4" transform="translate(3 2.91)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                        <circle id="Elipse_1529" data-name="Elipse 1529" cx="2.5" cy="2.5" r="2.5" transform="translate(7 6.91)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                        <line id="Linha_421" data-name="Linha 421" y2="0.001" transform="translate(13 5.909)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    </svg>
                );
            default:
                break;
        }
        //icones da triagem
        if (channelId?.webchat_key !== null && channelId?.webchat_key?.has_bot === true) {
            return (
                <>
                    <IconRobotFace className="icon" />
                    <IconMessage2 className="icon" />
                </>
            )
        }
        else if (channelId?.webchat_key !== null && channelId?.webchat_key?.has_bot === false) {
            return (
                <IconMessage2 className="icon" />
            )
        }

        else if (channelId?.facebook_key !== null && channelId?.facebook_key?.has_bot === true) {
            return (
                <>
                    <IconRobotFace className="icon" />
                    <IconBrandFacebook className="icon" />
                </>
            )
        }
        else if (channelId?.facebook_key !== null && channelId?.facebook_key?.has_bot === false) {
            return (
                <IconBrandFacebook className="icon" />
            )
        }
        else if (channelId?.instagram_key !== null && channelId?.instagram_key?.has_bot === true) {
            return (
                <>
                    <IconRobotFace className="icon" />
                    <IconBrandInstagram className="icon" />
                </>
            )
        }
        else if (channelId?.instagram_key !== null && channelId?.instagram_key?.has_bot === false) {
            return (
                <IconBrandInstagram className="icon" />
            )
        }

        else if (channelId?.whatsapp_key !== null && channelId?.whatsapp_key?.has_bot === true) {
            return (
                <>
                    <IconRobotFace className="icon" />
                    <IconBrandWhatsapp className="icon" />
                </>
            )
        }
        else if (channelId?.whatsapp_key !== null && channelId?.whatsapp_key?.has_bot === false) {
            return (
                <IconBrandWhatsapp className="icon" />
            )
        }

    }

    return (
        <div className='d-flex' style={{ height: '100%', alignItems: "center" }}>
            <span className='pe-2 align-items-center'>
                { iconChannel() }
            </span>
            <span style={ style?.fontWeight? { fontWeight: style.fontWeight }: { } }>
                { title }
            </span>
        </div>
    );
}

export default ChannelLabel;