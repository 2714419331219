import { Socket } from "socket.io-client";
import BackendConstants from "../core/constants/backend-constants";
import { SocketServiceChatbot } from "../core/context/socket-context-chatbot";
import { Channels } from "../core/enums/channels";
import Utils from "../core/shared/utils";
import { CreateMessageRequest, Message } from "../domain/entities/messages";
import { TicketMessagesRequest } from "../domain/entities/request-messages";

export default class MessageSocketClientChatbot {
    socket!: Socket;
    oldMessages?: Message[];
    onListMessages: (messages: Message[]) => void;
    onReciveMessage: (message: Message) => void;
    onRequestMoreMessages: (messages: Message[]) => void;
    onBotcxpressReactivate: (messages: any) => void;
    onError: (error: any) => void;
    onConnect: () => void;
    private hasListeners = false;
    private ticket_id = null

    constructor(
        socket: SocketServiceChatbot,
        ticket_id?: string,
        oldMessages?: Message[],
        onReciveMessage?: (message: Message) => void,
        onListMessages?: (messages: Message[]) => void,
        onRequestMoreMessages?: (messages: Message[]) => void,
        onBotcxpressReactivate?: (messages: any) => void,
        onError?: (error: any) => void,
        onConnect?: () => void,
        company_id?:string
    ) {
        this.socket = socket.getInstance();
        this.ticket_id = ticket_id;
        this.oldMessages = oldMessages;
        this.onListMessages = onListMessages;
        this.onReciveMessage = onReciveMessage;
        this.onRequestMoreMessages = onRequestMoreMessages;
        this.onBotcxpressReactivate = onBotcxpressReactivate;
        this.onError = onError;
        this.onConnect = onConnect;
        this.connectIframe(ticket_id,company_id);


    }

    public createMessage(message: CreateMessageRequest, attachmentFiles?: File[]) {
        // Definindo o valor do canal para undefined, caso não haja um canal atribuído.
        message.channel_id = message.channel_id !== Channels.NotAssigned
            ? message.channel_id
            : undefined;

        // Removendo atributos vazios (undefined, null e "")
        let body = Utils.removeEmptyProperties(message);

        this?.socket?.emit("create-message", body);
    }

    public requestMoreMessages(requestBody: TicketMessagesRequest) {
        this?.socket?.emit("request-more-messages", requestBody);
    }

    private joinIframeSession(id:string, company_id:string){
        const requestBody = {
            id: id,
            company_id: company_id,
            page: 1,
            limit: BackendConstants.messagesLimit
        }
        this?.socket?.emit("start-iframe-chat", requestBody);
    }

    public leaveRoom(ticket_id: string) {
        this?.socket?.emit("leave-ticket-chat", ticket_id);
    }

    private connectIframe(id:string, company_id:string){
        console.log('connectIframe')
        if (this.socket) {
            console.log('socket instance ok')
            if (this.socket.connected && !this.hasListeners) {
                console.log(`connected ${this.socket.connected} listeners ${this.hasListeners}`)
                this.joinIframeSession(id, company_id)
                this.onConnect();

                this.socket.on("connect_error", () => {
                });

                this.socket.on("message-saved", (newMessage: Message) => {
                    console.log('message-saved')
                    this.onReciveMessage(newMessage);
                });

                this.socket.on("list-messages", (messages: Message[]) => {
                    this.onListMessages(messages);
                });

                this.socket.on("send-more-messages", (messages: Message[]) => {
                    this.onRequestMoreMessages(messages);
                });
                
                this.socket.on("botcxpress-reactivate", (response: any) => {
                    this.onBotcxpressReactivate(response);
                });

                this.handleErrorEvents(id);

                this.hasListeners = true;
            }
        }
    }

    private handleErrorEvents(ticket_id: string) {
        this.socket.on("error-on-create-message", (error: any) => {
            // TODO: Mostrar feedback visual quando houver problemas com o envio da mensagem.~
            this.onError(error);
        });
    }

    public disconect() {
        this.socket.removeListener("connect_error")
        this.socket.removeListener("message-saved")
        this.socket.removeListener("list-messages")
        this.socket.removeListener("send-more-messages")
        this.socket.removeListener("error-on-create-message")
        if (this.ticket_id) {
            this.leaveRoom(this.ticket_id)
        }
        this.hasListeners = false;
    }

    public isConnected() {
        return this.socket.connected;
    }
}