type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

const styles = StyleSheet.create({
  backgroundImage: {
    backgroundColor: '#0C2461',
  },
  backgroundLogin: {
    backgroundColor: '#F6F7F8',
    height: '90.5%'
  },
  backgroundLogin2: {
    backgroundColor: '#FFFFFF',
    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#707070',
    opacity: '1',
    height: '9.5%'
  },
  textFontBlue: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.5em + 0.8vw) Montserrat',
    letterSpacing: '0px',
    color: '#0C2461',
    opacity: '1',

  },
  textFontGlay: {
    textAlign: 'left',
    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#707070',
    opacity: '1',

  },
  LinkGlay: {
    textDecoration: 'none',
    color: '#707070',
    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
  },
  LinkGlay2: {
    textDecoration: 'none',
    color: '#707070',
    font: 'normal normal normal calc(0.2em + 0.6vw) Montserrat',
  },

  textBlack: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
  },

  companyName: {
    textAlign: 'left',
    font: 'bold calc(0.5em + 0.55vw) Montserrat',
    letterSpacing: '0px',
    color: '#4a69bd',
    opacity: 1,
    border: '1px solid #ffffff',
    width: 'fit-content',
    padding: '5px 15px',
    borderRadius: '20px',
    background: '#fff',
    boxShadow: '0px 1px 10px -7px #000',
    cursor: 'default',
    userSelect: 'none'
  },

  linkChangeMethod: {
    paddingLeft: 0,
    font: 'normal normal normal 14px Montserrat',
    color: '#0b5ed7',
    cursor: 'pointer',
    width: 'fit-content'
  },

  bold: {
    fontWeight: 600
  },

  imgStyle: {
    width: 166,
    height: 166,
    marginTop: '1vh',
    marginBottom: '1vh',
    marginLeft: '0.8vh'
  },
  spanSecret: {
    marginLeft: '0.8vh',
    fontSize: '13px'
  },
  divStep: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2vh',
    color: '#0C2461',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '1'
  },
  spanStep: {
    fontWeight: '500',
  },
});

export default styles;






