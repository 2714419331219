import './styles.scss';
import { FC } from "react";
import { KeyboardInterface } from "./indexModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconArrowRampRight, IconArrowUpRight, IconBackspaceFilled, IconDeviceLandlinePhone, IconPhoneIncoming, IconPhoneOutgoing, IconX } from "@tabler/icons-react";

const Keyboard: FC<KeyboardInterface> = ({
  extension,
  preferred_number,
  callcenterContext,
  changeDisplay,
  omitCharTerms,
  clearDisplay,
  showDisplay,
  formatDuration,
  showTransfer,
  setShowTransfer,
  extensions,
  toTransfer,
  setToTransfer,
  handleTransfer,
  t,
}) => {

  return (
    <div className="scene-keyboard">
      { 
        !showDisplay() &&
        <div className="extension-info">
          <OverlayTrigger placement="left" overlay={<Tooltip>{t('phone_callcenter.main_screen.keyboard.your_terminal')}</Tooltip>}>
            <IconDeviceLandlinePhone color='#fff' size={18} stroke={1}/>
          </OverlayTrigger>
          <div className='extension-number'>
            <span className="extension">{extension}</span>
            { preferred_number && <span>/ {preferred_number}</span>}
          </div>
        </div>
      }
      {/* <div className="display-call-info2" style={{ display: 'flex', flexDirection: 'column'}}>
        <span>durationContext: {callcenterContext.callStatusDuration}</span>
        <span>is_active: {callcenterContext.callStatusIsActive ? 'true' : 'false'}</span>
        <span>event: {callcenterContext.eventType}</span>
        <span>call_status: {callcenterContext.callStatusEvent}</span>
        <span>direction: {callcenterContext.callStatusDirection}</span>
      </div> */}
      {
        showDisplay() &&
        <div className="display-call-info">
          <div className="display-left">
            <div className="number">
              { callcenterContext.callStatusDirection === 'outgoing' && <IconPhoneOutgoing size={18} stroke={1.5} color="#fff" />}
              { callcenterContext.callStatusDirection === 'incoming' && <IconPhoneIncoming size={18} stroke={1.5} color="#fff" />}
              <span className="phoneNumber">
                { callcenterContext.callStatusDirection === 'outgoing' ? callcenterContext.callInfoDestiny : callcenterContext.callStatusIncomingNumber }
              </span>
            </div>
            { callcenterContext.callStatusIsActive && <span className="duration">{formatDuration(callcenterContext.callStatusDuration)}</span> }
            { !callcenterContext.callStatusIsActive && callcenterContext.callStatusEvent === 'trying' && 
              <span className="duration">{t('phone_callcenter.main_screen.keyboard.trying_to_call')}</span>
            }
            { !callcenterContext.callStatusIsActive && callcenterContext.callStatusEvent === 'calling' && 
              <span className="duration">{t('phone_callcenter.main_screen.keyboard.calling')}</span>
            }
            { !callcenterContext.callStatusIsActive && callcenterContext.callStatusEvent === 'ringing' && 
              <span className="duration">{t('phone_callcenter.main_screen.keyboard.call_received')}</span>
            }
          </div>
          <div className={callcenterContext.ticketInfo ? "display-right two-options" : "display-right"}>
            {
              callcenterContext.ticketInfo &&
              <span className='display-action'>{t('phone_callcenter.main_screen.keyboard.view_ticket')}</span>
            }
            { callcenterContext.callStatusIsActive && 
              <span 
                className={ showTransfer ? 'display-action active' : 'display-action'} 
                onClick={() => setShowTransfer(!showTransfer)}>
                {t('phone_callcenter.main_screen.keyboard.transfer')}
              </span>
            }
          </div>
        </div>
      }
      {
        !showTransfer &&
        <div className="view-keyboard">
          <div className="display">
            <input type="text" placeholder={t('phone_callcenter.main_screen.keyboard.dial_the_number')}
              value={callcenterContext.callInfoDisplay}
              onKeyDown={(e: any) => omitCharTerms(e)}
              onChange={() => {}}
              autoFocus={true}
            />
            <OverlayTrigger placement="left" overlay={<Tooltip>{t('phone_callcenter.main_screen.keyboard.delete')}</Tooltip>}>
              <span className="clearDisplay">
                <IconBackspaceFilled size={28} onClick={clearDisplay}/>
              </span>
            </OverlayTrigger>
          </div>
          <div className="board">
            <div onClick={(e: any) => changeDisplay(e)}>1</div>
            <div onClick={(e: any) => changeDisplay(e)}>2</div>
            <div onClick={(e: any) => changeDisplay(e)}>3</div>
            <div onClick={(e: any) => changeDisplay(e)}>4</div>
            <div onClick={(e: any) => changeDisplay(e)}>5</div>
            <div onClick={(e: any) => changeDisplay(e)}>6</div>
            <div onClick={(e: any) => changeDisplay(e)}>7</div>
            <div onClick={(e: any) => changeDisplay(e)}>8</div>
            <div onClick={(e: any) => changeDisplay(e)}>9</div>
            <div onClick={(e: any) => changeDisplay(e)}>*</div>
            <div onClick={(e: any) => changeDisplay(e)}>0</div>
            <div onClick={(e: any) => changeDisplay(e)}>#</div>
          </div>
        </div>
      }
      {
        showTransfer &&
        <div className="view-transfer">
          <div className="subscene-header">
            <span className='subscene-title'>{t('phone_callcenter.main_screen.keyboard.transfer_call')}</span>
            <IconX size={18} color='#bababa' onClick={() => setShowTransfer(false)} />
          </div>
          <div className="subscene-content">
            <select className="form-control" onChange={e => setToTransfer(e.target.value)} defaultValue="">
              <option value="">{t('phone_callcenter.main_screen.keyboard.select_terminal')}</option>
              {extensions.map(item => {
                return (
                  <option key={item.id} value={item.extension}>{item.extension} - {item.employee_name || ''}</option>
                );
              })}
            </select>
            <div className="transfer-actions">
              <span className={toTransfer ? 'transfer-option' : 'transfer-option disabled'} onClick={() => handleTransfer('direct')}>
                <IconArrowUpRight color='#fff' size={18} /> {t('phone_callcenter.main_screen.keyboard.direct_transfer')}
              </span>
              <div className="divider">
                <span className="line"></span>
                <span className="line-text">{t('phone_callcenter.main_screen.keyboard.or')}</span>
                <span className="line"></span>
              </div>
              <span className={toTransfer ? 'transfer-option' : 'transfer-option disabled'} onClick={() => handleTransfer('assisted')}>
                <IconArrowRampRight color='#fff' size={18} /> {t('phone_callcenter.main_screen.keyboard.assisted_transfer')}
              </span>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Keyboard;