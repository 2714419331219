type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

const styles = StyleSheet.create({

  textFontGlay: {
    textAlign: 'left',
    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#707070',
    opacity: '1',
  },

  textBlack: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
  },
  inputGray: {
    height: '1vh',
  }
});

export default styles;