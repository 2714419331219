import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

export interface EmployeeInterface {
    email: string;
    name: string;
    id: string;
    profile_id: string;
    user_id: string;
    status_employee_id: string;
}

const useStatusEmployee = () => {

    const socket = useContext(SocketContext)

    const [statusEmployee, setStatusEmployee] = useState<EmployeeInterface>()

    useEffect(() => {
        socket.socket?.on("status-employee", (data) => setStatusEmployee(data))

        return () => {
            socket.socket?.off("status-employee", setStatusEmployee)
        }
    }, [socket.socket?.active])

    return { statusEmployee }
}

export default useStatusEmployee