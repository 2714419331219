import { useState } from 'react'
import FormConfigInstagram from '.';

const FormConfigInstagramController = (props) => {

    const [showModal, setShowModal] = useState(false)

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    return <FormConfigInstagram
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        data={props.data}
        updateListAfterDelete={props.updateListAfterDelete}
        cnpj={props.cnpj}
        getInstagramById={props.getInstagramById}
        isEditAllowed={props.isEditAllowed}
    />
}

export default FormConfigInstagramController