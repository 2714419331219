import { useEffect, useState } from "react";
import HomeSecurityConfig from ".";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import UserService from "../../../../services/user-service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyAuthentications, getIdCompany, setCompanyAuthentications, } from "../../../../store/company";
import { getProfileId } from '../../../../store/user';
import { getHasAuthAppEmployee, getIdEmployee, setHasAuthAppEmployee } from '../../../../store/employee';
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../../store/internal";
import { useTranslation } from "react-i18next";
import constsApi from "../../../../services/constsApi";
import { AuthenticationTypes } from "../../../../core/enums/authentication-types";

const AppRequesterConst = new AppRequesterController();

const HomeSecurityConfigController = (props: any) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [openModalPhone, setOpenModalPhone] = useState(undefined);
  const [phoneNumber, /*setPhoneNumber*/] = useState("");
  const [infoAuth, setInfoAuth] = useState([]);

  const [headers, setHeaders] = useState(undefined);

  const controller = new AbortController();

  const companyId = useSelector(getIdCompany);
  const employeeId = useSelector(getIdEmployee);
  const companyAuth = useSelector(getCompanyAuthentications);
  const employeeHasAuthApp = useSelector(getHasAuthAppEmployee);
  const userProfileId = useSelector(getProfileId);

  const [companyHasAuthApp, setCompanyHasAuthApp] = useState(false);

  const isAdmin = () => userProfileId === constsApi.profile[0].id;
  const isAgent = () => userProfileId === constsApi.profile[1].id;
  const isOwner = () => userProfileId === constsApi.profile[2].id;

  useEffect(() => {
    setCompanyHasAuthApp(false);
    companyAuth?.forEach(item => {
      if (item.name === AuthenticationTypes.App) {
        setCompanyHasAuthApp(true);
      }
    });
  }, [companyAuth]);

  useEffect(() => {
    if (UserService.token) {
      setHeaders(UserService.getHeaders());
      const fetch = async () => {
        await getCurrentAuthentications(UserService.getHeaders());
      }
      fetch();
    }
  }, [UserService.token]);

  const getAvailableAuthentications = async () => {
    let data = undefined;
    await AppRequesterConst.Get(
      '/authentication/authentication-types', { headers, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        data = resp_data.data?.authentications || [];
      },
      (error: Object) => { console.log(error) }, navigate, dispatch, setIsLoading, { }
    );
    return data;
  }

  const getCurrentAuthentications = async (currentHeader?: any) => {
    let data = [];
    await AppRequesterConst.Get(
      '/company', { headers: headers || currentHeader, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        setInfoAuth(resp_data.data?.companies[0].enable_employee_master)
        const company_temp = resp_data.data?.companies[0];
        data = company_temp.enable_2fa ? company_temp?.authentications : [];
        dispatch(setCompanyAuthentications(data));
      },
      (error: Object) => { console.log(error) }, navigate, dispatch, setIsLoading, { }
    );
    return data;
  }

  const setAuthentication = async (value: boolean, ids: string[]) => {
    const body = {
      id: companyId,
      enable_2fa: value,
      authentications: ids
    }
    let data = [];
    await AppRequesterConst.Put(
      '/company', body, { headers, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        dispatch(setShowAlertFeedback({ message: t('configurations.security.change_success'), visibility: true, signalIcon: true }));
        const company_temp = resp_data.data.company;
        data = company_temp?.enable_2fa ? company_temp?.authentications : [];
        dispatch(setCompanyAuthentications(data));
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ 
          message: t('configurations.security.change_failed', { error_code: error?.response?.status || 500}), 
          visibility: true, signalIcon: false })
        );
        data = null;
      }, navigate, dispatch, setIsLoading,
    );
    return data;
  }

  const changeMasterAccess = async (value: boolean) => {
    const body = {
      id: companyId,
      enable_employee_master: value
    }

    await AppRequesterConst.Put(
      '/company', body, { headers, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        dispatch(setShowAlertFeedback({ message: t('configurations.security.change_success_master_access'), visibility: true, signalIcon: true }));
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({
          message: t('configurations.security.change_failed', { error_code: error?.response?.status || 500 }),
          visibility: true, signalIcon: false
        }))

      }, navigate, dispatch, setIsLoading
    )
  }
  
  const generateAppCode = async () => {
    let data = {};
    try {
      await AppRequesterConst.Get(
        'two-factor-authentication/generate-code-aplication', { headers, signal: controller.signal },
        (response: Object) => {},
        (resp_data: any) => {
          data = resp_data.data.token;
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ 
            message: t('configurations.security.tab_app.alert_error_generate_code'), 
            visibility: true, signalIcon: false })
          );
          data = null;
        }, navigate, dispatch, setIsLoading, {}
      );
      return data;
    } catch (error) {
      dispatch(setShowAlertFeedback({ 
        message: t('configurations.security.tab_app.alert_error_generate_code'), 
        visibility: true, signalIcon: false })
      );
      return data;
    }
  }

  const verifyAppCode = async (code: string) => {
    const temp_header = Object.assign({}, headers);
    const request_body = {
      code: String(code),
      token: temp_header['Authorization'].split(' ')[1],
      autenticationType: '810de59e-729d-48de-bb82-0dd4b29817ba'
    };

    const basic_headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN_BASIC_AUTH,
    };

    await AppRequesterConst.Post(
      'two-factor-authentication/verify-code', request_body, { headers: basic_headers, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        dispatch(setShowAlertFeedback({ message: t('configurations.security.change_success'), visibility: true, signalIcon: true }));
        dispatch(setHasAuthAppEmployee(true));
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ 
          message: t('configurations.security.tab_app.alert_error_verify_code'), 
          visibility: true, signalIcon: false })
        );
      }, navigate, dispatch, setIsLoading
    );

    return;
  }

  const revokeApp2FA = async () => {
    await AppRequesterConst.Post(
      `agent/invalid-app-authentication-2fa/${employeeId}`, {}, { headers, signal: controller.signal },
      (response: Object) => { },
      (resp_data: any) => {
        dispatch(setShowAlertFeedback({ message: t('configurations.security.change_success'), visibility: true, signalIcon: true }));
        dispatch(setHasAuthAppEmployee(false));
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ 
          message: t('configurations.security.tab_app.alert_remove_error'), 
          visibility: true, signalIcon: false })
        );
      }, navigate, dispatch, setIsLoading
    );
  }

  const openRemoveConfirmation = async () => {
    dispatch(setShowConfirmationModal({
      visibility: true,
      text: { 
        title: t('configurations.security.tab_app.modal_confirm_title'), 
        body:  t('configurations.security.tab_app.modal_confirm_body'), 
        buttonConfirmationText: t('configurations.security.tab_app.modal_confirm_btn_yes'), 
        buttonReturnText: t('configurations.security.tab_app.modal_confirm_btn_no'), 
        closeButton: true,
        warning: t('configurations.security.tab_app.modal_confirm_warning')
      },
      functionConfirmation: revokeApp2FA
    }));
    return;
  }

  return headers ? 
    <HomeSecurityConfig
      isLoading={isLoading}
      phoneNumber={phoneNumber}
      openModalPhone={openModalPhone}
      setOpenModalPhone={setOpenModalPhone}
      getAvailableAuthentications={getAvailableAuthentications}
      setAuthentication={setAuthentication}
      changeMasterAccess={changeMasterAccess}
      infoAuth={infoAuth}
      companyAuth={companyAuth}
      employeeHasAuthApp={employeeHasAuthApp}
      generateAppCode={generateAppCode}
      verifyAppCode={verifyAppCode}
      openRemoveConfirmation={openRemoveConfirmation}
      userProfileId={userProfileId}
      getCurrentAuthentications={getCurrentAuthentications}
      isAgent={isAgent}
      companyHasAuthApp={companyHasAuthApp}
      t={t}
    />
    : <></>
};

export default HomeSecurityConfigController;