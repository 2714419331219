import { Button, Col, Form, FormControl, FormLabel, InputGroup, Modal, Row } from "react-bootstrap";
import styles from "../styles";
import '../styles.scss';
import { Formik } from "formik";

const IntegrateAccount = ({
  show,
  onHide,
  accountsVx,
  selectEmail,
  setSelectEmail,
  showFeedbackEmail,
  setShowFeedbackEmail,
  integrateAccountWithAgent
}) => {
 
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Integrar agente do CX a um atendente no VX</Modal.Title>      
      </Modal.Header>
      <Modal.Body className='mx-3 mb-2'>
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.email) {
            errors['email'] = 'Selecione uma conta'
            setShowFeedbackEmail(true)
          } else {
            setShowFeedbackEmail(false)
            let selected = accountsVx.find(item => item.email === values.email);
            setSelectEmail(selected)
          }

          return errors

        }}
          onSubmit={() => {
            integrateAccountWithAgent(selectEmail);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} noValidate validated={false}>
              <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                <Col>
                  <FormLabel>
                    E-mail da conta no VX
                  </FormLabel>
                  <InputGroup>
                  <Form.Control
                    required 
                    className="custom-select"
                    as="select" 
                    type="select"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={showFeedbackEmail}
                    placeholder="Selecione o atendente que será integrado"
                    >
                    <option value="" disabled selected style={{color: "#ccc"}} >Selecione o atendente que será integrado</option>
                    {accountsVx.map(item => (
                      <option value={item.email}>{ item.email }</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid" id="feedbackdomain">
                    {errors['email']}
                  </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mt-3">
                <Col xs={6} className="d-flex justify-content-start">
                  <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={onHide} >Cancelar</Button>
                </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                  <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                </Col>
              </Row>
          </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default IntegrateAccount;