import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
    setShowConfirmationModal,
    setShowAlertFeedback,
    setShowViewDomainModal
} from '../../../../../store/internal'


import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { TokenInterface, TokenInterfaceControlPanel } from '../../../../../services/requestsInterfacesModel';
import UserService from '../../../../../services/user-service';
import ListConfigDomain from '.';
import { IListConfigDomainController } from './indexModel';
import { getToken } from '../../../../../store/control_panel';
import { AppRequesterControlPanelController } from '../../../../../services/appRequesterControlPanel/appRequesterControlPanelController';

const ListConfigDomainController: FC<IListConfigDomainController> = (props) => {
    const dispatch = useDispatch();
    const appRequesterConst = new AppRequesterControlPanelController();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [domainId, setDomainId] = useState(null);
    const [domainName, setDomainName] = useState('name is here');

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }, 
    }

    const executeDeleteDomain = async (id: string) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + values.token.value,
        };

        await appRequesterConst.Delete(
            `/channel-email/domain/${id}`, { headers },
            (response: Object) => {return response;},
            (data) => {
                /* if ((data.status === 200 || data.status === 204) && data.data?.companyEmailDomain?.length > 0) {
                    setDomains(data.data.companyEmailDomain);
                } else {
                    setDomains([]);
                } */
                dispatch(setShowAlertFeedback({ message: "Domínio excluído com sucesso.", visibility: true, signalIcon: true }));
                props.callbackUpdateList();
            },
            (error: { response: { status: number; data: { message: string; }; }; }) => {
                dispatch(setShowAlertFeedback({ message: "Não foi possível excluir esse domínio.", visibility: true, signalIcon: false }));
                /* if (error.response.status === 400) {
                    // dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                } */
            }, navigate, dispatch, () => {}
        ).finally(() => {});
    }

    const deleteDomainConfirm = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Excluir domínio', "body": `Tem certeza que deseja excluir o domínio ${domainName}? Essa operação não poderá ser desfeita.`, "buttonReturnText": "Cancelar", "replaceText": domainName }, functionConfirmation: () => executeDeleteDomain(domainId) }));
    }

    const handleOpenModalEdit = () => {
        dispatch(setShowViewDomainModal(true));
    };

    const handleCloseModalEdit = () => {
        dispatch(setShowViewDomainModal(false));
    }

    const openPopover = (id, domain) => {
        setDomainId(id);
        setDomainName(domain);
    }

    return <ListConfigDomain
        isLoading={isLoading}
        handleCloseModalEdit={handleCloseModalEdit}
        openPopover={openPopover}
        deleteDomainConfirm={deleteDomainConfirm}
        handleOpenModalEdit={handleOpenModalEdit}
        domainList={props.domainList}
        domainId={domainId} 
        callbackUpdateList={props.callbackUpdateList}
        isEditAllowed={props.isEditAllowed}
    />
}

export default ListConfigDomainController;