import React from 'react';
import { useSelector } from 'react-redux'

import { Container, Row, Col, Tooltip, Button, OverlayTrigger, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    getShowAlertFeedback,
    getShowRegisterCustomersModal,
    getShowCallNotificationBar,
    getShowAcquirePlatformComponent,
    getShowModalConsumersSimilarData,
    setShowEditConsumersModal,
    setShowModalConsumersSimilarData,
} from '../../../store/internal'

import Loading from '../../../components/loading/index'
import ListOfConsumersController from './listOfConsumers/indexController';

import ModalRegisterConsumersController from '../components/modalRegisterConsumers/indexController';

import CallNotificationBar from '../components/callNotificationBar';
import ButtonSearchCleanInput from '../components/buttonSearchCleanInputComponent';
import AcquirePlatformNotificationBar from '../components/acquirePlatformNotificationBar';
import ButtonListNotificationController from '../../../components/buttonListNotification/indexController';
import ModalConsumersSimilarDataController from './unificationConsumers/modalConsumersSimilarData/indexController';
import ButtonStatusAgent from '../components/buttonStatusAgent';
import FilterOptionComponentController from '../components/filterOptionComponent/indexController';

const HomeConsumers = ({
    t,
    values,
    isLoading,
    phoneNumber,
    openModalPhone,
    setOpenModalPhone,
    currentConsumers,
    currentHasMoreInformation,
    setCurrentFilterSearch,
    currentFilterSearch,
    setCurrentConsumerForSimilarData,
    setSimilarConsumers,
    similarConsumers,
    currentConsumerForSimilarData,
    callbackModalCustomers,
    callbackOrderByConsumers,
    search,
    showCreateConsumer,
    updateListAfterDelete,
    updateListfunction,
    setCurrentConsumerFunction,
    currentState,
    setCurrentState,
    cleanList,
}) => {

    return (
        <>
            <Container fluid className="nopadding">
                <Loading visibility={isLoading}></Loading>
                <ModalRegisterConsumersController token={values.token.value} visibility={useSelector(getShowRegisterCustomersModal)} callback={callbackModalCustomers} setCurrentConsumer={setCurrentConsumerForSimilarData} setSimilarConsumers={setSimilarConsumers} />
                <ModalConsumersSimilarDataController originRedirectPage='consumer_page' visibility={useSelector(getShowModalConsumersSimilarData)} similarConsumers={similarConsumers} currentConsumer={currentConsumerForSimilarData} />
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.Consumers}></SidebarController> */}
                    <div className="content pt-0 ps-0" >
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        <Row className="nopadding mt-3 ps-3">
                            <Col md={7} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_consumers.general.welcome_main')}</div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('home_consumers.general.welcome_sub')}</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ButtonSearchCleanInput inputPlaceholder={t('home_consumers.general.input_placeholder')} filteringTickets={(e, auxObj, action) => search(e, auxObj, action)} setCurrentFilterSearch={setCurrentFilterSearch} currentFilterSearch={currentFilterSearch} />
                                <ButtonListNotificationController/>
                                <ButtonStatusAgent/>
                            </Col>
                        </Row>
                        <Row className="mt-3 ps-3" id="buttonCreateConsumer" style={{ marginTop: '5vh', padding: 0, margin: '1vw 0vw 0px 0vw' }}>
                            <Col md={3} style={{ marginLeft: '1vw' }}>
                                <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                                    {t('home_consumers.general.page_title')}

                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                {t('home_consumers.general.create_consumer')}
                                            </Tooltip>
                                        }
                                    >

                                        <Button className="mx-3 nopadding buttonTicketsSec" onClick={showCreateConsumer}>
                                            <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </Button>

                                    </OverlayTrigger>

                                </p>
                            </Col>
                        </Row>
                        {
                            <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                                <ListGroup variant="flush" >
                                    <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                                        <FilterOptionComponentController
                                            optionNames={["ConsumerTags", "AtribuidoConsumidores"]}
                                            currentState={currentState}
                                            setCurrentState={setCurrentState}
                                            values_user={values.user}
                                            filteringTickets={(e, auxObj, action) => search(e, auxObj, action)}
                                            cleanList={cleanList}
                                            values_token_value={values.token.value}
                                            yoursTickets={false}
                                            screen={'home_consumers'}
                                        />
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        }
                        <ListOfConsumersController datas={currentConsumers} callbackModalCustomers={callbackModalCustomers} updateListAfterDelete={updateListAfterDelete} updateListfunction={updateListfunction} HasMoreInformation={currentHasMoreInformation} showCreateConsumer={showCreateConsumer} setCurrentConsumerFunction={(value) => setCurrentConsumerFunction(value)} callbackOrderByConsumers={(column, order) => callbackOrderByConsumers(column, order)} />
                    </div>
                </div>
            </Container >
        </>
    );
}

export default HomeConsumers;
