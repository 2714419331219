import React, { FC, useEffect, useState } from 'react';
import { Col, Row, InputGroup, FormControl, Button } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import { ButtonSearchCleanInputInterface } from './indexModel';
import { ButtonSearchCleanInputController } from './indexController';
import { useTranslation } from 'react-i18next';


const ButtonSearchCleanInput: FC<ButtonSearchCleanInputInterface> = (props) => {
    // const navigate = useNavigate();
    
    const [searchButtonClickedWithContent, setSearchButtonClickedWithContent] = useState(false);
    const [currentEventSave, setCurrentEventSave] = useState(null as Object || null);
    const [showCleanButton, setShowCleanButton] = useState(false);

    const {t} = useTranslation()

    const ButtonSearchCleanInputControllerConst = new ButtonSearchCleanInputController(props, setCurrentEventSave, setShowCleanButton, setSearchButtonClickedWithContent);

    useEffect(() => {
        if (props.isClosingCreateTicket === true && currentEventSave !== null) {
            props.setIsClosingCreateTicket(false);
            setCurrentEventSave((state: any) => {
                if (searchButtonClickedWithContent && props.currentFilterSearch && props.currentFilterSearch.length > 0) {
                    ButtonSearchCleanInputControllerConst.cleanSearchField(state);
                } else {
                    ButtonSearchCleanInputControllerConst.clickSearchButton(state);
                }
                return null;
            });
        }        

        if (props.setDefaultSearching) {
            props.setDefaultSearching((state: any) => {
                if (state) {
                    setShowCleanButton(true);
                }

                return state;
            });
        }
    }, [props.isClosingCreateTicket, props.defaultSearching])

    /** changing placholder implies change search mode */
    useEffect(() => {
        if (props.inputPlaceholder) {
            props.setCurrentFilterSearch("");
            setSearchButtonClickedWithContent(false);
            setCurrentEventSave(null);
        }
    }, [props.inputPlaceholder]);

    return (
        <Row className="d-flex nopadding">
            {props.desactiveSearchButton === true ?
                //para pesquisa de respostas frequentes
                <Col className="" style={{ width: '16vw', paddingRight: '0' }}>
                    <InputGroup className="mb-1">
                        <FormControl
                            className="form-control-Sec1"
                            id="SearchInput"
                            placeholder={props.inputPlaceholder}
                            aria-label={props.inputPlaceholder}
                            aria-describedby={props.inputPlaceholder}
                            type="text"
                            name="searchInputName"
                            onChange={(e) => ButtonSearchCleanInputControllerConst.onChangeFrequent(e)}
                            value={props.currentFilterSearch && props.currentFilterSearch.length < 200 ? props.currentFilterSearch : ""}
                            onKeyDown={(e) => ButtonSearchCleanInputControllerConst.onKeyDownFrequent(e)}
                        />
                        <div style={{ position: 'absolute', zIndex: "5" }} className="align-self-center mx-2">
                            <svg id="search-24px" xmlns="http://www.w3.org/2000/svg" width="26.304" height="26.304" viewBox="0 0 26.304 26.304">
                                <path id="Caminho_66" data-name="Caminho 66" d="M0,0H26.3V26.3H0Z" fill="none" />
                                <path id="Caminho_67" data-name="Caminho 67" d="M16.7,15.056h-.866l-.307-.3a7.144,7.144,0,1,0-.767.767l.3.307V16.7l4.658,4.658a1.155,1.155,0,0,0,1.633-1.633Zm-6.576,0a4.932,4.932,0,1,1,4.932-4.932A4.925,4.925,0,0,1,10.124,15.056Z" transform="translate(0.288 0.288)" fill="#707070" />
                            </svg>
                        </div>
                    </InputGroup>
                </Col>
                :
                //para as pesquisas genéricas do site
                <Col md={5} sm={5} className="" style={{ width: '16vw', paddingRight: '0' }}>
                    <InputGroup className="mb-1">
                        <FormControl
                            className="form-control-Sec1"
                            id="SearchInput"
                            placeholder={props.inputPlaceholder}
                            aria-label={props.inputPlaceholder}
                            aria-describedby={props.inputPlaceholder}
                            type="text"
                            name="searchInputName"
                            onChange={(e) => ButtonSearchCleanInputControllerConst.onChangeGeneric(e)}
                            value={props.currentFilterSearch && props.currentFilterSearch.length < 200 ? props.currentFilterSearch : ""}
                            onKeyDown={(e) => ButtonSearchCleanInputControllerConst.onKeyDownGeneric(e)}
                        />
                        <div style={{ position: 'absolute', zIndex: "5" }} className="align-self-center mx-2">
                            <svg id="search-24px" xmlns="http://www.w3.org/2000/svg" width="26.304" height="26.304" viewBox="0 0 26.304 26.304">
                                <path id="Caminho_66" data-name="Caminho 66" d="M0,0H26.3V26.3H0Z" fill="none" />
                                <path id="Caminho_67" data-name="Caminho 67" d="M16.7,15.056h-.866l-.307-.3a7.144,7.144,0,1,0-.767.767l.3.307V16.7l4.658,4.658a1.155,1.155,0,0,0,1.633-1.633Zm-6.576,0a4.932,4.932,0,1,1,4.932-4.932A4.925,4.925,0,0,1,10.124,15.056Z" transform="translate(0.288 0.288)" fill="#707070" />
                            </svg>
                        </div>
                    </InputGroup>
                </Col>
            }
            {props.desactiveSearchButton === true ?
                <></>
                :
                <Col className="mx-2 px-0" style={{ width: '4.5vw' }}>
                    {(searchButtonClickedWithContent && props.currentFilterSearch && props.currentFilterSearch.length > 0) || (showCleanButton)? 
                        <Button id="searchButton" name="cleanSearchButtonName"
                            onClick={(e) => ButtonSearchCleanInputControllerConst.cleanSearchField(e)} 
                            className="buttonBlue2 nopadding"
                        >
                            {t('components.search_button.clear')}
                        </Button>:
                        <Button id="searchButton" name="searchButtonName"
                            onClick={(e) => ButtonSearchCleanInputControllerConst.clickSearchButton(e)} 
                            className="buttonGray nopadding"
                        >
                            {t('components.search_button.search')}
                        </Button>
                    }
                </Col>
            }
        </Row>
    );
}

export default ButtonSearchCleanInput;
