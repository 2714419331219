import { ListGroup, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from "../../../../../components/loading";
import ModalEditEmailController from '../../../components/modalEditEmail/indexController';
import Utils from "../../../../../core/shared/utils";
import { getShowEditEmailModal } from "../../../../../store/internal";
import { useSelector } from "react-redux";
import ButtonOrderByList from '../../../components/buttonOrderByList';
import { ListName } from '../../../../../core/enums/order-sort-by';

const ListOfEmails = ({
    props,
    isLoading,
    values,
    currentEmailData,
    setCurrentEmailInfo,
    popover,
    t,
}) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <ModalEditEmailController token={values.token.value} visibility={useSelector(getShowEditEmailModal)} callback={props.callbackModalEmails} data={currentEmailData} />
            <div className="ps-0 ms-0" style={{ marginTop: '2vh', marginLeft: '2vh' }}>
                <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                        <Row name="listOfEmailsHead">
                            <Col md={3} className="nopadding">
                                <span style={{ cursor: 'pointer' }} className="me-1" >{t("home_channels.lists_channels.list_emails.email")}</span>
                            </Col>
                            <Col md={2} className="nopadding ps-0">
                                <span style={{ cursor: 'pointer' }} className="me-1">{t("home_channels.lists_channels.list_emails.sector")}</span>
                            </Col>
                            <Col md={2} className="nopadding ps-0">
                                <ButtonOrderByList title={t("home_channels.lists_channels.list_emails.status")} columnIdName='status' callbackOrderBy={props.callbackOrderBy} />
                            </Col>
                            <Col md={2} className="nopadding ps-0">
                                <span style={{ cursor: 'pointer' }} className="me-1">{t("home_channels.lists_channels.list_emails.link_verification")}</span>
                            </Col>
                            <Col md={3} className='d-flex justify-content-end' >
                                <p>{t("home_channels.lists_channels.list_emails.actions")}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>

                {
                    props.datas !== undefined ?
                        <div id="listOfEmails" style={{ overflowY: 'auto', height: '41vh' }}>
                            <InfiniteScroll
                                dataLength={props.datas.length}
                                next={props.updateListfunction}
                                hasMore={props.HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfEmails"}
                                onScroll={() => Utils.hidePopoverOnScroll(ListName.ListOfEmails)}
                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {

                                            props.datas.map((item, index) => {
                                                //let date = new Date(item.created_at);

                                                return (
                                                    <ListGroup.Item style={{ color: '#707070' }} id={item.id} key={"keyAll" + index.toString()}>
                                                        <Row className='TicketsRowHeight'>
                                                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.email}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.email}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.sector && item.sector.name ? item.sector.name : "N/A"}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.sector && item.sector.name ? item.sector.name : "N/A"}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.active ?
                                                                                <>{t("home_channels.lists_channels.list_emails.active")}</> : <>{t("home_channels.lists_channels.list_emails.inactive")}</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div style={{ width: '33%' }}>
                                                                        {item.active ?
                                                                            <div className="d-flex justify-content-center py-1"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                                    borderRadius: '3px',
                                                                                    font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                    letterSpacing: '0px',
                                                                                    color: '#0C2461',
                                                                                }}>
                                                                                {t("home_channels.lists_channels.list_emails.active")}
                                                                            </div> :
                                                                            <div className="d-flex justify-content-center py-1"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    background: '#FFD4B0 0% 0% no-repeat padding-box',
                                                                                    borderRadius: '3px',
                                                                                    font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                    letterSpacing: '0px',
                                                                                    color: '#FF8119',
                                                                                }}>
                                                                                {t("home_channels.lists_channels.list_emails.inactive")}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {t("home_channels.lists_channels.list_emails.link_confirmation")}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    {item.confirmation_url ?
                                                                    <a target="_blank" href={`${item.confirmation_url}`}>
                                                                        {t("home_channels.lists_channels.list_emails.click_for_confirm")}
                                                                    </a>
                                                                    :
                                                                    <>N/A</>
                                            }
                                                                </OverlayTrigger>
                                                            </Col>
                                                            <Col md={2} className='d-flex justify-content-center py-1' >
                                                                {item.is_primary ?
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip>{t("home_channels.lists_channels.list_emails.email_main")}</Tooltip>
                                                                        }
                                                                    >
                                                                        <svg id="Grupo_13471" data-name="Grupo 13471" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                            <path id="Caminho_10331" data-name="Caminho 10331" d="M0,0H24V24H0Z" fill="none" />
                                                                            <path id="Caminho_10332" data-name="Caminho 10332" d="M12,17.75,5.828,20.995l1.179-6.873-5-4.867,6.9-1L11.993,2l3.086,6.253,6.9,1-5,4.867,1.179,6.873Z" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                    :
                                                                    <></>
                                                                }
                                                            </Col>
                                                            <Col md={1} className='d-flex justify-content-end' >
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item)}>
                                                                    <Button id={"button*ID*" + item.id + "*ID*" + item.active.toString() + "*is_primary*" + item.is_primary.toString()} name={item.email} onClick={(e) => setCurrentEmailInfo(e)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>);
                                            })}
                                    </ListGroup>
                                }
                            </InfiniteScroll>
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '42vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1} className="d-flex justify-content-center">
                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('home_channels.lists_channels.list_emails.no_results')} <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateEmail}>{t('home_channels.lists_channels.list_emails.add_email')}</Button></p>
                                </Col>
                            </Row>
                        </div>
                }

            </div>
        </>
    );
}

export default ListOfEmails;