import { Dispatch } from "react";
import { setMute } from '../../store/internal';
class NotificationSoundController {
    private sound: HTMLAudioElement = new Audio();
    private notification: boolean = false;
    private mute: boolean;
    private dispath: Dispatch<any>;

    constructor(mute: boolean, dispath: Dispatch<any>) {
        const cacheNotificationSound = localStorage.getItem('cx_notification_mute_sound');
        this.sound = new Audio(process.env.PUBLIC_URL + '/audios/notification_3.mp3');
        this.sound.load();
        this.mute = cacheNotificationSound ? cacheNotificationSound === 'true' : mute;
        if (!cacheNotificationSound) {
            localStorage.setItem('cx_notification_mute_sound', mute.toString());
        }
        this.dispath = dispath;
    }


    handleClick = (mute) => {
        this.dispath(setMute(!mute));
        this.mute = !mute;
        localStorage.setItem('cx_notification_mute_sound', this.mute.toString());
        this.setNotification(false);
    }

    public playSound = () => {
        const aux_mute = localStorage.getItem('cx_notification_mute_sound') === 'true';
        if (!aux_mute) {
            var playPromise = this.sound.play();
    
            if (playPromise !== undefined) {
                playPromise.then(_ => { })
                    .catch(error => {
                        this.notification = true;
                        console.log(error);
                    });
            }
        }
    }


    public getNotification = () => {
        return this.notification;
    }

    public setNotification = (newNotification: boolean) => {
        this.notification = newNotification;
    }
}

export default NotificationSoundController;