import React, { FC } from 'react';

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import pt from 'react-phone-input-2/lang/pt.json'
import { IModalEditPhone } from './indexModal';


const ModalEditLink: FC<IModalEditPhone> = (props) => {
  return (
    <Modal show={props.modalShow} onHide={() => props.Hide()} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {props.t("components.modal_link.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{
              numero: props.number ? props.number : ""
            }}
            validate={values => {
            }}

            onSubmit={null}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={props.validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center mb-4">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{props.t("components.modal_phone.label_number")}</Form.Label>
                    {/* <PhoneInputDropdown phoneNumber={values.phone} values={values} setValues={setValues} type={PhoneType.Phone} tag={phoneTag} setPhoneError={setPhoneError} /> */}
                    <PhoneInput
                      placeholder={props.t("components.modal_phone.phone_input_placeholder")}
                      value={values?.numero}
                      onChange={(e) => props.SetCustomers_phone(e)}
                      onBlur={handleBlur}
                      inputProps={{
                        name: 'Telefone',
                        required: true,
                        border: 'none',
                        style: { width: '100%', font: ' var(--px15_19-font-Roboto)', height: '32px', border: props.phoneBorder }
                      }}
                      country={'br'}
                      // onlyCountries={['br', 'pt']}
                      localization={pt}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{props.t("components.modal_link.label_link")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}></span></Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <InputGroup style={{ marginBottom: "1rem" }}>
                      <FormControl
                        className="form-control-Default"
                        placeholder={props.t('home_consumers.modal_edit.form_name.placeholder')}
                        aria-label={props.t('home_consumers.modal_edit.form_name.placeholder')}
                        aria-describedby={props.t('home_consumers.modal_edit.form_name.placeholder')}
                        size="lg"
                        type="name"
                        id="customers_name"
                        name="name"
                        autoComplete='given-name'
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={`https://wa.me/${values?.numero}`}
                        disabled={true}
                      ></FormControl>
                      <Button onClick={() => props?.copyToClipboard(`https://wa.me/${values?.numero}`)} style={{ background: 'transparent', border: 'none', padding: '0px', alignItems: 'center', marginLeft: "0.3rem" }}>
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                            <Tooltip id='tooltipToken'>
                              {props.t("components.modal_link.tooltip_copy")}
                            </Tooltip>
                          }
                        >
                          <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2C4CA2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                          </svg>
                        </OverlayTrigger>
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-end mt-3">
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' className="buttonWhiteRed" style={{ width: '56%' }} onClick={() => props.Hide()} >{props.t("components.modal_link.btn_closed")}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditLink;
