import { FC, useContext, useState } from "react";
import ModalCC_CCO_Create from ".";
import { EmailContext } from "../../../../../../../core/context/cc-cco-context";
import { useTranslation } from "react-i18next";
import { IModalCC_CCO_CreateController } from "./indexModel";
import { AppRequesterController } from "../../../../../../../services/appRequester/appRequesterController";
import UserService from "../../../../../../../services/user-service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../../../store/token";

const ModalCC_CCO_CreateController: FC<IModalCC_CCO_CreateController> = (props) => {
    const email = useContext(EmailContext)

    const { t } = useTranslation();
    const appRequesterConst = new AppRequesterController();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const emailCC = email.getEmailCC()
    const emailCCO = email.getEmailCCO()

    const [requesterEmail, setRequesterEmail] = useState("");
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [emailError, setEmailError] = useState("");

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken),
        }
    }

    const onChangeEmailCC = (e: { detail: { tagify: { getCleanValue: () => any[] } } }) => {
        email.setEmailCC(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value
        }))
    }

    const onChangeEmailCCO = (e: { detail: { tagify: { getCleanValue: () => any[] } } }) => {
        email.setEmailCCO(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value
        }))
    }

    const validateEmail = () => {
        if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(requesterEmail)) || requesterEmail === "") {
            setEmailIsInvalid(true);
            setEmailError(t('home_consumers.modal_edit.validate.email'));
        } else {
            setEmailIsInvalid(false);
        }
    }

    const onChangeEmail = (e) => {
        let email = e.target.value;
        setRequesterEmail(email);
    }

    const saveEmails = async () => {
        if (!emailIsInvalid) {
            const headers = UserService.getHeaders();
            await appRequesterConst.Get(
                '/consumer', { headers, params: { search: requesterEmail } },
                (response: Object) => {
                    return response;
                },
                (data) => {
                    if (data.data && data.status == 200 && data.data?.consumers?.length > 0) {
                        let consumer = data.data.consumers[0];
                        
                        let newUser = [Object.assign(consumer, { value: consumer.user_id, label: consumer.name })]
            
                        props.setSelectedConsumers(newUser);
                        props.setCurrentRequester_user(newUser[0]);
                    } else {
                        let newUser = [{
                            value: requesterEmail,
                            label: requesterEmail.split("@")[0],
                            name: requesterEmail.split("@")[0],
                            email: requesterEmail
                        }]
            
                        props.setSelectedConsumers(newUser);
                        props.setCurrentRequester_user(newUser[0]);
                    }
                },
                (error: Object) => {
                    
                }, navigate, dispatch, () => {}, { values: values }
            );

            props.handleClose();
        }
    }

    return <ModalCC_CCO_Create 
        show={props.show}
        saveEmails={saveEmails}
        emailCC={emailCC}
        emailCCO={emailCCO}
        onChangeEmailCC={onChangeEmailCC}
        onChangeEmailCCO={onChangeEmailCCO}
        t={t} 
        requesterEmail={requesterEmail} 
        emailIsInvalid={emailIsInvalid} 
        emailError={emailError} 
        validateEmail={validateEmail} 
        onChangeEmail={onChangeEmail}    
        handleClose={props.handleClose}
    />
}

export default ModalCC_CCO_CreateController;
