import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { setShowRecoverPasswordAgente, setShowAlertFeedback } from '../../../../../store/internal'
import { useNavigate } from 'react-router-dom';
import { ModalEditTokenChatbot } from './indexModel';
import RecoverAgentPassword from './index'
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { getToken } from '../../../../../store/token';
import { TokenInterfaceControlPanel } from '../../../../../services/requestsInterfacesModel';
import constsApi from "../../../../../services/constsApi";

const AppRequesterConst = new AppRequesterController()

const RecoverAgentPasswordController: FC<ModalEditTokenChatbot> = (props) => {
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [senha, setSenha] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  useEffect(() => {
    setModalShow(props.visibility);
  }, [props.visibility, props.data]);

  const Hide = () => {
    dispatch(setShowRecoverPasswordAgente(false));
  }

  const clickPassword = () => {
    if ((document.getElementById("senha") as HTMLInputElement).type === "text") {
      (document.getElementById("senha") as HTMLInputElement).type = "password";
    } else {
      (document.getElementById("senha") as HTMLInputElement).type = "text";
    }

    setShowPassword(!showPassword);
  };

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link)
    dispatch(setShowAlertFeedback({ message: "Copiado para a área de transferência", visibility: true, signalIcon: true }));

  }

  const generateLink = async (companyId, email) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    const JsonSend = {
      "company_id": companyId,
      "email": email
    };

    await AppRequesterConst.Post(
      `/panel/generate-link-reset-password`, JsonSend, { headers },
      (response: Object) => { },
      (data: any) => {
        if (data.status === 200) {
          copyToClipboard(data.data.link);
          Hide();
        }
        props.callback()
      },
      (error: { response: { status: number } }) => {
        if (error.response.status === 400) { }
        if (error.response.status === 401) { }
      }, navigate, dispatch, setIsLoading,
    )
  }

  return (
    <RecoverAgentPassword
      modalShow={modalShow}
      Hide={Hide}
      props={props}
      dispatch={dispatch}
      navigate={navigate}
      setIsLoading={setIsLoading}
      showPassword={showPassword}
      clickPassword={clickPassword}
      setSenha={setSenha}
      senha={senha}
      generateLink={generateLink}
    />
  );
}

export default RecoverAgentPasswordController;