import { FC, useEffect, useState } from 'react'
import ButtonSearchCleanInput from './index'
import { ButtonSearchCleanInputInterface } from './indexModel'
const ButtonSearchCleanInputController: FC<ButtonSearchCleanInputInterface> = ({ 
    inputPlaceholder, 
    currentFilterSearch, 
    isClosingCreateTicket, 
    setIsClosingCreateTicket, 
    setDefaultSearching, 
    defaultSearching, 
    setCurrentFilterSearch, 
    filledCreatingTicket, 
    closeWithoutSavingConfirmation,
    filteringTickets,
    desactiveSearchButton,
 }) => {
    
    const [searchButtonClickedWithContent, setSearchButtonClickedWithContent] = useState(false)
    const [currentEventSave, setCurrentEventSave] = useState(null as Object || null);
    const [showCleanButton, setShowCleanButton] = useState(false);

    useEffect(() => {
        if (isClosingCreateTicket === true && currentEventSave !== null) {
            setIsClosingCreateTicket(false);
            setCurrentEventSave((state: any) => {
                if (searchButtonClickedWithContent && currentFilterSearch && currentFilterSearch.length > 0) {
                    cleanSearchField(state)
                } else {
                    clickSearchButton(state)
                }
                return null
            })
        }

        if (setDefaultSearching) {
            setDefaultSearching((state: any) => {
                if (state) {
                    setShowCleanButton(true)
                }

                return state;
            })
        }
    }, [isClosingCreateTicket, defaultSearching])

    useEffect(() => {
        if (inputPlaceholder) {
            setCurrentFilterSearch("")
            setSearchButtonClickedWithContent(false);
            setCurrentEventSave(null);
        }
    }, [inputPlaceholder])

    const clickCleanSearchConfirmation = (e: Object) => {
        if (filledCreatingTicket) {
            setCurrentEventSave(e);
            closeWithoutSavingConfirmation(e);
        } else {
            cleanSearchField(e);
        }
    }

    const cleanSearchField = (e: Object) => {
        setCurrentFilterSearch("");
        filteringTickets(e, {}, "clean");

        setShowCleanButton(false);
        setSearchButtonClickedWithContent(false)
    }

    const clickSearchConfirmation = (e: any) => {
        if (filledCreatingTicket) {
            setCurrentEventSave(e);
            closeWithoutSavingConfirmation(e)
        } else {
            clickSearchButton(e);
        }
    }

    const clickSearchButton = (e: Object) => {
        filteringTickets(e);
        if (currentFilterSearch && currentFilterSearch.length > 0) {
            setSearchButtonClickedWithContent(true)
        }
    }

    const onChangeFrequent = (e: any) => {
        setCurrentFilterSearch(e.currentTarget.value);

        if (e.currentTarget.value.length == 0) {
            cleanSearchField(e);
        }
    }

    const onKeyDownFrequent = (e: any) => {
        if (e.key === "Enter") {
            clickSearchButton(e);
        }
    }

    const onChangeGeneric = (e: any) => {
        setCurrentFilterSearch(e.currentTarget.value);

        if (e.currentTarget.value.length == 0) {
            clickCleanSearchConfirmation(e);
        }
    }

    const onKeyDownGeneric = (e: any) => {
        if (e.key === "Enter") {
            clickSearchConfirmation(e.target.value);
        }
    }

    return (
        <ButtonSearchCleanInput
            inputPlaceholder={inputPlaceholder}
            currentFilterSearch={currentFilterSearch}
            clickSearchButton={clickSearchButton}
            cleanSearchField={cleanSearchField}
            desactiveSearchButton={desactiveSearchButton}
            searchButtonClickedWithContent={searchButtonClickedWithContent}
            showCleanButton={showCleanButton}
            onChangeFrequent={onChangeFrequent}
            onKeyDownFrequent={onKeyDownFrequent}
            onChangeGeneric={onChangeGeneric}
            onKeyDownGeneric={onKeyDownGeneric}
        />
    )
}

export default ButtonSearchCleanInputController