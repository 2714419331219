export class modalConfirmationController {
  
  private props
  private setModalShow:Function
  private dispatch:Function
  private setShowConfirmationModal:Function

  constructor(props,setModalShow:Function,dispatch:Function,setShowConfirmationModal:Function){
    this.props=props
    this.setModalShow= setModalShow
    this.dispatch= dispatch
    this.setShowConfirmationModal=setShowConfirmationModal
  }

  Hide () {
    if (this.props.functionCancellation) {
      this.props.functionCancellation();
    }
    this.setModalShow(false);
    this.dispatch(this.setShowConfirmationModal({ 
      visibility: false, 
      text: { 
        title: '', 
        body: '', 
        id: '', 
        buttonReturnText: '', 
        buttonConfirmationText: '', 
        closeButton: true 
      }, 
      funtionConfirmation: () => { }, 
      functionConfirmation: () => { },
      functionCancellation: () => { },
    }));
  }

  Cancellation () {
    if (this.props.functionCancellation)
      this.props.functionCancellation();
    this.Hide();
  }

  Confirmation () {
    if (this.props.funtionConfirmation) {
      this.props.funtionConfirmation();
    }
    if (this.props.functionConfirmation) {
      this.props.functionConfirmation();
    }
      
    this.setModalShow(false);
    this.dispatch(this.setShowConfirmationModal({ visibility: false, text: { "title": '', "body": '', "id": '', buttonReturnText: '', buttonConfirmationText: '', closeButton: true }, functionConfirmation: () => { } }));
  }

  Confirmation2() {
    if (this.props.funtionConfirmation2) {
      this.props.funtionConfirmation2();
    }
    if (this.props.functionConfirmation2) {
      this.props.functionConfirmation2();
    }

    this.setModalShow(false);
    this.dispatch(this.setShowConfirmationModal({ visibility: false, text: { "title": '', "body": '', "id": '', buttonReturnText: '', buttonConfirmationText: '', closeButton: true }, functionConfirmation: () => { }, functionConfirmation2: () => { } }));
  }
}
