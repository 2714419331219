import React, { FC } from 'react';

import { Container, Row, Button, Col, Tab, Tabs } from 'react-bootstrap';

import allStyles from './styles';
import ButtonSearchCleanInput from '../../../buttonSearchCleanInputComponent';

import { TemplateMessageComponentInterface } from './indexModel';
import { Channels } from '../../../../../../core/enums/channels';
import './styles.css';

const TemplateMessageComponent: FC<TemplateMessageComponentInterface> = (props) => {
    return (
        <Container
            className="d-flex justify-content-center nopadding"
        >
            <div >
                <Row className="nopadding d-flex align-items-center">
                    <Col className="nopadding px-2 body-phoneDialer" sm={12} md={12}
                    >
                        <Row className="d-flex justify-content-start align-items-center nopadding mt-3" >
                            <Col style={allStyles.title}>
                                { props.t("home_tickets.template_messages.frequent_responses") }
                            </Col>
                            <Col sm={1} md={1} className="nopadding d-flex justify-content-center">
                                <Button
                                    style={{
                                        cursor: 'pointer',
                                        border: 'none',
                                        background: 'none',
                                    }}
                                    onClick={() => props.showTemplateMessage()}>
                                    <svg id="Grupo_11452" data-name="Grupo 11452" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Caminho_9734" data-name="Caminho 9734" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_394" data-name="Linha 394" x1="10" y2="10" transform="translate(6 6)" fill="none" stroke="#6a6a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_395" data-name="Linha 395" x2="10" y2="10" transform="translate(6 6)" fill="none" stroke="#6a6a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Button>
                            </Col>
                        </Row>

                        <Row className="nopadding" style={{ height: '9.38vh', width: '100%' }}>

                            <Tabs mountOnEnter={true} unmountOnExit={true} onSelect={(selectedTab) => props.tabActive(selectedTab)} defaultActiveKey={ props.currentChannelId === Channels.WhatsApp? "resppostas_whatsapp": "mensagens_rapidas" } id="channelsTabs" >
                                {/* quick message não pode ser usada na criação com canal whatsapp */}
                                {(props.currentTicket === null && props.currentChannelId === Channels.WhatsApp || (props.currentTicket !== null && props.currentChannelId === Channels.WhatsApp && props.hasLastMessageFromConsumer === false)) 
                                    ? 
                                    <></>:
                                    <Tab eventKey={"mensagens_rapidas"} tabClassName={props.currentChannelId !== Channels.WhatsApp? props.classWhatsappResponse: props.classQuickMessages} title={ props.t("home_tickets.template_messages.title_quick_messages") } >
                                        <Row className='mt-2 me-0'>
                                            <ButtonSearchCleanInput inputPlaceholder={props.t('home_tickets.messages_component.search')} filteringTickets={() => { }} setCurrentFilterSearch={(e) => props.setCurrentFilterSearch(e)} currentFilterSearch={props.currentFilterSearch} desactiveSearchButton={true}></ButtonSearchCleanInput>
                                        </Row>
                                        {
                                            props.quickMessage !== undefined &&
                                            <Row id="listOfAlerts" style={{ overflowY: 'auto', overflowX: 'hidden', height: 'min(max(50px, 35vh), 240px)', width: 'fit-content' }}>
                                                {
                                                    props.quickMessage.map((item, index) => {
                                                        if (item.quick_message === true) {
                                                            return (
                                                                <div key={index} style={{ cursor: 'pointer' }}>
                                                                    <Row className='my-1 mx-2' style={allStyles.responseTextBold} onClick={() => { props.callbackQuickMessage(item); props.block_ticket.setReadOnlyEditorComponent(false); }}>{item.title}</Row>
                                                                    <Row className='my-1 mx-2' style={allStyles.responseText} onClick={() => { props.callbackQuickMessage(item); props.block_ticket.setReadOnlyEditorComponent(false); }}>
                                                                        {item.message}
                                                                    </Row>

                                                                    {/* <Row className='my-1 mx-2' style={allStyles.responseTextBold} onClick={() => props.callbackTemplateMessage(item)}>{item.title}</Row>
                                                                    <Row className='my-1 mx-2' style={allStyles.responseText} onClick={() => props.callbackTemplateMessage(item)}>
                                                                        {item.message}
                                                                    </Row> */}
                                                                    <hr style={{ border: "1px solid #E8E8E8" }} />
                                                                </div>
                                                            )
                                                        }

                                                    })
                                                }
                                            </Row>
                                        }
                                    </Tab>
                                }

                                {/* template whatsapp somente para whatsapp */}
                                {props.currentChannelId === Channels.WhatsApp &&
                                    <Tab eventKey={"resppostas_whatsapp"} tabClassName={props.classWhatsappResponse} title={ props.t("home_tickets.template_messages.whatsapp_messages") } > 
                                        <Row className='mt-2 me-0'>
                                            <ButtonSearchCleanInput inputPlaceholder={props.t('home_tickets.messages_component.search')} filteringTickets={() => { }} setCurrentFilterSearch={props.setCurrentFilterSearch} currentFilterSearch={props.currentFilterSearch} desactiveSearchButton={true}></ButtonSearchCleanInput>
                                        </Row>
                                        {
                                            props.templateMessage !== undefined &&
                                            <div id="listOfAlerts" style={{ maxHeight: '240px', overflowY: 'auto', width: '100%' }}>
                                                {
                                                    props.templateMessage.map((item, index) => {
                                                        if (item.quick_message === false) {
                                                            return (
                                                                <div key={index} style={{ cursor: 'pointer' }}>
                                                                    <div className='my-1 mx-2' style={allStyles.responseTextBold} onClick={() => { props.callbackTemplateMessage(item); props.block_ticket.setReadOnlyEditorComponent(true); }}>{item.title_and_number}</div>
                                                                    <div className='my-1 mx-2' onClick={() => { props.callbackTemplateMessage(item); props.block_ticket.setReadOnlyEditorComponent(true); }}>
                                                                        <span style={{ ...allStyles.responseText, wordWrap: item?.message?.length > 50 ? 'break-word' : 'normal' }}>
                                                                            {item.message}
                                                                        </span>
                                                                    </div>
                                                                    <hr style={{ border: "1px solid #E8E8E8" }} />
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        }


                                    </Tab>
                                }
                            </Tabs>
                        </Row>
                        <Row className="d-flex justify-content-center nopadding mt-2" style={{ height: '5vh', width: '100%' }}>

                        </Row>
                        <Row className="d-flex align-items-center nopadding my-3">

                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default TemplateMessageComponent;