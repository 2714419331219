import React, { FC } from 'react';

import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Loading from '../../../../../components/loading'

import allStyles from "../styles"
import { IconBrandFacebook, IconWorldWww } from '@tabler/icons-react';
import { IListConfigDomain } from './indexModel';
import ModalViewDomainController from '../modalViewDomain/indexController';

const ListConfigDomain: FC<IListConfigDomain> = (props) => {
    const popover = (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={props.handleOpenModalEdit} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Ver detalhes</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={() => props.deleteDomainConfirm()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir domínio</Button>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <ModalViewDomainController domainId={props.domainId} callbackList={props.callbackUpdateList}  />
            <Loading visibility={props.isLoading}></Loading>
            <div className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={allStyles.TextRowCollums}>
                        <Row name="ListOfAgentsHead">
                            <Col md={5} className="nopadding">
                                <p>Domínio</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>Status</p>
                            </Col>
                            <Col className='justify-content-end d-flex'>
                                { props.isEditAllowed && <p>Ações</p> }
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    props.domainList?.length !== 0 ?
                        <div style={{ overflowY: 'auto', height: '56vh' }}>
                            {
                                <ListGroup variant="flush" className="TicketsRow">
                                    {props.domainList.map((item, index) => {
                                            return (
                                                <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                                    <Row className='TicketsRowHeight'>
                                                        <Col md={5} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item?.domain != null ?
                                                                                <>{item?.domain}</>
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.domain}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item?.is_authenticated?
                                                                                <>Verificado</>:
                                                                                <>Pendente</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.is_authenticated?
                                                                            <>Verificado</>:
                                                                            <>Pendente</>
                                                                        }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col className='d-flex justify-content-end' style={{ marginLeft: '2.3rem' }} >
                                                            {
                                                                props.isEditAllowed &&
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                    <Button onClick={() => props.openPopover(item.id, item.domain)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            }
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={2} className="d-flex justify-content-center">
                                    <IconWorldWww size={80} color="#4a69bd" />
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '2%', backgroundColor: '#F9F9F9' }}>
                                <Col md={6} className='d-flex justify-content-center'>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, adicione um domínio</p>
                                </Col>
                            </Row>
                        </div>
                }
                {/* <ModalEditFacebookPageController
                    show={props.showModalEdit}
                    handleClose={props.handleCloseModalEdit}
                    data={props.data}
                    getFacebookById={props.getFacebookById}
                /> */}
            </div>
        </>
    );

}

export default ListConfigDomain;