import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowAlertFeedback } from '../../store/internal';
import { AlertPositiveNegativeInterface } from './indexModel';
import AlertPositiveNegative from './index';

const AlertPositiveNegativeController: FC<AlertPositiveNegativeInterface> = (props) => {
  const [visibility, setVisibility] = useState(props.visibility);
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(undefined);

  const displayFunction = () => {
    if (typeof props.visibility === 'string') {
      return props.visibility;
    } else {
      return props.visibility ? 'block' : 'none';
    }
  }

  const closeAlertFunction = () => {
    dispatch(setShowAlertFeedback({ visibility: false, message: "", signalIcon: false }));
    setVisibility(false);
    clearTimeout(timer);
    setTimer(undefined);
  }

  useEffect(() => {
    let timeOut = props.timeOut? props.timeOut: 4000;

    setVisibility(props.visibility);
    clearTimeout(timer);
    setTimer(setTimeout(() => {
      dispatch(setShowAlertFeedback({ visibility: false, message: "", signalIcon: true }));
    }, timeOut));
    
    return () => {
      clearTimeout(timer);
      setTimer(undefined);
    }
  }, [props.timestamp, props.visibility]);

  return (
    <AlertPositiveNegative 
      visibility={visibility}
      signalIcon={props.signalIcon}
      message={props.message}
      setVisibility={setVisibility}
      displayFunction={displayFunction}
      closeAlertFunction={closeAlertFunction}
      timestamp={props.timestamp}
    />
  );
}

export default AlertPositiveNegativeController;
