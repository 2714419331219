import React, { FC, useEffect } from 'react';
import { Button, Col, Container, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { IModalUnifyConsumers } from './indexModal';

const ModalUnifyConsumers: FC<IModalUnifyConsumers> = (props) => {
    return (
        <Modal show={props.showModal} onHide={() => props.hide()} aria-labelledby="" size="lg" centered={true} >
            <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%", marginRight: '3%' }}>
                <Modal.Title 
                    style={{
                        textAlign: 'left',
                        font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
                        letterSpacing: '0px',
                        color: '#1E1E1E',
                        opacity: '1',
                    }}
                >
                    {props.t('home_consumers.unification.modal_unify.unify_consumer')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Header className="py-0" 
                style={{ 
                    marginLeft: "6%", 
                    marginRight: '3%',
                    textAlign: 'left',
                    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#707070',
                    opacity: '1', 
                }}
            >
                <Col>
                    <Row className="nopadding">
                        <div className="nopadding">
                            {props.t('home_consumers.unification.modal_unify.alert_text')}
                        </div>
                    </Row>
                    <Row className="nopadding mt-2">
                        <div className="nopadding">
                            <b style={{ color: '#1E1E1E' }}>*</b> {props.t('home_consumers.unification.modal_unify.confirmation_text')}
                        </div>
                    </Row>
                </Col>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "6%", marginRight: "3%", marginBottom: "2%" }}>
                <Container className='ps-0'>
                    { props.originRedirectPage !== "unify_consumers" &&
                        <Row className="px-3 pb-3">
                            <Button
                                style={{
                                    background: '#FF0000 0% 0% no-repeat padding-box',
                                    border: '1px solid #FF0000',
                                    borderRadius: '4px',
                                    opacity: '1',
                                }}
                                onClick={(e) => props.gotoUnifyPage(e)}
                            >
                                {props.t('home_consumers.unification.modal_unify.compare_registrations')}
                                <span className="ps-2">
                                    <svg id="Grupo_13717" data-name="Grupo 13717" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Caminho_10347" data-name="Caminho 10347" d="M0,0H24V24H0Z" fill="none"/>
                                        <path id="Caminho_10348" data-name="Caminho 10348" d="M12,6H6A2,2,0,0,0,4,8V18a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V12" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                        <path id="Caminho_10349" data-name="Caminho 10349" d="M11,13l9-9" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                        <path id="Caminho_10350" data-name="Caminho 10350" d="M15,4h5V9" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    </svg>
                                </span>
                            </Button>
                        </Row>
                    }
                    <Row>
                        <Modal.Title 
                            style={{
                                textAlign: 'left',
                                font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#1E1E1E',
                                opacity: '1',
                            }}
                        >
                            {props.t('home_consumers.unification.modal_unify.final_data')}
                        </Modal.Title>
                    </Row>
                    <Row>
                        <Col
                            style={{ 
                                textAlign: 'left',
                                font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#707070',
                                opacity: '1',
                            }}
                        >
                            <div>
                                { props.currentConsumer && Object.keys(props.currentConsumer).map((field, index) => {
                                    if (field !== "custom_fields")
                                        return (
                                            <div style={{ display: 'inline-block' }} key={'field-modal-' + field.toString() + index}>
                                                <Row className='d-flex nopadding'>
                                                    <Row className="ps-3 pt-3">
                                                        { props.consumerFieldName(field) }
                                                    </Row>
                                                    <Row className="ps-3">
                                                        <b style={{ color: '#1E1E1E' }} className='nopadding'>
                                                            {   field === "name" && props.currentConsumer.name && props.currentConsumer.lastname? 
                                                                    (props.currentConsumer.name + " " + props.currentConsumer.lastname):

                                                                field === "observations"?
                                                                    <OverlayTrigger key={field} placement='top' overlay={<Tooltip>{ props.currentConsumer[field] }</Tooltip>} >
                                                                        <span style={{ width: '15vw' }} className='oneLineClampEllipsis'>
                                                                            { props.currentConsumer[field] }
                                                                        </span>
                                                                    </OverlayTrigger>:

                                                                props.currentConsumer[field]? 
                                                                    props.currentConsumer[field]:
                                                                    <>&mdash;</>
                                                            }
                                                        </b>
                                                    </Row>
                                                </Row>
                                            </div>   
                                        )
                                })}

                                {/* Campos customizados */}
                                { props.currentConsumer && props.currentConsumer['custom_fields'] && props.currentConsumer['custom_fields'].length > 0 && props.currentConsumer['custom_fields'].map((custom, index) => {
                                    return (
                                        <div style={{ display: 'inline-block' }} key={'custom-field-' + custom.name + index}>
                                            <Row className='d-flex nopadding'>
                                                <Row className="ps-3 pt-3">
                                                    { custom.name }
                                                </Row>
                                                <Row className="ps-3">
                                                    <b style={{ color: '#1E1E1E' }} className='nopadding'>
                                                        { custom.value[0] }
                                                    </b>
                                                </Row>
                                            </Row>
                                        </div>   
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mt-4">
                        <Col md={6} className="d-flex justify-content-start">
                            <div style={{ width: '70%' }}>
                                <Button size='lg' className="buttonWhite" onClick={props.hide} >{props.t('home_consumers.unification.modal_unify.cancel')} </Button>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                            <div style={{ width: '70%' }}>
                                <Button size='lg'
                                    className='buttonBlue'
                                    onClick={() => props.unifyConsumersConfirm()}>
                                    {props.t('home_consumers.unification.modal_unify.confirm')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ModalUnifyConsumers;