import allStyles from './styles'
import { Row, Col, InputGroup, Form, FormControl, Button, Modal, Container, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Formik } from "formik"
import { Channels } from "../../../../core/enums/channels"
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController"
import UserService from '../../../../services/user-service'
import { setShowAlertFeedback } from '../../../../store/internal'

const AppRequesterConst = new AppRequesterController()

const QuickMessageRegister = ({
    props,
    setTitle,
    setMessage,
    validated,
    feedbackTitle,
    setShowFeedbackTitle,
    feedbackMessage,
    setShowFeedbackMessage,
    setIsLoading,
    dispatch,
    clearInputs,
    navigate,
    title,
    message,
    t,
    getRootProps,
    getInputProps,
    showFiles,
    isVideo,
    fileIds,
    disabled,
    setDisabled,
}) => {
    
    return (
        <>   
            <Modal show={props.modalShow} onHide={props.onHide} aria-labelledby="" size="lg" centered={true}>
                <Modal.Header closeButton style={{ marginLeft: "1.5%", marginTop: "1%", marginBottom: "-1%" }}>
                    <Modal.Title style={allStyles.textBlack}>
                        {t('configurations_ticket.modal_quick_message_register.new_quick_message')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Formik
                            initialValues={{ title: "", message: ""}}
                            validate={(values) => {
                                setTitle(values.title)
                                setMessage(values.message)

                                let errors = {}

                                if(!values.title) {
                                    errors["title"] = t('configurations_ticket.modal_quick_message_register.validate.title')
                                    setShowFeedbackTitle(true)
                                } else {
                                    setShowFeedbackTitle(false)
                                }

                                if(!values.message) {
                                    errors["message"] = t('configurations_ticket.modal_quick_message_register.validate.message')
                                    setShowFeedbackMessage(true)
                                } else {
                                    setShowFeedbackMessage(false)
                                }

                                return errors
                            }}
                            onSubmit={ async () => {
                                setDisabled(true)
                                let JsonSend = {
                                    "title": title,
                                    "message": message,
                                    "channel_id": Channels.Email,
                                    // "phone_number": "null"
                                }

                                if (fileIds.length > 0) {
                                    JsonSend["quick_message_attachment"] = fileIds[0].id
                                }

                                // if (selectedChannel && selectedChannel.length >= 1) {
                                //     JsonSend["channel_id"] = selectedChannel[0].value
                                // }

                                // if (selectedPhoneNumber && selectedPhoneNumber.length >= 1) {
                                //     JsonSend["phone_number"] = selectedPhoneNumber[0].label
                                // }

                                const headers = UserService.getHeaders()

                                await AppRequesterConst.Post(
                                    'template-message/quick-message', JsonSend, { headers },
                                    (response: Object) => {

                                    },
                                    (data: { data: { message: string} }) => {
                                        dispatch(setShowAlertFeedback({ message: t('configurations_ticket.modal_quick_message_register.submit.message'), visibility: true, signalIcon: true }))
                                        props.callback()
                                        props.onHide()
                                        clearInputs()
                                    },
                                    (error) => {
                                        dispatch(setShowAlertFeedback({ message: t('configurations_ticket.modal_quick_message_register.submit.error'), visibility: true, signalIcon: false }))
                                        setDisabled(false)
                                    }, navigate, dispatch, setIsLoading,
                                )
                            }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit

                        }) => (
                            <Form onSubmit={ handleSubmit } noValidate validated={validated}>
                                <Row style={allStyles.textFontGlay} className='d-flex justify-content-center'>
                                    {/* <Col sm={true} md={6}>
                                        <Form.Label style={{ font: 'normal normal calc(0.3rem + 0.55vw) Montserrat' }}>Canais</Form.Label>
                                        <SelectComponent
                                            className={"multiselectForm mb-3"}
                                            options={optionsChannel}
                                            setOptions={setOptionsChannel}
                                            selecteds={selectedChannel}
                                            setSelecteds={setSelectedChannel}
                                            singleSelect={true}
                                            title={"Selecione o canal"}
                                            overrideStrings={
                                                {
                                                    "allitemsAreSelected": "Selecione o canal de atendimento",
                                                    "clearSearch": "Limpar pesquisa",
                                                    "clearSelected": "Limpar canal selecionado",
                                                    "noOptions": "Sem canais",
                                                    "search": "Pesquisar canais",
                                                    "selectAll": "Selecionar todos os canais",
                                                    "selectAllFiltered": "Selecionar todos os canais (Filtrado)",
                                                    "selectSomeItems": "Selecione o canal",
                                                    "create": "Criar",
                                                }
                                            }
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            filtering={false}
                                            hasAllOption={false}
                                        />
                                    </Col> */}
                                    {/* <Col>
                                        <Form.Label style={{ font: 'normal normal calc(0.3rem + 0.55vw) Montserrat' }}>Número de WhatsApp</Form.Label>
                                        <SelectComponent
                                            className={"multiselectForm mb-3"}
                                            options={optionsPhoneNumber}
                                            setOptions={setOptionsPhoneNumber}
                                            selecteds={selectedPhoneNumber}
                                            setSelecteds={setSelectedPhoneNumber}
                                            singleSelect={true}
                                            title={"Selecione o número de WhatsApp"}
                                            overrideStrings={
                                                {
                                                    "allitemsAreSelected": "Selecione o número de WhatsApp",
                                                    "clearSearch": "Limpar pesquisa",
                                                    "clearSelected": "Limpar número selecionado",
                                                    "noOptions": "Pesquisar número",
                                                    "search": "Pesquisar número",
                                                    "selectAll": "Selecionar todos os bots",
                                                    "selectAllFiltered": "Selecionar todos os bots (Filtrado)",
                                                    "selectSomeItems": "Selecione o número WhatsApp",
                                                    "create": "Criar",
                                                }
                                            }
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            filtering={false}
                                            hasAllOption={false}
                                        />
                                    </Col> */}
                                </Row>
                                <Row style={allStyles.textFontGlay} className="mb-3">
                                        <Form.Label style={{ font: 'normal normal calc(0.3rem + 0.55vw) Montserrat' }}>{t('configurations_ticket.modal_quick_message_register.title')} <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="title"
                                            isInvalid={feedbackTitle}
                                            className="form-control-Default"
                                                placeholder={t('configurations_ticket.modal_quick_message_register.placeholder_title')}
                                                aria-label={t('configurations_ticket.modal_quick_message_register.placeholder_title')}
                                                aria-describedby={t('configurations_ticket.modal_quick_message_register.placeholder_title')}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            id="feedbackTitle"
                                        >
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Row>
                                <Row style={allStyles.textFontGlay}>
                                    <Form.Group>
                                            <Form.Label style={{ font: 'normal normal calc(0.3rem + 0.55vw) Montserrat' }}>{t('configurations_ticket.modal_quick_message_register.message')} <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                id="message"
                                                isInvalid={feedbackMessage}
                                                className="form-control-Default"
                                                    placeholder={t('configurations_ticket.modal_quick_message_register.placeholder_message')}
                                                    aria-label={t('configurations_ticket.modal_quick_message_register.placeholder_message')}
                                                    aria-describedby={t('configurations_ticket.modal_quick_message_register.placeholder_message')}
                                                as={"textarea"}
                                                // maxLength={400}
                                                style={{ maxHeight: "20vh", minHeight: "20vh", height: "20vh" }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid"
                                                id="feedbackMessage"
                                            >
                                                {errors.message}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        <Row>
                                            <Col>
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: "#005293", maxWidth: "690px", paddingLeft: "0px", marginTop: "20px" }}>
                                                        {t('configurations_ticket.modal_quick_message_register.hint_a')} <span style={{ fontWeight: "bolder" }}>{`{consumidor}`}</span> {t('configurations_ticket.modal_quick_message_register.hint_b')} <span style={{ fontWeight: "bolder" }}>{`{ticket}`}</span> {t('configurations_ticket.modal_quick_message_register.hint_c')}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Row>
                                <Row style={allStyles.textFontGlay}>
                                    <Form.Label htmlFor="documents" style={{ font: 'normal normal calc(0.3rem + 0.55vw) Montserrat' }}>{t("configurations_ticket.modal_quick_message_register.attachment")}</Form.Label>
                                    <div className='mb-2' style={allStyles.changeEditorDropzone}>
                                        <div {...getRootProps({ className: 'dropzone' })}
                                        style={allStyles.dragDropzone}>
                                        <input {...getInputProps({})} />
                                        {showFiles.length > 0 ?
                                            <div name="showFilesName" className="ps-2 py-1 pt-2">
                                            <div style={{ display: 'block' }}>
                                                {showFiles}
                                            </div>
                                            </div>
                                            :
                                            <div style={{ textDecoration: 'underline' }}>
                                            { t("configurations.chatbots.view.bot_info.drop_files") }
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    <div>
                                        {isVideo === true && <p style={{ color: "red", font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto' }}>{t('configurations_ticket.modal_quick_message_register.error_video')}</p>}
                                    </div>
                                </Row>
                                <Row className="d-flex justify-content-flex-start" style={{ justifyContent: "space-between", maxWidth: "50vw" }}>
                                    <Col xs={6} className="d-flex justify-content-start">
                                            <Button size="lg" className="buttonWhite" style={{ width: "65%" }} onClick={props.onHide}>{t('configurations_ticket.modal_quick_message_register.cancel')}</Button>
                                    </Col>
                                    <Col xs={6} className="d-flex justify-content-end">
                                            <Button size="lg" className="buttonBlue" style={{ width: "65%" }} type='submit' disabled={disabled}>{t('configurations_ticket.modal_quick_message_register.save')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        </Formik>                
                    </Container> 
                </Modal.Body>
            </Modal>
        </>
    )
}

export default QuickMessageRegister