import { useDispatch } from "react-redux"
import { AppRequesterController } from "../services/appRequester/appRequesterController"
import UserService from "../services/user-service"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { setShowAlertFeedback } from "../store/internal"
import { useTranslation } from "react-i18next"

const AppRequesterConst = new AppRequesterController()

const useNotificationDeleteRequest = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const headers = UserService.getHeaders()

    const deleteNotificationRequest = async (id: string) => {

        await AppRequesterConst.Delete(
            `notification/delete/${id}`, { headers },
            (response: Object) => { },
            (data) => {
                dispatch(setShowAlertFeedback({ message: `${t("modal_template_list_notification.notification_deleted_successfully")}`, visibility: true, signalIcon: true }))
            },
            (error) => {

            }, navigate, dispatch, setIsLoading
        )
    }

    return { deleteNotificationRequest, isLoading }
}

export default useNotificationDeleteRequest