import { FC, useContext } from "react";
import PhoneCallcenter from ".";
import { PhoneCallcenterControllerInterface } from "./indexModel";
import './styles.scss';
import { CallcenterContext } from "../../../../core/context/callcenter-context";
import ReceivingCall from "./components/ReceivingCall";


const PhoneCallcenterController: FC<PhoneCallcenterControllerInterface> = () => {
  const callcenterContext = useContext(CallcenterContext);

  return (
    <div className="phoneContainer">
      { callcenterContext.showPhoneCallcenter && <PhoneCallcenter /> }
      { !callcenterContext.showPhoneCallcenter && ['ringing', 'in-call'].includes(callcenterContext.callStatusEvent) && <ReceivingCall /> }
    </div>
  );
}

export default PhoneCallcenterController;
