import { FC, useEffect, useState } from "react";
import { IListTagsComponentController } from "./indexModel";
import { useDispatch, useSelector } from "react-redux";
import ListTagsComponent from ".";
import { TokenInterface } from "../../../../../../../services/requestsInterfacesModel";
import { getShowTemplateTagModal, setShowConfirmationModal, setShowTemplateTagModal } from "../../../../../../../store/internal";

const ListTagsComponentController: FC<IListTagsComponentController> = (props) => {
    const dispatch = useDispatch();

    const [dragged, setDragged] = useState<number | null>(null);
    // posição do mouse
    const [mouse, setMouse] = useState<[number, number]>([0, 0]);
    const [dropZone, setDropZone] = useState(0);
    const [selectedTag, setSelectedTag] = useState(null);

    const [showCreateTag, setShowCreateTag] = useState(false);
   
    const values: TokenInterface = {
        internal: {
            getShowTemplateTagModal: useSelector(getShowTemplateTagModal),
        }
    }

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            setMouse([e.x, e.y]);
        };

        document.addEventListener("mousemove", handler);

        return () => document.removeEventListener("mousemove", handler);
    }, []);
    
    useEffect(() => {
        if (dragged !== null) {
            // dropzones
            const elements = Array.from(document.getElementsByClassName("drop-zone-param"));
            // posição dos dropzones
            const positions = elements.map((e) => e.getBoundingClientRect().top);
            // distancia do mouse para a posição do dropzone
            const absDifferences = positions.map((v) => Math.abs(v - mouse[1]));

            // drozone mais próximo do mouse, é onde será soltado o item arrastado
            let result = absDifferences.indexOf(Math.min(...absDifferences));

            if (result > dragged) result += 1;

            setDropZone(result);
        }
    }, [dragged, mouse]);

    // ordenação correta da listagem pelo tag_number, remoção dos tag_number dos itens
    useEffect(() => {
        props.setInitTags((tags) => {
            if (tags?.length > 0) {
                let orderedTags = tags.slice();
                let haveTagNumbers = true;

                tags.map((item) => {
                    if (item.tag_number === null || item.tag_number === undefined)
                        haveTagNumbers = false;
                    else {
                        // remove tag_number
                        let newItem = {};
                        Object.keys(item).map((key) => {
                            if (key !== "tag_number")
                                newItem[key] = item[key];
                        });

                        orderedTags[item.tag_number - 1] = newItem;
                    }
                });
                
                // verifica se alguma tag possui um número, senão possuir significa que a lista foi ordenada
                if (haveTagNumbers)
                    return orderedTags;
                else 
                    return tags;
            }

            return tags;
        });
    }, [props.initTags]);

    // reordenando lista
    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (dragged !== null) {
                e.preventDefault();
                setDragged(null);

                props.setInitTags((params) => {
                    return reorderList(params, dragged, dropZone);
                });
            }
        };

        document.addEventListener("mouseup", handler);
        return () => document.removeEventListener("mouseup", handler);
    });
    
    // visibilidade da criação de tag
    useEffect(() => {
        if (values.internal.getShowTemplateTagModal) {
            setShowCreateTag(true);
        } else {
            setShowCreateTag(false);
        }
    }, [values.internal.getShowTemplateTagModal]);

    // puxando item para baixo
    const reorderListForward = <T,>(l: T[], start: number, end: number) => {
        const temp = l[start];
      
        for (let i=start; i<end; i++) {
          l[i] = l[i+1];
        }
        l[end - 1] = temp;
      
        return l;
    };

    // puxando item para cima
    const reorderListBackward = <T,>(l: T[], start: number, end: number) => {
        const temp = l[start];
      
        for (let i = start; i > end; i--) {
          l[i] = l[i - 1];
        }
      
        l[end] = temp;
      
        return l;
    };

    // selecionando direção da reordenação do item
    const reorderList = <T,>(l: T[], start: number, end: number) => {
        if (start < end) 
            return reorderListForward([...l], start, end);
        else if (start > end) 
            return reorderListBackward([...l], start, end);

        return l;
    };

    const clickDown = () => {
        let start = null;
        props.initTags.map((item, index) => {
            if ((item.alias || item.id) === selectedTag)
                start = index;
        });
        

        props.setInitTags((params) => {
            if (start && start < params.length - 1) {
                let temp = params[start];
                params[start] = params[start + 1];
                params[start + 1] = temp;
            }

            return params;
        });
    }

    const clickUp = () => {
        let start = null;
        props.initTags.find((item, index) => {
            if ((item.alias || item.id) === selectedTag)
                start = index;
        });

        if (start && start > 0)
            props.setInitTags((params) => {
                let temp = params[start];
                params[start] = params[start - 1];
                params[start - 1] = temp;

                return params;
            });
    }

    const deleteTagConfirmation = () => {
        dispatch(setShowConfirmationModal({
            visibility: true, text: { "title": "Excluir Variável", "body": "Tem certeza que deseja excluir a variável ", "id": selectedTag + '?', "buttonReturnText": "Cancelar" },
            functionConfirmation: () => deleteTag()
        }));

        /* let aux = [];
        aux.push(this.currentTicketId.split("*ID*")[1]);
        this.setDeleteListTickets(aux); */
    }

    const deleteTag = () => {
        props.setInitTags((tags) => {
            return tags.filter((tag) => (tag.alias || tag.id) !== selectedTag);
        });
    }

    const hideCreateParamModal = () => {
        dispatch(setShowTemplateTagModal(false));
        setShowCreateTag(false);
    }

    return <ListTagsComponent 
        initTags={props.initTags}
        setInitTags={props.setInitTags}
        dragged={dragged}
        setDragged={setDragged}
        mouse={mouse}
        dropZone={dropZone}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag} 
        clickDown={clickDown} 
        clickUp={clickUp}    
        showCreateTagModal={showCreateTag}
        hideCreateTagModal={hideCreateParamModal}
        deleteTagConfirmation={deleteTagConfirmation}
    />
}

export default ListTagsComponentController;