import React from 'react';

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';

import { setShowAlertFeedback } from '../../../../store/internal'
import { verifyCode } from '../../../../services/codeCxpressInterface';
import SelectComponentController from '../selectComponent/indexController';

const ModalEditEmail = ({
  modalShow,
  Hide,
  validated,
  setIsLoading,
  options_departments,
  setOptions_departments,
  selected_departments,
  setSelected_departments,
  email_isActive,
  setEmail_isActive,
  email_id,
  setCurrentEmail,
  showfeedbackEmail,
  Validate,
  AppRequesterConst,
  props,
  dispatch,
  navigate,
  t,
}) => {

  return (
    <Modal show={modalShow} onHide={Hide} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {t("home_channels.modals.edit_email_modal.modal_title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{
              email: (props.data && props.data.email) ? props.data.email : ""
            }}
            validate={values => {
              setCurrentEmail(values.email);
              return Validate(values)
            }}

            onSubmit={async () => {
              
              let JsonSend_sector = {
                "active": email_isActive,
                "id": email_id,
              }
              // if (selected_departments && selected_departments.length >= 1) {
              //   JsonSend_sector["sector_id"] = selected_departments[0].value;
              // }

              if (selected_departments[0]?.value !== null && selected_departments.length >= 1) {
                JsonSend_sector["sector_id"] = selected_departments[0].value;
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await AppRequesterConst.Put(
                '/company/email', JsonSend_sector, { headers },
                (response: Object) => {
                  return response;
                },
                (data: { data: { id: string; }; }) => {
                  dispatch(setShowAlertFeedback({ message: t("home_channels.modals.edit_email_modal.information_save_success"), visibility: true, signalIcon: true }));
                  props.callback(data.data.id);
                  Hide();
                },
                (error: {response:{data:{code_cxpress:number}}}) => {
                  dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                }, navigate, dispatch, setIsLoading
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="email">{t("home_channels.modals.edit_email_modal.email_address")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={showfeedbackEmail}
                        placeholder={t("home_channels.modals.edit_email_modal.report_email_address")}
                        aria-label={t("home_channels.modals.edit_email_modal.report_email_address")}
                        aria-describedby={t("home_channels.modals.edit_email_modal.report_email_address")}
                        size='lg'
                        type='email'
                        id="email"
                        name="email"
                        autoComplete='email'
                        maxLength={100}
                        disabled={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackemail">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_channels.modals.edit_email_modal.email_sector")}</Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      className={"multiselectForm mb-3"}
                      options={options_departments}
                      setOptions={setOptions_departments}
                      selecteds={selected_departments}
                      setSelecteds={setSelected_departments}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t("home_channels.modals.edit_email_modal.select_email_sector")}
                      overrideStrings={{
                        "allItemsAreSelected": `${t("home_channels.modals.edit_email_modal.all_items_are_selected")}`,
                        "clearSearch": `${t("home_channels.modals.edit_email_modal.clear_search")}`,
                        "clearSelected": `${t("home_channels.modals.edit_email_modal.clear_selected")}`,
                        "noOptions": `${t("home_channels.modals.edit_email_modal.no_options")}`,
                        "search": `${t("home_channels.modals.edit_email_modal.search")}`,
                        "selectAll": `${t("home_channels.modals.edit_email_modal.select_all")}`,
                        "selectAllFiltered": `${t("home_channels.modals.edit_email_modal.select_all_filtered")}`,
                        "selectSomeItems": `${t("home_channels.modals.edit_email_modal.select_some_items")}`,
                        "create": `${t("home_channels.modals.edit_email_modal.create")}`
                      }}
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                    />
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                  <Col sm={true} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="basic-url">{t("home_channels.modals.edit_email_modal.active_email")} &nbsp; &nbsp;</Form.Label>
                    <Form.Check name="radioIsAdministrator" label={t("home_channels.modals.edit_email_modal.no")} type={'radio'} checked={!email_isActive} onChange={() => { setEmail_isActive(false) }} inline></Form.Check>
                    <Form.Check name="radioIsAdministrator" label={t("home_channels.modals.edit_email_modal.yes")} type={'radio'} checked={email_isActive} onChange={() => { setEmail_isActive(true) }} inline></Form.Check>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-4">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => Hide()}>{t("home_channels.modals.edit_email_modal.cancel")}</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t("home_channels.modals.edit_email_modal.save")}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditEmail;
