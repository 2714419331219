import { useSelector } from 'react-redux'
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
  ListGroup
} from "react-bootstrap"
import { getShowEditTypeAgent, getShowEditPaswordAgent, getShowRecoverPasswordAgente } from "../../../../store/internal";
import EditAgentType from "./modalEditTypeAgent/indexController"
import EditAgentPassword from "./modalEditAgentPassword/indexController"
import RecoverAgentPassword from "./modalRecoverAgentPassword/indexController"
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../../components/loading';

const FormConfigAgents = ({
  props,
  popover,
  setCurrentAgentInfo,
  constsApi,
  values,
  currentAgent,
  isEditAllowed,
  updateListfunction,
  agentData,
  callbackAgents,
  company_id,
}) => {

  return (
    <>
      <EditAgentType token={values.token.value} data={currentAgent} visibility={useSelector(getShowEditTypeAgent)} callback={callbackAgents}></EditAgentType>
      <EditAgentPassword token={values.token.value} data={currentAgent} visibility={useSelector(getShowEditPaswordAgent)} callback={callbackAgents} company_id={company_id}></EditAgentPassword>
      <RecoverAgentPassword token={values.token.value} data={currentAgent} visibility={useSelector(getShowRecoverPasswordAgente)} callback={callbackAgents}></RecoverAgentPassword>
      <Row className="noppadding" >
        <Col style={{ color: '#000', font: 'normal normal bold 18px Montserrat', paddingRight: "20px" }}
          md={5}
          className="mt-2"
        >
          Configuração de agentes
        </Col>
      </Row>
      <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '4px', marginBottom: '8px' }}></div>
      <ListGroup variant="flush">
        <ListGroup.Item className="TicketsRowCollums">
          <Row name="ListOfAgentsHead" >
            <Col md={4} className="d-flex align-self-center ">
              <p>Nome</p>
            </Col>
            <Col md={4} className="nopadding">
              <p>E-mail</p>
            </Col>
            <Col md={2} className="nopadding">
              <p>Tipo</p>
            </Col>
            <Col md className='d-flex justify-content-end'>
              {isEditAllowed && <p>Ações</p>}
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
      {
        agentData !== undefined ?
          <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
            <InfiniteScroll
              dataLength={agentData.length}
              next={updateListfunction}
              hasMore={true}
              loader={<h4> </h4>}
              scrollableTarget={"ListOfAgents"}
            >

              {
                <ListGroup variant="flush" className="TicketsRow">
                  {agentData.map((item, index) => {
                    return (
                      <ListGroup.Item key={index} style={{ color: '#707070' }}>
                        <Row className='TicketsRowHeight'>
                          <Col md={4} className='d-flex align-self-center' style={{ cursor: 'pointer' }}>
                            <div className="oneLineClampEllipsis">
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    {item?.name != null ? <>{item?.name}</> : <>N/A</>}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {item.name ? item.name : <>N/A</>}
                                </span>
                              </OverlayTrigger>
                            </div>
                          </Col>

                          <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                            <div className="oneLineClampEllipsis">
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    {item.email != null ?
                                      <>{item.email}</>
                                      :
                                      <>N/A</>
                                    }
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {item.email ? item.email : <>N/A</>}
                                </span>
                              </OverlayTrigger>
                            </div>
                          </Col>

                          <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                            <div className="oneLineClampEllipsis ">
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    {item.profile_id === constsApi.profile[1].id ?
                                      <>Agente</>
                                      :
                                      item.profile_id === constsApi.profile[0].id ?
                                        <>Administrador</>
                                        :
                                        item.profile_id === constsApi.profile[2].id ?
                                          <>Proprietário</>
                                          :
                                          "N/A"
                                    }
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {item.profile_id === constsApi.profile[1].id ?
                                    <>Agente</>
                                    :
                                    item.profile_id === constsApi.profile[0].id ?
                                      <>Administrador</>
                                      :
                                      item.profile_id === constsApi.profile[2].id ?
                                        <>Proprietário</>
                                        :
                                        "N/A"
                                  }
                                </span>
                              </OverlayTrigger>
                            </div>
                          </Col>

                          <Col md className='d-flex justify-content-end' >
                            {
                              isEditAllowed &&
                              <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                <Button onClick={() => {setCurrentAgentInfo(item)}} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="1" />
                                    <circle cx="12" cy="19" r="1" />
                                    <circle cx="12" cy="5" r="1" />
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            }
                          </Col>
                          
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              }
            </InfiniteScroll>
          </div>
          :
          <></>
      }
    </>
  )
}

export default FormConfigAgents