import React, {useState, FC,  useEffect} from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import LinksConstants from '../../../../core/constants/links-contants';
import { getSubdomainCompany } from '../../../../store/company';
import { getNameUser } from '../../../../store/user';
import { AcquirePlatformNotificationBarInterface } from './indexModel';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { AcquirePlatformNotificationBarController } from './indexController';
import { t } from 'i18next';


const AcquirePlatformNotificationBar: FC<AcquirePlatformNotificationBarInterface> = (props) => {
    const [visibility, setVisibility] = useState(props.visibility);
    const [limitDays, /*setLimitDays*/] = useState(props.limitDays);

    const AcquirePlatformNotificationBarControllerConst = new AcquirePlatformNotificationBarController(visibility);

    useEffect(() => {
        setVisibility(props.visibility);
    }, [props.visibility]);
    
    const values: TokenInterface = {
        user: {
            name: useSelector(getNameUser),
        },
        company: {
            subdomain: useSelector(getSubdomainCompany),
        }
    }

    return (
        <div style={{display: AcquirePlatformNotificationBarControllerConst.showNotificationBar(), marginRight: '-1vw'}}>
            <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>{t("endTrial.acquire_platform")}</Tooltip>}
                    >
                <a target="_blank"  rel="noreferrer" href={LinksConstants.homeBuild(values.user.name, values.company.subdomain)} style={{textDecoration: 'none'}}>
                    <div className="d-flex align-items-center" style={{background: '#E5ECFF 0% 0% no-repeat padding-box', height: 'max(35px, 3.2vh)', opacity: '1', cursor: 'pointer'}}>
                        <div className="ms-4">
                            <span 
                                style={{
                                    textAlign: 'left',
                                    font: 'var(--px15_19-font)',
                                    letterSpacing: '0px',
                                    color: '#0C2461',
                                }}
                            >{t("endTrial.endTrialMessage_part_a")}</span>

                            <span
                                style={{
                                    textAlign: 'left',
                                    font: 'var(--px15_19_Bold-font)',
                                    letterSpacing: '0px',
                                    color: '#4A69BD',
                                }}
                            > {limitDays}</span>
                            <span style={{
                                    textAlign: 'left',
                                    font: 'var(--px15_19_Bold-font)',
                                    letterSpacing: '0px',
                                    color: '#4A69BD',
                                }}> {t("endTrial.endTrialMessage_part_b")}</span>
                        </div>
                    </div>
                </a>
            </OverlayTrigger>
        </div>
    );
}

export default AcquirePlatformNotificationBar;