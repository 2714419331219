import { ListGroup, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ButtonOrderByList from "../../../components/buttonOrderByList";
import Utils from "../../../../../core/shared/utils";
import { ListName } from "../../../../../core/enums/order-sort-by";
import { IconBrandFacebook, IconBrandInstagram } from "@tabler/icons-react";
import { IListOfFacebook } from "./indexModel";
import { FC } from "react";
import ModalEditFacebookController from "./modalEditFacebook/indexController";

const ListFacebook: FC<IListOfFacebook> = (props) => {
    return (
        <>
            <div className="ps-0 ms-0" style={{ marginTop: '2vh', marginLeft: '2vh' }}>
                <ModalEditFacebookController 
                    isFacebookPage={props.activeFacebook ? true : false} 
                    data={props.data}  
                    updateListAfterEdit={props.updateListAfterEdit}              
                />
                <ListGroup variant="flush" className="TicketsRow">
                    {props.datas !== undefined && props.datas?.length > 0 ?
                        <>
                            <ListGroup.Item className="TicketsRowCollums">
                                <Row name="listOfFacebooksHead">
                                    <Col md={2} className="nopadding">
                                        {props?.activeFacebook === true &&
                                            <span className="me-1" >{props.t("home_channels.lists_channels.list_facebook.associate_pages")}</span>
                                        }
                                        {props.activeInstragram === true &&
                                            <span className="me-1">{props.t("home_channels.lists_channels.list_facebook.associate_pages")}</span>
                                        }
                                    </Col>
                                    <Col md={4} className="d-flex justify-content-center">
                                        <ButtonOrderByList title="Status" columnIdName='is_active' callbackOrderBy={props.callbackOrderBy} />
                                    </Col>
                                    <Col md={3} className="nopadding">
                                        <span className="mx-1">{props.t("home_channels.lists_channels.list_facebook.sector")}</span>
                                    </Col>
                                    <Col /* md={3} */ className="d-flex justify-content-end" >
                                        <p>{props.t("home_channels.lists_channels.list_facebook.actions")}</p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <div id={props.activeFacebook === true ? "listOfFacebooks" : "listOfInstagram"} style={{ overflowY: 'auto', height: '58vh' }}>
                                <InfiniteScroll
                                    dataLength={props.datas.length}
                                    next={props.updateListFunction}
                                    hasMore={props.HasMoreInformation as boolean}
                                    loader={<h4> </h4>}
                                    scrollableTarget={props.activeFacebook === true ? "listOfFacebooks" : "listOfInstagram"}
                                    onScroll={() => { props.activeFacebook === true ? Utils.hidePopoverOnScroll(ListName.ListOfFacebooks) : Utils.hidePopoverOnScroll(ListName.listOfInstagram) }}
                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow">
                                            {
                                                props?.datas?.map((item, index) => {
                                                    return (
                                                        <ListGroup.Item style={{ color: '#707070' }} id={item?.id} key={"keyAll" + index?.toString()}>
                                                            <Row className="TicketsRowHeight">
                                                                {/* {props.activeFacebook === true && */}
                                                                <Col md={2} className='align-self-center nopadding' style={{ /* width: '43%' */ }} >
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item?.pageName}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span> {item?.pageName} </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4} className='d-flex align-self-center justify-content-center nopadding' style={{ cursor: 'pointer' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                {item?.isActive ?
                                                                                    <>{props.t("home_channels.lists_channels.list_facebook.active")}</> : <>{props.t("home_channels.lists_channels.list_facebook.inactive")}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <div style={{ width: '33%' }}>
                                                                            {item?.isActive ?
                                                                                <div className="d-flex justify-content-center py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                                        borderRadius: '3px',
                                                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                        letterSpacing: '0px',
                                                                                        color: '#0C2461'
                                                                                    }}>
                                                                                    {props.t("home_channels.lists_channels.list_facebook.active")}
                                                                                </div>
                                                                                :
                                                                                <div
                                                                                    className="d-flex justify-content-center py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#FFD4B0 0% 0% no-repeat padding-box',
                                                                                        borderRadius: '3px',
                                                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                        letterSpacing: '0px',
                                                                                        color: '#FF8119'

                                                                                    }}
                                                                                >
                                                                                    {props.t("home_channels.lists_channels.list_facebook.inactive")}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </OverlayTrigger>

                                                                </Col>
                                                                <Col md={3} className='align-self-center nopadding mx-1' >
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    { item?.sector?.name? item.sector.name: "N/A" }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span> { item?.sector?.name? item.sector.name: "N/A" } </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col /* md={3} */ className='d-flex justify-content-end'>
                                                                    {item?.pageName ?
                                                                        props.activeInstragram === true ?
                                                                            <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.popover(item?.isActive, item?.pageId, () => props.activeAndDisableRequest(item, 'instagram'), item?.userId, item)}>
                                                                                <Button id={item?.id} name={item?.email} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                        <circle cx="12" cy="12" r="1" />
                                                                                        <circle cx="12" cy="19" r="1" />
                                                                                        <circle cx="12" cy="5" r="1" />
                                                                                    </svg>
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                            :
                                                                            <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.popover(item?.isActive, item?.pageId, () => props.activeAndDisableRequest(item, 'facebook'), item?.userId, item)}>
                                                                                <Button id={item?.id} name={item?.email} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                        <circle cx="12" cy="12" r="1" />
                                                                                        <circle cx="12" cy="19" r="1" />
                                                                                        <circle cx="12" cy="5" r="1" />
                                                                                    </svg>
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    )
                                                })
                                            }
                                        </ListGroup>
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '42vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1} className="d-flex justify-content-center">
                                    {
                                        props.activeInstragram == true ?
                                            <IconBrandInstagram size={80} color="#4a69bd" />
                                            :
                                            <IconBrandFacebook size={80} color="#4a69bd" />
                                    }
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ margin: 'auto', backgroundColor: '#F9F9F9' }}>
                                <Col md={4} className="d-flex justify-content-center align-content-center">
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{props.t("home_channels.lists_channels.no_results_pages")}</p>
                                </Col>
                            </Row>
                        </div>
                    }
                </ListGroup>
            </div>
        </>
    )
}

export default ListFacebook