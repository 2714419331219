import ForgotPasswordControlPanel from ".";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppRequesterController } from "../../../services/appRequester/appRequesterController";
import constsRouters from "../../../routes/constsRouter";
import { setShowAlertFeedback } from "../../../store/internal";

const AppRequesterConst = new AppRequesterController();

const ForgotPasswordControlPanelController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null as string || null);
  const [isLoading, setIsLoading] = useState(false);
  const [scene, setScene] = useState<'email'|'send-success'>('email');
  const [showFeedbackEmail, setShowFeedbackEmail] = useState(false);

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    document.title = `Painel Cxpress | Esqueci a senha`;
  }, []);

  const submitForgotPassword = async () => {
    if (validated) {
      const body = { email };

      await AppRequesterConst.Post(
        '/panel/request-reset-password-panel', body, { },
        (response: Object) => { },
        (response: any) => {
          if(response.data !== undefined) {
            console.log(response.data);
            setScene('send-success');
          }
        }, 
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: 'Falha ao tentar recuperar senha', visibility: true, signalIcon: false }));
          if (error.response !== undefined) {
            console.log(error.response);
          }
        },
        navigate, dispatch, setIsLoading,
      );
    }
  }

  const validateForgotPassword = (values: { email: string }) => {
    const errors = {};

    setEmail(values.email);

    const regex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i);
    if (!values.email) {
      errors['email'] = 'Preencha este campo';
      setShowFeedbackEmail(true);
    } else if (!regex.test(values.email)) {
      errors['email'] = 'E-mail inválido';
      setShowFeedbackEmail(true);
    } else {
      setShowFeedbackEmail(false);
    }

    setValidated(!errors['email']);
    
    return errors;
  }

  const goToLogin = () => {
    navigate(constsRouters.routers.home.path);
  }

  return (
    <ForgotPasswordControlPanel 
      isLoading={isLoading}
      submitForgotPassword={submitForgotPassword}
      validateForgotPassword={validateForgotPassword}
      showFeedbackEmail={showFeedbackEmail}
      validated={validated}
      scene={scene}
      goToLogin={goToLogin}
    />
  );
};

export default ForgotPasswordControlPanelController;
