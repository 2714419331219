import { AxiosRequestConfig } from "axios";
import Api from "../api";
import constsApi from "../constsApi";
import { ErrorsInterface } from "../errorsInterfaceModel";
import { setForceLogout, setShowAcquirePlatformComponent } from "../../store/internal";
import { NavigateFunction } from "react-router-dom";
import { ContractStatus } from "../../core/enums/contract-status";
import { setNameUser } from "../../store/user";
import { setSubdomainCompany } from "../../store/company";
import { ErrorRequestController } from "../errorsInterfaceModel";
import { AppRequesterControlPanelInterface } from "./appRequesterControlPanelModel";

export class AppRequesterControlPanelController implements AppRequesterControlPanelInterface {
    Get = async (url: string, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        if (setIsLoading)
            setIsLoading(true);
        await Api.get(url, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            if (setIsLoading)
                setIsLoading(false);
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            if (setIsLoading)
                setIsLoading(false);
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error400(error);
                        break;
                    case constsApi.errors.error401.number:
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error403.number:
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error422(error);
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error404(error);
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }

    Post = async (url: string, data: Object, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        setIsLoading(true)
        await Api.post(url, data, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            setIsLoading(false)
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            setIsLoading(false)
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error400(error);
                        break;
                    case constsApi.errors.error401.number:
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error422(error);
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error404(error);
                        break;
                    case constsApi.errors.error403.number:
                        errorsFunctions.error403();
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error500.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error500(data);
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }

    Put = async (url: string, data: Object, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        setIsLoading(true)
        await Api.put(url, data, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            setIsLoading(false)
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            setIsLoading(false)
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error400(error);
                        break;
                    case constsApi.errors.error401.number:
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error403.number:
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error422(error);
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error404(error);
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }

    Delete = async (url: string, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        setIsLoading(true)
        await Api.delete(url, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            setIsLoading(false)
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            setIsLoading(false)
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error400(error);
                        break;
                    case constsApi.errors.error401.number:
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error403.number:
                        dispatch(setForceLogout(true));
                        break;
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error422(error);
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined)
                            errorsFunctions.error404(error);
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }
}