import { useTranslation } from "react-i18next";
import ModalCC_CCO_Update from ".";
import { IModalCC_CCO_UpdateController } from "./indexModel";
import { FC, useContext, useEffect, useState } from "react";
import { EmailContext } from "../../../../../../../core/context/cc-cco-context";
import UserService from "../../../../../../../services/user-service";
import { AppRequesterController } from "../../../../../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../../../store/token";

const ModalCC_CCO_UpdateController: FC<IModalCC_CCO_UpdateController> = (props) => {
    const { t } = useTranslation();
    const email = useContext(EmailContext);
    const appRequesterConst = new AppRequesterController();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [requesterEmail, setRequesterEmail] = useState("");
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [emailError, setEmailError] = useState("");

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken),
        }
    }

    useEffect(() => {
        if (props.currentSelectedConsumer?.email) {
            setRequesterEmail(props.currentSelectedConsumer.email);
        } else {
            setRequesterEmail("");
        }
    }, [props.currentSelectedConsumer]);

    const onChangeEmailCC = (e: { detail: { tagify: { getCleanValue: () => any[] } } }) => {
        email.setEmailCC(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value
        }))
    }

    const onChangeEmailCCO = (e: { detail: { tagify: { getCleanValue: () => any[] } } }) => {
        email.setEmailCCO(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value
        }))
    }

    const saveEmails = async () => {
        props.handleClose();
        props.updateEmails();
    }

    const validateEmail = () => {
        if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(requesterEmail)) || requesterEmail === "") {
            setEmailIsInvalid(true);
            setEmailError(t('home_consumers.modal_edit.validate.email'));
        } else {
            setEmailIsInvalid(false);
        }
    }

    const onChangeEmail = (e) => {
        let email = e.target.value;
        setRequesterEmail(email);
    }

    return <ModalCC_CCO_Update 
        show={props.show}
        handleClose={props.handleClose}
        currentTicket={props.currentTicket}
        t={t}
        onChangeEmailCC={onChangeEmailCC}
        onChangeEmailCCO={onChangeEmailCCO}
        saveEmails={saveEmails}
        requesterEmail={requesterEmail}
        onChangeEmail={onChangeEmail}
        validateEmail={validateEmail}
        emailIsInvalid={emailIsInvalid}
        emailError={emailError} 
    />
}

export default ModalCC_CCO_UpdateController;