import { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';

import { getShowSendTicketCopyModal, setShowEditTicketsModal, setShowSendTicketCopyModal } from '../../../../../store/internal'

import Utils from '../../../../../core/shared/utils';
import { Ticket } from '../../../../../domain/entities/ticket';

import { ListChatbotInterface } from './indexModel';

import { useTranslation } from 'react-i18next';
import ListOfChatbot from '.';
import { EventParentElementInterface } from '../../../components/messagesComponent/indexModel';

const ListOfChatbotController: FC<ListChatbotInterface> = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [tickets, setTickets] = useState(null);
    const [currentStatusPage, setCurrentStatusPage] = useState("");
    const [currentTicketId, setCurrentTicketId] = useState("");
    const [ticketModal, setTicketModal] = useState(null);
    const showSendTicketCopyModal = useSelector(getShowSendTicketCopyModal)

    useEffect(() => {
        if(showSendTicketCopyModal) {
            dispatch(setShowSendTicketCopyModal(false));
        }
    }, []);

    useEffect(() => {
        if (props.datas !== undefined) {
            if (props.statusPage !== currentStatusPage) {
                setCurrentStatusPage(props.statusPage);
            }
            if (props.datas && !tickets) {
                setTickets(props.datas);
            } else if (tickets) {
                setTickets(props.datas);
            }
        } else {
            setTickets(undefined);
        }
    }, [props.datas as Ticket[]]);

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfTickets");
    }, [tickets]);

    const showEditModal = (e: { currentTarget: { parentElement: { parentElement: { id: string; }; }; }; }) => {
        props.setCurrentTicketFunction(e.currentTarget.parentElement.parentElement.id);
        props.updateListfunction();
        dispatch(setShowEditTicketsModal(true));
    }
    const showSendTicketCopyModalFunction = (e: EventParentElementInterface) => {
        setShowSendTicketCopyModal(e.currentTarget.parentElement.parentElement.id.split("*ID*")[1]);
        dispatch(setShowSendTicketCopyModal(true));
    }
    const selectModalTicket = (event, data) => {
        setTicketModal(data);
        showSendTicketCopyModalFunction(event);
    }
    const formatedDate = (date: Date) => {
        const day = Utils.addLeftZeros(date.getDate());
        const month = Utils.addLeftZeros(date.getMonth() + 1);
        const year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    const setCurrentTicketInfo = (e: { currentTarget: {id: string }; }) => {
        setCurrentTicketId(e.currentTarget.id);
        
    }

    return <ListOfChatbot
        isLoading={isLoading}
        tickets={tickets}
        props={props}
        t={t}
        showEditModal={showEditModal}
        formatedDate={formatedDate}
        currentTicketId={currentTicketId}
        setCurrentTicketInfo={setCurrentTicketInfo}
        selectModalTicket={selectModalTicket}
        ticketModal={ticketModal}
        showSendTicketCopyModal={showSendTicketCopyModal}
    />
}

export default ListOfChatbotController