import React, { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux'

import allStyles from './styles';

import { setShowConfirmationModal } from '../../store/internal'

import { Modal, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ModalConfirmationInterface } from './indexModel';
import { modalConfirmationController} from './indexController';
import { t } from 'i18next';

const ModalConfirmation: FC<ModalConfirmationInterface> = (props) => {
  const [modalShow, setModalShow] = useState(props.visibility);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const dispatch = useDispatch();

  const modalConfirmationControllerConst = new modalConfirmationController(props,setModalShow,dispatch,setShowConfirmationModal);

  useEffect(() => {
    if (props.text.closeButton === true || props.text.closeButton === undefined) {
      setShowCloseButton(true);
    } else {
      setShowCloseButton(false);
    }
  }, [props.text.closeButton]);

  useEffect(() => {
    setModalShow(props.visibility);
  }, [props.visibility])

  const replaceJSX = (value: string, find: string, replace: any) => {
    return value.split(find).flatMap((item) => [item, replace]).slice(0, -1);
  }
  const changeText = (obj) => {
    const t3 = replaceJSX(obj.body, '#####', <strong>{obj.replaceText}</strong>)
    return t3;
  }
  
  return (
    <Modal show={modalShow} onHide={()=>modalConfirmationControllerConst.Hide()} centered backdrop={ showCloseButton? true: 'static' } keyboard={ showCloseButton? true: false } >
      <Modal.Header style={{ padding: '1rem 1rem 0rem 1rem' }} closeButton={ showCloseButton? true: false }>
      </Modal.Header>
      <Modal.Header style={{ padding: '0rem 1.45rem' }}>
        <Modal.Title className="mt-2" style={allStyles.textBlack}>
          {props.text.title}
          <p className="mt-2" style={allStyles.textFontGlay}>
            {props.text.replaceText ? changeText(props.text) : props.text.body}
            {props.text.id && <span style={allStyles.textBlackSec}>{props.text.id}</span>}
            {props.text.message && <span style={allStyles.textBlackSec}>{props.text.message}</span>}
            {props.text.middleText && <span style={allStyles.textFontGlay}>{props.text.middleText}</span>}
            {props.text.warning && <span style={{...allStyles.textBlackSec, ...allStyles.spaceLeft}}>{props.text.warning}</span>}
            {props.text.end && <span style={allStyles.textFontGlay}>{props.text.end}</span>}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Row className="mt-3" style={{ justifyContent: 'space-between' }}>

          {props.text.twoButton !== undefined ?
            <>
              <Col md={5}>
                <Button onClick={() => modalConfirmationControllerConst.Confirmation2()} style={{ width: '100%' }} id="buttonDeleteAllSelected" size='lg' className="buttonWhite2" >{props.text.twoButton}</Button>
              </Col>
            </>
            :
            <></>
          }
          {props.text.buttonReturnText === undefined ?
            <></>
            :
            <>
              <Col md={5}>
                <Button onClick={()=>modalConfirmationControllerConst.Cancellation()} style={{ width: '100%' }} id="buttonDeleteAllSelected" size='lg' className="buttonWhite2" >{props.text.buttonReturnText}</Button>
              </Col>
              <Col md={2}>

              </Col>
            </>
          }

          {props.text.buttonReturnText === undefined ?
            props.text.isExport && props.text.isExport === true ?
              <Col md={5} className="d-flex justify-content-end align-self-end">
                <Button onClick={() => modalConfirmationControllerConst.Confirmation()} style={{ height: '100%' }} id="buttonDeleteAllSelected" size='lg' className="buttonBlue2WithoutBold" >{props.text.buttonConfirmationText !== undefined ? props.text.buttonConfirmationText : t('modal_template_list_notification.delete') }</Button>
              </Col>
              :
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col md={5} className="d-flex justify-content-end align-self-end">
                  <Button onClick={() => modalConfirmationControllerConst.Confirmation()} style={{ height: '100%' }} id="buttonDeleteAllSelected" size='lg' className="buttonBlue2WithoutBold" >{props.text.buttonConfirmationText !== undefined ? props.text.buttonConfirmationText : t('modal_template_list_notification.delete') }</Button>
                </Col>
              </Row>
            :
            <Col md={5} className="d-flex justify-content-end align-self-end">
              <Button onClick={()=>modalConfirmationControllerConst.Confirmation()} style={{ height: '100%' }} id="buttonDeleteAllSelected" size='lg' className="buttonBlue2WithoutBold" >{props.text.buttonConfirmationText !== undefined ? props.text.buttonConfirmationText : t('modal_template_list_notification.delete') }</Button>
            </Col>
          }
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ModalConfirmation;
