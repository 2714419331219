/*
 * Enumerador que contém os tipos de status onde um ticket pode estar. 
 */

import { t } from 'i18next';

export enum TicketStatus {
    Open = 'b58c1105-0ffe-4d07-a7d6-751389fc3008',
    Resolved = '2b45ba71-fa68-4ce5-bf16-14f31d43ba1a',
    Pending = '0ec35dbb-398e-4c2f-996c-933af162c950',
    OnHold = '3dd9b9ac-7399-4ce8-b22c-a513eb473803',
    New = 'daddbd66-1dd2-4008-94c9-7fcb9b1a5800',
    Abandoned = "2f955a38-e8cf-4033-bf6c-c5e4f68c8ea9",
    Overdue = "b0b0b0b0-b0b0-b0b0-b0b0-b0b0b0b0b0b0",
    OverdueTicketTask = "325434f8-836a-4e19-824f-8f35b4d40a5d",
    NotAssigned = 'N/A',
}

export const TicketStatusMultiselectOptions = [
    { label: t('ticket_status.new'), value: TicketStatus.New }, 
    { label: t('ticket_status.open'), value: TicketStatus.Open },
    { label: t('ticket_status.pending'), value: TicketStatus.Pending },
    { label: t('ticket_status.on_hold'), value: TicketStatus.OnHold },
    { label: t('ticket_status.resolved'), value: TicketStatus.Resolved },
];
