import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MultifactorTab from ".";

const MultifactorTabController = (props: any) => {

    const [availableAuth, setAvailableAuth] = useState([]);
    const [currentAuth, setCurrentAuth] = useState({});
    // const allowedAuthentications = ['e0e66fd2-e3e8-4217-ab25-85d6c30dec86', '810de59e-729d-48de-bb82-0dd4b29817ba']; // Email, App
    const allowedAuthentications = ['Email', 'Google Authenticator']; // Email, App
    const { t } = useTranslation();

    useEffect(() => {
        const fetchAvailable = async () => {
            const data = await props.getAvailableAuthentications();
            setAvailableAuth(data);
        }
        fetchAvailable();
        setCurrentAuth(processCurrent(props.companyAuth));
    }, []);

    const changeStatus = async (value: boolean, id: string) => {
        if (!props.isLoading) {
            const temp = { ...currentAuth };
            temp[id] = value;
            const ids = [];
            Object.keys(temp).forEach(key => {
                if (temp[key]) {
                    ids.push(key);
                }
            });
            const success = await props.setAuthentication(ids.length > 0, ids);
            if (success) {
                setCurrentAuth(temp);
            }
        }
    }

    const processCurrent = (current: any[]) => {
        const temp = {};
        availableAuth?.forEach(item => {
            temp[item.id] = false;
        });

        if (current?.length > 0) {
            current.forEach(item => {
                temp[item.id] = true;
            });
        }

        return temp;
    }

    const getAuthStatus = (id): boolean => {
        return currentAuth[id];
    }

    return <MultifactorTab
        allowedAuthentications={allowedAuthentications}
        t={t}
        isLoading={props.isLoading}
        availableAuth={availableAuth}
        getAuthStatus={getAuthStatus}
        changeStatus={changeStatus}
    />

};

export default MultifactorTabController;