import { FC, useState } from "react"
import General from "."
import UserService from "../../../../../services/user-service"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import { TokenInterface } from "../../../../../services/requestsInterfacesModel"
import { useDispatch, useSelector } from "react-redux"
import { getIdCompany } from "../../../../../store/company"
import { setShowAlertFeedback } from "../../../../../store/internal"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { BotxpressGeneralTabController } from "./indexModel"

const AppRequesterConst = new AppRequesterController()

const GeneralController: FC<BotxpressGeneralTabController> = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation();

    const [showTokenGPT, setShowTokenGPT] = useState(false);
    const [showTokenEngine, setShowTokenEngine] = useState(false);
    const [TokenUserGpt, setTokenUserGpt] = useState('');
    const [TokenUserEngine, setTokenUserEngine] = useState('');
    const [showFeedbackTokenGpt, setShowFeedbackTokenGpt] = useState(false);
    const [showFeedbackTokenEngine, setShowFeedbackTokenEngine] = useState(false);

    const values: TokenInterface = {
      company: {
        id: useSelector(getIdCompany),
      },
    };

    const clickTokenGPT = () => {
      if ((document.getElementById("tokenGpt") as HTMLInputElement).type === "text") {
        (document.getElementById("tokenGpt") as HTMLInputElement).type = "password";
      } else {
        (document.getElementById("tokenGpt") as HTMLInputElement).type = "text";
      }

      setShowTokenGPT(!showTokenGPT);
    };

    const clickTokenEngine = () => {
      if ((document.getElementById("tokenEngine") as HTMLInputElement).type === "text") {
        (document.getElementById("tokenEngine") as HTMLInputElement).type = "password";
      } else {
        (document.getElementById("tokenEngine") as HTMLInputElement).type = "text";
      }

      setShowTokenEngine(!showTokenEngine);
    };

    const submit = async (values) => {
      const JsonSend = {
        "id": props.dataCompany.id,
        "token_chatgpt": TokenUserGpt,
      }

      if (TokenUserEngine !== "") {
        JsonSend["token_transcription"] = TokenUserEngine
      }

      const headers = UserService.getHeaders();

      await AppRequesterConst.Put(
        `/company`, JsonSend, { headers },
        (response: Object) => { return response; },
        (data: Object) => {
          props.getCompanyId();
          dispatch(setShowAlertFeedback({ message: t('configurations.chatbots.general.messages.updated_successfully'), visibility: true, signalIcon: true }));
        },
        (error: { response: { status: number; data: { message: string, code_cxpress: number, context: { value: string } }; }; }) => {
          if (error.response.data.code_cxpress === 1201) {
            if (error.response.data.message.includes("chatGPT")) {
              dispatch(setShowAlertFeedback({ message: t('configurations.chatbots.general.error_invalid_token'), visibility: true, signalIcon: false }))
              //dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('configurations.chatbots.general.error_invalid_token_transcription'), visibility: true, signalIcon: false }))
            }
          }
          else {
            dispatch(setShowAlertFeedback({ message: t('configurations.chatbots.general.error_update'), visibility: true, signalIcon: false }));
          }
        }, navigate, dispatch, props.setIsLoading
      );
    }

    const validate = (values: { tokenGpt: string, tokenEngine: string }) => {
      let errors = {};

      if (!values.tokenGpt) {
          errors["tokenGpt"] = t('configurations.chatbots.general.messages.empty_field');
          setShowFeedbackTokenGpt(true);
      }
      else {
          setShowFeedbackTokenGpt(false);
      }

      // if (!values.tokenEngine) {
      //     errors["tokenEngine"] = t('configurations.chatbots.general.messages.empty_field');
      //     setShowFeedbackTokenEngine(true);
      // }
      // else {
      //     setShowFeedbackTokenEngine(false);
      // }

      setTokenUserGpt(values.tokenGpt);
      if(values?.tokenEngine)
        setTokenUserEngine(values.tokenEngine);

      return errors;
    }

    return <General
      t={t}
      dataCompany={props.dataCompany}
      showTokenGPT={showTokenGPT}
      showTokenEngine={showTokenEngine}
      showFeedbackTokenGpt={showFeedbackTokenGpt}
      showFeedbackTokenEngine={showFeedbackTokenEngine}
      clickTokenGPT={clickTokenGPT}
      clickTokenEngine={clickTokenEngine}
      submit={submit}
      validate={validate}
    />
}

export default GeneralController