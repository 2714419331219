import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { Popover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    setShowConfirmationModal,
    setShowEditConsumersModal,
    setShowAlertFeedback,
    getShowEditConsumersModal,
} from '../../../../store/internal'

import { getToken, /*setToken*/ } from '../../../../store/token'
import { getIdCompany, /*setIdCompany*/ } from '../../../../store/company'
import { getIdEmployee, /*setIdEmployee*/ } from '../../../../store/employee'
import /*user,*/ { getIdUser, getNameUser, /*setNameUser,*/ getProfileId, /*setProfileId*/ } from '../../../../store/user'

import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import Utils from '../../../../core/shared/utils';
import UserService from '../../../../services/user-service';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';

import { useTranslation } from 'react-i18next';
import { ConsumerIdData } from '../viewConsumers/indexModel';
import constsRouters from "../../../../routes/constsRouter";
import ListOfConsumers from '.';
import { ListConsumersInterface } from './indexModel';
import { AgentData } from '../../homeAgents/indexModel';
import { verifyCode } from '../../../../services/codeCxpressInterface';

const AppRequesterConst = new AppRequesterController();

const ListOfConsumersController: FC<ListConsumersInterface> = (props) => {

    
    const { t } = useTranslation();
    let navigate = useNavigate();
    // const [confirmationText, setConfirmationText] = useState({ "title": '', "body": '', 'id': '' });
    const dispatch = useDispatch();
    const [currentConsumerId, setCurrentConsumerId] = useState('');
    const [currentConsumersData, setCurrentConsumersData] = useState(null);
    
    const [deleteListConsumers, setDeleteListConsumers] = useState([]);
    const [consumersDeletedNames, setConsumersDeletedNames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [employeesData, setEmployeesData] = useState<any>([]);
    const [actionsClick, setActionsClick] = useState(false)
    const [clickCol, setClickCol] = useState(false)
    // const [customByID, setCustomById] = useState([])
    
    const [currentConsumerForSimilarData, setCurrentConsumerForSimilarData] = useState(null);
    const [similarConsumers, setSimilarConsumers] = useState(undefined);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
        }
    };

    // quando o componente é montado os modais devem estar com visibilidade desabilitada
    useEffect(() => {
        setActionsClick(false)
        setClickCol(false)
        if (values.internal.getShowEditConsumersModal) {
            dispatch(setShowEditConsumersModal(false));
        }
    }, []);

    useEffect(() => {
        if (currentConsumersData !== null && (actionsClick === true || clickCol === true)) {
            dispatch(setShowEditConsumersModal(true));
            setActionsClick(false)
            setClickCol(false)
        }
    }, [currentConsumersData, actionsClick, clickCol]);

    useEffect(() => {
        if (deleteListConsumers.length > 0) {
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'block');
            Utils.changeStyleElementById('buttonExportCSV', 'display', 'block');
            Utils.changeStyleElementById('buttonExportAllConsumers', 'display', 'none');
        }

        Utils.changeActiveScrollBar("ListOfConsumers");
    }, [props.datas]);

    const getConsumer = async (userID: string) => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/consumer/' + userID, { headers },
            (response: Object) => {

            },
            (data: ConsumerIdData) => {
                if (data.status === 200) {

                    setCurrentConsumersData({
                        address: data.data.consumers[0].address,
                        id: data.data.consumers[0].id,
                        user_id: userID,
                        name: data.data.consumers[0].name + " " + (data.data.consumers[0].name && data.data.consumers[0].lastname ? data.data.consumers[0].lastname : ""),
                        document_number: data.data.consumers[0].document_number,
                        company_id: data.data.consumers[0].company_id,
                        company_number: data.data.consumers[0].company_number,
                        email: data.data.consumers[0].email,
                        facebook: data.data.consumers[0].facebook,
                        instagram: data.data.consumers[0].instagram,
                        youtube: data.data.consumers[0].youtube,
                        lastname: data.data.consumers[0].lastname,
                        observations: data.data.consumers[0].observations,
                        phone: data.data.consumers[0].phone,
                        twitter: data.data.consumers[0].twitter,
                        whatsapp: data.data.consumers[0].whatsapp,
                        custom: data.data.consumers[0].custom_fields,
                        employee_user_id: data.data.consumers[0].employee_user_id,
                        instagram_user: data.data.consumers[0].instagram_user,
                        facebook_user: data.data.consumers[0].facebook_user,
                        tags: data.data.consumers[0].tags
                    });
                }
            },
            (error: Object) => {
                console.log(error);
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllEmployees = async () => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            `/agent`, { headers },
            (response: Object) => { },
            (data: AgentData) => {
                if (data.data && data.status == 200) {
                    setEmployeesData(data.data.employees.map((item) => {
                        return { label: item.name, value: item.user_id }
                    }))
                }
            },
            (error: { response: { status: number; data: { code_cxpress: number } } }) => {

            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const deleteConsumers = async (listConsumers: any[]) => {
        const headers = UserService.getHeaders()

        const info = {
            headers,
            // headers: {
            //     "Content-Type": "application/json; charset=utf-8",
            //     Authorization: "Bearer " + values.token.value
            // },
            data: {
                ids: listConsumers
            }
        };

        await AppRequesterConst.Delete(
            '/consumer', info,
            (response: Object) => { },
            (data) => {
                if (data?.status === 200) {
                    if (consumersDeletedNames != null && consumersDeletedNames && consumersDeletedNames.length === 1) {
                        dispatch(setShowAlertFeedback({
                            message: `${t("home_consumers.list_of_consumers.function_confirmation_all.message_a")} ${consumersDeletedNames[0]} ${t("home_consumers.list_of_consumers.function_confirmation_all.message_b")}`, signalIcon: true, visibility: true 
                        }));
                        props.updateListAfterDelete();
                    } else {
                        dispatch(setShowAlertFeedback({
                            message: t("home_consumers.list_of_consumers.function_confirmation_all.message_else"), signalIcon: true, visibility: true
                        }));
                        props.updateListAfterDelete();
                    }
                }
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                if (error?.response?.data?.code_cxpress === 1412) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else {

                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading
        );
    }

    // const exportConsumersCsv = async () => {
    //     const selectedConsumers = props.datas.filter((item) => deleteListConsumers.includes(item.user_id));
    //     let csvContent = `${t('home_consumers.list_of_consumers.csv_content')}`;

    //     selectedConsumers.forEach((consumer) => {
    //         const nome = consumer.name;
    //         const email = consumer.email ? consumer.email : `${t('home_consumers.list_of_consumers.na')}`;
    //         const whatsapp = consumer.whatsapp ? consumer.whatsapp : `${t('home_consumers.list_of_consumers.na')}`;
    //         const endereco = consumer.address ? consumer.address : `${t('home_consumers.list_of_consumers.na')}`;
    //         const cpfCnpj = consumer.document_number ? consumer.document_number : `${t('home_consumers.list_of_consumers.na')}`;
    //         const facebook = consumer.facebook ? consumer.facebook : `${t('home_consumers.list_of_consumers.na')}`;
    //         const instagram = consumer.instagram ? consumer.instagram : `${t('home_consumers.list_of_consumers.na')}`;
    //         const sobrenome = consumer.lastname ? consumer.lastname : `${t('home_consumers.list_of_consumers.na')}`;
    //         const observacoes = consumer.observations ? consumer.observations : `${t('home_consumers.list_of_consumers.na')}`;
    //         const telefone = consumer.phone ? consumer.phone : `${t('home_consumers.list_of_consumers.na')}`;
    //         const totalTickets = consumer.total_of_tickets ? consumer.total_of_tickets : `${t('home_consumers.list_of_consumers.na')}`;
    //         const twitter = consumer.twitter ? consumer.twitter : `${t('home_consumers.list_of_consumers.na')}`;
    //         const webchat = consumer.webchat ? consumer.webchat : `${t('home_consumers.list_of_consumers.na')}`;
    //         const youtube = consumer.youtube ? consumer.youtube : `${t('home_consumers.list_of_consumers.na')}`;

    //         csvContent += `${nome},${sobrenome},${email},${whatsapp},${endereco},${cpfCnpj},${facebook},${instagram},${observacoes},${telefone},${totalTickets},${twitter},${webchat},${youtube}\n`;
    //     });

    //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    //     const link = document.createElement("a");
    //     if (link.download !== undefined) {
    //         const url = URL.createObjectURL(blob);
    //         link.setAttribute("href", url);
    //         link.setAttribute("download", "consumers.csv");
    //         link.style.visibility = "hidden";
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     }
    // };

    const exportConsumersCsv = async () => {

        const JsonSend = {
            "users": deleteListConsumers,
        };

        const headers = UserService.getHeaders()

        AppRequesterConst.Post(
            '/consumer/csv/export-consumers',
            JsonSend,
            { headers: headers },
            (response: Object) => {
                return response;
            },
            (data) => {
                if (data.status === 200 && data.data) {
                    var decoder = new TextDecoder();
                    var uintData = Uint8Array.from(data.data.content.data);
                    var arrayBufferData = uintData.buffer;
                    var decoded = decoder.decode(arrayBufferData);

                    const blob = new Blob([decoded], { type: "text/csv;charset=utf-8;" });

                    var hiddenElement = document.createElement('a');

                    if (hiddenElement.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        hiddenElement.setAttribute("href", url);
                        hiddenElement.setAttribute("download", data.data.fileName);
                        hiddenElement.style.visibility = "hidden";
                        document.body.appendChild(hiddenElement);
                        hiddenElement.click();
                        document.body.removeChild(hiddenElement);
                    }
                }
            },
            (error: Object) => {
            },
            navigate,
            dispatch,
            setIsLoading
        );
    }

    const exportAllConsumers = async () => {

        const headers = UserService.getHeaders()

        AppRequesterConst.Post(
            '/consumer/csv/export-consumers-by-company',
            {},
            { headers: headers },
            (response: Object) => {
                return response;
            },
            (data) => {
                if (data.status === 200 && data.data) {
                    var decoder = new TextDecoder();
                    var uintData = Uint8Array.from(data.data.content.data);
                    var arrayBufferData = uintData.buffer;
                    var decoded = decoder.decode(arrayBufferData);

                    const blob = new Blob([decoded], { type: "text/csv;charset=utf-8;" });

                    var hiddenElement = document.createElement('a');

                    if (hiddenElement.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        hiddenElement.setAttribute("href", url);
                        hiddenElement.setAttribute("download", data.data.fileName);
                        hiddenElement.style.visibility = "hidden";
                        document.body.appendChild(hiddenElement);
                        hiddenElement.click();
                        document.body.removeChild(hiddenElement);
                    }
                }
            },
            (error: Object) => {
            },
            navigate,
            dispatch,
            setIsLoading
        );
    }

    const popover = (
        <Popover id={currentConsumerId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={(e) => showEditModal2(e)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_consumers.list_of_consumers.consumer_edit')}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={() => deleteConsumer(deleteConsumers)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_consumers.list_of_consumers.consumer_delete')}</Button>
            </Popover.Body>
        </Popover>
    );


    const deleteAllSelected = (deleteConsumers: Function) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": `${t('home_consumers.list_of_consumers.delete_all_selected.title')}`, "body": `${t('home_consumers.list_of_consumers.delete_all_selected.body')}`, 'id': '', "buttonReturnText": `${t('home_consumers.list_of_consumers.delete_all_selected.cancel')}` }, functionConfirmation: () => funtionConfirmationAll(deleteConsumers) }));
    }

    const AllCheckboxesClick = (e: { currentTarget: { checked: boolean; }; }) => {
        let aux = [];
        if (e.currentTarget.checked === true) {
            for (let i = 0; i < props.datas.length; i++) {
                if (document.getElementById("checkboxesAll" + i)) {
                    (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = true;
                    aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[1]);
                    setDeleteListConsumers(aux);
                }
            }
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'block');
            Utils.changeStyleElementById('buttonExportCSV', 'display', 'block');
            Utils.changeStyleElementById('buttonExportAllConsumers', 'display', 'none');
        } else {
            for (let i = 0; i < props.datas.length; i++) {
                if (document.getElementById("checkboxesAll" + i)) {
                    (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = false;
                    setDeleteListConsumers(aux);
                }
            }
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'none');
            Utils.changeStyleElementById('buttonExportCSV', 'display', 'none');
            Utils.changeStyleElementById('buttonExportAllConsumers', 'display', 'block');
        }

    }

    const CheckboxesClick = () => {
        let count = 0;
        let aux = [];
        let auxName = [];
        for (let i = 0; i < props.datas.length; i++) {
            if ((document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked === true) {
                count++;
                aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[1]);
                setDeleteListConsumers(aux);
                auxName.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[0]);
                setConsumersDeletedNames(auxName);
            }
        }
        if (count !== 0) {
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'block');
            Utils.changeStyleElementById('buttonExportCSV', 'display', 'block');
            Utils.changeStyleElementById('buttonExportAllConsumers', 'display', 'none');
        } else {
            setDeleteListConsumers([]);
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'none');
            Utils.changeStyleElementById('buttonExportCSV', 'display', 'none');
            Utils.changeStyleElementById('buttonExportAllConsumers', 'display', 'block');
        }
    }

    // const funtionConfirmation = (deleteConsumers: Function) => {
    //     setDeleteListConsumers((state) => {
    //         deleteConsumers(state).finally(() => {
    //             dispatch(setShowAlertFeedback({
    //                 message: `${t('home_consumers.list_of_consumers.function_confirmation.message_a')} ${currentConsumersData.name + " " + (currentConsumersData.name && currentConsumersData.lastname ? currentConsumersData.lastname : "")} ${t('home_consumers.list_of_consumers.function_confirmation.message_b')}`, signalIcon: true, visibility: true
    //             }));
    //         });
    //         return state;
    //     });
    // }

    const funtionConfirmationAll = (deleteConsumers: Function) => {
        setDeleteListConsumers((state) => {
            deleteConsumers(state).finally(() => {

            });
            return state;
        });
        for (let i = 0; i < props.datas.length; i++) {
            const checkbox = document.getElementById("checkboxesAll" + i) as HTMLInputElement;

            if (checkbox.checked === true) {
                checkbox.checked = false;
            }
        }
        setDeleteListConsumers([])
        Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'none');
        Utils.changeStyleElementById('buttonExportCSV', 'display', 'none');
        Utils.changeStyleElementById('buttonExportAllConsumers', 'display', 'block');
    }

    const deleteConsumer = (deleteConsumers: Function) => {
        let aux = [];
        aux.push(currentConsumerId.split("*ID*")[1]);
        setDeleteListConsumers(aux);

        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": `${t('home_consumers.list_of_consumers.delete_consumer.title')}`, "body": `${t('home_consumers.list_of_consumers.delete_consumer.body')}`, "id": ' ' + currentConsumerId.split("*ID*")[0] + '?', "buttonReturnText": `${t('home_consumers.list_of_consumers.delete_consumer.cancel')}` }, functionConfirmation: () => funtionConfirmationAll(deleteConsumers) }));
        //mudar o componente para aceitar duas linhas.
        //fazer if quando o agente tiver tickets atribuídos.
        //dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Excluir Agente', "body": 'Este consumidor possui tickets em execução atualmente. Tem certeza que deseja excluir o consumidor', "id": ' ' + currentConsumerId.split("*ID*")[0] + '?' }, functionConfirmation: funtionConfirmation }));
    }

    const clickOpenEdit = (id: string) => {
        setClickCol(true)
        setCurrentConsumerId(id);
        handleSetCurrentConsumerData(id);
        props.setCurrentConsumerFunction(id);
    }
    const setCurrentConsumerInfo = (e: { currentTarget: { id: string; }; }) => {
        setCurrentConsumerId(e.currentTarget.id);
        handleSetCurrentConsumerData(e.currentTarget.id.split("*ID*")[1]);
    }
    const handleSetCurrentConsumerData = (selectedConsumerId: string) => {
        setCurrentConsumersData(null)
        getConsumer(selectedConsumerId);
        //getAllEmployees();
    }

    const showEditModal2 = (e: { currentTarget: { parentElement: { parentElement: { id: string; }; }; }; }) => {
        setActionsClick(true)
        props.setCurrentConsumerFunction(e.currentTarget.parentElement.parentElement.id.split("*ID*")[1]);
        // dispatch(setShowEditConsumersModal(true));
    }

    const viewConsumerPage = (e: { currentTarget: { id: string; }; }) => {
        setCurrentConsumerId(e.currentTarget.id);
        let selectedConsumerId = e.currentTarget.id.split("*ID*")[1];
        navigate(constsRouters.routers.consumersView.path, { state: props.datas.filter(item => item.user_id === selectedConsumerId)[0] });
    }

    return <ListOfConsumers
        t={t}
        props={props}
        popover={popover}
        AllCheckboxesClick={AllCheckboxesClick}
        CheckboxesClick={CheckboxesClick}
        deleteAllSelected={deleteAllSelected}
        exportConsumersCsv={exportConsumersCsv}
        setCurrentConsumerInfo={setCurrentConsumerInfo}
        viewConsumerPage={viewConsumerPage}
        currentConsumersData={currentConsumersData}
        similarConsumers={similarConsumers}
        isLoading={isLoading}
        values={values}
        deleteConsumers={deleteConsumers}
        setCurrentConsumerForSimilarData={setCurrentConsumerForSimilarData}
        setSimilarConsumers={setSimilarConsumers}
        currentConsumerForSimilarData={currentConsumerForSimilarData}
        employeesData={employeesData}
        tagConsumer={props.tagConsumer}
        clickOpenEdit={clickOpenEdit}
        setActionsClick={setActionsClick}
        exportAllConsumers={exportAllConsumers}
    />

}

export default ListOfConsumersController;