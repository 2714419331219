import { t } from "i18next";

export enum CustomField {
    Text = 'd31ebd9e-af9f-4559-b04a-729439218a6f',
    Textarea = '2982a738-4de3-440a-8ed1-cc1fdb51bc9b',
    Dropdown = '74bf4b70-3415-4e8a-ac81-7ebe435c1424',
    Multiselect = '49d495a8-50d4-4d61-9d62-e1cc03227573',
}

export const Inputs = [
    { label: t('configurations_consumers.modal_edit_custom_field.simple_text_label'), value: CustomField.Text },
    { label: t('configurations_consumers.modal_edit_custom_field.multiline_text_label'), value: CustomField.Textarea },
    { label: t('configurations_consumers.modal_edit_custom_field.simple_select_label'), value: CustomField.Dropdown },
    { label: t('configurations_consumers.modal_edit_custom_field.multiple_select_label'), value: CustomField.Multiselect },

]