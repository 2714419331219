
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Utils from "../../../../../core/shared/utils";
import HomeMessageTriggerReportPdf from ".";

const HomeMessageTriggerReportPDFController = ({ show, reportDatas, componentRef, triggerInfos, triggerResult }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);

    const formatedScheduledDate = () => {
        let date_scheduled: Date, formatedScheduledDate: string;

        if (triggerInfos && triggerInfos.date_scheduled) {
            date_scheduled = new Date(triggerInfos.date_scheduled);
            formatedScheduledDate = Utils.formatedDate(date_scheduled) + " - " + Utils.formatTime(date_scheduled.toString()).replace(":", "h");
        }

        return formatedScheduledDate;
    }

    return (
        <HomeMessageTriggerReportPdf
            isLoading={isLoading}
            triggerInfos={triggerInfos}
            formatedScheduledDate={formatedScheduledDate}
            triggerResult={triggerResult}
            reportDatas={reportDatas}
            t={t}
            show={show}
            componentRef={componentRef}
        />
    )
}

export default HomeMessageTriggerReportPDFController