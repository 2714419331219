import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getPromptUpdatedNlp, setPromptUpdatedNlp, setShowAlertFeedback } from "../../../../../store/internal";
import constsRouters from '../../../../../routes/constsRouter';
import botTabStyles from './styles.module.css';
import ViewBot from "./index";
import { ChannelsMultiselectOptions } from "../../../../../core/enums/channels";
import NotificationSocket from "../../../../../sockets/notification-socket";
import { SocketContext } from "../../../../../core/context/socket-context";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getContractStatusId, getIdCompany } from "../../../../../store/company";
import { getToken } from "../../../../../store/token";
import { useTranslation } from "react-i18next";

const ViewBotController = () => {
  const dataBot = useLocation();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const socket = useContext(SocketContext);
  const notificationService: NotificationSocket = socket.getNotificationSocket();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [channel] = useState(dataBot?.state?.channel);
  const [activeTab] = useState(botTabStyles['nav-link'] + " " + botTabStyles['nav'] + " " + botTabStyles['active']);
  const [inactiveTab] = useState(botTabStyles['nav-link'] + " " + botTabStyles['nav']);

  const [infoTabClassname, setInfoTabClassname] = useState(dataBot.state && (dataBot.state.defaultActiveKey === 'info') ? activeTab : inactiveTab);
  const [chatTabClassname, setTrainTabClassname] = useState(dataBot.state && (dataBot.state.defaultActiveKey === 'chat') ? activeTab : inactiveTab);

  // const [promptUpdatedNlp, setPromptUpdatedNlp] = useState(dataBot.state.data?.prompt_updated_nlp === undefined ? true : dataBot.state.data.prompt_updated_nlp);
  // const [aux, setAux] = useState([]);

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany),
      contractStatusId: useSelector(getContractStatusId),
    },
    token: {
      value: useSelector(getToken)
    },
    promptUpdatedNlp: useSelector(getPromptUpdatedNlp)
  };

  const goToListOfBots = () => {
    navigate(constsRouters.routers.configurationChatbot.path, { state: { channel: channel ? channel : '4a7b71bb-6432-4794-ad11-f978b067871d' } });
  }

  const returnChannel = (channelId) => {
    return ChannelsMultiselectOptions.map((item) => {
      if (item.value === channelId) {
        return item.label
      }
    })
  }

  const activeTabInfo = () => {
    setInfoTabClassname(botTabStyles['nav-link'] + " " + botTabStyles['nav'] + " " + botTabStyles['active']);
    setTrainTabClassname(botTabStyles['nav-link'] + " " + botTabStyles['nav']);
  }

  const activeTabTrain = () => {
    setInfoTabClassname(botTabStyles['nav-link'] + " " + botTabStyles['nav']);
    setTrainTabClassname(botTabStyles['nav-link'] + " " + botTabStyles['nav'] + " " + botTabStyles['active']);
  }

  return (
    <ViewBot
      isLoading={isLoading}
      values={values}
      dataBot={dataBot}
      returnChannel={returnChannel}
      goToListOfBots={goToListOfBots}
      setInfoTabClassname={setInfoTabClassname}
      setTrainTabClassname={setTrainTabClassname}
      activeTab={activeTab}
      inactiveTab={inactiveTab}
      infoTabClassname={infoTabClassname}
      activeTabInfo={activeTabInfo}
      setIsLoading={setIsLoading}
      chatTabClassname={chatTabClassname}
      activeTabTrain={activeTabTrain}
      t={t}
    />
  );
};

export default ViewBotController;
