import { useNavigate } from "react-router-dom";
import Utils from "../../../../core/shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useRef, useState } from "react";
import { getToken } from "../../../../store/token";
import UserService from "../../../../services/user-service";
import { MessageSendingIdInterface, MessageSendingReport, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { HomeMessageTriggerReportInterface } from "./indexModel";
import HomeMessageTriggerReport from ".";
import { useTranslation } from "react-i18next";

const HomeMessageTriggerReportController: FC<HomeMessageTriggerReportInterface> = ({ show, handleClose, currentTriggerId }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false);

    const [currentState, setCurrentState] = useState("");

    const [triggerResult, setTriggerResult] = useState(null);
    const [triggerInfos, setTriggerInfos] = useState(null);
    const [reportDatas, setReportDatas] = useState(undefined);

    const [printPDF, setPrintPDF] = useState(false);


    const AppRequesterConst = new AppRequesterController();


    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        /** get trigger id from search */
        var triggerId = new URLSearchParams(window.location.search).get("id");

        // if (!triggerId) {
        //     navigate(constsRouters.routers.ticketsMessageTrigger.path);
        // }
        if (currentTriggerId) {
            getInformations(triggerId, getTriggerErrors, getTriggerInfos);
        }
    }, [currentTriggerId]);

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfReports");
    }, [reportDatas]);

    const getTriggerErrors = async (triggerId: string) => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            "/message-sending/" + currentTriggerId + "/report",
            { headers: headers },
            (response:Object) => { },
            (data:MessageSendingReport) => {

                if (data && data.status === 200 && data.data) {
                    setTriggerResult({
                        sent_failure: data.data.sent_failure,
                        sent_success: data.data.sent_success,
                        total_answers: data.data.total_answers,
                        total_sent: data.data.total_sent,
                    });

                    if (data && data.data && data.data.messages && data.data.messages.length > 0) {
                        setReportDatas(data.data.messages);
                    } else {
                        setReportDatas(undefined);
                    }
                }
            },

            (error:Object) => { 

            },
            navigate, dispatch, setIsLoading, { values: values}
        );
    }

    const getTriggerInfos = async (triggerId: string) => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            "/message-sending/" + currentTriggerId,
            { headers: headers },
            (response:Object) => { },
            (data:MessageSendingIdInterface) => {

                if (data && data.status === 200 && data.data) {
                    setTriggerInfos({
                        channel: data.data.message_sending.channel,
                        type: data.data.message_sending.type,
                        date_scheduled: data.data.message_sending.date_scheduled,
                    });
                }
            },

            (error:Object) => {  

            },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const printIsValid = () => {
        setPrintPDF(true)
        return Promise.resolve()
    }

    const printIsInvalid = () => {
        setPrintPDF(false)
    }

    const getInformations = (triggerId: string, getTriggerErrors: Function, getTriggerInfos: Function) => {
        getTriggerErrors(triggerId);
        getTriggerInfos(triggerId);

        setCurrentState("carregou")
    }

    const componentRef = useRef();
    const printReport = () => {
        window.print();
    }

    const formatedScheduledDate = () => {
        let date_scheduled:Date, formatedScheduledDate: string;
        
        if (triggerInfos && triggerInfos.date_scheduled) {
            date_scheduled = new Date(triggerInfos.date_scheduled);
            formatedScheduledDate = Utils.formatedDate(date_scheduled) + " - " + Utils.formatTime(date_scheduled.toString()).replace(":", "h");
        }

        return formatedScheduledDate;
    }

    return (
        <HomeMessageTriggerReport
            isLoading={isLoading}
            triggerInfos={triggerInfos}
            formatedScheduledDate={formatedScheduledDate}
            triggerResult={triggerResult}
            printReport={printReport}
            reportDatas={reportDatas}
            t={t}
            show={show}
            handleClose={handleClose}
            componentRef={componentRef}
            printPDF={printPDF}
            printIsInvalid={printIsInvalid}
            printIsValid={printIsValid}
        />
    )
}

export default HomeMessageTriggerReportController