import { FC, useState } from "react";
import { IClipboardButtonController } from "./indexModel";
import ClipboardButton from ".";
import { useDispatch } from "react-redux";

const ClipboardButtonController: FC<IClipboardButtonController> = (props) => {
    const dispatch = useDispatch();
    const [clicked, setClicked] = useState(false);
    const message = props.label + " copiado: " + props.text;

    const clickClipboard = () => {
        navigator.clipboard.writeText(props.text); 
        setClicked(true);

        setTimeout(() => { setClicked(false); }, 2000);
    }

    return <ClipboardButton clickClipboard={clickClipboard} clicked={clicked} message={message}  />
}

export default ClipboardButtonController;