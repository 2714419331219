import React, { FC } from 'react';
import { Modal, Button, Form, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from './styles';
import { IModalRegisterDomain } from './indexModel';

const ModalRegisterDomain: FC<IModalRegisterDomain> = (props) => {
    return (
        <Modal show={props.show} onHide={props.hide} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ marginBottom: '-2vh' }} className='mx-3'>Adicionar novo domínio</Modal.Title>
            </Modal.Header>
            <Modal.Body className='mx-3 mb-2'>
                <Formik
                    initialValues={{ domain: '' }}
                    validate={props.validate}
                    onSubmit={props.onPost}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={false}>
                            <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                                <Col>
                                    <Form.Label htmlFor="domain" className='mt-3'>
                                        Digite o domínio que a empresa utilizará nos envios de e-mails dentro da plataforma
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            className="form-control-Default mt-3"
                                            isInvalid={props.showDomainFeedback}
                                            placeholder="Informe o domínio"
                                            aria-label="Informe o domínio"
                                            aria-describedby="Informe o domínio"
                                            size="lg"
                                            type="domain"
                                            id="domain"
                                            name="domain"
                                            maxLength={50}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackdomain">
                                            {errors['domain']}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={props.hide} >Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalRegisterDomain;