import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import allStyles from './styles';
import SelectComponent from '../selectComponent';
import { useLocation, useNavigate } from "react-router-dom"
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { getToken } from '../../../../store/token';
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel';
import { useDispatch, useSelector } from 'react-redux';
import { setShowAlertFeedback } from '../../../../store/internal';

const AppRequesterConst = new AppRequesterController()

const ModalRegisterVozxpress = ({ show, handleClose, callback }) => {
    
    const [validated] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { state } = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [account, setAccount] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState([])
    const [typeAccount, setSelectedTypeAccount] = useState("account")
    const [optionsAccount, setOptionsAccount] = useState([])

    useEffect(() => {
        getCompanyById()
        setSelectedAccount([])
        setAccount(false)
        setSelectedTypeAccount('account')
    }, [show])
    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }
    const getCompanyById = async () => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/panel/vozxpress/account/${state.id}/employees-without-account/`, { headers },
            (response: Object) => {},
            (data: any) => {
                if(data.status === 200) {
                    setOptionsAccount([])
                    let agentes = [];
                    data.data.employees
                    .slice()
                    .sort((a, b) => {
                        const nameA = a.name ? a.name.toLowerCase() : 'n/a';
                        const nameB = b.name ? b.name.toLowerCase() : 'n/a';
                        return nameA.localeCompare(nameB);
                    })
                    .map((item) => {
                        agentes.push({ label: `${item.name} ${item.lastname === null ? '' : item.lastname}`, value: item.user_id  });
                    });
                    setOptionsAccount(state => {
                        return state.concat(agentes);
                    });
                }
                
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { }
        )
    }


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Cadastrar Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{}}
                    validate={() => {
                    }}
                    onSubmit={() => {

                            const JsonSend = {
                                "user_id": selectedAccount[0].value,
                                "user_type": typeAccount
                            };
                            
                            const headers = {
                              "Content-Type": "application/json; charset=utf-8",
                              "Authorization": "Bearer " + values.token.value
                            };
                            
                            AppRequesterConst.Post(
                              '/panel/vozxpress/account',
                              JsonSend,
                              { headers: headers },
                              (response: Object) => {
                                return response;
                              },
                              (data:Object) => {                                
                                dispatch(setShowAlertFeedback({ message: "Account criada com sucesso.", visibility: true, signalIcon: true}))
                                callback()
                                handleClose()
                                setSelectedAccount([])
                                setAccount(false)
                                setSelectedTypeAccount('account')
                                getCompanyById()
                              },
                              (error) => {
                                if(error.response.status === 400){
                                    dispatch(setShowAlertFeedback({ message: "Não é possível cadastrar outra account do tipo user.", visibility: true, signalIcon: false}))
                                } 
                                if(error.response.status === 500){
                                    dispatch(setShowAlertFeedback({ message: "Não é possível cadastrar o agente. Já existe account com esse email.", visibility: true, signalIcon: false}))
                                }
                              },
                              navigate,
                              dispatch,
                              setIsLoading
                            );
                        
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-left">
                                
                                <Form.Label>Accounts <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span></Form.Label>
                                <SelectComponent
                                    className={"multiselectForm mb-3"}
                                    options={optionsAccount}
                                    setOptions={setOptionsAccount}
                                    selecteds={selectedAccount}
                                    setSelecteds={setSelectedAccount}
                                    singleSelect={true}
                                    title={"Selecione a Account"}
                                    overrideStrings={
                                        {
                                            "allitemsAreSelected": selectedAccount[0] ? selectedAccount[0]?.label && selectedAccount[0]?.value : "",
                                            "clearSearch": "Limpar pesquisa",
                                            "clearSelected": "Limpar seleção de Agentes",
                                            "noOptions": "Sem Agentes",
                                            "search": "Pesquisar Agente",
                                            "selectAll": "Selecionar todas os Agente",
                                            "selectAllFiltered": "Selecionar todos os Agentes (Filtrado)",
                                            "selectSomeItems": "Selecione o Agente",
                                            "create": "Criar",
                                        }
                                    }
                                    hasCreateItem={false}
                                    hasNullOption={false}
                                    filtering={false}
                                    hasAllOption={false}

                                />
                                
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col sm={true} style={allStyles.textFontGlay}>
                                    <Form.Label htmlFor="basic-url">Tipo de Conta? &nbsp; &nbsp;</Form.Label>
                                    <Form.Check name="radioIsAdministrator" label={'Account'} type={'radio'} checked={!account} onChange={() => { setAccount(false);setSelectedTypeAccount("account") }} inline></Form.Check>
                                    <Form.Check name="radioIsAdministrator" label={'User'} type={'radio'} checked={account} onChange={() => { setAccount(true);setSelectedTypeAccount("user") }} inline></Form.Check>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={handleClose} >Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button  size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Cadastrar</Button>
                                </Col>
                            </Row>
                            
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalRegisterVozxpress;
