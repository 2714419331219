type CSSProperties = {
    [key: string]: React.CSSProperties;
};

export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
        return styles;
    };
};

const styles = StyleSheet.create({
    inputSearch: {
        textAlign: 'left',
        font: 'normal normal normal calc(0.2em + 0.7vw) Roboto',
        letterSpacing: '0px',
        color: '#AFAFAF',
        opacity: '1',
        border: 'none',
        outline: 'none',
        background: '#F9F9F9 0% 0% no-repeat padding-box',
    },
    buttonSearchViewTicket: {
        width: '5.3vw',
        height: '3.7vh',
        border: 'none',
        background: '#F9F9F9 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        opacity: '1',
        color: '#AFAFAF',
    },
    labelBlue: {
        height: '20px',
        background: '#EEF3FF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        opacity: '1',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        font: 'var( --px10_13_Bold-font)',
        letterSpacing: '0px',
        color: '#0C2461',
        justifySelf: 'center',
        width:'50px'
    },
    labelBlue2: {
        height: '20px',
        background: '#EEF3FF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        opacity: '1',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        font: 'var( --px10_13_Bold-font)',
        letterSpacing: '0px',
        color: '#0C2461',
        justifySelf: 'center',
        width:'100px'
    },
    labelBlueAux: {
        height: '20px',
        background: '#EEF3FF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        opacity: '1',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        font: 'normal normal bold calc(0.2em + 0.5vw) Montserrat',
        letterSpacing: '0px',
        color: '#0C2461',
        justifySelf: 'center',
        width:'30%'
    },
    dropdownOptionRow: {
        marginBottom: '1vh',
    },
});

export default styles;