import './styles.scss';
import { Modal, Button, Form, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import { CircleFlag } from 'react-circle-flags';
import { IconCheck } from '@tabler/icons-react';
import { FC } from 'react';
import { ModalSelectLanguageInterface } from './indexModel';
import Loading from '../../../../components/loading';


const ModalSelectLanguage: FC<ModalSelectLanguageInterface> = ({
  t,
  closeModal,
  validated,
  language,
  setLanguage,
  onSubmit,
  showModal,
  setShowModal,
  titleLanguage,
  iconLanguage,
  getAvailableLanguages,
  isSelectedLanguage,
  isLoading,
  isLogged,
}) => {
  const btnFlagClass = isLogged ? 'btnFlagLogged' : 'btnFlag';

  return (
    <div className="modalSelectLanguage">
      <div className="btnLanguage">
        <OverlayTrigger key='br' placement='top' overlay={<Tooltip id='flag-title'>{titleLanguage()}</Tooltip>}>
          <span className={btnFlagClass} onClick={() => setShowModal(!showModal)}>
            <CircleFlag countryCode={iconLanguage()} title=' ' />
          </span>
        </OverlayTrigger>
      </div>
      {
        showModal &&
        <Modal show={showModal} onHide={() => closeModal(false)} size="lg" centered={true} className='bs-modalSelectLanguage'>
          <Modal.Header closeButton>
            <Modal.Title>{t("components.modal_language.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
                enableReinitialize={true}
                initialValues={{}}
                onSubmit={onSubmit}
            >
              {(formik) => (
                <Form className='setLanguageForm' onSubmit={formik.handleSubmit} noValidate validated={validated}>
                  {isLoading && <Loading visibility={true} noBootstrap={true} customStyle={{ position: 'absolute' }} />}
                  <div className="language-list">
                      {getAvailableLanguages().map(item => {
                        let itemClass = 'language-item';

                        if (isSelectedLanguage(language, item?.language)) {
                          itemClass += ' active';
                        }

                        return (
                          <div key={item?.language} className={itemClass} onClick={() => setLanguage(item?.language)}>
                            <CircleFlag countryCode={item?.flag} title=' ' />
                            <span className='language-name'>{item?.title}</span>
                            { isSelectedLanguage(language, item?.language) && <IconCheck className="icon-check" /> }
                          </div>
                        );
                      })}
                  </div>
                  { isLogged &&
                    <div className="d-flex justify-content-start mt-4">
                      <span style={{ color: 'red', fontSize: '14', marginLeft: '0.4rem' }}>{t("components.modal_language.message_alert")}</span>
                    </div>
                  }
                  <Row className="d-flex justify-content-center mt-4">
                    <Col xs={6} className="d-flex justify-content-start">
                      <Button disabled={isLoading} size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => closeModal(false)} >{t("components.modal_language.btn_cancel")}</Button>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                      <Button disabled={isLoading} size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t("components.modal_language.btn_send")}</Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal >
      }
    </div>
  );
};

export default ModalSelectLanguage;