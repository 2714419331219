import { Col, FormControl, FormLabel, InputGroup, Modal, Row } from "react-bootstrap";
import styles from "../styles";

const MoreInfo = ({
  show,
  onHide,
  moreInfo
}) => {
 
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Mais informações</Modal.Title>      
      </Modal.Header>
      <Modal.Body className='mx-3 mb-2'>
        {
          moreInfo.user && 
          <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
            <Col>
            <div className="box">
              <label>E-mail da conta no VX</label>
              <span className="custom-span">{moreInfo?.user?.email}</span>
            </div>
            </Col>
            <Col>
              <div className="box">
                <label>Tipo de conta</label>
                <span className="custom-span">{moreInfo?.user?.type}</span>
              </div>
            </Col>
          </Row>
        }
      <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
        <Col>
        <div className="box">
          <label>E-mail da conta no VX</label>
          <span className="custom-span">{moreInfo?.account?.email}</span>
        </div>
        </Col>
        <Col>
          <div className="box">
            <label>Tipo de conta</label>
            <span className="custom-span">{moreInfo?.account?.type}</span>
          </div>
        </Col>
      </Row>
      <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
        <Col>
          <div className="box">
            <label>Ramal</label>
            <span className="custom-span">{moreInfo?.account?.extension ? moreInfo.account.extension : 'N/A'}</span>
          </div>
        </Col>
        <Col></Col>
      </Row>
      </Modal.Body>
    </Modal>
  )
}

export default MoreInfo;