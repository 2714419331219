import React, { FC, useState } from 'react';
import { OrderBy } from '../../../../core/enums/order-sort-by';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderByList, getSortByList, setSortByList } from '../../../../store/internal';
import { ButtonOrderByListInterface } from './indexModel';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';


const ButtonOrderByList: FC<ButtonOrderByListInterface> = (props) => {
    const dispatch = useDispatch();

    const values: TokenInterface = {
        internal: {
            getOrderByList: useSelector(getOrderByList),
            getSortByList: useSelector(getSortByList),
        }
    };

    const [orderBy, setOrderBy] = useState(
        (values.internal.getSortByList.sort_by === props.columnIdName && values.internal.getSortByList.listName === props.listName)? 
            values.internal.getOrderByList: 
        (props.defaultOrder !== undefined)? 
            props.defaultOrder: OrderBy.asc
    );

    const orderByFunction = () => {
        dispatch(setSortByList({ sort_by: props.columnIdName, listName: props.listName }));
        
        if (orderBy === OrderBy.asc) {
            setOrderBy(OrderBy.desc);
            props.callbackOrderBy(props.columnIdName, OrderBy.desc);
        } else {
            setOrderBy(OrderBy.asc);
            props.callbackOrderBy(props.columnIdName, OrderBy.asc);
        }
    }
    
    return (
        <div>
            <span onClick={orderByFunction} style={{ cursor: 'pointer' }} className="me-1" >{props.title}</span>
            <span onClick={orderByFunction} style={{ cursor: 'pointer' }}>
                {orderBy === OrderBy.desc?
                    <svg id="Grupo_11467" data-name="Grupo 11467" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                        <path id="Caminho_9741" data-name="Caminho 9741" d="M0,16.383H16.383V0H0Z" fill="none" />
                        <path id="Caminho_9742" data-name="Caminho 9742" d="M6,13.1,10.1,9l4.1,4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>:
                    <svg id="Grupo_11489" data-name="Grupo 11489" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                        <path id="Caminho_9741" data-name="Caminho 9741" d="M0,0H16.383V16.383H0Z" fill="none" />
                        <path id="Caminho_9742" data-name="Caminho 9742" d="M6,9l4.1,4.1,4.1-4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                }
            </span>
        </div>
    );
}


export default ButtonOrderByList;