import { createSlice } from '@reduxjs/toolkit'

export const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    value: null as string | null
  },
  reducers: {
    setToken: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {setToken} = tokenSlice.actions

export const getToken = (state: { token: { value: string; }; }) => (state?.token?.value);

export default tokenSlice.reducer

