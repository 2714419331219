import { useContext } from 'react';
import Login from './components/LoginForm';
import ConnectLoading from './components/ConnectLoading';
import MainScreenController from './components/MainScreen/indexController';
import { CallcenterContext } from '../../../../core/context/callcenter-context';

const CONNECTED_STATUS = ['connected', 'sent_request'];
const CONNECTING_STATUS = ['connecting'];
const DISCONNECTED_STATUS = ['disconnected'];

const PhoneCallcenter = () => {
  const callcenterContext = useContext(CallcenterContext);

  return (
    <>
      <div className='phoneCallcenter'>
        { DISCONNECTED_STATUS.includes(callcenterContext.sessionStatus) && <Login />}
        { CONNECTING_STATUS.includes(callcenterContext.sessionStatus) && <ConnectLoading />}
        { CONNECTED_STATUS.includes(callcenterContext.sessionStatus) && <MainScreenController /> }
      </div>
    </>
  );
}

export default PhoneCallcenter;
