import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import LinksConstants from '../../../../core/constants/links-contants';
import { getSubdomainCompany } from '../../../../store/company';
import { getNameUser } from '../../../../store/user';
import { AcquirePlatformInterface } from './indexModel';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { t } from 'i18next';


const AcquirePlatformComponent: FC<AcquirePlatformInterface> = (props) => {
    const [limitDays, /*setLimitDay*/] = useState(props.limitDays);
    const [show, setShow] = useState(props.visibility);
    //const [title, setTitle] = useState(props.title);
    const [text, /*setText*/] = useState(props.text); // useState("Para cadastrar novos agentes você precisa adquirir a plataforma.");

    useEffect(() => {
        setShow(props.visibility);
    }, [props.visibility]);

    const values: TokenInterface = {
        user: {
            name: useSelector(getNameUser),
        },
        company: {
            subdomain: useSelector(getSubdomainCompany),
        }
    }

    return (
        <div>
            {show ?
                <Row
                    className="d-flex align-items-center justify-content-center py-4"
                    style={{
                        //height: '20vh', 
                        background: '#E5ECFF 0% 0% no-repeat padding-box',
                        width: '97%',
                        marginLeft: '2%',
                        //position: 'absolute', 
                        //marginTop: '-25vh',
                        //marginLeft: '6vh',
                        borderRadius: '10px',
                    }}
                >
                    <Col>
                        <Row>
                            <div
                                style={{
                                    textAlign: 'center',
                                    //font: 'var(--px20_24_Bold-font)',
                                    font: 'var(--px15_20_Bold-font)',
                                    letterSpacing: '0px',
                                    color: '#1E1E1E'
                                }}>
                            {t("endTrial.endTrialMessage_part_a")} <span>{limitDays}</span> {t("endTrial.endTrialMessage_part_b")}

                            </div>
                        </Row>
                        <Row className='mt-2'>
                            <div
                                style={{
                                    textAlign: 'center',
                                    font: 'var(--px15_19-font)',
                                    letterSpacing: '0px',
                                    color: '#1E1E1E',
                                    opacity: '1',
                                }}
                            >
                                {text}
                            </div>
                        </Row>
                        <Row className="d-flex justify-content-center mt-3">
                            <div style={{ width: '20vw' }}>
                                <a target="_blank" rel="noreferrer" href={LinksConstants.homeBuild(values.user.name, values.company.subdomain)}><Button className="buttonBlue">{t("endTrial.acquire_platform")}</Button></a>
                            </div>
                        </Row>
                    </Col>
                </Row> : null
            }
        </div>
    );
}

export default AcquirePlatformComponent;