import React, { useState, useEffect, FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';

import {
  setShowCreateMessageTriggerModal,
  setShowAlertFeedback,
  getCleanMessageEditor,
  setCleanMessageEditor
} from '../../../../store/internal'
import { ModalRegisterMessageTriggerInterface, WhatsappKey } from './indexModel';
import { useDropzone } from 'react-dropzone';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';

import ChatEditor from '../chatEditor';
import { ErrorRequestController } from '../../../../services/errorsInterfaceModel';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { useTranslation } from 'react-i18next';
import { Channels } from '../../../../core/enums/channels';
import AttachmentsInMessageController from '../../homeMessageTrigger/components/attachmentsInMessage/indexController';
import SelectComponentController from '../selectComponent/indexController';
import { BlockTicketContext } from '../../../../core/context/block-ticket-context';

const AppRequesterConst = new AppRequesterController();

const ModalRegisterMessageTrigger: FC<ModalRegisterMessageTriggerInterface> = (props) => {
  const {t} = useTranslation();
  const block_ticket = useContext(BlockTicketContext)
  const [isLoading, setIsLoading] = useState(false)
  const [validated] = useState(false);

  const [date, setDate] = useState(null as string || null);
  const [showfeedbackDate, setShowfeedbackDate] = useState(false);

  const [hour, setHour] = useState(null as string || null);
  const [showfeedbackHour, setShowfeedbackHour] = useState(false);

  const cleanMessageEditor = useSelector(getCleanMessageEditor);
  const [messageContentHtml, setMessageContentHtml] = useState("");
  const [/*showfeedbackMessage*/, setShowfeedbackMessage] = useState(false);

  const [tempAttachmentIds, setTempAttachmentIds] = useState([] as string[]);

  const [file_id, setFile_id] = useState(null as string || null);

  const [selected_type, setSelected_type] = useState([]);
  const [selected_channel, setSelected_channel] = useState([]);
  const [selected_template, setSelected_template] = useState([]);
  const [selected_email, setSelected_email] = useState([])
  const [selected_atendimento, setSelected_atendimento] = useState([])
  const [templateMessage, setTemplateMessage] = useState(null);
  const [hasWhatsapp, sethasWhatsapp] = useState(false);
  const [hasEmail, setHasEmail] = useState(false)
  const [oldChannel, setOldChannel] = useState(null)
  const [options_templates, setOptions_templates] = useState([]);
  const [options_email, setOptions_email] = useState([])
  const [whatsAppKeysId, setSelected_whatsappKey] = useState<WhatsappKey>(null)
  const [templateMessageId, setSelected_templateMessageId] = useState(null)
  const [email_principal, setOptions_email_principal] = useState([])
  const [triageConfirmation, setTriageConfirmation] = useState(selected_template[0]?.has_triage === true ? true : false)

  const [modalShow, setModalShow] = useState(props.visibility);
  const [disableButtonSave, setDisableButtonSave] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [errorSelectType, setErrorSelectType] = useState(false);
  const [errorSelectChannel, setErrorSelectChannel] = useState(false);
  const [errorSelectRecipients, setErrorSelectRecipients] = useState(false);
  const [errorSelectWhatsappTemplate, setErrorSelectWhatsappTemplate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelectChangeType = (selectedOptions) => {
    setSelected_type(selectedOptions);
    setErrorSelectType(selectedOptions?.length === 0);
  };

  const handleSelectChangeChannel = (selectedOptions) => {
    setSelected_channel(selectedOptions);
    setErrorSelectChannel(selectedOptions?.length === 0);
    attachmentIdsCallback([]);
  };

  const handleSelectChangeTemplate = (selectedOptions) => {
    setSelected_template(selectedOptions);
    setErrorSelectWhatsappTemplate(selectedOptions?.length === 0);
  };

  let JsonSend_sector = {}

  const [isClicked, setIsClicked] = useState(false);
  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 15728640,
    disabled: selected_channel[0] ? false : true,
    noClick: isClicked,
    noDrag: isClicked,
    onDrop: (file) => {
      const formData = new FormData()

      formData.append("file", file[0])
      formData.append("chanel_id", selected_channel[0].value)

      const headers = {
        "Content-Type": "multipart/form-data; charset=utf-8",
        "Authorization": "Bearer " + props.token
      };

      AppRequesterConst.Post(
        '/message-sending/upload', formData, { headers },
        (response: Object) => {
          return response;
        },
        (data: { data: { id: string; }; }) => {
          dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.upload_sucess"), visibility: true, signalIcon: true }));
          setDeleted(false)
          setIsClicked(true)
          setFile_id(data.data.id);
        },
        (error: { response: { status: number; data: { message: any[], code_cxpress: number; }; }; }) => {
          setIsClicked(true);
          
          if (error.response.status === 422) {
            dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.upload_error"), visibility: true, signalIcon: false }));
          }
          else if (error.response.status === 400) {
            dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.template_params_error"), visibility: true, signalIcon: false }));
            deleteFile(files)
            setFiles([])
            setDeleted(true) 
          }
          else {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }, navigate, dispatch, setIsLoading,
      );
    },
    onDropRejected: (reject) => {
      if (reject[0].errors[0].code === "file-too-large") {
        dispatch(setShowAlertFeedback({ visibility: true, message: t("home_message_trigger.modal_register_message_trigger.size_file"), signalIcon: false }));
      }
    },
    accept: { 'text/csv': ['.csv'] },
  });

  const [options_types, setOptions_types] = useState(props?.dataTriggerType ? props?.dataTriggerType?.map((item, index) => {
    return { label: item.name, value: item.id }
  }) : []);

  const [options_channel, setOptions_channel] = useState([
    { label: "E-mail", value: Channels.Email /* "3f3fa530-fd3e-4018-ab98-4d9c3cd5406a" */ },
    { label: "Whatsapp", value: Channels.WhatsApp/* "33be81d8-88ac-4e08-bb4d-cf69fd23267f" */ },
  ]);

  const [options_atendimento, setOptions_atendimento] = useState([
    { label: t("home_message_trigger.modal_register_message_trigger.agent"), value: "agente" },
    { label: t("home_message_trigger.modal_register_message_trigger.chatbot"), value: "chatbot" }
  ])

  useEffect(() => {
    setOptions_types(props?.dataTriggerType ? props?.dataTriggerType?.map((item, index) => {
      return { label: item?.name, value: item?.id }
    }) : []);

    setOptions_templates(props?.templateMessageList ? props?.templateMessageList.filter(item => item.quick_message === false)
      .map((item, index) => {
        // return { label: item.title_and_number, value: item.message, whatsappkeyid: item.whatsapp_keys_id, id: item.id }
        return Object.assign(item, { label: item.title_and_number, value: item.message, whatsapp_keys_id: item.whatsapp_keys_id, whatsappkeyid : item.whatsapp_keys_id, id: item.id, is_bot_nlp: item?.is_bot_nlp, has_triage: item?.has_triage });
      }) : [])
    
    setOptions_email(props?.emailList ? props?.emailList/* .filter(item => item.active === true) */
      .map((item, index) => {
        return { label: item.email, value: item.id }
      }) : [])

    setOptions_email_principal(props?.emailPrincipal ? props?.emailPrincipal?.filter((item) => item?.email_suport ).map((item) => {
      return {label: item.email, value: item.id}
    }) : [])

    setModalShow(props.visibility);
  }, [props.visibility, props.emailList, props.emailPrincipal, messageContentHtml]);

  useEffect(() => {
    acceptedFiles.map(file => {
      const returnedTarget = Object.assign(file, { isLoading: true });
      file = returnedTarget;
    });
    setFiles(acceptedFiles);
  }, [props.visibility, acceptedFiles, files]);

  useEffect(() => {
    selected_channel[0] && selected_channel[0].label === "Whatsapp" ? sethasWhatsapp(true) : sethasWhatsapp(false);
    setSelected_template([]);
  }, [selected_channel]);

  useEffect(() => {
    selected_channel[0] && selected_channel[0].label === "E-mail" ? setHasEmail(true) : setHasEmail(false)
    setSelected_email([])
  }, [selected_channel])

  useEffect(() => {
    block_ticket.setIsAllowedMessage(true);
    block_ticket.setReadOnlyEditorComponent(false);
  }, [])

  /*const selectType = (value) => {
    if (value.length > 0) {
      setSelected_type([value[value.length - 1]]);
    } else {
      setSelected_type([]);
    }
  }*/

  const selectChannel = (value: string | any[]) => {
    if (value.length > 0) {
      setOldChannel(selected_channel);
      setSelected_channel([value[value.length - 1]]);
      if (oldChannel !== selected_channel) {
        deleteFile(files);
        setTemplateMessage({ message: "" });
      }
    } else {
      setSelected_channel([]);
    }
  }

  const selectTemplate = (value: string | any[]) => {
    if (value.length > 0) {
      setSelected_template([value[value.length - 1]]);
      setTemplateMessage({ message: value[value.length - 1].value });
      setSelected_whatsappKey({ whatsapp_keys_id: value[value.length - 1].whatsappkeyid})
      setSelected_templateMessageId({ id: value[value.length - 1].id })
    } else {
      setSelected_template([]);
      setTemplateMessage({ message: "" });
    }
  }

  const callbackGetStringHtml = (parentRef = null, htmlString: string) => {
    setMessageContentHtml(htmlString);
    // sendMessage(); 
  }

  const changeToText = (html: string) => {
    let blocksFromHTML = convertFromHTML(html);
    let newEditorState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    let stateHtml = EditorState.createWithContent(newEditorState)
    let text = convertToRaw(stateHtml.getCurrentContent()).blocks.map((item: { text: string; }, index: any) => {
      return item.text
    })
    return text.join('\n')
  }

  const Hide = () => {
    setCleanMessageEditor(false)
    setSelected_channel([]);
    setSelected_type([]);
    setSelected_template([]);
    files.length > 0 ? deleteFile(files) : setFiles([]);
    setFile_id(null);
    dispatch(setShowCreateMessageTriggerModal(false));
    setModalShow(false);
    setSelected_atendimento([])
    setTriageConfirmation(false)
    setErrorSelectType(false)
    setErrorSelectChannel(false)
    setErrorSelectRecipients(false)
    //setErrorMessage(false)
    setErrorSelectWhatsappTemplate(false)
    setShowfeedbackDate(false)
    setShowfeedbackHour(false)
  }

  const deleteFile = (fileName: any[]) => {
    let newFiles = files.filter((item) => item.path !== fileName);
    acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
    setIsClicked(false);
    setFiles(newFiles);
  }

  const converterData = (data: string) => {
    let separaData = data.split("/")
    let dataFormatada = separaData[1] + "/" + separaData[0] + "/" + separaData[2]
    let Data = new Date(dataFormatada)
    return (Data)
  }

  const converterHora = (hora: string) => {
    let separaHora = hora.split(":")
    let Data = new Date(2000, 1, 1, parseInt(separaHora[0]), parseInt(separaHora[1]))
    return (Data)
  }

  const dataToTimestamp = (data: string, hora: any) => {
    let separaData = data.split("/")
    let separaHora = hora.split(":")
    let timestamp = new Date(`${separaData[2]}/${separaData[1]}/${separaData[0]} ${separaHora[0]}:${separaHora[1]}`)
    return timestamp.toISOString()
  }

  const attachmentIdsCallback = (ids: []) => {
    setTempAttachmentIds(ids);
  }

  const showFiles = files.map((file, index) => {
    if (!deleted) {
      return (
    <span key={index}
      style={{
        background: '#E8E8E8 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        opacity: '1',
        marginRight: '0.5vw',
        marginBottom: '1vh',
        whiteSpace: 'pre', display: 'inline-block'
      }}>
      <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
        <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </svg>
      <span
        style={{
          textAlign: 'left',
          font: 'var(--px10_13-font-Roboto)',
          letterSpacing: '0px',
          color: '#1E1E1E',
          opacity: '1',
        }}
      >
        {file.name}
      </span>
      {file.isLoading ?
        <svg name={file.name} onClick={() => deleteFile(file.name)} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
          <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
          <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </svg>
        :
        <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
        </Spinner>
      }
    </span>
      )
    }
  });

  const enableTriageConfirmation = () => {
    if (triageConfirmation === false) {
      setTriageConfirmation(true)
    } else {
      setTriageConfirmation(false)
    }
  }

  return (
    <Modal show={modalShow} onHide={Hide} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {t("home_message_trigger.modal_register_message_trigger.new_message_trigger")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{ date: '', hour: '', message: '' }}
            validate={values => {
              let errors = {};
              setDate(values.date);
              setHour(values.hour);
              var hoje = new Date().toLocaleString().substr(0, 10)

              if(selected_type.length === 0){
                setErrorSelectType(true);
              }

              if(selected_channel.length === 0){
                setErrorSelectChannel(true);
              }

              if(files.length === 0) {
                setErrorSelectRecipients(true);
              }

              if(selected_template.length === 0) {
                setErrorSelectWhatsappTemplate(true);
              }

              if (!values.date) {
                errors["date"] = t("home_message_trigger.modal_register_message_trigger.fill_in_field");
                setShowfeedbackDate(true);
              }
              else if (converterData(values.date).toString() === "Invalid Date") {
                errors["date"] = t("home_message_trigger.modal_register_message_trigger.invalid_date");
                setShowfeedbackDate(true);
              }
              else if (converterData(values.date).getTime() < converterData(hoje).getTime()) {
                errors["date"] = t("home_message_trigger.modal_register_message_trigger.invalid_date");
                setShowfeedbackDate(true);
              }
              else {
                setShowfeedbackDate(false);
              }

              if (!values.hour) {
                errors["hour"] = t("home_message_trigger.modal_register_message_trigger.fill_in_field");
                setShowfeedbackHour(true);
              }
              else if (converterHora(values.hour).toString() === "Invalid Date" || parseInt(values.hour.split(":")[0]) > 23 || parseInt(values.hour.split(":")[1]) > 59) {
                errors["hour"] = t("home_message_trigger.modal_register_message_trigger.invalid_hour");
                setShowfeedbackHour(true);
              }
              else {
                setShowfeedbackHour(false);
              }

              if (!messageContentHtml) {
                // dispatch(setShowAlertFeedback({ message: "Campo mensagem não preenchido", visibility: true, signalIcon: false }));
                errors["message"] = t("home_message_trigger.modal_register_message_trigger.fill_in_field");
                setShowfeedbackMessage(true);
              }
              else {
                setShowfeedbackMessage(false);
              }

              return errors;
            }}

            onSubmit={async () => {
              switch(selected_channel[0].value){
                case "3f3fa530-fd3e-4018-ab98-4d9c3cd5406a":
                  JsonSend_sector = {
                    "date_scheduled": dataToTimestamp(date, hour),
                    "message": hasEmail ? (messageContentHtml === "<p><br></p>" ? null : (messageContentHtml)) : (messageContentHtml === "<p><br></p>" ? null : messageContentHtml),
                    "file_id": file_id,
                    "messages_sending_type_id": selected_type[0] ? selected_type[0].value : "",
                    "chanel_id": selected_channel[0] ? selected_channel[0].value : "",
                    "email_id": selected_email[0] ? selected_email[0].value : email_principal[0].value
                  }
                  break;
                case "33be81d8-88ac-4e08-bb4d-cf69fd23267f":
                  JsonSend_sector = {
                    "date_scheduled": dataToTimestamp(date, hour),
                    "message": hasWhatsapp ? (messageContentHtml === "<p><br></p>" ? null : changeToText(messageContentHtml)) : (messageContentHtml === "<p><br></p>" ? null : messageContentHtml),
                    "file_id": file_id,
                    "messages_sending_type_id": selected_type[0] ? selected_type[0].value : "",
                    "chanel_id": selected_channel[0] ? selected_channel[0].value : "",
                    "whatsapp_keys_id": whatsAppKeysId.whatsapp_keys_id,
                    "template_message_id": templateMessageId.id
                  }
                  if (selected_template[0]?.is_bot_nlp === true) {
                    if (selected_atendimento[0]?.value === "chatbot") {
                      JsonSend_sector["response_message_level"] = 1
                    } else {
                      JsonSend_sector["response_message_level"] = 2
                    }
                  }

                  if (selected_template[0]?.has_triage === true) {
                    JsonSend_sector["has_triage"] = triageConfirmation
                  }
                  break;
              }

              JsonSend_sector["attachment_ids"] = tempAttachmentIds;

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };
              setDisableButtonSave(true);
              await AppRequesterConst.Post(
                '/message-sending', JsonSend_sector, { headers },
                (response: Object) => {
                  return response;
                },
                (data: { data: { messageSending: any; }; }) => {
                  setDisableButtonSave(false);
                  dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.registered_successfully"), visibility: true, signalIcon: true }));
                  props.callback(data.data.messageSending);
                  Hide();
                },
                (error: ErrorRequestController) => {
                  setDisableButtonSave(false);
                  dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                }, navigate, dispatch, setIsLoading,
                () => { },
                {
                  error422: () => {
                    if (selected_channel && selected_channel[0]?.value === Channels.WhatsApp && tempAttachmentIds?.length > 1)
                      dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.whatsapp_single_file"), visibility: true, signalIcon: false }));
                    else 
                      dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.field_not_selected"), visibility: true, signalIcon: false }));
                  },
                  error400: (error: { response: { data: { message: string }; }; }) => {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                  }
                }
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.type")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    {/* <pre>{JSON.stringify(selected_type)}</pre> */}
                    <SelectComponentController
                      className={!errorSelectType ? "mb-3" : "multiselectFormError"}
                      options={options_types}
                      setOptions={setOptions_types}
                      selecteds={selected_type}
                      setSelecteds={handleSelectChangeType}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t("home_message_trigger.modal_register_message_trigger.trigger_type")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": selected_type[0] ? selected_type[0].label : "",
                          "clearSearch": t("home_message_trigger.filter_option_type.clearSearch"),
                          "clearSelected": t("home_message_trigger.filter_option_type.clearSelected"),
                          "noOptions": t("home_message_trigger.filter_option_type.noOptions"),
                          "search": t("home_message_trigger.modal_register_message_trigger.search_type"),
                          "selectAll": t("home_message_trigger.filter_option_type.selectAll"),
                          "selectAllFiltered": t("home_message_trigger.filter_option_type.selectAllFiltered"),
                          "selectSomeItems": t("home_message_trigger.modal_register_message_trigger.trigger_type"),
                          "create": t("home_message_trigger.filter_option_type.create"),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                    />
                    {errorSelectType && <p style={{ padding: '0', color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>}
                  </Col>
                </Row>

                <Row
                  style={allStyles.textFontGlay}
                  className="mb-3 d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.date_trigger")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputMask
                      mask={"99/99/9999"}
                      maskChar={"_"}
                      value={values.date}
                      onChange={handleChange}
                    >
                      {(inputProps: any) => (
                        <InputGroup>
                          <FormControl
                            isInvalid={showfeedbackDate}
                            className="form-control-Default"
                            placeholder="DD/MM/AAAA"
                            aria-label="DD/MM/AAAA"
                            aria-describedby="DD/MM/AAAA"
                            type="text"
                            size="lg"
                            id="date"
                            name="date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            id="feedbackdate"
                          >
                            {errors.date}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    </InputMask>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.trigger_hour")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputMask
                      mask="99:99"
                      maskChar="_"
                      value={values.hour}
                      onChange={handleChange}
                    >
                      {(inputProps: any) => (
                        <InputGroup>
                          <FormControl
                            className="form-control-Default"
                            placeholder="HH:MM"
                            aria-label="HH:MM"
                            aria-describedby="HH:MM"
                            type="text"
                            size="lg"
                            id="hour"
                            name="hour"
                            isInvalid={showfeedbackHour}
                            onBlur={handleBlur}
                          ></FormControl>
                          <Form.Control.Feedback
                            type="invalid"
                            id="feedbackHour"
                          >
                            {errors.hour}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    </InputMask>
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.channel")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <SelectComponentController
                      className={!errorSelectChannel ? "mb-3" : "multiselectFormError"}
                      options={options_channel}
                      setOptions={setOptions_channel}
                      selecteds={selected_channel}
                      setSelecteds={handleSelectChangeChannel}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t("home_message_trigger.modal_register_message_trigger.select_trigger_channel")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": selected_channel[0] ? selected_channel[0].label : "",
                          // "clearSearch": "Limpar pesquisa",
                          // "clearSelected": "Limpar canais selecionados",
                          "noOptions": t("home_message_trigger.modal_register_message_trigger.noOptions"),
                          "search": t("home_message_trigger.modal_register_message_trigger.search_channel"),
                          // "selectAll": "Selecionar todos os canais",
                          // "selectAllFiltered": "Selecionar todos os canais (Filtrado)",
                          "selectSomeItems": t("home_message_trigger.modal_view_message_trigger.selectd_channel"),
                          // "create": "Criar",
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                      onSelect={selectChannel}
                      isChannel

                    />
                    {errorSelectChannel && <p style={{ padding: '0', color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>}

                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.recipients")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span> <span style={{ font: "normal normal normal calc(0.05em + 0.7vw) Montserrat" }}>({t("home_message_trigger.modal_view_message_trigger.csv_name")}{selected_channel[0] ? selected_channel[0].label : t("home_message_trigger.modal_register_message_trigger.channel")}")</span></Form.Label>
                    <div style={allStyles.changeEditorDropzone}>
                      <div {...getRootProps({ className: 'dropzone' })}
                        style={allStyles.dragDropzone}>
                        <input {...getInputProps({})} />
                        {files.length > 0 && !deleted ?
                          <div name="showFilesName" className="ps-2 py-1 pt-2">
                            { setErrorSelectRecipients(false)}
                            <div style={{ display: 'block' }}>
                              {showFiles}
                            </div>
                          </div>
                          :
                          <div>
                            <div>{selected_channel[0] ? t("home_message_trigger.modal_register_message_trigger.click_and_drag") : t("home_message_trigger.modal_register_message_trigger.select_channel")}</div>
                          </div>
                        }
                      </div>
                    </div>
                    {errorSelectRecipients && <p style={{ padding: '0', color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>}
                  </Col>
                </Row>
                {hasEmail === true ? 
                  <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                    <Col sm={true}>
                      <Form.Label htmlFor='basic-url'>{t("home_message_trigger.modal_view_message_trigger.set_email")}</Form.Label>
                      <SelectComponentController
                        className={"mb-3"}
                        options={options_email}
                        setOptions={setOptions_email}
                        selecteds={selected_email}
                        setSelecteds={setSelected_email}
                        singleSelect={true}
                        isFormNoFilter={true}
                        title={t("home_message_trigger.modal_register_message_trigger.select_email")}
                        overrideStrings={
                          {
                            "allItemsAreSelected": selected_email[0] ? selected_email[0].label && selected_email[0].id : "",
                            "clearSearch": t("home_message_trigger.filter_option_email.clearSearch"),
                            "clearSelected": t("home_message_trigger.filter_option_email.clearSelected"),
                            "noOptions": t("home_message_trigger.filter_option_email.noOptions"),
                            "search": t("home_message_trigger.filter_option_email.search"),
                            "selectAll": t("home_message_trigger.filter_option_email.selectAll"),
                            "selectAllFiltered": t("home_message_trigger.filter_option_email.selectAllFiltered"),
                            "selectSomeItems": `${email_principal[0]?.label} ${t("home_message_trigger.modal_register_message_trigger.main_email")}`,
                            "create": t("home_message_trigger.filter_option_email.create"),
                          }
                        }
                        hasCreateItem={false}
                        hasNullOption={false}
                        hasAllOption={false}
                      />
                    </Col>
                  </Row>
                  : 
                  <></>  
                }

                {hasWhatsapp === true ?
                  <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                    <Col sm={true}>
                      <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.template_whatsapp")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                      <SelectComponentController
                        className={!errorSelectWhatsappTemplate ? "multiselectFormViewTrigger mb-3" : "multiselectFormViewTrigger multiselectFormError"}
                        options={options_templates}
                        setOptions={setOptions_templates}
                        selecteds={selected_template}
                        setSelecteds={handleSelectChangeTemplate}
                        singleSelect={true}
                        isFormNoFilter={true}
                        title={t("home_message_trigger.modal_register_message_trigger.select_template_whatsapp")}
                        overrideStrings={
                          {
                            "allItemsAreSelected": selected_template[0] ? selected_template[0].label && selected_template[0].whatsapp_keys_id && selected_template[0].id && selected_template[0].title_and_number : "",
                            // "clearSearch": "Limpar pesquisa",
                            // "clearSelected": "Limpar canais selecionados",
                            "noOptions": t("home_message_trigger.modal_register_message_trigger.noOptions"),
                            "search": t("home_message_trigger.modal_register_message_trigger.search_whatsapp"),
                            // "selectAll": "Selecionar todos os canais",
                            // "selectAllFiltered": "Selecionar todos os canais (Filtrado)",
                            "selectSomeItems": t("home_message_trigger.modal_register_message_trigger.selecte_template"),
                            // "create": "Criar",
                          }
                        }
                        hasCreateItem={false}
                        hasNullOption={false}
                        hasAllOption={false}
                        onSelect={selectTemplate}
                      />
                      {errorSelectWhatsappTemplate && <p style={{ padding: '0', color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>}

                    </Col>
                  </Row>
                  :
                  <></>
                }

                <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.message")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '1'}}
                      >
                      <ChatEditor 
                        onDropFiles={setTempAttachmentIds}
                        messageTriggerModal={true} 
                        messageChannel={selected_channel[0]? selected_channel[0].value: null} 
                        hideButtonSend={true} isAllowedMessage={hasWhatsapp} blockColor={hasWhatsapp} 
                        callbackGetStringHtml={callbackGetStringHtml}
                        viewTriggerModal={false} 
                        cleanMessageEditor={cleanMessageEditor}
                        templateMessage={templateMessage}>
                      </ChatEditor>
                    </div>
                  </Col>
                  {/* {errorMessage && <p style={{ color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>} */}

                </Row>

                {hasWhatsapp === true && selected_template[0]?.is_bot_nlp === true && selected_template[0]?.bot_enable === true ?
                  <>
                    <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                      <Col sm={true}>
                        <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_register_message_trigger.service_after_trigger")}</Form.Label>
                        <SelectComponentController
                          className={"mb-3"}
                          options={options_atendimento}
                          setOptions={setOptions_atendimento}
                          selecteds={selected_atendimento}
                          setSelecteds={setSelected_atendimento}
                          singleSelect={true}
                          isFormNoFilter={true}
                          title={t("home_message_trigger.modal_register_message_trigger.title_service_after_trigger")}
                          overrideStrings={
                            {
                              "allItemsAreSelected": selected_atendimento[0] ? selected_atendimento[0].label && selected_atendimento[0].value : "",
                              // "clearSearch": "Limpar pesquisa",
                              // "clearSelected": "Limpar selecionado",
                              "noOptions": t("home_message_trigger.modal_register_message_trigger.noOptions"),
                              "search": t("home_message_trigger.modal_register_message_trigger.search_service"),
                              // "selectAllFiltered": "Selecionar todos (Filtrado)",
                              "selectSomeItems": t("home_message_trigger.modal_register_message_trigger.select_service"),
                              // "create": "Criar",
                            }
                          }
                          hasCreateItem={false}
                          hasNullOption={false}
                          hasAllOption={false}
                          disableSearch={true}
                        />
                      </Col>
                    </Row>
                    {selected_template[0]?.has_triage === true ?
                      <>
                        <Row style={allStyles.textFontGlay}>
                          <Col className='d-flex  align-self-center'>
                            <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_register_message_trigger.triage_check")}</Form.Label>
                            <div className="switch-input" style={{ marginLeft: '0.3rem' }}>
                              <label className="switch">
                                <input
                                  disabled={isLoading}
                                  type="checkbox"
                                  checked={triageConfirmation}
                                  onChange={enableTriageConfirmation}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </>
                      :
                      <>
                      </>
                    }
                  </>
                  :
                  <></>
                }

                { (selected_template && selected_template[0]?.is_attachment_allowed || (selected_channel && selected_channel[0]?.value === Channels.Email) ) &&
                  <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                    <AttachmentsInMessageController attachmentType={selected_template && selected_template[0]?.attachment_type} modalVisibility={props.visibility} selectedChannelId={selected_channel[0]?.value} setAttachmentsFilesIds={attachmentIdsCallback} />
                  </Row>
                }

                <Row className="d-flex justify-content-center mt-3 mb-2">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={Hide} >{t("home_message_trigger.modal_view_message_trigger.cancel")}</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button disabled={disableButtonSave} size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t("home_message_trigger.modal_view_message_trigger.save")}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalRegisterMessageTrigger;