import './styles.scss';
import Loading from '../../../components/loading';
import { Button, Col, Container, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { IconDotsVertical, IconListSearch } from '@tabler/icons-react';
import { FC } from 'react';
import { ControlPanelListAccountsInterface } from './indexModel';
import ModalEditPermissionsController from './components/modalEditPermissions/indexController';
import { useSelector } from 'react-redux';
import { getShowAccountEmailConfirmationModal, getShowAccountInvalidApp2FAModal, getShowEditUserPermissionsModal } from '../../../store/internal_controlpanel';
import ModalEmailConfirmationController from './components/modalEmailConfirmation/indexController';
import ModalInvalidApp2FAController from './components/modalInvalidApp2FA/indexController';

const ControlPanelAccounts: FC<ControlPanelListAccountsInterface> = (props) => {

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString();
  }

  const showPermissionsModal = useSelector(getShowEditUserPermissionsModal);
  const showEmailConfirmationModal = useSelector(getShowAccountEmailConfirmationModal);
  const showAccountInvalidApp2FAModal = useSelector(getShowAccountInvalidApp2FAModal);

  return (
    <>
      { showPermissionsModal && <ModalEditPermissionsController currentUser={props.currentUser} showModal={showPermissionsModal} callback={props.getUsers} /> }
      { showEmailConfirmationModal && <ModalEmailConfirmationController currentUser={props.currentUser} showModal={showEmailConfirmationModal} callback={props.getUsers} /> }
      { showAccountInvalidApp2FAModal && <ModalInvalidApp2FAController currentUser={props.currentUser} showModal={showAccountInvalidApp2FAModal} callback={props.getUsers} /> }
      { props.isLoading && <Loading visibility={props.isLoading} noBootstrap={true} /> }
      <Container fluid className="controlPanelAccounts nopadding">
        <div className="wrapper">
          <div className="content pt-0 ps-0">
            <Row className="nopadding mt-3 ps-3">
              <Col md={5} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                <Row>
                  <Col className="d-flex justify-content-start align-self-start">
                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>Contas de CS</div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>Visualiza as contas do painel de controle de CS</div>
                </Row>
              </Col>
            </Row>
            <div style={{ marginTop: '2vh' }} className="ps-3">
              {
                !props.isLoading && props.users?.length === 0 &&
                <div className="listEmpty">
                  <IconListSearch color='#4a69bd' size={56} />
                  <span>Nenhuma conta cadastrada!</span>
                </div>
              }
              {
                props.users?.length > 0 &&
                <>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                      <Row name="ListOfAgentsHead">
                        <Col md={4} className='d-flex align-self-center'>
                          <p>E-mail</p>
                        </Col>
                        <Col md={2} className="d-flex align-self-center">
                          <p>Data de criação</p>
                        </Col>
                        <Col md={2} className="d-flex align-self-center">
                          <p>Data de confirmação</p>
                        </Col>
                        <Col md={2} className="d-flex align-self-center">
                          <p>Permissões</p>
                        </Col>
                        <Col md className='d-flex justify-content-end'>
                          <p>Ações</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>

                  <div style={{ overflowY: 'auto', height: '56vh' }}>
                    <ListGroup variant="flush" className="TicketsRow">
                      {
                        props.users.map((item, index) => {

                          const email = item.email !== null ? <>{item.email}</> : <>N/A</>;
                          const date = formatDate(item.created_at);
                          const confirmation_date = item.email_confirmation_at ? formatDate(item.email_confirmation_at) : <>N/A</>;

                          return (
                            <ListGroup.Item key={index} style={{ color: '#707070' }} className="listItem">
                              <Row className='TicketsRowHeight'>
                                <Col md={4} className='d-flex align-self-center'>
                                  <div className="oneLineClampEllipsis">
                                    <OverlayTrigger placement='top' overlay={ <Tooltip id={`tp_email_${index}`}>{email}</Tooltip>}>
                                      <span className='itemValue'>{email}</span>
                                    </OverlayTrigger>
                                  </div>
                                </Col>
                                <Col md={2} className='d-flex align-self-center'>
                                  <div className="oneLineClampEllipsis">
                                    <OverlayTrigger placement='top' overlay={ <Tooltip id={`tp_date_${index}`}>{date}</Tooltip>}>
                                      <span className='itemValue'>{date}</span>
                                    </OverlayTrigger>
                                  </div>
                                </Col>
                                <Col md={2} className='d-flex align-self-center'>
                                  <div className="oneLineClampEllipsis">
                                    <OverlayTrigger placement='top' overlay={ <Tooltip id={`tp_confirm_date_${index}`}>Não confirmada</Tooltip>}>
                                      <span className='itemValue'>{confirmation_date}</span>
                                    </OverlayTrigger>
                                  </div>
                                </Col>
                                <Col md={2} className='d-flex align-self-center'>
                                  <div className="oneLineClampEllipsis permissionsIcons">
                                      {
                                        item.permissions?.length === 0 ?
                                          <OverlayTrigger placement='top' overlay={<Tooltip id={`tp_permission_${index}`}>Nenhuma permissão</Tooltip>}>
                                            <span className='itemValue'>N/A</span>
                                          </OverlayTrigger>
                                        :
                                        <>
                                          {item.permissions?.map((permission, index) => (
                                            <OverlayTrigger 
                                              key={permission.id}
                                              placement='top' 
                                              overlay={<Tooltip id={`tp_permission_${index}`}>{props.getPermission(permission.control_panel_permission_id).name}</Tooltip>}
                                            >
                                              {props.getPermission(permission.control_panel_permission_id).icon}
                                            </OverlayTrigger>)
                                          )}
                                        </>
                                      }
                                    
                                  </div>
                                </Col>
                                <Col md className='d-flex justify-content-end' style={{ marginRight: '10px' }} >
                                  <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.popover}>
                                    <Button onClick={() => props.setCurrentUser(item)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                      <IconDotsVertical stroke={1.5} color="#6A6A6A" />
                                    </Button>
                                  </OverlayTrigger>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          );
                        })
                      }
                    </ListGroup>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ControlPanelAccounts;
