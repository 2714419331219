import React, { FC, useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { DataCompanyInterface, TokenInterface } from '../../../../../services/requestsInterfacesModel';
import { useDispatch, useSelector } from 'react-redux';
import { getIdCompany } from '../../../../../store/company';
import { useNavigate } from 'react-router-dom';
import IScheduleServiceTab, { ScheduleDaysType, ScheduleEndType, ScheduleInitType } from './indexModel';

import { DateObject, DatePickerProps } from "react-multi-date-picker";
import { getToken } from '../../../../../store/token';
import ScheduleServiceTab from '.';
import { useTranslation } from 'react-i18next';
import { getChatbot } from '../../../../../store/chatbot';

const AppRequesterConst = new AppRequesterController();

const ScheduleServiceTabController: FC<IScheduleServiceTab> = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeSchedule, setActiveSchedule] = useState(true);
    const [activeWeekDays, setActiveWeekDays] = useState({
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
    });

    const [initSchedule, setInitSchedule] = useState<ScheduleDaysType>({ sunday: null, monday: null, tuesday: null, wednesday: null, thursday: null, friday: null, saturday: null });
    const [showFeedbackInit, setShowFeedbackInit] = useState<ScheduleInitType>({ init_sunday: false, init_monday: false, init_tuesday: false, init_wednesday: false, init_thursday: false, init_friday: false, init_saturday: false });
    const [endSchedule, setEndSchedule] = useState<ScheduleDaysType>({ sunday: null, monday: null, tuesday: null, wednesday: null, thursday: null, friday: null, saturday: null });
    const [showFeedbackEnd, setShowFeedbackEnd] = useState<ScheduleEndType>({ end_sunday: false, end_monday: false, end_tuesday: false, end_wednesday: false, end_thursday: false, end_friday: false, end_saturday: false });

    const [messageOffService, setMessageOffService] = useState("Olá! Estamos fora do horário de atendimento. Voltaremos {proxHorario}. Quando nosso time iniciar as atividades, um dos nossos atendentes entrará em contato.");
    const [messageOnService, setMessageOnService] = useState("Olá! Nossa equipe está disponível, em breve um dos nossos atendentes entrará em contato. Por favor, aguarde.");
    const [showFeedbackMessageOffService, setShowFeedbackMessageOffService] = useState(false);
    const [showFeedbackMessageOnService, setShowFeedbackMessageOnService] = useState(false);
    const [transferService, setTransferService] = useState(false);
    const hasBot = useSelector(getChatbot)

    const { t } = useTranslation()

    /// formulário só é mostrado depois de feita a requisição
    const [updateForm, setUpdatedForm] = useState(false);

    const [scheduleDays, setScheduleDays] = useState<DateObject[]>([]);

    // const datePickerRef  = useRef();
    const datePickerRef = React.createRef<DatePickerProps>();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        getScheduleService(controller);

        return () => {
            controller.abort();
        }
    }, []);

    const fullTime = () => {
        let initDays = { init_sunday: null, init_monday: null, init_tuesday: null, init_wednesday: null, init_thursday: null, init_friday: null, init_saturday: null };
        let endDays = { end_sunday: null, end_monday: null, end_tuesday: null, end_wednesday: null, end_thursday: null, end_friday: null, end_saturday: null };
        let activeDays = { sunday_active: true, monday_active: true, tuesday_active: true, wednesday_active: true, thursday_active: true, friday_active: true, saturday_active: true };

        Object.keys(initSchedule).map((day) => {
            if (initSchedule[day] === '' || initSchedule[day] === null) {
                initDays["init_" + day] = "00:00";
                if (endSchedule[day] === '') {
                    endDays["end_" + day] = "23:59";
                } else if (endSchedule[day] === null) {
                    endDays["end_" + day] = "23:59";
                }
            } else if (endSchedule[day] === '') {
                endDays["end_" + day] = "23:59";
            } else if (endSchedule[day] === null) {
                endDays["end_" + day] = "23:59";
            } else {
                initDays["init_" + day] = initSchedule[day] !== "00:00" ? initSchedule[day] : initDays["init_" + day];
                endDays["end_" + day] = endSchedule[day] !== "23:59" ? endSchedule[day] : '23:59';
            }
        });

        return [initDays, endDays, activeDays];
    }

    const getScheduleService = async (controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;

        await AppRequesterConst.Get(
            '/company', { headers, signal },
            (response: Object) => {},
            (data: DataCompanyInterface) => {
                if (data.status === 200) {
                    if (data?.data?.companies) {
                        if (data.data.companies[0].opening_hours !== null /* && data.data.companies[0].use_opening_hours === true */) {
                            let initHours: ScheduleDaysType = initSchedule;
                            let endHours: ScheduleDaysType = endSchedule;

                            let activeDays = activeWeekDays;

                            data.data.companies[0].opening_hours.map((hour) => {
                                initHours[hour["day"]] = hour["start_time"];
                                endHours[hour["day"]] = hour["end_time"];

                                if (hour.start_time === null) {
                                    activeDays[hour.day] = false;
                                } else {
                                    activeDays[hour.day] = true;
                                }
                            });

                            setInitSchedule(initHours);
                            setEndSchedule(endHours);
                            setActiveWeekDays(activeDays);
                            setTransferService(data.data.companies[0].transfer_outside_opening_hours);
                        }

                        if (data.data.companies[0].non_attendance_days !== null) {
                            let non_days = [];
                            data.data.companies[0].non_attendance_days.map((day) => {
                                let dateDay = new Date(day.replaceAll('Z', ""));
                                let formatedDay = new DateObject(dateDay);
                                non_days.push(formatedDay);
                            });

                            setScheduleDays(non_days);
                        }

                        if (data.data.companies[0].use_opening_hours !== null || data.data.companies[0].use_opening_hours !== undefined) {
                            setActiveSchedule(data.data.companies[0].use_opening_hours);
                        }

                        setMessageOffService(data.data.companies[0].message_opening_hours);
                        setMessageOnService(data.data.companies[0].message_in_attendance);
                    }
                }

                setUpdatedForm(true);
            },
            (error: any) => { console.log(error.response); }, navigate, dispatch, props.setIsLoading, { values: values }
        );
    }

    const handleMessageOffService = (e, handleChange) => {
        setMessageOffService(e.target.value);
        handleChange(e);
    }

    const handleMessageOnService = (e, handleChange) => {
        setMessageOnService(e.target.value);
        handleChange(e);
    }

    const HourInputForm = (handleBlur, handleChange, label: string, values, showFeedback, errors) => {
        let weekLabel = label.split('_')[1] + "_active";

        const [isDisabled, setIsDisabled] = useState(false);

        useEffect(() => {
            // let weekLabel = label.split('_')[0] + "_active";
            if (!values.activeSchedule) {
                setIsDisabled(true);
            } else if (values.activeSchedule && !values[weekLabel]) {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }

        }, [values.activeSchedule, values[weekLabel]]);
        
        return (
            <div className='mx-2 mb-4' style={{ /* paddingBottom: '10px' */ }}>
                <FormControl
                    className="form-control-Default  text-center hourInput mt-2"
                    placeholder={""}
                    aria-label={""}
                    aria-describedby={""}
                    type='time'
                    id={label}
                    name={label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[label]}
                    isInvalid={showFeedback[label] && values[weekLabel] && values.activeSchedule}
                    disabled={isDisabled}
                >
                </FormControl>
                <Form.Control.Feedback className='mt-0' type="invalid" id={"feedback" + label} style={{ position: 'absolute', font: "var(--px10_13-font)", textAlign: 'start' }}>
                    {errors[label]}
                </Form.Control.Feedback>
            </div>
        );
    }

    const CheckWeekDay = (id: string, defaulChecked: boolean, handleChange, values, weekDayName: string) => {
        return (
            <InputGroup className='d-flex align-items-center justify-content-start'>
                <Form.Check
                    type='checkbox'
                    id={id}
                    checked={defaulChecked}
                    // defaultChecked={defaulChecked}
                    onChange={handleChange}
                    onClick={() => {
                        let active = document.getElementById(id) as HTMLInputElement;
                        values[id] = active.checked;

                        if (active.checked === false) {
                            values["init_" + id.split("_")[0]] = "";
                            values["end_" + id.split("_")[0]] = "";
                        }
                    }}
                    className="form-check-input-2 d-flex align-items-center"
                    disabled={!values.activeSchedule}
                    label={weekDayName}
                />
            </InputGroup>
        );
    }

    const copyAllWeekDays = (values, setFieldTouched) => {
        // let initDays = initSchedule;
        if (values.init_monday !== null && values.init_monday !== undefined) {
            Object.keys(initSchedule).map((init) => {
                if (values[init + "_active"]){
                    values["init_" + init] = values.init_monday;
                    // initDays[init] = values.init_monday;

                    let initDayInput = document.getElementById("init_" + init) as HTMLInputElement;
                    initDayInput.value = values.init_monday;

                    // setFieldTouched("init_" + init, false, true);
                    // initDayInput.onchange(e);
                }
            });
        }
        // setInitSchedule(initDays);
            
        if (values.end_monday !== null && values.end_monday !== undefined) {
            Object.keys(endSchedule).map((end) => {
                if (values[end + "_active"]) {
                    values["end_" + end] = values.end_monday;

                    let endDayInput = document.getElementById("end_" + end) as HTMLInputElement;
                    endDayInput.value = values.end_monday;

                    // setFieldTouched("end_" + end, true, true);
                }
            });
        }

        // forçar validação dos campos
        setFieldTouched("init_monday", true, true);
    }

    const enableTransferService = () => {
        setTransferService(true);
    }

    const disableTransferService = () => {
        setTransferService(false);
    }

    return <ScheduleServiceTab
        initSchedule={initSchedule}
        endSchedule={endSchedule}
        activeSchedule={activeSchedule}
        activeWeekDays={activeWeekDays}
        messageOffService={messageOffService}
        messageOnService={messageOnService}
        scheduleDays={scheduleDays}
        setInitSchedule={setInitSchedule}
        setEndSchedule={setEndSchedule}
        setActiveSchedule={setActiveSchedule}
        setScheduleDays={setScheduleDays}
        setMessageOffService={setMessageOffService}
        setMessageOnService={setMessageOnService}
        navigate={navigate}
        dispatch={dispatch}
        props={props}
        updateForm={updateForm}
        setShowFeedbackMessageOffService={setShowFeedbackMessageOffService}
        setShowFeedbackMessageOnService={setShowFeedbackMessageOnService}
        setShowFeedbackInit={setShowFeedbackInit}
        showFeedbackInit={showFeedbackInit}
        setShowFeedbackEnd={setShowFeedbackEnd}
        showFeedbackEnd={showFeedbackEnd}
        values={values}
        AppRequesterConst={AppRequesterConst}
        fullTime={fullTime}
        CheckWeekDay={CheckWeekDay}
        HourInputForm={HourInputForm}
        copyAllWeekDays={copyAllWeekDays}
        handleMessageOffService={handleMessageOffService}
        handleMessageOnService={handleMessageOnService}
        datePickerRef={datePickerRef}
        showFeedbackMessageOffService={showFeedbackMessageOffService}
        showFeedbackMessageOnService={showFeedbackMessageOnService}
        t={t}
        transferService={transferService}
        enableTransferService={enableTransferService}
        disableTransferService={disableTransferService}
        hasBot={hasBot}
    />

}

export default ScheduleServiceTabController;