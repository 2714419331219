import { FC, useEffect, useState } from "react";
import { IImageViewerController } from "./indexModel";
import ImageViewer from ".";

const ImageViewerController: FC<IImageViewerController> = (props) => {
    const [showImgViewer, setShowImgViewer] = useState(false);
    const [currentImgViewer, setCurrentImgViewer] = useState(0);

    const [attachmentImages, setAttachmentImages] = useState([]);

    // filtrando as imagens
    useEffect(() => {
        if (props.attachments?.length > 0) {
            setAttachmentImages(
                props.attachments.filter((image) => image !== undefined)
            );
        } else {
            setAttachmentImages([]);
        }
    }, [props.attachments]);

    // corrigindo fade que está sendo aplicado por algum motivo desconhecido
    /* useEffect(() => {
        if (showImgViewer) {
            let fades;
            fades = document.getElementsByClassName("fade");

            // removendo uso da classe fade que esconde o image viewer
            if (fades && fades.length > 0) {
                for (let index = 0; index < fades.length; index++) {
                    const element = fades[index];
                    
                    fades[index].classList = [];
                }
            }
        }
            
    }, [showImgViewer, document.getElementById("viewerBackdrop")]); */

    const onClickImage = (index) => {
        setShowImgViewer(true); 
        setCurrentImgViewer(index);

        setTimeout(() => {
            document.getElementById("viewerBackdrop").parentElement.style.opacity = '1';
        }, 100);
    }

    return <ImageViewer 
        // attachments={props.attachments} 
        attachmentImages={attachmentImages}
        currentImgViewer={currentImgViewer} 
        setCurrentImgViewer={setCurrentImgViewer} 
        showImgViewer={showImgViewer} 
        setShowImgViewer={setShowImgViewer}   
        onClickImage={onClickImage} 
    />
}

export default ImageViewerController;