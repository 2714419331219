import Multiselect from 'multiselect-react-dropdown';
import React, { FC, useEffect, useState } from 'react';
import { TagsComponentInterface } from './indexModel';
import { TagsComponentController } from './indexController';
import { t } from 'i18next';

const TagsComponent: FC<TagsComponentInterface> = (props) => {
    const TagsComponentControllerConst = new TagsComponentController(props);

    const refSelect = React.createRef<Multiselect>();

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        props.setCurrentTags((tags) => {
            if (tags.length === 0) {
                refSelect.current.resetSelectedValues();
            }
            return tags;
        });
    }, [props.currentTags]);

    useEffect(() => {
        if (refSelect && refSelect.current !== null && props.onSearch) {
            refSelect.current.onChange = overrideOnChange;
        }
    }, [refSelect]);

    useEffect(() => {
        if (props.onSearch) {
            refSelect.current.setState({ inputValue: inputValue });
            if(props.onSearch)
                props.onSearch(inputValue);

            if (props.abortConst)
                return () => {
                    props.abortConst.abort();
                }
        }
    }, [inputValue]);

    const overrideOnChange = (event) => {
       setInputValue(event.target.value);
    }

    return (
        <Multiselect
            options={props.tags}
            selectedValues={props.currentTags}
            onSelect={(selectedList) => TagsComponentControllerConst.onSelectTags(selectedList)}
            onRemove={(selectedList) => TagsComponentControllerConst.onRemoveTags(selectedList)}
            displayValue="name"
            placeholder=""
            disable={props.disabled}
            ref={refSelect}
            emptyRecordMsg={t("home_consumers.modal_register.no_results_tags")}
            customCloseIcon={<><svg id="Grupo_11452" data-name="Grupo 11452" xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 24 24">
                <path id="Caminho_9734" data-name="Caminho 9734" d="M0,0H24V24H0Z" fill="none" />
                <line id="Linha_394" data-name="Linha 394" x1="12" y2="12" transform="translate(6 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Linha_395" data-name="Linha 395" x2="12" y2="12" transform="translate(6 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </svg></>}
            style={{
                multiselectContainer: { // To change css for multiselect (Width,height,etc..)
                    minHeight: 'min(max(35px, 4.7vh), 70px)',
                    background: 'var(--white-color) 0% 0% no-repeat padding-box',
                    border: '1px solid #ced4da',
                    borderRadius: '0.3rem',
                },
                searchBox: { // To change search box element look
                    border: 'none',
                    font: 'var(--px15_19-font-Roboto)',
                    padding:'0'
                },
                inputField: { // To change input field position or margin
                    margin: '10px',
                },
                chips: { // To change css chips(Selected options)
                    background: '#e9ecef',
                    color: 'rgb(112, 112, 112)',
                    borderRadius: '4px',
                    margin:'0.5rem',
                    font: 'var(--px15_19-font-Roboto)',
                },
                optionContainer: { // To change css for option container 
                    border: '1px solid rgba(0,0,0,.15)',
                    overflowY: 'auto',
                    maxHeight: '250px'
                },
                option: { // To change css for dropdown options
                    backgroundColor: 'white',
                    font: 'var(--px15_19-font-Roboto)',
                    color: 'rgb(112, 112, 112)',
                    paddingTop: '1rem',
                    paddingBottom: '1rem'
                },
                groupHeading: { // To chanage group heading style
                }
            }}
        />
    );
}


export default TagsComponent;