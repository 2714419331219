import React, { FC } from "react";
import { useSelector } from 'react-redux'

import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { ContractStatus } from "../../core/enums/contract-status";
import { getShowAcquirePlatformComponent } from "../../store/internal";

import peoples_img from "./peoples.png";

import Styles from './styles';

import LinksConstants from "../../core/constants/links-contants";

import Loading from '../../components/loading';

import { IEndTrial } from "./indexModel";

const EndTrial: FC<IEndTrial> = (props) => {
    return (
        <Container fluid>
            <Loading visibility={props.isLoading}></Loading>
            <Row style={{ height: '22vh' }}>
                <Col className="d-flex justify-content-center align-items-start mt-4">
                    <svg style={{ maxWidth: '10%' }} xmlns="http://www.w3.org/2000/svg" width="161" height="32.66" viewBox="0 0 161 32.66">
                        <g id="Grupo_11117" data-name="Grupo 11117" transform="translate(0 0.001)">
                            <g id="Grupo_11" data-name="Grupo 11" transform="translate(59.062 3.601)">
                                <path id="Caminho_63" data-name="Caminho 63" d="M24.322-35.137a10.747,10.747,0,0,1,.9,4.507,10.453,10.453,0,0,1-.9,4.507,7.615,7.615,0,0,1-3.068,3.5,9.227,9.227,0,0,1-4.871,1.248,9.4,9.4,0,0,1-3.588-.659,7.084,7.084,0,0,1-2.721-1.941v9.082H3.7V-39.3H9.9v2.219a7.054,7.054,0,0,1,2.756-2.1,9.319,9.319,0,0,1,3.727-.711,9.227,9.227,0,0,1,4.871,1.248A7.615,7.615,0,0,1,24.322-35.137Zm-6.205,6.794A5.454,5.454,0,0,0,18.5-30.63a5.56,5.56,0,0,0-.381-2.323,3.258,3.258,0,0,0-1.491-1.56,5.145,5.145,0,0,0-2.392-.52,4.4,4.4,0,0,0-3.085,1.075,3.919,3.919,0,0,0-1.179,3.051v.555a4.455,4.455,0,0,0,.537,2.253,3.408,3.408,0,0,0,1.508,1.4,5.047,5.047,0,0,0,2.219.468,5.007,5.007,0,0,0,2.392-.537A3.347,3.347,0,0,0,18.117-28.342Z" transform="translate(-3.696 39.886)" fill="#0c2461" />
                                <path id="Caminho_62" data-name="Caminho 62" d="M3.7-39.254h6.309v1.664a5.267,5.267,0,0,1,1.664-1.716,3.935,3.935,0,0,1,2.011-.5,3.79,3.79,0,0,1,2.721.971,3.416,3.416,0,0,1,1.023,2.6,3.767,3.767,0,0,1-.451,1.855,3.174,3.174,0,0,1-1.265,1.265,3.767,3.767,0,0,1-1.855.451,3.714,3.714,0,0,1-1.907-.485,2.9,2.9,0,0,1-1.213-1.317.978.978,0,0,0-.52.485,3.473,3.473,0,0,0-.139,1.213v10.885H3.7Z" transform="translate(20.165 39.809)" fill="#0c2461" />
                                <path id="Caminho_60" data-name="Caminho 60" d="M16.023-26.574a2.008,2.008,0,0,0,.693-1.144h6.448a6.227,6.227,0,0,1-2.045,3.813A8.649,8.649,0,0,1,17.5-21.946a17.782,17.782,0,0,1-4.8.607,14.617,14.617,0,0,1-6.569-1.369A7.8,7.8,0,0,1,2.26-26.644a9.594,9.594,0,0,1-.8-3.987A11.454,11.454,0,0,1,1.654-32.8a8.2,8.2,0,0,1,.607-1.889,7.8,7.8,0,0,1,3.8-3.883,13.913,13.913,0,0,1,6.257-1.317,13.016,13.016,0,0,1,6.292,1.4,8.241,8.241,0,0,1,3.727,4,10.666,10.666,0,0,1,.745,2.444A17.1,17.1,0,0,1,23.3-29.07H7.911a3.754,3.754,0,0,0,.347,1.283,2.981,2.981,0,0,0,1.629,1.595,7.017,7.017,0,0,0,2.843.52,7.776,7.776,0,0,0,1.924-.225A3.106,3.106,0,0,0,16.023-26.574Zm-3.64-9.082a5.813,5.813,0,0,0-2.583.52,3.212,3.212,0,0,0-1.543,1.56,3.9,3.9,0,0,0-.347,1.109h8.978a6.644,6.644,0,0,0-.312-1.109,3.2,3.2,0,0,0-1.56-1.56A6.016,6.016,0,0,0,12.383-35.657Z" transform="translate(38.155 39.886)" fill="#0c2461" />
                                <path id="Caminho_61" data-name="Caminho 61" d="M10.63-21.305a14.357,14.357,0,0,1-5.425-.9A5.345,5.345,0,0,1,2.172-24.98a7.479,7.479,0,0,1-.555-2.669H7.961A2.569,2.569,0,0,0,8.2-26.436q.52,1.248,2.531,1.248a4.217,4.217,0,0,0,1.491-.225,1.352,1.352,0,0,0,.8-.676,1.1,1.1,0,0,0,.139-.555,1.17,1.17,0,0,0-.139-.589,1.483,1.483,0,0,0-.693-.659,6.843,6.843,0,0,0-1.456-.52q-.936-.243-2.011-.451l-.832-.173A13.718,13.718,0,0,1,4.6-30.284a4.6,4.6,0,0,1-2.011-2.08A5.1,5.1,0,0,1,2.206-34.3a4.511,4.511,0,0,1,.451-2.045A5.216,5.216,0,0,1,5.673-39a13.793,13.793,0,0,1,5.131-.884,13.323,13.323,0,0,1,5.061.867,5.151,5.151,0,0,1,2.912,2.635,5.262,5.262,0,0,1,.485,2.253H13.126a2.315,2.315,0,0,0-.208-1.04A2.194,2.194,0,0,0,10.7-36.281q-1.595,0-1.941.763a1.231,1.231,0,0,0-.139.555.821.821,0,0,0,.139.52,1.918,1.918,0,0,0,1.231.9,24.329,24.329,0,0,0,2.721.659l.832.173a13.707,13.707,0,0,1,3.848,1.352,4.649,4.649,0,0,1,2.045,2.184,4.334,4.334,0,0,1,.381,1.941A5.843,5.843,0,0,1,19.4-24.98a5.489,5.489,0,0,1-3.207,2.773A15.471,15.471,0,0,1,10.63-21.305Z" transform="translate(61.862 39.886)" fill="#0c2461" />
                                <path id="Caminho_59" data-name="Caminho 59" d="M10.63-21.305a14.357,14.357,0,0,1-5.425-.9A5.345,5.345,0,0,1,2.172-24.98a7.479,7.479,0,0,1-.555-2.669H7.961A2.569,2.569,0,0,0,8.2-26.436q.52,1.248,2.531,1.248a4.217,4.217,0,0,0,1.491-.225,1.352,1.352,0,0,0,.8-.676,1.1,1.1,0,0,0,.139-.555,1.17,1.17,0,0,0-.139-.589,1.483,1.483,0,0,0-.693-.659,6.843,6.843,0,0,0-1.456-.52q-.936-.243-2.011-.451l-.832-.173A13.718,13.718,0,0,1,4.6-30.284a4.6,4.6,0,0,1-2.011-2.08A5.1,5.1,0,0,1,2.206-34.3a4.511,4.511,0,0,1,.451-2.045A5.216,5.216,0,0,1,5.673-39a13.793,13.793,0,0,1,5.131-.884,13.323,13.323,0,0,1,5.061.867,5.151,5.151,0,0,1,2.912,2.635,5.262,5.262,0,0,1,.485,2.253H13.126a2.315,2.315,0,0,0-.208-1.04A2.194,2.194,0,0,0,10.7-36.281q-1.595,0-1.941.763a1.231,1.231,0,0,0-.139.555.821.821,0,0,0,.139.52,1.918,1.918,0,0,0,1.231.9,24.329,24.329,0,0,0,2.721.659l.832.173a13.707,13.707,0,0,1,3.848,1.352,4.649,4.649,0,0,1,2.045,2.184,4.334,4.334,0,0,1,.381,1.941A5.843,5.843,0,0,1,19.4-24.98a5.489,5.489,0,0,1-3.207,2.773A15.471,15.471,0,0,1,10.63-21.305Z" transform="translate(82.121 39.886)" fill="#0c2461" />
                            </g>
                            <path id="Caminho_87" data-name="Caminho 87" d="M16.369-30.049a17.28,17.28,0,0,1-8.164-1.785A10.652,10.652,0,0,1,3.3-37.052a14.99,14.99,0,0,1-1.144-5.963A14.583,14.583,0,0,1,3.3-48.942a10.814,10.814,0,0,1,4.94-5.217,17.056,17.056,0,0,1,8.129-1.82,18.322,18.322,0,0,1,5.755.867A12.274,12.274,0,0,1,26.6-52.582a9.2,9.2,0,0,1,2.34,3.189,11.066,11.066,0,0,1,.919,3.987h-7.21a5.226,5.226,0,0,0-.537-1.855,5.051,5.051,0,0,0-1.127-1.473,6.727,6.727,0,0,0-4.715-1.525,7.881,7.881,0,0,0-3.813.849,5.131,5.131,0,0,0-2.288,2.479,7.286,7.286,0,0,0-.52,1.716,12.417,12.417,0,0,0-.173,2.2,9.8,9.8,0,0,0,.659,3.917,4.973,4.973,0,0,0,2.34,2.5,8.253,8.253,0,0,0,3.831.832,9.083,9.083,0,0,0,2.773-.4,5.581,5.581,0,0,0,2.115-1.2,4.543,4.543,0,0,0,1.057-1.4,4.688,4.688,0,0,0,.468-1.716h7.21a9.961,9.961,0,0,1-.884,3.883,9.612,9.612,0,0,1-2.305,3.12,12.4,12.4,0,0,1-4.541,2.548A18.635,18.635,0,0,1,16.369-30.049Z" transform="translate(-2.156 59.13)" fill="#0c2461" />
                            <g id="Grupo_42" data-name="Grupo 42" transform="translate(27.768 -0.001)">
                                <path id="União_5" data-name="União 5" d="M0,32.225,10.386,16.33l1.6,2.7-1.6-2.7,4.355-7.344.085.138-.081-.136S19.818.606,24.466.147c4.6-.453,5.029.288,5.029.288L19.108,16.33l-.006-.01-4.356,7.353v0s-5.07,8.379-9.718,8.838c-1.078.106-1.927.147-2.594.147C.25,32.66,0,32.225,0,32.225Zm5.034.287h0ZM24.458.148h0Zm.008,0h0Z" fill="#0c2461" />
                                <path id="União_6" data-name="União 6" d="M0,.435,10.386,16.33l1.6-2.7-1.6,2.7,4.355,7.344.085-.138-.081.136s5.071,8.379,9.719,8.838c4.6.453,5.029-.288,5.029-.288L19.108,16.33l-.006.01L14.746,8.986v0S9.677.606,5.029.147C3.951.041,3.1,0,2.434,0,.25,0,0,.435,0,.435ZM5.034.147h0ZM24.458,32.512h0Zm.008,0h0Z" transform="translate(0 0)" fill="#4a69bd" />
                            </g>
                        </g>
                    </svg>
                </Col>
            </Row>
            <Row style={{ height: '32vh' }}>
                <Col style={{ height: "100%" }} className="d-flex justify-content-center">
                    <Image src={peoples_img} height="100%" alt={props.t("endTrial.accountAcquisitionMessage")} />
                </Col>
            </Row>
            <Row style={{ height: '23vh' }} className="d-flex align-items-start justify-content-center">
                {useSelector(getShowAcquirePlatformComponent).currentContractStatusId === ContractStatus.EndTrial ?
                    <Col style={Styles.box} sm={9} md={9} className="nopadding py-4 mt-4">
                        <Row classname="d-flex justify-content-center nopadding">
                            <p style={Styles.title} className="nopadding">
                                {props.t("endTrial.endTrialMessage")}
                            </p>
                        </Row>
                        <Row className="nopadding mt-2">
                            <p style={Styles.text}>
                            {props.t("endTrial.accountAcquisitionMessage")}
                            </p>
                        </Row>
                        <Row className="d-flex justify-content-center nopadding">
                            <div style={Styles.button}>
                                <a target="_blank" rel="noreferrer" href={LinksConstants.homeBuild(props.values.user.name, props.values.company.subdomain)}/* "https://home.cxpress.io" */><Button className="buttonBlue">{props.t("endTrial.acquire_platform")}</Button></a>
                            </div>
                        </Row>
                    </Col>
                    :
                    <Col style={Styles.box} sm={9} md={9} className="nopadding py-4 mt-4">
                        <Row classname="d-flex justify-content-center nopadding">
                            <p style={Styles.title} className="nopadding">
                            {props.t("endTrial.noActiveSubscriptionMessage")}
                            </p>
                        </Row>
                        <Row className="nopadding mt-2">
                            <p style={Styles.text}>
                            {props.t("endTrial.reactivationInstructionsMessage")}
                            </p>
                        </Row>
                        <Row className="d-flex justify-content-center nopadding">
                            <div style={Styles.button}>
                                <Button className="buttonBlue">{props.t("endTrial.request_contact")}</Button>
                            </div>
                        </Row>
                    </Col>
                }
            </Row>
            <Row style={{ height: '23vh' }}>
                <Col style={{ width: '50%' }} className="nopadding d-flex align-items-end">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1381.287 78.999">
                        <g id="Grupo_11529" data-name="Grupo 11529" transform="translate(0 -719)">
                            <g id="Grupo_11104" data-name="Grupo 11104" transform="translate(2727.235 3943.429)">
                                <path id="Caminho_9608" data-name="Caminho 9608" d="M480.148,379.926h-35l.018-1.709.054-1.7.09-1.691.126-1.682.161-1.672.2-1.661.23-1.65.264-1.639.3-1.628.331-1.616.364-1.6.4-1.591.428-1.578.46-1.565.491-1.551.522-1.537.553-1.523.583-1.508.613-1.493.643-1.477.672-1.461.7-1.445.729-1.428.757-1.411.785-1.394.812-1.376.839-1.358.866-1.339.892-1.321.918-1.3.943-1.282.969-1.262.993-1.242,1.018-1.221,1.042-1.2,1.065-1.178,1.089-1.156,1.112-1.134,1.134-1.112,1.156-1.089,1.178-1.065,1.2-1.042,1.221-1.018,1.242-.993,1.262-.969,1.282-.944,1.3-.918,1.321-.892,1.339-.866,1.358-.839,1.376-.812,1.394-.785,1.411-.757,1.428-.729,1.445-.7,1.461-.672,1.477-.643,1.493-.613,1.508-.583,1.523-.553,1.537-.522,1.551-.491,1.565-.46,1.578-.428,1.591-.4,1.6-.364,1.616-.331,1.628-.3,1.639-.264,1.65-.23,1.661-.2,1.672-.161,1.682-.126,1.691-.09,1.7-.054,1.709-.018v35l-1.712.033-1.7.1-1.677.162-1.659.225-1.638.287-1.616.347-1.594.406-1.57.464-1.544.521-1.518.576-1.49.63-1.462.683-1.432.735-1.4.786-1.368.835-1.334.884-1.3.931-1.263.976-1.226,1.021-1.187,1.064-1.148,1.107-1.107,1.148-1.064,1.187-1.021,1.226-.977,1.263-.931,1.3-.884,1.334-.835,1.368-.786,1.4-.735,1.432-.684,1.462-.63,1.49-.576,1.518-.521,1.544-.464,1.57-.406,1.594-.347,1.616-.286,1.638-.225,1.658-.162,1.677-.1,1.7-.033,1.712Z" transform="translate(-2677.772 -3525.689)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9610" data-name="Caminho 9610" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-2723.139 -3525.7)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9609" data-name="Caminho 9609" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-2700.572 -3525.7)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9611" data-name="Caminho 9611" d="M787.132,380.03h-16.09v-78.66h16.09Z" transform="translate(-2745.365 -3525.7)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9603" data-name="Caminho 9603" d="M178.967,301.439v78.609l-1.709-.018-1.7-.054-1.691-.09-1.682-.126-1.672-.161-1.661-.2-1.65-.23-1.639-.264-1.628-.3-1.616-.331-1.6-.363-1.591-.4-1.578-.428-1.565-.46-1.551-.491-1.537-.522-1.523-.553-1.508-.583-1.493-.613-1.477-.643-1.461-.672-1.445-.7-1.428-.729-1.411-.757-1.394-.785-1.376-.812-1.358-.839-1.34-.866-1.321-.892-1.3-.918-1.282-.943-1.262-.969-1.242-.993-1.221-1.018-1.2-1.042-1.178-1.066-1.156-1.089-1.134-1.112-1.112-1.134-1.089-1.156-1.066-1.178-1.042-1.2-1.018-1.221-.993-1.241-.969-1.262-.944-1.282-.918-1.3-.892-1.321-.866-1.34-.839-1.358-.812-1.376-.785-1.394-.757-1.411-.729-1.428-.7-1.445-.672-1.461-.643-1.477-.613-1.493-.583-1.508-.553-1.523-.522-1.537-.491-1.551-.46-1.565-.428-1.578-.4-1.591-.364-1.6-.331-1.616-.3-1.628-.264-1.639-.23-1.65-.2-1.661-.161-1.672-.126-1.682-.09-1.691-.054-1.7-.018-1.709h78.609Z" transform="translate(-2606.261 -3525.715)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9604" data-name="Caminho 9604" d="M210.15,301.245l78.608,78.609H210.15Z" transform="translate(-2629.032 -3525.674)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9605" data-name="Caminho 9605" d="M342.687,379.927l-1.71-.018-1.7-.055-1.691-.09-1.681-.126-1.671-.161-1.661-.2-1.65-.23-1.638-.264-1.627-.3-1.615-.331-1.6-.363-1.59-.4-1.577-.428-1.563-.46-1.55-.491-1.536-.522-1.521-.553-1.506-.583-1.491-.613-1.475-.643-1.459-.672-1.443-.7-1.426-.729-1.409-.757-1.392-.785-1.374-.812-1.356-.839-1.337-.866-1.318-.892-1.3-.918-1.279-.944-1.259-.969-1.239-.993-1.218-1.018-1.2-1.042-1.176-1.065-1.154-1.089-1.132-1.112-1.109-1.134-1.086-1.156-1.063-1.178-1.039-1.2-1.015-1.221-.991-1.241-.966-1.262-.941-1.282-.916-1.3-.89-1.321-.863-1.339-.837-1.358-.81-1.376-.783-1.394-.755-1.411-.727-1.428-.7-1.445-.67-1.461-.641-1.477-.611-1.493-.581-1.508-.551-1.523-.521-1.537-.49-1.551-.458-1.565-.427-1.578-.395-1.591-.362-1.6-.329-1.616-.3-1.628-.263-1.64-.229-1.651-.195-1.661-.16-1.672-.125-1.682-.09-1.691-.054-1.7-.018-1.71H421.3l-.018,1.71-.054,1.7-.09,1.691-.126,1.682-.161,1.672-.2,1.661-.23,1.651-.264,1.64-.3,1.628-.331,1.616-.363,1.6-.4,1.591-.428,1.578-.46,1.565-.491,1.551-.522,1.537-.553,1.523-.583,1.508-.613,1.493-.643,1.477-.672,1.461-.7,1.445-.729,1.428-.757,1.411-.785,1.394-.812,1.376-.839,1.358-.866,1.339-.892,1.321-.918,1.3-.944,1.282-.968,1.262-.993,1.241-1.018,1.221-1.042,1.2-1.065,1.178-1.089,1.156-1.112,1.134-1.134,1.112-1.156,1.089-1.178,1.065-1.2,1.042-1.221,1.018-1.241.993-1.262.969-1.282.944-1.3.918-1.321.892-1.339.866-1.358.839-1.376.812-1.394.785-1.411.757-1.428.729-1.445.7-1.461.672-1.477.643-1.493.613-1.508.583-1.523.553-1.537.522-1.551.491-1.565.46-1.578.428-1.591.4-1.6.363-1.616.331-1.628.3-1.639.264-1.651.23-1.661.2-1.672.161-1.682.126-1.691.09-1.7.055-1.71.018Z" transform="translate(-2640.245 -3525.689)" fill="#ff8119" fill-rule="evenodd" />
                                <path id="Caminho_9601" data-name="Caminho 9601" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-3304.876 -3525.7)" fill="#ff8119" fill-rule="evenodd" />
                                <path id="Caminho_9602" data-name="Caminho 9602" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-3282.31 -3525.7)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9606" data-name="Caminho 9606" d="M814.872,380.03H771.041v-78.66h43.831Z" transform="translate(-3327.103 -3525.7)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9613" data-name="Caminho 9613" d="M0,0,78.608,78.609H0Z" transform="translate(-1949.845 -3145.623) rotate(-90)" fill="#0c2461" fill-rule="evenodd" />
                            </g>
                            <path id="Caminho_9603-2" data-name="Caminho 9603" d="M178.967,301.439v78.609l-1.709-.018-1.7-.054-1.691-.09-1.682-.126-1.672-.161-1.661-.2-1.65-.23-1.639-.264-1.628-.3-1.616-.331-1.6-.363-1.591-.4-1.578-.428-1.565-.46-1.551-.491-1.537-.522-1.523-.553-1.508-.583-1.493-.613-1.477-.643-1.461-.672-1.445-.7-1.428-.729-1.411-.757-1.394-.785-1.376-.812-1.358-.839-1.34-.866-1.321-.892-1.3-.918-1.282-.943-1.262-.969-1.242-.993-1.221-1.018-1.2-1.042-1.178-1.066-1.156-1.089-1.134-1.112-1.112-1.134-1.089-1.156-1.066-1.178-1.042-1.2-1.018-1.221-.993-1.241-.969-1.262-.944-1.282-.918-1.3-.892-1.321-.866-1.34-.839-1.358-.812-1.376-.785-1.394-.757-1.411-.729-1.428-.7-1.445-.672-1.461-.643-1.477-.613-1.493-.583-1.508-.553-1.523-.522-1.537-.491-1.551-.46-1.565-.428-1.578-.4-1.591-.364-1.6-.331-1.616-.3-1.628-.264-1.639-.23-1.65-.2-1.661-.161-1.672-.126-1.682-.09-1.691-.054-1.7-.018-1.709h78.609Z" transform="translate(993.974 417.907)" fill="#0c2461" fill-rule="evenodd" />
                            <path id="Caminho_9604-2" data-name="Caminho 9604" d="M210.15,301.245l78.608,78.609H210.15Z" transform="translate(971.203 417.948)" fill="#4a69bd" fill-rule="evenodd" />
                            <path id="Caminho_9605-2" data-name="Caminho 9605" d="M342.687,379.927l-1.71-.018-1.7-.055-1.691-.09-1.681-.126-1.671-.161-1.661-.2-1.65-.23-1.638-.264-1.627-.3-1.615-.331-1.6-.363-1.59-.4-1.577-.428-1.563-.46-1.55-.491-1.536-.522-1.521-.553-1.506-.583-1.491-.613-1.475-.643-1.459-.672-1.443-.7-1.426-.729-1.409-.757-1.392-.785-1.374-.812-1.356-.839-1.337-.866-1.318-.892-1.3-.918-1.279-.944-1.259-.969-1.239-.993-1.218-1.018-1.2-1.042-1.176-1.065-1.154-1.089-1.132-1.112-1.109-1.134-1.086-1.156-1.063-1.178-1.039-1.2-1.015-1.221-.991-1.241-.966-1.262-.941-1.282-.916-1.3-.89-1.321-.863-1.339-.837-1.358-.81-1.376-.783-1.394-.755-1.411-.727-1.428-.7-1.445-.67-1.461-.641-1.477-.611-1.493-.581-1.508-.551-1.523-.521-1.537-.49-1.551-.458-1.565-.427-1.578-.395-1.591-.362-1.6-.329-1.616-.3-1.628-.263-1.64-.229-1.651-.195-1.661-.16-1.672-.125-1.682-.09-1.691-.054-1.7-.018-1.71H421.3l-.018,1.71-.054,1.7-.09,1.691-.126,1.682-.161,1.672-.2,1.661-.23,1.651-.264,1.64-.3,1.628-.331,1.616-.363,1.6-.4,1.591-.428,1.578-.46,1.565-.491,1.551-.522,1.537-.553,1.523-.583,1.508-.613,1.493-.643,1.477-.672,1.461-.7,1.445-.729,1.428-.757,1.411-.785,1.394-.812,1.376-.839,1.358-.866,1.339-.892,1.321-.918,1.3-.944,1.282-.968,1.262-.993,1.241-1.018,1.221-1.042,1.2-1.065,1.178-1.089,1.156-1.112,1.134-1.134,1.112-1.156,1.089-1.178,1.065-1.2,1.042-1.221,1.018-1.241.993-1.262.969-1.282.944-1.3.918-1.321.892-1.339.866-1.358.839-1.376.812-1.394.785-1.411.757-1.428.729-1.445.7-1.461.672-1.477.643-1.493.613-1.508.583-1.523.553-1.537.522-1.551.491-1.565.46-1.578.428-1.591.4-1.6.363-1.616.331-1.628.3-1.639.264-1.651.23-1.661.2-1.672.161-1.682.126-1.691.09-1.7.055-1.71.018Z" transform="translate(959.991 417.933)" fill="#ff8119" fill-rule="evenodd" />
                            <path id="Caminho_9601-2" data-name="Caminho 9601" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(295.359 417.922)" fill="#ff8119" fill-rule="evenodd" />
                            <path id="Caminho_9602-2" data-name="Caminho 9602" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(317.926 417.922)" fill="#0c2461" fill-rule="evenodd" />
                            <path id="Caminho_9606-2" data-name="Caminho 9606" d="M814.872,380.03H771.041v-78.66h43.831Z" transform="translate(273.132 417.922)" fill="#4a69bd" fill-rule="evenodd" />
                        </g>
                    </svg>
                </Col>
                <Col style={{ width: '50%' }} className="nopadding d-flex align-items-end">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1381.287 78.999">
                        <g id="Grupo_11529" data-name="Grupo 11529" transform="translate(0 -719)">
                            <g id="Grupo_11104" data-name="Grupo 11104" transform="translate(2727.235 3943.429)">
                                <path id="Caminho_9608" data-name="Caminho 9608" d="M480.148,379.926h-35l.018-1.709.054-1.7.09-1.691.126-1.682.161-1.672.2-1.661.23-1.65.264-1.639.3-1.628.331-1.616.364-1.6.4-1.591.428-1.578.46-1.565.491-1.551.522-1.537.553-1.523.583-1.508.613-1.493.643-1.477.672-1.461.7-1.445.729-1.428.757-1.411.785-1.394.812-1.376.839-1.358.866-1.339.892-1.321.918-1.3.943-1.282.969-1.262.993-1.242,1.018-1.221,1.042-1.2,1.065-1.178,1.089-1.156,1.112-1.134,1.134-1.112,1.156-1.089,1.178-1.065,1.2-1.042,1.221-1.018,1.242-.993,1.262-.969,1.282-.944,1.3-.918,1.321-.892,1.339-.866,1.358-.839,1.376-.812,1.394-.785,1.411-.757,1.428-.729,1.445-.7,1.461-.672,1.477-.643,1.493-.613,1.508-.583,1.523-.553,1.537-.522,1.551-.491,1.565-.46,1.578-.428,1.591-.4,1.6-.364,1.616-.331,1.628-.3,1.639-.264,1.65-.23,1.661-.2,1.672-.161,1.682-.126,1.691-.09,1.7-.054,1.709-.018v35l-1.712.033-1.7.1-1.677.162-1.659.225-1.638.287-1.616.347-1.594.406-1.57.464-1.544.521-1.518.576-1.49.63-1.462.683-1.432.735-1.4.786-1.368.835-1.334.884-1.3.931-1.263.976-1.226,1.021-1.187,1.064-1.148,1.107-1.107,1.148-1.064,1.187-1.021,1.226-.977,1.263-.931,1.3-.884,1.334-.835,1.368-.786,1.4-.735,1.432-.684,1.462-.63,1.49-.576,1.518-.521,1.544-.464,1.57-.406,1.594-.347,1.616-.286,1.638-.225,1.658-.162,1.677-.1,1.7-.033,1.712Z" transform="translate(-2677.772 -3525.689)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9610" data-name="Caminho 9610" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-2723.139 -3525.7)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9609" data-name="Caminho 9609" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-2700.572 -3525.7)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9611" data-name="Caminho 9611" d="M787.132,380.03h-16.09v-78.66h16.09Z" transform="translate(-2745.365 -3525.7)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9603" data-name="Caminho 9603" d="M178.967,301.439v78.609l-1.709-.018-1.7-.054-1.691-.09-1.682-.126-1.672-.161-1.661-.2-1.65-.23-1.639-.264-1.628-.3-1.616-.331-1.6-.363-1.591-.4-1.578-.428-1.565-.46-1.551-.491-1.537-.522-1.523-.553-1.508-.583-1.493-.613-1.477-.643-1.461-.672-1.445-.7-1.428-.729-1.411-.757-1.394-.785-1.376-.812-1.358-.839-1.34-.866-1.321-.892-1.3-.918-1.282-.943-1.262-.969-1.242-.993-1.221-1.018-1.2-1.042-1.178-1.066-1.156-1.089-1.134-1.112-1.112-1.134-1.089-1.156-1.066-1.178-1.042-1.2-1.018-1.221-.993-1.241-.969-1.262-.944-1.282-.918-1.3-.892-1.321-.866-1.34-.839-1.358-.812-1.376-.785-1.394-.757-1.411-.729-1.428-.7-1.445-.672-1.461-.643-1.477-.613-1.493-.583-1.508-.553-1.523-.522-1.537-.491-1.551-.46-1.565-.428-1.578-.4-1.591-.364-1.6-.331-1.616-.3-1.628-.264-1.639-.23-1.65-.2-1.661-.161-1.672-.126-1.682-.09-1.691-.054-1.7-.018-1.709h78.609Z" transform="translate(-2606.261 -3525.715)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9604" data-name="Caminho 9604" d="M210.15,301.245l78.608,78.609H210.15Z" transform="translate(-2629.032 -3525.674)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9605" data-name="Caminho 9605" d="M342.687,379.927l-1.71-.018-1.7-.055-1.691-.09-1.681-.126-1.671-.161-1.661-.2-1.65-.23-1.638-.264-1.627-.3-1.615-.331-1.6-.363-1.59-.4-1.577-.428-1.563-.46-1.55-.491-1.536-.522-1.521-.553-1.506-.583-1.491-.613-1.475-.643-1.459-.672-1.443-.7-1.426-.729-1.409-.757-1.392-.785-1.374-.812-1.356-.839-1.337-.866-1.318-.892-1.3-.918-1.279-.944-1.259-.969-1.239-.993-1.218-1.018-1.2-1.042-1.176-1.065-1.154-1.089-1.132-1.112-1.109-1.134-1.086-1.156-1.063-1.178-1.039-1.2-1.015-1.221-.991-1.241-.966-1.262-.941-1.282-.916-1.3-.89-1.321-.863-1.339-.837-1.358-.81-1.376-.783-1.394-.755-1.411-.727-1.428-.7-1.445-.67-1.461-.641-1.477-.611-1.493-.581-1.508-.551-1.523-.521-1.537-.49-1.551-.458-1.565-.427-1.578-.395-1.591-.362-1.6-.329-1.616-.3-1.628-.263-1.64-.229-1.651-.195-1.661-.16-1.672-.125-1.682-.09-1.691-.054-1.7-.018-1.71H421.3l-.018,1.71-.054,1.7-.09,1.691-.126,1.682-.161,1.672-.2,1.661-.23,1.651-.264,1.64-.3,1.628-.331,1.616-.363,1.6-.4,1.591-.428,1.578-.46,1.565-.491,1.551-.522,1.537-.553,1.523-.583,1.508-.613,1.493-.643,1.477-.672,1.461-.7,1.445-.729,1.428-.757,1.411-.785,1.394-.812,1.376-.839,1.358-.866,1.339-.892,1.321-.918,1.3-.944,1.282-.968,1.262-.993,1.241-1.018,1.221-1.042,1.2-1.065,1.178-1.089,1.156-1.112,1.134-1.134,1.112-1.156,1.089-1.178,1.065-1.2,1.042-1.221,1.018-1.241.993-1.262.969-1.282.944-1.3.918-1.321.892-1.339.866-1.358.839-1.376.812-1.394.785-1.411.757-1.428.729-1.445.7-1.461.672-1.477.643-1.493.613-1.508.583-1.523.553-1.537.522-1.551.491-1.565.46-1.578.428-1.591.4-1.6.363-1.616.331-1.628.3-1.639.264-1.651.23-1.661.2-1.672.161-1.682.126-1.691.09-1.7.055-1.71.018Z" transform="translate(-2640.245 -3525.689)" fill="#ff8119" fill-rule="evenodd" />
                                <path id="Caminho_9601" data-name="Caminho 9601" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-3304.876 -3525.7)" fill="#ff8119" fill-rule="evenodd" />
                                <path id="Caminho_9602" data-name="Caminho 9602" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-3282.31 -3525.7)" fill="#0c2461" fill-rule="evenodd" />
                                <path id="Caminho_9606" data-name="Caminho 9606" d="M814.872,380.03H771.041v-78.66h43.831Z" transform="translate(-3327.103 -3525.7)" fill="#4a69bd" fill-rule="evenodd" />
                                <path id="Caminho_9613" data-name="Caminho 9613" d="M0,0,78.608,78.609H0Z" transform="translate(-1949.845 -3145.623) rotate(-90)" fill="#0c2461" fill-rule="evenodd" />
                            </g>
                            <path id="Caminho_9603-2" data-name="Caminho 9603" d="M178.967,301.439v78.609l-1.709-.018-1.7-.054-1.691-.09-1.682-.126-1.672-.161-1.661-.2-1.65-.23-1.639-.264-1.628-.3-1.616-.331-1.6-.363-1.591-.4-1.578-.428-1.565-.46-1.551-.491-1.537-.522-1.523-.553-1.508-.583-1.493-.613-1.477-.643-1.461-.672-1.445-.7-1.428-.729-1.411-.757-1.394-.785-1.376-.812-1.358-.839-1.34-.866-1.321-.892-1.3-.918-1.282-.943-1.262-.969-1.242-.993-1.221-1.018-1.2-1.042-1.178-1.066-1.156-1.089-1.134-1.112-1.112-1.134-1.089-1.156-1.066-1.178-1.042-1.2-1.018-1.221-.993-1.241-.969-1.262-.944-1.282-.918-1.3-.892-1.321-.866-1.34-.839-1.358-.812-1.376-.785-1.394-.757-1.411-.729-1.428-.7-1.445-.672-1.461-.643-1.477-.613-1.493-.583-1.508-.553-1.523-.522-1.537-.491-1.551-.46-1.565-.428-1.578-.4-1.591-.364-1.6-.331-1.616-.3-1.628-.264-1.639-.23-1.65-.2-1.661-.161-1.672-.126-1.682-.09-1.691-.054-1.7-.018-1.709h78.609Z" transform="translate(993.974 417.907)" fill="#0c2461" fill-rule="evenodd" />
                            <path id="Caminho_9604-2" data-name="Caminho 9604" d="M210.15,301.245l78.608,78.609H210.15Z" transform="translate(971.203 417.948)" fill="#4a69bd" fill-rule="evenodd" />
                            <path id="Caminho_9605-2" data-name="Caminho 9605" d="M342.687,379.927l-1.71-.018-1.7-.055-1.691-.09-1.681-.126-1.671-.161-1.661-.2-1.65-.23-1.638-.264-1.627-.3-1.615-.331-1.6-.363-1.59-.4-1.577-.428-1.563-.46-1.55-.491-1.536-.522-1.521-.553-1.506-.583-1.491-.613-1.475-.643-1.459-.672-1.443-.7-1.426-.729-1.409-.757-1.392-.785-1.374-.812-1.356-.839-1.337-.866-1.318-.892-1.3-.918-1.279-.944-1.259-.969-1.239-.993-1.218-1.018-1.2-1.042-1.176-1.065-1.154-1.089-1.132-1.112-1.109-1.134-1.086-1.156-1.063-1.178-1.039-1.2-1.015-1.221-.991-1.241-.966-1.262-.941-1.282-.916-1.3-.89-1.321-.863-1.339-.837-1.358-.81-1.376-.783-1.394-.755-1.411-.727-1.428-.7-1.445-.67-1.461-.641-1.477-.611-1.493-.581-1.508-.551-1.523-.521-1.537-.49-1.551-.458-1.565-.427-1.578-.395-1.591-.362-1.6-.329-1.616-.3-1.628-.263-1.64-.229-1.651-.195-1.661-.16-1.672-.125-1.682-.09-1.691-.054-1.7-.018-1.71H421.3l-.018,1.71-.054,1.7-.09,1.691-.126,1.682-.161,1.672-.2,1.661-.23,1.651-.264,1.64-.3,1.628-.331,1.616-.363,1.6-.4,1.591-.428,1.578-.46,1.565-.491,1.551-.522,1.537-.553,1.523-.583,1.508-.613,1.493-.643,1.477-.672,1.461-.7,1.445-.729,1.428-.757,1.411-.785,1.394-.812,1.376-.839,1.358-.866,1.339-.892,1.321-.918,1.3-.944,1.282-.968,1.262-.993,1.241-1.018,1.221-1.042,1.2-1.065,1.178-1.089,1.156-1.112,1.134-1.134,1.112-1.156,1.089-1.178,1.065-1.2,1.042-1.221,1.018-1.241.993-1.262.969-1.282.944-1.3.918-1.321.892-1.339.866-1.358.839-1.376.812-1.394.785-1.411.757-1.428.729-1.445.7-1.461.672-1.477.643-1.493.613-1.508.583-1.523.553-1.537.522-1.551.491-1.565.46-1.578.428-1.591.4-1.6.363-1.616.331-1.628.3-1.639.264-1.651.23-1.661.2-1.672.161-1.682.126-1.691.09-1.7.055-1.71.018Z" transform="translate(959.991 417.933)" fill="#ff8119" fill-rule="evenodd" />
                            <path id="Caminho_9601-2" data-name="Caminho 9601" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(295.359 417.922)" fill="#ff8119" fill-rule="evenodd" />
                            <path id="Caminho_9602-2" data-name="Caminho 9602" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(317.926 417.922)" fill="#0c2461" fill-rule="evenodd" />
                            <path id="Caminho_9606-2" data-name="Caminho 9606" d="M814.872,380.03H771.041v-78.66h43.831Z" transform="translate(273.132 417.922)" fill="#4a69bd" fill-rule="evenodd" />
                        </g>
                    </svg>
                </Col>
            </Row>
        </Container>
    );
}

export default EndTrial;