import { Channels } from "../enums/channels";
import { ListName } from "../enums/order-sort-by";
import { TicketStatus } from "../enums/ticket-status";
import countryPhoneCodes from "./constants/country-phone-codes";

export default class Utils {
    public static addLeftZeros = (value: number) => value.toString().padStart(2, '0');

    public static formatedDate = (date: Date) => {
        /*
         * Este método retorna a data seguindo o padrão do protótipo.
         * Com ele, a data sai no formato "dd/mm/aaaa", sempre colocando um zero à esquerda
         * caso necessário. 
         */
        const day = Utils.addLeftZeros(date.getDate());
        const month = Utils.addLeftZeros(date.getMonth() + 1);
        const year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }
    
    public static formatTime = (date: string) => {
        let parsed = new Date(date);

        const hours = Utils.addLeftZeros(parsed.getHours());
        const minutes = Utils.addLeftZeros(parsed.getMinutes());

        return `${hours}:${minutes}`;
    }

    public static getTicketChannelTranslationName(channel: Channels) {
        switch (channel) {
            case Channels.Facebook:
                return "channels_names.facebook";
            case Channels.Instagram:
                return "channels_names.instagram";
            case Channels.Telefonia:
                return "channels_names.telephony";
            case Channels.Webchat:
                return "channels_names.webchat";
            case Channels.WhatsApp:
                return "channels_names.whatsapp";
            case Channels.Email:
                return "channels_names.email";
            default:
                return "N/A";
        }
    }

    public static removeEmptyProperties(data: any) {
        return Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== null));
    }

    public static insertAtTop<T>(item: T, currentList: T[]) : void {
        currentList.splice(0, 0, item);
    }

    public static removeAt(index: number, currentList: any[]) : void {
        currentList.splice(index, 1);
    }

    public static removeWhere() : void {}

    public static getNotificationsQueue(obj: any) : any {
        if(obj) return obj.queue;
    }

    //changing width in list when hiding scroll bar
    public static changeActiveScrollBar(listName: string) : void {
        let listDiv = document.getElementById(listName);
        let headList = document.getElementsByName(listName + "Head")[0];

        if (headList) {
            if (listDiv && (listDiv.scrollHeight > listDiv.clientHeight)) {
                headList.classList.add("LargerHeadListRowColumns");
            } else {
                headList.classList.remove("LargerHeadListRowColumns");
            }
        }
    }

    /**
     * esconde o popover de ações quando houver uma scrolagem da lista
     * @param listName nome da lista (id)
     */
    public static hidePopoverOnScroll (listName: ListName) : void {
        const list = document.querySelector("#" + listName);
        let popover = document.querySelector(".popover.show");

        list.addEventListener("scroll", (event) => {
            if (popover) {
                popover.classList.remove("show");
            }
        });         
    }

    public static changeStyleElementById = (element_id: string, property: string, value: any) => {
        const element = document.getElementById(element_id);
        if (element) {
            document.getElementById(element_id).style[property] = value;
        }
    }

    public static countryPhoneCodes = () => {
        const codes = [];
        countryPhoneCodes.map((country, index) => {
            codes.push(country[3]);
        });

        return codes;
    }

    private static getLuminance(color: string): number {
        // Remove o símbolo "#" se estiver presente
        color = color.replace('#', '');

        // Converte a cor hex para valores RGB
        const r = parseInt(color.substring(0, 2), 16) / 255;
        const g = parseInt(color.substring(2, 4), 16) / 255;
        const b = parseInt(color.substring(4, 6), 16) / 255;

        // Converte RGB para luminância relativa
        const [red, green, blue] = [r, g, b].map((channel) => {
            return channel <= 0.03928
                ? channel / 12.92
                : Math.pow((channel + 0.055) / 1.055, 2.4);
        });

        // Calcula a luminância
        return 0.2126 * red + 0.7152 * green + 0.0722 * blue;
    }

    public static getTextColorForBackground(backgroundColor: string): string {
        const luminance = this.getLuminance(backgroundColor);
        // Se a luminância for menor que 0.5, use branco, senão, use preto
        return luminance < 0.5 ? '#FFFFFF' : '#000000';
    }
}