import { FC, useEffect, useState } from "react";
import { IModalEditTaskController } from "./indexModel";
import ModalEditTask from ".";
import { getShowEditTaskModal, setShowEditTaskModal } from "../../../../store/internal";
import { useDispatch, useSelector } from "react-redux";
import { TicketStatus } from "../../../../core/enums/ticket-status";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import UserService from "../../../../services/user-service";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../store/token";
import BackendConstants from "../../../../core/constants/backend-constants";
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController()

const ModalEditTaskController: FC<IModalEditTaskController> = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [/*isLoading*/, setIsLoading] = useState(false);

    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)
    const [currentPage, /*setCurrentPage*/] = useState(1);

    const [agentsOptions, setAgentsOptions] = useState([]);
    const [selectedRequester, setSelectedRequester] = useState([]);
    const [selectedAssigned, setSelectedAssigned] = useState([]);

    // const [aux, setAux] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [followers, setFollowers] = useState([]);
    const [selectedFollowers, setSelectedFollowers] = useState([]);

    const { t } = useTranslation()

    const [statusOptions, setStatusOptions] = useState([
        { 'label': t('home_tasks.modal_task.status_options.open'), 'value': TicketStatus.Open },
        { 'label': t('home_tasks.modal_task.status_options.resolved'), 'value': TicketStatus.Resolved },
        { 'label': t('home_tasks.modal_task.status_options.pending'), 'value': TicketStatus.Pending },
        { 'label': t('home_tasks.modal_task.status_options.on_hold'), 'value': TicketStatus.OnHold },
        { 'label': t('home_tasks.modal_task.status_options.new'), 'value': TicketStatus.New }]);
    const [selectedStatus, setSelectedStatus] = useState([]);

    const [showfeedbackDate, setShowfeedbackDate] = useState(false)
    const [showfeedbackHour, setShowfeedbackHour] = useState(false)
    const [date, setDate] = useState(null as string || null);
    const [hour, setHour] = useState(null as string || null);
    const [description, setDescription] = useState('')
    const [observation, setObservation] = useState('')
    const [atrasado, setAtrasado] = useState(false)
    const [alertRegister, setAlertRegister] = useState(false)
    const [notificationAlertTime, setNotificationAlertTime] = useState("")
    const [allPeriod, setAllPeriod] = useState([
        { label: t('home_tasks.modal_task.notify_config.option_hours'), value: "hours" },
        { label: t('home_tasks.modal_task.notify_config.option_days'), value: "days" },
        { label: t('home_tasks.modal_task.notify_config.option_weeks'), value: "weeks" }
    ])
    const [selectdPeriod, setSelectedPeriod] = useState([])
    const [selectOnPeriod, setOnSelectPeriod] = useState([])

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const abortConst = new AbortController();

    const values: TokenInterface = {
        internal: {
            getShowEditTaskModal: useSelector(getShowEditTaskModal),
        },
        token: {
            value: useSelector(getToken)
        },
    }

    useEffect(() => {
        if (values.internal.getShowEditTaskModal) {
            setModalShow(true);
            getAllAgents()
            getTags()
        }
        else
            setModalShow(false);

    }, [values.internal.getShowEditTaskModal]);

    useEffect(() => {
        updateSelectedAssignedEmployee(props?.dataEdit)
        updateSelectedTaskStatus(props?.dataEdit.status_task_id)
        updateSelectedTaskTags(props?.dataEdit.tags)
        updateSelectedFollowers(props?.dataEdit.followers)
        updateSelectPeriod(props?.dataEdit.notification_alert_type)
        setDescription(props?.dataEdit.description)
        setObservation(props?.dataEdit.observation)
        setAlertRegister(props?.dataEdit?.should_notificate)
        setNotificationAlertTime(props?.dataEdit?.notification_alert_time)
        if (props?.dataEdit?.status_task_id === "b0b0b0b0-b0b0-b0b0-b0b0-b0b0b0b0b0b0") {
            setAtrasado(true)
        }
        else {
            setAtrasado(false)
        }
    }, [props?.dataEdit])

    const clearFields = (put?: boolean) => {
        setAgentsOptions([])
        setSelectedRequester([])
        setSelectedAssigned([])
        setTags([])
        setSelectedTags([])
        setFollowers([])
        setSelectedFollowers([])
        setSelectedStatus([])
        setShowfeedbackDate(false)
        setShowfeedbackHour(false)
        setDate('')
        setHour('')
        setDescription('')
        setObservation('')
        setModalShow(false)
        dispatch(setShowEditTaskModal(false));
        if (put) {
            const controller = new AbortController()
            props.getTasks(props.currentParamsTask, controller)
        }
    }

    const updateSelectedTaskStatus = (status_task_id: string) => {
        switch (status_task_id) {
            case TicketStatus.Open:
                setSelectedStatus([{ label: t('home_tasks.modal_task.status_options.open'), value: TicketStatus.Open }]);
                break;
            case TicketStatus.Resolved:
                setSelectedStatus([{ label: t('home_tasks.modal_task.status_options.resolved'), value: TicketStatus.Resolved }]);
                break;
            case TicketStatus.Pending:
                setSelectedStatus([{ label: t('home_tasks.modal_task.status_options.pending'), value: TicketStatus.Pending }]);
                break;
            case TicketStatus.OnHold:
                setSelectedStatus([{ label: t('home_tasks.modal_task.status_options.on_hold'), value: TicketStatus.OnHold }]);
                break;
            case TicketStatus.New:
                setSelectedStatus([{ label: t('home_tasks.modal_task.status_options.new'), value: TicketStatus.New }]);
                break;
            default:
                break;
        }
    }

    const updateSelectPeriod = (status_pediod_id: string) => {
        switch(status_pediod_id) {
            case "hours":
                setSelectedPeriod([{ label: t('home_tasks.modal_task.notify_config.option_hours'), value: "hours" }])
                break;
            case "days":
                setSelectedPeriod([{ label: t('home_tasks.modal_task.notify_config.option_days'), value: "days" }])
                break;
            case "weeks":
                setSelectedPeriod([{ label: t('home_tasks.modal_task.notify_config.option_weeks'), value: "weeks" }])
                break;
            default:
                break;
        }
    }

    const updateSelectedAssignedEmployee = (data) => {
        setSelectedAssigned([{ label: data?.assigned_employee_name, value: data?.assigned_employee_id }])
    }

    const updateSelectedTaskTags = (tags) => {
        var tagsEdit = tags?.map((tag) => ({ label: tag.name, value: tag.tag_id }))
        setSelectedTags(tagsEdit)
    }

    const updateSelectedFollowers = (followers) => {
        var aux = followers?.map((follower) => ({ label: follower.employee_name, value: follower.employee_id }))
        setSelectedFollowers(aux)
    }

    const hideModal = () => {
        dispatch(setShowEditTaskModal(false));
        setModalShow(false);
    }

    const convertDate = (data: string) => {
        let separaData = data.split("/")
        let dataFormatada = separaData[1] + "/" + separaData[0] + "/" + separaData[2]
        let Data = new Date(dataFormatada)
        return (Data)
    }

    const convertHour = (hora: string) => {
        let separaHora = hora.split(":")
        let Data = new Date(2000, 1, 1, parseInt(separaHora[0]), parseInt(separaHora[1]))
        return (Data)
    }

    const getAllAgents = async () => {
        const headers = UserService.getHeaders();
        await AppRequesterConst.Get(
            '/agent', { headers },
            (response: Object) => {
                return response;
            },
            (data) => {
                if (data.status === 200 && data.data.employees) {
                    setAgentsOptions(data.data.employees.length > 0 ? data.data.employees.map((item) => ({ label: `${item.name} ${item.lastname ? item.lastname : ""}`, value: item.id })) : []);
                }
            },
            (error: Object) => {
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getTags = async () => {
        const headers = UserService.getHeaders()
        await AppRequesterConst.Get(
            `/tag?is_active=true`, { headers },
            (response: Object) => { },
            (data: { data: { tags: { name: string, id: string }[] }, status: number }) => {
                let auxObj = [];
                if (data.status === 200) {
                    data.data.tags.forEach((element) => {
                        auxObj.push({ label: element.name, value: element.id });
                    });
                }

                setTags(auxObj);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    let dataFormatada, horaFormatada;

    const dateStr = props?.dataEdit?.date_limit;

    if (dateStr) {
        const [datePart, timePart] = dateStr.split(' ');
        const [ano, mes, dia] = datePart.split('-');
        const [hora, minuto] = timePart.split(':');
    
        dataFormatada = `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
        horaFormatada = `${hora.padStart(2, '0')}:${minuto.padStart(2, '0')}`;
    }
    
    return <ModalEditTask
        modalShow={modalShow}
        hideModal={hideModal}
        selectedAssigned={selectedAssigned}
        setSelectedAssigned={setSelectedAssigned}
        agentsOptions={agentsOptions}
        setAgentsOptions={setAgentsOptions}
        selectedRequester={selectedRequester}
        setSelectedRequester={setSelectedRequester}
        abortConst={abortConst}
        followers={followers}
        selectedFollowers={selectedFollowers}
        setSelectedFollowers={setSelectedFollowers}
        statusOptions={statusOptions}
        setStatusOptions={setStatusOptions}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        tags={tags}
        setTags={setTags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        dataEdit={props?.dataEdit}
        showFeedbackHour={showfeedbackHour}
        setShowFeedbackHour={setShowfeedbackHour}
        showFeedbackDate={showfeedbackDate}
        setShowFeedbackDate={setShowfeedbackDate}
        date={date}
        setDate={setDate}
        hour={hour}
        setHour={setHour}
        convertDate={convertDate}
        convertHour={convertHour}
        clearFields={clearFields}
        description={description}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        currentLimit={currentLimit}
        currentPage={currentPage}
        isNotEditable={props.isNotEditable}
        atrasado={atrasado}
        setAtrasado={setAtrasado}
        alertRegister={alertRegister}
        setAlertRegister={setAlertRegister}
        allPeriod={allPeriod}
        setAllPeriod={setAllPeriod}
        selectdPeriod={selectdPeriod}
        setSelectedPeriod={setSelectedPeriod}
        notificationAlertTime={notificationAlertTime}
        setNotificationAlertTime={setNotificationAlertTime}
        dataFormatada={dataFormatada}
        horaFormatada={horaFormatada}
        AppRequesterConst={AppRequesterConst}
        t={t}
        observation={observation}
    />
}

export default ModalEditTaskController;