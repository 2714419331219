const constsApi = {
    status: [
        {
            id: 'b58c1105-0ffe-4d07-a7d6-751389fc3008',
            name: 'Aberto'
        }, {
            id: '2b45ba71-fa68-4ce5-bf16-14f31d43ba1a',
            name: 'Resolvido'
        },
        {
            id: '0ec35dbb-398e-4c2f-996c-933af162c950',
            name: 'Pendente'
        },
        {
            id: '3dd9b9ac-7399-4ce8-b22c-a513eb473803',
            name: 'Em espera'
        },
        {
            id: 'daddbd66-1dd2-4008-94c9-7fcb9b1a5800',
            name: 'Novo'
        },
        {
            id: '2f955a38-e8cf-4033-bf6c-c5e4f68c8ea9',
            name: 'Abandonado'
        }
    ],

    profile: [
        {
            id: '1d9a359a-910e-45a0-9bfd-29eedf814b12',
            name: 'Administrador'
        },
        {
            id: 'ec2f67da-d7b0-4486-85a7-b8f297c5dc3c',
            name: 'Agente'
        },
        {
            id: 'a90baf90-b2b4-4ed9-a268-4be4f6addb1c',
            name: 'Proprietario'
        },
        {
            id: '5f2a7f61-c784-4b1a-93f5-ec989686ba58',
            name: 'Master'
        }
    ],

    channel: [
        {
            id: '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a',
            name: "E-mail"
        },
        {
            id: '6977996d-11d1-44cc-a863-2e1d9c24e046',
            name: "Telefone"
        },
        {
            id: '33be81d8-88ac-4e08-bb4d-cf69fd23267f',
            name: "WhatsApp"
        },
        {
            id: 'aa1cea93-23de-46aa-af14-d6f766acf5c8',
            name: "Facebook"
        },
        {
            id: 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2',
            name: "Instagram"
        },
        {
            id: '4a7b71bb-6432-4794-ad11-f978b067871d',
            name: "Webchat"
        },
    ],
    errors: {
        error400: {
            number: 400,
            name: "Bad Request"
        },
        error404: {
            number: 404,
            name: "Não encontrado"
        },
        error422: {
            number: 422,
            name: "Erro na requisição"
        },
        error401: {
            number: 401,
            name: "End session, redirect to login",
        },
        error403: {
            number: 403,
            name: "End Trial and Inactive"
        },
        error500: {
            number: 500,
            name: "Parâmetro ou configuração incorreta"
        }
    },
    authentication_methods: {
        email: 'e0e66fd2-e3e8-4217-ab25-85d6c30dec86',
        app: '810de59e-729d-48de-bb82-0dd4b29817ba'
    },
    permissions_controlpanel: {
        master: '75fb2f00-cd3b-4193-b5c2-e4326b714bc1',
        contract: '39f7133b-60b9-419a-a597-fbf5eb8d47be',
        company: 'a5cbfe59-27be-4b9f-83a1-ac232484e317',
        keys: '8ce55c30-d91b-49ce-9aa0-152eb624b28b',
        agents: '72531077-56b5-438c-877b-c9fa9ce5153c',
        read_all: '55e90cfe-e87e-40cc-97d4-bfd762cd0910',
    },
};


export default constsApi;
