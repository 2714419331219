import { Container, ListGroup, Modal, Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import allStyles from './styles'
import InfiniteScroll from "react-infinite-scroll-component"
import Utils from "../../../../core/shared/utils"
import { ListName } from "../../../../core/enums/order-sort-by"
import { t } from "i18next"

const ModalViewConsumer = ({ handleClose, show, data, updateListFunction, HasMoreInformation, popover, setConsumerInfo, currentConsumerId, currentConsumerName }) => {
    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="" size="lg" centered={true}>
            <Modal.Header closeButton style={{ marginLeft: "4%", marginTop: '1%', marginBottom: '-1%' }}>
                <Modal.Title style={allStyles.textBlack}>
                    {t("home_agents.modal_consumer.title_modal_view")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="TicketsRowCollums">
                            <Row name="">
                                <Col md={2} className="nopadding">
                                    <p>{t("home_agents.modal_consumer.list_name")}</p>
                                </Col>
                                <Col md={4} className="nopadding">
                                    <p>{t("home_agents.modal_consumer.list_email")}</p>
                                </Col>
                                <Col md={4} className="nopadding">
                                    <p>{t("home_agents.modal_consumer.list_phone")}</p>
                                </Col>
                                <Col className="d-flex justfy-content-end">
                                    <p>{t("home_agents.modal_consumer.list_actions")}</p>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                    {
                        data.consumers !== undefined ? 
                            <div id="ListOfAgents" style={{ overflowY: 'auto', height: '48vh' }}>
                                <InfiniteScroll
                                    dataLength={data.consumers.length}
                                    next={updateListFunction}
                                    hasMore={HasMoreInformation as boolean}
                                    loader={<h4></h4>}
                                    scrollableTarget={"ListOfAgents"}
                                    onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfConsumers) }}
                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow">
                                            {
                                                data.consumers.map((item, index) => {
                                                        return (
                                                            <ListGroup.Item style={{ color: '#707070' }} id={item.name + " " + "*ID*" + item.id} key={"keyAll" + index.toString()}>
                                                                <Row className="TicketsRowHeight">
                                                                    <Col md={2} className="align-self-center nopadding" style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id}>
                                                                        <div className="oneLineClampEllipsis">
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        {item.name ? item.name : "N/A"}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {item.name ? item.name : "N/A"}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4} className="align-self-center nopadding" style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id}>
                                                                        <div className="oneLineClampEllipsis">
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        {item.email ? item.email : "N/A"}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {item.email ? item.email : "N/A"}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4} className="align-self-center nopadding" style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id}>
                                                                        <div className="oneLineClampEllipsis">
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        {item.phone ? item.phone : "N/A"}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {item.phone ? item.phone : "N/A"}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={1} className="d-flex justfy-content-end">
                                                                        <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(currentConsumerId, currentConsumerName)}>
                                                                            <Button 
                                                                                id={item.name + "*ID*" + item.user_id } 
                                                                                name={item.name} 
                                                                                onClick={(e) => setConsumerInfo(e)}
                                                                                className="nopadding d-flex justify-content-end align-self-center" 
                                                                                style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <circle cx="12" cy="12" r="1" />
                                                                                    <circle cx="12" cy="19" r="1" />
                                                                                    <circle cx="12" cy="5" r="1" />
                                                                                </svg>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                </Row>
                                                            </ListGroup.Item>
                                                        )
                                                })
                                            }
                                        </ListGroup>

                                    }
                                </InfiniteScroll>
                            </div>
                        : 
                            <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {t("home_agents.modal_consumer.warning_consumers_empty")}
                            </Row>
                    }
            </Modal.Body>
        </Modal>
    )
}

export default ModalViewConsumer