import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import { Col, Row, OverlayTrigger, Tooltip, Button, Container } from 'react-bootstrap';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Pt from 'date-fns/locale/pt-BR';

import './styles.css';
import SelectComponent from '../selectComponent';

// registerLocale('pt', Pt);
// setDefaultLocale("pt");

interface PropsInputCalendar {
    children?: React.ReactNode;
    value?: string;
    placeholder?: string,
    placeholderText?: string;
    onClick?: MouseEventHandler<HTMLInputElement>;
    // onClick?: MouseEventHandler<HTMLLabelElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const FilterOptionComponent = ({
    optionNames,
    handleCleanList,
    channelOptions,
    setChannelOptions,
    selectedChannel,
    setSelectedChannel,
    botOptions,
    setBotOptions,
    selectedBot,
    setSelectedBot,
    selectedAgents,
    agentOptions,
    setAgentOptions,
    setSelectedAgents,
    statusOptions,
    setStatusOptions,
    selectedStatus,
    setSelectedStatus,
    selectedDepartments,
    departmentOptions,
    setDepartmentsOptions,
    setSelectedDepartments,
    startDate,
    endDate,
    onSelectDropdownDate,
    setUpdateInputDateActive,
    currentFilterDate,
    setCurrentFilterDate,
    selectedTriggerType,
    triggerTypeOptions,
    setTriggerTypeOptions,
    setSelectedTriggerType,
    selectedResponsible,
    responsibleOptions,
    setResponsibleOptions,
    setSelectedResponsible,
    triggerStatusOptions,
    setTriggerStatusOptions,
    selectedTriggerStatus,
    setSelectedTriggerStatus,
    selectedTags,
    tagsOptions,
    setTagsOptions,
    setSelectedTags,
    selectedConsumer,
    consumerOptions,
    setConsumerOptions,
    setSelectedConsumer,
    callbackFiltering
}) => {

    /**
     * Custom Calendar Input, support icon calendar
     */
    const CustomInputCalendar = React.forwardRef<HTMLDivElement, PropsInputCalendar>(
        (props, ref) => {
            return (
                <Container>
                    <Row 
                        style={{ /*backgroundColor: 'green',*/ padding: '0 8px', height:'5.6vh' }}
                        id="dateInput"
                        className='dateInputTickets'
                        onClick={props.onClick}
                        ref={ref}
                    >
                        <Col className="d-flex align-items-center ms-0 ps-0 nopadding" md={10}>
                            <input className='dateInputCalendar ps-0' id="dateInputCalendarId" type="text" onChange={props.onChange} value={props.value} placeholder={ props.placeholderText || props.placeholder } >
                            </input>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-end nopadding' md={2}>
                            <svg id="ic-calendar" xmlns="http://www.w3.org/2000/svg" width="2.5vh" viewBox="0 0 24 24">
                                <path id="Caminho_445" data-name="Caminho 445" d="M0,0H24V24H0Z" fill="none" />
                                <rect id="Retângulo_608" data-name="Retângulo 608" width="16" height="16" rx="2" transform="translate(4 5)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Linha_31" data-name="Linha 31" y2="4" transform="translate(16 3)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Linha_32" data-name="Linha 32" y2="4" transform="translate(8 3)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Linha_33" data-name="Linha 33" x2="16" transform="translate(4 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <rect id="Retângulo_609" data-name="Retângulo 609" width="2" height="2" transform="translate(8 15)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </svg>
                        </Col>
                    </Row>
                </Container>
            );
        },
    );

    return (
        <Row>
            <Col md={2} style={{ color: '#0C2461', width: '10.5%', paddingRight: '0' }} className="align-self-center ms-3">
                <p className='nopadding'>FILTRAR POR:</p>
            </Col>
            {
                optionNames.map((optionName, index) => {
                    {
                        switch (optionName) {
                            case "Canal":
                                return (
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding">
                                        <div className={"nopadding"}>
                                            <SelectComponent
                                                options={channelOptions}
                                                setOptions={setChannelOptions}
                                                selecteds={selectedChannel}
                                                setSelecteds={setSelectedChannel}
                                                singleSelect={true}
                                                title={"Canal"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": "Todos os canais foram selecionados.",
                                                        "clearSearch": "Limpar pesquisa",
                                                        "clearSelected": "Limpar canais selecionados",
                                                        "noOptions": "Sem canais",
                                                        "search": "Pesquisar canais",
                                                        "selectAll": "Todos os canais",
                                                        "selectAllFiltered": "Selecionar todos os canais (Filtrado)",
                                                        "selectSomeItems": "Canal",
                                                        "create": "Criar",
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={true}
                                                nullOptionLabel={"Não atribuído"}
                                                filtering={true}
                                                hasAllOption={true}
                                                allOptionLabel={"Todos os canais"}
                                            />
                                        </div>
                                    </Col>
                                );
                            case "Bot":
                                return (
                                    <Col key={index} md={1} style={{ width: '7%' }} className="nopadding ps-2">
                                        <div className={"nopadding"}>
                                            <SelectComponent
                                                options={botOptions}
                                                setOptions={setBotOptions}
                                                selecteds={selectedBot}
                                                setSelecteds={setSelectedBot}
                                                singleSelect={true}
                                                title={"Bot"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": "Todos as opções foram selecionados.",
                                                        "clearSearch": "Limpar pesquisa",
                                                        "clearSelected": "Limpar opções selecionados",
                                                        "noOptions": "Sem opções",
                                                        "search": "Pesquisar",
                                                        "selectAll": "Todos",
                                                        "selectAllFiltered": "Selecionar todas as opções (Filtrado)",
                                                        "selectSomeItems": "Bot",
                                                        "create": "Criar",
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                filtering={true}
                                                hasAllOption={true}
                                                allOptionLabel={"Todos"}
                                            />
                                        </div>
                                    </Col>
                                );
                            case "Atribuido":
                                return (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                selectedAgents && selectedAgents[0] ?
                                                    selectedAgents[0].label : "Atribuído"
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="top3"
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2">
                                            <div className={"nopadding"}>
                                                <SelectComponent
                                                    options={agentOptions}
                                                    setOptions={setAgentOptions}
                                                    selecteds={selectedAgents}
                                                    setSelecteds={setSelectedAgents}
                                                    singleSelect={true}
                                                    title={"Atribuição"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": "Todos os agentes foram selecionados.",
                                                            "clearSearch": "Limpar pesquisa",
                                                            "clearSelected": "Limpar agentes selecionados",
                                                            "noOptions": "Sem agentes",
                                                            "search": "Pesquisar agentes",
                                                            "selectAll": "Selecionar todos os agentes",
                                                            "selectAllFiltered": "Selecionar todos os agentes (Filtrado)",
                                                            "selectSomeItems": "Atribuição",
                                                            "create": "Criar",
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={true}
                                                    nullOptionLabel={"Não atribuído"}
                                                    filtering={true}
                                                    hasAllOption={true}
                                                    allOptionLabel={"Todos os agentes"}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                );
                            case "Status":
                                return (
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2">
                                        <div className={"nopadding"}>
                                            <SelectComponent
                                                options={statusOptions}
                                                setOptions={setStatusOptions}
                                                selecteds={selectedStatus}
                                                setSelecteds={setSelectedStatus}
                                                singleSelect={true}
                                                title={"Status"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": "Todos os status foram selecionados.",
                                                        "clearSearch": "Limpar pesquisa",
                                                        "clearSelected": "Limpar opções selecionados",
                                                        "noOptions": "Sem opções",
                                                        "search": "Pesquisar",
                                                        "selectAll": "Selecionar todos os status",
                                                        "selectAllFiltered": "Selecionar todos os status (Filtrado)",
                                                        "selectSomeItems": "Status",
                                                        "create": "Criar",
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                filtering={true}
                                                hasAllOption={true}
                                                allOptionLabel={"Todos os status"}
                                            />
                                        </div>
                                    </Col>
                                );
                            case "Setor":
                                return (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                selectedDepartments && selectedDepartments[0] ?
                                                    selectedDepartments[0].label : "Setor"
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="top4"
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2">
                                            <div className={"nopadding"}>
                                                <SelectComponent
                                                    options={departmentOptions}
                                                    setOptions={setDepartmentsOptions}
                                                    selecteds={selectedDepartments}
                                                    setSelecteds={setSelectedDepartments}
                                                    singleSelect={true}
                                                    title={"Setor"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": "Todos os setores foram selecionados.",
                                                            "clearSearch": "Limpar pesquisa",
                                                            "clearSelected": "Limpar setores selecionados",
                                                            "noOptions": "Sem setores",
                                                            "search": "Pesquisar setores",
                                                            "selectAll": "Selecionar todos os setores",
                                                            "selectAllFiltered": "Selecionar todos os setores (Filtrado)",
                                                            "selectSomeItems": "Setor",
                                                            "create": "Criar",
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    filtering={true}
                                                    hasAllOption={true}
                                                    allOptionLabel={"Todos os setores"}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                );
                            case "Data":
                                return (
                                    <Col key={index} md={2} style={{ width: '16%' }} className="nopadding pe-2 ps-2">
                                        <DatePicker
                                            className="dateInputTickets"
                                            // id="dateInput"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Filtrar por data"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={onSelectDropdownDate}
                                            calendarClassName="calendaryStyle"
                                            onSelect={() => { setUpdateInputDateActive(true) /* document.getElementById("dateInputCalendarId").className = "dateInputCalendarActive"; */ }}
                                            onCalendarClose={() => {
                                                if (currentFilterDate[0] !== null && currentFilterDate[1] === null)
                                                    setCurrentFilterDate([null, null]);
                                                setUpdateInputDateActive(false);
                                            }}
                                            customInput={<CustomInputCalendar />}
                                        />
                                    </Col>
                                );
                            case "Tipo":
                                return (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                selectedTriggerType && selectedTriggerType[0] ?
                                                    selectedTriggerType[0].label : "Tipo"
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="top5"
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className="nopadding pe-2">
                                            <div className={"nopadding"}>
                                                <SelectComponent
                                                    options={triggerTypeOptions}
                                                    setOptions={setTriggerTypeOptions}
                                                    selecteds={selectedTriggerType}
                                                    setSelecteds={setSelectedTriggerType}
                                                    singleSelect={true}
                                                    title={"Tipo"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": "Todos os tipos foram selecionados.",
                                                            "clearSearch": "Limpar pesquisa",
                                                            "clearSelected": "Limpar tipos selecionados",
                                                            "noOptions": "Sem tipos",
                                                            "search": "Pesquisar tipos",
                                                            "selectAll": "Selecionar todos os tipos",
                                                            "selectAllFiltered": "Selecionar todos os tipos (Filtrado)",
                                                            "selectSomeItems": "Tipo",
                                                            "create": "Criar",
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    filtering={true}
                                                    hasAllOption={true}
                                                    allOptionLabel={"Todos os tipos"}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                );
                            case "Responsável":
                                return (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                selectedResponsible && selectedResponsible[0] ?
                                                    selectedResponsible[0].label : "Responsável"
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="top6"
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2">
                                            <div className={"nopadding"}>
                                                <SelectComponent
                                                    options={responsibleOptions}
                                                    setOptions={setResponsibleOptions}
                                                    selecteds={selectedResponsible}
                                                    setSelecteds={setSelectedResponsible}
                                                    singleSelect={true}
                                                    title={"Responsável"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": "Todos os responsável foram selecionados.",
                                                            "clearSearch": "Limpar pesquisa",
                                                            "clearSelected": "Limpar responsável selecionados",
                                                            "noOptions": "Sem responsável",
                                                            "search": "Pesquisar responsável",
                                                            "selectAll": "Selecionar todos os responsável",
                                                            "selectAllFiltered": "Selecionar todos os responsável (Filtrado)",
                                                            "selectSomeItems": "Responsável",
                                                            "create": "Criar",
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    filtering={true}
                                                    hasAllOption={true}
                                                    allOptionLabel={"Todos os responsáveis"}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                );
                            case "TriggerStatus":
                                return (
                                    <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2">
                                        <div className={"nopadding"}>
                                            <SelectComponent
                                                options={triggerStatusOptions}
                                                setOptions={setTriggerStatusOptions}
                                                selecteds={selectedTriggerStatus}
                                                setSelecteds={setSelectedTriggerStatus}
                                                singleSelect={true}
                                                title={"Status"}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": "Todos os status foram selecionados.",
                                                        "clearSearch": "Limpar pesquisa",
                                                        "clearSelected": "Limpar opções selecionados",
                                                        "noOptions": "Sem opções",
                                                        "search": "Pesquisar",
                                                        "selectAll": "Selecionar todos os status",
                                                        "selectAllFiltered": "Selecionar todos os status (Filtrado)",
                                                        "selectSomeItems": "Status",
                                                        "create": "Criar",
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                filtering={true}
                                                hasAllOption={true}
                                                allOptionLabel={"Todos os status"}
                                            />
                                        </div>
                                    </Col>
                                );
                            case "Tag":
                                return (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                selectedTags && selectedTags[0] ?
                                                    selectedTags[0].label : "Tags"
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="top5"
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2">
                                            <div className={"nopadding"}>
                                                <SelectComponent
                                                    options={tagsOptions}
                                                    setOptions={setTagsOptions}
                                                    selecteds={selectedTags}
                                                    setSelecteds={setSelectedTags}
                                                    singleSelect={true}
                                                    title={"Tags"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": "Todas as tags foram selecionados.",
                                                            "clearSearch": "Limpar pesquisa",
                                                            "clearSelected": "Limpar tags selecionadas",
                                                            "noOptions": "Sem tags",
                                                            "search": "Pesquisar tags",
                                                            "selectAll": "Selecionar todos as tags",
                                                            "selectAllFiltered": "Selecionar todos as tags (Filtrado)",
                                                            "selectSomeItems": "Tags",
                                                            "create": "Criar",
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    filtering={true}
                                                    hasAllOption={true}
                                                    allOptionLabel={"Todas as tags"}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                );
                            case "Consumer":
                                return (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>{
                                                selectedConsumer && selectedConsumer[0] ?
                                                    selectedConsumer[0].label : "Consumidores"
                                            }</Tooltip>}
                                        placement={"top"}
                                        key="top6"
                                    >
                                        <Col key={index} md={2} style={{ width: '11%' }} className="nopadding ps-2">
                                            <div className={"nopadding"}>
                                                <SelectComponent
                                                    options={consumerOptions}
                                                    setOptions={setConsumerOptions}
                                                    selecteds={selectedConsumer}
                                                    setSelecteds={setSelectedConsumer}
                                                    singleSelect={true}
                                                    title={"Consumidores"}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": "Todos os consumidores foram selecionados.",
                                                            "clearSearch": "Limpar pesquisa",
                                                            "clearSelected": "Limpar consumidores selecionados",
                                                            "noOptions": "Sem consumidores",
                                                            "search": "Pesquisar consumidor",
                                                            "selectAll": "Selecionar todos os consumidores",
                                                            "selectAllFiltered": "Selecionar todos os consumidores (Filtrado)",
                                                            "selectSomeItems": "Consumidores",
                                                            "create": "Criar",
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    filtering={true}
                                                    hasAllOption={true}
                                                    allOptionLabel={"Todos os consumidores"}
                                                />
                                            </div>
                                        </Col>
                                    </OverlayTrigger>
                                );
                        }
                    }
                })
            }
            <Col md={1} className="nopadding ps-2">
                <Button id="filterButton" className="buttonTicketsSec2 " onClick={callbackFiltering}>
                    Filtrar
                </Button>
            </Col>
            <Col style={{ margin: 'auto 0 auto auto' }} className="d-flex justify-content-end align-self-end">
                <Button className="nopadding buttonTicketsSec4" onClick={handleCleanList}>
                    <svg id="Grupo_11452" data-name="Grupo 11452" xmlns="http://www.w3.org/2000/svg" width="60%" viewBox="0 0 24 24">
                        <path id="Caminho_9734" data-name="Caminho 9734" d="M0,0H24V24H0Z" fill="none" />
                        <line id="Linha_394" data-name="Linha 394" x1="12" y2="12" transform="translate(6 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <line id="Linha_395" data-name="Linha 395" x2="12" y2="12" transform="translate(6 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                </Button>
            </Col>
        </Row>
    );
}

export default FilterOptionComponent;