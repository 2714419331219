import React from 'react'
import { Modal, Row, Container, Col } from 'react-bootstrap'
import allStyles from './styles';
import StarComponent from '../starComponent';

const ModalRanking = ({ t, show, onHide, dataInfo }) => {
    return (
        <Modal show={show} onHide={onHide} aria-labelledby="" centered={true} size='xl'>
            <Modal.Header style={allStyles.textBlack} closeButton>
                {t('dashboard.modal_ranking.title')}
            </Modal.Header>
            <Modal.Body>
                <Container className='noppading'>
                    <Row style={{ marginBottom: "30px" }}>
                        <div style={{ backgroundColor: "#E5ECFF", color: "#4A69BD", width: "10%", height:"3vh", justifyContent: "center", alignItems: "center", display: "flex", borderRadius: "15px", fontWeight: "bold", fontFamily: "Montserrat", fontSize: "16px" }}>
                            {dataInfo?.data.qnt_ratings}
                        </div>
                    </Row>
                    <Row style={{textAlign: 'left', font: 'normal normal bold 15px/19px Montserrat', letterSpacing: '0px', color: '#1E1E1E', opacity: '1', marginBottom: '20px'}}>
                        {t('dashboard.modal_ranking.label_legend')}
                    </Row>
                    <Row style={{textAlign: 'left'}}>
                        <Col style={{ font: "normal normal normal 12px/19px Montserrat", color: "#707070" }}>
                            <div>
                                {t('dashboard.modal_ranking.score', { score: 1 })}
                            </div>
                            {
                                <StarComponent points={1} width="17" height="17"/>
                            }
                            <div style={{ marginTop: "25px", fontSize: "0.7rem", backgroundColor: "#E5ECFF", color: "#4A69BD", width: "60%", display: "flex", justifyContent: "center", borderRadius: "15px" }}>
                                {dataInfo?.data.note_1}
                            </div>
                        </Col>
                        <Col style={{ font: "normal normal normal 12px/19px Montserrat", color: "#707070" }}>
                            <div>
                                {t('dashboard.modal_ranking.score', { score: 2 })}
                            </div>
                            {
                                <StarComponent points={2} width="17" height="17"/>
                            }
                            <div style={{ marginTop: "25px", fontSize: "0.7rem", backgroundColor: "#E5ECFF", color: "#4A69BD", width: "60%", display: "flex", justifyContent: "center", borderRadius: "15px" }}>
                                {dataInfo?.data.note_2}
                            </div>
                        </Col>
                        <Col style={{ font: "normal normal normal 12px/19px Montserrat", color: "#707070" }}>
                            <div>
                                {t('dashboard.modal_ranking.score', { score: 3 })}
                            </div>
                            {
                                <StarComponent points={3} width="17" height="17"/>
                            }
                            <div style={{ marginTop: "25px", fontSize: "0.7rem", backgroundColor: "#E5ECFF", color: "#4A69BD", width: "60%", display: "flex", justifyContent: "center", borderRadius: "15px" }}>
                                {dataInfo?.data.note_3}
                            </div>
                        </Col>
                        <Col style={{ font: "normal normal normal 12px/19px Montserrat", color: "#707070" }}>
                            <div>
                                {t('dashboard.modal_ranking.score', { score: 4 })}
                            </div>
                            {
                                <StarComponent points={4} width="17" height="17"/>
                            }
                            <div style={{ marginTop: "25px", fontSize: "0.7rem", backgroundColor: "#E5ECFF", color: "#4A69BD", width: "60%", display: "flex", justifyContent: "center", borderRadius: "15px" }}>
                                {dataInfo?.data.note_4}
                            </div>
                        </Col>
                        <Col style={{ font: "normal normal normal 12px/19px Montserrat", color: "#707070" }}>
                            <div>
                                {t('dashboard.modal_ranking.score', { score: 5 })}
                            </div>
                            {
                                <StarComponent points={5} width="17" height="17"/>
                            }
                            <div style={{ marginTop: "25px", fontSize: "0.7rem", backgroundColor: "#E5ECFF", color: "#4A69BD", width: "60%", display: "flex", justifyContent: "center", borderRadius: "15px" }}>
                                {dataInfo?.data.note_5}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default ModalRanking