import React from 'react';
import ReactDOM from 'react-dom';
//import store from './store/index.tsx'
import { Provider } from 'react-redux'
import App from './app';
import { PersistGate } from 'redux-persist/integration/react'
import factory from './store/index'
import { AppProvider } from './providers';
import './i18next';

const { store, persistor } = factory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppProvider>
        <App />
      </AppProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
