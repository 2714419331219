import React from 'react';

import allStyles from './styles';
import { Modal, Row, Container, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';

import { setShowAlertFeedback } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';

const AppRequesterConst = new AppRequesterController();

const EditTokenChatbot = ({
  modalShow,
  Hide,
  setTokenUserGpt,
  props,
  tokenUserGpt,
  dispatch,
  navigate,
  setIsLoading,
  showPassword,
  clickPassword,

}) => {

  return (
    <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" centered={true}>
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          Atualizar token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{
              token: tokenUserGpt,
            }}
            validate={values => {
              console.log(values.token);
              let errors = {};
              setTokenUserGpt(values.token);

              return errors;
            }}
            onSubmit={async () => {
              let JsonSend = {
                "id": props.id,
                "token_chatgpt": tokenUserGpt
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await AppRequesterConst.Put(
                '/company', JsonSend, { headers },
                (response: Object) => { return response; },
                (data: Object) => {
                  dispatch(setShowAlertFeedback({ message: `Token atualizado com sucesso!`, visibility: true, signalIcon: true }));
                  Hide();
                },
                (error: { response: { status: number; data: { message: string }; }; }) => {
                  if (error.response.data.message) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                  }
                  else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                  }
                }, navigate, dispatch, setIsLoading
              );
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                  <Col md={12}>
                    <Form.Label htmlFor="name">Token</Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        // isInvalid={showFeedbackName}
                        placeholder="Informe o seu token"
                        aria-label="Informe o seu token"
                        aria-describedby="Informe o seu token"
                        size='lg'
                        type='password'
                        id="token"
                        name="token"
                        autoComplete='given-name'
                        maxLength={100}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.token?.trim()}
                      >
                      </FormControl>
                      <div className="d-flex justify-content-end align-self-start">
                        <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', paddingTop: '0.5vh', zIndex: "5" }} onClick={() => clickPassword()}>
                          {showPassword ?
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <circle cx="12" cy="12" r="2" />
                              <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <line x1="3" y1="3" x2="21" y2="21" />
                              <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                              <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                            </svg>
                          }
                        </Button>
                      </div>
                    </InputGroup>
                  </Col>
                </Row>
                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center mt-4">
                  <Col className="d-flex justify-content-center">
                    <Button size='lg' className="buttonWhite" style={{ width: '70%' }} onClick={() => Hide()} >Cancelar</Button>
                  </Col>
                  <Col className="d-flex justify-content-center" >
                    <Button type='submit' size='lg' className="buttonBlue" style={{ width: '70%' }}>Salvar</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default EditTokenChatbot;