import { Dispatch, SetStateAction, createContext, useState } from "react";

export const BlockTicketContext = createContext<BlockTicketContextType>(null);

export const BlockTicketProvider = ({ children }) => {

    const [isAllowedMessage, setIsAllowedMessage] = useState<boolean>();
    const [readOnlyEditorComponent, setReadOnlyEditorComponent] = useState<boolean>(false);
    const [editTicket, setEditTicket] = useState<boolean>(true)
    const [editButtonsView, setEditButtonsView] = useState<boolean>(false)
    const [disableEditTicket, setDisableEditTicket] = useState<boolean>(false)
    const [admBlocked, setAdmBlocked] = useState<boolean>(false)

    const ProviderValue = {
        isAllowedMessage,
        setIsAllowedMessage,
        readOnlyEditorComponent,
        setReadOnlyEditorComponent,
        editTicket,
        setEditTicket,
        editButtonsView,
        setEditButtonsView,
        disableEditTicket,
        setDisableEditTicket,
        setAdmBlocked,
        admBlocked,
    }

    return (
        <BlockTicketContext.Provider value={ProviderValue}>
            {children}
        </BlockTicketContext.Provider>
    )
}

export type BlockTicketContextType = {
    isAllowedMessage: boolean;
    setIsAllowedMessage: Dispatch<SetStateAction<boolean>>;
    readOnlyEditorComponent: boolean;
    setReadOnlyEditorComponent: Dispatch<SetStateAction<boolean>>;
    editTicket: boolean;
    setEditTicket: Dispatch<SetStateAction<boolean>>;
    editButtonsView: boolean;
    setEditButtonsView: Dispatch<SetStateAction<boolean>>
    disableEditTicket: boolean;
    setDisableEditTicket: Dispatch<SetStateAction<boolean>>
    setAdmBlocked: Dispatch<SetStateAction<boolean>>
    admBlocked: boolean;
}