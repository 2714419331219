import React, { FC, useEffect, useState } from 'react';
import AttachmentsInMessage from '.';
import { AttachmentsInMessageControllerProps } from './indexModel';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../store/token';
import { useDropzone } from 'react-dropzone';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { Channels } from '../../../../../core/enums/channels';
import { useTranslation } from 'react-i18next';

const AttachmentsInMessageController: FC<AttachmentsInMessageControllerProps> = (props) => {
    const AppRequesterConst = new AppRequesterController();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setIsLoading] = useState(false);

    const [gfids, setGfids] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileLoading, setFileLoading] = useState([]);
    const [lastIds, setLastIds] = useState([]);

    const [loadPreviewFiles, setLoadPreviewFiles] = useState(true);

    const values = {
        token: {
            value: useSelector(getToken),
        }
    }

    const getFileTypes = () => {
        switch (props.attachmentType) {
            case 'icon': {
                return {
                    'image/gif': ['.gif'],
                    'image/png': ['.png'],
                    'image/vnd.microsoft.icon': ['.ico'],
                    'image/svg+xml': ['.svg'],
                    'video/webm': ['.webm'],
                }
            }
            case 'image': {
                return {
                    'image/jpeg': ['.jpeg'],
                    'image/png': ['.png'],
                };
            }
            case 'video': {
                return {
                    'video/mpeg': ['.mpeg'],
                    'video/mp4': ['.mp4'],
                };
            }
            case 'document': {
                return {
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.ms-powerpoint': ['.ppt'],
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                    'application/vnd.ms-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                };
            }
            default: {
                return { 
                    'video/mpeg': ['.mpeg'],
                    'video/mp4': ['.mp4'],
                    'audio/mpeg': ['.mpeg', '.mp3'],
                    'audio/aac': ['.aac'],
                    'audio/amr': ['.amr'],
                    'image/jpeg': ['.jpeg'],
                    'image/png': ['.png'],
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.ms-powerpoint': ['.ppt'],
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                    'application/vnd.ms-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                };
            }
        }
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        noClick: props.attachmentType === "icon" && files.length > 0? true: props.disableClick,
        noDrag: props.attachmentType === "icon" && files.length > 0? true: props.disableClick,
        multiple: true,
        minSize: 1,
        maxSize: 15728640,
        validator: (file) => {
            let findedFile = files.find(item => item.name === file.name);

            if (findedFile) {
                return {
                    code: "file-name-finded",
                    message: t("home_message_trigger.list_of_messages_triggers.same_name_file")
                };
            }

            // limitar arquivos do whatsapp para 1
            if (files.length === 1 && props.selectedChannelId === Channels.WhatsApp) {
                return {
                    code: "most-files-to-whatsapp",
                    message: t("home_message_trigger.list_of_messages_triggers.whatsapp_only_1_file")
                };
            }

            return null
        },
        disabled: props.selectedChannelId === null || props.selectedChannelId === undefined /* || fileLoading.find((item) => item === true) */,
        /* noClick: isClicked,
        noDrag: isClicked, */
        onDrop: (dropedFiles) => {
            let jsonSend = new FormData();
            dropedFiles.forEach(element => {
                jsonSend.append("files", element)
            });
            jsonSend.append("channel_id", props.selectedChannelId);
            jsonSend.append("audio_whatsapp", "false")

            // adicionando loading nos arquivos recentes
            const fileLoadingLength = fileLoading.length;
            for (let index = fileLoadingLength; index < fileLoadingLength + dropedFiles.length;  index++) {
            fileLoading[index] = true;
            }
            setFileLoading(fileLoading);

            const headers = {
                "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
                "Authorization": "Bearer " + values.token.value
            };

            AppRequesterConst.Post(
                '/chat/upload/attachment', jsonSend, { headers },
                (response: Object) => {
                    return response;
                },
                (data: { data: {  files: { id: string []; name: string[]; }[] }; }) => {
                    dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.upload_sucess"), visibility: true, signalIcon: true }));
                    setLastIds(data.data.files.map((file) => file.id));

                    // adicionando o gfid junto com os demais atributos
                    setFiles((allFiles) => {
                        allFiles.map((file, index) => { 
                            // if (file.name === data.data.name)
                            data.data.files.map((dataFile, indexGf) => {
                                if (dataFile.name === file.name)
                                    allFiles[index] = Object.assign(file, { gfid: data.data.files[indexGf] });
                            })
                        });
                        return allFiles;
                    });
                },
                (error: { response: { status: number; data: { message: any[]; }; }; }) => { 
                    if (error.response?.data?.message && error.response.data.message[0]) {
                        dispatch(setShowAlertFeedback({ message: error.response.data.message[0], visibility: true, signalIcon: false }));
                    } else {
                        dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, setIsLoading, 
                () => { 
                    // removendo loading dos arquivos recentes
                    for (let index = 0; index < fileLoading.length; index++) {
                        fileLoading[index] = false;
                    }
                    setFileLoading(fileLoading);
                }
            );
        },
        onDropRejected: (reject) => {
            if (reject[0].errors[0].code === "file-too-large") {
                dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.file_length'), signalIcon: false }));
            } else if (reject[0].errors[0].code === "file-name-finded") {
                dispatch(setShowAlertFeedback({ visibility: true, message: t("home_message_trigger.list_of_messages_triggers.same_name_file"), signalIcon: false }));
            } else if (reject[0].errors[0].code === "most-files-to-whatsapp") {
                dispatch(setShowAlertFeedback({ visibility: true, message: reject[0].errors[0].message, signalIcon: false }));
            }
        },
        accept: getFileTypes()
    });

    // passa os gfids com a callback
    useEffect(() => {
        props.setAttachmentsFilesIds(gfids);
    }, [gfids]);

    // adiciona o último gfid de um upload na listagem
    useEffect(() => {
        if (lastIds?.length > 0) {
            setGfids([...gfids, ...lastIds]);
            setLastIds([]);
        } 
    }, [lastIds]);

    // gerando url para unico arquivo
    useEffect(() => {
        if (files.length > 0) {
            const file = files[0];

            // é criada uma url temporária da imagem para ser usada para visualização
            if (props.setPreviewImageURL)
                props.setPreviewImageURL(URL.createObjectURL(file));
        }
    }, [files]);

    useEffect(() => {
        setLoadPreviewFiles((load) => {
            // carregando arquivos salvos
            if (load && props.previewFiles) {
                let files = props.previewFiles.map((item) => {
                    return { name: item.filename, url: item.url, gfid: item._id, isLoading: false };
                });
                
                setFiles(files);
    
                setGfids(files.map((item) => { return item.gfid }));
                setLoadPreviewFiles(false);
            } else { // edição normal dos arquivos
                let allFiles = files.concat(acceptedFiles);
    
                allFiles = [...new Map(allFiles.map(file => [file.name, file])).values()];
            
                allFiles.map(file => {
                    const returnedTarget = Object.assign(file, { isLoading: true });
                    file = returnedTarget;
                });
            
                setFiles(allFiles);
            
                acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
            } 
            return load;
        });   

    }, [acceptedFiles, props.previewFiles]);

    const deleteFile = (fileName: any[]) => {
        let newFiles = files.filter((item) => item.name !== fileName);
        acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
        setFiles(newFiles);

        // gfids após deletar um arquivo
        let newGfids = newFiles.map((file, index) => { 
            if (file?.gfid)
                return file.gfid;
            else if (file?._id)
                return file._id;
        });
        setGfids(newGfids);

        if (props.setPreviewImageURL)
            props.setPreviewImageURL("");
    }

    const handleDownloadFile = (file: any) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.target = '_blank';
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    return <AttachmentsInMessage customDropMessage={props.customDropMessage} files={files} setFiles={setFiles} fileLoading={fileLoading} handleDownloadFile={handleDownloadFile} deleteFile={deleteFile} getRootProps={getRootProps} getInputProps={getInputProps} selectedChannelId={props.selectedChannelId} customTitle={props.customTitle} t={t}/>
}   

export default AttachmentsInMessageController;