import React, { FC } from 'react';
import { Button, Col, ListGroup, OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap';
import { IListOfIntegrations } from './indexModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../../../../components/loading';

const ListOfIntegrations: FC<IListOfIntegrations> = (props) => {

    return (
        <div style={{ marginTop: '2vh' }} >
            <Loading visibility={props.isLoading} />
            {
                props.integrations && props.integrations.length > 0 ?
                <>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="TicketsRowCollums">
                            <Row name="ListOfIntegrationsHead">
                                <Col md={2} className="nopadding">
                                        {props.t('home_departments.integration_tab.list_of_integration.name')} 
                                </Col>
                                <Col md={2} className="nopadding">
                                        {props.t('home_departments.integration_tab.list_of_integration.status')} 
                                </Col>
                                <Col md={3} className="nopadding">
                                        {props.t('home_departments.integration_tab.list_of_integration.bots')} 
                                </Col>
                                <Col className="d-flex justify-content-end">
                                        {props.t('home_departments.integration_tab.list_of_integration.actions')} 
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                    <div id="ListOfIntegrations" style={{ overflowY: 'auto', height: '58vh' }}>
                        <InfiniteScroll
                            dataLength={props.integrations?.length}
                            next={props.updateListfunction}
                            hasMore={true}
                            loader={<h4> </h4>}
                            scrollableTarget={"ListOfIntegrations"}
                            // onScroll={ () => { Utils.hidePopoverOnScroll("ListOfIntegrations") } }
                        >
                            {
                                <ListGroup variant="flush" className="TicketsRow" >
                                    {props.integrations.map((item, index) => {
                                        return (
                                            <ListGroup.Item style={{ color: '#707070' }} id={item.sector_name + "*ID*" + item.sector_id} key={"keyAll" + index.toString()} >
                                                <Row className='TicketsRowHeight'>
                                                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} /* id={item.sector_name + "*ID*" + item.sector_id + "*ID*" + item.active.toString()} */ /* onClick={(e) => ListOfDepartmentsControllerConst.viewDepartmentPage(e)} */>
                                                        <div className='oneLineClampEllipsis me-3'>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.name != null ?
                                                                            <>{item.name}</>
                                                                            :
                                                                            <>{props.t('home_departments.integration_tab.list_of_integration.na')} </>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.name ? item.name : <>{props.t('home_departments.integration_tab.list_of_integration.na')} </>}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} /* id={item.name + "*ID*" + item.id + "*ID*" + item.active.toString()} */ /* onClick={(e) => ListOfDepartmentsControllerConst.viewDepartmentPage(e)} */>
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    {item.active ?
                                                                        <>{props.t('home_departments.integration_tab.list_of_integration.active')} </> : <>{props.t('home_departments.integration_tab.list_of_integration.inactive')} </>
                                                                    }
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div style={{ width: '33%' }}>
                                                                {item.active ?
                                                                    <div className="d-flex justify-content-center py-1"
                                                                        style={{
                                                                            width: '100%',
                                                                            background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                            borderRadius: '3px',
                                                                            font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                            letterSpacing: '0px',
                                                                            color: '#0C2461',
                                                                        }}>
                                                                        {props.t('home_departments.integration_tab.list_of_integration.active')} 
                                                                    </div> :
                                                                    <div className="d-flex justify-content-center py-1"
                                                                        style={{
                                                                            width: '100%',
                                                                            background: '#FFD4B0 0% 0% no-repeat padding-box',
                                                                            borderRadius: '3px',
                                                                            font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                            letterSpacing: '0px',
                                                                            color: '#FF8119',
                                                                        }}>
                                                                        {props.t('home_departments.integration_tab.list_of_integration.inactive')} 
                                                                    </div>
                                                                }
                                                            </div>
                                                        </OverlayTrigger>
                                                    </Col>
                                                    <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} /* id={item.sector_name + "*ID*" + item.sector_id + "*ID*" + item.active.toString()} */ /* onClick={(e) => ListOfDepartmentsControllerConst.viewDepartmentPage(e)} */>
                                                        <div className='oneLineClampEllipsis'>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        { item.chatbots && item.chatbots.length > 0? item.chatbots.toString(): ""}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    { item.chatbots && item.chatbots.length > 0? item.chatbots.toString(): ""}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col className='d-flex justify-content-end' >
                                                        <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.popoverActions}>
                                                            <Button id={`${item.name}*ID*${item.id}*ID*${item?.active?.toString()}`} name={item.name} onClick={(e) => props.setCurrentIntegration(e)} className='nopadding d-flex justify-content-end align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <circle cx="12" cy="12" r="1" />
                                                                    <circle cx="12" cy="19" r="1" />
                                                                    <circle cx="12" cy="5" r="1" />
                                                                </svg>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>);
                                    })}
                                </ListGroup>
                            }
                        </InfiniteScroll>
                    </div>
                </>
                :
                <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                    <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                        <Col md={1} className="d-flex justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-layers-linked" width="4vw" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#4a69bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M19 8.268a2 2 0 0 1 1 1.732v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h3" />
                                <path d="M5 15.734a2 2 0 0 1 -1 -1.734v-8a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-3" />
                            </svg>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center align-content-center mt-2' style={{  backgroundColor: 'transparent' }}>
                        <Col className="d-flex justify-content-center" >
                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{props.t('home_departments.integration_tab.list_of_integration.no_results')}  <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateIntegrationModal}>{props.t('home_departments.integration_tab.list_of_integration.add_integration')} </Button></p>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    );
}

export default ListOfIntegrations;