import { useDispatch, useSelector } from "react-redux"

import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppRequesterController } from "../services/appRequester/appRequesterController"
import { TokenInterface } from "../services/requestsInterfacesModel"
import { getIdUser } from "../store/user"
import UserService from "../services/user-service"

const AppRequesterConst = new AppRequesterController()

const useDaysForNotificationExpirationRequest = (setDays) => {

    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser)
        }
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    const headers = UserService.getHeaders()
    const router = `/agent/${values?.user?.id}/days-for-notification-expiration`

    const getTimeExpiration = useCallback(async () => {

        await AppRequesterConst.Get(
            router, { headers },
            (response: Object) => {

            },
            (data: any) => {
                if (data.status === 200) {
                    setDays(data?.data?.days)
                }
            },
            (error: Object) => {

            }, navigate, dispatch, setIsLoading, { }
        )
    }, [])

    // useEffect(() => {
    //     getTimeExpiration()
    // }, [getTimeExpiration])

    return { isLoading, refetchTimeExpiration: getTimeExpiration }

}

export default useDaysForNotificationExpirationRequest