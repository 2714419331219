import { useContext } from "react"
import { SocketContext } from "../core/context/socket-context"

const useLeaveTicketChat = () => {
    
    const socket = useContext(SocketContext);

    const onSocketLeaveTicketChat = (ticketId: string) => {
        socket.socket?.emit("leave-ticket-chat", ticketId)
    }

    return { onSocketLeaveTicketChat }
}

export default useLeaveTicketChat