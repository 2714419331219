import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ValidateControlPanelEmail from ".";
import constsRouters from "../../../routes/constsRouter";
import { setShowAlertFeedback } from "../../../store/internal";
import { AppRequesterController } from "../../../services/appRequester/appRequesterController";

const AppRequesterConst = new AppRequesterController();

const ValidateControlPanelEmailController = () => {

  const [validation, setvalidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `Painel Cxpress | Confirmação de conta`;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    setIsLoading(true);

    if (code !== undefined) {
      checkToken(code);
    } else {
      setvalidation(false);
      setIsLoading(false);
    }
  }, []);

  const checkToken = async (code: string) => {
    const headers = {
      "Authorization": "Bearer " + code,
    };

    await AppRequesterConst.Post(
      '/panel/credentials/confirm-email', {}, { headers },
      (response: Object) => { },
      (data: Object) => {
        setvalidation(true);
        dispatch(setShowAlertFeedback({ message: 'Conta confirmada com sucesso', visibility: true, signalIcon: true }));
      },
      (error: { response: { status: number; }; }) => {
        setvalidation(false);
        dispatch(setShowAlertFeedback({ message: 'Não foi possível confirmar sua conta', visibility: true, signalIcon: false }));
        if (error.response !== undefined) {
          //200 confirmado, 204 não encontrado
          if (error.response.status === 400) {

          }
        }
      }, navigate, dispatch, setIsLoading
    );
  }

  const goToLogin = () => {
    navigate(constsRouters.routers.home.path);
  }

  return (
    <ValidateControlPanelEmail 
      validation={validation}
      isLoading={isLoading}
      goToLogin={goToLogin}
      checkToken={checkToken}
    />
  );
}

export default ValidateControlPanelEmailController;
