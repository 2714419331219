import { Col, Row } from "react-bootstrap";
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import { Formik } from 'formik';

const General = ({
    t,
    dataCompany,
    showTokenGPT,
    showTokenEngine,
    showFeedbackTokenGpt,
    showFeedbackTokenEngine,
    clickTokenGPT,
    clickTokenEngine,
    submit,
    validate,
}) => {

    return (
        <Row style={{ marginRight: 0 }}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    tokenGpt: dataCompany?.token_chatgpt,
                    tokenEngine: dataCompany?.token_transcription
                }}
                validate={validate}
                onSubmit={submit}
            >

                {({
                    values,
                    //setValues,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    errors
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="d-flex justify-content-left">
                            <Col md={8} sm={8} lg={4}>

                            <Form.Label htmlFor="name">{t('configurations.chatbots.general.chat_gpt_token_label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="form-control-Default"
                                        isInvalid={showFeedbackTokenGpt}
                                        style={{ paddingRight: '40px' }}
                                        placeholder={t("configurations.chatbots.general.input_token_placeholder")}
                                        aria-label={t("configurations.chatbots.general.input_token_placeholder")}
                                        aria-describedby={t("configurations.chatbots.general.input_token_placeholder")}
                                        size='lg'
                                        type='password'
                                        id="tokenGpt"
                                        name="tokenGpt"
                                        autoComplete='given-name'
                                        maxLength={100}
                                        value={values.tokenGpt}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                    </FormControl>
                                    
                                    <div className="d-flex justify-content-end align-self-start">
                                        <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', paddingTop: '0.5vh', zIndex: "5" }} onClick={() => clickTokenGPT()}>
                                            {showTokenGPT ?
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx="12" cy="12" r="2" />
                                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                </svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="3" y1="3" x2="21" y2="21" />
                                                    <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                                    <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                                </svg>
                                            }
                                        </Button>
                                    </div>

                                    <Form.Control.Feedback type="invalid" id="FeedbackTokenGpt">
                                        {errors.tokenGpt}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row className="d-flex justify-content-left">
                            <Col md={8} sm={8} lg={4}>

                            <Form.Label htmlFor="name">{t('configurations.chatbots.general.transcription_token_label')}</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="form-control-Default"
                                        isInvalid={showFeedbackTokenEngine}
                                        style={{ paddingRight: '40px' }}
                                        placeholder={t("configurations.chatbots.general.input_token_placeholder")}
                                        aria-label={t("configurations.chatbots.general.input_token_placeholder")}
                                        aria-describedby={t("configurations.chatbots.general.input_token_placeholder")}
                                        size='lg'
                                        type='password'
                                        id="tokenEngine"
                                        name="tokenEngine"
                                        autoComplete='given-name'
                                        maxLength={100}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.tokenEngine}
                                    >
                                    </FormControl>
                                    <div className="d-flex justify-content-end align-self-start">
                                        <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', paddingTop: '0.5vh', zIndex: "5" }} onClick={() => clickTokenEngine()}>
                                            {showTokenEngine ?
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx="12" cy="12" r="2" />
                                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                </svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="3" y1="3" x2="21" y2="21" />
                                                    <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                                    <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                                </svg>
                                            }
                                        </Button>
                                    </div>

                                    <Form.Control.Feedback type="invalid" id="FeedbackTokenEngine">
                                        {errors.tokenEngine}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>

                        <Col md={4} className="d-flex mt-4">
                            <Button
                                type="submit"
                                size="lg"
                                className="buttonBlue"
                                style={{ width: "35%" }}
                            >
                                {t('configurations.chatbots.general.save')}
                            </Button>
                        </Col>

                    </Form>
                )}

            </Formik >
        </Row>
    )
}

export default General;