import { FC, useEffect, useState } from "react";
import { ILocalizedPhoneInputController } from "./indexModel";
import LocalizedPhoneInput from ".";
import i18next from "i18next";
import pt from 'react-phone-input-2/lang/pt.json';
import es from 'react-phone-input-2/lang/pt.json';
import { useTranslation } from "react-i18next";

const LocalizedPhoneInputController: FC<ILocalizedPhoneInputController> = (props) => {
    const [country, setCountry] = useState('br');
    const [localization, setLocalization] = useState(undefined);
    const [preferredCountries] = useState(['gb', 'br', 'pt', 'es']);
    
    const { t } = useTranslation();

    const lngToRegion = {
        'en': 'gb',
        'pt': 'pt',
        'br': 'br',
        'es': 'es'
    }

    const lngToLocalization = {
        'en': undefined,
        'br': pt,
        'pt': pt,
        'es': es
    }

    useEffect(() => {
        let language = i18next.language;
        if (language.includes('-')) {
            language = language.split('-')[1].toString().toLowerCase();
        }

        setCountry(lngToRegion[language]);
        setLocalization(lngToLocalization[language]);
    }, []);

    return <LocalizedPhoneInput 
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        handleBlur={props.handleBlur}
        phoneBorder={props.phoneBorder}
        localization={localization}
        preferredCountries={preferredCountries}
        country={country} 
        t={t}    
    />
}

export default LocalizedPhoneInputController;