import React from 'react';
import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../../components/loading'
import ButtonOrderByList from '../../components/buttonOrderByList';
import { ContractStatus } from '../../../../core/enums/contract-status';
import { ListName, OrderBy, SortBy } from '../../../../core/enums/order-sort-by';

const ListClients = ({
    isLoading,
    formatDate, 
    formatCNPJ,  
    viewAgentPage, 
    datas, 
    updateListfunction, 
    HasMoreInformation,  
    callbackOrderBy }) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <div style={{ marginTop: '2vh' }} className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                        <Row name="ListOfAgentsHead">
                            <Col md={2} className='d-flex align-self-center'>
                                <p>CNPJ</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <ButtonOrderByList 
                                    title="Empresa" 
                                    columnIdName={SortBy.name} 
                                    callbackOrderBy={callbackOrderBy} 
                                    defaultOrder={OrderBy.desc} 
                                    listName={ListName.ListOfTickets}/>
                            </Col>
                            <Col md={1} className="nopadding">
                                <p>Subdomínio</p>
                            </Col>
                            <Col md={2} className="nopadding" style={{ width: '10%' }}>
                                <p>Proprietário</p>
                            </Col>
                            <Col md={2} className="nopadding" style={{ width: '14%' }}>
                                <p>E-mail</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <ButtonOrderByList 
                                    defaultOrder={OrderBy.desc}
                                    title="Data de adesão" 
                                    columnIdName={SortBy.created_date} 
                                    callbackOrderBy={callbackOrderBy}/>
                            </Col>
                            <Col className='nopadding'>
                                <ButtonOrderByList 
                                    defaultOrder={OrderBy.desc}
                                    title="Status" 
                                    columnIdName={SortBy.status} 
                                    callbackOrderBy={callbackOrderBy}/>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    datas !== undefined ?
                        <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
                            <InfiniteScroll
                                dataLength={datas.length}
                                next={updateListfunction}
                                hasMore={HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"ListOfAgents"}

                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {datas.map((item, index) => {

                                            const cnpj = formatCNPJ(item.cnpj)
                                            const date = formatDate(item.created_at, "pt-BR")

                                            let status = "N/A"
                                            switch (item.contract_status_id) {
                                                case ContractStatus.Active:
                                                    status = 'Ativo';
                                                    break;
                                                case ContractStatus.Inactive:
                                                    status = 'Inativo';
                                                    break;
                                                case ContractStatus.EndTrial:
                                                    status = 'Fim da gratuidade';
                                                    break;
                                                case ContractStatus.Trial:
                                                    status = 'Período grátis';
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <ListGroup.Item style={{ color: '#707070' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.id} key={"keyAll" + index.toString()} >
                                                    <Row className='TicketsRowHeight'>
                                                        <Col md={2} className='d-flex  align-self-center'>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {cnpj != null ?
                                                                            <>{cnpj}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {cnpj ? cnpj : <>N/A</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.id} onClick={viewAgentPage}>
                                                            <div className='oneLineClampEllipsis'>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.name != null ?
                                                                                <>{item.name}</>
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item.name ? item.name : <>N/A</>}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={1} className='align-self-center nopadding' style={{ cursor: 'pointer'}} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.id} onClick={viewAgentPage}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.subdomain != null ?
                                                                                <>{item.subdomain}</>
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item.subdomain ? item.subdomain : <>N/A</>}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer', width: '10%' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.id} onClick={viewAgentPage} >
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.name != null ?
                                                                            <>{item.name} {item.name && item.lastname ? item.lastname : ''}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.name ? item.name : <>N/A</>} {item.name && item.lastname ? item.lastname : ''}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer', width: '14%' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.id} onClick={viewAgentPage}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.email != null ?
                                                                                <>{item.email}</>
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item.email ? item.email : <>N/A</>}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.id} onClick={viewAgentPage}>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {date}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {date}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col md={2} className='nopadding' >
                                                            {status === "Ativo" ?
                                                                <Button style={{ backgroundColor: '#E0F3E8', color: '#46AC6F', border: 'none', lineHeight: '1', padding: '0.2rem 0.5rem' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                Ativo
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span className='FilterTicketsRow'>Ativo</span>
                                                                    </OverlayTrigger>
                                                                </Button>
                                                                :
                                                                status === "Inativo" ?
                                                                    <Button style={{ backgroundColor: '#E8E8E8', color: '#707070', border: 'none', lineHeight: '1', padding: '0.2rem 0.5rem' }}>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    Inativo
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className='FilterTicketsRow '>Inativo</span>
                                                                        </OverlayTrigger>
                                                                    </Button>
                                                                    :
                                                                    status === "Período grátis" ?
                                                                        <Button style={{ backgroundColor: '#EEF3FF', color: '#0C2461', border: 'none', lineHeight: '1', padding: '0.2rem 0.5rem' }}>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id='tooltip'>
                                                                                        Período grátis
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span className='FilterTicketsRow '>Período grátis</span>
                                                                            </OverlayTrigger>
                                                                        </Button>
                                                                        :
                                                                        status === "Fim da gratuidade" ?
                                                                            <Button style={{ backgroundColor: '#FFE5CF', color: '#FF8119', border: 'none', lineHeight: '1', padding: '0.2rem 0.5rem'}}>
                                                                                <OverlayTrigger
                                                                                    key='top'
                                                                                    placement='top'
                                                                                    overlay={
                                                                                        <Tooltip id='tooltip'>
                                                                                            Fim da gratuidade
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className='FilterTicketsRow ' style={{ font: 'normal noemal bold 15px Roboto'}}>Fim da gratuidade</span>
                                                                                </OverlayTrigger>
                                                                            </Button> :
                                                                            null
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>);
                                        })}
                                    </ListGroup>
                                }
                            </InfiniteScroll>
                        </div>
                        :
                        <></>
                        // <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                        //     <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                        //         <Col md={1} className="d-flex justify-content-center">
                        //             <svg id="Grupo_11213" data-name="Grupo 11213" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                        //                 <path id="Caminho_9567" data-name="Caminho 9567" d="M0,0H24V24H0Z" fill="none" />
                        //                 <circle id="Elipse_158" data-name="Elipse 158" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        //                 <path id="Caminho_9568" data-name="Caminho 9568" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        //                 <path id="Caminho_9569" data-name="Caminho 9569" d="M16,11h6M19,8v6" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        //             </svg>
                        //         </Col>
                        //     </Row>
                        //     <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                        //         <Col md={4}>
                        //             <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, <Button className="buttonAsText3 nopadding mb-1" onClick={null}>ADICIONAR AGENTE</Button></p>
                        //         </Col>
                        //     </Row>
                        // </div>
                }
                <Row style={{ marginTop: '3vh' }} >
                    <Col md={3}>
                        <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="buttonDeleteAllSelected" className="buttonBlue" onClick={() => { }} >Excluir selecionados</Button>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default ListClients;