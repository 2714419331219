// import { Button, Popover } from "react-bootstrap"
import { useEffect, FC, useState, /*useState*/ } from 'react';
import Utils from "../../../../../core/shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { getIdCompany } from "../../../../../store/company";
import { getIdEmployee } from "../../../../../store/employee";
import { getIdUser, getNameUser, getProfileId } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
import { getPhoneNumber } from '../../../../../store/callcenter';
import { ListTagsInterface } from "./indexModel";
import ListPhones from ".";
import { useTranslation } from 'react-i18next';
import { Button, Popover } from 'react-bootstrap';
import { setShowEditPhoneModal, setShowLinkModal } from '../../../../../store/internal';

const ListOfPhonesController: FC<ListTagsInterface> = (props) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const [phone, setPhone] = useState('')
    const [sector, setSector] = useState([])
    const [phoneId, setPhoneId] = useState('')

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfPhones");
    }, []);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        callcenter: {
            phone_number: useSelector(getPhoneNumber)
        }
    };


    const popover = (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={() => showModal()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("components.modal_phone.btn_edit_phone")}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={() => showModalLink()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("components.modal_phone.btn_link")}</Button>
            </Popover.Body>
        </Popover>
    );

    const showModal = () => {
        dispatch(setShowEditPhoneModal(true));
    }

    const showModalLink = () => {
        dispatch(setShowLinkModal(true));
    }

    const infos = (number, sector, id) => {
        setPhone(number)
        setSector(sector)
        setPhoneId(id)
    }

    return <ListPhones
        t={t}
        props={props}
        values={values}
        isLoading={isLoading}
        popover={popover}
        infos={infos}
        phone={phone}
        sector={sector}
        phoneId={phoneId}
    />
}

export default ListOfPhonesController;