import React, { FC } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ButtonComponentInterface } from "./indexModel";

const ButtonComponent: FC<ButtonComponentInterface> = (props) => {
    return (
        <>
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                    <Tooltip id="tooltip" className="tooltip-arrow-Top">
                        {props.titleTootip}
                    </Tooltip>
                }
            >
                <Button className="mx-3 nopadding buttonTicketsSec" onClick={props.onClick}>
                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                        <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                        <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                </Button>
            </OverlayTrigger>
        </>
    )
}

export default ButtonComponent

