import { useTranslation } from 'react-i18next';
import Utils from '../../core/shared/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { CompanyRequests } from '../../services/company/companyControler';
import ForgotURL from '.';
import { BodyInterface } from './indexModel';
import { setShowAlertFeedback } from '../../store/internal';

const ForgotUrlController = () => {
    // const host: string = window.location.host;
    // const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    const { t } = useTranslation();
    const CompanyRequestsConst = new CompanyRequests();

    const [userEmail, setUserEmail] = useState("");
    const [showfeedback, setShowfeedback] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const resendEmailDomain = async () => {
        const body = {
            user_email: userEmail,
        };

        const headers = {
            "Content-Type": "application/json; charset=utf-8"
        };

        // setIsLoading(true);
        CompanyRequestsConst.SendEmailWithSubdomains(
            body,
            { headers },
            (response : Object) => {
                return response
            },
            (data) => {
                if (data.status === 200) {
                    dispatch(setShowAlertFeedback({ visibility: true, message: t("forgotUrl.new_email_sended"), signalIcon: true }))
                }
                return data
            },
            (error :Object) => {
                return error
            }, navigate, dispatch, setIsLoading
        );
    }

    const validate = (values) => {
        setUserEmail(values.email);

        return validationInputEmail(values,
            () => { setShowfeedback(true) },
            () => { setShowfeedback(false) })
    }

    const onSubmit = async (values, errors) => {
        const body = {
            "user_email": userEmail,
        };

        const headers = {
            "Content-Type": "application/json; charset=utf-8"
        };

        // setIsLoading(true);
        CompanyRequestsConst.SendEmailWithSubdomains(
            body,
            { headers },
            (response :Object) => {
                Utils.changeStyleElementById('screen1', 'display', 'none');
                Utils.changeStyleElementById('screen2', 'display', 'block');
                return response
            },
            (data) => {
                if (data.status === 200) {
                    dispatch(setShowAlertFeedback({ visibility: true, message: t("forgotUrl.email_sended"), signalIcon: true }))
                }
                return data;
            },
            (error:Object) => {
                setShowfeedback(true);
                document.getElementById("feedbackemail").innerHTML = t("forgotUrl.error_email_not_found");
            }, navigate, dispatch, setIsLoading,
        );
    }

    const validationInputEmail = (values: BodyInterface, FeedbackShow: Function, FeedbackHide: Function) => {
        let errors = {};
        if (!values.email) {
            errors['email'] = t('forgotUrl.requiredField');
            FeedbackShow();
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
            errors['email'] = t('forgotUrl.invalidEmail');
            FeedbackShow();
        } else {
            FeedbackHide();
        }

        return errors;
    }

    return <ForgotURL 
        isLoading={isLoading} 
        t={t} 
        showfeedback={showfeedback} 
        userEmail={userEmail} 
        resendEmailDomain={resendEmailDomain} 
        onSubmit={onSubmit} 
        validate={validate}
    />
}

export default ForgotUrlController;
