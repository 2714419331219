export interface Template_Message_hatsapp_Interface{
    data:{
        message: string,
        templateMessages: [
          {
            id: string,
            company_id: string,
            channel_id: string,
            message: string,
            title: string,
            name: string,
            namespace: string,
            tags: [
              {
                id: string,
                template_message_id: string,
                tag_number: 0,
                tag: string,
                type_tag: string,
                alias: string,
                value_replace: string
              }
            ],
            created_at: string,
            updated_at: string,
            deleted_at: string,
            quick_message: string
          }
        ]
    },
    status: number
}

export interface Message_Sending_Interface{
    data:[
        
        company_id: string,
        limit: number,
        page: number,
        active: boolean,
        search: string,
        sort_by: string,
        order_by: string,
        offset: number
          
    ],
    status:number
}

export interface  Message_SendingType_Interface{
    data:{
        messageSendingTypes: [
          {
            id: string,
            name: string,
            active: boolean
          }
        ],
        message: string
      },
    status: number
}

export interface TokenInterface {
    user: {
        name: string,
        lastname: string,
        profile_id: string
    },
    token: {
        value: string
    },
    company: {
        id: string
    },
}

export enum CurrentTriggerTabEnum {
    Trigger = "trigger",
    TriggerType = "trigger_type"
}