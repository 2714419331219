import PhoneInput from "react-phone-input-2";
import { ILocalizedPhoneInput } from "./indexModel";
import { FC, useEffect } from "react";

const LocalizedPhoneInput: FC<ILocalizedPhoneInput> = (props) => {
    return (
        <>
            {(props.country !== 'gb' && props.localization !== undefined) || props.country === "gb"?
                <PhoneInput
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.handleBlur}
                    inputProps={{
                        // name: 'Telefone',
                        required: true,
                        border: 'none',
                        style: { width: '100%', font: ' var(--px15_19-font-Roboto)', height: '32px', border: props.phoneBorder }
                    }}
                    localization={props.localization as Object}
                    preferredCountries={props.preferredCountries} 
                    country={props.country}
                    enableSearch
                    searchPlaceholder={props.t("components.localized_phone_input.search")}
                    searchStyle={{ width: '94%', marginTop: '0.2vh', marginBottom: '0.2vh' }}
                />:
                <></>
            }
        </>
    );
}

export default LocalizedPhoneInput;