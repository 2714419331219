type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

const styles = StyleSheet.create({

  textFontGlay: {
    textAlign: 'left',
    font: 'var(--px15_19-font)',
    letterSpacing: '0px',
    color: '#707070',
    opacity: '1',
  },

  textBlack: {
    textAlign: 'left',
    font: 'var(--px15_20_Bold-font)',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
  },

  textBlackSec: {
    textAlign: 'left',
    font: 'var(--px15_19_Bold-font)',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
  },
  inputGray: {
    height: '1vh',
  },

  spaceLeft: {
    marginLeft: '0.5vh'
  },
});

export default styles;






