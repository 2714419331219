import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

const General = ({
    enableDetailedMessage,
    isLoading,
    showName,
    t,
}) => {

    return (
        <Row style={{ marginRight: 0 }}>
            <Col className='d-flex  align-self-center'>
                <div className="switch-input" >
                    <span style={{ marginRight: '-0.5rem', fontWeight: '500' }}>{t("home_channels.channel_tabs.general.general_config_company_message")}</span>
                    {
                        <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip'>
                                    {t("home_channels.channel_tabs.general.general_config_company_message_tooltiip")}
                                </Tooltip>
                            }
                        >
                            <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            </svg>
                        </OverlayTrigger>
                    }
                    <label className="switch">
                        <input
                            disabled={isLoading}
                            type="checkbox"
                            checked={showName}
                            onChange={enableDetailedMessage}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </Col>
        </Row>
    )
}

export default General;