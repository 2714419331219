import React, { useState, useEffect, FC } from 'react';
import { useDispatch } from "react-redux";

import { setShowEditPaswordAgent } from '../../../../../store/internal'
import { useNavigate } from 'react-router-dom';
import { ModalEditTokenChatbot } from './indexModel';
import EditAgentPassword from './index'

const EditAgentPasswordController: FC<ModalEditTokenChatbot> = (props) => {
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [senha, setSenha] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showFeedbackSenha, setShowFeedbackSenha] = useState(false);

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setModalShow(props.visibility);
  }, [props.visibility, props.data]);

  const Hide = () => {
    setShowPassword(false);
    setShowFeedbackSenha(false);
    dispatch(setShowEditPaswordAgent(false));
  }

  const clickPassword = () => {
    if ((document.getElementById("senha") as HTMLInputElement).type === "text") {
        (document.getElementById("senha") as HTMLInputElement).type = "password";
    } else {
        (document.getElementById("senha") as HTMLInputElement).type = "text";
    }

    setShowPassword(!showPassword);
};

  return (
    <EditAgentPassword
      modalShow={modalShow}
      Hide={Hide}
      props={props}
      dispatch={dispatch}
      navigate={navigate}
      setIsLoading={setIsLoading}
      showPassword={showPassword}
      clickPassword={clickPassword}
      setSenha={setSenha}
      senha={senha}
      showFeedbackSenha={showFeedbackSenha}
      setShowFeedbackSenha={setShowFeedbackSenha}
      company_id={props.company_id}
    />
  );
}

export default EditAgentPasswordController;