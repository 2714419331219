import React from 'react';

import allStyles from './styles';

import { Modal, Row, Container, Col, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';

import { setShowAlertFeedback } from '../../../../../store/internal'
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import constsApi from '../../../../../services/constsApi';

const AppRequesterConst = new AppRequesterController();

const EditAgentType = ({
  modalShow,
  Hide,
  props,
  dispatch,
  navigate,
  setIsLoading,
  agentAdministrator,
  setAgentAdministrator,

}) => {

  return (
    <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" centered={true}>
      <Modal.Header style={{ padding: '1rem 1rem 0rem 1rem' }} closeButton>
      </Modal.Header>
      <Modal.Header style={{ padding: '0rem 1.45rem' }}>
        <Modal.Title style={allStyles.textBlack}>
          Alterar tipo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Container>
          <Formik
            initialValues={{
              token: "",
            }}
            validate={values => {
              let errors = {};
              // setTokenUserGpt(values.token);
              return errors;
            }}
            onSubmit={async () => {
              let JsonSend = {
                "company_id": props.data.company_id,
                "employee_id": props.data.id,
                "profile_id": agentAdministrator ? constsApi.profile[0].id : constsApi.profile[1].id
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await AppRequesterConst.Put(
                '/panel/employee/profile', JsonSend, { headers },
                (response: Object) => { return response; },
                (data: any) => {
                  if (data.status === 200) {
                    dispatch(setShowAlertFeedback({ message: `Tipo do agente ${props.data.name} foi atualizado`, visibility: true, signalIcon: true }));
                  }
                  props.callback()
                  Hide();
                },
                (error: { response: { status: number; data: { message: string }; }; }) => {
                  if (error.response.data.message) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                  }
                  else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                  }
                }, navigate, dispatch, setIsLoading
              );
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>

                <Row className="d-flex justify-content-center">
                  <Col md={12} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="basic-url">Agente Administrador? &nbsp; &nbsp;</Form.Label>
                    <Form.Check name="radioIsAdministrator" label={'Não'} type={'radio'} checked={!agentAdministrator} onChange={() => { setAgentAdministrator(false) }} inline></Form.Check>
                    <Form.Check name="radioIsAdministrator" label={'Sim'} type={'radio'} checked={agentAdministrator} onChange={() => { setAgentAdministrator(true) }} inline></Form.Check>
                  </Col>
                </Row>
                <Row style={allStyles.textFontGlay} className="mt-3">
                  <Col md={5} >
                    <Button size='lg' className="buttonWhite2" style={{ width: '100%' }} onClick={() => Hide()} >Cancelar</Button>
                  </Col>
                  <Col md={2}>

                  </Col>
                  <Col md={5} className="d-flex justify-content-end align-self-end">
                    <Button type='submit' size='lg' className="buttonBlue2WithoutBold" style={{ width: '100%' }}>Salvar</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default EditAgentType;