import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { Button, Popover } from "react-bootstrap";
import { setShowAlertFeedback, setShowConfirmationModal, setShowEditFacebookPage } from "../../../../../store/internal";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import UserService from "../../../../../services/user-service";
import { ItemKeyInterface, IListOfFacebookController } from "./indexModel";
import ListFacebook from ".";
import { useTranslation } from "react-i18next";
import Utils from "../../../../../core/shared/utils";

const ListOfFacebookController: FC<IListOfFacebookController> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const appRequesterConst = new AppRequesterController();

    const [/*isLoading*/, setIsLoading] = useState();
    const [active, setActive] = useState(false);
    const [/*pageID*/, setPageID] = useState("");

    const [data, setData] = useState(null);

    /*const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany),
            cnpj: useSelector(getCNPJCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getShowAcquirePlatformComponent: useSelector(getShowAcquirePlatformComponent).visibility,
        }
    }*/

    useEffect(() => {
        props?.activeFacebook ?
            Utils.changeActiveScrollBar("listOfFacebooks")
            :
            Utils.changeActiveScrollBar("listOfInstagram")
    }, [props.datas]);

    const activeAndDisableRequest = async (item: ItemKeyInterface, channel: 'facebook'|'instagram') => {

        const headers = UserService.getHeaders();

        let address = '';
        const JsonSend = {
            is_active: !item.isActive
        }

        if (channel === 'facebook' && item.pageId) {
            address = `/channel-facebook/update-facebook-keys/api/${item.pageId}`;
            setPageID(item.pageId);
        } else if (channel === 'instagram' && item.userId) {
            address = `/channel-instagram/update-instagram-keys/api/${item.userId}`;
            setPageID(item.userId);
        } else {
            return;
        }

        setActive(!item.isActive);

        await appRequesterConst.Put(
            address, JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                if (item.isActive) {
                    dispatch(setShowAlertFeedback({ message: t("home_channels.lists_channels.list_facebook.success_revoke_page"), visibility: true, signalIcon: true }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t("home_channels.lists_channels.list_facebook.success_active_page"), visibility: true, signalIcon: true }));
                }
                props.updateListAfterEdit();
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading
        );
    }

    const disableFacebookConfirm = (e: { currentTarget: { id: string; }; }, activeAndDisableRequest: Function, userId: string) => {
        setActive(false);
        setPageID(e.currentTarget.id);
        dispatch(setShowConfirmationModal({ 
            visibility: true, 
            text: { 
                title: t("home_channels.lists_channels.list_facebook.revoke_access"), 
                body: t("home_channels.lists_channels.list_facebook.revoke_access_page"), 
                id: "", 
                message: t("home_channels.lists_channels.list_facebook.send_messages"), 
                middleText: t("home_channels.lists_channels.list_facebook.for_page"), 
                warning: t("home_channels.lists_channels.list_facebook.not_warning"), 
                end: t("home_channels.lists_channels.list_facebook.in_cxpress"), 
                buttonConfirmationText: t("home_channels.lists_channels.list_facebook.revoke_access"), 
                buttonReturnText: t("home_channels.lists_channels.list_facebook.cancel") 
            }, 
            functionConfirmation: () => activeAndDisableRequest(userId)
        }));
    }

    const activeFacebookConfirm = (e: { currentTarget: { id: string; }; }, activeAndDisableRequest: Function, userId: string) => {
        setActive(true);
        setPageID(e.currentTarget.id);
        dispatch(setShowConfirmationModal({ 
            visibility: true, 
            text: { 
                title: t("home_channels.lists_channels.list_facebook.active_access"), 
                body: t("home_channels.lists_channels.list_facebook.active_access_page"), 
                id: "", 
                message: t("home_channels.lists_channels.list_facebook.send_messages"), 
                middleText: t("home_channels.lists_channels.list_facebook.for_page"), 
                warning: t("home_channels.lists_channels.list_facebook.warning"), 
                end: t("home_channels.lists_channels.list_facebook.in_cxpress"), 
                buttonConfirmationText: t("home_channels.lists_channels.list_facebook.active_access"), 
                buttonReturnText: t("home_channels.lists_channels.list_facebook.cancel") 
            }, 
            functionConfirmation: () => activeAndDisableRequest(userId)
        }));
    }

    const popover = (active: boolean, page_id: string, activeAndDisableRequest: Function, userId: string, itemData: any) => {
        return (
            <Popover id={page_id} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    <Button id={"edit_page" + page_id} onClick={() => editInfo(itemData)} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_facebook.edit")}</Button>
                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    {active ?
                        <Button id={page_id} onClick={(e) => disableFacebookConfirm(e, activeAndDisableRequest, userId)} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_facebook.revoke_account")}</Button>
                        :
                        <Button id={page_id} onClick={(e) => activeFacebookConfirm(e, activeAndDisableRequest, userId)} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_facebook.active_access")}</Button>
                    }
                </Popover.Body>
            </Popover>
        )
    };

    const editInfo = (itemData: any) => {
        dispatch(setShowEditFacebookPage(true));
        setData(itemData);
    }

    return <ListFacebook
        t={t}
        popover={popover}
        activeAndDisableRequest={activeAndDisableRequest}
        datas={props.datas}
        activeFacebook={props.activeFacebook}
        activeInstragram={props.activeInstragram}
        callbackOrderBy={props.callbackOrderBy}
        updateListFunction={props.updateListFunction}
        HasMoreInformation={props.HasMoreInformation}
        data={data}    
        updateListAfterEdit={props.updateListAfterEdit}
    />
}

export default ListOfFacebookController;