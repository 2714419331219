import { createSlice } from '@reduxjs/toolkit'

export const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    value: null as string | null
  },
  reducers: {
    setToken: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {setToken} = tokenSlice.actions

export const getToken = (state: { token: { value: string; }; }) => (state.token?.value);

export default tokenSlice.reducer
// import { createSlice } from '@reduxjs/toolkit'

// export const internalSlice = createSlice({
//     name: 'control_panel',
//     initialState: {
//         tokenPanel: null as string || null,
//     },
//     reducers: {
//         setTokenPanel: (state, action)  => {
//             state.tokenPanel = action.payload
//         },
//     }
// })

// // Action creators are generated for each case reducer function
// export const { setTokenPanel } = internalSlice.actions

// // export const getTokenPanel = (state: { control_panel: { tokenPanel: string; } }) => (state.control_panel.tokenPanel)
// export const getTokenPanel = state => (state.internal.tokenPanel !== undefined ? state.internal.tokenPanel: internalSlice.getInitialState().tokenPanel);

// export default internalSlice.reducer

