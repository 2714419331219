import { FC } from 'react';
import { AlertPositiveNegativePropsInterface } from './indexModel';
import styles from './styles';
import { IconCircleCheck, IconAlertCircle, IconX } from '@tabler/icons-react';

const AlertPositiveNegative: FC<AlertPositiveNegativePropsInterface> = (props) => {

  const chooseSignalIcon = () => {
    return (
      props.signalIcon ?
        <IconCircleCheck size={26} color="#46ac6f" />
        :
        <IconAlertCircle size={26} color="#dc3545" />
    );
  }

  return (
    <div style={{ display: props.displayFunction(), ...styles.customContainer }} className="">
      <div className="d-flex align-items-center me-0" style={styles.rowAlert}>
        <div className="d-flex justify-content-start align-items-center" style={{ width: 'fit-content', marginRight: '15px' }}>
          {chooseSignalIcon()}
        </div>
        <div
          className="d-flex justify-content-start nopadding"
          style={{
            textAlign: 'left',
            font: 'normal normal bold calc(0.4em + 0.40vw) Montserrat',
            letterSpacing: '0px',
            color: '#1E1E1E',
            opacity: '1',
            flexDirection: 'column'
          }}
        >
          {
            typeof props.message === 'object' ?
              props.signalIcon ? <>Alteração realizada com sucesso</> : <>Ocorreu um erro inesperado, tente novamente mais tarde</>
              :
              <>{props.message}</>
          }
          <span style={styles.timestamp}>{new Date(props.timestamp || Date.now()).toLocaleString()}</span>
        </div>
          <div className="d-flex justify-content-end">
            <IconX size={20} color="#707070" style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => props.closeAlertFunction()}/>
          </div>
      </div>
    </div>
  );
}

export default AlertPositiveNegative;
