import React, { FC, useEffect, useState } from 'react';
import { ModalHistoryLogInterface } from './indexModel';
import { Button, Col, Container, ListGroup, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import ButtonSearchCleanInput from '../../../buttonSearchCleanInputComponent';

import allStyles from './styles';

import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { CurrentTicketHistoryLogInterface, GetAllTicketsInterface, TicketsHistoryInterface } from '../../../../../../services/requestsInterfacesModel';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TokenInterface } from '../../../../homeMessageTrigger/indexModel';
import { getToken } from '../../../../../../store/token';
import { ErrorRequestController } from '../../../../../../services/errorsInterfaceModel';
import { TicketStatus } from '../../../../../../core/enums/ticket-status';
import Loading from '../../../../../../components/loading';
import { TicketLogChange } from '../../../../../../core/enums/ticket-log-change';
import UserService from '../../../../../../services/user-service';

const ModalHistoryLog: FC<ModalHistoryLogInterface> = (props) => {
    return (
        <Container
            className='d-flex justify-content-center nopadding'
        >
            <Loading visibility={props.isLoading}></Loading>
            <Row className="nopadding d-flex align-items-center">
                <Col className="nopadding px-2" sm={12} md={12} 
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '10px',
                        opacity: 1,
                        width: 'min(max(240px, 24vw), 690px)',
                        // height: 'min(max(240px, 50vh), 506px)',
                    }}
                >
                    <Row className="d-flex justify-content-start align-items-center nopadding mt-3" >
                        <Col 
                            style={{
                                textAlign: 'left',
                                font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#0C2461',
                                opacity: '1',
                            }}
                        >
                            {
                                props.requester?.profile_id !== null && props.requester?.profile_id !== undefined?
                                <p>
                                    { props.t("home_tickets.history_log.history_agent") }
                                </p>:
                                <p>
                                    { props.t("home_tickets.history_log.history_consumer") }
                                </p>
                            }
                        </Col>
                        <Col sm={1} md={1} className="nopadding d-flex justify-content-center">
                            <Button
                                style={{
                                    cursor: 'pointer',
                                    border: 'none',
                                    background: 'none',
                                }}
                                onClick={() => props.setShowPopoverHistoryLog(false)}>
                                <svg id="Grupo_11452" data-name="Grupo 11452" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Caminho_9734" data-name="Caminho 9734" d="M0,0H24V24H0Z" fill="none" />
                                    <line id="Linha_394" data-name="Linha 394" x1="10" y2="10" transform="translate(6 6)" fill="none" stroke="#6a6a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <line id="Linha_395" data-name="Linha 395" x2="10" y2="10" transform="translate(6 6)" fill="none" stroke="#6a6a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>
                            </Button>
                        </Col>
                    </Row>

                    <Row className="d-flex nopadding mb-4" style={{ /* height: '5vh',  */width: '100%' }}>
                        <Tabs onSelect={(selectedTab) => props.tabActive(selectedTab) } className='ms-2' mountOnEnter={true} unmountOnExit={true} defaultActiveKey={"current_ticket"} id="historyTicketsTabs" >
                            <Tab eventKey={"current_ticket"} tabClassName={props.classCurrentTicket} title={ props.t("home_tickets.history_log.title_current_ticket") }>
                                <Row>
                                    <div id="listOfCurrentTicketUpdates" style={{ overflowY: 'auto', height: 'calc(min(max(50px, 35vh), 240px) + 53px)', marginTop: '0.4rem' }}>
                                        {
                                            props.currentTicketHistoryList !== undefined && props.currentTicketHistoryList !== null?
                                            <ListGroup variant="flush">
                                                {
                                                    props.currentTicketHistoryList.map((item, index) => {
                                                        let date: Date | null = null;
                                                        let hour: string = null;

                                                        date = new Date(item.created_at);
                                                        hour = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}:${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`;

                                                        let action = "";
                                                        let target1 = null;
                                                        let target2 = null;
                                                        let isSubject = false;

                                                        // verificando se foi modificado o assunto do ticket
                                                        if (item.field === "Assunto do ticket")
                                                            isSubject = true;

                                                        // ação inicial
                                                        if (item.action === TicketLogChange.Add)
                                                            action = props.t("home_tickets.history_log.add");
                                                        else if (item.action === TicketLogChange.Remove) 
                                                            action = props.t("home_tickets.history_log.delete");
                                                        else if (item.action === TicketLogChange.Replace && item.field === "Status")
                                                            action = props.t("home_tickets.history_log.pass");
                                                        else if (item.action === TicketLogChange.Replace)
                                                            action = props.t("home_tickets.history_log.change");
                                                        else if (item.action === TicketLogChange.DeleteTicket && item.field !== 'Erro')
                                                            action = props.t("home_tickets.history_log.ticket_deleted");
                                                        else if (item.action === TicketLogChange.DeleteTicket && item.field === 'Erro')
                                                            action = props.t("home_tickets.history_log.delete_ticket_error");
                                                        else if (item.action === TicketLogChange.SolveTicket && item.field === 'Erro')
                                                            action = props.t("home_tickets.history_log.solve_ticket_error");
                                                        else if (item.action === TicketLogChange.RecoveryTicket){
                                                            action = props.t("home_tickets.history_log.recovery_ticket");
                                                            item.field = "Ticket";
                                                        }
                                                        
                                                        // especificando ação 
                                                        if (item.field === "Seguidores")
                                                            action += " " + props.t("home_tickets.history_log.follower");
                                                        else if (item.field === "Atribuído")
                                                            action += " " + props.t("home_tickets.history_log.assigned");
                                                        else if (item.field === "Solicitante")
                                                            action += " " + props.t("home_tickets.history_log.requester");

                                                        // especificando os alvos da ação
                                                        if(item.field !== 'Erro'){
                                                            if (item.old_value === null) 
                                                                target1 = item.new_value;
                                                            else if (item.new_value === null) 
                                                                target1 = item.old_value;
                                                            else { 
                                                                target1 = item.old_value;
                                                                target2 = item.new_value;
                                                            }
                                                        }

                                                        // ação de troca
                                                        if (item.action === TicketLogChange.Replace)
                                                            action += " " + props.t("home_tickets.history_log.from")

                                                        return (
                                                            <div key={index} id={item.id} >
                                                                <Row className='my-1 mx-2'>
                                                                    <Col md={6} sm={6} className='d-flex justify-content-start px-0'>
                                                                        <div className='oneLineClampEllipsis nopadding'>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id={'tooltip*ID*' + index}>
                                                                                        {item.employee_name}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span style={allStyles.textAgent}>
                                                                                    {item.employee_name}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={6} className='d-flex justify-content-end px-0'>
                                                                        <div className="me-2 d-flex align-items-center" style={allStyles.labelNavyBlue}>
                                                                            {item.action === TicketLogChange.DeleteTicket?
                                                                                "Ticket":
                                                                                item.field
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='my-1 mx-2'>
                                                                    <div className='px-0'>
                                                                        <span className={isSubject && 'oneLineClampEllipsis nopadding'}>
                                                                            <span style={allStyles.textAction}>
                                                                                {
                                                                                    (item.action === TicketLogChange.DeleteTicket || item.action === TicketLogChange.RecoveryTicket || item.action === TicketLogChange.SolveTicket)
                                                                                        ?    action
                                                                                        :    <>{action}: &#160;</>
                                                                                }
                                                                            </span>
                                                                            {target1 &&
                                                                                    <OverlayTrigger
                                                                                        key='top'
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip id={'tooltip*ID*' + index}>
                                                                                                {target1}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span style={allStyles.textTarget}>
                                                                                            {target1} &#160;
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                            }
                                                                        </span>
                                                                        <span className={isSubject && 'oneLineClampEllipsis nopadding'}>
                                                                            {target2 &&
                                                                                <>
                                                                                    <span style={allStyles.textAction}>
                                                                                        { props.t("home_tickets.history_log.to") }: &#160;
                                                                                    </span>
                                                                                    <OverlayTrigger
                                                                                        key='top'
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip id={'tooltip*ID*' + index}>
                                                                                                {target2}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span style={allStyles.textTarget}>
                                                                                            {target2} &#160;
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                </>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </Row>  
                                                                <Row className='mt-3 mb-1 mx-2'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {props.formatedDate(date)} - {hour}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span style={allStyles.textDate}>
                                                                            {props.formatedDate(date)} {props.t("home_tickets.history_log.complement_hour")} {hour}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Row>
                                                                <hr style={{ border: "1px solid #E8E8E8", margin: '0.5rem' }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ListGroup>: 
                                             <div className='align-self-center' style={{ overflow: 'hidden', height: '20vh', backgroundColor: '#F9F9F9' }}>
                                                <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '5vh', backgroundColor: '#F9F9F9' }}>
                                                    <Col className='d-flex justify-content-center' md={1}>
                                                        <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                                            <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                            <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Col>
                                                </Row>
                                                <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                                    <Col className='d-flex justify-content-center'>
                                                        <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>
                                                            <span>{props.t("home_tickets.history_log.without_updates")}</span>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                </Row>
                            </Tab>
                            <Tab eventKey={"last_tickets"} tabClassName={props.classLastTickets} title={props.t("home_tickets.history_log.title_last_tickets")} name="lastTickets" id="test">
                                <Row className='mt-2 me-0'>
                                    <ButtonSearchCleanInput inputPlaceholder={props.t("home_tickets.history_log.search")} filteringTickets={(e, auxObj, action) => props.filteringTickets(e, auxObj, action)} setCurrentFilterSearch={props.setCurrentFilterSearch} currentFilterSearch={props.currentFilterSearch} desactiveSearchButton={true}></ButtonSearchCleanInput>
                                </Row>
                                <Row>
                                    <div id="listOfLastTickets" style={{ overflowY: 'auto', height: 'min(max(50px, 35vh), 240px)', marginTop: '0.4rem' }}>
                                        {/* <InfiniteScroll
                                            dataLength={props.lastTicketsHistoryList? props.lastTicketsHistoryList.length: 4}
                                            next={updateListfunction}
                                            hasMore={true}
                                            loader={<h4></h4>}
                                            scrollableTarget={"listOfLastTickets"}
                                        > */}
                                            {props.lastTicketsHistoryList !== undefined && props.lastTicketsHistoryList !== null?
                                                <ListGroup variant="flush">
                                                    {props.lastTicketsHistoryList.map((item, index) => {
                                                        let date: Date | null = null;
                                                        let hour: string = null;
                                                        let newDate: Date | null = null;

                                                        if (item.deleted_at !== null) {
                                                            newDate = new Date(item.deleted_at);
                                                        } else if (item.finished_at !== null) {
                                                            newDate = new Date(item.finished_at);
                                                        } else if (item.ended_at !== null) {
                                                            newDate = new Date(item.ended_at);
                                                        } else if (item.updated_level2_at !== null) {
                                                            newDate = new Date(item.updated_level2_at);
                                                        } else if (item.updated_at !== null) {
                                                            newDate = new Date(item.updated_at);
                                                        } else {
                                                            newDate = new Date(item.created_at);
                                                        }

                                                        date = newDate;
                                                        hour = `${newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()}:${newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()}:${newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds()}`;
                                                        
                                                        /* let statusType = Utils.getTicketStatusName(item.status_id);
                                                        let statusName = statusType == TicketStatus.NotAssigned
                                                        ? "N/A"
                                                        : statusType.charAt(0).toUpperCase(); */

                                                        return (
                                                            <div key={index} id={item.id} style={{ cursor: 'pointer', backgroundColor: props.currentTicket && props.currentTicket.id && props.currentTicket.id === item.id && '#f0f8ff' }} onClick={(e) => props.openSelectedTicket(e)}>
                                                                {/* <ListGroup.Item style={{ cursor: 'pointer' }} id={"itemTicketList" + (index + 1)}> */}
                                                                <Row className='my-1 mx-2'>
                                                                    <Col md={6} sm={6} className='d-flex justify-content-start px-0'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    #{item.ticket_reference_id}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <div
                                                                                className="d-flex align-items-center px-2 mx-0"
                                                                                style={allStyles.labelBlue}
                                                                            >
                                                                                <p style={{ padding: '0', margin: '0vw 0vw 0vw -0.2vw' }}>#{item.ticket_reference_id}</p>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                    <Col sm={6} md={6} className="d-flex justify-content-end px-0">
                                                                        {item.status_id === TicketStatus.Resolved ?
                                                                            <Button style={{ backgroundColor: '#707070', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                <OverlayTrigger
                                                                                    key='top'
                                                                                    placement='top'
                                                                                    overlay={
                                                                                        <Tooltip id='tooltip'>
                                                                                            { props.t("ticket_status.resolved") }
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className='FilterTicketsRow '>{ props.t("ticket_status_symbol.resolved") }</span>
                                                                                </OverlayTrigger>
                                                                            </Button>
                                                                            :
                                                                            item.status_id === TicketStatus.Pending?
                                                                                <Button style={{ backgroundColor: '#DE2450', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                    <OverlayTrigger
                                                                                        key='top'
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip id='tooltip'>
                                                                                                { props.t("ticket_status.pending") }
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span className='FilterTicketsRow '>{ props.t("ticket_status_symbol.pending") }</span>
                                                                                    </OverlayTrigger>
                                                                                </Button>
                                                                                :
                                                                                item.status_id === TicketStatus.OnHold?
                                                                                    <Button style={{ backgroundColor: '#FBBD4E', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                        <OverlayTrigger
                                                                                            key='top'
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip id='tooltip'>
                                                                                                    { props.t("ticket_status.on_hold") }
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className='FilterTicketsRow '>{ props.t("ticket_status_symbol.on_hold") }</span>
                                                                                        </OverlayTrigger>
                                                                                    </Button>
                                                                                    :
                                                                                    item.status_id === TicketStatus.Open?
                                                                                        <Button style={{ backgroundColor: '#4A69BD', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                            <OverlayTrigger
                                                                                                key='top'
                                                                                                placement='top'
                                                                                                overlay={
                                                                                                    <Tooltip id='tooltip'>
                                                                                                        { props.t("ticket_status.open") }
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <span className='FilterTicketsRow '>{ props.t("ticket_status_symbol.open") }</span>
                                                                                            </OverlayTrigger>
                                                                                        </Button>
                                                                                        :
                                                                                        item.status_id === TicketStatus.New?
                                                                                            <Button style={{ backgroundColor: '#17a589', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                                <OverlayTrigger
                                                                                                    key='top'
                                                                                                    placement='top'
                                                                                                    overlay={
                                                                                                        <Tooltip id='tooltip'>
                                                                                                            { props.t("ticket_status.new") }
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <span className='FilterTicketsRow '>{ props.t("ticket_status_symbol.new") }</span>
                                                                                                </OverlayTrigger>
                                                                                            </Button>
                                                                                            :
                                                                                            item.status_id === TicketStatus.Abandoned?
                                                                                                <Button style={{ backgroundColor: '#2D3233', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                                    <OverlayTrigger
                                                                                                        key='top'
                                                                                                        placement='top'
                                                                                                        overlay={
                                                                                                            <Tooltip id='tooltip'>
                                                                                                                { props.t("ticket_status.abandoned") }
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <span className='FilterTicketsRow '>{ props.t("ticket_status_symbol.abandoned") }</span>
                                                                                                    </OverlayTrigger>
                                                                                                </Button>
                                                                                            :
                                                                                            null
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row className='my-1 mx-2'>
                                                                    <div className='oneLineClampEllipsis nopadding'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id={'tooltip*ID*' + index}>
                                                                                    {item.subject}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    textAlign: 'left',
                                                                                    font: 'var(--px15_19_Bold-font)',
                                                                                    letterSpacing: '0px',
                                                                                    height: '2.2vh',
                                                                                }}>
                                                                                {item.subject}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Row>
                                                                <Row className='my-1 mx-2'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {props.formatedDate(date)} - {hour}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span style={allStyles.textDate}
                                                                        >
                                                                            {props.formatedDate(date)} {props.t("home_tickets.history_log.complement_hour")} {hour}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Row>
                                                                <hr style={{ border: "1px solid #E8E8E8", margin: '0.5rem' }} />
                                                                {/* // </ListGroup.Item> */}
                                                            </div>
                                                        )
                                                    })}
                                                </ListGroup>:
                                                <div className='align-self-center' style={{ overflow: 'hidden', height: '20vh', backgroundColor: '#F9F9F9' }}>
                                                    <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '5vh', backgroundColor: '#F9F9F9' }}>
                                                        <Col className='d-flex justify-content-center' md={1}>
                                                            <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                                                <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                                <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </Col>
                                                    </Row>
                                                    <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                                        <Col className='d-flex justify-content-center'>
                                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>
                                                                <span>{ props.t("home_tickets.history_log.without_updates") }</span>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        {/* </InfiniteScroll> */}
                                    </div>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default ModalHistoryLog;
