import { FC, SetStateAction, useState } from "react";
import CreateTagModal from ".";
import { useDispatch } from "react-redux";
import { ICreateTagModalController } from "./indexModal";

const CreateTagController: FC<ICreateTagModalController> = (props) => {
    const dispatch = useDispatch();

    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [showFeedbackLabel, setShowFeedbackLabel] = useState(false);
    
    const [optionsType, setOptionsType] = useState([
        { value: "consumer.name", label: "Nome do consumidor" },
        { value: "consumer.email", label: "Email do consumidor" },
        { value: "consumer.phone", label: "Telefone do consumidor" },
        { value: "ticket.ticket_reference_id", label: "Número do ticket" },
        { value: "employee.name", label: "Nome do agente" },
        { value: "general.type", label: "Tipo geral" },
    ]);
    const [selectedType, setSelectedType] = useState([]);

    /* const hideCreateParamModal = () => {
        props.dispatch(setShowCreateParamModal(false));
    } */

    return <CreateTagModal 
        showFeedbackName={showFeedbackName}
        setShowFeedbackName={setShowFeedbackName}
        showFeedbackLabel={showFeedbackLabel}
        setShowFeedbackLabel={setShowFeedbackLabel}
        dispatch={dispatch}
        tags={props.tags}
        setTags={props.setTags}
        hideTagModal={props.hideTagModal}
        optionsType={optionsType}
        setOptionsType={setOptionsType} 
        selectedType={selectedType} 
        setSelectedType={setSelectedType}    
    />
}

export default CreateTagController;