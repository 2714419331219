import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import allStyles from "./styles";
import { Formik } from "formik";
import { FC, useEffect } from "react";
import { ICreateTagModal } from "./indexModal";
import { setShowAlertFeedback, setShowTemplateTagModal } from "../../../../../../../store/internal";
import SelectComponentController from "../../../../../../homeApp/components/selectComponent/indexController";
import './styles.css';

const CreateTagModal: FC<ICreateTagModal> = (props) => {
    return (
        <>
          <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
              <Modal.Title style={allStyles.textBlack}>
                  Adicionar variável
              </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginLeft: "6%", marginRight: "4%", marginBottom: "2%" }}>
              <Container className="nopadding">
                <Formik
                    initialValues={{ name: '', label: '' }}
                    validate={values => {
                        var specialChars = /[^a-zA-Z0-9(-)(_)]/g;
                        let errors = {};

                        if (values.name.length === 0) {
                            errors['name'] = "Campo não pode estar vazio."; 
                            props.setShowFeedbackName(true);
                        } else if (values.name.includes(" ")) {
                            errors['name'] = "Nome de variável não pode conter espaço."; 
                            props.setShowFeedbackName(true);
                        } else if (values.name.match(specialChars)) {
                            errors['name'] = "Caracteres especiais não são aceitos."; 
                            props.setShowFeedbackName(true);
                        } else if (props.tags && props.tags.find((param) => param.name === values.name)) {
                          errors['name'] = "Nome do parâmetro já é usado."; 
                          props.setShowFeedbackName(true);
                        } else {
                            props.setShowFeedbackName(false);
                        }

                        /* if (values.label.length === 0) {
                            errors['label'] = "Campo não pode estar vazio."; 
                            props.setShowFeedbackLabel(true);
                        } else {
                            props.setShowFeedbackLabel(false);
                        } */

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        if (props.selectedType?.length === 0) {
                            props.dispatch(setShowAlertFeedback({ message: "Selecione o tipo da variável", visibility: true, signalIcon: false}));
                        } else {
                            props.setTags((tags) => {
                                let tag = { alias: values.name };
                                
                                if (props.selectedType?.length > 0)
                                    tag['tag'] = props.selectedType[0].value;
    
                                if (tags)
                                    tags.push(tag);
    
                                return tags;
                            });
                            
                            props.dispatch(setShowTemplateTagModal(false));   
                        }
                    }}    
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,                
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={false}>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="name">Nome<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={props.showFeedbackName}
                                            placeholder="Informe o nome do parâmetro"
                                            aria-label="Informe o nome do parâmetro"
                                            aria-describedby="Informe o nome do parâmetro"
                                            size='lg'
                                            type='name'
                                            id="name"
                                            name="name"
                                            maxLength={50}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackname">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="basic-url">Tipo da Variável</Form.Label>
                                    <SelectComponentController
                                        className={"multiselectForm mb-3 scrollInDropContent"}
                                        options={props.optionsType}
                                        setOptions={props.setOptionsType}
                                        selecteds={props.selectedType}
                                        setSelecteds={props.setSelectedType}
                                        singleSelect={true}
                                        isFormNoFilter={true}
                                        title={"Selecione o tipo da variável"}
                                        overrideStrings={
                                            {
                                            "allItemsAreSelected": "",
                                            "clearSearch": "Limpar pesquisa",
                                            "clearSelected": "Limpar tipos selecionados",
                                            "noOptions": "",
                                            "search": "Pesquisar tipos",
                                            "selectAll": "",
                                            "selectAllFiltered": "",
                                            "selectSomeItems": "Selecione o tipo da variável",
                                            "create": "",
                                            }
                                        }
                                        hasCreateItem={false}
                                        hasNullOption={true}
                                        // nullOptionLabel={"Sem número"}
                                        hasAllOption={false}
                                        // onSelect={selectChannel}

                                                // nullOptionLabel={"Não atribuído"}
                                                // allOptionLabel={"Todos os canais"}
                                    />
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                              <Col xs={6} className="d-flex justify-content-start">
                                <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={ () => props.hideTagModal() } >Cancelar</Button>
                              </Col>
                              <Col xs={6} className="d-flex justify-content-end">
                                <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                              </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
              </Container>
          </Modal.Body>
        </>
      );
}

export default CreateTagModal;