import React from 'react';
import { useSelector } from 'react-redux'

import { Row, Col, Form, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    getShowEditConsumersModal,
    getShowModalConsumersSimilarData,
} from '../../../../store/internal'

import constsApi from '../../../../services/constsApi';

import InfiniteScroll from 'react-infinite-scroll-component';

import Loading from '../../../../components/loading'
import ModalEditConsumersController from '../../components/modalEditConsumers/indexController';

import Utils from '../../../../core/shared/utils';
import ButtonOrderByList from '../../components/buttonOrderByList';
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { ListName, SortBy } from '../../../../core/enums/order-sort-by';

import ModalConsumersSimilarDataController from '../unificationConsumers/modalConsumersSimilarData/indexController';

const ListOfConsumers = ({
    t,
    props,
    deleteConsumers,
    exportConsumersCsv,
    AllCheckboxesClick,
    CheckboxesClick,
    viewConsumerPage,
    setCurrentConsumerInfo,
    currentConsumersData,
    setCurrentConsumerForSimilarData,
    setSimilarConsumers,
    similarConsumers,
    currentConsumerForSimilarData,
    isLoading,
    values,
    popover,
    deleteAllSelected,
    employeesData,
    tagConsumer,
    clickOpenEdit,
    setActionsClick,
    exportAllConsumers,
}) => {
    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <ModalEditConsumersController employeesData={employeesData} token={values.token.value} visibility={useSelector(getShowEditConsumersModal)} callback={props.callbackModalCustomers} data={currentConsumersData} setCurrentConsumer={setCurrentConsumerForSimilarData} setSimilarConsumers={setSimilarConsumers} />
            <ModalConsumersSimilarDataController originRedirectPage='consumer_page' visibility={useSelector(getShowModalConsumersSimilarData)} similarConsumers={similarConsumers} currentConsumer={currentConsumerForSimilarData} />
            <div style={{ marginTop: '2vh' }} className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                        <Row name="ListOfConsumersHead">
                            <Col md={1} style={{ width: '5%' }} >
                                {
                                    ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id)) &&
                                    <Form.Check
                                        type='checkbox'
                                        id='Allcheckboxes'
                                        onClick={(e) => AllCheckboxesClick(e)}
                                    />
                                }
                            </Col>
                            <Col md={2} className="nopadding">
                                <ButtonOrderByList title={t('home_consumers.list_of_consumers.name')} columnIdName={SortBy.name} callbackOrderBy={props.callbackOrderByConsumers} listName={ListName.ListOfConsumers} />
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>{t('home_consumers.list_of_consumers.email')}</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>{t('home_consumers.list_of_consumers.document')}</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>{t('home_consumers.list_of_consumers.phone')}</p>
                            </Col>
                            <Col md className="nopadding">
                                <p>{t('home_consumers.list_of_consumers.address')}</p>
                            </Col>
                            <Col className='d-flex justify-content-end '>
                                <p>{t('home_consumers.list_of_consumers.actions')}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    props.datas !== undefined && props.datas?.length > 0 ?
                        <>
                            <div id="ListOfConsumers" style={{ overflowY: 'auto', height: '50vh' }}>
                                <InfiniteScroll
                                    dataLength={props.datas.length}
                                    next={props.updateListfunction}
                                    hasMore={props.HasMoreInformation as boolean}
                                    loader={<h4> </h4>}
                                    scrollableTarget={"ListOfConsumers"}
                                    onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfConsumers) }}
                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow" >
                                            {props.datas.map((item, index) => {
                                                return (

                                                    <ListGroup.Item style={{ color: '#707070' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} key={"keyAll" + index.toString()} >
                                                        <Row className='TicketsRowHeight'>
                                                            <Col md={1} className='d-flex  align-self-center' style={{ width: '5%' }}>
                                                                {
                                                                    ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id)) &&
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        id={"checkboxesAll" + index}
                                                                        onClick={() => CheckboxesClick()}
                                                                        className="form-check-input-2"
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} onClick={tagConsumer ? () => clickOpenEdit(item?.user_id) : (e) => viewConsumerPage(e)}>
                                                                <div className="oneLineClampEllipsis">
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.name != null ?
                                                                                    <>{item.name} {item.name && item.lastname ? item.lastname : ""}</>
                                                                                    :
                                                                                    <>{t('home_consumers.list_of_consumers.na')}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.name ? item.name : <>N/A</>} {item.name && item.lastname ? item.lastname : ""}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} onClick={tagConsumer ? () => clickOpenEdit(item?.user_id) : (e) => viewConsumerPage(e)} >
                                                                <div className="oneLineClampEllipsis me-3">
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.email != null ?
                                                                                    <>{item.email}</>
                                                                                    :
                                                                                    <>{t('home_consumers.list_of_consumers.na')}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.email ? item.email : <>{t('home_consumers.list_of_consumers.na')}</>}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} onClick={tagConsumer ? () => clickOpenEdit(item?.user_id) : (e) => viewConsumerPage(e)}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.document_number != null ?
                                                                                    <>{item.document_number.length <= 11 ? item.document_number.slice(0, 3) + "." + item.document_number.slice(3, 6) + "." + item.document_number.slice(6, 9) + "-" + item.document_number.slice(9, 11) : item.document_number.slice(0, 2) + "." + item.document_number.slice(2, 5) + "." + item.document_number.slice(5, 8) + "/" + item.document_number.slice(8, 12) + '-' + item.document_number.slice(12, 14)}</>
                                                                                    :
                                                                                    <>{t('home_consumers.list_of_consumers.na')}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.document_number != null ?
                                                                                <>{item.document_number.length <= 11 ? item.document_number.slice(0, 3) + "." + item.document_number.slice(3, 6) + "." + item.document_number.slice(6, 9) + "-" + item.document_number.slice(9, 11) : item.document_number.slice(0, 2) + "." + item.document_number.slice(2, 5) + "." + item.document_number.slice(5, 8) + "/" + item.document_number.slice(8, 12) + '-' + item.document_number.slice(12, 14)}</>
                                                                                :
                                                                                <>{t('home_consumers.list_of_consumers.na')}</>
                                                                            }
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} onClick={tagConsumer ? () => clickOpenEdit(item?.user_id) : (e) => viewConsumerPage(e)}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.phone != null ?
                                                                                <>{formatPhoneNumberIntl("+" + item.phone)}</>
                                                                                :
                                                                                <>{t('home_consumers.list_of_consumers.na')}</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item.phone != null ?
                                                                            <>{formatPhoneNumberIntl("+" + item.phone)}</>
                                                                            :
                                                                            <>{t('home_consumers.list_of_consumers.na')}</>
                                                                        }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} onClick={tagConsumer ? () => clickOpenEdit(item?.user_id) : (e) => viewConsumerPage(e)}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.address != null ?
                                                                                    <>{item.address}</>
                                                                                    :
                                                                                    <>{t('home_consumers.list_of_consumers.na')}</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.address != null ?
                                                                                <>{item.address}</>
                                                                                :
                                                                                <>{t('home_consumers.list_of_consumers.na')}</>
                                                                            }
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col className='d-flex justify-content-end' >
                                                                {item.created_by_employee === 'false' &&
                                                                    <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                        <Button id={item?.name + " " + (item?.name && item?.lastname ? item.lastname : "") + "*ID*" + item?.user_id} onClick={(e) => { setCurrentConsumerInfo(e); setActionsClick(false) }}
                                                                            className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <circle cx="12" cy="12" r="1" />
                                                                                <circle cx="12" cy="19" r="1" />
                                                                                <circle cx="12" cy="5" r="1" />
                                                                            </svg>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                );

                                            })}
                                        </ListGroup>
                                    }
                                </InfiniteScroll>
                            </div>
                            <Row style={{ marginTop: '3vh', width: '100%' }} > {/* mostrar botão apenas quando tiver dados de consumidores */}
                                <Col md={3}>
                                    <Button style={{ display: 'block', height: '5.5vh', maxWidth: '20vw' }} id="buttonExportAllConsumers" className="buttonBlue" onClick={exportAllConsumers} >{t('home_consumers.list_of_consumers.export_all_consumers')}</Button>
                                </Col>
                            </Row>
                        </>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '50vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1} className="d-flex justify-content-center">
                                    <svg id="Grupo_11212" data-name="Grupo 11212" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9563" data-name="Caminho 9563" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_157" data-name="Elipse 157" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9564" data-name="Caminho 9564" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9565" data-name="Caminho 9565" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9566" data-name="Caminho 9566" d="M21,21V19a4,4,0,0,0-3-3.85" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '5%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('home_consumers.list_of_consumers.no_results')} <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateConsumer}>{t('home_consumers.list_of_consumers.add_consumer')}</Button></p>
                                </Col>
                            </Row>
                        </div>
                }

                <Row style={{ marginTop: '0vh', width: '100%' }} >
                    <Col md={3}>
                        <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="buttonDeleteAllSelected" className="buttonBlue" onClick={() => deleteAllSelected(deleteConsumers)} >{t('home_consumers.list_of_consumers.delete_selecteds')}</Button>
                    </Col>
                    <Col md={3}>
                        <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="buttonExportCSV" className="buttonBlue" onClick={exportConsumersCsv} >{t('home_consumers.list_of_consumers.export_csv')}</Button>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default ListOfConsumers;