import { FC, useEffect, useState } from "react";
import { ICreateIntegrationModalController } from "./indexModel";
import CreateIntegrationModal from ".";
import { useDispatch, useSelector } from "react-redux";
import { getShowCreateIntegrationModal, getShowCreateParamModal, setShowAlertFeedback, setShowCreateIntegrationModal, setShowCreateParamModal } from "../../../../../../store/internal";
import { Option } from "react-multi-select-component";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import UserService from "../../../../../../services/user-service";
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router";
import { getToken } from "../../../../../../store/token";
import { response } from "express";
import { useTranslation } from "react-i18next";

const CreateIntegrationModalController: FC<ICreateIntegrationModalController> = (props) => {
    const dispatch = useDispatch();
    const AppRequesterConst = new AppRequesterController();
    const navigate = useNavigate();

    const [active, setActive] = useState(true);
    const [activeMessageParam, setActiveMessageParam] = useState(false);

    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [showFeedbackUrl, setShowFeedbackUrl] = useState(false);
    const [showFeedbackInstructions, setShowFeedbackInstructions] = useState(false);
    const [showFeedbackToken, setShowFeedbackToken] = useState(false);

    const [optionsChatbots, setOptionsChatbots] = useState<Option[]>([]);
    const [selectedsChatbots, setSelectedsChatbots] = useState<Option[]>([]);

    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [instructions, setInstructions] = useState("");
    const [token, setToken] = useState("");
    
    const [isLoading, setIsLoading] = useState(false);

    const [integrationParams, setIntegrationParams] = useState([]);
    const [showParamModal, setShowParamModal] = useState(false);
    const [showIntegrationModal, setShowIntegrationModal] = useState(false);

    const { t } = useTranslation()

    const values: TokenInterface = {
        internal: {
            getShowCreateParamModal: useSelector(getShowCreateParamModal),
            getShowEditIntegrationModal: useSelector(getShowCreateIntegrationModal),
        },
        token: {
            value: useSelector(getToken)
        }
    }

    // fazendo troca dos modais
    useEffect(() => {
        if (values.internal.getShowCreateParamModal) {
            setShowParamModal(true);
        } else {
            setShowParamModal(false);
        }

        if (values.internal.getShowEditIntegrationModal) {
            setShowIntegrationModal(true);
        } else {
            setShowIntegrationModal(false);
        }

        if (!values.internal.getShowEditIntegrationModal && !values.internal.getShowCreateParamModal) {
            cleanFields();
        }
    }, [values.internal.getShowCreateParamModal, values.internal.getShowEditIntegrationModal]);

    useEffect(() => {
        if(values.internal.getShowEditIntegrationModal === true) {
            const controller = new AbortController();

            getAllChatbots(controller);
    
            return () => {
                controller.abort();
            }
        }
    }, [values.internal.getShowEditIntegrationModal]);

    const getAllChatbots = async (controller: AbortController) => {
        let signal = controller.signal;
        let params = {};

        const headers = UserService.getHeaders();
        const config = { headers, params, signal };

        await AppRequesterConst.Get(
            "/bot-cxpress?order_by=asc",
            config,
            (response: Object) => {},
            (data) => {
                if ((data.status === 200 || data.status === 204) && data.data.bot_cxpress.length > 0) {

                    setOptionsChatbots((bots) => {
                        let newBots = [];
                        data.data.bot_cxpress.map((bot) => {
                            if (bot.bot_enable === true && bot.channel_id === '33be81d8-88ac-4e08-bb4d-cf69fd23267f') {
                                newBots.push(Object.assign(bot, { value: bot.id, label: bot.bot_name }));
                            }
                        });
                        return newBots;
                    });
                }
            },
            (error: Object) => {
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const hideModal = () => {
        if (values.internal.getShowCreateParamModal)
            dispatch(setShowCreateParamModal(false));
        else 
            dispatch(setShowCreateIntegrationModal(false));
    }

    const validate = (values) => {
        let errors = {};

        if (!values.name) {
            errors["name"] = t('home_departments.integration_tab.create_integration.validate.empty_field');
            setShowFeedbackName(true);
        } if (values.name.length === 0) {
            errors['name'] = t('home_departments.integration_tab.create_integration.validate.name_length'); 
            setShowFeedbackName(true);
        } else {
            setShowFeedbackName(false);
        }

        if (!values.url) {
            errors['url'] = t('home_departments.integration_tab.create_integration.validate.empty_field');
            setShowFeedbackUrl(true);
        } else if (values.url.length === 0) {
            errors['url'] = t('home_departments.integration_tab.create_integration.validate.empty_field');
            setShowFeedbackUrl(true);
        } else if (values.url.includes(" ")) {
            errors['url'] = t('home_departments.integration_tab.create_integration.validate.url_space');
            setShowFeedbackUrl(true);
        } else {
            setShowFeedbackUrl(false);
        }

        if (!values.instructions) {
            errors["instructions"] = 'Preencha este campo';
            setShowFeedbackInstructions(true);
        } else {
            setShowFeedbackInstructions(false);
        }

        if (!values.token) {
            errors["token"] = 'Preencha este campo';
            setShowFeedbackToken(true);
        } else {
            setShowFeedbackToken(false);
        }

        setName(values.name);
        setUrl(values.url);
        setInstructions(values.instructions);
        setToken(values.token);

        return errors;
    }

    const cleanFields = () => {
        setIntegrationParams([]);
        setName("");
        setUrl("");
        setInstructions("");
        setToken("");
        setShowFeedbackName(false);
        setShowFeedbackUrl(false);
        setShowFeedbackInstructions(false);
        setShowFeedbackToken(false);
        setActive(true);
        setActiveMessageParam(false);
        setSelectedsChatbots([]);
    }

    const submit = async (valuesForm) => {
        if (selectedsChatbots?.length === 0) {
            dispatch(setShowAlertFeedback({ message: t('home_departments.integration_tab.create_integration.select_bot'), visibility: true, signalIcon: false }));
        } else {
            let JsonSend = {
                name: valuesForm.name,
                api_url: valuesForm.url,
                access_token: valuesForm.token,
                active: active,
                use_last_message: activeMessageParam,
                command_start_integration: valuesForm.instructions
            }

            let bots = selectedsChatbots.map((bot) => {
                return bot.value;
            });
            JsonSend['chatbots'] = bots;

            let params = integrationParams.map((param, index) => {
                return { name: param.name, sequence: index, message_of_param: param.information };
            });
            JsonSend['params'] = params;

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + values.token.value
            };
    
            await AppRequesterConst.Post(
                '/api-integration', JsonSend, { headers },
                (response: Object) => {
                    return response;
                },
                (data: { data: { employees: { user_id: string; }[]; }; }) => {
                    dispatch(setShowAlertFeedback({ message: t('home_departments.integration_tab.create_integration.integration_created'), visibility: true, signalIcon: true }));
                    props.callback();
                    hideModal();
                },
                (error: { response: { data: { message: { context: { label: string; }; }[]; }; status: number; }; }) => {
                    if (error.response.status === 400) {
                        dispatch(setShowAlertFeedback({ message: t('home_departments.integration_tab.create_integration.valid_value'), visibility: true, signalIcon: false }));
                    } else {
                        dispatch(setShowAlertFeedback({ message: t('home_departments.integration_tab.create_integration.unexpected_error'), visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, setIsLoading
            );
        }
    }

    return <CreateIntegrationModal 
        hide={hideModal}
        active={active}
        activeMessageParam={activeMessageParam}
        setActive={setActive}
        setActiveMessageParam={setActiveMessageParam}
        showFeedbackName={showFeedbackName}
        setShowFeedbackName={setShowFeedbackName}
        validate={validate}
        optionsChatbots={optionsChatbots}
        setOptionsChatbots={setOptionsChatbots}
        selectedsChatbots={selectedsChatbots}
        setSelectedsChatbots={setSelectedsChatbots}
        integrationParams={integrationParams}
        setIntegrationParams={setIntegrationParams}
        dispatch={dispatch}
        getShowCreateParamModal={showParamModal}
        getShowCreateIntegrationModal={showIntegrationModal} 
        showFeedbackUrl={showFeedbackUrl} 
        setShowFeedbackUrl={setShowFeedbackUrl}  
        showFeedbackToken={showFeedbackToken}
        setShowFeedbackToken={setShowFeedbackToken}
        submit={submit}  
        name={name}
        url={url}
        token={token}
        cleanFields={cleanFields}
        isLoading={isLoading}
        instructions={instructions}
        setInstructions={setInstructions}
        showFeedbackInstructions={showFeedbackInstructions}
        setShowFeedbackInstructions={setShowFeedbackInstructions}
        t={t}
    />
}   

export default CreateIntegrationModalController;