import { FC, useEffect, useState } from 'react'
import FormConfigDomain from '.'
import { IFormConfigDomainController } from './indexModel';
import { useDispatch, useSelector } from 'react-redux';
import { setShowRegisterDomainModal } from '../../../../store/internal';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../store/control_panel';
import { AppRequesterControlPanelController } from '../../../../services/appRequesterControlPanel/appRequesterControlPanelController';
import { useNavigate } from 'react-router-dom';

const FormConfigDomainController: FC<IFormConfigDomainController> = (props) => {
    const [domains, setDomains] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appRequesterConst = new AppRequesterControlPanelController();

    const [currentState, setCurrentState] = useState("");

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }, 
        /* control_panel: {
            getShowRegisterDomainModal: useSelector(getShowRegisterDomainModal)
        } */
    }

    useEffect(() => {
        const controller = new AbortController();

        setCurrentState((currentState) => {
            getAllDomains(controller);
            return "";
        });

        return () => {
            controller.abort();
        }
        
    }, [currentState]);

    const callbackUpdateList = () => {
        setCurrentState("updating");
    }

    const getAllDomains = async (controller: AbortController) => {
        let signal = controller.signal;

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + values.token.value,
        };

        await appRequesterConst.Get(
            `/channel-email/domains/${props.companyId}`, { headers, signal },
            (response: Object) => {return response;},
            (data) => {
                if ((data.status === 200 || data.status === 204) && data.data?.companyEmailDomain?.length > 0) {
                    setDomains(data.data.companyEmailDomain);
                } else {
                    setDomains([]);
                }
            },
            (error: { response: { status: number; data: { message: string; }; }; }) => {
                /* if (error.response.status === 400) {
                    // dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                } */
            }, navigate, dispatch, () => {}
        ).finally(() => {});
    }

    const handleOpenModal = () => {
        dispatch(setShowRegisterDomainModal(true));
    };

    return <FormConfigDomain
        handleOpenModal={handleOpenModal}
        data={props.data}
        updateListAfterDelete={props.updateListAfterDelete}
        cnpj={props.cnpj}
        domains={domains} 
        companyId={props.companyId}   
        callbackUpdateList={callbackUpdateList}
        isEditAllowed={props.isEditAllowed}
    />
}

export default FormConfigDomainController