import { Container, Row, Col, Button } from "react-bootstrap"
import allStyles from './styles'
import { StatusAgent } from "../../../../core/enums/status-agent"
import { IconPointFilled } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux"
import { getIdCompany } from "../../../../store/company"
import { getIdUser } from "../../../../store/user"
import useUpdateStatusEmployee from "../../../../hooks/useUpdateStatusEmployee"
import { setIdStatusEmployee } from "../../../../store/employee";
import { useTranslation } from "react-i18next";

const TemplateStatusAgent = (props) => {

    const { requestAgentStatus } = useUpdateStatusEmployee()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const values = {
        company: {
            id: useSelector(getIdCompany)
        },
        user: {
            id: useSelector(getIdUser),
        }
    };

    const handleStatus = (user_id, status_employee, company_id) => {
        requestAgentStatus(user_id, status_employee, company_id)
        dispatch(setIdStatusEmployee(status_employee))
        props.showPopover(false)
    }
     
    return ( 
        <Container className="d-flex justify-content-end nopadding">
            <div>
                <Row className="nopadding d-flex align-items-center">
                    <Col className="nopadding px-2" sm={12} md={12} style={allStyles.bodyPhoneDialer}>
                        <Row style={{ paddingLeft: "5px" }}>
                            <Button onClick={() => handleStatus(values.user.id, StatusAgent.Online, values.company.id)} style={{ backgroundColor: "transparent", border: "none", display: "flex", alignItems: "center" }}>
                                <span style={{ padding: "0px", margin: "0px" }}>
                                    <IconPointFilled stroke={1.5} color="#1e8e3e" className="svg-status-online" />
                                </span>
                                
                                <span style={{ color: "#242526", fontSize: "0.8rem", fontWeight: "bold" }}>
                                    {t("home_agents.list_agents.online")}
                                </span>
                            </Button>
                        </Row>
                        <Row style={{ paddingLeft: "5px" }}>
                            <Button onClick={() => handleStatus(values.user.id, StatusAgent.Ausente, values.company.id)} style={{ backgroundColor: "transparent", border: "none", display: "flex", alignItems: "center" }}>
                                <span style={{ padding: "0px", margin: "0px" }}>
                                    <IconPointFilled stroke={1.5} color="#fff200" className="svg-status-ausente" />
                                </span>
                                
                                <span style={{ color: "#242526", fontSize: "0.8rem", fontWeight: "bold" }}>
                                {t("home_agents.list_agents.away")}
                                </span>
                            </Button>
                        </Row>
                        <Row style={{ paddingLeft: "5px" }}>
                            <Button  onClick={() => handleStatus(values.user.id, StatusAgent.Offline, values.company.id)} style={{ backgroundColor: "transparent", border: "none", display: "flex", alignItems: "center" }}>
                                <span style={{ padding: "0px", margin: "0px" }}>
                                    <IconPointFilled stroke={1.5} color="#ed1c24" className="svg-status-offline" />
                                </span>
                                
                                <span style={{ color: "#242526", fontSize: "0.8rem", fontWeight: "bold" }}>
                                {t("home_agents.list_agents.offline")}
                                </span>
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default TemplateStatusAgent