import { Button, Col, Container, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import Loading from "../../../components/loading";
import { Formik } from "formik";
import { FC } from "react";
import { ForgotPasswordControlPanelInterface } from "./indexModel";

const ForgotPasswordControlPanel: FC<ForgotPasswordControlPanelInterface> = (props) => {
  return (
    <>
      <Container fluid className='nopadding newPasswordControlPanel'>
        {props.isLoading && <Loading visibility={props.isLoading} />}
        <Row className='nopadding'>
          <Col className='nopadding'>
            <Row className='nopadding align-items-center body-content'>
              <div className='d-flex justify-content-center'>
                <Col className='box-content' sm={3} md={3}>
                  <div className='d-flex align-items-center'>
                    <div className='validateLogo'>
                      <img src='/Logo.svg' alt='' />
                    </div>
                    <Col className='d-flex justify-content-end px-1 box-title'>
                      Esqueci a senha
                    </Col>
                  </div>
                  {
                    props.scene === 'email' &&
                    <Formik
                      initialValues={{ email: '' }}
                      validate={props.validateForgotPassword}
                      onSubmit={props.submitForgotPassword}
                    >
                      {formik => (
                        <Form className='forgotForm'
                          onSubmit={formik.handleSubmit}
                          noValidate
                          validated={props.validated}
                        >
                          <Row className='d-flex justify-content-center'>
                            <Row className="px-1 mt-3 nopadding">
                              <span>Informe o e-mail que você deseja receber instruções sobre como recuperar a senha</span>
                            </Row>
                            <Row className='form-item mt-4'>
                              <InputGroup className='mb-3 px-1'>
                                <FormControl
                                  className='form-control-Default'
                                  isInvalid={props.showFeedbackEmail}
                                  placeholder='Informe seu e-mail'
                                  aria-label='Informe seu e-mail'
                                  aria-describedby='Informe seu e-mail'
                                  size='lg'
                                  type='email'
                                  id='email'
                                  name='email'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                ></FormControl>
                                <Form.Control.Feedback type='invalid' id='feedbackEmail'>
                                  {formik.errors.email}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Row>
                            <Row className='mt-3'>
                              <div className='px-1 actionButtons'>
                                <Button type='submit' className='buttonBlue px-1' disabled={!props.validated}>Verificar e-mail</Button>
                                <Button className='buttonFirstAccess' onClick={() => props.goToLogin()}>Voltar para o login</Button>
                              </div>
                            </Row>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  }
                  {
                    props.scene === 'send-success' &&
                    <>
                      <div className='confirmed'>
                        <span className="title mt-2 mb-3">Instruções enviadas!</span>
                        <p className="description">
                          Você receberá um e-mail com instruções de como recuperar sua senha
                        </p>
                        <Button className='buttonFirstAccess' onClick={() => props.goToLogin()}>Voltar para o login</Button>
                      </div>
                    </>
                  }
                </Col>
              </div>
            </Row>
            <Row className='nopadding footer-content'>
              <Col className='d-flex justify-content-center align-items-center pt-3'>
                <p className='footer-text'>
                  <span>Esta é uma área exclusiva e de acesso restrito. </span>
                  <span>2022 Cxpress. Todos os direitos reservados</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPasswordControlPanel;
