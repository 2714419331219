import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../../../../store/token'
import { getIdCompany } from '../../../../store/company'
import { getIdEmployee, setHasPhoneIntegrationEmployee } from '../../../../store/employee'
import { getIdUser, getProfileId } from '../../../../store/user'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    setShowCreateEmailModal,
    setShowConfirmationModal,
    getShowConfirmationModal,
    setShowAlertFeedback,
    getShowAcquirePlatformComponent,
    getShowCreateEmailModal,
    getShowRequestTelefoneModal,
    setShowRequestTelefoneModal,
    setShowEditPhoneModal,
    getShowEditPhoneModal
} from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import BackendConstants from '../../../../core/constants/backend-constants';
import UserService from '../../../../services/user-service';
import { 
    DataChannelListPagesByUserInterface, 
    DataChannelVozxpressExtensionsInterface, 
    DataChannelWebchatLoadKeysCompanyIdInterface, 
    DataChannelWhatsappNumbersInterface, 
    DataCompanyEmailInterface, 
    DataSectorActivesInterface 
} from '../../../../services/requestsInterfacesModel';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import HomeChannels from '.';
import { useTranslation } from 'react-i18next';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import constsApi from '../../../../services/constsApi';

const AppRequesterConst = new AppRequesterController();

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const HomeConfigurationsChannelsController = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [aux, setAux] = useState([]);
    const [auxFb, setAuxFb] = useState([]);
    const [auxIg, setAuxIg] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageFacebook, setCurrentPageFacebook] = useState(1);
    const [currentPageInstagram, setCurrentPageInstagram] = useState(1);
    const [currentHasMoreInformation, setCurrentHasMoreInformation] = useState(true);
    const [currentParamsOrderBy, setCurrentParamsOrderBy] = useState({});
    const [currentParamsOrderByFacebook, setCurrentParamsOrderByFacebook] = useState({});
    const [currentLimit, setCurrentLimit] = useState(BackendConstants.limitMin);
    const [currentState, setCurrentState] = useState("");
    const [currentStateFacebook, setCurrentStateFacebook] = useState("");
    const [currentStateInstagram, setCurrentStateInstagram] = useState("");
    const [currentEmails, setCurrentEmails] = useState(undefined);
    const [departmentsTuples, setDepartmentsTuples] = useState(null);
    const [listPhone, setListPhone] = useState([]);
    const [listOfPagesFacebook, setListOfPagesFacebook] = useState([]);
    const [listOfPagesInstagram, setListOfPagesInstagram] = useState([]);
    const [listWhatsapp, setListWhatsapp] = useState([]);
    const [listWebchat, setListWebchat] = useState([]);
    const [hasWhatsapp, setHasWhatsapp] = useState(false);
    const [hasPhone, setHasPhone] = useState(false);
    const [hasFacebook, setHasFacebook] = useState(false);
    const [hasInstagram, setHasInstagram] = useState(false);
    const [hasWebchat, setHasWebchat] = useState(false);
    const [emailConfirmation, setEmailConfirmation] = useState(false);
    const [showName, setShowName] = useState(false);
    const [company_channels, setCompany_channels] = useState([]);
    const [whatsapp_channel, setWhatsapp_channel] = useState(false);
    const [instagram_channel, setInstagram_channel] = useState(false);
    const [phone_channel, setPhone_channel] = useState(false);
    const [facebook_channel, setFacebook_channel] = useState(false);
    const [webchat_channel, setWebchat_channel] = useState(false);
    const [email_channel, setEmail_channel] = useState(false);


    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getShowAcquirePlatformComponent: useSelector(getShowAcquirePlatformComponent).visibility,
            getShowCreateEmailModal: useSelector(getShowCreateEmailModal),
            getShowEditPhoneModal: useSelector(getShowEditPhoneModal),
        }
    };

    const isAdmin = () => values?.user.profile_id === constsApi.profile[0].id;
    const isAgent = () => values?.user.profile_id === constsApi.profile[1].id;
    const isOwner = () => values?.user.profile_id === constsApi.profile[2].id;
    const isMaster = () => values?.user.profile_id === constsApi.profile[3].id;

    const disabledAcquirePlatform = useSelector(getShowAcquirePlatformComponent).visibility;

    const [initialTab] = useState((isAdmin() || isOwner() || isMaster()) ? "general" : "telefone");

    useEffect(() => {
        if (values.internal.getShowCreateEmailModal) {
            dispatch(setShowCreateEmailModal(false));
        }

        if (values.internal.getShowEditPhoneModal) {
            dispatch(setShowEditPhoneModal(false));
        }
    }, []);

    useEffect(() => {
        channels_list()
    }, [company_channels]);
    
    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value === null) {
        } else {
            setCurrentLimit((limit) => {
                setCurrentPageFacebook((page) => {
                    getListOfPagesFacebook(Object.assign(currentParamsOrderByFacebook, { limit: currentLimit, page: currentPageFacebook }), controller);
                    return page;
                });
                return limit;
            });
        }
        return () => {
            controller.abort();
        }
    }, [currentPageFacebook, currentStateFacebook]);

    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value === null) {
        } else {
            setCurrentLimit((limit) => {
                setCurrentPageInstagram((page) => {
                    getListOfPagesInstagram(Object.assign(currentParamsOrderByFacebook, { limit: currentLimit, page: currentPageInstagram }), controller);
                    return page;
                });
                return limit;
            });
        }
        return () => {
            controller.abort();
        }
    }, [currentPageInstagram, currentStateInstagram]);

    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value === null) {
        } else {
            setCurrentLimit((limit) => {
                setCurrentPage((page) => {
                    getDepartments(controller);
                    getListWhatsapp(controller);
                    getListPhones(controller);
                    getInformations(Object.assign(currentParamsOrderBy, { limit: currentLimit, page: currentPage }), controller);
                    getListOfWebChat(controller);
                    getEmailConfirmation(controller);
                    return page;
                });
                return limit;
            });
        }
        return () => {
            controller.abort();
        }
    }, [currentPage, currentState]);

    const getDepartments = async (controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/sector/actives', { headers, signal, params: { companyid: values.company.id } },
            (response: Object) => { },
            (data: DataSectorActivesInterface) => {
                if (data.status === 200 && data && data.data && data.data.sectors) {
                    let departmentsTuples = [];
                    let allDepartments = data.data.sectors;

                    allDepartments.map(element => {
                        departmentsTuples.push({ 'label': element.name, 'value': element.id });
                    });
                    setDepartmentsTuples(departmentsTuples);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getListWhatsapp = async (controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller?.signal;

        await AppRequesterConst.Get(
            '/channel-whatsapp/whatsapp-numbers', { headers, signal },
            (response: Object) => { },
            (data: DataChannelWhatsappNumbersInterface) => {
                if (data.status === 200 && data.data.whatsapp_numbers.length !== 0) {
                    setHasWhatsapp(true);
                }
                setListWhatsapp(data.data.whatsapp_numbers);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getListPhones = async (controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/channel-vozxpress/extensions', { headers, signal },
            (response: Object) => { },
            (data: DataChannelVozxpressExtensionsInterface) => {
                if (data.status === 200 && data.data.is_active === true) {
                    setHasPhone(true);
                    dispatch(setHasPhoneIntegrationEmployee(true));
                }
                setListPhone(data.data.extentions);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getListEmails = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/company/email', { headers, signal, params: params },
            (response: Object) => { },
            (data: DataCompanyEmailInterface) => {
                if (data.status === 200) {
                    setCurrentHasMoreInformation(true);
                    setAux((state) => {
                        let aux = state.concat(data.data.emails);
                        setAux(aux);
                        setCurrentEmails(aux);
                        return state;
                    });
                } else if (aux.length === 0) {
                    setCurrentEmails(undefined);
                }

                setIsLoading(false);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getListOfPagesFacebook = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/channel-facebook/list-pages-by-user', { headers, signal, params: params },
            (response: Object) => { },
            (data: DataChannelListPagesByUserInterface) => {
                if (data.status === 200 && data.data?.keys?.length !== 0) {
                    setHasFacebook(true);

                    setAuxFb((state) => {
                        let aux = state.concat(data.data.keys);
                        setAuxFb(aux);
                        setListOfPagesFacebook(aux);
                        return state;
                    });
                    // setListOfPagesFacebook(data.data.keys);
                    // console.log(params, data.data.keys.length);
                    /* setListOfPagesFacebook ((state) => {
                        return state.concat(data.data.keys);
                    }); */
                } else if (auxFb.length === 0) {
                    setListOfPagesFacebook([]);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getListOfPagesInstagram = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/channel-instagram/list-pages-by-user', { headers, signal, params: params },
            (response: Object) => { },
            (data: DataChannelListPagesByUserInterface) => {
                if (data.status === 200 && data.data?.keys?.length !== 0) {
                    setHasInstagram(true);

                    setAuxIg((state) => {
                        let aux = state.concat(data.data.keys);
                        setAuxIg(aux);
                        setListOfPagesInstagram(aux);
                        return state;
                    });
                    // setListOfPagesInstagram(data.data.keys);
                    /* console.log(params, data.data.keys.length);

                    setListOfPagesInstagram((state) => {
                        state.concat(data.data.keys);
                        console.log(state, data.data.keys);
                        return state;
                    }); */
                } else if (auxIg.length === 0) {
                    setListOfPagesInstagram([]);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getListOfWebChat = async (controller: AbortController) => {
        // const tokenBasicAuth = process.env.REACT_APP_TOKEN_BASIC_AUTH

        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/channel-webchat/webchat-load-keys/' + values.company.id, { headers, signal },
            (response: Object) => { },
            (data: DataChannelWebchatLoadKeysCompanyIdInterface) => {
                if (data.status === 200 && data.data[0].id !== null) {
                    setHasWebchat(true);
                    setListWebchat(data.data);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const requestChannel = async (currentChannel) => {
        const JsonSend = {
            "channel": currentChannel
        };

        const headers = UserService.getHeaders();

        await AppRequesterConst.Post(
            '/channel/send-request', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_channels.requested_contact'), visibility: true, signalIcon: true }));
                // props.callback();
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                if (error.response !== undefined) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    // if (error.response.status === 400) {
                    //     //setShowfeedback(true);
                    // }
                    // if (error.response.status === 401) {
                    //     //setShowfeedbackPassword(true);
                    // }
                }
            }, navigate, dispatch, setIsLoading
        );
    }

    const getEmailConfirmation = async (controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal

        await AppRequesterConst.Get(
            '/company', { headers, signal },
            (response: Object) => { },
            (data) => {
                if (data.status == 200) {
                    if (data.data.companies[0].is_enabled_ticket_confirmation === null || data.data.companies[0].is_enabled_ticket_confirmation === false) {
                        setEmailConfirmation(false)
                    } else {
                        setEmailConfirmation(true);
                    }
                    setShowName(data?.data?.companies[0]?.show_name_in_messages)
                    setCompany_channels(data?.data?.companies[0]?.channels)
                    
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
            );
    }

    const changeEmailConfirmation = async (value: boolean) => {
        const headers = UserService.getHeaders()
        let JsonSend = {
            "is_enabled_ticket_confirmation": value,
            "id": values.company.id
        }

        await AppRequesterConst.Put(
            '/company', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                if (value === false) {
                    setEmailConfirmation(false)
                    dispatch(setShowAlertFeedback({ message: t('home_channels.email_confirmation_disabled'), visibility: true, signalIcon: true }));
                }
                else {
                    setEmailConfirmation(true)
                    dispatch(setShowAlertFeedback({ message: t('home_channels.email_confirmation_enabled'), visibility: true, signalIcon: true }));
                }
            },
            (error: { response: { status: number; }; }) => {
                dispatch(setShowAlertFeedback({ message: t('errors.TicketErrorMessage'), visibility: true, signalIcon: false }));
            }, navigate, dispatch, setIsLoading
        );
    }

    const getInformations = (params: { limit: number; page: number; }, controller: AbortController) => {
        getListEmails(params, controller);
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
    }

    const updateListfunctionFacebook = () => {
        setCurrentPageFacebook(currentPageFacebook + 1);
    }

    const updateListfunctionInstagram = () => {
        setCurrentPageInstagram(currentPageInstagram + 1);
    }

    const updateListAfterEdit = () => {
        setAux([]);
        setCurrentPage(1);

        setCurrentState("deactivating");

        if (currentState === "deactivating") {
            setCurrentState("");
        }
    }

    const updateListAfterEditFacebook = () => {
        setAuxFb([]);
        setCurrentPageFacebook(1);

        setCurrentStateFacebook("deactivating");

        if (currentStateFacebook === "deactivating") {
            setCurrentStateFacebook("");
        }
    }

    const updateListAfterEditInstagram = () => {
        setAuxIg([]);
        setCurrentPageInstagram(1);

        setCurrentStateInstagram("deactivating");

        if (currentStateInstagram === "deactivating") {
            setCurrentStateInstagram("");
        }
    }

    const callbackModalEmails = () => {
        setCurrentPage(1);
        setAux([]);

        const controller = new AbortController();
        getListEmails(Object.assign(currentParamsOrderBy, { limit: currentLimit, page: currentPage }), controller);
    }

    const showCreateEmail = () => {
        setCurrentPage(1);
        // setAux([]);
        dispatch(setShowCreateEmailModal(true));
    }

    // showCreateFacebook(setAux: Function, setCurrentPage: Function, dispatch: Function, setShowCreateEmailModal: Function, loginSocialFacebook: Function) {
    //     setCurrentPage(1);
    //     setAux([]);
    //     dispatch(setShowCreateEmailModal(true))
    //     // dispatch(loginSocialFacebook())
    // }

    const showRequestWhatsapp = (currentChannel: String) => {
        // currentChannel = "33be81d8-88ac-4e08-bb4d-cf69fd23267f"
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_channels.request_whatsapp_integration'), "body": t('home_channels.request_integration_body'), "id": t('home_channels.request_integration_id'), buttonConfirmationText: t('home_channels.request_integration_button'), buttonReturnText: t('home_channels.request_integration_cancel') }, functionConfirmation: () => requestChannel(currentChannel) }));
    }
    const showRequestTelefone = (currentChannel: String) => {
        // currentChannel = "6977996d-11d1-44cc-a863-2e1d9c24e046"
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_channels.request_phone_integration'), "body": t('home_channels.request_integration_body'), "id": t('home_channels.request_integration_id'), buttonConfirmationText: t('home_channels.request_integration_button'), buttonReturnText: t('home_channels.request_integration_cancel') }, functionConfirmation: () => requestChannel(currentChannel) }));
    }

    const showRequestFacebook = (currentChannel: String) => {
        // currentChannel = "aa1cea93-23de-46aa-af14-d6f766acf5c8"
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_channels.request_facebook_integration'), "body": t('home_channels.request_integration_body'), "id": t('home_channels.request_integration_id'), buttonConfirmationText: t('home_channels.request_integration_button'), buttonReturnText: t('home_channels.request_integration_cancel') }, functionConfirmation: () => requestChannel(currentChannel) }))
    }

    const showRequestInstagram = (currentChannel: String) => {
        // currentChannel = "f28cb4a4-e42b-425a-8011-59d5f9c1d0f2"
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_channels.request_instagram_integration'), "body": t('home_channels.request_integration_body'), "id": t('home_channels.request_integration_id'), buttonConfirmationText: t('home_channels.request_integration_button'), buttonReturnText: t('home_channels.request_integration_cancel') }, functionConfirmation: () => requestChannel(currentChannel) }))
    }

    const showRequestWebChat = (currentChannel: String) => {
        // currentChannel = "4a7b71bb-6432-4794-ad11-f978b067871d"
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_channels.request_webchat_integration'), "body": t('home_channels.request_integration_body'), "id": t('home_channels.request_integration_id'), buttonConfirmationText: t('home_channels.request_integration_button'), buttonReturnText: t('home_channels.request_integration_cancel') }, functionConfirmation: () => requestChannel(currentChannel) }))
    }

    const callbackOrderBy = (column: any, order: any) => {
        setCurrentPage(1);
        setCurrentPageFacebook(1);
        setCurrentPageInstagram(1);
        setAux([]);
        setAuxFb([]);
        setAuxIg([]);
        const auxObj = {};

        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order.toString();
        }

        setCurrentState(column + order.toString());

        if(instagram_channel)
            setCurrentStateFacebook(column + order.toString());

        if(facebook_channel)
            setCurrentStateInstagram(column + order.toString());

        if (column === 'is_active')
            setCurrentParamsOrderByFacebook(auxObj);
        else
            setCurrentParamsOrderBy(auxObj);

        if (document.getElementById("listOfEmails"))
            document.getElementById("listOfEmails").scrollTop = 0;
        if (document.getElementById("listOfFacebooks"))
            document.getElementById("listOfFacebooks").scrollTop = 0;
    }

    const CheckboxClick = () => {
        if ((document.getElementById("confirmacao") as HTMLInputElement).checked === true) {
            changeEmailConfirmation(true);
        } else {
            changeEmailConfirmation(false);
        }
    }

    const copyToClipboard = (e) => {
        var copyText = e
        navigator.clipboard.writeText(copyText)
        dispatch(setShowAlertFeedback({ message: t('home_channels.clipboard_copy'), visibility: true, signalIcon: true }));
    }

    const channels_list = () => {
        company_channels.map((item,index) => {
            switch(item.id){
                case "6977996d-11d1-44cc-a863-2e1d9c24e046":
                    setPhone_channel(true);
                    break;
                case "33be81d8-88ac-4e08-bb4d-cf69fd23267f":
                    setWhatsapp_channel(true);
                    break;
                case "aa1cea93-23de-46aa-af14-d6f766acf5c8":
                    setFacebook_channel(true);
                    break;
                case "f28cb4a4-e42b-425a-8011-59d5f9c1d0f2":
                    setInstagram_channel(true);
                    break;
                case "4a7b71bb-6432-4794-ad11-f978b067871d":
                    setWebchat_channel(true);
                    break;
                case "3f3fa530-fd3e-4018-ab98-4d9c3cd5406a":
                    setEmail_channel(true);
                    break;
                default:
                    break;
            }
        })
    }

    return <HomeChannels
        t={t}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        values={values}
        callbackModalEmails={callbackModalEmails}
        departmentsTuples={departmentsTuples}
        showCreateEmail={showCreateEmail}
        changeEmailConfirmation={changeEmailConfirmation}
        emailConfirmation={emailConfirmation}
        currentEmails={currentEmails}
        updateListfunction={updateListfunction}
        updateListfunctionFacebook={updateListfunctionFacebook}
        updateListfunctionInstagram={updateListfunctionInstagram}
        currentHasMoreInformation={currentHasMoreInformation}
        updateListAfterEdit={updateListAfterEdit}
        updateListAfterEditFacebook={updateListAfterEditFacebook}
        updateListAfterEditInstagram={updateListAfterEditInstagram}
        callbackOrderBy={callbackOrderBy}
        hasWhatsapp={hasWhatsapp}
        showRequestWhatsapp={showRequestWhatsapp}
        listWhatsapp={listWhatsapp}
        hasFacebook={hasFacebook}
        showRequestFacebook={showRequestFacebook}
        listOfPagesFacebook={listOfPagesFacebook}
        hasInstagram={hasInstagram}
        showRequestInstagram={showRequestInstagram}
        listOfPagesInstagram={listOfPagesInstagram}
        hasWebchat={hasWebchat}
        showRequestWebChat={showRequestWebChat}
        listWebchat={listWebchat}
        copyToClipboard={copyToClipboard}
        hasPhone={hasPhone}
        showRequestTelefone={showRequestTelefone}
        listPhone={listPhone}
        showName={showName}
        setShowName={setShowName}
        disabledAcquirePlatform={disabledAcquirePlatform}
        isOwner={isOwner}
        isAdmin={isAdmin}
        isMaster={isMaster}
        isAgent={isAgent}
        initialTab={initialTab}
        getListWhatsapp={getListWhatsapp}
        company_channels={company_channels}
        whatsapp_channel={whatsapp_channel}
        instagram_channel={instagram_channel}
        phone_channel={phone_channel}
        facebook_channel={facebook_channel}
        email_channel={email_channel}
        webchat_channel={webchat_channel}
    />
}

export default HomeConfigurationsChannelsController;