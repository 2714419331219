import { Button, Col, Modal, Row } from "react-bootstrap"
import ModalRegisterTagsTicketsController from "../modalRegisterTagsTickets/indexController";
import { useSelector } from "react-redux";
import { getShowRegisterTagsModal, setShowRegisterTagsModal } from "../../../../store/internal";

const ModalManageTickets = ({ show, hide, t, deleteAllSelected, resolveAllSelected, removeAllAssigned, deleteListTickets, setDeleteListTickets, dispatch, callback, addAllTags }) => {

    return (
        <>
        <ModalRegisterTagsTicketsController visibility={useSelector(getShowRegisterTagsModal)} deleteListTickets={deleteListTickets} setDeleteListTickets={setDeleteListTickets} callback={callback}/>
        <Modal show={show} onHide={hide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t("home_tickets.list_tickets.manage_tickets")}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col>
                        <Button style={{ height: '5.5vh', maxWidth: '30vw' }} id="buttonAddTags" className="buttonBlue" onClick={() => {
                            addAllTags();
                            hide();
                        }} >
                            {t("home_tickets.list_tickets.add_tags")}
                        </Button>
                    </Col >
                </Row>
                <Row style={{ marginTop: '2vh' }} >
                    <Col>
                        <Button style={{ height: '5.5vh', maxWidth: '30vw' }} id="buttonSetResolved" className="buttonBlue" onClick={() => {
                            resolveAllSelected();
                            hide();
                        }} >
                            {t("home_tickets.list_tickets.set_as_resolved")}
                        </Button>
                    </Col >
                </Row>
                <Row style={{ marginTop: '2vh' }} >
                    <Col>
                        <Button style={{ height: '5.5vh', maxWidth: '30vw' }} id="buttonDeleteAllSelected" className="buttonBlue" onClick={() => {
                            deleteAllSelected();
                            hide();
                        }} >
                            {t("home_tickets.list_tickets.delete_selecteds")}
                        </Button>
                    </Col >
                </Row>
                <Row style={{ marginTop: '2vh' }} >
                    <Col>
                        <Button style={{ height: '5.5vh', maxWidth: '30vw' }} id="buttonRemoveAssigned" className="buttonBlue" onClick={() => {
                            removeAllAssigned();
                            hide();
                        }} >
                            {t("home_tickets.list_tickets.remove_assigned_agent")}
                        </Button>
                    </Col >
                </Row>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default ModalManageTickets