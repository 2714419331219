import React from 'react';
import { Row, Col, Button, Modal, Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import allStyles from './styles';
import { Formik } from 'formik';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import UserService from '../../../../services/user-service';
import TagsComponent from '../tagsComponent';
import SelectComponentController from '../selectComponent/indexController';


const ModalRegisterTagsTickets = ({
    t,
    modalShow,
    Hide,
    AppRequesterConst,
    dispatch,
    setShowAlertFeedback,
    callback,
    navigate,
    setIsLoading,
    tagsOptions,
    setTagsOptions,
    currentTags,
    setCurrentTags,
    deleteListTickets,
}) => {

    return (
        <Modal show={modalShow && tagsOptions.length > 1} onHide={() => Hide()} aria-labelledby="" centered={true}>
            <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
                <Modal.Title style={allStyles.textBlack}>
                    {t('home_tickets.list_tickets.modalRegisterTagsTickets.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
                <Container>
                    <Formik
                        initialValues={{ }}
                        onSubmit={async () => {
                            let JsonSend = {
                                tags_id: currentTags ? currentTags.map((item) => item.value) : [],
                                tickets_id: deleteListTickets ? deleteListTickets : []
                            }

                            const headers = UserService.getHeaders()

                            await AppRequesterConst.Put(
                                '/ticket/tags', JsonSend, { headers },
                                (response: Object) => { return response; },
                                (data: Object) => {
                                    dispatch(setShowAlertFeedback({ message: `${t('home_tickets.list_tickets.modalRegisterTagsTickets.submit_success')}`, visibility: true, signalIcon: true }));
                                    callback();
                                    Hide();
                                },
                                (error: { response: { status: number, data: { code_cxpress: number } }; }) => {
                                    if (error.response !== undefined) {
                                        if (error.response.status === 400) {
                                            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                                        }
                                        else {
                                            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                                        }
                                    }
                                }, navigate, dispatch, setIsLoading
                            );
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                                    <Row className="mx-1 mb-1 mt-1">
                                        <Col>
                                            {t('home_tickets.list_tickets.modalRegisterTagsTickets.tags')}
                                        </Col>
                                    </Row>
                                    <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                        <Col sm={true}>
                                            <SelectComponentController
                                                className={"multiselectForm mb-3 w-100"}
                                                options={tagsOptions}
                                                setOptions={setTagsOptions}
                                                selecteds={currentTags}
                                                setSelecteds={setCurrentTags}
                                                singleSelect={false}
                                                isFormNoFilter={true}
                                                title={t('home_tickets.list_tickets.modalRegisterTagsTickets.tags')}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.allItemsAreSelected"),
                                                        "clearSearch": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.clearSearch"),
                                                        "clearSelected": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.clearSelected"),
                                                        "noOptions": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.noOptions"),
                                                        "search": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.search"),
                                                        "selectAll": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.selectAll"),
                                                        "selectAllFiltered": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.selectAllFiltered"),
                                                        "selectSomeItems": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.selectSomeItems"),
                                                        "create": t("home_tickets.list_tickets.modalRegisterTagsTickets.override_strings.create"),
                                                    }
                                                }
                                                hasCreateItem={false}
                                                hasNullOption={true}
                                                nullOptionLabel={t("home_tickets.list_tickets.modalRegisterTagsTickets.null_option")}
                                                hasAllOption={true}
                                            />
                                            </Col>
                                    </Row>
                                </Row>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center mt-4">
                                    <Col className="d-flex justify-content-center">
                                        <Button size='lg' className="buttonWhite" style={{ width: '70%' }} onClick={() => Hide()} >{t('home_tickets.list_tickets.modalRegisterTagsTickets.cancel')}</Button>
                                    </Col>
                                    <Col className="d-flex justify-content-center" >
                                        <Button type='submit' size='lg' className="buttonBlue" disabled={currentTags?.length === 0 ? true : false} style={{ width: '70%' }}>{t('home_tickets.list_tickets.modalRegisterTagsTickets.add')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ModalRegisterTagsTickets;