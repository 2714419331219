import React, { FC } from 'react';

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';

import { IModalEditFacebook } from './indexModal';
import SelectComponentController from '../../../../components/selectComponent/indexController';

const ModalFacebook: FC<IModalEditFacebook> = (props) => {
  return (
    <Modal show={props.modalShow} onHide={() => props.hide()} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          { props.t("home_channels.lists_channels.list_facebook.modal_edit_info.title", { channel: props.isFacebookPage? "Facebook": "Instagram" }) }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{
              pageName: props.data?.pageName? props.data.pageName: ""
            }}
            validate={props.validate}
            onSubmit={props.onSubmit}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="email">{props.t("home_channels.lists_channels.list_facebook.modal_edit_info.page_name")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={props.showFeedbackPageName}
                        placeholder={props.t("home_channels.lists_channels.list_facebook.modal_edit_info.page_name")}
                        aria-label={props.t("home_channels.lists_channels.list_facebook.modal_edit_info.page_name")}
                        aria-describedby={props.t("home_channels.lists_channels.list_facebook.modal_edit_info.page_name")}
                        size='lg'
                        type='text'
                        id="pagemName"
                        name="pageName"
                        // autoComplete='email'
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pageName}
                        // disabled={true}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackPageName">
                        { errors.pageName }
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{ props.t("home_channels.lists_channels.list_facebook.modal_edit_info.sector_label") }{/*<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>*/}</Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      className={"multiselectForm mb-3"}
                      options={props.optionsDepartments}
                      setOptions={props.setOptionsDepartments}
                      selecteds={props.selectedDepartments}
                      setSelecteds={props.setSelectedDepartments}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={props.t("home_channels.lists_channels.list_facebook.modal_edit_info.empty_sector")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.selected_sector"),
                          "clearSearch": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.clear_search_sector"),
                          "clearSelected": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.clear_selected_sector"),
                          "noOptions": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.no_options_sector"),
                          "search": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.search_sector"),
                          "selectAll": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.select_all_sector"),
                          "selectAllFiltered": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.select_all_filtered_sector"),
                          "selectSomeItems": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.select_some_itens_sector"),
                          "create": props.t("home_channels.lists_channels.list_facebook.modal_edit_info.create_sector"),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={true}
                      hasAllOption={false}
                    />
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center mt-3">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => props.hide()} >{ props.t("home_channels.lists_channels.list_facebook.modal_edit_info.btn_exit") }</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{ props.t("home_channels.lists_channels.list_facebook.modal_edit_info.btn_save") }</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalFacebook;
