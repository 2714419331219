import { FC, useState } from "react";
import { IInputColorPickerController } from "./indexModel";
import InputColorPicker from ".";

const InputColorPickerController: FC<IInputColorPickerController> = (props) => {
    const [show, setShow] = useState(false);

    // manter as duas funções com focus no popover para evitar um defeito
    // click fora 
    const onTogglePopover = (nextShow) => { 
      if (nextShow === false)
        setShow(nextShow); 
    }

    // click no botão
    const onToggleButtonClick = () => {
      setShow((showPop) => {
        return !showPop;
      });
    }

    return (
        <InputColorPicker 
        onTogglePopover={onTogglePopover}
        onToggleButtonClick={onToggleButtonClick}
        show={show}
        color={props.color}
        setColor={props.setColor} 
      />
    );
}

export default InputColorPickerController;