import { FC } from "react";
import { Modal, Container, Button, Col, Row } from "react-bootstrap";
import Loading from "../../../../../components/loading";
import { ModalInvalidApp2FAInterface } from "./indexModel";

import './styles.scss';

const ModalInvalidApp2FA: FC<ModalInvalidApp2FAInterface> = (props) => {
  return (
    <Modal className="modalInvalidApp2FA" show={props.showModal} onHide={() => props.hide()} aria-labelledby="" centered={true}>
      { props.isLoading && <Loading visibility={props.isLoading} noBootstrap={true} customStyle={{ position: 'absolute' }} /> }
      <Modal.Header style={{ padding: '1rem 1rem 0rem 1rem' }} closeButton>
        <span>Invalidar aplicativo multifator</span>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {
            !props.currentUser?.is_app_authenticated &&
            <span className="modalInfo">
              A conta <strong>{props.currentUser?.email}</strong> não possui aplicativo multifator configurado.
            </span>
          }
          {
            props.currentUser?.is_app_authenticated &&
            <span className="modalInfo">
              Deseja remover a configuração de aplicativo multifator da conta <strong>{props.currentUser?.email}</strong>?
            </span>
          }
          <Row className="mt-3">
            <Col md={5} className="">
              <Button size='lg' className="buttonWhite2" style={{ width: '100%' }} onClick={() => props.hide()}>Cancelar</Button>
            </Col>
            <Col></Col>
            <Col md={5} className="d-flex justify-content-end align-self-end">
              <Button 
                onClick={() => props.submit()} 
                disabled={props.isLoading || !props.currentUser?.is_app_authenticated} 
                size='lg' className="buttonBlue2WithoutBold" 
                style={{ width: '100%' }}
              >Confirmar</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalInvalidApp2FA;
