import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom"
import {
  Button,
  Popover
} from "react-bootstrap"
import FormConfigAgents from "./index"
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel';
import { setShowConfirmationModal, setShowEditTypeAgent, setShowEditPaswordAgent, setShowRecoverPasswordAgente, setShowAlertFeedback } from '../../../../store/internal'
import { getToken } from '../../../../store/token';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import constsApi from "../../../../services/constsApi";
import BackendConstants from "../../../../core/constants/backend-constants";

const AppRequesterConst = new AppRequesterController()

const FormConfigAgentsController = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [currentAgent, setCurrentAgent] = useState(Object);

  //const [currentHasMoreInformation, setCurrentHasMoreInformation] = useState(true);

  const [currentLimit, setCurrentLimit] = useState(BackendConstants.limit);

  const [agentPage, setAgentPage] = useState(1);

  const [agentData, setAgentData] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [aux, setAux] = useState([]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    //props.updateListAfterDelete()
    setShowModal(false);
  };

  useEffect(() => {
    // console.log(props.data)
  }, [props?.data])

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  const invalidateAuthenticationPost = async (id) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    const JsonSend = {
      "employee_id": id,
    };

    await AppRequesterConst.Post(
      `/panel/employee/invalid-authentication-2fa/${id}`, JsonSend, { headers },
      (response: Object) => { },
      (data: any) => {
        if (data.status === 200) {
          dispatch(setShowAlertFeedback({ message: "Aplicativo invalidado!", visibility: true, signalIcon: true }));
        }
        callbackAgents();
      },
      (error: { response: { status: number } }) => {
        if (error.response.status === 400) { }
        if (error.response.status === 401) { }
      }, navigate, dispatch, props.setIsLoading,
    )
  }

  const tornarProprietarioPut = async (companyId, email) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    const JsonSend = {
      "company_id": companyId,
      "email": email
    };

    await AppRequesterConst.Put(
      `/panel/company/owner`, JsonSend, { headers },
      (response: Object) => { },
      (data: any) => {
        if (data.status === 200) {
          dispatch(setShowAlertFeedback({ message: "Proprietário atualizado com sucesso!", visibility: true, signalIcon: true }));
          callbackAgents();
        }
      },
      (error: { response: { status: number } }) => {
        if (error.response.status === 400) { }
        if (error.response.status === 401) { }
      }, navigate, dispatch, props.setIsLoading,
    )
  }

  const deleteChatbot = async (employeeId, companyId) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    const JsonSend = {
      "company_id": companyId,
      "employee_id": employeeId
    };

    await AppRequesterConst.Delete(
      `/panel/employee/${companyId}/${employeeId}`, { headers: headers },
      (response: Object) => { },
      (data: Object) => {
        dispatch(setShowAlertFeedback({ message: `${currentAgent.name} excluído com sucesso.`, visibility: true, signalIcon: true }));
        callbackAgents();
      },
      (error: Object) => {
        dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
      },
      navigate,
      dispatch,
      props.setIsLoading,
    );
  }

  const setCurrentAgentInfo = (data) => {
    setCurrentAgent(data)
  }

  const tornarProprietario = (e) => {
    dispatch(setShowConfirmationModal({
      visibility: true, text: { "title": 'Tornar proprietário', "body": `Tem certeza que deseja tornar o agente ${currentAgent.name} proprietário da empresa?`, "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" },
      functionConfirmation: () => {
        tornarProprietarioPut(currentAgent.company_id, currentAgent.email)
      }
    }));
  }

  const invalidarMultifator = (e) => {
    dispatch(setShowConfirmationModal({
      visibility: true, text: { "title": 'Invalidar aplicativo multifator', "body": `Desesa invalidar a autenticação multifator via aplicativo do agente ${currentAgent.name}? Será necessário que o agente configure novamente.`, "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" },
      functionConfirmation: () => {
        invalidateAuthenticationPost(currentAgent.id)
      }
    }));
  }

  const excluirAgente = (e) => {
    dispatch(setShowConfirmationModal({
      visibility: true, text: { "title": 'Excluir Agente', "body": `Desesa excluir o agente ${currentAgent.name}? Esta opção não poderá ser desfeita.`, "buttonReturnText": "Cancelar" },
      functionConfirmation: () => {
        deleteChatbot(currentAgent.id, currentAgent.company_id)
      }
    }));
  }

  const trocarTipo = () => {
    dispatch(setShowEditTypeAgent(true));
  }

  const trocarSenha = () => {
    dispatch(setShowEditPaswordAgent(true));
  }

  const recuperarSenha = () => {
    dispatch(setShowRecoverPasswordAgente(true))
  }

  const updateListFunction = () => {
    setAgentPage(agentPage + 1)
  }

  const getAllEmployees = async (params?: { limit: number; page: number; }) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    }

    await AppRequesterConst.Get(
      `/panel/company/${props.company_id}/employee`, { headers, params: params },
      (response: Object) => { },
      (data) => {
        if (data.status === 200) {
          // setAgentPage(data.data.data);
          setAux((state) => {
            let aux = state.concat(data.data.data);
            setAgentData(aux)
            return aux;
          })
        } else if (aux.length === 0) {
          setAgentData(undefined);
        }
      },
      (error: { response: { status: number; }; }) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
          }
          if (error.response.status === 401) {
          }
        }
      }, navigate, dispatch, props.setIsLoading, { values: values }
    );
  }

  const callbackAgents = () => {
    setAux([])
    setAgentPage(1)
    getAllEmployees({ limit: currentLimit, page: agentPage });
  }

  useEffect(() => {
    setAgentPage((page) => {
      getAllEmployees({ limit: currentLimit, page: page });
      return page
    })
  }, [agentPage])

  const popover = (
    <Popover id={currentAgent.id} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
      <Popover.Body className="nopadding">
        {currentAgent.profile_id !== constsApi.profile[2].id ?
          <>
            <Button onClick={(e) => { tornarProprietario(e) }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Tornar proprietário</Button>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
          </>
          :
          <></>
        }
        {currentAgent.profile_id !== constsApi.profile[2].id ?
          <>
            <Button onClick={() => { trocarTipo() }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Alterar tipo</Button>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
          </>
          :
          <></>
        }
        <Button onClick={() => { trocarSenha() }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Alterar senha</Button>
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
        <Button onClick={() => { recuperarSenha() }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Recuperação de senha</Button>
        {currentAgent.is_app_authenticated === true &&
          <>
          <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
          <Button onClick={(e) => { invalidarMultifator(e) }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Invalidar aplicativo multifator</Button>
          </>
        }
        {currentAgent.profile_id !== constsApi.profile[2].id ?
          <>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
            <Button onClick={(e) => { excluirAgente(e) }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir agente</Button>
          </>
          :
          <></>
        }

      </Popover.Body>
    </Popover>
  );

  return (
    <FormConfigAgents
      props={props}
      popover={popover}
      setCurrentAgentInfo={setCurrentAgentInfo}
      constsApi={constsApi}
      values={values}
      currentAgent={currentAgent}
      isEditAllowed={props.isEditAllowed}
      updateListfunction={updateListFunction}
      agentData={agentData}
      callbackAgents={callbackAgents}
      company_id={props.company_id}
    />
  )
}

export default FormConfigAgentsController