export class modalRegisterTriggerTypeController {
    private dispatch:Function
    private setShowCreateTriggerTypeModal:Function
    private setModalShow:Function
    private setType_isActive: Function

    constructor(dispatch:Function, setShowCreateTriggerTypeModal:Function, setModalShow:Function, setType_isActive: Function) {
        this.dispatch = dispatch
        this.setShowCreateTriggerTypeModal = setShowCreateTriggerTypeModal
        this.setModalShow = setModalShow
        this.setType_isActive = setType_isActive
    }
    Hide () {
        this.dispatch(this.setShowCreateTriggerTypeModal(false));
        this.setModalShow(false);
        this.setType_isActive(true)
    }
}