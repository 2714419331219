import { useEffect, useState } from "react"
import MasterAccess from "."

const allowedAuthentications = ['Administrador']

const MasterAccessController = ({ t, isLoading, getCurrentAuthentications, changeMasterAccess, infoGetAuth }) => {
    
    const [masterAcess, setMasterAcess] = useState(false)

    useEffect(() => {
        setMasterAcess(infoGetAuth);
    }, [infoGetAuth]);

    const changeStatus = async (value: boolean) => {
        if (value) {
            await changeMasterAccess(false);
            await getCurrentAuthentications()
        } else {
            await changeMasterAccess(true);
            await getCurrentAuthentications()
        } 
    }

    return (
        <MasterAccess
            t={t}
            masterAcess={masterAcess}
            isLoading={isLoading}
            changeStatus={changeStatus}
        />
    )
}

export default MasterAccessController