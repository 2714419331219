import React, { useEffect, useState } from 'react';

import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import InfiniteScroll from 'react-infinite-scroll-component';

import Loading from '../../../../../components/loading'

const ListBots = ({
  isLoading,
  props,
  viewBotPage,
  formatedDate,
  dataBot,
  values,
  popover,
  setCurrentBotInfo,
  t,
  dataCompany,
  setActiveTab,
  status,
  statusId,
  channel
}) => {
  return (
    <>
      {props.datas.length > 0 &&
        <Row className="ps-0" id="buttonCreateAgent" style={{ marginTop: '5vh', padding: 0, margin: '1vw 0vw 0px 0vw' }}>
          <Col md={3} style={{ marginLeft: '1vw' }}>
            <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
              {t("configurations.chatbots.lists.chatbot")}
              {
                <OverlayTrigger
                  key='top'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                      {t("configurations.chatbots.lists.register_chatbot")}
                    </Tooltip>
                  }
                >
                  <Button className="mx-3 nopadding buttonTicketsSec" onClick={(e) => { viewBotPage(e, 'info') }} >
                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                      <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                      <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                  </Button>

                </OverlayTrigger>
              }
            </p>
          </Col>
        </Row>
      }
      <Loading visibility={isLoading}></Loading>
      {
        props.datas.length > 0 ?
          <div style={{ marginTop: '2vh' }} className="ps-3">
            <ListGroup variant="flush">
              <ListGroup.Item className="TicketsRowCollums">
                <Row name="ListOfAgentsHead">
                  <Col md={3} className='d-flex align-self-center'>
                    <p>{ t("configurations.chatbots.lists.name") }</p>
                  </Col>
                  <Col md={3} className="nopadding">
                    <p>{ t("configurations.chatbots.lists.last_update") }</p>
                  </Col>
                  {channel === '33be81d8-88ac-4e08-bb4d-cf69fd23267f' ?
                    <Col md={3} className='d-flex justify-content-start'>
                      <p>Status</p>
                    </Col>
                    :
                    <></>
                  }
                  <Col className='d-flex justify-content-end'>
                    <p>{ t("configurations.chatbots.lists.actions") }</p>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
              <InfiniteScroll
                dataLength={props.datas.length}
                next={props.updateListfunction}
                hasMore={props.HasMoreInformation as boolean}
                loader={<h4> </h4>}
                scrollableTarget={"ListOfBots"}
              >
                {
                  <ListGroup variant="flush" className="TicketsRow">
                    {props.datas.map((item, index) => {
                      let date: Date | null = null;
                      let hour: string = null;
                      let newDate: Date | null = null;

                      // setPromptUpdatedNlp(item.prompt_updated_nlp)
                      newDate = new Date(item.updated_at);

                      date = newDate;
                      hour = `${newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()}:${newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()}:${newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds()}`;

                      return (
                        <ListGroup.Item style={{ color: '#707070' }} key={"keyAll" + index.toString()} >
                          <Row className='TicketsRowHeight'>
                            <Col md={3} className='d-flex  align-self-center' style={{ cursor: 'pointer' }} id={item.id} onClick={(e) => { viewBotPage(e, 'info', {}, item.prompt_updated_nlp) }} disabled={true}>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    {item.bot_name != null ?
                                      <>{item.bot_name}</>
                                      :
                                      <>N/A</>
                                    }
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {item.bot_name ? item.bot_name : <>N/A</>}
                                </span>
                              </OverlayTrigger>
                            </Col>
                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.id} onClick={(e) => { viewBotPage(e, 'info', {}, item.prompt_updated_nlp) }}>
                              <div className='oneLineClampEllipsis'>
                                <OverlayTrigger
                                  key='top'
                                  placement='top'
                                  overlay={
                                    <Tooltip id='tooltip'>
                                      {formatedDate(date)} - {hour} {item.last_employee_update_name ? <span>por: {item.last_employee_update_name ? item.last_employee_update_name : "N/A"}</span> : ''} 
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {formatedDate(date)} - {hour} {item.last_employee_update_name ? <span>por: {item.last_employee_update_name ? item.last_employee_update_name : "N/A"}</span> : '' }
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </Col>
                            {channel === '33be81d8-88ac-4e08-bb4d-cf69fd23267f' ?
                              <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.id} onClick={(e) => { viewBotPage(e, 'info', item.prompt_updated_nlp) }}>
                                <OverlayTrigger
                                  key='top'
                                  placement='top'
                                  overlay={
                                    <Tooltip id='tooltip'>
                                      {item.bot_enable ?
                                        <>{t('home_tags.list_active')}</> : <>{t('home_tags.list_inactive')}</>
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <div style={{ width: '33%' }}>
                                    {item.bot_enable ?
                                      <div className="d-flex justify-content-center py-1"
                                        style={{
                                          width: '100%',
                                          background: '#EEF3FF 0% 0% no-repeat padding-box',
                                          borderRadius: '3px',
                                          font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                          letterSpacing: '0px',
                                          color: '#0C2461',
                                        }}>
                                        {t('home_tags.list_active')}
                                      </div> :
                                      <div className="d-flex justify-content-center py-1"
                                        style={{
                                          width: '100%',
                                          background: '#FFD4B0 0% 0% no-repeat padding-box',
                                          borderRadius: '3px',
                                          font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                          letterSpacing: '0px',
                                          color: '#FF8119',
                                        }}>
                                        {t('home_tags.list_inactive')}
                                      </div>
                                    }
                                  </div>
                                </OverlayTrigger>
                              </Col>
                              :
                              <></>
                            }
                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.id} onClick={(e) => { viewBotPage(e, 'info', item.prompt_updated_nlp) }}>
                            {item.id === dataBot.state?.idPost ?
                              (values.promptUpdatedNlp === false || status === false ?
                                <Col className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.id} onClick={(e) => { viewBotPage(e, 'info',{}, item.prompt_updated_nlp) }}>
                                  <span className="mx-1">
                                    { t("configurations.chatbots.lists.updating_chatbot") }
                                    <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
                                    </Spinner>
                                  </span>
                                </Col>
                                :
                                <></>)
                              :
                              ((item.prompt_updated_nlp === false && item.id === statusId) || (status === false && item.id === statusId) ?
                                <Col className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.id} onClick={(e) => { viewBotPage(e, 'info', {},item.prompt_updated_nlp) }}>
                                  <span className="mx-1">
                                    { t("configurations.chatbots.lists.updating_chatbot") }
                                    <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
                                    </Spinner>
                                  </span>
                                </Col>
                                :
                                <></>)
                            }
                            </Col>
                            <Col className='d-flex justify-content-end ps-0' style={{/* width: '5%' */ }}>
                              <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item)} >
                                <Button id={item.id} onClick={(e) => { setCurrentBotInfo(e, item.prompt_updated_nlp) }} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="1" />
                                    <circle cx="12" cy="19" r="1" />
                                    <circle cx="12" cy="5" r="1" />
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                        </ListGroup.Item>);
                    })}
                  </ListGroup>
                }
              </InfiniteScroll>
            </div>
          </div>
          :
          ((dataCompany?.token_transcription === undefined || dataCompany?.token_transcription === null ||
            dataCompany?.token_chatgpt === null || dataCompany?.token_chatgpt === undefined ||
            dataCompany?.token_chatgpt === "" || dataCompany?.token_chatgpt === "") &&
            channel === "6977996d-11d1-44cc-a863-2e1d9c24e046") ?

            <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
              <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                <Col md={1} className="d-flex justify-content-center">
                  <svg id="Grupo_11213" data-name="Grupo 11213" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                    <path id="Caminho_9567" data-name="Caminho 9567" d="M0,0H24V24H0Z" fill="none" />
                    <circle id="Elipse_158" data-name="Elipse 158" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Caminho_9568" data-name="Caminho 9568" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Caminho_9569" data-name="Caminho 9569" d="M16,11h6M19,8v6" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </svg>
                </Col>
              </Row>
              <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                <Col md={4}>
                  <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("configurations.chatbots.lists.empty_tokens")}, <Button className="buttonAsText3 nopadding mb-1" onClick={() => setActiveTab("general")}>{t("configurations.chatbots.lists.add")}</Button></p>
                </Col>
              </Row>
            </div>
            :
            <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
              <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                <Col md={1} className="d-flex justify-content-center">
                  <svg id="Grupo_11213" data-name="Grupo 11213" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                    <path id="Caminho_9567" data-name="Caminho 9567" d="M0,0H24V24H0Z" fill="none" />
                    <circle id="Elipse_158" data-name="Elipse 158" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Caminho_9568" data-name="Caminho 9568" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Caminho_9569" data-name="Caminho 9569" d="M16,11h6M19,8v6" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </svg>
                </Col>
              </Row>
              <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                <Col md={4}>
                  <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("configurations.chatbots.lists.without_results")}, <Button className="buttonAsText3 nopadding mb-1" onClick={(e) => { viewBotPage(e, 'info', dataCompany) }}>{t("configurations.chatbots.lists.add_a_chatbot")}</Button></p>
                </Col>
              </Row>
            </div>
      }
    </>
  );

}

export default ListBots;