import constsRouters from "../../../../routes/constsRouter";

export class CallNotificationBarController {
    private dispatch: Function
    private setShowCallNotificationBar: Function
    private setShowMeetButton: Function
    private setCurrentTicketCallCenter: Function
    private setCallCenterState: Function
    private visibility: boolean
    private setUpdateButtonNumberOfTickets: Function
    private props
    private currentTicketCallCenter: any
    private navigate: Function
    private setShowEditTicketsModal: Function

    constructor(dispatch: Function, setShowCallNotificationBar: Function, setShowMeetButton: Function, setCurrentTicketCallCenter: Function, setCallCenterState: Function, visibility: boolean, 
        setUpdateButtonNumberOfTickets: Function, props, currentTicketCallCenter: any, navigate: Function, setShowEditTicketsModal: Function) {
        this.dispatch = dispatch
        this.setShowCallNotificationBar = setShowCallNotificationBar
        this.setShowMeetButton = setShowMeetButton
        this.setCurrentTicketCallCenter = setCurrentTicketCallCenter
        this.setCallCenterState = setCallCenterState
        this.visibility = visibility
        this.setUpdateButtonNumberOfTickets = setUpdateButtonNumberOfTickets
        this.props = props
        this.currentTicketCallCenter = currentTicketCallCenter
        this.navigate = navigate
        this.setShowEditTicketsModal = setShowEditTicketsModal
    }

    showPhone(state: { call_status: { incoming_display_name: string; incoming_number: string; }; call_info: { to_call: string; }; }) {
        if (state.call_status.incoming_display_name) {
            return (state.call_status.incoming_display_name);
        } else if (state.call_status.incoming_number) {
            return (state.call_status.incoming_number);
        } else {
            return (state.call_info.to_call);
        }
    }

    onCallcenterUpdate(state, ticketState, setPhonenumber: Function) {
        this.dispatch(this.setShowCallNotificationBar(state.call_status.is_active))
        this.dispatch(this.setShowMeetButton(state.call_status.is_ringing || state.call_status.is_active));
        this.setCurrentTicketCallCenter(ticketState);

        this.setCallCenterState(state)

        setPhonenumber(this.showPhone(state));
    }

    showNotificationBar() {
        if (this.visibility) {
            return 'block';
        } else {
            return 'none';
        }
    }

    showEditModal() {
        this.dispatch(this.setUpdateButtonNumberOfTickets(true));

        if (this.props.setCurrentTicketFunction && this.currentTicketCallCenter)
        this.props.setCurrentTicketFunction(this.currentTicketCallCenter.id);

        if (this.props.updateListfunction)
            this.props.updateListfunction();

            this.navigate(constsRouters.routers.tickets.path);
            this.dispatch(this.setShowEditTicketsModal(true));
    }
}