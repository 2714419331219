import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowEditTagsModal } from "../../../../store/internal";
import { ModalEditTagsInterface } from "./indexModel";
import ModalEditTags from ".";
import { useTranslation } from "react-i18next";

const ModalEditTagsController: FC<ModalEditTagsInterface> = (props) => {

    const [/*isLoading*/, setIsLoading] = useState(false)
    const [tagName, setTagName] = useState(null as string || null);
    const [tagIsActive, setTagIsActive] = useState((props.data && props.data.active != null) ? props.data.active : true);

    const [showFeedbackName, setShowFeedbackName] = useState(false);

    const [modalShow, setModalShow] = useState(props.visibility);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setModalShow(props.visibility);
        setTagIsActive((props.data && props.data.active != null) ? props.data.active : true);
    }, [props.visibility, props.data]);

    const Hide = () => {
        setShowFeedbackName(false);
        dispatch(setShowEditTagsModal(false));
        if (props?.consumer) {
            props?.setConsumer(false);
        }
    }

    const Validate = (values) => {
        let errors = {};

        if (!values.name) {
            errors['name'] = t('modal_edit_tag.validate_name_error')
            setShowFeedbackName(true)
        } else {
            setShowFeedbackName(false);
        }

        return errors;
    }
    return <ModalEditTags
        modalShow={modalShow}
        Hide={Hide}
        data={props?.data}
        Validate={Validate}
        tagName={tagName}
        tagIsActive={tagIsActive}
        token={props.token}
        dispatch={dispatch}
        callback={props.callback}
        navigate={navigate}
        setIsLoading={setIsLoading}
        showFeedbackName={showFeedbackName}
        setTagIsActive={setTagIsActive}
        setTagName={setTagName}
        t={t}
        consumer={props?.consumer}
        tagConsumer={props?.tagConsumer}
    />
}

export default ModalEditTagsController