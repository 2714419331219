import { AxiosRequestConfig } from 'axios';
import { NavigateFunction } from "react-router-dom";

import { CompanyInterface, BodyCompanySubdomainValidationInterface, BodySendEmailWithSubdomainsInterface } from './companyModel'
import { ErrorsInterface } from '../errorsInterfaceModel'

import { AppRequesterController } from '../appRequester/appRequesterController';

const AppRequestConst = new AppRequesterController();

export class CompanyRequests implements CompanyInterface {
    CompanySubdomainValidation = async (body: BodyCompanySubdomainValidationInterface, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, errorsFunctions?: ErrorsInterface) => {
        await AppRequestConst.Post(
            '/company/subdomain-validation', body, config,
            (response: Object) => {
                responseFunction(response)
                return response
            },
            (data: Object) => {
                thenFunction(data)
                window.location.href = ("http://" + body.subdomain + "." + window.location.hostname.replace("http://", "") + ":" + window.location.port).replace("www.", "");
            },
            (error: Object) => {
                catchFunction(error)
            }, navigate, dispatch, setIsLoading
        )
        
        // await Api.post('/company/subdomain-validation', body, headers).then((response) => {
        //     responseFunction(response);
        //     return response;
        // }).then(data => {
        //     thenFunction(data);
        //     window.location.href = ("http://" + body.subdomain + "." + window.location.hostname.replace("http://", "") + ":" + window.location.port).replace("www.", "");
        // }).catch((error) => {
        //     catchFunction(error);
        //     if (error.response !== undefined) {
        //         switch (error.response.status) {
        //             case constsApi.errors.error400.number:
        //                 errorsFunctions.error400();
        //                 break;
        //             case constsApi.errors.error422.number:
        //                 errorsFunctions.error422();
        //                 break;
        //             case constsApi.errors.error404.number:
        //                 errorsFunctions.error404();
        //                 break;

        //             default:
        //                 break;
        //         }
        //     }
        // });
    }

    SendEmailWithSubdomains = async (body: BodySendEmailWithSubdomainsInterface, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, errorsFunctions?: ErrorsInterface) => {
        await AppRequestConst.Post(
            '/company/subdomain', body, config,
            (response: Object) => {
                responseFunction(response)
                return response
            },
            (data: Object) => {
                thenFunction(data)
            },
            (error: Object) => {
                catchFunction(error)
            }, navigate, dispatch, setIsLoading
        )
        // await Api.post('/company/subdomain', body, headers).then((response) => {
        //     responseFunction(response);
        // }).then(data => {
        //     thenFunction(data);
        // }).catch((error) => {
        //     catchFunction(error);
        //     if (error.response !== undefined) {
        //         switch (error.response.status) {
        //             case constsApi.errors.error400.number:
        //                 errorsFunctions.error400();
        //                 break;
        //             case constsApi.errors.error422.number:
        //                 errorsFunctions.error422();
        //                 break;
        //             case constsApi.errors.error404.number:
        //                 errorsFunctions.error404();
        //                 break;

        //             default:
        //                 break;
        //         }
        //     }
        // });
    }
}


