import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { useSelector } from 'react-redux'
import Utils from '../../../../core/shared/utils';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, Pie } from 'react-chartjs-2';
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getShowCallNotificationBar, getShowAcquirePlatformComponent,
} from '../../../../store/internal'
import Loading from '../../../../components/loading/index'
import CallNotificationBar from '../../components/callNotificationBar';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import FilterOptionComponentController from '../../components/filterOptionComponent/indexController';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import DataDashboardController from './dataDashboard/indexController';

const HomeDashboardChatbot = ({
  isLoading,
  printPDF,
  phoneNumber,
  openModalPhone,
  setOpenModalPhone,
  componentRef,
  currentParamsNames,
  currentState,
  SetCurrentState,
  values,
  filteringTickets,
  cleanList,
  resetingFilters,
  setResetingFilters,
  yoursTickets,
  setYoursTickets,
  getDate,
  currentDayDate,
  printIsValid,
  options,
  data,
  data2,
  printIsInvalid,
  sessionBot,
  totalMessages,
  retentionRate,
  nps,
  topTermos,
  exportTickets,
  setCurrentFilterDates,
  setBot,
  setChannel,
  dataNpsPieChart,
  optionsPieChart,
  t
}) => {
  return (
    <>
      <Container fluid className="nopadding" >
        <Loading visibility={isLoading}></Loading>
        <div className="wrapper" >
          {/* <SidebarController currentPage={SidebarButtonPage.Dashboard} subPage={SidebarButtonPage.DashboardSubChatbot} className="nopadding" /> */}
          <div className="content pt-0 ps-0" style={{ backgroundColor: "#F9FBff", overflowY: "scroll", maxHeight: '100vh' }}>
            <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
            <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
            {printPDF ?
              <></>
              :
              <Row className="nopadding mt-3 ps-3" >
                <Col md={12} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                  <Row>
                    <Col className="d-flex justify-content-start align-self-start">
                      <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('dashboard_chatbot.title')}</div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <ButtonListNotificationController />
                      <ButtonStatusAgent />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('dashboard_chatbot.subtitle')}</div>
                  </Row>
                </Col>
              </Row>
            }
            <div ref={componentRef} style={{ backgroundColor: "#F9FBff" }}>
              <style type='text/css' media="print">
                {"\
                  @page {\ size: landscape;\ }\
                "}
              </style>
              {printPDF ?
                <div style={{ width: '1350px', height: '190px', backgroundColor: '#E5ECFF' }}>
                  <Row className=" justify-content-center" >
                    <Col md={10}>
                      <Row style={{ marginTop: '4vh', marginLeft: '2vw' }}>
                        <Col className="d-flex">
                          <div style={{ font: 'normal normal bold 25px/30px Montserrat' }}>{t('dashboard_chatbot.title_print')}</div>
                        </Col>
                      </Row>
                      <Row style={{ font: 'normal normal normal 16px/21px Roboto', color: '#1E1E1E', marginTop: '4vh', marginLeft: '2vw' }}>
                        <Col md={3} style={{ width: '25vw' }}>
                          {t('dashboard_chatbot.period')}
                        </Col>
                        <Col md={2} style={{ width: '16.7vw' }}>
                          {t('dashboard_chatbot.chatbot')}
                        </Col>
                        <Col md={1} style={{ width: '8.3vw' }}>
                          {t('dashboard_chatbot.chanel')}
                        </Col>
                      </Row>
                      <Row style={{ font: 'normal normal bold 16px/21px Roboto', color: '#1E1E1E', marginLeft: '2vw' }}>
                        <Col md={3} style={{ width: '25vw' }}>
                          {currentParamsNames["start_date"] && currentParamsNames["end_date"] ?
                            Utils.formatedDate(currentParamsNames["start_date"]) + " à " + Utils.formatedDate(currentParamsNames["end_date"])
                            :
                            "-"
                          }
                        </Col>
                        <Col md={2} style={{ width: '16.7vw' }}>
                          {currentParamsNames["bot_id"] ?
                            currentParamsNames["bot_id"]
                            :
                            "-"
                          }
                        </Col>
                        <Col md={1} style={{ width: '8.3vw' }}>
                          {currentParamsNames["channel"] ?
                            currentParamsNames["channel"]
                            :
                            "-"
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                :
                <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                  <ListGroup variant="flush" >
                    <ListGroup.Item style={{ backgroundColor: '#CEE2FF', padding: '0.6%' }}>
                      <FilterOptionComponentController
                        optionNames={["Data", "Chatbot", "Canal"]}
                        currentState={currentState}
                        setCurrentState={SetCurrentState}
                        values_user={values.user}
                        values_internal_getShowEditTicketsModal={false}
                        values_internal_getShowCreateTicketsModal={false}
                        filteringTickets={(e, auxObj, dataObj, action) => filteringTickets(e, auxObj, dataObj, action)}
                        cleanList={() => cleanList()}
                        values_token_value={values.token.value}
                        values_company_id={values.company.id}
                        resetingFilters={resetingFilters}
                        setResetingFilters={setResetingFilters}
                        yoursTickets={yoursTickets}
                        setYoursTickets={setYoursTickets}
                        dashboardMetrics={true}
                        firstAndCurrentDay={[getDate(), currentDayDate]}
                        setFilterDates={setCurrentFilterDates}
                        setSelectedBot={setBot}
                        setChannel={setChannel}
                        isChatbot={true}
                        screen={'home_dashboard_chatbot'}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              }
              <Row style={{ margin: '2vh 2vw 0px', padding: '0' }}>
                <DataDashboardController
                  sessionBot={sessionBot}
                  totalMessages={totalMessages}
                  retentionRate={retentionRate}
                  nps={nps}
                  printPDF={printPDF}
                />
              </Row>
              <Row className={printPDF ? '' : 'justify-content-center'} style={{ margin: '2vh 1.5vw 0px', padding: '0' }} >
                <Col md={1}></Col>
                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px" }}>
                  <Row style={{ margin: '2vh 0vw 0px' }}>
                    <Col md={3} className="d-flex justify-content-start" >
                      <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                        {t('dashboard_chatbot.total_user')}
                      </p>
                      {printPDF ?
                        <></>
                        :
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                            <Tooltip id='tooltip'>
                              {t('dashboard_chatbot.qtd_total_user')}
                            </Tooltip>
                          }
                        >
                          <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                          </svg>
                        </OverlayTrigger>
                      }
                    </Col>
                  </Row>
                  <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                    <Col >
                      <Bar plugins={[ChartDataLabels]} options={options} data={data} style={{ height: '30vh', width: printPDF ? '130vh' : '67.4vw' }} />
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
              </Row>

              <Row style={{ margin: '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "space-between" }}>
                <Col md={1}></Col>
                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px" }}>
                  <Row style={{ margin: '2vh 0vw 0px' }}>
                    <Col md={3} className="d-flex justify-content-start" >
                      <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                        {`${t('dashboard.nps_Score_metrics')}`}
                      </p>
                    </Col>
                  </Row>
                  {dataNpsPieChart?.labels?.length > 0 ?
                    <Row style={{ height: '35vh' }} className='d-flex justify-content-center' >
                      <Col md='5'>
                        <Pie
                          data={dataNpsPieChart}
                          options={optionsPieChart}
                        />
                      </Col>
                    </Row> :
                    <Row className='d-flex justify-content-center mb-3'>
                      <Col className='d-flex justify-content-center align-items-center'>
                        <Row className='d-flex justify-content-center align-content-center' style={{ /* backgroundColor: '#F9F9F9'  */ }}>
                          <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('dashboard.no_results')}</p>
                        </Row>
                      </Col>
                    </Row>
                  }
                </Col>
                <Col md={1}></Col>
              </Row>

              <Row className={printPDF ? '' : 'justify-content-center'} style={{ margin: '2vh 1.5vw 0px', padding: '0' }} >
                <Col md={1}></Col>
                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px" }}>
                  <Row style={{ margin: '2vh 0vw 0px' }}>
                    <Col md={4} className="d-flex justify-content-start" >
                      <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                        {t('dashboard_chatbot.service_human_chatbot')}
                      </p>
                      {printPDF ?
                        <></>
                        :
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                            <Tooltip id='tooltip'>
                              {t('dashboard_chatbot.service_human_chatbot_tooltip')}
                            </Tooltip>
                          }
                        >
                          <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                          </svg>
                        </OverlayTrigger>
                      }
                    </Col>
                  </Row>
                  <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                    <Col >
                      <Bar datasetIdKey='data2' plugins={[ChartDataLabels]} options={options} data={data2} style={{ height: '30vh', width: printPDF ? '130vh' : '67.4vw' }} />
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
              </Row>

              <Row className={printPDF ? '' : 'justify-content-center'} style={{ margin: '2vh 1.5vw 0px', padding: '0' }} >
                <Col md={2}></Col>
                <Col md={8} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px" }}>
                  <Row style={{ margin: '2vh 0vw 0px' }}>
                    <Col md={4} className="d-flex justify-content-start" style={printPDF ? { width: '40vw' } : {}}>
                      <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                        {t('dashboard_chatbot.top_terms')}
                      </p>
                      {printPDF ?
                        <></>
                        :
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                            <Tooltip id='tooltip'>
                              {t('dashboard_chatbot.top_terms_tooltip')}
                            </Tooltip>
                          }
                        >
                          <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                          </svg>
                        </OverlayTrigger>
                      }
                    </Col>
                  </Row>
                  <Row className={'mb-3 justify-content-center'} style={{ margin: '2vh 0vw 0px', color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                    <Col md={6} style={printPDF ? { width: '50vw' } : {}}>{t('dashboard_chatbot.term')}</Col>
                    <Col >{t('dashboard_chatbot.usage_quantity')}</Col>
                  </Row>
                  <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                    <Col >
                      {
                        topTermos?.map((item, index) => {
                          return (
                            <Row className='TicketsRowHeight'>
                              <Col md={6} style={printPDF ? { width: '50vw' } : {}}>{item._id}</Col>
                              <Col >{item.count}</Col>
                              <hr style={{ border: "1px solid #E8E8E8" }} />
                            </Row>
                          )
                        })
                      }
                    </Col>
                  </Row>
                </Col>
                <Col md={2}></Col>
              </Row>

              <Row className="justify-content-center" style={{ margin: '2vh 7.5vw 0px', padding: '0' }} >
                {printPDF ?
                  <></>
                  :
                  <>
                    <Col >
                      <ReactToPrint
                        content={() => componentRef.current}
                        onBeforeGetContent={() => printIsValid()}
                        onAfterPrint={() => printIsInvalid()}
                      >
                        <PrintContextConsumer>
                          {({ handlePrint }) => (
                            <Button onClick={handlePrint} size='lg' type='submit' className="buttonBlue" style={{ width: '100%' }}>{t('dashboard_chatbot.export_pdf')}</Button>
                          )}
                        </PrintContextConsumer>
                      </ReactToPrint>
                    </Col>

                    <Col >

                      <Button onClick={exportTickets} size='lg' type='submit' className="buttonBlue" style={{ width: '100%', marginBottom: '25px' }}>{t('dashboard_chatbot.export_csv')}</Button>

                    </Col>
                  </>
                }
              </Row>
            </div>
          </div>
        </div>
      </Container >
    </>
  );
}

export default HomeDashboardChatbot;
