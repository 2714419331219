import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { SelectComponentInterface } from './indexModel';

export type optionSelect = {
    label: string,
    value: string,
    key?: string,
}

interface IDefaultItemRendererProps {
    option: optionSelect;
    onClick: ChangeEventHandler;
}

export const optionSelectFormat = (item, label, value) => {
    return { ...item, label: label, value: value };
}

const SelectComponent:FC<SelectComponentInterface> = (props) => {
    const [isMultiSelect, setIsMultiSelect] = useState(false);

    useEffect(() => {
        if (props.singleSelect === false && props.hasAllOption) {
            setIsMultiSelect(true);
        } else {
            setIsMultiSelect(false);
        }
    }, [props.hasAllOption, props.singleSelect]);

    // include create item option
    useEffect(() => {
        // add create item option
        if (props.hasCreateItem && props.createItemLabel && props.createItemOnClick) {
            let createItem = { label: props.createItemLabel, value: 'create' };

            if (props.options.length === 0 || props.options[0].value !== createItem.value) {
                props.setOptions([ createItem, ...props.options ]);
            }
        }

        let nullOption = { label: props.nullOptionLabel, value: "NA" };
        let allOption = { label: props.allOptionLabel, value: "all" };
        
        setIsMultiSelect((isMulti) => {
            // all and na
            if (isMulti === false && props.hasAllOption && props.hasNullOption) {
                if (props.options.length === 0 || (props.options[0].value !== allOption.value && props.options[1].value !== nullOption.value)) {
                    props.setOptions( [ allOption, nullOption, ...props.options ]);
                }
            } 

            // na
            if (props.hasNullOption && props.hasAllOption === false && props.nullOptionLabel) {
                if (props.options.length === 0 || props.options[0].value !== nullOption.value) {
                    props.setOptions( [ nullOption, ...props.options] );
                }
            }

            // all
            if (isMulti === false && props.hasAllOption && props.hasNullOption === false && props.allOptionLabel) {
                if (props.options.length === 0 || props.options[0].value !== allOption.value) {
                    props.setOptions( [ allOption, ...props.options ] );
                }
            }

            return isMulti;
        });
    }, [props.options,props]);

    const customCreateItemOption = ({
        option,
        onClick,
    }: IDefaultItemRendererProps) => {
        return (
            <div className={`item-renderer`}>
                <input
                    type="checkbox"
                    onChange={(props.hasCreateItem && option.value === "create" ) ? props.createItemOnClick : onClick}
                />
                <span style={(props.hasCreateItem && option.value === "create" ) ? { fontWeight: 'bold' } : {}}>{option.label}</span>
            </div>
        );
    }

    const onChange = (value: string | any[]) => {
        // props.setSelecteds(value);

        // selectOptions(value, props.singleSelect, props.setSelecteds, props.options);

        // sigle option to select
        if (props.singleSelect) {
            // all selected or NA options selected for form (not filter)
            if ((value.length > 0 && value[value.length - 1].value === "all") || (value.length > 0 && value[value.length - 1].value === "NA" && props.filtering !== true)) {
                props.setSelecteds([]);
            } else if (value.length > 0) {
                props.setSelecteds([value[value.length - 1]]);

                props.setSelecteds((selecteds) => {
                    addItemNotInOptions(selecteds[0], props.options, props.setOptions);
                    return selecteds;
                });

                // addItemNotInOptions(value[0], props.options, props.setOptions);
            } else {
                props.setSelecteds([]);
            }
        } else { // normal multiselect
            props.setSelecteds(value);
            /* if (value.length === props.options.length) {
                props.setSelecteds([]);
            } else {
                props.setSelecteds(value);
            } */
        }

        if (props.onSelect) {
            props.onSelect(value);
        }
    }

    /**
     * search item in search
     * using in seaarch from back
     * add to list if not founded
     */
    const addItemNotInOptions = (selected: { label: string;value: string; key: string; }, list: any[], setList: { (value: any[]): void; (value: React.SetStateAction<{ label: string; value: string; key: string; }[]>): void; (value: { label: string; value: string; key: string; }[]): void; (arg0: (state: any) => any): void; }) => {
        /* let selectedId = null;
        if (selected.id) {
            selectedId = selected.id;
        } else if (selected.user_id) {
            selectedId = selected.user_id;
        } else {
            throw new Error("Ausência do 'id' ou do 'user_id'");
        } */
        
        setList((list) => {
            if (!list.find((item) => item.value === selected.value) && selected.key !== "NA") {
                list.splice(1, 0, selected);
            }
            return list;
        });

        /* if (!list.find((item) => item.value === selected.value) && selected.key !== "NA") {
            setList((state) => {
                state.splice(1, 0, selected);
                return state;
            });
        } */
    }

    return (
        <MultiSelect
            className={props.className !== undefined? props.className: ""}
            options={props.options}
            value={props.selecteds}
            onChange={onChange}
            closeOnChangedValue={isMultiSelect? false: true}
            hasSelectAll={isMultiSelect}
            // hasSelectAll={false}
            labelledBy={props.title}
            overrideStrings={
                props.overrideStrings
            }
            ItemRenderer={customCreateItemOption}
            filterOptions={props.filterOptions? props.filterOptions: undefined}
            disableSearch={props.disableSearch? props.disableSearch: false}
            disabled={props.disabled? props.disabled: false}
        />
    );
}

export default SelectComponent;