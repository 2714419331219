import React from 'react';

import allStyles from './styles';

import { Modal, Row, Container, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';

import { setShowAlertFeedback } from '../../../../../store/internal'
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';

const AppRequesterConst = new AppRequesterController();

const EditAgentPassword = ({
  modalShow,
  Hide,
  props,
  dispatch,
  navigate,
  setIsLoading,
  showPassword,
  clickPassword,
  setSenha,
  senha,
  showFeedbackSenha,
  setShowFeedbackSenha,
  company_id
}) => {

  return (
    <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" centered >
      <Modal.Header style={{ padding: '1rem 1rem 0rem 1rem' }} closeButton>
      </Modal.Header>
      <Modal.Header style={{ padding: '0rem 1.45rem' }}>
        <Modal.Title style={allStyles.textBlack}>
          Alterar senha
          <p className="mt-2" style={allStyles.textFontGlay}>
            {`Você está alterando a senha do agente ${props.data.name}`}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Formik
            initialValues={{
              senha: "",
            }}
            validate={values => {
              let errors = {};
              setSenha(values.senha)

              if (!values.senha) {
                errors['senha'] = 'Preencha este campo';
                setShowFeedbackSenha(true);
              } else if (values.senha.length > 0 && values.senha.length <= 4) {
                errors['senha'] = 'A senha deve ter pelo menos 5 caracteres';
                setShowFeedbackSenha(true);
              } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(values.senha)) {
                errors['senha'] = 'A senha deve conter pelo menos um caractere especial';
                setShowFeedbackSenha(true);
              } else if (!/[A-Z]/.test(values.senha)) {
                errors['senha'] = 'A senha deve conter pelo menos uma letra maiúscula';
                setShowFeedbackSenha(true);
              } else if (!/[a-z]/.test(values.senha)) {
                errors['senha'] = 'A senha deve conter pelo menos uma letra minúscula';
                setShowFeedbackSenha(true);
              } else if (!/\d/.test(values.senha)) {
                errors['senha'] = 'A senha deve conter pelo menos um número';
                setShowFeedbackSenha(true);
              }
              else {
                setShowFeedbackSenha(false);
              }

              return errors;
            }}
            onSubmit={async () => {
              let JsonSend = {
                "company_id": company_id,
                "password": senha,
                "password_confirmation": senha,
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await AppRequesterConst.Post(
                `/panel/employee/change_password/${props.data.id}`, JsonSend, { headers },
                (response: Object) => { return response; },
                (data: any) => {
                  if (data.status === 200) {
                    dispatch(setShowAlertFeedback({ message: `Senha atualizada com sucesso!`, visibility: true, signalIcon: true }));
                  }
                  props.callback()
                  Hide();
                },
                (error: { response: { status: number; data: { message: string, code_cxpress: number }; }; }) => {
                  if (error.response.status === 400 && error.response.data.code_cxpress === 2601) {
                    dispatch(setShowAlertFeedback({ message: "Não é possível alterar a senha do usuário pois a confirmação de email ainda não foi realizada.", visibility: true, signalIcon: false }));
                  }
                  else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                  }
                }, navigate, dispatch, setIsLoading
              );
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>

                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                  <Col md={12}>
                    <Form.Label htmlFor="name">Nova senha</Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={showFeedbackSenha}
                        placeholder="Informe a nova senha"
                        aria-label="Informe a nova senha"
                        aria-describedby="Informe a nova senha"
                        size='lg'
                        type='password'
                        id="senha"
                        name="senha"
                        maxLength={100}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.senha.trim()}
                      >
                      </FormControl>
                      <div className="d-flex justify-content-end align-self-start">
                        <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', paddingTop: '0.5vh', zIndex: "5" }} onClick={() => clickPassword()}>
                          {showPassword ?
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <circle cx="12" cy="12" r="2" />
                              <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="22" height="4vh" viewBox="0 0 22 22" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <line x1="3" y1="3" x2="21" y2="21" />
                              <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                              <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                            </svg>
                          }
                        </Button>
                      </div>
                      <Form.Control.Feedback type="invalid" id="showFeedbackSenha">
                        {errors.senha}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row style={allStyles.textFontGlay} className="mt-3">
                  <Col md={5} >
                    <Button size='lg' className="buttonWhite2" style={{ width: '100%' }} onClick={() => Hide()} >Cancelar</Button>
                  </Col>
                  <Col md={2}>

                  </Col>
                  <Col md={5} className="d-flex justify-content-end align-self-end">
                    <Button type='submit' size='lg' className="buttonBlue2WithoutBold" style={{ width: '100%' }}>Salvar</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default EditAgentPassword;