import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonCountNumberTicketsControllerInterface } from "./indexModel";
import ButtonCountNumberTickets from ".";

const ButtonCountNumberTicketsController: FC<ButtonCountNumberTicketsControllerInterface> = (props) => {
    const [numberOfAllTickets, setNumberOfAllTickets] = useState(null as number || null);
    const [numberOfYoursTickets, setNumberOfYoursTickets] = useState(null as number || null);
    const [numberOfNewTickets, setNumberOfNewTickets] = useState(null as number || null);
    const [numberOfUnreadTickets, setNumberOfUnreadTickets] = useState(null as number || null);
    const { t } = useTranslation();

    useEffect(() => {
        setNumberOfAllTickets(props.numberOfAllTickets);
        setNumberOfYoursTickets(props.numberOfYoursTickets);
        setNumberOfNewTickets(props.numberOfNewTickets);
        setNumberOfUnreadTickets(props.numberOfUnreadTickets);
    }, [props.numberOfAllTickets, props.numberOfYoursTickets, props.numberOfNewTickets, props.numberOfUnreadTickets]);

    return (
        <ButtonCountNumberTickets 
            t={t}
            closeWithoutSavingConfirmation={props.closeWithoutSavingConfirmation}
            numberOfAllTickets={numberOfAllTickets}
            numberOfYoursTickets={numberOfYoursTickets}
            numberOfNewTickets={numberOfNewTickets}
            numberOfUnreadTickets={numberOfUnreadTickets}        
            isLoading={props.isLoading}
            setNoAtendence={props?.setNoAtendence} 
        />
    );
}

export default ButtonCountNumberTicketsController;