export default class UserService {
    public static token: string;

    public static getHeaders() {
        let header = {}
        if(UserService.token !== undefined) {
            header = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + UserService.token,
            };
            
        } else {
            header = undefined
        }

        return header
    }
    
}