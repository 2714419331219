import { FC } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import constsApi from "../../../../../services/constsApi";
import ListOfTriageController from './listOfTriage/indexController';
import ModalRegisterTriageController from '../../../components/modalRegisterTriage/indexController';
import { useSelector } from 'react-redux';
import { getShowCreateTriageModal } from '../../../../../store/internal';
import { TriageTabInterface } from './indexModel';

const TriageTab: FC<TriageTabInterface> = (props) => {

    return (
        <>
            <ModalRegisterTriageController visibility={useSelector(getShowCreateTriageModal)} callback={props.callbackModalTriage} />
            <Row id="buttonCreateTriage" style={{ marginTop: '4vh', padding: 0 }}>
                <Col md={3} >
                    <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                        {props.t('home_departments.general.triage')}
                        {
                            ((props.values.user.profile_id === constsApi.profile[0].id) || (props.values.user.profile_id === constsApi.profile[2].id)) &&
                            <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                        {props.t('home_departments.general.create_triage')}
                                    </Tooltip>
                                }
                                >
                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={props.showCreateTriage}>
                                        <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                            <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                            <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </Button>

                                </OverlayTrigger>
                        }
                    </p>
                </Col>
            </Row>
            <ListOfTriageController setIsLoading={props.setIsLoading} showCreateTriage={props.showCreateTriage} refreshTriage={props.refreshTriage} setRefreshTriage={props.setRefreshTriage} />
        </>
    );
}

export default TriageTab;