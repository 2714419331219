
export default class LinksConstants {
    private static homeCxpress: string = "https://home.cxpress.io/contrate/";
    private static tutorialsCxpress: string = "https://blog.cxpress.io/tutoriais/";

    static homeBuild(name: string, subdomain: string) {
        return this.homeCxpress + "?utm_name=" + name + "&utm_subdomain=" + subdomain;
    }

    static tutorialsBuild() {
        return this.tutorialsCxpress;
    }

}