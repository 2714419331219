export class ButtonSearchCleanInputController {
    private props
    private setCurrentEventSave: Function
    private setShowCleanButton: Function
    private setSearchButtonClickedWithContent: Function

    constructor(props, setCurrentEventSave: Function, setShowCleanButton: Function, setSearchButtonClickedWithContent: Function) {
        this.props = props
        this.setCurrentEventSave = setCurrentEventSave
        this.setShowCleanButton = setShowCleanButton
        this.setSearchButtonClickedWithContent = setSearchButtonClickedWithContent
    }
    clickCleanSearchConfirmation(e: Object) {
        if (this.props.filledCreatingTicket) {
            this.setCurrentEventSave(e);
            this.props.closeWithoutSavingConfirmation(e);
        } else {
            this.cleanSearchField(e);
        }
    }

    cleanSearchField(e: Object) {
        this.props.setCurrentFilterSearch("");
        this.props.filteringTickets(e, {}, "clean");

        this.setShowCleanButton(false);

        this.setSearchButtonClickedWithContent(false);
    }

    clickSearchConfirmation(e: Object) {
        if (this.props.filledCreatingTicket) {
            this.setCurrentEventSave(e);
            this.props.closeWithoutSavingConfirmation(e); 
        } else {
            this.clickSearchButton(e);
        }
    }

    clickSearchButton(e: Object) {
        this.props.filteringTickets(e);
        if (this.props.currentFilterSearch && this.props.currentFilterSearch.length > 0)
        this.setSearchButtonClickedWithContent(true);
    }

    onChangeFrequent(e: any) {
        this.props.setCurrentFilterSearch(e.currentTarget.value);

        if (e.currentTarget.value.length == 0) {
            this.cleanSearchField(e);
        }
    }

    onKeyDownFrequent(e: any) {
        if (e.key === "Enter") {
            this.clickSearchButton(e);
        }
    }

    onChangeGeneric(e: any) {
        this.props.setCurrentFilterSearch(e.currentTarget.value);

        if (e.currentTarget.value.length == 0) {
            this.clickCleanSearchConfirmation(e);
        }
    }

    onKeyDownGeneric(e: any) {
        if (e.key === "Enter") {
            this.clickSearchConfirmation(e);
        }
    }
}