import React, { useState, useEffect, FC, } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css';

import { getToken } from '../../../../store/token';
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user';
import { getIdCompany } from '../../../../store/company';

import {
  setShowEditTriageModal,
} from '../../../../store/internal'
import { ModalEditTriageControllerInterface } from './indexModel';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';

import "@yaireo/tagify/dist/tagify.css"

import ModalEditTriage from './index';
import UserService from '../../../../services/user-service';
import { useTranslation } from 'react-i18next';
import { setShowConfirmationModal } from '../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalEditTriageController: FC<ModalEditTriageControllerInterface> = (props) => {
  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    internal: {
    }
  };
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [validated] = useState(false);

  const [currentTriageId, setCurrentTriageId] = useState('');
  const [triageName, setTriageName] = useState("");
  const [initialMessage, setInitialMessage] = useState("");
  const [finalMessage, setFinalMessage] = useState("");
  const [ticketAvailable, setTicketAvailable] = useState<any>([])
  const [triageFields, setTriageFields] = useState<any>([])

  const [triageIsActive, setTriageIsActive] = useState(true);

  const [showfeedbackName, setShowfeedbackName] = useState(false);
  const [showfeedbackInitialMessage, setShowfeedbackInitialMessage] = useState(false);
  const [showfeedbackFinalMessage, setShowfeedbackFinalMessage] = useState(false);
  const [selectedChanels, setSelectedChanels] = useState([]);
  const [triageIsMandatory, setTriageIsMandatory] = useState(false)

  const [optionsChanels, setOptionsChanels] = useState([]);

  const { t } = useTranslation()

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setModalShow(props.visibility);
    setTriageIsActive((props.data && props.data.active != null) ? props.data.active : true);
    setCurrentTriageId((props.data && props.data.id != null) ? props.data.id : '');
    setSelectedChanels(props.data?.channel_keys ? props.data?.channel_keys?.map((item) => ({ label: item.name, value: item.id })) : []);
    setTriageFields(props.data?.fields ? props.data.fields.map((item) => ({ id: item.custom_fields_id ? item.custom_fields_id : item.table + '.' + item.table_column, name: item.name, is_custom: item.is_custom })) : [])
    setTriageIsMandatory(props?.data?.has_mandatory_fields)
    if (props.visibility) {
      getTriageFields()
      getChanels()
    }
    setShowfeedbackName(false);
    setShowfeedbackInitialMessage(false);
    setShowfeedbackFinalMessage(false);
  }, [props.visibility, props.data])

  useEffect(()=>{

  },[triageIsActive])

  const verifyTriage = (name) => {
    let response = false;
    triageFields.forEach(element => {
      if(name === element.name) {
        response = true;
      };
    });
    return response
  }

  const getTriageFields = async () => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      '/triage/avaliable_fields', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data.status === 200) {
          setTicketAvailable(data.data.fields.length > 0 ? data.data.fields.filter((item) => {if(!verifyTriage(item.name)){ return { id: item.id, name: item.name, is_custom: item.is_custom }}}) : []);
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getChanels = async () => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      'channel/keys', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data.status === 200) {
          setOptionsChanels(data.data.channel_keys.map((item) => ({
            channel_icon: item,
            label: (
              item.whatsapp_key ? `${item.name} - ${item.whatsapp_key.phone_number}` : item.name
            ),
            value: item.id
          })));
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    if (droppableSource.droppableId === "droppable") {
      setTicketAvailable(sourceClone);
      setTriageFields(destClone);
    } else {
      setTriageFields(sourceClone);
      setTicketAvailable(destClone);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // quando é dropado fora da lista
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId === "droppable" ? ticketAvailable : triageFields,
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable") {
        setTicketAvailable(items);
      } else {
        setTriageFields(items);
      }
    } else {
      move(
        source.droppableId === "droppable" ? ticketAvailable : triageFields,
        source.droppableId === "droppable" ? triageFields : ticketAvailable,
        source,
        destination
      );
    }
  }

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '4px',
    height: 'min(max(35px, 4.7vh), 70px)',
    textOverflow: 'clip',

    // muda a cor do backgroun quando tá arrastando
    background: isDragging ? "#D8E2FF" : "#FFFFFF",

    // temos que aplicar o style nos arrastáveis
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#4A69BD" : "#E5ECFF",
    padding: grid,
    width: 200,
    borderRadius: '4px',
  });

  const Hide = () => {
    setTicketAvailable([]);
    setTriageFields([]);
    setSelectedChanels([]);
    dispatch(setShowEditTriageModal(false));
    setModalShow(false);
    setTriageIsActive(true)
  }

  const updateConfirmButton = (e, handleSubmit) => {
    e.preventDefault(); // Evita que o formulário seja submetido automaticamente
    dispatch(
      setShowConfirmationModal({
        visibility: true,
        text: {
          title: t('home_departments.triage_tab.update_triage'),
          body: t('home_departments.triage_tab.update_question'),
          buttonConfirmationText: t('home_departments.triage_tab.update_confirm_message'),
          buttonReturnText: t('home_departments.triage_tab.cancel'),
        },
        functionConfirmation: () => {
          handleSubmit(); // Chama o onSubmit quando a confirmação for feita
        },
      })
    );
  };

  return (
    <ModalEditTriage
      modalShow={modalShow}
      Hide={Hide}
      setTriageName={setTriageName}
      setShowfeedbackName={setShowfeedbackName}
      setShowfeedbackInitialMessage={setShowfeedbackInitialMessage}
      setShowfeedbackFinalMessage={setShowfeedbackFinalMessage}
      triageName={triageName}
      triageIsActive={triageIsActive}
      initialTriageStatus={props?.data?.active}
      initialMessage={initialMessage}
      finalMessage={finalMessage}
      selectedChanels={selectedChanels}
      values={values}
      dispatch={dispatch}
      props={props}
      navigate={navigate}
      setIsLoading={setIsLoading}
      validated={validated}
      showfeedbackName={showfeedbackName}
      showfeedbackInitialMessage={showfeedbackInitialMessage}
      showfeedbackFinalMessage={showfeedbackFinalMessage}
      optionsChanels={optionsChanels}
      setOptionsChanels={setOptionsChanels}
      setSelectedChanels={setSelectedChanels}
      setTriageIsActive={setTriageIsActive}
      setInitialMessage={setInitialMessage}
      setFinalMessage={setFinalMessage}
      ticketAvailable={ticketAvailable}
      triageFields={triageFields}
      onDragEnd={onDragEnd}
      getItemStyle={getItemStyle}
      getListStyle={getListStyle}
      currentTriageId={currentTriageId}
      updateConfirmButton={updateConfirmButton}
      t={t}
      triageIsMandatory={triageIsMandatory}
      setTriageIsMandatory={setTriageIsMandatory}
    />
  );
}

export default ModalEditTriageController;
