import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { Popover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    setShowConfirmationModal,
    setShowAlertFeedback
} from '../../../../../store/internal'

import { getToken } from '../../../../../store/token'

import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { TokenInterfaceControlPanel } from '../../../../../services/requestsInterfacesModel';
import UserService from '../../../../../services/user-service';
import ListConfigFacebook from '.';

const AppRequesterConst = new AppRequesterController();

const ListConfigFacebookController = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const [showModalEdit, setShowModalEdit] = useState(false);
    const [idAccount, setIdAccount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pageId, setPageId] = useState('');
    const [pageName, setPageName] = useState('');

    const funtionConfirmation = async (id: string) => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Delete(
            `/channel-facebook/facebook-key/${id}`, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: "Página excluída com sucesso.", visibility: true, signalIcon: true }))
                props.getFacebookById()
            },
            (error: Object) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading,
        )
    }

    const deletePage = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Excluir página', "body": `Tem certeza que deseja excluir a página #####? Essa operação não poderá ser desfeita.`, "buttonReturnText": "Cancelar", "replaceText": pageName }, functionConfirmation: () => funtionConfirmation(pageId) }));
    }
    const handleOpenModalEdit = () => {
        getAccountById(pageId)
    };

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    }

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    const getAccountById = async (id) => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            `/channel-facebook/facebook-key/${id}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setData(data.data.facebook_key)
                    setShowModalEdit(true);
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ visibility: true, message: "Ocorreu um erro inesperado, tente novamente", signalIcon: false }));
                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    const openPopover = (pageId, id, pageName) => {
        setPageId(pageId)
        setIdAccount(id)
        setPageName(pageName)
    }

    const popover = (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={handleOpenModalEdit} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Editar página</Button>


                <div>
                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    <Button onClick={() => deletePage()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir página</Button>
                </div>

            </Popover.Body>
        </Popover>
    );
    return <ListConfigFacebook
        props={props}
        isLoading={isLoading}
        popover={popover}
        getAccountById={getAccountById}
        data={data}
        showModalEdit={showModalEdit}
        handleCloseModalEdit={handleCloseModalEdit}
        openPopover={openPopover}
        infos={props?.datas}
        getFacebookById={props.getFacebookById}
        isEditAllowed={props.isEditAllowed}
    />

}

export default ListConfigFacebookController;