import React, { useEffect, useState } from "react";
import NotificationSoundController from "./indexController";
import { Button, Col, Container, Row } from "react-bootstrap";
import './styles.css';
import { getMute } from "../../store/internal";
import { useDispatch, useSelector } from "react-redux";

const BellNotification = () => {
    const dispatch = useDispatch();

    const values = {
        mute: {
            value: useSelector(getMute)
        }
    };

    const NotificationSoundConst = new NotificationSoundController(values.mute.value, dispatch);

    return (
        <Col md={1} style={{ width: '2.4vw' }} className='me-1'>
            <Button onClick={() => { NotificationSoundConst.handleClick(values.mute.value) }} className="buttonTicketsSec3 nopadding">
                {/* notificationSoundConst.getMute() */values.mute.value
                    ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 8a5 5 0 0 1 1.912 4.934m-1.377 2.602a5 5 0 0 1 -.535 .464" />
                        <path d="M17.7 5a9 9 0 0 1 2.362 11.086m-1.676 2.299a9 9 0 0 1 -.686 .615" />
                        <path d="M9.069 5.054l.431 -.554a.8 .8 0 0 1 1.5 .5v2m0 4v8a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l1.294 -1.664" />
                        <path d="M3 3l18 18" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 8a5 5 0 0 1 0 8" />
                        <path d="M17.7 5a9 9 0 0 1 0 14" />
                        <path d="M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
                    </svg>
                }
            </Button>
        </Col>
    );
}

export default BellNotification;