import { FC } from "react";
import { IModalGeneralTypeController } from "./indexModal";
import ModalGeneralType from ".";
import { useTranslation } from "react-i18next";

const ModalGeneralTypesController: FC<IModalGeneralTypeController> = (props) => {
  const { t } = useTranslation();

  return (
    <ModalGeneralType
      onHide={props.onHide}
      show={props.show}
      generalTypesVariables={props.generalTypesVariables}
      auxiTemplateMessage={props.auxiTemplateMessage}
      setAuxiTemplateMessage={props.setAuxiTemplateMessage}
      setTemplateMessage={props.setTemplateMessage}
      setTemplateParamsGeneralType={props.setTemplateParamsGeneralType}
      t={t}
    >
    </ModalGeneralType>
  )
}
export default ModalGeneralTypesController;