import { Button, Popover } from "react-bootstrap"
import ListOfTriggerTypes from ".";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { MessageSendingTypeInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getNameUser, getProfileId } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import Utils from "../../../../core/shared/utils";
import { getShowEditTriggerTypeModal, setShowAlertFeedback, setShowConfirmationModal, setShowEditTriggerTypeModal } from "../../../../store/internal";
import UserService from "../../../../services/user-service";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { ListOfTriggerTypesInterface } from "./indexModel";

const ListOfTriggerTypesController: FC<ListOfTriggerTypesInterface> = ({
    callback,
    datas,
    updateListfunction,
    callbackOrderBy,
    updateListAfterEditStatus,
    showCreateTriggerType,
    setCurrentTriggerTypeFunction,
    actionsActiveColumn,
    statusPage
}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [currentTriggerTypeId, setCurrentTriggerTypeId] = useState('');
    const [currentTriggerTypeActive, setCurrentTriggerTypeActive] = useState(false);
    const [currentTriggerTypeName, setCurrentTriggerTypeName] = useState("");
    const [currentData, setCurrentData] = useState({name: currentTriggerTypeName, id: currentTriggerTypeId, active: currentTriggerTypeActive});
    const [/*isLoading*/, setIsLoading] = useState(false);
    const [triggerTypes, setTriggerTypes] = useState(undefined);
    const [currentStatusPage, setCurrentStatusPage] = useState("");


    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTriggerTypeModal: useSelector(getShowEditTriggerTypeModal),
        },
    };

    const AppRequesterConst = new AppRequesterController();

    useEffect(() => {
        if (values.internal.getShowEditTriggerTypeModal) {
            dispatch(setShowEditTriggerTypeModal(false));
        }
    }, []);

    useEffect(() => {
        if (datas !== undefined) {
            if (statusPage !== currentStatusPage) {
                setCurrentStatusPage(statusPage);
            }
            setTriggerTypes(datas);
        } else {
            setTriggerTypes(undefined);
        } 
        setCurrentData({name: currentTriggerTypeName, id: currentTriggerTypeId, active: currentTriggerTypeActive});

        Utils.changeActiveScrollBar("listOfTriggerTypes");
   }, [datas, triggerTypes, currentTriggerTypeName]);

    const setCurrentTriggerInfo = (e: { currentTarget: { id: string; name:string; }; }) => {
        setCurrentTriggerTypeId(e.currentTarget.id.split('*ID*')[0]);
        setCurrentTriggerTypeActive(e.currentTarget.id.split('*ID*')[1] === "true"? true: false);
        setCurrentTriggerTypeName(e.currentTarget.name);
        
    }

    const showEditTriggerType = () => {
        dispatch(setShowEditTriggerTypeModal(true));
    }

    const disableTypeConfirm = (handleStatusTypeFunction: Function) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_message_trigger.list_of_trigger_types.inactive_type_tittle"), "body": t("home_message_trigger.list_of_trigger_types.inactive_body"), "id": currentTriggerTypeName + "?", "buttonConfirmationText": t("home_message_trigger.list_of_trigger_types.inactive_button"), "buttonReturnText": t("home_message_trigger.list_of_trigger_types.cancel") }, functionConfirmation: handleStatusTypeFunction }));
    }

    const activeTypeConfirm = (handleStatusTypeFunction: Function) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_message_trigger.list_of_trigger_types.active_type_tittle"), "body": t("home_message_trigger.list_of_trigger_types.active_body"), "id": currentTriggerTypeName + "?", "buttonConfirmationText": t("home_message_trigger.list_of_trigger_types.active_button"), "buttonReturnText": t("home_message_trigger.list_of_trigger_types.cancel") }, functionConfirmation: handleStatusTypeFunction }));
    }

    const popover = (active: boolean, handleStatusTypeFunction: Function) => {
        return (
            <Popover id={currentTriggerTypeId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    <Button onClick={() => showEditTriggerType()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_message_trigger.list_of_trigger_types.edit_type")}</Button>
                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>

                    {active ?
                        <Button onClick={() => disableTypeConfirm(handleStatusTypeFunction)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}
                            >
                                {t("home_message_trigger.list_of_trigger_types.inactivate_type")}
                        </Button>
                        :
                        <Button onClick={() => activeTypeConfirm(handleStatusTypeFunction)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>
                            {t("home_message_trigger.list_of_trigger_types.activate_type")}
                        </Button>
                    }
                </Popover.Body>
            </Popover>
        )
    };

    const handleStatusTypeFunction = async () => {
    
        const headers = UserService.getHeaders()
    
        let newStatus = true;
        if (currentTriggerTypeActive) {
            newStatus = false;
        }
    
        let JsonSend = {
            "id": currentTriggerTypeId,
            //"name": curren,
            "active": newStatus
        }
    
    
        await AppRequesterConst.Put(
            '/message-sending-type', JsonSend, { headers },
    
            (response:Object) => {},
            (data: MessageSendingTypeInterface) => {
    
                if (newStatus) {
                    dispatch(setShowAlertFeedback({ message: t("home_message_trigger.list_of_trigger_types.active_sucess"), visibility: true, signalIcon: true }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t("home_message_trigger.list_of_trigger_types.inactive_sucess"), visibility: true, signalIcon: true }));
                }
                
                updateListAfterEditStatus();
            },
            (error: { response: { status: number; data:{code_cxpress: number}}; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
               
            }, navigate, dispatch, setIsLoading
        );
    }

    return (
        <ListOfTriggerTypes
            t={t}
            values={values}
            callback={callback}
            currentData={currentData}
            triggerTypes={triggerTypes}
            callbackOrderBy={callbackOrderBy}
            actionsActiveColumn={actionsActiveColumn}
            updateListfunction={updateListfunction}
            popover={popover}
            handleStatusTypeFunction={handleStatusTypeFunction}
            setCurrentTriggerInfo={setCurrentTriggerInfo}
            showCreateTriggerType={showCreateTriggerType}
        />
    )
}

export default ListOfTriggerTypesController