import React from 'react'

const StarComponent = (props) => {    

    const availableFull = (count: number) => {

        let first = count?.toString()?.split(".")[0]
        let second = count?.toString()?.split(".")[1]
        
        let firstInt = parseInt(first)
        let secontInt = parseInt(second)
        
        let finalResult = 5 - count
        let empty = parseInt(finalResult?.toString()?.split(".")[0])
        
        let emptyStarFinal = secontInt < 5 ? 5 - ((firstInt + empty) - empty) : empty
        
        let startFull = [...Array(firstInt ? firstInt : 0)].map((star, index) => {
            return (
                <svg key={"full"+index} id="Grupo_13479" data-name="Grupo 13479" xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.width : "24"} viewBox="0 0 24 24">
                    <path id="Caminho_10333" data-name="Caminho 10333" d="M0,0H24V24H0Z" fill="none"/>
                    <path id="Caminho_10334" data-name="Caminho 10334" d="M8.243,7.34l-6.38.925-.113.023a1,1,0,0,0-.44,1.684l4.622,4.5-1.09,6.355-.013.11a1,1,0,0,0,1.464.944l5.706-3,5.693,3,.1.046a1,1,0,0,0,1.352-1.1l-1.091-6.355,4.624-4.5.078-.085a1,1,0,0,0-.633-1.62l-6.38-.926L12.89,1.56a1,1,0,0,0-1.794,0L8.243,7.34Z" fill="#4a69bd" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
        })

        let halfStar = [...Array(1)].map((star, index) => {
            return (
                <svg key={"half"+index} id="Grupo_13567" data-name="Grupo 13567" xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.width : "24"}  viewBox="0 0 24 24">
                    <path id="Caminho_10335" data-name="Caminho 10335" d="M0,0H24V24H0Z" fill="none"/>
                    <path id="Caminho_10336" data-name="Caminho 10336" d="M12,1a.993.993,0,0,1,.823.443l.067.116L15.742,7.34l6.38.925a1,1,0,0,1,.7,1.526l-.07.1-.078.086-4.624,4.5,1.09,6.355a1,1,0,0,1-1.249,1.135l-.1-.035-.1-.046L12,18.88l-5.706,3a1,1,0,0,1-.32.106L5.867,22a1,1,0,0,1-1.038-1.06l.013-.11,1.09-6.355-4.623-4.5a1,1,0,0,1,.328-1.647l.113-.036.114-.023L8.243,7.34,11.1,1.56A.968.968,0,0,1,12,1Zm0,3.274V16.75a1,1,0,0,1,.239.029l.115.036.112.05,4.363,2.3-.836-4.873a1,1,0,0,1,.136-.7l.07-.1.082-.09,3.546-3.453-4.891-.708a1,1,0,0,1-.62-.344l-.073-.1-.06-.106L12,4.274Z" fill="#4a69bd" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
        })

        let emptyStar = [...Array(emptyStarFinal ? emptyStarFinal : 0)].map((star, index) => {
            return (
                <svg key={"empty"+index} id="Grupo_13566" data-name="Grupo 13566" xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.width : "24"} viewBox="0 0 24 24">
                    <path id="Caminho_10337" data-name="Caminho 10337" d="M0,0H24V24H0Z" fill="none"/>
                    <path id="Caminho_10338" data-name="Caminho 10338" d="M12,17.75,5.828,20.995l1.179-6.873-5-4.867,6.9-1L11.993,2l3.086,6.253,6.9,1-5,4.867,1.179,6.873Z" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </svg>
            )
        })

        let result = secontInt >= 5 ? startFull?.concat(halfStar?.concat(emptyStar)) : startFull?.concat(emptyStar)
        
        return result
    }
    
    return (
        <span style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            {availableFull(props?.points ? props?.points.toFixed(1) : 0)}
        </span>
    )
}

export default StarComponent