export enum Colors {
    // Black
    Black = "#1E1E1E",

    // Blue tones
    Blue50 = "#EEF3FF",
    Blue100 = "#E5ECFF",
    Blue200 = "#CFDCFF",
    Blue300 = "#96ACE8",
    Blue500 = "#4A69BD",
    Blue800 = "#0C2461",

    PrimaryBlue = "#104C81",

    // Grey tones
    Grey50 = "#F9F9F9",
    Grey100 = "#F6F7F8",
    Grey200 = "#E8E8E8",
    Grey300 = "#D9D9D9",
    Grey500 = "#AFAFAF",
    Grey700 = "#707070",
    Grey900 = "#6A6A6A", 

    PrimaryGrey = "#BCBCBC",

    // Orange
    LightOrange = "#FFE8D5",

    // Pink
    Pink = "#DE2450",

    // Red
    Red = "#FF1919",
    PrimaryRed = "#FF0000",

    // White
    White = "##FFFFFF",

    // Yellow
    Yellow = "#FBBD4E"
}