import { useEffect, useState } from "react";
import BotConfig from "./index";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import constsRouters from "../../../../../../routes/constsRouter";
import { useDispatch } from "react-redux";
import { Channels } from "../../../../../../core/enums/channels";

const BotConfigController = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataBot = useLocation();

  const [overflowMessage, setOverflowMessage] = useState('');
  const [showOverflowMessageErrorFeedback, setShowOverflowMessageErrorFeedback] = useState(false);
  const [temperature, setTemperature] = useState<number>()
  const [botEnable, setBotEnable] = useState<boolean>()
  const [initialChatbotMessage, setInitialChatbotMessage] = useState('');
  const [showInitialChatbotMessageErrorFeedback, setShowInitialChatbotMessageErrorFeedback] = useState(false);

  const [channel] = useState(dataBot.state?.channel);
  const [isLoading, setIsLoading] = useState(false);
  const [ableDisable, setAbleDisable] = useState(dataBot.state?.data?.bot_enable);
  const [understandAudio, setUnderstandAudio] = useState(!!dataBot.state?.data?.understand_audio)
  const [audioReplyType, setAudioReplyType] = useState(dataBot.state?.data?.audio_reply_type)
  const [directAttendance, setDirectAttendance] = useState(dataBot.state?.data?.direct_attendance)
  
  let isWhatsappChannel = (channel === Channels.WhatsApp)
  let hasWhatsappKeys = dataBot.state?.data?.whatsapp_keys_id ? true : false;
  
  const { t } = useTranslation();
  
  const [selectedAudioReply, setSelectedAudioReply] = useState([]);
  const [audioReplyOptions, setAudioReplyOptions] = useState([
    { label: t("configurations.chatbots.view.bot_config.reply_type_text"), value: 'text' },
    { label: t("configurations.chatbots.view.bot_config.reply_type_audio"), value: 'audio' }
  ]);
  
  useEffect(() => {
    setDirectAttendance(dataBot.state?.data?.direct_attendance)
    setSelectedAudioReply([audioReplyOptions[(audioReplyType === 'text' ? 0 : 1)]])
  },[dataBot])

  useEffect(() => {
    if (isLoading) {
      props.setIsLoading(true);
    } else {
      props.setIsLoading(false);
    }
  }, [isLoading]);

  const goToListOfBots = (promptUpdate?: boolean, idPost?: string) => {
    navigate(constsRouters.routers.configurationChatbot.path, { state: { channel: channel ? channel : '4a7b71bb-6432-4794-ad11-f978b067871d', promptUpdate: promptUpdate, idPost: idPost } });
  }

  return (
    <BotConfig 
      overflowMessage={overflowMessage}
      setOverflowMessage={setOverflowMessage}
      showOverflowMessageErrorFeedback={showOverflowMessageErrorFeedback}
      setShowOverflowMessageErrorFeedback={setShowOverflowMessageErrorFeedback}
      initialChatbotMessage={initialChatbotMessage}
      setInitialChatbotMessage={setInitialChatbotMessage}
      showInitialChatbotMessageErrorFeedback={showInitialChatbotMessageErrorFeedback}
      setShowInitialChatbotMessageErrorFeedback={setShowInitialChatbotMessageErrorFeedback}
      setIsLoading={setIsLoading}
      t={t}
      goToListOfBots={goToListOfBots}
      dataBot={dataBot}
      navigate={navigate}
      dispatch={dispatch}
      temperature={temperature}
      setTemperature={setTemperature}
      setBotEnable={setBotEnable}
      botEnable={botEnable}
      ableDisable={ableDisable}
      setAbleDisable={setAbleDisable}
      understandAudio={understandAudio}
      setUnderstandAudio={setUnderstandAudio}
      selectedAudioReply={selectedAudioReply}
      setSelectedAudioReply={setSelectedAudioReply}
      audioReplyOptions={audioReplyOptions}
      setAudioReplyOptions={setAudioReplyOptions}
      isWhatsappChannel={isWhatsappChannel}
      hasWhatsappKeys={hasWhatsappKeys}
      directAttendance={directAttendance}
      setDirectAttendance={setDirectAttendance}
    />
  )
}

export default BotConfigController;