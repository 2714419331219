import { createSlice } from '@reduxjs/toolkit';

export const userControlPanelSlice = createSlice({
  name: 'user_controlpanel',
  initialState: {
    id: null as string | null,
    email: null as string | null,
    authentication_method_default: false as boolean | null,
    has_app_authentication:  false as boolean | null,
    permissions: []
  },
  reducers: {
    setUserControlPanelId: (state, action) => {
      state.id = action.payload;
    },
    setUserControlPanelEmail: (state, action) => {
      state.email = action.payload;
    },
    setUserControlPanelAuthMethodDefault: (state, action) => {
      state.authentication_method_default = action.payload;
    },
    setUserControlPanelHasAppAuth: (state, action) => {
      state.has_app_authentication = action.payload;
    },
    setUserControlPanelPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setUserControlPanelId,
  setUserControlPanelEmail,
  setUserControlPanelAuthMethodDefault,
  setUserControlPanelHasAppAuth,
  setUserControlPanelPermissions
} = userControlPanelSlice.actions;

export const getUserControlPanelId = (state: { user_controlpanel: { id: string } }) => (state.user_controlpanel?.id);
export const getUserControlPanelEmail = (state: { user_controlpanel: { email: string } }) => (state.user_controlpanel?.email);
export const getUserControlPanelAuthMethodDefault = (state: { user_controlpanel: { authentication_method_default: string } }) => (state.user_controlpanel?.authentication_method_default);
export const getUserControlPanelHasAppAuth = (state: { user_controlpanel: { has_app_authentication: string } }) => (state.user_controlpanel?.has_app_authentication);
export const getUserControlPanelPermissions = (state: { user_controlpanel: { permissions: { id: string, created_at: string }[] } }) => (state.user_controlpanel?.permissions);

export default userControlPanelSlice.reducer;
