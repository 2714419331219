import { FC, useEffect, useState } from "react";
import { FormValues, IFormOfModalsTemplateMessageController } from "./indexModel";
import FormOfModalsTemplateMessage from ".";
import { TokenInterfaceControlPanel } from "../../../../../../services/requestsInterfacesModel";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../../../../store/token";
import { AppRequesterControlPanelController } from "../../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import { useNavigate } from "react-router-dom";
import { setShowAlertFeedback, setShowTemplateTagModal } from "../../../../../../store/internal";

const FormOfModalsTemplateMessageController: FC<IFormOfModalsTemplateMessageController> = (props) => {
    const [showFeedbackTitle, setShowFeedbackTitle] = useState(false);
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [showFeedbackPhone, setShowFeedbackPhone] = useState(false);
    const [attachmentIds, setAttachmentIds] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [whatsappKeys, setWhatsappKeys] = useState([]);
    const [selectedWhatsappKey, setSelectedWhatsappKey] = useState([]);
    // const [phone, setPhone] = useState("");

    const appRequesterConst = new AppRequesterControlPanelController();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        const controller = new AbortController();

        if (props.currentCompany) {
            getCompanyWhatsapps(controller, props.currentCompany.company_id);
        }

        return () => {
            controller.abort();
        }
    }, [props.currentCompany]);

    useEffect(() => {
        if (props.preSelectedWhatsapp)
            setSelectedWhatsappKey([props.preSelectedWhatsapp]);
    }, [props.preSelectedWhatsapp]);

    const getCompanyWhatsapps = async (controller: AbortController, companyId: string) => {
        const signal = controller.signal;

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        }

        await appRequesterConst.Get(
            `/channel-whatsapp/whatsapp-keys/${companyId}`, { headers, signal },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setWhatsappKeys((numbers) => {
                        numbers = data.data.whatsAppKeys.map((item) => {
                            return { label: item.phone_number, value: item.id }
                        });
                        return numbers;
                    });
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, () => {}
        )
    }

    const validate = (values: FormValues) => {
        let errors = {};

        if (values.message?.length > 1024) {
            errors['message'] = "Mensagem não atende ao limite de 1024 caracteres.";
            setShowFeedbackMessage(true);
        } else if (values.message?.length === 0) {
            errors['message'] = "Mensagem não pode ser vazia.";
            setShowFeedbackMessage(true);
        } else if (props.tags?.length > 0) { // restringindo uso das tags com {{nome}} e permitindo somente com {{1}}
            props.tags.map((tag, index) => {
                if (values?.message.includes(`{{${tag.alias}}}`)) {
                    errors['message'] = `Coloque o número {{${index+1}}} da variável, não o seu nome {{${tag.alias}}}`;
                    setShowFeedbackMessage(true);
                }

                if (!values?.message.includes(`{{${index+1}}}`)) {
                    errors['message'] = `Adicione o número {{${index+1}}} da variável {{${tag.alias}}}`;
                    setShowFeedbackMessage(true);
                }
            });
        } else {
            setShowFeedbackMessage(false);
        }

        if (values.title?.length > 100) {
            errors['title'] = "Título não atende ao limite de 100 caracteres.";
            setShowFeedbackTitle(true);
        } else if (values.title?.length === 0) {
            errors['title'] = "Título não pode ser vazio.";
            setShowFeedbackTitle(true);
        } else {
            setShowFeedbackTitle(false);
        }

        return errors;
    }

    const attachmentIdsCallback = (ids: []) => {
        setAttachmentIds(ids);
    }

    const onSubmit = (valuesForm: FormValues) => {
        let form = {
            message: valuesForm.message,
            title: valuesForm.title,
        }

        if (selectedWhatsappKey[0]?.label)
            form['phone_number'] = selectedWhatsappKey[0].label;

        if (attachmentIds.length > 0)
            form['image_id'] = attachmentIds[0];

        if (props.tags?.length > 0) {
            let tags = props.tags.map((tag, index) => {
                return  {
                    "tag_number": index + 1,
                    "tag": tag.tag,
                    "alias": tag.alias,
                };
            });

            form['tags'] = tags;
        }

        if (selectedWhatsappKey.length === 0) {
            dispatch(setShowAlertFeedback({ message: "Selecione um número de Whatsapp.", visibility: true, signalIcon: false }));
        } else {
            // verificando se é de edição ou criação
            if (props.templateId)
                props.onSubmit(form, props.templateId);
            else 
                props.onSubmit(form); 
        }
    }

    const hideTagModal = () => {
        dispatch(setShowTemplateTagModal(false));
    }

    const showCreateTagModal = () => {
        dispatch(setShowTemplateTagModal(true));
    }

    return <FormOfModalsTemplateMessage 
        validate={validate}
        onSubmit={onSubmit}
        hide={props.hide}
        initialValues={props.initialValues}
        showFeedbackTitle={showFeedbackTitle}
        showFeedbackMessage={showFeedbackMessage}
        showFeedbackPhone={showFeedbackPhone}
        attachmentIdsCallback={attachmentIdsCallback} 
        currentCompany={props.currentCompany} 
        whatsappKeys={whatsappKeys} 
        setWhatsappKeys={setWhatsappKeys} 
        selectedWhatsappKey={selectedWhatsappKey} 
        setSelectedWhatsappKey={setSelectedWhatsappKey}  
        tags={props.tags}
        setTags={props.setTags}  
        hideTagModal={hideTagModal}
        showCreateTagModal={showCreateTagModal}
        previewImage={props.previewImage}
        isLoading={isLoading}
    />
}

export default FormOfModalsTemplateMessageController;