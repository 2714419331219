import {
    Row,
    Col,
    Button,
    Form,
    InputGroup,
    FormControl,
} from "react-bootstrap"
import allStyles from "./styles"
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'
import { Formik } from "formik"
import SelectComponent from "../selectComponent"
import { setShowAlertFeedback } from "../../../../store/internal"


const AppRequesterConst = new AppRequesterController()

const FormConfigContract = ({
    setSelectedStatus,
    selectedStatus,
    setOptionsStatus,
    optionsStatus,
    clickEmail,
    setClickEmail,
    clickFacebook,
    setClickFacebook,
    clickInstagram,
    setClickInstagram,
    clickWhatsApp,
    setClickWhatsApp,
    clickTelefonia,
    setClickTelefonia,
    qtdAgentes,
    setQtdAgentes,
    setCustomFildsConsumer,
    customFildsConsumer,
    setCustomFildsTicket,
    customFildsTicket,
    setStatusCompany,
    statusCompany,
    setClickWebchat,
    clickWebchat,
    showfeedbackAgents,
    setShowFeedbackAgents,
    showfeedbackTicket,
    setShowFeedbackTicket,
    setShowFeedbackConsumers,
    showfeedbackConsumers,
    acessToken,
    dispatch,
    navigate,
    setIsLoading,
    id,
    company_id,
    setChannels,
    channels,
    getCompanyById,
    isEditAllowed,
    setStatusIcon,
}) => {
    return (
        <>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                    className="mt-2"
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Configurações do Contrato
                    </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Formik
                enableReinitialize={true}
                
                initialValues={{ qtdAgentes: qtdAgentes, customFildsConsumer: customFildsConsumer, customFildsTicket: customFildsTicket }}
                validate={values => {
                    let errors = {};
                    setQtdAgentes(values?.qtdAgentes);
                    setCustomFildsConsumer(values?.customFildsConsumer)
                    setCustomFildsTicket(values?.customFildsTicket)
                    if (!values.qtdAgentes) {
                        errors["qtdAgentes"] = 'Preencha este campo';
                        setShowFeedbackAgents(true);
                    } else {
                        setShowFeedbackAgents(false);
                    }

                    if (!values.customFildsTicket) {
                        errors["customFildsTicket"] = 'Preencha este campo';
                        setShowFeedbackTicket(true);
                    } else {
                        setShowFeedbackTicket(false);
                    }

                    if (!values.customFildsConsumer) {
                        errors["customFildsConsumer"] = 'Preencha este campo';
                        setShowFeedbackConsumers(true);
                    } else {
                        setShowFeedbackConsumers(false);
                    }

                    return errors;
                }}
                onSubmit={() => {
                    if (isEditAllowed) {
                        const JsonSend = {
                            "id": id,
                            "company_id": company_id,
                            "quantity_agents": qtdAgentes,
                            "consumer_custom_fields_limit": customFildsConsumer,
                            "ticket_custom_fields_limit": customFildsTicket,
                            "contract_status_id": selectedStatus[0].value,
                            "channels": channels
                        };
    
                        const headers = {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization": "Bearer " + acessToken
                        };
    
                        AppRequesterConst.Post(
                            `/contract`,
                            JsonSend,
                            { headers: headers },
                            (response: Object) => {
                                return response;
                            },
                            (data) => {
                                if (data.data.message === "Requisição atendida com sucesso.") {
                                    setShowFeedbackConsumers(false);
                                    setShowFeedbackTicket(false);
                                    setShowFeedbackAgents(false);
                                    setStatusIcon(selectedStatus[0].value)
                                    dispatch(setShowAlertFeedback({ message: `contrato atualizado com sucesso!`, visibility: true, signalIcon: true }))
                                    getCompanyById()
                                }
    
                            },
                            (error: Object) => {
                                dispatch(setShowAlertFeedback({ message: `Ocorreu um erro inesperado, tente novamente mais tarde.`, visibility: true, signalIcon: false }))
                            },
                            navigate,
                            dispatch,
                            setIsLoading
                        );
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                        <Row>
                            <Col md={6}>
                                <Form.Label htmlFor="name" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Status da empresa
                                </Form.Label>
                                <SelectComponent
                                    className={"multiselectForm mb-3"}
                                    options={optionsStatus}
                                    disableSearch={true}
                                    setOptions={setOptionsStatus}
                                    selecteds={selectedStatus}
                                    setSelecteds={setSelectedStatus}
                                    singleSelect={true}
                                    title={"Selecione a Account"}
                                    overrideStrings={
                                        {
                                            "noOptions": "Sem Agentes",
                                            "search": "Pesquisar Agente",
                                            "selectAll": "Selecionar todas os Agente",
                                            "selectAllFiltered": "Selecionar todos os Agentes (Filtrado)",
                                            "selectSomeItems": "Selecione o status da empresa",
                                            "create": "Criar",
                                        }
                                    }
                                    hasCreateItem={false}
                                    hasNullOption={false}
                                    // filtering={false}
                                    hasAllOption={false}
                                    disabled={!isEditAllowed}
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Label htmlFor="qtdAgentes" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Quantidade de agentes
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        className="form-control-Default"
                                        isInvalid={showfeedbackAgents}
                                        placeholder="Informe a quantidade de agentes"
                                        aria-label="Informe a quantidade de agentes"
                                        aria-describedby="Informe a quantidade de agentes"
                                        size="lg"
                                        type="number"
                                        id="qtdAgentes"
                                        name="qtdAgentes"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.qtdAgentes}
                                        disabled={!isEditAllowed}
                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid" id="qtdAgentes">
                                        {errors['qtdAgentes']}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '2rem' }}>
                            <Col md={6}>
                                <Form.Label htmlFor="customFildsTicket" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Campos customizados de ticket
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        className="form-control-Default"
                                        isInvalid={showfeedbackTicket}
                                        placeholder="Informe a quantidade de campos customizados do ticket"
                                        aria-label="Informe a quantidade de campos customizados do ticket"
                                        aria-describedby="Informe a quantidade de campos customizados do ticket"
                                        size="lg"
                                        type="number"
                                        id="customFildsTicket"
                                        name="customFildsTicket"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.customFildsTicket}
                                        disabled={!isEditAllowed}
                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackticket">
                                        {errors['customFildsTicket']}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <Form.Label htmlFor="customFildsConsumer" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Campos customizados de consumidor
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        className="form-control-Default"
                                        isInvalid={showfeedbackConsumers}
                                        placeholder="Informe a quantidade de campos customizados do consumidor"
                                        aria-label="Informe a quantidade de campos customizados do consumidor"
                                        aria-describedby="Informe a quantidade de campos customizados do consumidor"
                                        size="lg"
                                        type="number"
                                        id="customFildsConsumer"
                                        name="customFildsConsumer"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.customFildsConsumer}
                                        disabled={!isEditAllowed}
                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackConsumer">
                                        {errors['customFildsConsumer']}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                            <Col style={{
                                textAlign: 'left',
                                font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#0C2461',
                                opacity: '1',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                                md={5}
                                className="mt-2"
                            >
                                <div style={{ paddingRight: "20px", color: "black" }}>
                                    Canais
                                </div>
                            </Col>
                        </Row>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
                        <Row style={{ marginTop: '1rem', marginBottom: '4rem' }}>
                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickEmail(!clickEmail) : undefined} style={{
                                        color: clickEmail ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickEmail ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Email
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickFacebook(!clickFacebook) : undefined} style={{
                                        color: clickFacebook ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickFacebook ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Facebook
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickInstagram(!clickInstagram) : undefined} style={{
                                        color: clickInstagram ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickInstagram ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Instagram
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickWhatsApp(!clickWhatsApp) : undefined} style={{
                                        color: clickWhatsApp ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickWhatsApp ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        WhatsApp
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickTelefonia(!clickTelefonia) : undefined} style={{
                                        color: clickTelefonia ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickTelefonia ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Telefonia
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickWebchat(!clickWebchat) : undefined} style={{
                                        color: clickTelefonia ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickWebchat ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Webchat
                                    </div>
                                </>
                            </Col>
                        </Row>
                        {
                            isEditAllowed &&
                            <Row className="d-flex justify-content-start mt-4">
                                <Col xs={3} className="d-flex justify-content-start">
                                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} disabled={!isEditAllowed}>Salvar</Button>
                                </Col>
                            </Row>
                        }
                    </Form>
                )}
            </Formik >
        </>
    )
}

export default FormConfigContract