import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";

import styles from './styles';
import { Formik } from "formik";

const AppAuthTab = ({ 
  t, 
  employeeHasAuthApp, 
  revokeCode,
  validate2FA,
  verifyCode,
  isLoadingQR,
  onLoadQR,
  imageQR,
  secret,
  showFeedback2FA,
}) => {

  return (
    <>
      <Row className="" style={{ marginTop: '1vh', padding: 0 }}>
        <Col>
          <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '2vh', marginBottom: '1vh' }}>
            {t('configurations.security.tab_app.content_title')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '740px', marginTop: '-0.5vh' }}>
          <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>
            {t('configurations.security.tab_app.content_description')}
          </p>
        </Col>
      </Row>
      <Row>
        { employeeHasAuthApp ? 
          <div style={styles.divStep}>
            <button style={styles.buttonbRemove} onClick={revokeCode}>{t('configurations.security.tab_app.button_remove')}</button>
          </div>
          :
          <Formik
            initialValues={{ code_2fa: '' }}
            validate={values => {
              validate2FA(values)
            }}
            onSubmit={verifyCode}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={false}>
                <Row style={{ height: 'fit-content' }} className=" d-flex my-4">
                  <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                    <Row className="mb-2 mx-2">
                    <div style={styles.divStep}>
                      <span style={styles.spanStep}>{t('configurations.security.tab_app.step1')}</span>
                    </div>
                    <div style={styles.divStep}>
                      <span style={styles.spanStep}>{t('configurations.security.tab_app.step2')}</span> 
                      { isLoadingQR && <img style={styles.imgStyle} src="/assets/chart-loading.png" alt="qr code" /> }
                      <img style={{...styles.imgStyle, display: isLoadingQR ? 'none' : 'block'}} src={imageQR} alt="qr code" onLoad={onLoadQR}/>
                      <span style={styles.spanSecret}>Código: {secret}</span>
                    </div>
                    <div style={styles.divStep}>
                      <span style={styles.spanStep}>{t('configurations.security.tab_app.step3')}</span>
                    </div>
                    </Row>
                    <Row style={styles.textFontGlay} className="mx-2">
                      <InputGroup className="mb-3" style={{ position: 'relative' }}>
                        <FormControl className="form-control-Default"
                          style={styles.inputCode}
                          isInvalid={showFeedback2FA}
                          placeholder={t('login.placeholder_2fa_app')}
                          aria-label={t('login.placeholder_2fa_app')}
                          aria-describedby={t('login.placeholder_2fa_app')}
                          size="lg"
                          type="text"
                          id="user_code_2fa"
                          name="code_2fa"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code_2fa || ''}
                        >
                        </FormControl>
                        <Form.Control.Feedback type="invalid" id="feedback_code_2fa" style={styles.errorCode}>
                          {errors.code_2fa}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Row>
                    <Row className="mb-4 mt-3 mx-3">
                      <Button type="submit" style={styles.buttonSave} className="buttonBlue" disabled={showFeedback2FA || !values.code_2fa}>
                        {t('configurations.security.tab_app.button_save')}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        }
      </Row>
    </>
  );

}

export default AppAuthTab;
