import { IconX, IconEye, IconEyeClosed } from '@tabler/icons-react';

import './styles.scss';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useContext, useState } from "react";
import { CallcenterContext } from "../../../../../../core/context/callcenter-context";
import { useTranslation } from 'react-i18next';

const Login = () => {
  const callcenterCtx = useContext(CallcenterContext);

  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState<any>()
  
  const {t} = useTranslation()

  const getMicrophonePermission = async () => {
    if("MediaRecorder" in window) {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      alert ("The MediaRecorder API is not suported in your browser.")
    }
  }

  const loginExtension = () => {
    getMicrophonePermission()
    callcenterCtx?.instance?.register();
  }

  const closeLoginForm = () => {
    callcenterCtx.setShowPhoneCallcenter(false);
  }

  return (
    <div className="phoneCallcenterLogin" id="callcenter-content-component">
      <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.login_form.close')}</Tooltip>}>
        <span className="phoneCallcenterLogin-close" onClick={closeLoginForm}>
          <IconX size={18} color="#707070" stroke={2} />
        </span>
      </OverlayTrigger>
      <span className="loginInfo">
        {t('phone_callcenter.login_form.not_connected')}
      </span>
      <div className="formInfo">
        <div className="item">
          <span>{t('phone_callcenter.login_form.terminal')}</span>
          <input className='form-control' disabled={true} value={callcenterCtx.sessionCredentials.username} />
        </div>
        <div className="item">
          <span>{t('phone_callcenter.login_form.password')}</span>
          <div className="input">
            {
              showPassword ? 
              <input readOnly={true} className="form-control" type="text" disabled={true} value={callcenterCtx.sessionCredentials.password}></input>
              :
              <input readOnly={true} className="form-control" type="password" disabled={true} value={callcenterCtx.sessionCredentials.password}></input>
            }
            {
              showPassword ? 
              <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.login_form.hide_password')}</Tooltip>}>
                <span className="inputIconEye" onClick={() => setShowPassword(false)}><IconEyeClosed size={22} color="#707070" /></span>
              </OverlayTrigger>
              :
              <OverlayTrigger placement="top" overlay={<Tooltip>{t('phone_callcenter.login_form.show_password')}</Tooltip>}>
                <span className="inputIconEye" onClick={() => setShowPassword(true)}><IconEye size={22} color="#707070" /></span>
              </OverlayTrigger>
            }
          </div>
        </div>
        <button onClick={loginExtension} className="buttonBlue btn btn-primary">{t('phone_callcenter.login_form.connect')}</button>
      </div>
    </div>
  );
}

export default Login;