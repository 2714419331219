import { useEffect, useState } from "react"
import HomeClients from "."
import { useSelector } from "react-redux";
import { getUserControlPanelPermissions } from "../../../store/user_controlpanel";
import constsApi from "../../../services/constsApi";

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const HomeClientsController = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentParams, setCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentState, setCurrentState] = useState("")
    const [defaultSearching, setDefaultSearching] = useState(false);
    const [filledCreatingTicket, setFilledCreatingTicket] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);

    const userPermissions: {id: string, created_at: string}[] = useSelector(getUserControlPanelPermissions);

    useEffect(() => {
        setHasPermission(false);
        if (userPermissions) {
            if (userPermissions.length > 0) {
                setHasPermission(true);
            } else {
                setHasPermission(false);
            }
        }
    }, []);

    const search = (e: Object, auxObj: Object, action: string) => {
        setCurrentPage(1);
        setAux([])

        auxObj = {}

        if (action === "clean") {
            setCurrentFilterSearch("")
        } else if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }

        setCurrentState("searching");
        if (currentState === "searching") {
            setCurrentState("");
        }

        setCurrentParams(auxObj);
    }

    const filteringTickets = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj: Object, action: string) => {
        setCurrentPage(1)
        setAux([])
        if (document.getElementById("listOfTickets") !== null) {
            document.getElementById("listOfTickets").scrollTop = 0
        }

        let name = ""
        if (e.currentTarget) {
            name = e.currentTarget.name
        } else if (e.target) {
            name = e.target.name
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("")
            auxObj = {}
            setCurrentState("searching")
            if(currentState === "searching") {
                setCurrentState("")
            }
        } else if (name === "searchButtonName" || name === "searchButtonName") {
            auxObj = {}

            setCurrentState("searching")
            if (currentState === "searching") {
                setCurrentState("")
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch
            }
        } else {
            setCurrentState("filtering")

            if (currentState === "filtering") {
                setCurrentState("")
            }
        }

        setCurrentParams(auxObj)
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    return (
        <HomeClients
            isLoading={isLoading}
            search={search}
            setCurrentFilterSearch={setCurrentFilterSearch}
            currentFilterSearch={currentFilterSearch}
            filledCreatingTicket={filledCreatingTicket}
            setCurrentState={setCurrentState}
            currentState={currentState}
            setCurrentPage={setCurrentPage}
            setAux={setAux}
            setCurrentParams={setCurrentParams}
            filteringTickets={filteringTickets}
            cleanList={cleanList}
            currentParams={currentParams}
            currentPage={currentPage}
            aux={aux}
            defaultSearching={defaultSearching}
            hasPermission={hasPermission}
        />
    )
}

export default HomeClientsController