import { FC, useEffect, useState } from "react"
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController"
import UserService from "../../../../services/user-service"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import ModalRanking from "."
import { 
    AmountOfReciewsObject, 
    ModalRankingControllerInterface, 
    AmountOfReciews } from "./indexModel"
import { setShowAlertFeedback } from "../../../../store/internal"
import { useTranslation } from "react-i18next"

const AppRequesterConst = new AppRequesterController()

const ModalRankingController:FC <ModalRankingControllerInterface> = (props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [dataInfo, setDataInfo] = useState<AmountOfReciews>()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(props?.currentAgentId) {
            amountOfReviews()
        }
    }, [props.currentAgentId])
    

    const amountOfReviews = async () => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            `/metrics/employee/rating/percentage/${props.currentAgentId}`, {headers, params: props.information},
            (response: Object) => {},
            (data: AmountOfReciewsObject) => {
                setDataInfo(data?.data)
            },
            (error: { response: { status: number } }) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
                if (error.response !== undefined) {
                    if (error.response.status === 400) {

                    }
                    if (error.response.status === 401) {

                    }
                }
                setIsLoading(false)
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    return (
        <ModalRanking
            t={t}
            show={props.show}
            onHide={props.onHide}
            dataInfo={dataInfo}
        />
    )

}

export default ModalRankingController