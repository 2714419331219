import { Outlet } from 'react-router-dom';
import SidebarPanelController from './components/sidebarComponent/indexController';

import './styles.scss';

const HomeControlPanel = () => {
  return (
    <div className='homeControlPanel' style={{ display: "flex" }}>
      <SidebarPanelController />
      <Outlet/>
    </div>
  );
}

export default HomeControlPanel
