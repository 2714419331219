import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneCallcenterController from '../../indexController';
import './styles.scss';
import { PhoneButtonInterface } from './indexModel';

const PhoneButton: FC<PhoneButtonInterface> = (props) => {
  
  return (
    <div className="phoneButton">
      <PhoneCallcenterController show={props.callcenterCtx.showPhoneCallcenter} />
      <OverlayTrigger placement="left" overlay={<Tooltip>{props.getTooltip()}</Tooltip>}>
        <span
          onClick={props.openPhoneCallcenter}
          className={props.getPhoneClass()}>
            {props.getPhoneIcon()}
        </span>
      </OverlayTrigger>
    </div>
  );
}

export default PhoneButton;