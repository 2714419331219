import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

export interface TicketNotification {
    field_ticket_id: string;
    sender_id: string;
    ticket_id: string;
    trigger_type: string;
    user_id: string
}

const useTicketNotificationSocket = () => {

    const socket = useContext(SocketContext)

    const ticket_notification = new BroadcastChannel('ticket_notification')

    useEffect(() => {
        socket.socket?.on('ticket-notification', (data: TicketNotification) => ticket_notification.postMessage(data.user_id))

        return () => {
            socket.socket?.off('ticket-notification', () => { })
        }
    }, [socket.socket?.active])

    return { ticket_notification }
}

export default useTicketNotificationSocket