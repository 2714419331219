import { Button, Col, FormControl, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import allStyles from './styles'

const ModalWithInput = ({ title, information, show, onHide, copyTextToClipboard, buttonCopyUrl }: { title?: string, information?: string, show: boolean, onHide: () => void, copyTextToClipboard: Function, buttonCopyUrl: boolean }) => {

    return (
        <Modal show={show} onHide={onHide} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title style={allStyles.textBlack}>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={allStyles.textFontGlay}>
                    <Col md={11} style={{  }}>
                        <InputGroup className="mb-3">
                            <FormControl
                                style={{ fontSize: "12px" }}
                                disabled={true}
                                placeholder=" "
                                aria-label=" "
                                aria-describedby=" "
                                size="lg"
                                type="text"
                                id="linkAtivation"
                                name="linkAtivation"
                                value={information}
                            >
                            </FormControl>
                        </InputGroup>
                    </Col>
                    <Col md={1} style={{ margin: "0px", padding: "0px" }}>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {buttonCopyUrl === false ? 
                                        <>Copiar link</>
                                        :
                                        <>Copiado</>
                                    }
                                </Tooltip>
                            }
                        >
                            <Button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => { copyTextToClipboard(information) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                </svg>
                            </Button>

                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button size='lg' type="submit" className="buttonBlue" style={{ width: '30%' }} onClick={() => onHide()}>Ok</Button>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalWithInput