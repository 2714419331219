import { t } from 'i18next';
import React from 'react'
import { ListGroup, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import InfiniteScroll from 'react-infinite-scroll-component'

const ListWebchat = ({
    datas,
    copyToClipboard,
    updateListFunction,
    HasMoreInformation }) => {

    return (
        <>
            <div className="ps-0 ms-0" style={{ marginTop: '2vh', marginLeft: '2vh' }}>
                <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                        {datas !== undefined && datas.length > 0 ?
                            <>
                                <Row name="listOfWebchatsHead">
                                    <Col md={6} className="nopadding">
                                        <span className="me-1">{t("home_channels.lists_channels.list_webchat.available_routes")}</span>
                                    </Col>
                                    <Col md={2} className="nopadding">
                                        <span className="me-1">{t("home_channels.lists_channels.list_webchat.token_cxpress")}</span>
                                    </Col>
                                </Row>

                                <div id="listOfWebchats" style={{ overflow: 'auto', height: '50vh' }}>
                                    <InfiniteScroll
                                        dataLength={datas.length}
                                        next={updateListFunction}
                                        hasMore={HasMoreInformation as boolean}
                                        loader={<h4> </h4>}
                                        scrollableTarget={"listOfWebchats"}
                                    >
                                        <ListGroup variant="flush" className="TicketsRow">
                                            {datas.map(data => (
                                                <ListGroup.Item
                                                    style={{ color: '#707070' }}
                                                    id={data.id}
                                                    key={"keyAll" + data.id.toString()}
                                                >
                                                    <Row className="TicketsRowHeight">
                                                        <Col md={6} className="align-self-center nopadding">
                                                            <span>
                                                                <p>{data.url_send_message_cxpress}</p>
                                                            </span>
                                                        </Col>
                                                        <Col md={2} className="d-flex align-items-center nopadding">
                                                            <span className="mr-2"> {/* Adicionei a classe "mr-2" para adicionar um espaço entre o texto e o botão */}
                                                                <p className='oneLineClampEllipsis' style={{ marginLeft: '0.6vh' }}> &#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;</p>
                                                            </span>
                                                            <Button onClick={() => copyToClipboard(data.auth_token_cxpress)} style={{ background: 'transparent', border: 'none', padding: '0px', alignItems: 'center', marginTop: '-1rem' }}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltipToken'>
                                                                            {t("home_channels.lists_channels.list_webchat.copy_token")}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2C4CA2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                                                                        <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </InfiniteScroll>
                                </div>
                            </>
                            :

                            <div className='align-self-center' style={{ overflow: 'hidden', height: '42vh', backgroundColor: '#F9F9F9' }}>
                                <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                    <Col md={1} className="d-flex justify-content-center">
                                        <svg id="Grupo_12897" data-name="Grupo 12897" xmlns="http://www.w3.org/2000/svg" width={80} height={80} viewBox="0 0 17.974 17.974">
                                            <path id="Caminho_10311" data-name="Caminho 10311" d="M0,0H17.974V17.974H0Z" fill="none" />
                                            <path id="Caminho_10312" data-name="Caminho 10312" d="M18.238,11.238,15.991,8.991H10.749A.749.749,0,0,1,10,8.242V3.749A.749.749,0,0,1,10.749,3h6.74a.749.749,0,0,1,.749.749v7.489" transform="translate(-2.511 -0.753)" fill="none" stroke='#4a69bd' strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                            <path id="Caminho_10313" data-name="Caminho 10313" d="M11.238,13.745v1.5a.749.749,0,0,1-.749.749H5.247L3,18.238V10.749A.749.749,0,0,1,3.749,10h1.5" transform="translate(-0.753 -2.511)" fill="none" stroke='#4a69bd' strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                        </svg>
                                    </Col>
                                </Row>
                                <Row className='d-flex justify-content-center align-content-center mt-2' style={{ margin: 'auto', backgroundColor: '#F9F9F9' }}>
                                    <Col md={4} className="d-flex justify-content-center align-content-center">
                                        <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("home_channels.lists_channels.no_results")}</p>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </ListGroup.Item>
                </ListGroup>
            </div >
        </>
    );

}

export default ListWebchat