import { useNavigate } from "react-router-dom";
import Page404 from ".";
import { TokenInterface } from "../../services/requestsInterfacesModel";
import { useSelector } from "react-redux";
import { getToken } from "../../store/token";
import { useEffect } from "react";
import constsRouters from "../../routes/constsRouter";
import { useTranslation } from "react-i18next";

const Page404Controller = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }
    };

    useEffect(() => {
        if (!values.token.value) {
            navigate(constsRouters.routers.home.path);
        }

        // ocultando sidebar
        document.getElementById("app-sidebar").style.display = "none";
    });
    
    return <Page404 values={values} t={t} />
}

export default Page404Controller;