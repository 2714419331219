import React, { FC, useEffect, useState } from 'react';
import { Button, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getIdUser, getNameUser, getProfileId } from '../../../../../store/user';

import { getShowEditDepartmentModal, setShowAlertFeedback, setShowConfirmationModal, setShowEditDepartmentModal } from '../../../../../store/internal';
import constsRouters from '../../../../../routes/constsRouter';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { getToken } from '../../../../../store/token';

import Utils from '../../../../../core/shared/utils';
import UserService from '../../../../../services/user-service';

import { ListOfDepartmentsInterface } from './indexModel';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
import { verifyCode } from '../../../../../services/codeCxpressInterface';
import { useTranslation } from 'react-i18next';
import ListOfDepartments from '.';

const ListOfDepartmentsController: FC<ListOfDepartmentsInterface> = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appRequester = new AppRequesterController();

    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditDepartmentModal: useSelector(getShowEditDepartmentModal),
        }
    }

    const [currentDepartmentData, setCurrentDepartmentData] = useState({ sector_id: "N/A", emails: [], keywords: [], active: null, sector_name: "N/A", agents: [], keyword: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [departmentData, setDepartmentData] = useState(null)

    useEffect(() => {
        Utils.changeActiveScrollBar("ListOfDepartments");
    }, [props.datas]);

    useEffect(() => {
        if (currentDepartmentData.sector_id !== 'N/A') {
            getDepartmentBySectorId();
        } 
    }, [currentDepartmentData]);

    // fechando modais abertos ao carregar a página
    useEffect(() => {
        if (values.internal.getShowEditDepartmentModal) {
            dispatch(setShowEditDepartmentModal(false));
        }
    }, []);

    const enableDepartmentConfirmation = async () => {

        const headers = UserService.getHeaders()

        let JsonSend = {
            "id": currentDepartmentData.sector_id,
            "name": currentDepartmentData.sector_name,
            "active": true
        }

        if (departmentData?.emails?.length > 0 ) {
            JsonSend["emails_id"] = departmentData.emails.map((email) => { return email.id; });
        }

        if (departmentData?.agents?.length > 0) {
            JsonSend["employees_user_ids"] = departmentData.agents.map((agent) => { return agent.user_id });
        }

        if (departmentData?.keyword?.length > 0) {
            JsonSend["key_words"] = departmentData.keyword;
        }

        await appRequester.Put(
            '/sector', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_departments.list_of_departments.activation_success'), visibility: true, signalIcon: true }));
                props.updateListAfterUpdateDepartment();
            },
            (error: { response: { status: number; data: { code_cxpress: number } }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                // if (error.response !== undefined) {
                //     if (error.response.status === 400) {
                //         //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedback(true);
                //     }
                //     if (error.response.status === 401) {
                //         //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedbackPassword(true);
                //     }
                // }
            }, navigate, dispatch, setIsLoading
        );
    }

    const disableDepartmentConfirmation = async () => {

        const headers = UserService.getHeaders()

        let JsonSend = {
            "id": currentDepartmentData.sector_id,
            "name": currentDepartmentData.sector_name,
            "active": false
        }

        if (departmentData?.emails?.length > 0 ) {
            JsonSend["emails_id"] = departmentData.emails.map((email) => { return email.id; });
        }

        if (departmentData?.agents?.length > 0) {
            JsonSend["employees_user_ids"] = departmentData.agents.map((agent) => { return agent.user_id });
        }

        if (departmentData?.keyword?.length > 0) {
            JsonSend["key_words"] = departmentData.keyword;
        }

        await appRequester.Put(
            '/sector', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_departments.list_of_departments.inactivation_success'), visibility: true, signalIcon: true }));
                props.updateListAfterUpdateDepartment();
            },
            (error: { response: { status: number; data: { code_cxpress: number } }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                // if (error.response !== undefined) {
                //     if (error.response.status === 400) {
                //         //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedback(true);
                //     }
                //     if (error.response.status === 401) {
                //         //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedbackPassword(true);
                //     }
                // }
            }, navigate, dispatch, setIsLoading
        );
    }

    const getDepartmentBySectorId = async () => {
        const headers = UserService.getHeaders()

        await appRequester.Get(
            `/sector/${currentDepartmentData.sector_id}`, { headers },
            (response: Object) => {

            },
            (data: { data: { code_cxpress: number, sectors: Array<Object> } }) => {
                setDepartmentData(data.data.sectors[0])
            },
            (error: { response: { status: number; data: { code_cxpress: number } } }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error?.response?.data?.code_cxpress, t), visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading, {  }
        )
    }

    const popoverActions = (
        <Popover id={currentDepartmentData.sector_id} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={() => showEditDepartmentModal()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.list_of_departments.edit_department')}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                {currentDepartmentData.active && currentDepartmentData.active === true ?
                    <Button onClick={() => disableDepartment()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.list_of_departments.inactivate_department')}</Button> :
                    <Button onClick={() => enableDepartment()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.list_of_departments.activate_department')}</Button>
                }
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={(e) => viewDepartmentPage(e)} name="ButtonViewRelatedTickets" className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.list_of_departments.view_associated_tickets')}</Button>
            </Popover.Body>
        </Popover>
    );

    const showEditDepartmentModal = () => {
        dispatch(setShowEditDepartmentModal(true));
    }

    const enableDepartment = () => {
        dispatch(setShowConfirmationModal({ text: { title: t('home_departments.list_of_departments.activate_department'), body: t('home_departments.list_of_departments.activate_question'), id: currentDepartmentData.sector_name + "?", buttonConfirmationText: t('home_departments.list_of_departments.activate'), buttonReturnText: t('home_departments.list_of_departments.cancel') }, visibility: true, functionConfirmation: enableDepartmentConfirmation }));
    }

    const disableDepartment = () => {
        dispatch(setShowConfirmationModal({ text: { title: t('home_departments.list_of_departments.inactivate_department'), body: t('home_departments.list_of_departments.inactivate_question'), id: currentDepartmentData.sector_name + "?", buttonConfirmationText: t('home_departments.list_of_departments.inactivate'), buttonReturnText: t('home_departments.list_of_departments.cancel') }, visibility: true, functionConfirmation: disableDepartmentConfirmation }));
    }

    const viewDepartmentPage = (e: any) => {
        if (e.target && e.target.name === "ButtonViewRelatedTickets") {
            navigate(constsRouters.routers.configurationDepartmentsView.path, { state: { id: currentDepartmentData.sector_id, name: currentDepartmentData.sector_name, active: currentDepartmentData.active } });
        } else {
            navigate(constsRouters.routers.configurationDepartmentsView.path, { state: { id: e.currentTarget.id.split("*ID*")[1], name: e.currentTarget.id.split("*ID*")[0], active: e.currentTarget.id.split("*ID*")[2] === "true" ? true : false } });
        }
    }

    const setCurrentDepartmentInfo = (e: { currentTarget: { id: string; }; }) => {
        //setCurrentDepartmentId(e.currentTarget.id);
        //setCurrentListedAgentIsOwner(e.currentTarget.id.split("*ID*")[2]);
        return handleSetCurrentDepartmentData(e.currentTarget.id.split("*ID*")[1]);
    }

    const handleSetCurrentDepartmentData = (selectedAgentId: string) => {
        let dataAgent = props.datas.filter((item: { sector_id: string; }) => item.sector_id === selectedAgentId)[0];
        setCurrentDepartmentData(dataAgent);
    }

    return <ListOfDepartments
        t={t}
        props={props}
        currentDepartmentData={currentDepartmentData}
        viewDepartmentPage={viewDepartmentPage}
        setCurrentDepartmentInfo={setCurrentDepartmentInfo}
        departmentData={departmentData}
        popoverActions={popoverActions}
        values={values}
        isLoading={isLoading}
    />

}

export default ListOfDepartmentsController;