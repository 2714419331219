type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

const styles = StyleSheet.create({
  bodyStyle: {
    backgroundColor: '#E8E8E8',
    height: '100vh',
    width: '100vw',
  },
  colStyle: {
    backgroundColor: 'white',
    //height: '32vh'
    
  },
  textFontBlack: {
    textAlign: 'left',
    font: 'var(--px20_24_Bold-font)',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1'

  },
  textFontBlack2: {
    textAlign: 'left',
    font: 'var(--px15_20_Bold-font)',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1'

  },
  buttonStyle: {
    width: '100%',
    height: '6vh',
    background: '#FF8119 0% 0% no-repeat padding-box',
    borderRadius: '6px',
    opacity: '1',
    font: 'var(--px20_24_Bold-font)',
    color: 'white',
    borderColor: 'transparent',
  },
});

export default styles;






