import { Button, OverlayTrigger, Row, Tooltip, Container } from "react-bootstrap"
import QuickMessageRegisterController from "../../../components/quickMessageRegister/indexController"
import ListOfQuickMessageController from "../listOfQuickMessage/indexController"
import Loading from "../../../../../components/loading"

const HomeQuickMessage = ({
    isLoading,
    showQuickMessageModalRegister,
    handleCloseQuickMessageModalRegister,
    handleShowQuickMessageModalRegister,
    listWhatsapp,
    aux,
    updateListFunction,
    updateListAfterDelete,
    callBackModalQuickMessage,
    t,
}) => {    
    
    return (
        <Container fluid className="noppading">  
            <Loading visibility={isLoading}/>
            <QuickMessageRegisterController modalShow={showQuickMessageModalRegister} onHide={handleCloseQuickMessageModalRegister} callback={callBackModalQuickMessage} listWhatsapp={listWhatsapp} />
            <Row>
                <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh', marginBottom: '1vh' }}>
                    {t('configurations_ticket.quick_messages.title')}
                    {
                        <OverlayTrigger
                            key='top'
                            placement="top"
                            overlay={
                                <Tooltip id="tootip" className="tooltip-arrow-Top">
                                    {t('configurations_ticket.quick_messages.new_quick_message')}
                                </Tooltip>
                            }
                        >
                            <Button className="mx-3 nopadding buttonTicketsSec" onClick={handleShowQuickMessageModalRegister}>
                                <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                    <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                    <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>
                            </Button>
                        </OverlayTrigger>
                    }
                </p>
            </Row>
            <Row>
                <ListOfQuickMessageController data={aux} currentPage={updateListFunction} updateListAfterDelete={updateListAfterDelete} listWhatsapp={listWhatsapp} callBackModalQuickMessage={callBackModalQuickMessage} />
            </Row>
        </Container>
    )
}

export default HomeQuickMessage