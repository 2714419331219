import Loading from '../../../../../../components/loading';
import './styles.scss';
import { CallcenterContext } from '../../../../../../core/context/callcenter-context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const ConnectLoading = () => {
  const context = useContext(CallcenterContext);
  const {t} = useTranslation()

  return (
    <div className="phoneCallcenterLoading">
      <span>{t('phone_callcenter.connect_loading.connection_to_the_terminal')}</span>
      <span className="extension">{context.sessionCredentials.username}</span>
      <Loading visibility={true} noBootstrap={true} customStyle={{ position: 'relative' }} />
    </div>
  );
}

export default ConnectLoading;
