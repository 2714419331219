import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setShowAlertFeedback, setShowRegisterTagsModal } from "../../../../store/internal";
import ModalRegisterTags from ".";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";

const ModalRegisterTagsController = (props) => {

    const AppRequesterConst = new AppRequesterController();
    const [isLoading, setIsLoading] = useState(false)
    const [tagName, setTagName] = useState(null as string || null);
    const [tagIsActive, setTagIsActive] = useState(true);

    const [showFeedbackName, setShowFeedbackName] = useState(false);

    const [modalShow, setModalShow] = useState(props.visibility);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setModalShow(props.visibility);
        setTagIsActive(true);
    }, [props.visibility]);


    const Hide = () => {
        setShowFeedbackName(false);
        dispatch(setShowRegisterTagsModal(false));
        props?.setConsumer(false)
    }


    return <ModalRegisterTags
        t={t}
        modalShow={modalShow}
        Hide={Hide}
        setTagName={setTagName}
        setShowFeedbackName={setShowFeedbackName}
        tagName={tagName}
        tagIsActive={tagIsActive}
        token={props.token}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        setShowAlertFeedback={setShowAlertFeedback}
        callback={props.callback}
        navigate={navigate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setTagIsActive={setTagIsActive}
        showFeedbackName={showFeedbackName}
        consumer={props?.consumer}
    />

}

export default ModalRegisterTagsController