import React, { FC, useState } from "react";
import { ICreateIntegrationModal } from "./indexModel";
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import allStyles from './styles';
import { Formik } from "formik";
import { setShowCreateParamModal } from "../../../../../../store/internal";
import IntegrationParamsComponentController from "../integrationParamsComponent/indexController";
import CreateParamAuxiliarModalController from "../createParamAuxiliarModal/indexController";
import Loading from "../../../../../../components/loading";
import SelectComponentController from "../../../../components/selectComponent/indexController";

const CreateIntegrationModal: FC<ICreateIntegrationModal> = (props) => {
    return (
        <Modal className="fade" show={props.getShowCreateIntegrationModal} /* show={useSelector(getShowRegisterIntegrationModal)}  */onHide={() => { props.hide(); }} aria-labelledby="" size="lg" centered={true} >
          <Loading visibility={props.isLoading} />
          {props.getShowCreateParamModal && props.getShowCreateIntegrationModal ?
            // <CreateParamModal />:
            <CreateParamAuxiliarModalController integrationParams={props.integrationParams} setIntegrationParams={props.setIntegrationParams} hideCreateParamModal={props.hide} />:
            <>
              <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
                <Modal.Title style={allStyles.textBlack}>
                {props.t('home_departments.integration_tab.create_integration.create_integration')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ marginLeft: "6%", marginRight: "4%", marginBottom: "2%" }}>
                <Container className="nopadding">
                  <Formik
                  initialValues={{ name: props.name, url: props.url, instructions: props.instructions, token: props.token }}
                    validate={values => {
                      return props.validate(values);
                    }}
        
                    onSubmit={(values) => props.submit(values)}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit} noValidate validated={false}>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                          <Col sm={true}>
                          <Form.Label htmlFor="name">{props.t('home_departments.integration_tab.create_integration.form_name.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                            <InputGroup className="mb-3">
                              <FormControl className="form-control-Default"
                                isInvalid={props.showFeedbackName}
                              placeholder={props.t('home_departments.integration_tab.create_integration.form_name.placeholder')}
                              aria-label={props.t('home_departments.integration_tab.create_integration.form_name.placeholder')}
                              aria-describedby={props.t('home_departments.integration_tab.create_integration.form_name.placeholder')}
                                size='lg'
                                type='name'
                                id="name"
                                name="name"
                                autoComplete='given-name'
                                maxLength={100}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              >
                              </FormControl>
                              <Form.Control.Feedback type="invalid" id="feedbackname">
                                {errors.name}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                          <Col sm={true}>
                          <Form.Label htmlFor="basic-url">{props.t('home_departments.integration_tab.create_integration.form_chatbots.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                            {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                            <div className="nopadding">
                              <SelectComponentController
                                  // className={"mb-3"}
                                  options={props.optionsChatbots}
                                  setOptions={props.setOptionsChatbots}
                                  selecteds={props.selectedsChatbots}
                                  setSelecteds={props.setSelectedsChatbots}
                                  singleSelect={false}
                                  isFormNoFilter={true}
                                  title={props.t('home_departments.integration_tab.create_integration.form_chatbots.placeholder')}
                                  overrideStrings={
                                    {
                                      "allItemsAreSelected": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.allItemsAreSelected'),
                                      "clearSearch": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.clearSearch'),
                                      "clearSelected": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.clearSelected'),
                                      "noOptions": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.noOptions'),
                                      "search": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.search'),
                                      "selectAll": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.selectAll'),
                                      "selectAllFiltered": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.selectAllFiltered'),
                                      "selectSomeItems": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.selectSomeItems'),
                                      "create": props.t('home_departments.integration_tab.create_integration.form_chatbots.override_strings.create'),
                                    }
                                  }
                                  hasCreateItem={false}
                                  hasNullOption={false}
                                  hasAllOption={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                        <Form.Label htmlFor="name">{props.t('home_departments.integration_tab.create_integration.form_url.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                            <InputGroup className="mb-3">
                              <FormControl className="form-control-Default"
                                isInvalid={props.showFeedbackUrl}
                            placeholder={props.t('home_departments.integration_tab.create_integration.form_url.placeholder')}
                            aria-label={props.t('home_departments.integration_tab.create_integration.form_url.placeholder')}
                            aria-describedby={props.t('home_departments.integration_tab.create_integration.form_url.placeholder')}
                                size='lg'
                                type='url'
                                id="url"
                                name="url"
                                // autoComplete='given-name'
                                // maxLength={100}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.url}
                              >
                              </FormControl>
                              <Form.Control.Feedback type="invalid" id="feedbackurl">
                                {errors.url}
                              </Form.Control.Feedback>
                            </InputGroup>
                        </Row>
                      <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                        <Form.Label htmlFor="name">{props.t('home_departments.integration_tab.create_integration.form_token.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                        <InputGroup className="mb-3">
                          <FormControl className="form-control-Default"
                            isInvalid={props.showFeedbackToken}
                            placeholder={props.t('home_departments.integration_tab.create_integration.form_token.placeholder')}
                            aria-label={props.t('home_departments.integration_tab.create_integration.form_token.placeholder')}
                            aria-describedby={props.t('home_departments.integration_tab.create_integration.form_token.placeholder')}
                            size='lg'
                            type='token'
                            id="token"
                            name="token"
                            // autoComplete='given-name'
                            // maxLength={100}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.token}
                          >
                          </FormControl>
                          <Form.Control.Feedback type="invalid" id="feedbacktoken">
                            {errors.token}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Row>

                      <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                        <Form.Label htmlFor="name">{props.t('home_departments.integration_tab.create_integration.form_instruction.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                        <InputGroup className="mb-3">
                          <FormControl className="form-control-Default"
                            isInvalid={props.showFeedbackInstructions}
                            placeholder={props.t('home_departments.integration_tab.create_integration.form_instruction.placeholder')}
                            aria-label={props.t('home_departments.integration_tab.create_integration.form_instruction.placeholder')}
                            aria-describedby={props.t('home_departments.integration_tab.create_integration.form_instruction.placeholder')}
                            size='lg'
                            type='instructions'
                            id="instructions"
                            name="instructions"
                            as="textarea"
                            style={{ maxHeight: "10vh", minHeight: "10vh", height: "10vh" }}
                            maxLength={200}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.instructions}
                            htmlSize={200}
                          >
                          </FormControl>
                          <Form.Control.Feedback type="invalid" id="feedbackurl">
                            {errors.instructions}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Row>

                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Form.Label htmlFor="name">
                          {props.t('home_departments.integration_tab.create_integration.integration_params')}
                              <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => { props.dispatch(setShowCreateParamModal(true)) }}>
                                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                        <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Button>
                            </Form.Label>
                            <InputGroup className="mb-3" style={{  }}>
                              <IntegrationParamsComponentController initParams={props.integrationParams} setInitParams={props.setIntegrationParams} />
                            </InputGroup>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                          <Form.Label htmlFor="email">{props.t('home_departments.integration_tab.create_integration.active_integration')} &nbsp; &nbsp;{/* <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span> */}</Form.Label>
                          <Form.Check name="radioIsAdministrator" label={props.t('home_departments.integration_tab.create_integration.no')} type={'radio'} checked={!props.active} onChange={() => { props.setActive(false) }} inline></Form.Check>
                          <Form.Check name="radioIsAdministrator" label={props.t('home_departments.integration_tab.create_integration.yes')} type={'radio'} checked={props.active} onChange={() => { props.setActive(true) }} inline></Form.Check>
                            </Col>
                        </Row>
                      {props.integrationParams.length === 0 &&
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                          <Col sm={true}>
                            <Form.Label htmlFor="email">{props.t('home_departments.integration_tab.create_integration.last_message_as_param')} &nbsp; &nbsp;</Form.Label>
                            <Form.Check name="radioLastMessage" label={props.t('home_departments.integration_tab.create_integration.no')} type={'radio'} checked={!props.activeMessageParam} onChange={() => { props.setActiveMessageParam(false) }} inline></Form.Check>
                            <Form.Check name="radioLastMessage" label={props.t('home_departments.integration_tab.create_integration.yes')} type={'radio'} checked={props.activeMessageParam} onChange={() => { props.setActiveMessageParam(true) }} inline></Form.Check>
                          </Col>
                        </Row>
                      }
                        <Row className="d-flex justify-content-center mt-4">
                          <Col xs={6} className="d-flex justify-content-start">
                          <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={props.hide} >{props.t('home_departments.integration_tab.create_integration.cancel')}</Button>
                          </Col>
                          <Col xs={6} className="d-flex justify-content-end">
                          <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} disabled={props.isLoading}>{props.t('home_departments.integration_tab.create_integration.save')}</Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Container>
              </Modal.Body>
            </>
          }
        </Modal>
      );
}

export default CreateIntegrationModal;