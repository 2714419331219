import { FC, useEffect, useState } from 'react';
import { ModalEditPermissionsControllerInterface } from './indexModel';
import ModalEditPermissions from '.';
import { useDispatch, useSelector } from 'react-redux';
import { setShowEditUserPermissionsModal } from '../../../../../store/internal_controlpanel';
import { getToken } from '../../../../../store/control_panel';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { useNavigate } from 'react-router-dom';
import { IconFileText, IconKey, IconUserCog, IconUserEdit, IconBuildingWarehouse, IconEye } from '@tabler/icons-react';
import constsApi from '../../../../../services/constsApi';

const AppRequesterConst = new AppRequesterController();

const ModalEditPermissionsController: FC<ModalEditPermissionsControllerInterface> = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [master, setMaster] = useState(false);
  const [contract, setContract] = useState(false);
  const [company, setCompany] = useState(false);
  const [keys, setKeys] = useState(false);
  const [agents, setAgents] = useState(false);
  const [readAll, setReadAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector(getToken);

  const formStateBase = [
    {
      label: 'Somente visualização',
      id: constsApi.permissions_controlpanel.read_all,
      value: readAll,
      set: setReadAll,
      icon: <IconEye stroke={1.5} />
    },
  ];

  const formState = [
    {
      label: 'Administrador geral',
      id: constsApi.permissions_controlpanel.master,
      value: master,
      set: setMaster,
      icon: <IconUserCog stroke={1.5} />
    },
    {
      label: 'Configurar contrato',
      id: constsApi.permissions_controlpanel.contract,
      value: contract,
      set: setContract,
      icon: <IconFileText stroke={1.5} />
    },
    {
      label: 'Configurar empresa',
      id: constsApi.permissions_controlpanel.company,
      value: company,
      set: setCompany,
      icon: <IconBuildingWarehouse stroke={1.5} />
    },
    {
      label: 'Configurar páginas/chaves',
      id: constsApi.permissions_controlpanel.keys,
      value: keys,
      set: setKeys,
      icon: <IconKey stroke={1.5} />
    },
    {
      label: 'Configurar agentes',
      id: constsApi.permissions_controlpanel.agents,
      value: agents,
      set: setAgents,
      icon: <IconUserEdit stroke={1.5} />
    },
  ];

  useEffect(() => {
    if (props.currentUser) {
      const user_permissions = props.currentUser.permissions.map(item => item.control_panel_permission_id) || [];

      formStateBase?.forEach(item => {
        if (user_permissions.includes(item.id)) {
          item.set(true);
        }
      });
      
      formState?.forEach(item => {
        if (user_permissions.includes(item.id)) {
          item.set(true);
        }
      });
    } else {
      hide();
    }
  }, []);

  const hide = () => {
    dispatch(setShowEditUserPermissionsModal(false));
  }

  const submit = async () => {
    if (props.currentUser) {
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${token}`
      };

      const permissions = readAll ? 
        formStateBase.filter(item => item.value).map(item => item.id) :
        formState.filter(item => item.value).map(item => item.id);

      const body = {
        user_id: props.currentUser.id,
        permissions,
      };

      await AppRequesterConst.Put(
        '/panel/credentials/permissions', body, { headers },
        (response: Object) => { return response; },
        (data: any) => {
          if (data.status === 200) {
            dispatch(setShowAlertFeedback({ message: `Permissões da conta ${props.currentUser.email} atualizadas`, visibility: true, signalIcon: true }));
            if (props.callback) {
              props.callback();
            }
            hide();
          }
        },
        (error: { response: { status: number; data: { message: string }; }; }) => {
          if (error.response.data.message) {
            dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
          }
          else {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
          }
        }, navigate, dispatch, setIsLoading
      );
    }
  }

  return (
    <ModalEditPermissions 
      showModal={props.showModal}
      hide={hide}
      currentUser={props.currentUser}
      formStateBase={formStateBase}
      formState={formState}
      submit={submit}
      isLoading={isLoading}
      isOnlyRead={readAll}
    />
  );

}

export default ModalEditPermissionsController;
