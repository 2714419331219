import { useTranslation } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import DataDashboard from '.';
import { useEffect, useState } from 'react';

const DataDashboardController = (props) => {

    const { t } = useTranslation();

    const [/*totalTickets*/, setTotalTickets] = useState(0);
    const [/*inProgressTickets*/, setInProgressTickets] = useState(0);
    const [/*newTickets*/, setNewTickets] = useState(0);
    const [/*totalConsumers*/, setTotalConsumers] = useState(0);

    useEffect(() => {
        setTotalTickets(props.totalTickets)
        setInProgressTickets(props.inProgressTickets)
        setNewTickets(props.newTickets)
        setTotalConsumers(props.totalConsumers)
    }, [props.totalTickets]);

    // const convertNumToTime = (num: number) => {
    //     var hour = Math.floor(num);
    //     var dec = num - hour;
    //     var min = 1 / 60;
    //     dec = min * Math.round(dec / min);

    //     var minute = Math.floor(dec * 60) + '';
    //     if (minute.length < 2) {
    //         minute = '0' + minute;
    //     }

    //     return hour + 'h' + minute
    // }


    return (
        <DataDashboard
            printPDF={props.printPDF}
            sessionBot={props.sessionBot}
            totalMessages={props.totalMessages}
            retentionRate={props.retentionRate}
            nps={props.nps}
            t={t}
        />
    );
}

export default DataDashboardController;
