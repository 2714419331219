import React from 'react';
import { Button, Col, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import Utils from '../../../../../../core/shared/utils';
import { ListName } from '../../../../../../core/enums/order-sort-by';
import ModalEditTriage from "../../../../components/modalEditTriage/indexController";
import { useSelector } from 'react-redux';
import { getShowEditTriageModal } from '../../../../../../store/internal';

const ListOfDepartments = ({
  popoverActions,
  props,
  triageList,
  callbackModalTriage,
  setCurrentTriageInfo,
  currentTriage,
  t,
}) => {
  return (
    <>
      <ModalEditTriage visibility={useSelector(getShowEditTriageModal)} callback={callbackModalTriage} data={currentTriage}></ModalEditTriage>
      <div style={{ marginTop: '2vh' }} >
        <ListGroup variant="flush">
          <ListGroup.Item className="TicketsRowCollums">
            <Row name="ListOfDepartmentsHead">
              <Col md={2} className="nopadding">
                <p>{t('home_departments.triage_tab.name')}</p>
              </Col>
              <Col md={2} className="nopadding">
                <p>{t('home_departments.triage_tab.status')}</p>
              </Col>
              {/* <Col md={2} className="nopadding">
                <ButtonOrderByList title="Nome" columnIdName='name' callbackOrderBy={props.callbackOrderByDepartments} />
              </Col>
              <Col md={2} className="nopadding">
                <ButtonOrderByList title="Status" columnIdName='active' callbackOrderBy={props.callbackOrderByDepartments} />
              </Col> */}
              <Col md={3} className="nopadding">
                <p>{t('home_departments.triage_tab.automations')}</p>
              </Col>
              <Col className='d-flex justify-content-end '>
                <p>{t('home_departments.triage_tab.actions')}</p>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        {
          triageList !== undefined ?
            <div id="ListOfDepartments" style={{ overflowY: 'auto', height: '58vh' }}>
              <InfiniteScroll
                dataLength={triageList.length}
                next={props.updateListfunction}
                hasMore={props.HasMoreInformation as boolean}
                loader={<h4> </h4>}
                scrollableTarget={"ListOfDepartments"}
                onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfDepartments) }}
              >
                {
                  <ListGroup variant="flush" className="TicketsRow" >
                    {triageList.map((item, index) => {
                      return (
                        <ListGroup.Item style={{ color: '#707070' }} id={item.name + "*ID*" + item.id} key={"keyAll" + index.toString()} >
                          <Row className='TicketsRowHeight'>
                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + "*ID*" + item.id + "*ID*" + item.active.toString()} onClick={() => { }}>
                              <div className='oneLineClampEllipsis me-3'>
                                <OverlayTrigger
                                  key='top'
                                  placement='top'
                                  overlay={
                                    <Tooltip id='tooltip'>
                                      {item.name != null ?
                                        <>{item.name}</>
                                        :
                                        <>{t('home_departments.triage_tab.na')}</>
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {item.name ? item.name : <>{t('home_departments.triage_tab.na')}</>}
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </Col>
                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + "*ID*" + item.id + "*ID*" + item.active.toString()} onClick={() => { }}>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    {item.active ?
                                      <>{t('home_departments.triage_tab.active')}</> : <>{t('home_departments.triage_tab.inactive')}</>
                                    }
                                  </Tooltip>
                                }
                              >
                                <div style={{ width: '33%' }}>
                                  {item.active ?
                                    <div className="d-flex justify-content-center py-1"
                                      style={{
                                        width: '100%',
                                        background: '#EEF3FF 0% 0% no-repeat padding-box',
                                        borderRadius: '3px',
                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                        letterSpacing: '0px',
                                        color: '#0C2461',
                                      }}>
                                      {t('home_departments.triage_tab.active')}
                                    </div> :
                                    <div className="d-flex justify-content-center py-1"
                                      style={{
                                        width: '100%',
                                        background: '#FFD4B0 0% 0% no-repeat padding-box',
                                        borderRadius: '3px',
                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                        letterSpacing: '0px',
                                        color: '#FF8119',
                                      }}>
                                      {t('home_departments.triage_tab.inactive')}
                                    </div>
                                  }
                                </div>
                              </OverlayTrigger>
                            </Col>
                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + "*ID*" + item.id + "*ID*" + item.active.toString()} onClick={() => { }}>
                              <div className='oneLineClampEllipsis'>
                                <OverlayTrigger
                                  key='top'
                                  placement='top'
                                  overlay={
                                    <Tooltip id='tooltip'>
                                      {
                                        item.channel_keys ? item.channel_keys.toString() : t('home_departments.triage_tab.na')
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {
                                      item.channel_keys ? item.channel_keys.toString() : t('home_departments.triage_tab.na')
                                    }
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </Col>
                            <Col className='d-flex justify-content-end' >
                              <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popoverActions}>
                                <Button id={item.id} name={item.name} onClick={(e) => { setCurrentTriageInfo(e) }} className='nopadding d-flex justify-content-end align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="1" />
                                    <circle cx="12" cy="19" r="1" />
                                    <circle cx="12" cy="5" r="1" />
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                        </ListGroup.Item>);
                    })}
                  </ListGroup>
                }
              </InfiniteScroll>
            </div>
            :
            <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
              <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                <Col md={1} className="d-flex justify-content-center">
                  <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                    <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                    <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </svg>
                </Col>
              </Row>
              <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                <Col md={4}>
                  <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('home_departments.triage_tab.no_results')} <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateTriage}>{t('home_departments.triage_tab.add_triage')}</Button></p>
                </Col>
              </Row>
            </div>
        }
      </div>
    </>
  );
}

export default ListOfDepartments;