import React from "react";
import { Button, Col, Container, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

const ModalConsumersSimilarData = ({
    showModal,
    hide,
    currentConsumer,
    similarConsumers,
    gotoUnifyPage,
    props,
    t
}) => {

    return (
        <Modal show={showModal} onHide={hide} aria-labelledby="" size="lg" centered={true} >
            <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%", marginRight: '3%' }}>
                <Modal.Title 
                    style={{
                        textAlign: 'left',
                        font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
                        letterSpacing: '0px',
                        color: '#1E1E1E',
                        opacity: '1',
                    }}
                >
                    <span>
                        {t('home_consumers.unification.modal_similar_data.similar_data')}
                    </span>
                    <span className="ms-2">
                        <svg id="Grupo_13657" data-name="Grupo 13657" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <path id="Caminho_9643" data-name="Caminho 9643" d="M0,0H24V24H0Z" fill="none"/>
                            <circle id="Elipse_172" data-name="Elipse 172" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                            <line id="Linha_175" data-name="Linha 175" x2="12.6" y2="12.6" transform="translate(5.7 5.7)" fill="none" stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                        </svg>
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Header className="py-0" 
                style={{ 
                    marginLeft: "6%", 
                    marginRight: '3%',
                    textAlign: 'left',
                    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#707070',
                    opacity: '1', 
                }}
            >
                <Col>
                    <Row className="nopadding">
                        <div className="nopadding">
                            {t('home_consumers.unification.modal_similar_data.alert_text')}
                        </div>
                    </Row>
                    <Row className="nopadding mt-2">
                        <div className="nopadding">
                            <b style={{ color: '#1E1E1E' }}>*</b> {t('home_consumers.unification.modal_similar_data.confirmation_text')}
                        </div>
                    </Row>
                </Col>
            </Modal.Header>
            <Modal.Header style={{ marginLeft: "6%", marginTop: "0", marginBottom: "-1%", marginRight: '3%', paddingBottom: '0' }} className="pt-4">
                <Modal.Title 
                    style={{
                        textAlign: 'left',
                        font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
                        letterSpacing: '0px',
                        color: '#1E1E1E',
                        opacity: '1',
                    }}
                >
                    <span>
                        {t('home_consumers.unification.modal_similar_data.consumers')}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "6%", marginRight: "3%", marginBottom: "2%" }}>
                <Container className="ps-0">
                    {/* <Row className="px-3 pb-3">
                        <Button
                            style={{
                                background: '#FF0000 0% 0% no-repeat padding-box',
                                border: '1px solid #FF0000',
                                borderRadius: '4px',
                                opacity: '1',
                            }}
                            onClick={(e) => gotoUnifyPage(e, props.originRedirectPage)}
                        >
                            Comparar cadastros 
                            <span className="ps-2">
                                <svg id="Grupo_13717" data-name="Grupo 13717" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Caminho_10347" data-name="Caminho 10347" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Caminho_10348" data-name="Caminho 10348" d="M12,6H6A2,2,0,0,0,4,8V18a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V12" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    <path id="Caminho_10349" data-name="Caminho 10349" d="M11,13l9-9" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    <path id="Caminho_10350" data-name="Caminho 10350" d="M15,4h5V9" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </svg>
                            </span>
                        </Button>
                    </Row> */}
                    <Row>
                        <Col
                            style={{ 
                                textAlign: 'left',
                                font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#707070',
                                opacity: '1', 
                            }}
                        >
                            <div style={{ maxHeight: '20vh', overflowY: 'auto' }}>
                                <ListGroup variant='flush' >
                                    <ListGroup.Item key="consumer-data">
                                        {currentConsumer && 
                                            <>
                                                <Row className="pb-2" style={{
                                                    textAlign: 'left',
                                                    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                                    letterSpacing: '0px',
                                                    color: '#707070',
                                                }}>
                                                {t('home_consumers.unification.modal_similar_data.new_consumer')}
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Row 
                                                            style={{
                                                                textAlign: 'left',
                                                                font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                                                letterSpacing: '0px',
                                                                color: '#707070',
                                                            }}
                                                        >
                                                        {t('home_consumers.unification.modal_similar_data.name')}
                                                        </Row>
                                                        <Row>
                                                            <b style={{ color: '#1E1E1E' }} className="nopadding oneLineClampEllipsis">  
                                                                <OverlayTrigger key={currentConsumer.name + (currentConsumer.lastname? currentConsumer.lastname: "")} placement='top' 
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {currentConsumer.name + (currentConsumer.lastname? currentConsumer.lastname: "")}
                                                                        </Tooltip>
                                                                    } 
                                                                >
                                                                    <span className=''>
                                                                        {currentConsumer.name + (currentConsumer.lastname? currentConsumer.lastname: "")}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </b>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row
                                                            style={{
                                                                textAlign: 'left',
                                                                font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                                                letterSpacing: '0px',
                                                                color: '#707070',
                                                            }}
                                                        >
                                                        {t('home_consumers.unification.modal_similar_data.document_number')}
                                                        </Row>
                                                        <Row>
                                                            <b style={{ color: '#1E1E1E' }} className="nopadding">
                                                                {currentConsumer.document_number?
                                                                    currentConsumer.document_number:
                                                                    <>&mdash;</>
                                                                }
                                                            </b>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </ListGroup.Item>

                                    {
                                        similarConsumers && similarConsumers.map((item, index) => {
                                            return (
                                                <ListGroup.Item key={item.id} className="pb-1 pt-2">
                                                    <Row className="pb-2 pt-3" style={{
                                                        textAlign: 'left',
                                                        font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                                        letterSpacing: '0px',
                                                        color: '#707070',
                                                    }}>
                                                        {t('home_consumers.unification.modal_similar_data.consumer_index')} {index + 1}
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Row 
                                                                style={{
                                                                    textAlign: 'left',
                                                                    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                                                    letterSpacing: '0px',
                                                                    color: '#707070',
                                                                }}
                                                            >
                                                                {t('home_consumers.unification.modal_similar_data.name')}
                                                            </Row>
                                                            <Row>
                                                                <b style={{ color: '#1E1E1E' }} className="nopadding oneLineClampEllipsis">  
                                                                    <OverlayTrigger key={item.document_number? item.document_number: item.name} placement='top' 
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {item.name + (item.lastname? item.lastname: "")}
                                                                            </Tooltip>
                                                                        } 
                                                                    >
                                                                        <span className=''>
                                                                            {item.name + (item.lastname? item.lastname: "")}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </b>
                                                            </Row>
                                                        </Col>
                                                        <Col>
                                                            <Row
                                                                 style={{
                                                                    textAlign: 'left',
                                                                    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
                                                                    letterSpacing: '0px',
                                                                    color: '#707070',
                                                                }}
                                                            >
                                                                {t('home_consumers.unification.modal_similar_data.document_number')}
                                                            </Row>
                                                            <Row>
                                                                <b style={{ color: '#1E1E1E' }} className="nopadding">
                                                                    {item.document_number?
                                                                        item.document_number:
                                                                        <>&mdash;</>
                                                                    }
                                                                </b>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            );
                                        })
                                    }
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mt-4" style={{  }}>
                        <Col md={6} className="d-flex justify-content-start">
                            <div style={{ width: '70%' }}>
                                <Button size='lg' className="buttonWhite" onClick={hide} >{t('home_consumers.unification.modal_similar_data.cancel')}</Button>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                            <div style={{ width: '70%' }}>
                                <Button size='lg' className="buttonBlue" onClick={/* openUnifyModal */() => gotoUnifyPage(props.originRedirectPage)}>{t('home_consumers.unification.modal_similar_data.compare_registrations')}</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ModalConsumersSimilarData;