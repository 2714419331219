import React from "react";
import { io, Socket } from "socket.io-client";
import { BACKEND_URL } from "../shared/backend_url";
import NotificationSocket from "../../sockets/notification-socket";


export class SocketServiceChatbot {
    public socket: Socket;
    private notificationAgent;
    private notificationSocket;
    private notificationStatusAgent;
    private notificationLogin;
    private callback: (notificationService) => void;
    areLoadedMessageEvents = false;

    public getInstance = () => this.socket;

    public createSocket(token?: string) {

        if (token && !this.socket) {
            /*
             * A instância do socket será criada somente se
             * houver um token e não houver outra instância do socket criada. 
             */
            this.socket = io(
                BACKEND_URL,
                {
                    path: "/api/socket",
                    transports: ['websocket'],
                    auth: { token: token }
                }
            )


            this.socket.on("connect", () => {
                if (this.callback) {
                    //console.log("connect");
                    this.notificationSocket = new NotificationSocket()
                    this.notificationSocket.setSocketContext(this);
                    this.notificationSocket.init();

                    this.callback(this.notificationSocket);
                }
            });

            this.socket.on("error", (error) => {
                console.log('error')
                console.log(error);
            });

            this.socket.on("reconnect", (attempt) => {
                console.log('reconnect')
                console.log(attempt);
            });

            this.socket.on("reconnect_failed", () => {
                console.log("reconnect_failed");
            });

            this.socket.on("connect_error", () => {
                console.log("Não foi possível atualizar as mensagens");
            });

            this.socket.on("disconnect", (reason) => {
                console.log("disconnect " + reason);
            });


        }
    }

    public getNotificationSocket(): NotificationSocket {
        return this.notificationSocket;
    }

    public addOnConnectCallback(callback: (notificationService) => void) {
        this.callback = callback;
    }

    public setNotificationStatusAgent(notification: any) {
        this.notificationStatusAgent = notification
    }

    public getNotificationStatusAgent() {
        return this.notificationStatusAgent
    }

    public setNotificationLogin(notification: boolean) {
        this.notificationLogin = notification
    }

    public getNotificationLogin() {
        return this.notificationLogin
    }
    
    public setNotificationAgent(notification: boolean) {
        this.notificationAgent = notification
    }

    public getNotificationAgent() {
        return this.notificationAgent
    }

    public disconnectSocket(): void {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
        }
    }
}

export const socket = new SocketServiceChatbot();

export const SocketContextChatbot = React.createContext(socket);