import React, { Dispatch, SetStateAction } from "react";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { ModalSendTicketCopyInterface } from "./indexModel";
import { setShowAlertFeedback, setShowSendTicketCopyModal } from '../../../../store/internal';
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";

export class ModalSendTicketCopyController {
  private dispatch: Function;
  private navigate: NavigateFunction;
  private props: React.PropsWithChildren<ModalSendTicketCopyInterface>;
  private setModalShow: Function;
  private setEmails: Function;
  private emails: string[];
  private setIsLoading: Dispatch<SetStateAction<boolean>>;
  private t: TFunction;
  
  private AppRequesterCtrl = new AppRequesterController();

  constructor(bodyCtrl: ISendTicketCopyBodyController) {
    this.dispatch = bodyCtrl.dispatch;
    this.navigate = bodyCtrl.navigate;
    this.setModalShow = bodyCtrl.setModalShow;
    this.setEmails = bodyCtrl.setEmails;
    this.emails = bodyCtrl.emails;
    this.setIsLoading = bodyCtrl.setIsLoading;
    this.props = bodyCtrl.props;
    this.t = bodyCtrl.t;
  }

  hide() {
    this.dispatch(setShowSendTicketCopyModal(false));
    this.setModalShow(false);
  }

  validate(values: ISendTicketCopy) {
    let errors = {};
    return errors;
  }

  onChangeEmails (e: { detail: { tagify: { getCleanValue: () => any[]; }; }; }) {
    this.setEmails(e.detail.tagify.getCleanValue().map(tag => tag.value));
  }

  async submit(values: ISendTicketCopy) {

    const body = {
      ticket_id: this.props.data.id,
      subject: `${this.t('components.modals.send_ticket_copy.subject_value')} ${this.props.data?.subject} (#${this.props.data?.ticket_reference_id})`,
      content: values.message || '',
      email_list: this.emails
    };

    if (!body.content) {
      delete body.content;
    }

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + this.props.token
    };

    if (body.email_list.length > 0) {
      await this.AppRequesterCtrl.Post(
        'ticket/send-copy', body, { headers },
        (response: Object) => response,
        (_data) => {
          this.dispatch(setShowAlertFeedback({ message: this.t('components.modals.send_ticket_copy.alert_success'), visibility: true, signalIcon: true }));
          this.hide();
        },
        (error: { response: { data: { message: { context: { label: string; }; }[]; }; status: number }; }) => {
          if (error.response && error.response.data && error.response.data.message && error.response.status && error.response.status === 400) {
            this.dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
          } else if (error.response && error.response.data.message[0].context.label === "email") {
            this.dispatch(setShowAlertFeedback({ message: this.t('components.modals.send_ticket_copy.alert_invalid_email'), visibility: true, signalIcon: false }));
          } else {
            this.dispatch(setShowAlertFeedback({ message: this.t('components.modals.send_ticket_copy.alert_error'), visibility: true, signalIcon: false }));
          }
        }, this.navigate, this.dispatch, this.setIsLoading,
      );
    } else {
      this.dispatch(setShowAlertFeedback({ message: this.t('components.modals.send_ticket_copy.alert_validate_email'), visibility: true, signalIcon: false }));
    }
  };
}

interface ISendTicketCopy {
  message?: string;
}

export interface ISendTicketCopyBodyController {
  dispatch: Dispatch<any>, 
  navigate: NavigateFunction,
  props: React.PropsWithChildren<ModalSendTicketCopyInterface>,
  setModalShow: Function,
  setEmails: Function, 
  emails: string[],
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  t: TFunction,
}
