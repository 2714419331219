import MoreInfo from "./index";
import '../styles.scss';
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../../../store/token";
import { TokenInterfaceControlPanel } from "../../../../../services/requestsInterfacesModel";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { setShowAlertFeedback } from "../../../../../store/internal";
const IntegrateAccountController = (props) => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const AppRequesterConst = new AppRequesterController();

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  const [selectEmail, setSelectEmail] = useState({});
  const [showFeedbackEmail, setShowFeedbackEmail] = useState(false);


  const integrateAccountWithAgent = async (data) => {
    let body = {
      company_id: state.id,
      _id: data.id,
      user_id: props.moreInfo.user_id,
      owner_id: data.owner,
      email:  data.email,
      token_integration: data.token_integration || undefined,
      extension: data?.extension?.extension,
      extension_id: data?.extension?._id
    }

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Post(
      `/panel/vozxpress/connect-cx-vx`, body, { headers },
      (response: Object) => {},
      (data: any) => {
        dispatch(setShowAlertFeedback({ message: "Integração entre agente do CX e atendente do VX feita com sucesso", visibility: true, signalIcon: true}))
        props.getAccountsVx();
        props.getAgentsWithAccount();
        props.onHide();
      },
      (error: { response: { status: number } }) => {
        dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde", visibility: true, signalIcon: false }))
      }, navigate, dispatch, setIsLoading,
    )

  }

  return (
    <MoreInfo 
      show={props.show}
      onHide={props.onHide}
      accountsVx={props.accountsVx}
      selectEmail={selectEmail}
      setSelectEmail={setSelectEmail}
      showFeedbackEmail={showFeedbackEmail}
      setShowFeedbackEmail={setShowFeedbackEmail}
      integrateAccountWithAgent={integrateAccountWithAgent}
    />
  )
}

export default IntegrateAccountController;