import React from 'react';
import { Row, Col, Button, Modal, Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import allStyles from './styles';
import { Formik } from 'formik';
import { verifyCode } from '../../../../services/codeCxpressInterface';


const ModalRegisterTags = ({
    t,
    modalShow,
    Hide,
    setTagName,
    setShowFeedbackName,
    tagName,
    tagIsActive,
    token,
    AppRequesterConst,
    dispatch,
    setShowAlertFeedback,
    callback,
    navigate,
    isLoading,
    setIsLoading,
    setTagIsActive,
    showFeedbackName,
    consumer

}) => {

    return (
        <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" centered={true}>
            <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
                <Modal.Title style={allStyles.textBlack}>
                    {t('modal_register_tag.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
                <Container>
                    <Formik
                        initialValues={{
                            name: "",
                        }}
                        validate={values => {
                            let errors = {};
                            setTagName(values.name);

                            if (!values.name) {
                                errors['name'] = t('modal_register_tag.error_name')
                                setShowFeedbackName(true);
                            } else {
                                setShowFeedbackName(false);
                            }

                            return errors;
                        }}
                        onSubmit={async () => {
                            let JsonSend = {
                                "name": tagName,
                                "active": tagIsActive,
                            }

                            const headers = {
                                "Content-Type": "application/json; charset=utf-8",
                                "Authorization": "Bearer " + token
                            };

                            await AppRequesterConst.Post(
                                consumer ? '/consumer-tag' : '/tag', JsonSend, { headers },
                                (response: Object) => { return response; },
                                (data: Object) => {
                                    dispatch(setShowAlertFeedback({ message: `Tag ${JsonSend.name} ${t('modal_register_tag.post_sucess')}`, visibility: true, signalIcon: true }));
                                    callback();
                                    Hide();
                                },
                                (error: { response: { status: number, data:{code_cxpress:number} }; }) => {
                                    if (error.response !== undefined) {
                                        if (error.response.status === 400) {     /*"Tag não salva. Existe uma tag com esse nome no sistema."*/
                                            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                                        }
                                        else {
                                            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                                        }
                                    }
                                }, navigate, dispatch, setIsLoading
                            );

                            /* await Api.post('/tag', JsonSend, { headers }).then((response) => {
                                return response;
                            }).then(data => {
                                dispatch(setShowAlertFeedback({ message: `Tag ${JsonSend.name} cadastrada com sucesso!`, visibility: true, signalIcon: true }));
                                props.callback();
                                Hide();
                            }).catch((error) => {
                                if (error.response !== undefined) {
                                    if (error.response.status === 400) {
                                        dispatch(setShowAlertFeedback({ message: "Tag não salva. Existe uma tag com esse nome no sistema.", visibility: true, signalIcon: false }));
                                    }
                                    else {
                                        dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                                    }
                                }
                            }); */
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                                    <Col md={12}>
                                        <Form.Label htmlFor="name">{t('modal_register_tag.label_name')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <FormControl className="form-control-Default"
                                                isInvalid={showFeedbackName}
                                                placeholder={t('modal_register_tag.name_placeholder')}
                                                aria-label={t('modal_register_tag.name_placeholder')}
                                                aria-describedby={t('modal_register_tag.name_placeholder')}
                                                size='lg'
                                                type='name'
                                                id="tags_name"
                                                name="name"
                                                autoComplete='given-name'
                                                maxLength={50}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name.trim()}
                                            >
                                            </FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbackname">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                                    <Col md={12}>
                                        <Form.Label htmlFor="basic-url">{t('modal_register_tag.tag_active_label')} &nbsp; &nbsp;</Form.Label>
                                        <Form.Check name="radioIsAdministrator" label={t('modal_register_tag.no')} type={'radio'} checked={!tagIsActive} onChange={() => { setTagIsActive(false) }} inline></Form.Check>
                                        <Form.Check name="radioIsAdministrator" label={t('modal_register_tag.yes')} type={'radio'} checked={tagIsActive} onChange={() => { setTagIsActive(true) }} inline></Form.Check>
                                    </Col>
                                </Row>
                                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center mt-4">
                                    <Col className="d-flex justify-content-center">
                                        <Button size='lg' className="buttonWhite" style={{ width: '70%' }} onClick={() => Hide()} >{t('modal_register_tag.cancel')}</Button>
                                    </Col>
                                    <Col className="d-flex justify-content-center" >
                                        <Button type='submit' size='lg' className="buttonBlue" style={{ width: '70%' }} disabled={isLoading}>{t('modal_register_tag.save')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ModalRegisterTags;