import {Modal, Row, Col, Form, Button, InputGroup, FormControl} from 'react-bootstrap'
import Tags from "@yaireo/tagify/dist/react.tagify"
import allStyles from '../styles'
import { FC } from 'react'
import { IModalCC_CCO_Create } from './indexModel'

const ModalCC_CCO_Create: FC<IModalCC_CCO_Create> = (props) => {
    return(
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={allStyles.textBlack}>{ props.t("home_tickets.modal_cco.send_message_copy") } </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="d-flex justify-content-center">
                    <Col sm={true} style={allStyles.textFontGlay}>
                        <Form.Label htmlFor="basic-url">{ props.t("home_tickets.modal_cco.email_label") }</Form.Label>
                        <InputGroup className='mb-3'>
                            <FormControl 
                                className="form-control-Default"
                                isInvalid={props.emailIsInvalid}
                                placeholder={ props.t("home_tickets.modal_cco.email_placeholder") }
                                aria-label={ props.t("home_tickets.modal_cco.email_placeholder") }
                                aria-describedby={ props.t("home_tickets.modal_cco.email_placeholder") }
                                size="lg"
                                type="email"
                                id="customers_email"
                                name="email"
                                autoComplete='email'
                                onChange={props.onChangeEmail}
                                onBlur={props.validateEmail}
                                value={props.requesterEmail}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                id="feedbacktextarea"
                            >
                                { props.emailError }
                            </Form.Control.Feedback>
                        </InputGroup>
                        <Form.Label htmlFor='basic-url'>{ props.t("home_tickets.modal_cco.cc_copy") }</Form.Label>
                        <Tags
                            defaultValue={props.emailCC ? props.emailCC : ""}
                            className="form-control-Default-text-area tag-min-height mb-3"
                            placeholder={ props.t("home_tickets.modal_cco.enter_email_copy") }
                            onChange={(e) => props.onChangeEmailCC(e)}
                            settings={{pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/}}
                        />
                        <Form.Label htmlFor='basic-url'>{ props.t("home_tickets.modal_cco.cco_hidden_copy") }</Form.Label>
                        <Tags
                            defaultValue={props.emailCCO ? props.emailCCO : ""}
                            className="form-control-Default-text-area tag-min-height mb-3"
                            placeholder={ props.t("home_tickets.modal_cco.enter_email_hidden_copy") }
                            onChange={(e) => props.onChangeEmailCCO(e)}
                            settings={{pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/}}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Row className='d-flex justify-content-center mt-2 nopadding'>
                <Col xs={6} className='d-flex justify-content-center mb-3'>
                    <Button size='lg' type='submit' className='buttonBlue' style={{ width: '50%' }} onClick={() => props.saveEmails()}>{ props.t("home_tickets.modal_cco.save") }</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalCC_CCO_Create