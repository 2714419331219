import { Button, Col, OverlayTrigger } from "react-bootstrap"

const ButtonListNotification = ({ showPopover, showTemplateMessage, popOverListNotification, currentNotification }) => {
    return (
        <Col md={1} style={{ width: '2.4vw' }} className="me-1">
            <OverlayTrigger rootClose={showPopover} onToggle={() => showTemplateMessage()} 
                show={showPopover} trigger={['click']} placement="bottom-start" overlay={popOverListNotification}>
                <Button type="submit" 
                    className="buttonTicketsSec3 nopadding" 
                    onClick={() => showTemplateMessage()}>
                        {
                            currentNotification == true ?
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#888888"
                                    height="22"
                                    width="22"
                                    strokeWidth="1.5"
                                >
                                    <path d="M19 17v-5.2c-.5.1-1 .2-1.5.2H17v6H7v-7c0-2.8 2.2-5 5-5 .1-1.3.7-2.4 1.5-3.3-.3-.4-.9-.7-1.5-.7-1.1 0-2 .9-2 2v.3C7 5.2 5 7.9 5 11v6l-2 2v1h18v-1l-2-2m-9 4c0 1.1.9 2 2 2s2-.9 2-2h-4M21 6.5c0 1.9-1.6 3.5-3.5 3.5S14 8.4 14 6.5 15.6 3 17.5 3 21 4.6 21 6.5" />
                                </svg>
                            :
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#888888"
                                    height="22"
                                    width="22"
                                >
                                    <path d="M10 21h4c0 1.1-.9 2-2 2s-2-.9-2-2m11-2v1H3v-1l2-2v-6c0-3.1 2-5.8 5-6.7V4c0-1.1.9-2 2-2s2 .9 2 2v.3c3 .9 5 3.6 5 6.7v6l2 2m-4-8c0-2.8-2.2-5-5-5s-5 2.2-5 5v7h10v-7z" />
                                </svg>
                        }
                </Button>
            </OverlayTrigger>
        </Col>
    )
}

export default ButtonListNotification