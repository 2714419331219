type CSSProperties = {
    [key: string]: React.CSSProperties;
};

export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
        return styles;
    };
};

const styles = StyleSheet.create({
    boxGray: {
        border: '1px solid #BCBCBC',
        backgroundColor: '#f8f9fa',
        borderRadius: '4px',
        height: '20vh',
        font: 'var(--px15_19_Bold-font)',
        color:'#707070'
    },
    dropdownOptionRow: {
        marginBottom: '1vh',
    },
});

export default styles;