import React, { FC } from 'react';
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ButtonCountNumberTicketsInterface } from './indexModel';
import OrderByListOptions from '../../buttonOrderByList/orderByListOptions';

const ButtonCountNumberTickets: FC<ButtonCountNumberTicketsInterface> = (props) => {
    return (
        <Container className="nopadding">
            <Row style={{  }}>
                <Col md={2} style={{ width: '220px' }} onClick={props.setNoAtendence? (() => props?.setNoAtendence(false)): () => {}} >
                    <OverlayTrigger
                        key={"all_tickets"}
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                { props.t("components.button_count_number_tickets.total_resolved_tickets") }
                            </Tooltip>
                        }
                        trigger={['hover','focus']}
                        delay={400}
                    >
                        <Button className={"buttonTicketsActive"} onClick={props.closeWithoutSavingConfirmation} id="filterAllTickets" disabled={props.isLoading}>
                            <Row>
                                <Col style={{ color: '#4A69BD', font: 'normal normal bold min(max(10px, calc(0.2em + 1vw)), 24px) Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-start">
                                    {props.numberOfAllTickets !== null ?
                                        props.numberOfAllTickets :
                                        <>N/A</>
                                    }
                                </Col>
                                <Col className="d-flex justify-content-end align-self-start">
                                    <svg id="Grupo_11096" data-name="Grupo 11096" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Caminho_9596" data-name="Caminho 9596" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_150" data-name="Linha 150" x2="11" transform="translate(9 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_151" data-name="Linha 151" x2="11" transform="translate(9 12)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_152" data-name="Linha 152" x2="11" transform="translate(9 18)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_153" data-name="Linha 153" y2="0.01" transform="translate(5 6)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_154" data-name="Linha 154" y2="0.01" transform="translate(5 12)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_155" data-name="Linha 155" y2="0.01" transform="translate(5 18)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <p className="nopadding" style={{ color: '#0C2461', font: 'var(--px14-Bold-font)' }}>
                                        { props.t("components.button_count_number_tickets.all_tickets") }
                                    </p>
                                </Col>
                            </Row>
                        </Button>
                    </OverlayTrigger>
                </Col>
                <Col md={2} style={{ width: '220px' }} onClick={props.setNoAtendence? (() => props?.setNoAtendence(false)): () => {}}>
                    <OverlayTrigger
                        key={"yours_tickets"}
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                { props.t("components.button_count_number_tickets.total_assigneds_nresolved_tickets") }
                            </Tooltip>
                        }
                        trigger={['hover', 'focus']}
                        delay={400}
                    >
                        <Button className="buttonTicketsHome" onClick={props.closeWithoutSavingConfirmation} id="filterYourTickets" disabled={props.isLoading}>
                            <Row>
                                <Col style={{ color: '#4A69BD', font: 'normal normal bold min(max(10px, calc(0.2em + 1vw)), 24px) Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-start">
                                    {props.numberOfYoursTickets !== null ?
                                        props.numberOfYoursTickets :
                                        <>N/A</>
                                    }
                                </Col>
                                <Col className="d-flex justify-content-end align-self-start">
                                    <svg id="Grupo_11095" data-name="Grupo 11095" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Caminho_9591" data-name="Caminho 9591" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_163" data-name="Elipse 163" cx="4" cy="4" r="4" transform="translate(11 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9592" data-name="Caminho 9592" d="M18.5,18.5,21,21" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9593" data-name="Caminho 9593" d="M4,6H20" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9594" data-name="Caminho 9594" d="M4,12H8" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9595" data-name="Caminho 9595" d="M4,18H8" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <p className="nopadding" style={{ color: '#0C2461', font: 'var(--px14-Bold-font)' }}>
                                        { props.t("components.button_count_number_tickets.your_tickets") }
                                    </p>
                                </Col>
                            </Row>
                        </Button>
                    </OverlayTrigger>
                </Col>
                <Col md={2} style={{ width: '223px' }} onClick={props.setNoAtendence? (() => props?.setNoAtendence(true)): () => {}}>
                    <OverlayTrigger
                        key={"new_tickets"}
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                { props.t("components.button_count_number_tickets.total_natended_tickets") }
                            </Tooltip>
                        }
                        trigger={['hover', 'focus']}
                        delay={400}
                    >
                        <Button className="buttonTicketsHome" onClick={props.closeWithoutSavingConfirmation} id="filterNewTickets" disabled={props.isLoading}>
                            <Row>
                                <Col style={{ color: '#4A69BD', font: 'normal normal bold min(max(10px, calc(0.2em + 1vw)), 24px) Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-start">
                                    {props.numberOfNewTickets !== null ?
                                        props.numberOfNewTickets :
                                        <>N/A</>
                                    }
                                </Col>
                                <Col className="d-flex justify-content-end align-self-start">
                                    <svg id="Grupo_11095" data-name="Grupo 11095" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Caminho_9591" data-name="Caminho 9591" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_163" data-name="Elipse 163" cx="4" cy="4" r="4" transform="translate(11 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9592" data-name="Caminho 9592" d="M18.5,18.5,21,21" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9593" data-name="Caminho 9593" d="M4,6H20" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9594" data-name="Caminho 9594" d="M4,12H8" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9595" data-name="Caminho 9595" d="M4,18H8" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <p className="nopadding" style={{ color: '#0C2461', font: 'var(--px14-Bold-font)' }}>
                                        { props.t("components.button_count_number_tickets.n_atended") }
                                    </p>
                                </Col>
                            </Row>
                        </Button>
                    </OverlayTrigger>
                </Col>
                <Col md={2} style={{ width: '223px' }} onClick={props.setNoAtendence? (() => props?.setNoAtendence(false)): () => {}}>
                    <OverlayTrigger
                        key={"unread_tickets"}
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                { props.t("components.button_count_number_tickets.total_not_read_tickets") }
                            </Tooltip>
                        }
                        trigger={['hover', 'focus']}
                        delay={400}
                    >
                        <Button className="buttonTicketsHome" onClick={props.closeWithoutSavingConfirmation} id="filterUnreadTickets" disabled={props.isLoading}>
                            <Row>
                                <Col style={{ color: '#4A69BD', font: 'normal normal bold min(max(10px, calc(0.2em + 1vw)), 24px) Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-start">
                                    {props.numberOfUnreadTickets !== null ?
                                        props.numberOfUnreadTickets :
                                        <>N/A</>
                                    }
                                </Col>
                                <Col className="d-flex justify-content-end align-self-start">
                                    <svg id="Grupo_11095" data-name="Grupo 11095" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Caminho_9591" data-name="Caminho 9591" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_163" data-name="Elipse 163" cx="4" cy="4" r="4" transform="translate(11 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9592" data-name="Caminho 9592" d="M18.5,18.5,21,21" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9593" data-name="Caminho 9593" d="M4,6H20" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9594" data-name="Caminho 9594" d="M4,12H8" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9595" data-name="Caminho 9595" d="M4,18H8" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <p className="nopadding" style={{ color: '#0C2461', font: 'var(--px14-Bold-font)' }}>
                                        { props.t("components.button_count_number_tickets.not_read") }
                                    </p>
                                </Col>
                            </Row>
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </Container >
    );
}

export default ButtonCountNumberTickets;