export class TagsComponentController {
    private props

    constructor(props) {
        this.props = props
    }

    onSelectTags(selectedList: any) {
        this.props.setCurrentTags(selectedList);
        if (this.props.onSelect)
            this.props.onSelect();
    }

    onRemoveTags(selectedList: any) {
        this.props.setCurrentTags(selectedList);
        if (this.props.onSelect)
            this.props.onSelect();
    }
}