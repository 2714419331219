import React, { FC } from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import constsRouters from '../../routes/constsRouter';
import { IPage404 } from './indexModel';

const Page404: FC<IPage404> = (props) => {
    return (
        <Container fluid style={ {overflowX: 'hidden'}}>
            <Row style={{ height: '93vh' }} className="d-flex justify-content-center align-items-center">
                <Col sm={4} md={4}
                    style={{
                        background: '#E5ECFF 0% 0% no-repeat padding-box',
                        borderRadius: '25px',
                    }}
                >
                    <Row className="justify-content-center"
                        style={{
                            marginTop: '2vh',
                            textAlign: 'center',
                            font: 'normal normal bold calc(0.2em + 8vw) Montserrat',
                            //font: 'normal normal bold 150px/183px Montserrat',
                            letterSpacing: '0px',
                            color: '#1E1E1E',
                            opacity: '1'
                        }}
                    >
                        404
                    </Row>
                    <Row className="justify-content-center"
                        style={{ marginTop: '2vh', }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                //font: 'normal normal bold 20px/24px Montserrat',
                                font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#1E1E1E',
                                opacity: '1',
                            }}
                        >
                            { props.t("page_not_found_404.without_results") }
                        </div>
                    </Row>
                    <Row className="justify-content-center"
                        style={{
                            marginTop: '1.3vh',
                        }}
                    >
                        <Col sm={8} md={8}
                            style={{
                                textAlign: 'center',
                                font: 'normal normal normal calc(0.3em + 0.6vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#1E1E1E',
                                opacity: '1',
                            }}
                        >
                            { props.t("page_not_found_404.verify_search") }
                        </Col>
                    </Row>
                    <Row className="justify-content-center"
                        style={{
                            marginTop: '1.3vh',
                            textAlign: 'center',
                            font: 'normal normal bold calc(0.2em + 0.6vw) Montserrat',
                            letterSpacing: '0px',
                            color: '#1E1E1E',
                            opacity: '1',
                        }}
                    >
                        { props.t("page_not_found_404.or") }
                    </Row>
                    <Row className="justify-content-center"
                        style={{
                            marginTop: '1.3vh',
                        }}
                    >
                        <Link to={{pathname: (props.values.token.value !== null && props.values.token.value !== undefined) ? constsRouters.routers.home.path : `https://${window.location.host}` }} className="nopadding" style={{ width: '20.5vw' }}>
                            <Button className="buttonBlue"
                                style={{
                                    width: '100%',
                                    height: '5vh',
                                    marginBottom: '4vh'
                                }}
                            >
                                { props.t("page_not_found_404.go_to_home") }
                            </Button>
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Row style={{ width: '100%' }}>
                <div className="d-flex align-items-end nopadding">
                    <svg style={{ height: '7vh' }} xmlns="http://www.w3.org/2000/svg" width="1381.287" height="78.999" viewBox="0 0 1381.287 78.999">
                        <g id="Grupo_11278" data-name="Grupo 11278" transform="translate(0 -719)">
                            <g id="Grupo_11104" data-name="Grupo 11104" transform="translate(2727.235 3943.429)">
                                <path id="Caminho_9608" data-name="Caminho 9608" d="M480.148,379.926h-35l.018-1.709.054-1.7.09-1.691.126-1.682.161-1.672.2-1.661.23-1.65.264-1.639.3-1.628.331-1.616.364-1.6.4-1.591.428-1.578.46-1.565.491-1.551.522-1.537.553-1.523.583-1.508.613-1.493.643-1.477.672-1.461.7-1.445.729-1.428.757-1.411.785-1.394.812-1.376.839-1.358.866-1.339.892-1.321.918-1.3.943-1.282.969-1.262.993-1.242,1.018-1.221,1.042-1.2,1.065-1.178,1.089-1.156,1.112-1.134,1.134-1.112,1.156-1.089,1.178-1.065,1.2-1.042,1.221-1.018,1.242-.993,1.262-.969,1.282-.944,1.3-.918,1.321-.892,1.339-.866,1.358-.839,1.376-.812,1.394-.785,1.411-.757,1.428-.729,1.445-.7,1.461-.672,1.477-.643,1.493-.613,1.508-.583,1.523-.553,1.537-.522,1.551-.491,1.565-.46,1.578-.428,1.591-.4,1.6-.364,1.616-.331,1.628-.3,1.639-.264,1.65-.23,1.661-.2,1.672-.161,1.682-.126,1.691-.09,1.7-.054,1.709-.018v35l-1.712.033-1.7.1-1.677.162-1.659.225-1.638.287-1.616.347-1.594.406-1.57.464-1.544.521-1.518.576-1.49.63-1.462.683-1.432.735-1.4.786-1.368.835-1.334.884-1.3.931-1.263.976-1.226,1.021-1.187,1.064-1.148,1.107-1.107,1.148-1.064,1.187-1.021,1.226-.977,1.263-.931,1.3-.884,1.334-.835,1.368-.786,1.4-.735,1.432-.684,1.462-.63,1.49-.576,1.518-.521,1.544-.464,1.57-.406,1.594-.347,1.616-.286,1.638-.225,1.658-.162,1.677-.1,1.7-.033,1.712Z" transform="translate(-2677.772 -3525.689)" fill="#4a69bd" fillRule="evenodd" />
                                <path id="Caminho_9610" data-name="Caminho 9610" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-2723.139 -3525.7)" fill="#4a69bd" fillRule="evenodd" />
                                <path id="Caminho_9609" data-name="Caminho 9609" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-2700.572 -3525.7)" fill="#0c2461" fillRule="evenodd" />
                                <path id="Caminho_9611" data-name="Caminho 9611" d="M787.132,380.03h-16.09v-78.66h16.09Z" transform="translate(-2745.365 -3525.7)" fill="#0c2461" fillRule="evenodd" />
                                <path id="Caminho_9603" data-name="Caminho 9603" d="M178.967,301.439v78.609l-1.709-.018-1.7-.054-1.691-.09-1.682-.126-1.672-.161-1.661-.2-1.65-.23-1.639-.264-1.628-.3-1.616-.331-1.6-.363-1.591-.4-1.578-.428-1.565-.46-1.551-.491-1.537-.522-1.523-.553-1.508-.583-1.493-.613-1.477-.643-1.461-.672-1.445-.7-1.428-.729-1.411-.757-1.394-.785-1.376-.812-1.358-.839-1.34-.866-1.321-.892-1.3-.918-1.282-.943-1.262-.969-1.242-.993-1.221-1.018-1.2-1.042-1.178-1.066-1.156-1.089-1.134-1.112-1.112-1.134-1.089-1.156-1.066-1.178-1.042-1.2-1.018-1.221-.993-1.241-.969-1.262-.944-1.282-.918-1.3-.892-1.321-.866-1.34-.839-1.358-.812-1.376-.785-1.394-.757-1.411-.729-1.428-.7-1.445-.672-1.461-.643-1.477-.613-1.493-.583-1.508-.553-1.523-.522-1.537-.491-1.551-.46-1.565-.428-1.578-.4-1.591-.364-1.6-.331-1.616-.3-1.628-.264-1.639-.23-1.65-.2-1.661-.161-1.672-.126-1.682-.09-1.691-.054-1.7-.018-1.709h78.609Z" transform="translate(-2606.261 -3525.715)" fill="#0c2461" fillRule="evenodd" />
                                <path id="Caminho_9604" data-name="Caminho 9604" d="M210.15,301.245l78.608,78.609H210.15Z" transform="translate(-2629.032 -3525.674)" fill="#4a69bd" fillRule="evenodd" />
                                <path id="Caminho_9605" data-name="Caminho 9605" d="M342.687,379.927l-1.71-.018-1.7-.055-1.691-.09-1.681-.126-1.671-.161-1.661-.2-1.65-.23-1.638-.264-1.627-.3-1.615-.331-1.6-.363-1.59-.4-1.577-.428-1.563-.46-1.55-.491-1.536-.522-1.521-.553-1.506-.583-1.491-.613-1.475-.643-1.459-.672-1.443-.7-1.426-.729-1.409-.757-1.392-.785-1.374-.812-1.356-.839-1.337-.866-1.318-.892-1.3-.918-1.279-.944-1.259-.969-1.239-.993-1.218-1.018-1.2-1.042-1.176-1.065-1.154-1.089-1.132-1.112-1.109-1.134-1.086-1.156-1.063-1.178-1.039-1.2-1.015-1.221-.991-1.241-.966-1.262-.941-1.282-.916-1.3-.89-1.321-.863-1.339-.837-1.358-.81-1.376-.783-1.394-.755-1.411-.727-1.428-.7-1.445-.67-1.461-.641-1.477-.611-1.493-.581-1.508-.551-1.523-.521-1.537-.49-1.551-.458-1.565-.427-1.578-.395-1.591-.362-1.6-.329-1.616-.3-1.628-.263-1.64-.229-1.651-.195-1.661-.16-1.672-.125-1.682-.09-1.691-.054-1.7-.018-1.71H421.3l-.018,1.71-.054,1.7-.09,1.691-.126,1.682-.161,1.672-.2,1.661-.23,1.651-.264,1.64-.3,1.628-.331,1.616-.363,1.6-.4,1.591-.428,1.578-.46,1.565-.491,1.551-.522,1.537-.553,1.523-.583,1.508-.613,1.493-.643,1.477-.672,1.461-.7,1.445-.729,1.428-.757,1.411-.785,1.394-.812,1.376-.839,1.358-.866,1.339-.892,1.321-.918,1.3-.944,1.282-.968,1.262-.993,1.241-1.018,1.221-1.042,1.2-1.065,1.178-1.089,1.156-1.112,1.134-1.134,1.112-1.156,1.089-1.178,1.065-1.2,1.042-1.221,1.018-1.241.993-1.262.969-1.282.944-1.3.918-1.321.892-1.339.866-1.358.839-1.376.812-1.394.785-1.411.757-1.428.729-1.445.7-1.461.672-1.477.643-1.493.613-1.508.583-1.523.553-1.537.522-1.551.491-1.565.46-1.578.428-1.591.4-1.6.363-1.616.331-1.628.3-1.639.264-1.651.23-1.661.2-1.672.161-1.682.126-1.691.09-1.7.055-1.71.018Z" transform="translate(-2640.245 -3525.689)" fill="#ff8119" fillRule="evenodd" />
                                <path id="Caminho_9601" data-name="Caminho 9601" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-3304.876 -3525.7)" fill="#ff8119" fillRule="evenodd" />
                                <path id="Caminho_9602" data-name="Caminho 9602" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-3282.31 -3525.7)" fill="#0c2461" fillRule="evenodd" />
                                <path id="Caminho_9606" data-name="Caminho 9606" d="M814.872,380.03H771.041v-78.66h43.831Z" transform="translate(-3327.103 -3525.7)" fill="#4a69bd" fillRule="evenodd" />
                                <path id="Caminho_9613" data-name="Caminho 9613" d="M0,0,78.608,78.609H0Z" transform="translate(-1949.845 -3145.623) rotate(-90)" fill="#0c2461" fillRule="evenodd" />
                            </g>
                            <path id="Caminho_9603-2" data-name="Caminho 9603" d="M178.967,301.439v78.609l-1.709-.018-1.7-.054-1.691-.09-1.682-.126-1.672-.161-1.661-.2-1.65-.23-1.639-.264-1.628-.3-1.616-.331-1.6-.363-1.591-.4-1.578-.428-1.565-.46-1.551-.491-1.537-.522-1.523-.553-1.508-.583-1.493-.613-1.477-.643-1.461-.672-1.445-.7-1.428-.729-1.411-.757-1.394-.785-1.376-.812-1.358-.839-1.34-.866-1.321-.892-1.3-.918-1.282-.943-1.262-.969-1.242-.993-1.221-1.018-1.2-1.042-1.178-1.066-1.156-1.089-1.134-1.112-1.112-1.134-1.089-1.156-1.066-1.178-1.042-1.2-1.018-1.221-.993-1.241-.969-1.262-.944-1.282-.918-1.3-.892-1.321-.866-1.34-.839-1.358-.812-1.376-.785-1.394-.757-1.411-.729-1.428-.7-1.445-.672-1.461-.643-1.477-.613-1.493-.583-1.508-.553-1.523-.522-1.537-.491-1.551-.46-1.565-.428-1.578-.4-1.591-.364-1.6-.331-1.616-.3-1.628-.264-1.639-.23-1.65-.2-1.661-.161-1.672-.126-1.682-.09-1.691-.054-1.7-.018-1.709h78.609Z" transform="translate(993.974 417.907)" fill="#0c2461" fillRule="evenodd" />
                            <path id="Caminho_9604-2" data-name="Caminho 9604" d="M210.15,301.245l78.608,78.609H210.15Z" transform="translate(971.203 417.948)" fill="#4a69bd" fillRule="evenodd" />
                            <path id="Caminho_9605-2" data-name="Caminho 9605" d="M342.687,379.927l-1.71-.018-1.7-.055-1.691-.09-1.681-.126-1.671-.161-1.661-.2-1.65-.23-1.638-.264-1.627-.3-1.615-.331-1.6-.363-1.59-.4-1.577-.428-1.563-.46-1.55-.491-1.536-.522-1.521-.553-1.506-.583-1.491-.613-1.475-.643-1.459-.672-1.443-.7-1.426-.729-1.409-.757-1.392-.785-1.374-.812-1.356-.839-1.337-.866-1.318-.892-1.3-.918-1.279-.944-1.259-.969-1.239-.993-1.218-1.018-1.2-1.042-1.176-1.065-1.154-1.089-1.132-1.112-1.109-1.134-1.086-1.156-1.063-1.178-1.039-1.2-1.015-1.221-.991-1.241-.966-1.262-.941-1.282-.916-1.3-.89-1.321-.863-1.339-.837-1.358-.81-1.376-.783-1.394-.755-1.411-.727-1.428-.7-1.445-.67-1.461-.641-1.477-.611-1.493-.581-1.508-.551-1.523-.521-1.537-.49-1.551-.458-1.565-.427-1.578-.395-1.591-.362-1.6-.329-1.616-.3-1.628-.263-1.64-.229-1.651-.195-1.661-.16-1.672-.125-1.682-.09-1.691-.054-1.7-.018-1.71H421.3l-.018,1.71-.054,1.7-.09,1.691-.126,1.682-.161,1.672-.2,1.661-.23,1.651-.264,1.64-.3,1.628-.331,1.616-.363,1.6-.4,1.591-.428,1.578-.46,1.565-.491,1.551-.522,1.537-.553,1.523-.583,1.508-.613,1.493-.643,1.477-.672,1.461-.7,1.445-.729,1.428-.757,1.411-.785,1.394-.812,1.376-.839,1.358-.866,1.339-.892,1.321-.918,1.3-.944,1.282-.968,1.262-.993,1.241-1.018,1.221-1.042,1.2-1.065,1.178-1.089,1.156-1.112,1.134-1.134,1.112-1.156,1.089-1.178,1.065-1.2,1.042-1.221,1.018-1.241.993-1.262.969-1.282.944-1.3.918-1.321.892-1.339.866-1.358.839-1.376.812-1.394.785-1.411.757-1.428.729-1.445.7-1.461.672-1.477.643-1.493.613-1.508.583-1.523.553-1.537.522-1.551.491-1.565.46-1.578.428-1.591.4-1.6.363-1.616.331-1.628.3-1.639.264-1.651.23-1.661.2-1.672.161-1.682.126-1.691.09-1.7.055-1.71.018Z" transform="translate(959.991 417.933)" fill="#ff8119" fillRule="evenodd" />
                            <path id="Caminho_9601-2" data-name="Caminho 9601" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(295.359 417.922)" fill="#ff8119" fillRule="evenodd" />
                            <path id="Caminho_9602-2" data-name="Caminho 9602" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(317.926 417.922)" fill="#0c2461" fillRule="evenodd" />
                            <path id="Caminho_9606-2" data-name="Caminho 9606" d="M814.872,380.03H771.041v-78.66h43.831Z" transform="translate(273.132 417.922)" fill="#4a69bd" fillRule="evenodd" />
                        </g>
                    </svg>
                    <svg style={{ height: '7vh' }} xmlns="http://www.w3.org/2000/svg" width="856" height="78.807" viewBox="0 0 856 78.807">
                        <g id="Grupo_11104" data-name="Grupo 11104" transform="translate(2727.235 3224.429)">
                            <path id="Caminho_9608" data-name="Caminho 9608" d="M480.148,379.926h-35l.018-1.709.054-1.7.09-1.691.126-1.682.161-1.672.2-1.661.23-1.65.264-1.639.3-1.628.331-1.616.364-1.6.4-1.591.428-1.578.46-1.565.491-1.551.522-1.537.553-1.523.583-1.508.613-1.493.643-1.477.672-1.461.7-1.445.729-1.428.757-1.411.785-1.394.812-1.376.839-1.358.866-1.339.892-1.321.918-1.3.943-1.282.969-1.262.993-1.242,1.018-1.221,1.042-1.2,1.065-1.178,1.089-1.156,1.112-1.134,1.134-1.112,1.156-1.089,1.178-1.065,1.2-1.042,1.221-1.018,1.242-.993,1.262-.969,1.282-.944,1.3-.918,1.321-.892,1.339-.866,1.358-.839,1.376-.812,1.394-.785,1.411-.757,1.428-.729,1.445-.7,1.461-.672,1.477-.643,1.493-.613,1.508-.583,1.523-.553,1.537-.522,1.551-.491,1.565-.46,1.578-.428,1.591-.4,1.6-.364,1.616-.331,1.628-.3,1.639-.264,1.65-.23,1.661-.2,1.672-.161,1.682-.126,1.691-.09,1.7-.054,1.709-.018v35l-1.712.033-1.7.1-1.677.162-1.659.225-1.638.287-1.616.347-1.594.406-1.57.464-1.544.521-1.518.576-1.49.63-1.462.683-1.432.735-1.4.786-1.368.835-1.334.884-1.3.931-1.263.976-1.226,1.021-1.187,1.064-1.148,1.107-1.107,1.148-1.064,1.187-1.021,1.226-.977,1.263-.931,1.3-.884,1.334-.835,1.368-.786,1.4-.735,1.432-.684,1.462-.63,1.49-.576,1.518-.521,1.544-.464,1.57-.406,1.594-.347,1.616-.286,1.638-.225,1.658-.162,1.677-.1,1.7-.033,1.712Z" transform="translate(-2677.772 -3525.689)" fill="#4a69bd" fillRule="evenodd" />
                            <path id="Caminho_9610" data-name="Caminho 9610" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-2723.139 -3525.7)" fill="#4a69bd" fillRule="evenodd" />
                            <path id="Caminho_9609" data-name="Caminho 9609" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-2700.572 -3525.7)" fill="#0c2461" fillRule="evenodd" />
                            <path id="Caminho_9611" data-name="Caminho 9611" d="M787.132,380.03h-16.09v-78.66h16.09Z" transform="translate(-2745.365 -3525.7)" fill="#0c2461" fillRule="evenodd" />
                            <path id="Caminho_9603" data-name="Caminho 9603" d="M178.967,301.439v78.609l-1.709-.018-1.7-.054-1.691-.09-1.682-.126-1.672-.161-1.661-.2-1.65-.23-1.639-.264-1.628-.3-1.616-.331-1.6-.363-1.591-.4-1.578-.428-1.565-.46-1.551-.491-1.537-.522-1.523-.553-1.508-.583-1.493-.613-1.477-.643-1.461-.672-1.445-.7-1.428-.729-1.411-.757-1.394-.785-1.376-.812-1.358-.839-1.34-.866-1.321-.892-1.3-.918-1.282-.943-1.262-.969-1.242-.993-1.221-1.018-1.2-1.042-1.178-1.066-1.156-1.089-1.134-1.112-1.112-1.134-1.089-1.156-1.066-1.178-1.042-1.2-1.018-1.221-.993-1.241-.969-1.262-.944-1.282-.918-1.3-.892-1.321-.866-1.34-.839-1.358-.812-1.376-.785-1.394-.757-1.411-.729-1.428-.7-1.445-.672-1.461-.643-1.477-.613-1.493-.583-1.508-.553-1.523-.522-1.537-.491-1.551-.46-1.565-.428-1.578-.4-1.591-.364-1.6-.331-1.616-.3-1.628-.264-1.639-.23-1.65-.2-1.661-.161-1.672-.126-1.682-.09-1.691-.054-1.7-.018-1.709h78.609Z" transform="translate(-2606.261 -3525.715)" fill="#0c2461" fillRule="evenodd" />
                            <path id="Caminho_9604" data-name="Caminho 9604" d="M210.15,301.245l78.608,78.609H210.15Z" transform="translate(-2629.032 -3525.674)" fill="#4a69bd" fillRule="evenodd" />
                            <path id="Caminho_9605" data-name="Caminho 9605" d="M342.687,379.927l-1.71-.018-1.7-.055-1.691-.09-1.681-.126-1.671-.161-1.661-.2-1.65-.23-1.638-.264-1.627-.3-1.615-.331-1.6-.363-1.59-.4-1.577-.428-1.563-.46-1.55-.491-1.536-.522-1.521-.553-1.506-.583-1.491-.613-1.475-.643-1.459-.672-1.443-.7-1.426-.729-1.409-.757-1.392-.785-1.374-.812-1.356-.839-1.337-.866-1.318-.892-1.3-.918-1.279-.944-1.259-.969-1.239-.993-1.218-1.018-1.2-1.042-1.176-1.065-1.154-1.089-1.132-1.112-1.109-1.134-1.086-1.156-1.063-1.178-1.039-1.2-1.015-1.221-.991-1.241-.966-1.262-.941-1.282-.916-1.3-.89-1.321-.863-1.339-.837-1.358-.81-1.376-.783-1.394-.755-1.411-.727-1.428-.7-1.445-.67-1.461-.641-1.477-.611-1.493-.581-1.508-.551-1.523-.521-1.537-.49-1.551-.458-1.565-.427-1.578-.395-1.591-.362-1.6-.329-1.616-.3-1.628-.263-1.64-.229-1.651-.195-1.661-.16-1.672-.125-1.682-.09-1.691-.054-1.7-.018-1.71H421.3l-.018,1.71-.054,1.7-.09,1.691-.126,1.682-.161,1.672-.2,1.661-.23,1.651-.264,1.64-.3,1.628-.331,1.616-.363,1.6-.4,1.591-.428,1.578-.46,1.565-.491,1.551-.522,1.537-.553,1.523-.583,1.508-.613,1.493-.643,1.477-.672,1.461-.7,1.445-.729,1.428-.757,1.411-.785,1.394-.812,1.376-.839,1.358-.866,1.339-.892,1.321-.918,1.3-.944,1.282-.968,1.262-.993,1.241-1.018,1.221-1.042,1.2-1.065,1.178-1.089,1.156-1.112,1.134-1.134,1.112-1.156,1.089-1.178,1.065-1.2,1.042-1.221,1.018-1.241.993-1.262.969-1.282.944-1.3.918-1.321.892-1.339.866-1.358.839-1.376.812-1.394.785-1.411.757-1.428.729-1.445.7-1.461.672-1.477.643-1.493.613-1.508.583-1.523.553-1.537.522-1.551.491-1.565.46-1.578.428-1.591.4-1.6.363-1.616.331-1.628.3-1.639.264-1.651.23-1.661.2-1.672.161-1.682.126-1.691.09-1.7.055-1.71.018Z" transform="translate(-2640.245 -3525.689)" fill="#ff8119" fillRule="evenodd" />
                            <path id="Caminho_9601" data-name="Caminho 9601" d="M703.181,379.977l-1.446-.027-1.445-.08-1.441-.133-1.435-.186-1.427-.239-1.418-.291-1.406-.343-1.392-.395-1.377-.445-1.359-.5-1.34-.546-1.319-.594-1.3-.643-1.272-.69-1.246-.736-1.218-.782-1.188-.826L679,371.658l-1.124-.911-1.09-.952-1.054-.991-1.017-1.029-.978-1.066-.938-1.1-.9-1.135-.855-1.167-.811-1.2-.767-1.227-.721-1.255-.674-1.28-.627-1.3-.578-1.326-.529-1.347L665.861,353l-.429-1.382-.377-1.4-.326-1.41-.274-1.421-.221-1.43-.168-1.437-.115-1.442-.062-1.446-.012-.965,0-.482.044-1.446.1-1.444.151-1.439.2-1.433.256-1.424.309-1.414.36-1.4.412-1.387.462-1.371.512-1.353.562-1.333.611-1.312.658-1.288.706-1.263.752-1.237.8-1.208.84-1.178.883-1.146L673.42,315l.965-1.078,1-1.042,1.042-1,1.078-.965,1.113-.925,1.146-.883,1.178-.84,1.208-.8,1.237-.751,1.263-.705,1.288-.659,1.312-.611,1.333-.562,1.353-.512,1.371-.462,1.387-.412,1.4-.36,1.413-.308,1.424-.256,1.433-.2,1.439-.151,1.443-.1,1.446-.044.482,0,.483,0,1.446.044,1.444.1,1.439.151,1.433.2,1.424.256,1.414.308,1.4.36,1.387.412,1.371.462,1.353.512,1.333.562,1.312.611,1.289.659,1.263.705,1.236.751,1.208.8,1.178.84,1.146.883,1.113.925,1.078.965,1.042,1,1,1.042.965,1.078.925,1.113.883,1.146.84,1.178.8,1.208.752,1.237.705,1.263.658,1.288.611,1.312.562,1.333.513,1.353.462,1.371.411,1.387.36,1.4.308,1.414.256,1.424.2,1.433.151,1.439.1,1.444.044,1.446,0,.482,0,.482-.044,1.446-.1,1.444-.151,1.439-.2,1.433-.256,1.424-.308,1.414-.36,1.4-.411,1.387-.462,1.371-.513,1.353-.562,1.333-.611,1.312-.658,1.288-.705,1.263-.752,1.237-.8,1.208-.841,1.178-.883,1.146-.925,1.113-.965,1.078-1,1.042-1.042,1-1.078.965-1.113.925-1.146.883-1.178.84-1.208.8-1.236.751-1.263.706-1.289.658-1.312.611-1.333.562-1.353.512-1.371.462-1.387.411-1.4.36-1.414.308-1.424.256-1.433.2-1.439.151-1.444.1-1.446.045Z" transform="translate(-3304.876 -3525.7)" fill="#ff8119" fillRule="evenodd" />
                            <path id="Caminho_9602" data-name="Caminho 9602" d="M633.783,380.077H555.074V301.368h78.709Z" transform="translate(-3282.31 -3525.7)" fill="#0c2461" fillRule="evenodd" />
                            <path id="Caminho_9606" data-name="Caminho 9606" d="M814.872,380.03H771.041v-78.66h43.831Z" transform="translate(-3327.103 -3525.7)" fill="#4a69bd" fillRule="evenodd" />
                            <path id="Caminho_9613" data-name="Caminho 9613" d="M0,0,78.608,78.609H0Z" transform="translate(-1949.845 -3145.623) rotate(-90)" fill="#0c2461" fillRule="evenodd" />
                        </g>
                    </svg>
                </div>
            </Row>
        </Container >
    );
}

export default Page404;
